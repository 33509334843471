import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { TKey } from 'i18next';
import { WarningIcon } from 'icons';

interface Props {
  tKey: TKey<'common', 'warnings'>;
  fullWidth?: boolean;
}

const WarningField = ({ tKey, fullWidth }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'warnings' });

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      p={1.5}
      borderRadius={2}
      width={fullWidth ? '100%' : '75%'}
      bgcolor={({ palette }) => palette.warning.background}
    >
      <WarningIcon color="warning" />
      <Typography variant="caption" mr="auto" fontWeight="bold">
        {t(tKey)}
      </Typography>
    </Box>
  );
};

export default WarningField;
