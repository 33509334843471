import {
  findMissingField,
  getInputLabel,
  getInputProps,
  getLabelVariant,
  isMissingFieldRequired,
} from 'utils/missingFields';
import { MissingField } from 'api/missingData/types';
import { NaturalPersonFieldNames } from './types';

// Show warning for birth fields when:
// 1. NRN && placeOfBirth && dateOfBirth are NOT required (before RentalDeposit order)
// 2. nationality !== Belgian && NRN && placeOfBirth && dateOfBirth are required (after RentalDeposit order)
// In both cases all three fields should be displayed without "*".
const BIRTH_FIELDS: NaturalPersonFieldNames[] = [
  'nationalRegisterNumber',
  'dateOfBirth',
  'placeOfBirth',
];

const getMissingBirthFields = (missingFields: MissingField[]) =>
  missingFields.filter(({ propertyName }) =>
    BIRTH_FIELDS.find((field) => propertyName.toLowerCase() === field.toLowerCase()),
  );

export const isBirthFieldsWarningVisible = (
  missingFields: MissingField[],
  nationality?: string,
) => {
  const isNRN = !!findMissingField('nationalRegisterNumber', missingFields);
  const isDateOfBirth = !!findMissingField('dateOfBirth', missingFields);
  const isPlaceOfBirth = !!findMissingField('placeOfBirth', missingFields);

  if (!missingFields.length || !isNRN || (!isDateOfBirth && !isPlaceOfBirth)) {
    return false;
  }

  const missingBirthFields = getMissingBirthFields(missingFields);

  return (
    missingBirthFields.every(({ required }) => !required) ||
    (nationality !== 'Belgian' && missingBirthFields.every(({ required }) => required))
  );
};

export const getNaturalPersonInputProps = (
  name: NaturalPersonFieldNames,
  missingFields: MissingField[],
) => {
  const missingBirthFields = getMissingBirthFields(missingFields);

  if (missingBirthFields.length === BIRTH_FIELDS.length && BIRTH_FIELDS.includes(name)) {
    const isRequired = missingBirthFields.every(({ required }) => required)
      ? false
      : isMissingFieldRequired(name, missingFields);
    return {
      label: getInputLabel(name, isRequired),
      labelVariant: getLabelVariant(missingFields),
    } as const;
  }

  return getInputProps(name, missingFields);
};
