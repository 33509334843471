import { ProductName } from 'api/dossier/products/types';

// The order of products is important
// It is used in ProductsStatuses component (dashboard view) and in dossier history filters
const PRODUCTS: readonly ProductName[] = [
  'RentalDeposit',
  'FireInsurance',
  'LegalAssistance',
  'RentalContract',
  'MyMove',
  'StateReport',
  'IntermediateStateReport',
  'EndStateReport',
];

export default PRODUCTS;
