import { createContext, useState } from 'react';
import { areRelationsEqual } from 'modules/Relation/utils';
import transformName from 'utils/transformName';
import { LegalPersonFormData } from 'api/person/legal/types';
import {
  LegalPersonFormContextInterface,
  LegalPersonFormProviderProps,
  PersonForRelationUpdateInterface,
} from './types';
import { updateOwnerships, updateRepresentatives } from './utils';

export const LegalPersonFormContext = createContext<LegalPersonFormContextInterface>({
  personId: null,
  representativesToPreview: [],
  setRepresentativesToPreview: () => {},
  ownershipsToPreview: [],
  setOwnershipsToPreview: () => {},
  saveRelations: async () => false,
});

export const LegalPersonFormProvider = ({
  children,
  personId = null,
  defaultOwnerships = [],
  defaultRepresentatives = [],
}: LegalPersonFormProviderProps) => {
  const [representativesToPreview, setRepresentativesToPreview] =
    useState(defaultRepresentatives);
  const [ownershipsToPreview, setOwnershipsToPreview] = useState(defaultOwnerships);

  const saveRelations = async (personId: string, data: LegalPersonFormData) => {
    let ok = true;

    if (!areRelationsEqual(representativesToPreview, defaultRepresentatives)) {
      const person: PersonForRelationUpdateInterface = {
        id: personId,
        name: transformName(data),
        type: 'LegalPerson',
      };

      const { allSaved } = await updateRepresentatives(person)(
        representativesToPreview,
        setRepresentativesToPreview,
        true,
      );
      ok = allSaved;
    }
    if (!areRelationsEqual(ownershipsToPreview, defaultOwnerships)) {
      const { allSaved } = await updateOwnerships(personId, 'LegalPerson')(
        ownershipsToPreview,
        setOwnershipsToPreview,
        true,
      );
      ok = ok && allSaved;
    }
    return ok;
  };

  return (
    <LegalPersonFormContext.Provider
      value={{
        personId,
        representativesToPreview,
        setRepresentativesToPreview,
        ownershipsToPreview,
        setOwnershipsToPreview,
        saveRelations,
      }}
    >
      {children}
    </LegalPersonFormContext.Provider>
  );
};
