import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { trimStartMask } from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import { EstatePurposeFields } from 'modules/EstateFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { FormContainer, FormSection } from 'templates/FormTemplates';
import { ControlledMaskedInput } from 'components/inputs';
import EstateOwners from './EstateOwners';

const EstateGeneralStep = () => {
  const { t } = useTranslation(['common', 'formFields']);
  const { registerController } = useFormMethods<EstateFormInterface>();
  const { hideOwners, prevFormState, estateId } = useContext(EstateFormContext);

  return (
    <FormContainer>
      <FormSection container label={t('stepWizard.estate.sections.address')}>
        <AddressFieldsWithGoogle
          disableCountrySelection
          prefix="estate"
          clearButtonTranslationLabel={estateId ? 'resetAddress' : 'clearAddress'}
        />
      </FormSection>
      <FormSection container label={t('stepWizard.estate.sections.basicInformation')}>
        <EstatePurposeFields previousDestination={prevFormState.estate?.destination} />
        <ControlledMaskedInput
          xs={12}
          md={4}
          label={t('formFields:ownReference')}
          {...registerController('estate.ownReference')}
          {...trimStartMask}
        />
      </FormSection>
      {!hideOwners && (
        <FormSection container label={t('stepWizard.estate.sections.owners')}>
          <Grid item xs={12}>
            <EstateOwners />
          </Grid>
        </FormSection>
      )}
    </FormContainer>
  );
};

export default EstateGeneralStep;
