import { Stack, Typography } from '@mui/material';
import SubProductIcon from 'icons/SubProductIcon';
import { normalizeString } from 'utils/string';
import { SubProductInterface } from 'api/dossier/products/types';

const SubProductTitle = ({
  name,
  active,
  index,
}: Pick<SubProductInterface, 'name' | 'index' | 'active'>) => (
  <Stack direction="row" justifyContent="center" alignItems="center" gap={0.75}>
    <SubProductIcon
      name={name}
      fontSize="small"
      color={!active ? 'disabled' : 'secondary'}
    />
    <Typography fontWeight="bold" variant="h5">
      {index !== undefined
        ? `${normalizeString(name)} #${index + 1}`
        : normalizeString(name)}
    </Typography>
  </Stack>
);

export default SubProductTitle;
