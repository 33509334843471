import { GridRenderCellParams } from '@mui/x-data-grid';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import { PersonType } from 'api/person/types';

export const PersonTypeCell = <T extends { type: PersonType }>({
  row,
}: GridRenderCellParams<T>) => {
  const Icon = row.type === 'LegalPerson' ? LegalPersonIcon : NaturalPersonIcon;
  return <Icon />;
};
