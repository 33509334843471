import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GridValidRowModel, useGridApiContext } from '@mui/x-data-grid';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { SelectedRowsButtonProps } from './types';

const SelectedRowsButton = <TRow extends GridValidRowModel>({
  rowSelection: {
    handleSelectedRows,
    buttonRole = 'unsafe',
    buttonLabel = 'header.deleteSelected',
  },
  updateRows,
}: SelectedRowsButtonProps<TRow>) => {
  const { t } = useTranslation('table');
  const apiRef = useGridApiContext();
  const selectedRowsIds = apiRef.current.state.rowSelection;

  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const updateTable = async () => {
    apiRef.current.setRowSelectionModel([]);
    await updateRows();
  };

  const onClick = () => {
    const model = apiRef.current.state.rows.dataRowIdToModelLookup;
    const rows = selectedRowsIds.map((id) => model[id]) as TRow[];

    handleSelectedRows(selectedRowsIds, {
      rows,
      setUpAndOpenConfirmationModal,
      updateRows: updateTable,
    });
  };

  return (
    <Button onClick={onClick} role={buttonRole} disabled={!selectedRowsIds.length}>
      {t(buttonLabel)}
    </Button>
  );
};

export default SelectedRowsButton;
