import { capitalizeMask, trimMask } from 'formData/utils';
import { isCityBrussel, isCountryBelgium } from 'hooks/useAddressFields/utils';
import useFormMethods from 'hooks/useFormMethods';
import { HandleBlurChange } from 'types/handleChange';
import { getInputLabel } from 'utils/missingFields';
import { AddressFormInterface } from 'api/common/types';
import {
  ControlledMaskedInput,
  ControlledNumberInput,
  ControlledTextInput,
} from 'components/inputs';
import BelgiumPostCodeAndCity from './BelgiumPostCodeAndCity';
import CountryField from './CountryField';
import { AddressRawFieldsProps } from './types';

const OPTIONAL_FIELDS: (keyof AddressFormInterface)[] = ['apartmentNumber', 'extension'];

const AddressRawFields = ({
  prefix,
  isMissingFieldsModal,
  disableCountrySelection,
  onCountryChange,
  fetchStreetTranslation,
  disableFields,
  ...belgiumPostCodeAndCityProps
}: AddressRawFieldsProps) => {
  const { registerController, watch, setValue } = useFormMethods<{
    address: AddressFormInterface;
  }>(prefix);

  const registerInput = (name: keyof AddressFormInterface) => {
    const isRequired = isMissingFieldsModal && !OPTIONAL_FIELDS.includes(name);
    const label = getInputLabel(`address.${name}`, isRequired);
    return {
      label,
      labelVariant: isMissingFieldsModal ? 'separate' : 'float',
      disabled: disableFields,
      ...registerController(`address.${name}`),
    } as const;
  };

  const isBelgium = isCountryBelgium(watch('address.country'));
  const isBrussel = isCityBrussel(watch('address.city'));

  const updateStreetTranslation: HandleBlurChange = async (event) => {
    if (isBrussel) {
      const value = event.target.value;
      const streetTranslation = await fetchStreetTranslation(value);
      setValue('address.streetTranslation', streetTranslation);
    }
  };

  return (
    <>
      <ControlledMaskedInput
        xs={12}
        md={6}
        {...registerInput('street')}
        {...capitalizeMask}
        onBlur={updateStreetTranslation}
      />
      <ControlledNumberInput xs={4} md={2} {...registerInput('houseNumber')} />
      <ControlledTextInput xs={4} md={2} {...registerInput('extension')} />
      <ControlledMaskedInput
        xs={4}
        md={2}
        {...registerInput('apartmentNumber')}
        {...trimMask}
      />
      {isBelgium && isBrussel && (
        <ControlledMaskedInput
          xs={12}
          {...registerInput('streetTranslation')}
          {...capitalizeMask}
        />
      )}
      <CountryField
        prefix={prefix}
        disableCountrySelection={disableCountrySelection || disableFields}
        isMissingFieldsModal={isMissingFieldsModal}
        onCountryChange={onCountryChange}
        updatePostCodeAndCityParams={
          belgiumPostCodeAndCityProps.updatePostCodeAndCityParams
        }
      />
      {isBelgium ? (
        <BelgiumPostCodeAndCity
          prefix={prefix}
          isMissingFieldsModal={isMissingFieldsModal}
          disableFields={disableFields}
          {...belgiumPostCodeAndCityProps}
        />
      ) : (
        <>
          <ControlledTextInput xs={6} md={3} {...registerInput('postCode')} />
          <ControlledMaskedInput
            xs={6}
            md={3}
            {...registerInput('city')}
            {...capitalizeMask}
          />
        </>
      )}
    </>
  );
};

export default AddressRawFields;
