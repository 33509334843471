import { SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { saveDocument } from 'hooks/useFileUpload/utils';
import { AddendumSteps } from 'modules/ProductRentalContract/RegisterAddendum/static-data';
import { UseRegisterAddendumStepInterface } from 'modules/ProductRentalContract/RegisterAddendum/types';
import { StepInterface } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { attachDossierTypeDocument } from 'api/dossier/requests';
import { UploadAddendumStep } from './UploadAddendumStep';

export const useUploadAddendum = ({
  onClose,
  setStepErrors,
  dossierId,
}: UseRegisterAddendumStepInterface): StepInterface => {
  const { t } = useTranslation();
  const { trigger, handleSubmit, formState } = useFormContext<RegisterAddendumForm>();

  const navigate = (changeStep: () => void) => async () => {
    const isValid = await trigger('uploadedAddendum', { shouldFocus: true });
    setStepErrors((prev) => ({ ...prev, [AddendumSteps.uploadedAddendum]: !isValid }));
    if (isValid) {
      changeStep();
    }
  };

  useUpdateEffect(() => {
    const isValid = !Object.keys(formState.errors.uploadedAddendum ?? {}).length;
    setStepErrors((prev) => ({ ...prev, [AddendumSteps.uploadedAddendum]: !isValid }));
  }, [Object.keys(formState.errors.uploadedAddendum ?? {}).length]);

  const onSave: SubmitHandler<RegisterAddendumForm> = async ({
    uploadedAddendum: { addendumFile, documentName, addendumSigningDate },
  }) => {
    if (addendumFile && documentName) {
      const { status, documentId } = await saveDocument({
        scope: 'dossier',
        date: addendumSigningDate,
        files: [addendumFile],
        documentName,
        documentType: 'Addendum',
        entityId: dossierId,
      });
      if (status === 'SAVED') {
        await attachDossierTypeDocument(dossierId, documentId, {
          attached: true,
        });
      }
    }
  };

  const onError: SubmitErrorHandler<RegisterAddendumForm> = (errors) => {
    setStepErrors({
      [AddendumSteps.rentalConditions]: !!errors.rentalConditions,
      [AddendumSteps.landlords]: !!errors.landlords,
      [AddendumSteps.tenants]: !!errors.tenants,
      [AddendumSteps.uploadedAddendum]: !!errors.uploadedAddendum,
    });
  };

  return {
    Component: <UploadAddendumStep />,
    cancelButton: {
      onClick: onClose,
    },
    backButton: { changeStepManually: true, onClick: navigate },
    labelButton: { changeStepManually: true, onClick: navigate },
    submitButton: {
      label: t('request'),
      onClick: handleSubmit(onSave, onError),
      saving: false,
    },
  };
};
