import { CustomDateOptions } from 'types/yup';
import yup from 'utils/yup';

export const entryDateOptions: CustomDateOptions = {
  min: ({ contractSigningDate }) => ({
    key: 'entryDateBeforeSigningDate',
    date: Date.parse(contractSigningDate),
  }),
  max: ({ endDate }) => ({
    key: 'entryDateAfterEndDate',
    date: Date.parse(endDate),
  }),
  maxFutureRange: { months: 6 },
  maxPastRange: { years: 20 },
};

const entryDateSchema = yup
  .string()
  .label('entryDate')
  .nullable()
  .customDate(entryDateOptions);

export default entryDateSchema;
