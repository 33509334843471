import { SelectOption } from 'components/inputs/Select/types';
import { FireInsuranceSelectorOption } from './types';

const values: FireInsuranceSelectorOption[] = ['yes', 'no', 'none'];

export const fireInsuranceSelectorOptions: SelectOption<FireInsuranceSelectorOption>[] =
  values.map((value) => ({
    label: value,
    value: value,
    translationKey: `fireInsuranceSelectors.${value}`,
  }));
