import { BelgiumCountry, BrusselCity } from 'formData/common/address/static-data';
import {
  createPostCodeAndCityId,
  getCityFromId,
  getPostCodeFromId,
  isPostCodeOrCitySavedAsId,
} from 'formData/common/address/utils';
import getLng from 'utils/getLng';
import { getObjectKeys } from 'utils/object';
import { testStringToJSON } from 'utils/string';
import { AddressFormInterface, TranslationFieldInterface } from 'api/common/types';
import { Country, PostCodesWithCities } from 'api/config/types';
import { SelectOption } from 'components/inputs/Select/types';
import { DEFAULT_PARAMS } from './static-data';
import { PostCodeAndCityOption } from './types';

export const translateCity = (city: string): string =>
  testStringToJSON(city) ? JSON.parse(city)[getLng()] : city;

export const createCityOption = ({
  city,
  postCode,
}: PostCodesWithCities): PostCodeAndCityOption => ({
  label: postCode && city ? `${translateCity(city)}, ${postCode}` : translateCity(city),
  value: createPostCodeAndCityId(postCode, city),
  postCode,
  city,
});

export const createPostCodeOption = ({
  city,
  postCode,
}: PostCodesWithCities): PostCodeAndCityOption => ({
  label: city && postCode ? `${postCode}, ${translateCity(city)}` : postCode,
  value: createPostCodeAndCityId(postCode, city),
  postCode,
  city,
});

export const createCountryOption = (
  country: Country,
): SelectOption<string, { country: Country }> => ({
  label: country.name[getLng()],
  value: JSON.stringify(country.name),
  country,
});

export const isCountryBelgium = (country: string | TranslationFieldInterface) => {
  if (!country) {
    return false;
  }
  if (typeof country === 'object') {
    return country[getLng()] === BelgiumCountry.name[getLng()];
  }
  if (testStringToJSON(country)) {
    return country === JSON.stringify(BelgiumCountry.name);
  }
  return getObjectKeys(BelgiumCountry.name).some(
    (lng) => BelgiumCountry.name[lng] === country,
  );
};

export const isCityBrussel = (city: string) => {
  if (!city) {
    return false;
  }
  if (isPostCodeOrCitySavedAsId(city)) {
    return getCityFromId(city) === JSON.stringify(BrusselCity);
  }
  if (testStringToJSON(city)) {
    return city === JSON.stringify(BrusselCity);
  }
  return getObjectKeys(BrusselCity).some((lng) => BrusselCity[lng] === city);
};

export const getInitPostCodeAndCityParams = (
  defaultAddress: AddressFormInterface | undefined,
) => {
  const { country = '', city = '', postCode = '' } = defaultAddress ?? {};
  if (isCountryBelgium(country)) {
    return {
      city: translateCity(getCityFromId(city)),
      postCode: getPostCodeFromId(postCode),
    };
  }
  return DEFAULT_PARAMS;
};
