import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import CardRow from 'templates/CardRow';
import { NaturalPersonData } from 'api/person/natural/types';

const TaxResidenceValue = ({
  taxResidenceBelgium,
  taxResidences,
}: Pick<NaturalPersonData, 'taxResidenceBelgium' | 'taxResidences'>) => {
  const { t } = useTranslation(['enums', 'formFields']);

  if (taxResidenceBelgium) {
    return <>{t('taxResidences.Belgium')}</>;
  }
  if (!taxResidences.length) {
    return <>{t('taxResidences.NotBelgium')}</>;
  }

  return (
    <Stack>
      {taxResidences.map(({ taxResidenceCountry, taxIdentificationNumber }, index) => (
        <Typography
          key={index}
          fontWeight={600}
        >{`${taxResidenceCountry} ${taxIdentificationNumber}`}</Typography>
      ))}
    </Stack>
  );
};

const TaxResidenceRow = (
  person: Pick<NaturalPersonData, 'taxResidenceBelgium' | 'taxResidences'>,
) => {
  const { t } = useTranslation('formFields');

  if (person.taxResidenceBelgium === null) {
    return null;
  }

  return (
    <CardRow
      title={person.taxResidences.length ? t('taxResidences') : t('taxResidenceBelgium')}
      value={<TaxResidenceValue {...person} />}
    />
  );
};

export default TaxResidenceRow;
