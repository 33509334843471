import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Box, CardActions, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { ProductContext } from 'contexts/product/productContext';
import { DeleteIcon } from 'icons';
import { apiUrl } from 'api/const';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import ProductDocumentsAction from './ProductDocumentsAction';
import { ProductCardFooterProps } from './types';

export const ProductCardFooter = ({
  deletable,
  missingDocuments,
}: ProductCardFooterProps) => {
  const { productName, productId } = useContext(ProductContext);
  const { id: dossierId, fetchRentalDossier } = useOutletContext<DossierOutletContext>();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const { t } = useTranslation();

  const onDelete = () => {
    setUpAndOpenConfirmationModal({
      translationKey: 'product',
      translationValues: { name: productName },
      url: apiUrl.product(dossierId, productId),
      onSuccess: fetchRentalDossier,
    });
  };

  return (
    <CardActions sx={{ justifyContent: 'space-between' }}>
      <Typography
        component={CustomLink}
        to={productId}
        sx={({ mixins }) => mixins.link}
        fontWeight={600}
        py={1}
        px={2}
      >
        {t('productCard.viewDetails')}
      </Typography>
      <Box display="flex" gap={1}>
        {!!missingDocuments?.length && (
          <ProductDocumentsAction missingDocuments={missingDocuments} />
        )}
        <TooltipIconButton
          onClick={onDelete}
          tooltipTitle="Delete"
          Icon={<DeleteIcon />}
          disabled={!deletable}
        />
      </Box>
    </CardActions>
  );
};
