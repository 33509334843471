import { NaturalPersonFieldNames, NaturalPersonSection } from './types';

export const NATURAL_PERSON_FIELDS_BY_SECTION: Record<
  NaturalPersonSection,
  NaturalPersonFieldNames[]
> = {
  general: ['name', 'firstName', 'givenName', 'sex', 'email', 'phone', 'language'],
  details: [
    'nationality',
    'nationalRegisterNumber',
    'dateOfBirth',
    'placeOfBirth',
    'iban',
    'bic',
    'isPoliticallyExposedPerson',
    'taxResidenceBelgium',
    'taxResidences',
  ],
};

export const NATIONAL_REGISTER_NUMBER_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
];
