import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListSubheader, Stack } from '@mui/material';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import hasRole from 'utils/hasRole';
import ImpersonatedBusinessNav from './ImpersonatedBusinessNav';
import NavItem from './NavItem';
import { navListStyles } from './styles';
import { getFaqNavItems, getNavItems } from './utils';

interface Props {
  onNavItem: () => void;
  isSidebarExpanded: boolean;
  forFaq?: boolean;
}

const Navigation = ({ onNavItem, isSidebarExpanded, forFaq }: Props) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'aside' });
  const [selected, setSelected] = useState('');
  const { rentalDossierChecklist } = useContext(ConfigContext);
  const { isFeatureActive } = useContext(FeatureFlagsContext);

  const select = (label: string) => () => {
    setSelected(label);
    onNavItem();
  };

  const navItems = useMemo(
    () =>
      forFaq ? getFaqNavItems() : getNavItems(rentalDossierChecklist, isFeatureActive),
    [rentalDossierChecklist, forFaq, isFeatureActive],
  );

  return (
    <Stack component="nav" sx={navListStyles}>
      {(hasRole('SuperAdmin') || hasRole('Admin')) && (
        <ImpersonatedBusinessNav
          isSidebarExpanded={isSidebarExpanded}
          select={select}
          selected={selected}
        />
      )}
      {navItems.map(({ subheader, items }) => (
        <List
          key={subheader}
          subheader={
            !subheader ? null : (
              <ListSubheader
                title={i18n.language === 'cimode' ? t(`subheader.${subheader}`) : ''}
                sx={{ opacity: isSidebarExpanded ? 1 : 0 }}
              >
                {t(`subheader.${subheader}`)}
              </ListSubheader>
            )
          }
        >
          <Box>
            {items.map(({ to, label, onClick, ...props }) => (
              <NavItem
                key={`${label}-${to}`}
                to={to}
                label={t(`items.${label}`, label)}
                isSelected={selected === label}
                select={onClick || select(label)}
                isSidebarExpanded={isSidebarExpanded}
                resetSelection={select('')}
                forFaq={forFaq}
                {...props}
              />
            ))}
          </Box>
        </List>
      ))}
    </Stack>
  );
};

export default Navigation;
