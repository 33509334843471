import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressInterface } from 'api/common/types';
import CardRow from './CardRow';
import RowValues from './RowValues';
import { groupAddressValues } from './utils';

const AddressRow = ({
  address,
  testId,
}: {
  address: AddressInterface;
  testId?: string;
}) => {
  const { t, i18n } = useTranslation('formFields');
  const values = useMemo(() => groupAddressValues(address), [i18n.language]);

  if (values.primary || values.secondary) {
    return (
      <CardRow
        title={t('address.self')}
        value={<RowValues {...values} />}
        testId={testId}
      />
    );
  }
  return null;
};

export default AddressRow;
