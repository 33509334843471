import { yup } from 'utils/yup/yup';

const endDateSchema = yup
  .string()
  .label('endDate')
  .nullable()
  .customDate({
    min: ({ entryDate }) => ({
      key: 'endDateBeforeEntryDate',
      date: Date.parse(entryDate),
    }),
  });

export default endDateSchema;
