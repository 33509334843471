import { Box } from '@mui/material';
import LabelWithTooltip from 'components/LabelWithTooltip';
import { InputLabelWrapperProps } from './types';

const InputLabelWrapper = ({ label, labelTooltip, children }: InputLabelWrapperProps) => (
  <Box display="flex" gap={2} alignItems="center">
    <Box flex={1}>
      <LabelWithTooltip label={label} tooltip={labelTooltip} />
    </Box>
    <Box flex={1}>{children}</Box>
  </Box>
);

export default InputLabelWrapper;
