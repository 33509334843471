import { languageSchema } from 'formData/common/schema';
import yup from 'utils/yup';

export const faqDemoSchema = yup.object({
  section: yup.string().required(),
  language: languageSchema.required(),
  name: yup.string().label('name').required(),
  howdyGoLink: yup
    .string()
    .url()
    .when({
      is: (value?: string) => value && !value.includes('embed'),
      then: (schema) => schema.invalid(),
    })
    .label('howdyGoLink')
    .required(),
  thumbnail: yup.string().label('thumbnail').required(),
});
