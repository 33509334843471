import { createFilterOptions } from '@mui/material/useAutocomplete';
import i18n from 'i18n';
import { GetFilterOptions, SearchInputOption, SearchInputProps } from './types';

export const getFilterOptions: GetFilterOptions =
  ({ config, shouldFilter, creatable, loading }) =>
  (options, params) => {
    let filtered = [...options];

    if (shouldFilter) {
      filtered = createFilterOptions<SearchInputOption>(config)(options, params).filter(
        ({ hidden }) => !hidden,
      );
    }

    if (!loading && creatable) {
      const { inputValue } = params;
      const isExisting = options.some(
        (option) => inputValue === option.label || inputValue === option.value,
      );
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          value: inputValue,
          label: i18n.t('selectInput.addNewText', {
            input: inputValue,
            interpolation: { escapeValue: false },
          }),
          created: true,
        });
      }
    }

    return filtered;
  };

export const getInitOption = <T extends object = object>(
  options: SearchInputOption<T>[],
  value: SearchInputProps['value'],
): SearchInputOption<T> | null => {
  const option = options.find((option) => option.value === value);
  return option ?? null;
};
