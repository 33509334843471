import { StringSchema } from 'yup';

export function exactLength(this: StringSchema, length: number) {
  return this.test('exact-length', (value, { createError }) => {
    if (!value && this.spec.nullable) {
      return true;
    }

    const error = createError({
      message: ({ path, label = path }) => ({
        key: 'string.exactLength',
        values: { field: label, count: length },
      }),
    });

    return value?.length !== length ? error : true;
  });
}
