import { Box } from '@mui/material';
import { PersonIcon } from 'icons';
import { AffiliatedPersonInterface } from 'api/common/types';
import CardRow from '../CardRow';
import PersonContactData from './AffiliatedPersonContactData';
import PersonLabel from './AffiliatedPersonLabel';

const AffiliatedPersonRow = (person: AffiliatedPersonInterface) => (
  <CardRow
    title={
      <Box display="flex" alignItems="center" gap={1}>
        <PersonIcon personType={person.type} color="primary" />
        <PersonLabel {...person} />
      </Box>
    }
    value={<PersonContactData {...person} />}
  />
);

export default AffiliatedPersonRow;
