import getLng from 'utils/getLng';
import api from 'api';
import { apiUrl } from 'api/const';
import { UrlParams } from 'api/types';
import { NotificationsSummary, ProductNotificationList } from './types';

export const getNotificationsSummary = () =>
  api.get<NotificationsSummary>(apiUrl.notificationsSummary);

export const getNotifications = (params: UrlParams) =>
  api.get<ProductNotificationList>(apiUrl.notifications({ ...params, lng: getLng() }));

export const clearNotifications = (data: { ids: string[] }) =>
  api.post(apiUrl.notificationsClear, data);

export const clearAllNotifications = (data: { isActionRequired: boolean }) =>
  api.post(apiUrl.notificationsClearAll, data);

export const deleteNotifications = (params: UrlParams) =>
  api.delete<void>(apiUrl.notifications(params));
