import { Box, CircularProgress, IconButton } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { ProcessedFileStatus } from 'hooks/useFileUpload/types';
import { CheckIcon, DeleteIcon, ErrorIcon, WarningIcon } from 'icons';

interface DocumentDeletionProps {
  isDeletingDisabled: boolean;
  onDelete: () => void;
}

const DocumentDeletion = ({ isDeletingDisabled, onDelete }: DocumentDeletionProps) => (
  <IconButton
    disabled={isDeletingDisabled}
    onClick={onDelete}
    sx={{ justifySelf: 'flex-end' }}
  >
    <DeleteIcon />
  </IconButton>
);

interface Props extends DocumentDeletionProps {
  status: ProcessedFileStatus;
  message?: string;
}

const DocumentAction = ({ status, message, ...deletionProps }: Props) => {
  switch (status) {
    case 'DRAFT':
    case 'READY':
      return <DocumentDeletion {...deletionProps} />;
    case 'PENDING':
      return (
        <Box pr={1} pt={0.5}>
          <CircularProgress size={24} />
        </Box>
      );
    case 'SAVED':
      return (
        <Box pr={1} pt={0.5}>
          <CheckIcon color="success" />
        </Box>
      );
    case 'WARNING':
    case 'ERROR':
      return (
        <Box display="flex" justifyContent="center" gap={0.5}>
          <IconTooltip title={message} sx={{ pt: 0.7 }}>
            {status === 'ERROR' ? (
              <ErrorIcon color="error" />
            ) : (
              <WarningIcon color="warning" />
            )}
          </IconTooltip>
          <DocumentDeletion {...deletionProps} />
        </Box>
      );
    default:
      return null;
  }
};

export default DocumentAction;
