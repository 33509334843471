import { Box } from '@mui/material';
import { DossierPermissions } from 'consts/permissions';
import routes from 'router/routes';
import Table from 'templates/Table';
import hasPermission from 'utils/hasPermission';
import { getDossiers } from 'api/dossier/requests';
import AddNewButton from 'components/AddNewButton';
import PageTitle from 'components/PageTitle';
import { dossiersColumns, getDossiersActions, getLinkToDossier } from './static-data';

const DossiersList = () => {
  const addable = hasPermission(DossierPermissions.Write);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle page="dossiers" />
        {addable && <AddNewButton translationKey="dossier" to={routes.newDossier} />}
      </Box>
      <Table
        tKey="dossiers"
        get={getDossiers}
        withUrlSearchParams
        columns={dossiersColumns}
        getActions={getDossiersActions}
        rowRedirectionLink={getLinkToDossier}
      />
    </Box>
  );
};

export default DossiersList;
