import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { trimStartMask } from 'formData/utils';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { ControlledMaskedInput } from 'components/inputs';

const OwnReferenceInput = () => {
  const { t } = useTranslation('formFields');
  const { control, formState } = useFormContext<DossierFormInterface>();

  return (
    <ControlledMaskedInput
      control={control}
      error={formState.errors.ownReference}
      name="ownReference"
      label={t('ownReference')}
      {...trimStartMask}
    />
  );
};

export default OwnReferenceInput;
