import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRouteMatch } from 'utils/route';
import { LoaderData, LoadersContextInterface } from './types';
import { shouldClearLoader } from './utils';

const LoadersContext = createContext<LoadersContextInterface>({
  loaderData: null,
  setLoaderData: () => {},
});

const LoadersProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<LoaderData | null>(null);

  const setLoaderData = (data: LoaderData) => {
    setData(data);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    const routeMatch = getRouteMatch(pathname);
    if (shouldClearLoader(data?.scope, routeMatch)) {
      setData(null);
    }
  }, [pathname]);

  return (
    <LoadersContext.Provider value={{ loaderData: data, setLoaderData }}>
      {children}
    </LoadersContext.Provider>
  );
};

export { LoadersContext, LoadersProvider };
