import { Box, Divider, Stack } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import { SetState } from 'types/setState';
import RelationPreviewItem from './RelationPreviewItem';
import { RelationData } from './types';

interface Props<T extends object> {
  relationsToPreview: RelationData<T>[];
  setRelationsToPreview?: SetState<RelationData<T>[]>;
  isDivider?: boolean;
  onRemove?: (relation: RelationData<T>, index: number) => void;
  withPrompt?: boolean;
  showIcons?: boolean;
  withLink?: boolean;
  removable?: boolean;
}

const RelationPreviewList = <T extends object>({
  relationsToPreview,
  setRelationsToPreview,
  isDivider,
  onRemove,
  withPrompt = false,
  withLink = true,
  removable = true,
  showIcons,
}: Props<T>) => {
  const handleRemove = (index: number) => async (data: RelationData<T>) => {
    if (onRemove) {
      onRemove(data, index);
    } else if (!setRelationsToPreview) {
      return;
    } else if (data.isSaved) {
      setRelationsToPreview((prev) =>
        prev.map((relation) =>
          relation.id === data.id ? { ...relation, toDelete: true } : relation,
        ),
      );
    } else {
      setRelationsToPreview((prev) => prev.filter(({ id }) => id !== data.id));
    }
  };

  usePrompt({ when: withPrompt && !!relationsToPreview.length, id: 'relations' });

  return (
    <>
      <Stack paddingX={2.5}>
        <span data-testid="relation-list">
          {relationsToPreview
            .filter(({ toDelete }) => !toDelete)
            .map((relation, index) => (
              <RelationPreviewItem
                key={relation.id}
                relation={relation}
                removable={removable && relation.deletable}
                notRemovableReason={relation.notDeletableReason}
                handleRemove={handleRemove(index)}
                showIcon={showIcons}
                withLink={withLink}
              />
            ))}
        </span>
      </Stack>
      {!!relationsToPreview.length && isDivider && (
        <Box pt={1}>
          <Divider />
        </Box>
      )}
    </>
  );
};

export default RelationPreviewList;
