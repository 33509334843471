import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { SortDirection } from 'utils/sortUtils/types';
import { ProductName } from 'api/dossier/products/types';
import { LanguageType, PersonType } from 'api/person/types';

const languagesOrder: LanguageType[] = ['Dutch', 'French', 'English'];

export const languageOptions: TranslatedOption<LanguageType>[] = getObjectKeys(
  namespaces.enums.language,
)
  .sort((a, b) => languagesOrder.indexOf(a) - languagesOrder.indexOf(b))
  .map((value) => ({
    value,
    label: value,
    translationKey: `language.${value}`,
  }));

export const documentLanguageOptions: TranslatedOption<'Dutch' | 'French' | 'German'>[] =
  getObjectKeys(namespaces.enums.documentLanguage).map((value) => ({
    value,
    label: value,
    translationKey: `documentLanguage.${value}`,
  }));

export const personTypeOptions: TranslatedOption<PersonType>[] = getObjectKeys(
  namespaces.enums.personTypes,
).map((type) => ({
  value: type,
  label: type,
  translationKey: `personTypes.${type}`,
}));

export const productOptions: TranslatedOption<ProductName>[] = getObjectKeys(
  namespaces.dossierProducts,
).map((name) => ({
  value: name,
  label: name,
  translationKey: `dossierProducts:${name}.name`,
}));

export const sortTypeOptions: TranslatedOption<SortDirection>[] = getObjectKeys(
  namespaces.enums.sortType,
).map((sortType) => ({
  value: sortType === 'Newest' ? 'desc' : 'asc',
  label: sortType,
  translationKey: `enums:sortType.${sortType}`,
}));
