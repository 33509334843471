import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import Button from 'atoms/Button';
import {
  useNotificationsTabHandlers,
  useNotificationsTabState,
} from 'hooks/useNotifications';
import { NotificationFooterProps } from './types';

const NotificationsFooter = ({
  activeTab,
  closeNotifications,
  fetchNotificationsSummary,
  notifications,
}: NotificationFooterProps) => {
  const { t } = useTranslation();
  const { isViewAllNotificationsDisabled } = useNotificationsTabState(activeTab);
  const { handleClearAllNotifications, handleViewAllNotifications } =
    useNotificationsTabHandlers(activeTab, fetchNotificationsSummary, closeNotifications);

  return (
    <Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" gap={1} sx={{ px: 2, py: 1 }}>
        <Button
          variant="outlined"
          onClick={handleClearAllNotifications}
          disabled={!notifications.length}
          fullWidth
        >
          {t('tabs.notificationActions.clearAll')}
        </Button>
        <Button
          variant="outlined"
          disabled={isViewAllNotificationsDisabled}
          onClick={handleViewAllNotifications}
          fullWidth
        >
          {t('tabs.notificationActions.viewAll')}
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationsFooter;
