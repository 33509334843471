import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useEstateFormDefaultValues from 'hooks/useEstateFormDefaultValues';
import useLoader from 'hooks/useLoader';
import EstateForm from 'modules/EstateForm';
import { getEstateDefaultValues } from 'modules/EstateView/utils';
import FullPageCard from 'templates/FullPageCard';
import { getEstate } from 'api/estate/requests';

export const AddNewEstate = () => {
  const { state } = useLocation();
  const estateId = useMemo(() => (state?.estateId ?? null) as string | null, []);

  const { loading, defaultValues } = useEstateFormDefaultValues(estateId, !!estateId);

  const { Loader, data } = useLoader({
    id: estateId,
    scope: 'estate',
    fetch: getEstate,
    withContext: false,
  });

  return (
    <Loader loadingAdditionalData={loading}>
      <FullPageCard>
        <EstateForm
          defaultValues={
            data ? { estate: getEstateDefaultValues(data), ...defaultValues } : undefined
          }
          isCopyMode={!!estateId}
        />
      </FullPageCard>
    </Loader>
  );
};
