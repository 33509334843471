export const getObjectPaths = (obj: object, prefix?: string) => {
  let paths: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    const path = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string') {
      paths.push(path);
    } else {
      const nestedPaths = getObjectPaths(value, path);
      paths = paths.concat(nestedPaths);
    }
  });
  return paths;
};
