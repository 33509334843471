import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { PageCardHeaderTitleProps } from '../types';

const PageCardHeaderTitle = ({
  tKey,
  endIcon,
  startIcon,
  translationValues,
  title,
  subtitle,
  variant,
  size,
}: PageCardHeaderTitleProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'pageCard' });

  return (
    <Box flex={1} display="flex" alignItems="center" gap={1.5}>
      {startIcon}
      <Stack>
        <Typography
          data-testid="cardHeader"
          color={variant === 'dark' ? 'common.white' : 'common.black'}
          fontWeight={size === 'normal' ? 'normal' : 'bold'}
          fontSize={size === 'normal' ? 16 : 20}
        >
          {title ||
            (tKey
              ? t(`${tKey}.title`, {
                  defaultValue: '',
                  interpolation: { escapeValue: false },
                  ...translationValues,
                })
              : '')}
        </Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
      </Stack>
      {endIcon}
    </Box>
  );
};

export default PageCardHeaderTitle;
