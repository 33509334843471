import { ThemedStyle } from 'types/themedStyle';

export const selectInputStyles: ThemedStyle = {
  '& .MuiTypography-root': {
    lineHeight: 1.4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '&:has(.clearable)': {
    '& .MuiTypography-root': {
      paddingRight: 1,
    },
  },
};
