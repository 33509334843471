import { naturalPersonSchema } from 'formData/naturalPerson';
import { commonDateOfBirthSchema } from 'formData/naturalPerson/dateOfBirth/schema';
import nationalRegisterNumberSchema from 'formData/naturalPerson/nationalRegisterNumber/schema';
import placeOfBirthSchema from 'formData/naturalPerson/placeOfBirth/schema';
import { isRequiredInMissingFields } from 'formData/utils';
import { MissingField } from 'api/missingData/types';

export const naturalPersonMissingDataSchema = naturalPersonSchema.shape(
  {
    dateOfBirth: commonDateOfBirthSchema.when(
      ['missingFields', '$productName', 'placeOfBirth'],
      {
        is: (
          missingFields: MissingField[] = [],
          productName: string | undefined,
          placeOfBirth: string | null,
        ) =>
          isRequiredInMissingFields(missingFields, 'dateOfBirth') &&
          productName === 'RentalDeposit' &&
          !!placeOfBirth,
        then: (schema) => schema.required(),
      },
    ),
    placeOfBirth: placeOfBirthSchema.when(
      ['missingFields', '$productName', 'dateOfBirth'],
      {
        is: (
          missingFields: MissingField[] = [],
          productName: string | undefined,
          dateOfBirth: string | null,
        ) =>
          isRequiredInMissingFields(missingFields, 'placeOfBirth') &&
          productName === 'RentalDeposit' &&
          !!dateOfBirth,
        then: (schema) => schema.required(),
      },
    ),
    nationalRegisterNumber: nationalRegisterNumberSchema.when(
      ['missingFields', 'dateOfBirth', 'placeOfBirth'],
      {
        is: (
          missingFields: MissingField[] = [],
          dateOfBirth: string | null,
          placeOfBirth: string | null,
        ) =>
          isRequiredInMissingFields(missingFields, 'placeOfBirth') &&
          isRequiredInMissingFields(missingFields, 'dateOfBirth') &&
          isRequiredInMissingFields(missingFields, 'nationalRegisterNumber') &&
          !dateOfBirth &&
          !placeOfBirth,
        then: (schema) =>
          schema.required({ key: 'nationalRegisterNumber.nrnOrPlaceAndDateOfBirth' }),
      },
    ),
  },
  [['placeOfBirth', 'dateOfBirth']],
);
