import { languageOptions } from 'formData/common/selectOptions';
import { trimStartMask } from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import AccountNumberField from 'modules/AccountNumberField';
import { findMissingField, getInputProps, getLabelVariant } from 'utils/missingFields';
import { LegalPersonFormData } from 'api/person/legal/types';
import {
  ControlledMaskedInput,
  ControlledSelect,
  ControlledVatInput,
} from 'components/inputs';
import { LEGAL_PERSON_FIELDS_BY_SECTION } from './static-data';
import { LegalPersonFieldNames, LegalPersonFieldsProps } from './types';

const LegalPersonFields = ({
  prefix,
  missingFields = [],
  section,
}: LegalPersonFieldsProps) => {
  const { registerController } = useFormMethods<LegalPersonFormData>(prefix);

  const registerInput = (name: LegalPersonFieldNames) => ({
    ...getInputProps(name, missingFields),
    ...registerController(name),
  });

  const isVisible = (name: LegalPersonFieldNames) => {
    if (missingFields.length) {
      return !!findMissingField(name, missingFields);
    }
    if (section) {
      return LEGAL_PERSON_FIELDS_BY_SECTION[section].includes(name);
    }
    return true;
  };

  return (
    <>
      {isVisible('name') && (
        <ControlledMaskedInput
          xs={12}
          sm={6}
          {...trimStartMask}
          {...registerInput('name')}
        />
      )}
      {isVisible('language') && (
        <ControlledSelect
          xs={12}
          sm={6}
          {...registerInput('language')}
          options={languageOptions}
        />
      )}
      {(isVisible('iban') || isVisible('bic')) && (
        <AccountNumberField
          prefix={prefix}
          labelVariant={getLabelVariant(missingFields)}
        />
      )}
      {isVisible('vat') && <ControlledVatInput xs={12} {...registerInput('vat')} />}
    </>
  );
};

export default LegalPersonFields;
