import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LegalPersonFormContext } from 'contexts/personForm/legalPersonFormContext';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import LegalPersonFields from 'modules/LegalPersonFields';
import {
  FormContainer,
  FormSection,
  FormSide,
  FormVerticalDivider,
} from 'templates/FormTemplates';
import { Country } from 'api/config/types';
import { LegalPersonFormData } from 'api/person/legal/types';

const LegalPersonGeneralStep = () => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext<LegalPersonFormData>();
  const { personId } = useContext(LegalPersonFormContext);

  const onCountryChange = (country?: Country) => {
    const vatValue = getValues('vat');
    if (vatValue && !vatValue.number) {
      setValue('vat', {
        ...vatValue,
        prefix: country?.vatPrefix || 'BE',
      });
    }
  };

  return (
    <FormContainer>
      <FormSide>
        <FormSection
          container
          label={t('stepWizard.legalPerson.sections.basicInformation')}
        >
          <LegalPersonFields section="general" />
        </FormSection>
      </FormSide>
      <FormVerticalDivider />
      <FormSide>
        <FormSection container label={t('stepWizard.legalPerson.sections.details')}>
          <LegalPersonFields section="details" />
        </FormSection>
      </FormSide>
      <FormSection container label={t('stepWizard.legalPerson.sections.address')}>
        <AddressFieldsWithGoogle
          onCountryChange={onCountryChange}
          clearButtonTranslationLabel={personId ? 'resetAddress' : 'clearAddress'}
        />
      </FormSection>
    </FormContainer>
  );
};

export default LegalPersonGeneralStep;
