import { format } from 'date-fns';
import { getNRNCheckNumber } from 'utils/nrn';
import yup from 'utils/yup';

export const isValidNrnAndDateOfBirth = (
  nrn: string | null,
  dateOfBirth: string | null,
): boolean => {
  if (nrn && dateOfBirth) {
    const formattedNrn = nrn.replaceAll(/[^\d]/g, '');
    if (formattedNrn.length >= 6) {
      const NRN_dateOfBirth = formattedNrn.slice(0, 6);
      const formattedDateOfBirth = format(new Date(dateOfBirth), 'yyMMdd');
      const isDateUnknown = NRN_dateOfBirth.slice(2) === '0000';

      return isDateUnknown
        ? NRN_dateOfBirth.slice(0, 2) === formattedDateOfBirth.slice(0, 2)
        : formattedDateOfBirth === NRN_dateOfBirth;
    }
  }

  return true;
};

const nationalRegisterNumberSchema = yup
  .string()
  .nullable()
  .default(null)
  .transform((value) => value || null)
  .test('invalid-NRN', (value, { createError, parent }) => {
    if (!value) {
      return true;
    }

    const plainValue = value.replaceAll(/[^\d]/g, '');
    const { dateOfBirth, sex } = parent;

    // FIRST GROUP OF SIX DIGITS - DATE OF BIRTH (yyMMdd)
    if (!isValidNrnAndDateOfBirth(plainValue, dateOfBirth)) {
      return createError({
        message: 'nationalRegisterNumber.invalidDateOfBirth',
        type: 'invalid-nrn',
      });
    }
    // SECOND GROUP OF THREE DIGITS - DAILY SERIAL NUMBER
    // even serial numbers (002-998) for women
    // odd serial numbers (001-997) for men
    if (sex && plainValue.length >= 9) {
      const NRN_sex = Number(plainValue.slice(6, 9));
      const isEven = NRN_sex % 2 === 0;

      const isValidRange = NRN_sex > 0 && NRN_sex < 999;
      const isValidSex =
        (sex === 'Female' && isEven) || (sex === 'Male' && !isEven) || sex === 'Neutral';

      if (!isValidRange || !isValidSex) {
        return createError({
          message: 'nationalRegisterNumber.invalidSex',
          type: 'invalid-sex',
        });
      }
    }

    if (plainValue.length < 11) {
      return createError({
        message: 'nationalRegisterNumber.invalid',
        type: 'invalid-length',
      });
    }

    // THIRD GROUP OF TWO DIGITS - CHECK NUMBER
    const checkNumber = Number(plainValue.slice(-2));
    const before2000YearNumber = getNRNCheckNumber(plainValue);
    const afterOrIn2000YearNumber = getNRNCheckNumber(plainValue, true);

    if (checkNumber !== before2000YearNumber && checkNumber !== afterOrIn2000YearNumber) {
      return createError({
        message: 'nationalRegisterNumber.invalid',
        type: 'invalid-check-number',
      });
    }
    return true;
  });

export default nationalRegisterNumberSchema;
