import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import i18n from 'i18n';
import { ThemedStyle } from 'types/themedStyle';
import MenuItemContent from './MenuItemContent';
import { SelectInputProps } from './types';

// pt and pb is required, because py does not overwrite the default style
const sx: ThemedStyle = { pt: 1, pb: 1 };

const renderMenuItems = ({
  options,
  groupedOptions,
  loading,
  loadingText = 'default',
  noOptionsText = 'default',
}: Pick<
  SelectInputProps,
  'loading' | 'groupedOptions' | 'options' | 'loadingText' | 'noOptionsText'
>) => {
  if (loading) {
    return (
      <MenuItem sx={sx} disabled>
        {i18n.t(`selectInput.loadingText.${loadingText}`)}
      </MenuItem>
    );
  }
  if (groupedOptions && Object.keys(groupedOptions).length) {
    return Object.entries(groupedOptions).map(([group, options]) =>
      options.length
        ? [
            <ListSubheader
              key={`subheader-${group}`}
              disableSticky
              sx={{ lineHeight: 2.5 }}
            >
              {i18n.t(`selectInput.subHeader.${group}`, group)}
            </ListSubheader>,
            ...options.map((option) => (
              <MenuItem
                sx={sx}
                key={option.label}
                value={option.value}
                disabled={!!option.disabled}
              >
                <MenuItemContent {...option} />
              </MenuItem>
            )),
          ]
        : null,
    );
  }

  if (options.length && !groupedOptions) {
    return options.map((option) => (
      <MenuItem
        sx={sx}
        key={option.label}
        value={option.value}
        disabled={!!option.disabled}
      >
        <MenuItemContent {...option} />
      </MenuItem>
    ));
  }

  return (
    <MenuItem disabled>{i18n.t(`selectInput.noOptionsText.${noOptionsText}`)}</MenuItem>
  );
};

export default renderMenuItems;
