import { FieldValues } from 'react-hook-form';

export const getFormError = (path: string, errors: FieldValues) => {
  const [firstSegment, ...segments] = path.split('.');

  let next = errors?.[firstSegment];
  if (!Object.keys(errors).length || !next) {
    return undefined;
  }

  for (const segment of segments) {
    if (!next || typeof next === 'string' || typeof next === 'number') {
      return undefined;
    }
    if (Array.isArray(next) && !isNaN(Number(segment))) {
      next = next[Number(segment)];
    } else {
      next = next[segment];
    }
  }

  return next;
};
