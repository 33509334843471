import { useOutletContext } from 'react-router-dom';
import { DossierObjectDocumentsCard } from 'modules/DocumentsCard';
import { DossierDocumentsContext } from './types';

const DossierEstateDocuments = () => {
  const { estate, dossierDocuments, fetchDossierAndDossierDocuments } =
    useOutletContext<DossierDocumentsContext>();

  return (
    <DossierObjectDocumentsCard
      entityType="estate"
      entityId={estate.id}
      scope="estate"
      titleOptions={{ context: 'estate' }}
      fetchDossierAndDossierDocuments={fetchDossierAndDossierDocuments}
      showTitle={false}
      {...dossierDocuments.estate}
    />
  );
};

export default DossierEstateDocuments;
