import { useState } from 'react';
import useFormMethods from 'hooks/useFormMethods';
import { HandleBlurChange } from 'types/handleChange';
import calculateDepositAmount from 'utils/calculateDepositAmount';
import calculateEndOfRental from 'utils/calculateEndOfRental';
import calculateNumberOfMonthlyRent from 'utils/calculateNumberOfMonthlyRent';
import {
  NumberOfMonthlyRent,
  RentalConditions,
  RentalDuration,
  RentType,
} from 'api/dossier/types';

const useRentalConditionsFields = (prefix?: string) => {
  const { getValues, setValue, trigger, registerController, clearErrors } =
    useFormMethods<RentalConditions>(prefix);

  const [hiddenFields, setHiddenFields] = useState<
    Partial<Record<keyof RentalConditions, boolean>>
  >({
    durationNumberOfMonths: getValues('duration') !== 'Other',
    isTaxPayableByTenant: getValues('rentType') === 'Habitation',
    taxPayableByTenant: !getValues('isTaxPayableByTenant'),
    isVatRegimeApplicable: getValues('rentType') !== 'Commercial',
    isAdditionalVatRegimeApplicable: !getValues('isVatRegimeApplicable'),
  });

  const updateDepositAmount = (
    numberOfMonthlyRent: NumberOfMonthlyRent | null,
    rentalPriceValue: number | null,
  ) => {
    if (rentalPriceValue && numberOfMonthlyRent && numberOfMonthlyRent !== 'Other') {
      setValue('depositAmount', {
        value: calculateDepositAmount(numberOfMonthlyRent, rentalPriceValue),
        currency: getValues('rentalPrice.currency'),
      });
    }

    trigger('depositAmount', { shouldFocus: false });
  };

  const updateNumberOfMonthlyRent = (
    rentalPriceValue: number | null,
    depositValue: number | null,
  ) => {
    if (rentalPriceValue && depositValue) {
      const calculatedNumberOfMonthlyRent = calculateNumberOfMonthlyRent(
        rentalPriceValue,
        depositValue,
      );

      const numberOfMonthlyRent = getValues('numberOfMonthlyRent');
      if (calculatedNumberOfMonthlyRent !== numberOfMonthlyRent) {
        setValue('numberOfMonthlyRent', calculatedNumberOfMonthlyRent);
      }
    }
  };

  const onRentTypeChange = (rentType: RentType) => {
    setHiddenFields((prev) => ({
      ...prev,
      isTaxPayableByTenant: rentType === 'Habitation',
      taxPayableByTenant: rentType === 'Habitation' || !getValues('isTaxPayableByTenant'),
      isVatRegimeApplicable: rentType !== 'Commercial',
      isAdditionalVatRegimeApplicable:
        rentType !== 'Commercial' || !getValues('isVatRegimeApplicable'),
    }));
    setValue('rentFrequency', 'Month');

    if (rentType === 'CommonLaw' || rentType === 'Commercial') {
      setValue('numberOfMonthlyRent', 'Other');
    }
  };

  const onContractSigningDateChange = () => {
    trigger('entryDate', { shouldFocus: false });
  };

  const onEntryDateChange = (entryDate: Date | null) => {
    trigger('contractSigningDate', { shouldFocus: false });
    trigger('endDate', { shouldFocus: false });

    let endOfRental: string | null = null;
    const duration = getValues('duration');
    const numberOfMonths = getValues('durationNumberOfMonths');
    if (duration !== 'Other') {
      endOfRental = calculateEndOfRental(entryDate, duration);
    } else if (numberOfMonths !== null) {
      endOfRental = calculateEndOfRental(entryDate, numberOfMonths);
    }
    setValue('endDate', endOfRental);
  };

  const onRentalDurationChange = (duration: RentalDuration) => {
    trigger('endDate', { shouldFocus: false });
    clearErrors('durationNumberOfMonths');
    const entryDate = getValues('entryDate');
    if (entryDate) {
      const endOfRental = calculateEndOfRental(entryDate, duration);
      setValue('endDate', endOfRental);
    }
    setHiddenFields((prev) => ({
      ...prev,
      durationNumberOfMonths: duration !== 'Other',
    }));
  };

  const onDurationNumberOfMonthsBlur: HandleBlurChange = ({ target: { value } }) => {
    const numberOfMonths = value ? Number(value) : null;
    const entryDate = getValues('entryDate');
    if (entryDate && numberOfMonths !== null) {
      const endDate = calculateEndOfRental(entryDate, numberOfMonths);
      setValue('endDate', endDate);
    }
  };

  const onRentalPriceBlur: HandleBlurChange = ({ target: { value } }) => {
    const rentalPrice = value ? Number(value) : null;
    updateDepositAmount(getValues('numberOfMonthlyRent'), rentalPrice);
    if (getValues('numberOfMonthlyRent') === 'Other') {
      updateNumberOfMonthlyRent(rentalPrice, getValues('depositAmount.value'));
    }
  };

  const onNumberOfMonthlyRentChange = (numberOfMonthlyRent: NumberOfMonthlyRent) => {
    updateDepositAmount(numberOfMonthlyRent, getValues('rentalPrice.value'));
  };

  const onDepositAmountChange = (depositAmount: number | null) => {
    updateNumberOfMonthlyRent(getValues('rentalPrice.value'), depositAmount);
  };

  const onIsTaxPayableByTenantChange = (checked: boolean) => {
    setHiddenFields((prev) => ({ ...prev, taxPayableByTenant: !checked }));
  };

  const onIsVatRegimeApplicableChange = (checked: boolean) => {
    setHiddenFields((prev) => ({ ...prev, isAdditionalVatRegimeApplicable: !checked }));
    setValue('isAdditionalVatRegimeApplicable', checked);
  };

  return {
    hiddenFields,
    registerController,
    onRentTypeChange,
    onContractSigningDateChange,
    onEntryDateChange,
    onRentalDurationChange,
    onDurationNumberOfMonthsBlur,
    onRentalPriceBlur,
    onNumberOfMonthlyRentChange,
    onDepositAmountChange,
    onIsTaxPayableByTenantChange,
    onIsVatRegimeApplicableChange,
  };
};

export default useRentalConditionsFields;
