import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import AddressFields from 'modules/AddressFields';
import NaturalPersonFields from 'modules/NaturalPersonFields';
import routes from 'router/routes';
import { isTruthyValueInObject } from 'utils/object';
import transformName from 'utils/transformName';
import { MissingDataForm } from '../types';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { NaturalPersonMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getNaturalPersonsFields,
  isAnyAddressFieldMissing,
} from './utils';

const NaturalPersonMissingDataForm = ({
  id,
  index,
  fields,
}: NaturalPersonMissingDataFormProps) => {
  const {
    formState: { isDirty, dirtyFields, errors: formErrors, defaultValues },
  } = useFormContext<MissingDataForm>();

  const { t } = useTranslation(['formFields', 'modals']);

  const prefix = useMemo(() => `naturalPersons.${index}` as const, [index]);
  const errors = useMemo(
    () => formErrors.naturalPersons?.[index],
    [formErrors.naturalPersons, index],
  );

  const missingImportantFields = getMissingRequiredFields(errors, fields);

  const areNaturalPersonFieldsDirty =
    isDirty && !!isTruthyValueInObject(dirtyFields.naturalPersons?.[index]);

  const { requiredFields, notRequiredFields } = getNaturalPersonsFields(fields);
  const shouldDisplayAddressFields = isAnyAddressFieldMissing(fields);

  const personData = useMemo(
    () => defaultValues?.naturalPersons?.[index],
    [defaultValues?.naturalPersons?.[index]],
  );

  return (
    <Stack spacing={1} data-testid={id}>
      {personData && (
        <Typography fontWeight="bold">
          {personData.companyName
            ? t('modals:data.representativeLabel', {
                name: transformName(personData),
                companyName: personData.companyName,
              })
            : transformName(personData)}
        </Typography>
      )}
      <Stack spacing={1}>
        {!!requiredFields.length && (
          <NaturalPersonFields prefix={prefix} missingFields={requiredFields} />
        )}
        {shouldDisplayAddressFields && (
          <AddressFields prefix={prefix} isMissingFieldsModal />
        )}
        {!!notRequiredFields.length && (
          <NaturalPersonFields prefix={prefix} missingFields={notRequiredFields} />
        )}
        {missingImportantFields.length && areNaturalPersonFieldsDirty ? (
          <MissingRequiredFieldsError
            tKey="missingPersonRequiredFields"
            to={routes.naturalPersonTab(id).details}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default NaturalPersonMissingDataForm;
