export const sharedComposition = [
  'entrances',
  'toilets',
  'kitchens',
  'offices',
  'storages',
  'attics',
  'basements',
  'parkingPlaces',
  'gardens',
  'terraces',
] as const;

export const residentialComposition = [
  'bathrooms',
  'bedrooms',
  'sculleries',
  'livingRooms',
  'diningRooms',
  'showerRooms',
  'dressingRooms',
  'outhouses',
  'corridors',
  'garages',
  'gardenSheds',
  'porches',
  'unfurnishedAttic',
  'unfurnishedCellar',
  'unfurnishedOutbuildings',
] as const;

export const nonResidentialComposition = [
  'outdoorStorage',
  'factoryHall',
  'meetingRoom',
  'serverRoom',
  'showRoom',
  'premises',
  'lounge',
  'circulationSpace',
  'toiletBlock',
] as const;
