import { paymentSchema } from 'formData/common/schema';
import { numberSchema } from 'utils/yup';

const depositAmountSchema = paymentSchema.when(
  ['$region', 'rentType', 'rentalPrice'],
  ([region, rentType, rentalPrice], schema) => {
    const rentalPriceValue = rentalPrice?.value ?? 0;

    const max =
      rentType === 'Habitation' && region?.toLowerCase() === 'flanders'
        ? rentalPriceValue * 3
        : null;

    return schema.shape({
      value: numberSchema.label('depositAmount').when({
        is: () => !!max,
        then: (schema) => schema.max(max as number),
      }),
    });
  },
);

export default depositAmountSchema;
