import { useTranslation } from 'react-i18next';
import Button from 'atoms/Button';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { TKey } from 'i18next';
import { AddIcon } from 'icons';

interface AddNewButtonProps {
  translationKey: TKey<'common', 'addNew'>;
  to: string;
  state?: object;
  dataTestId?: string;
}

export const AddNewButton = ({
  translationKey,
  to,
  state,
  dataTestId,
}: AddNewButtonProps) => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation('common', { keyPrefix: 'addNew' });

  const handleClick = () => navigate(to, { state });

  return (
    <Button startIcon={<AddIcon />} onClick={handleClick} data-testid={dataTestId}>
      {t(`${translationKey}`)}
    </Button>
  );
};
