import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import getDocumentDateLabel from 'utils/getDocumentDateLabel';
import getFileSize from 'utils/getFileSize';
import { DocumentType } from 'api/documents/types';
import {
  ControlledDateInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/inputs';
import DocumentAction from './DocumentAction';
import { DocumentFieldsProps } from './types';

const DocumentFields = ({
  document: { status, files, id, scope, documentType, message, label },
  index,
  formMethods,
  documentActions: { updateDocument, removeDocument },
  fieldsConfig: {
    documentTypeOptions,
    disabledInputs,
    documentConfigData,
    isUploadingMissingDocuments = false,
    onFilePreview,
  },
  onDocumentTypeChange,
  onClosePreview,
}: DocumentFieldsProps) => {
  const { t } = useTranslation(['modals', 'enums', 'common']);
  const isOtherDocumentType = documentType === 'Other';
  const {
    control,
    clearErrors,
    formState: { errors },
  } = formMethods;

  const dateFieldLabel = useMemo(
    () =>
      (documentType &&
        getDocumentDateLabel(documentType, documentConfigData?.date?.type)) ??
      t('uploadDocumentsModal.fields.date'),
    [documentConfigData, documentType],
  );

  const handleRemoveDocument = () => {
    if (!isUploadingMissingDocuments && removeDocument) {
      removeDocument(id);
      onClosePreview();
    }
  };

  const handleRemoveFile = (index: number) => () => {
    if (files.length > 0) {
      const copy = [...files];
      copy.splice(index, 1);
      updateDocument(id, {
        files: copy,
        status: !copy.length ? 'DRAFT' : status,
      });
    }
    onClosePreview();
  };

  const isAnyActionDisabled = useMemo(
    () => status === 'SAVED' || status === 'PENDING',
    [status],
  );

  const handleDocumentTypeChange = async (documentType: string) => {
    onDocumentTypeChange?.(documentType as DocumentType);
    clearErrors(`documents.${index}.date`);
    updateDocument(id, {
      documentName: t(`documents:${scope}.${documentType}`, ''),
    });
  };

  return (
    <Box width="100%" px={2}>
      {label && (
        <Box pt={1} pb={0}>
          <Typography variant="caption" fontWeight="bold">
            {label}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" gap={2} pt={label ? 1.5 : 2.5}>
        <Stack gap={2} width={'100%'}>
          <ControlledSelect
            wrapWithGrid={false}
            name={`documents.${index}.documentType`}
            label={t('uploadDocumentsModal.fields.documentType')}
            control={control}
            onChange={handleDocumentTypeChange}
            options={documentTypeOptions}
            disabled={isUploadingMissingDocuments || isAnyActionDisabled}
            error={errors.documents?.[index]?.documentType}
            helperText={
              documentConfigData?.filesLimit
                ? t('common:helperText.maxNumberOfFiles', {
                    count: documentConfigData.filesLimit,
                  })
                : undefined
            }
          />
          {isOtherDocumentType && (
            <ControlledTextInput
              name={`documents.${index}.otherCustomType`}
              label={t('uploadDocumentsModal.fields.otherCustomType', 'otherCustomType')}
              control={control}
              error={errors.documents?.[index]?.otherCustomType}
            />
          )}
        </Stack>
        <DocumentAction
          status={status}
          message={message}
          onDelete={handleRemoveDocument}
          isDeletingDisabled={isUploadingMissingDocuments}
        />
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <ControlledTextInput
            xs={7}
            control={control}
            label={t(`uploadDocumentsModal.fields.documentName`)}
            name={`documents.${index}.documentName`}
            error={errors.documents?.[index]?.documentName}
            disabled={disabledInputs?.documentName}
          />
          <ControlledDateInput
            xs={5}
            control={control}
            label={dateFieldLabel}
            name={`documents.${index}.date`}
            error={errors.documents?.[index]?.date}
            disabled={disabledInputs?.date}
            disableFuture={documentConfigData?.date?.type === 'Past'}
            disablePast={documentConfigData?.date?.type === 'Future'}
          />
        </Grid>
        <Box display="flex" gap={1} pt={2} flexWrap="wrap" alignItems="center">
          {files.map((file, index) => (
            <Chip
              color="secondary"
              variant="outlined"
              disabled={isAnyActionDisabled}
              key={file.name + file.size + index}
              onDelete={handleRemoveFile(index)}
              onClick={() => onFilePreview(file)}
              component="button"
              label={
                <Stack direction="row" gap={1}>
                  <Typography variant="caption" fontWeight="bold">
                    {file.name}
                  </Typography>
                  <Typography variant="caption">{getFileSize(file.size)}</Typography>
                </Stack>
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentFields;
