import { CircularProgress } from '@mui/material';
import { ProcessedFileStatus } from 'hooks/useFileUpload/types';
import { CheckIcon, ErrorIcon } from 'icons';

export const UploadStatus = ({ status }: { status?: ProcessedFileStatus }) => {
  switch (status) {
    case 'ERROR':
      return <ErrorIcon color="error" />;
    case 'SAVED':
      return <CheckIcon color="success" data-testid="check-icon" />;
    case 'PENDING':
      return <CircularProgress size={20} />;
    default:
      return null;
  }
};
