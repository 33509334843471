import StorageKeys from 'consts/storage';
import Cookies from 'js-cookie';
import hasRole from 'utils/hasRole';
import routes from './routes';

export const redirectAdmins = () =>
  hasRole('SuperAdmin') || hasRole('Admin') ? routes.businesses : undefined;

export const redirectAdminOnlyOrNotImpersonated = () =>
  (hasRole('SuperAdmin') && !Cookies.get(StorageKeys.ImpersonatedBusinessId)) ||
  hasRole('Admin')
    ? routes.businesses
    : undefined;

export const redirectNotImpersonated = () =>
  (hasRole('SuperAdmin') || hasRole('Admin')) &&
  !Cookies.get(StorageKeys.ImpersonatedBusinessId)
    ? routes.businesses
    : undefined;
