import { useTranslation } from 'react-i18next';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import PageCardHeader from './PageCardHeader';
import { PageCardProps } from './types';

export const PageCard = ({
  tKey,
  headerProps,
  loading = false,
  blank = false,
  children,
  emptyMessageOptions,
}: PageCardProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'pageCard' });

  return (
    <Card elevation={0} sx={({ mixins }) => mixins.pageCard}>
      {!!headerProps && <PageCardHeader tKey={tKey} {...headerProps} />}
      {loading || blank ? (
        <Box display="flex" justifyContent="center" p={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography>
              {t(`${tKey}.emptyMessage`, {
                defaultValue: t('defaultEmptyMessage'),
                ...emptyMessageOptions,
              })}
            </Typography>
          )}
        </Box>
      ) : (
        children
      )}
    </Card>
  );
};
