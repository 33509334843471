import { ONLY_LETTERS_PATTERN } from 'consts/regex';
import IBAN from 'iban';
import { firstCharUpperCase } from 'utils/string';
import { MissingField } from 'api/missingData/types';

export const isRequiredInMissingFields = <T extends object>(
  fields: MissingField[],
  name: keyof T,
) => !!fields?.find((field) => field?.required && name === field?.propertyName);

const keepOnlyLettersAndBasicSigns = (value: string) =>
  [...value]
    .filter((letter, index) =>
      !index
        ? letter.match(new RegExp(`[${ONLY_LETTERS_PATTERN}]`))
        : letter.match(new RegExp(`[${ONLY_LETTERS_PATTERN}-.' ]`)),
    )
    .join('');

const filterOutNumbers = (value: string) =>
  [...value].filter((letter) => !letter.match(/\d/)).join('');

const indefiniteMaskTemplate = (s: string) => new Array(s.length).fill(/./);
const capitalizeInput = (s: string) => s && firstCharUpperCase(s).trimStart();

export const capitalizeMask = {
  mask: indefiniteMaskTemplate,
  pipe: capitalizeInput,
};

export const capitalizeMaskWithoutNumbers = {
  mask: indefiniteMaskTemplate,
  pipe: (value: string) => capitalizeInput(filterOutNumbers(value)),
};

export const capitalizeMaskWithOnlyLettersAndBasicSigns = {
  mask: indefiniteMaskTemplate,
  pipe: (value: string) => capitalizeInput(keepOnlyLettersAndBasicSigns(value)),
};

export const trimMask = {
  mask: indefiniteMaskTemplate,
  pipe: (value: string) => value && value.trim(),
};
export const trimStartMask = {
  mask: indefiniteMaskTemplate,
  pipe: (value: string) => value && value.trimStart(),
};

export const ibanMask = {
  mask: (value: string) => {
    const code = value.slice(0, 2).toUpperCase();
    const { example } = IBAN.countries[code] ?? {};
    if (example) {
      return IBAN.printFormat(example)
        .split('')
        .map((letter) => {
          if (letter.match(/[a-zA-Z0-9]/)) {
            return /[a-zA-Z0-9]/;
          }
          return letter;
        });
    }
    return new Array(value.length).fill(/./);
  },
  pipe: (value: string) => value.toUpperCase().trim(),
};
