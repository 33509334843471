import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { formatDate } from 'utils/date';
import paymentToString from 'utils/paymentToString';
import { RentalConditions } from 'api/dossier/types';
import { ProductActionInfoRow } from 'components/modals/ProductActionModal';

interface Props {
  rentalConditions: RentalConditions | null;
}

const RentalConditionsDetails = ({ rentalConditions }: Props) => {
  const { t } = useTranslation('modals');

  return rentalConditions ? (
    <Stack width="100%" gap={1}>
      {rentalConditions.entryDate && (
        <ProductActionInfoRow
          title={t('data.entryDate')}
          value={formatDate(rentalConditions.entryDate) ?? ''}
        />
      )}
      {rentalConditions.depositAmount && (
        <ProductActionInfoRow
          title={t('data.depositAmount')}
          value={paymentToString(rentalConditions.depositAmount)}
        />
      )}
    </Stack>
  ) : null;
};

export default RentalConditionsDetails;
