import { useContext, useMemo } from 'react';
import { ConfigContext } from 'contexts/config/ConfigContext';
import useFormMethods from 'hooks/useFormMethods';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { ControlledSelect } from 'components/inputs';
import { InputLabelVariant } from 'components/inputs/InputWrapper/types';
import { mapNationalities } from './utils';

interface Props {
  label: string;
  prefix: string | undefined;
  isMissingDataModal: boolean;
  labelVariant: InputLabelVariant;
}

const NationalityField = ({ isMissingDataModal, prefix, ...props }: Props) => {
  const { nationalities } = useContext(ConfigContext);

  const { getValues, setValue, trigger, registerController } =
    useFormMethods<NaturalPersonFormData>(prefix);

  const onNationalityChange = (nationality: string) => {
    setValue('nationality', nationality);

    if (!getValues('taxResidenceBelgium') && nationality === 'Belgian') {
      setValue('taxResidenceBelgium', 'Belgium');
    }
    if (
      !isMissingDataModal &&
      getValues('taxResidenceBelgium') &&
      nationality !== 'Belgian'
    ) {
      setValue('taxResidenceBelgium', null);
      setValue('taxResidences', []);
    }

    trigger('taxResidenceBelgium');
    trigger('nationalRegisterNumber');
  };

  const groupedNationalities = useMemo(
    () => ({
      main: nationalities.main.map(mapNationalities),
      other: nationalities.other.map(mapNationalities),
    }),
    [nationalities],
  );

  return (
    <ControlledSelect
      xs={12}
      md={6}
      {...registerController('nationality')}
      {...props}
      onChange={onNationalityChange}
      groupedOptions={groupedNationalities}
      options={[...groupedNationalities.main, ...groupedNationalities.other]}
    />
  );
};

export default NationalityField;
