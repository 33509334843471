import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';
import EstateDescription from './EstateDescription';

const EstateDescriptionCard = ({
  descriptions,
}: {
  descriptions: NonNullable<EstateViewInterface['data']['descriptions']>;
}) => {
  const { t } = useTranslation();

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.estate.description')} />
      <EstateDescription {...descriptions} />
    </Card>
  );
};

export default EstateDescriptionCard;
