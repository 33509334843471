import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { TabPanelProps } from './types';

export const TabPanel = ({
  children,
  value,
  index,
}: PropsWithChildren<TabPanelProps>) => (
  <Box role="tabpanel" hidden={value !== index}>
    {value === index && children}
  </Box>
);
