import { StringSchema } from 'yup';

export function startsWith(this: StringSchema, startValue: string) {
  return this.test('starts-with', (value, { createError }) => {
    const error = createError({
      message: ({ path, label = path }) => ({
        key: 'string.startsWith',
        values: { field: label, startValue },
      }),
    });
    if (!value) return true;
    return !value?.startsWith(startValue) ? error : true;
  });
}
