import { useContext } from 'react';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { clearAllNotifications } from 'api/notifications/requests';
import { ActiveNotificationTab, NotificationTabs } from './types';

const useNotificationsTabHandlers = (
  activeTab: number,
  fetchNotificationsSummary: () => Promise<void>,
  closeNotifications: () => void,
) => {
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const navigate = useCustomNavigate();

  const handleViewAllNotifications = () => {
    navigate(routes.notifications, {
      state: {
        notificationType:
          activeTab === ActiveNotificationTab.actionRequired
            ? 'actionRequired'
            : 'noAction',
      },
    });
    closeNotifications();
  };

  const clearNotifications = async () => {
    const response = await clearAllNotifications({
      isActionRequired: activeTab === ActiveNotificationTab.actionRequired,
    });
    if (response.ok && fetchNotificationsSummary) {
      await fetchNotificationsSummary();
    }
    return response;
  };

  const handleClearAllNotifications = () => {
    setUpAndOpenConfirmationModal({
      translationKey: `notificationsClearAll_${
        ActiveNotificationTab[activeTab] as NotificationTabs
      }`,
      onSubmit: clearNotifications,
      type: 'confirmation',
    });
  };

  return {
    handleViewAllNotifications,
    handleClearAllNotifications,
  };
};

export default useNotificationsTabHandlers;
