import { SvgIconProps } from '@mui/material';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import { PersonType } from 'api/person/types';

const PersonIcon = ({
  personType,
  ...props
}: SvgIconProps & { personType: PersonType }) =>
  personType === 'NaturalPerson' ? (
    <NaturalPersonIcon {...props} />
  ) : (
    <LegalPersonIcon {...props} />
  );

export default PersonIcon;
