import { useState } from 'react';
import { useTableInitFilter } from 'templates/Table/hooks';
import { TableSidebarFilterComponentProps } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import { DateInput } from 'components/inputs';

interface Props
  extends Pick<TableSidebarFilterComponentProps, 'filterName' | 'updateFilter'> {
  label?: string;
}

const DateFilter = ({ filterName, updateFilter, label }: Props) => {
  const initState = useTableInitFilter(filterName);
  const [value, setValue] = useState(initState);

  const onInputChange = (date: Date | null) => {
    const value = formatDate(date, 'yyyy-MM-dd') ?? '';
    setValue(value);
    updateFilter(filterName, value);
  };

  return <DateInput label={label} value={value} onChange={onInputChange} />;
};

export default DateFilter;
