export const mapObject = <TObject extends object, TValue>(
  object: TObject,
  getValue: (value: TObject[keyof TObject], index: number) => TValue,
) =>
  Object.entries(object)
    .map(([key, value], index) => ({
      key,
      value: getValue(value, index),
    }))
    .reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {}) as Record<
    keyof TObject,
    TValue
  >;
