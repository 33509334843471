import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import useFormMethods from 'hooks/useFormMethods';
import { EstateDestination, EstateDetailsForm } from 'api/estate/types';

const useEstateCompositionCustomFields = (estateDestination: EstateDestination) => {
  const [isCustomFieldModalOpen, setIsCustomFieldModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number>();

  const { control, getName } = useFormMethods<EstateDetailsForm>('estateDetails');

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: getName('estateComposition.additionalItems'),
  });

  const addNewCustomField = () => {
    setIsCustomFieldModalOpen(true);
  };

  const editCustomField = (index: number) => {
    setEditingIndex(index);
    setIsCustomFieldModalOpen(true);
  };

  const closeCustomFieldModal = () => {
    setIsCustomFieldModalOpen(false);
    setEditingIndex(undefined);
  };

  return {
    addNewCustomField,
    editCustomField,
    removeCustomField: remove,
    isCustomFieldModalOpen,
    customFields: fields,
    customFieldModalProps: {
      closeCustomFieldModal,
      fields,
      editingIndex,
      update,
      prepend,
      estateDestination,
    },
  };
};

export default useEstateCompositionCustomFields;
