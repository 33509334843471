import { isCityBrussel, isCountryBelgium } from 'hooks/useAddressFields/utils';
import getLng from 'utils/getLng';
import isTruthyValueInObject from 'utils/object/isTruthyValueInObject';
import { testStringToJSON } from 'utils/string';
import {
  AddressFormInterface,
  AddressInterface,
  TranslationFieldInterface,
} from 'api/common/types';
import addressSchema from './schema';

const CITY_AND_POSTCODE_SEPARATOR = '_&_';
export const createPostCodeAndCityId = (postCode: string, city: string) =>
  `${postCode}${CITY_AND_POSTCODE_SEPARATOR}${city}`;

export const isPostCodeOrCitySavedAsId = (postCodeOrCity: string) =>
  postCodeOrCity.includes(CITY_AND_POSTCODE_SEPARATOR);

export const getCityFromId = (cityValue: string): string => {
  if (isPostCodeOrCitySavedAsId(cityValue)) {
    const [_, city] = cityValue.split(CITY_AND_POSTCODE_SEPARATOR);
    return city;
  }
  return cityValue;
};

export const getPostCodeFromId = (postCodeValue: string): string => {
  if (isPostCodeOrCitySavedAsId(postCodeValue)) {
    const [postCode] = postCodeValue.split(CITY_AND_POSTCODE_SEPARATOR);
    return postCode;
  }
  return postCodeValue;
};

export const clearPostCodeAndCitiesIds = ({
  city,
  postCode,
  ...address
}: AddressFormInterface): AddressFormInterface => ({
  ...address,
  city: getCityFromId(city),
  postCode: getPostCodeFromId(postCode),
});

const getStreetTranslation = (street: TranslationFieldInterface) => {
  const lng = getLng();

  if ((lng === 'en' || lng === 'nl') && street['fr'] !== street[lng]) {
    return street['fr'];
  }
  if (lng === 'fr' && street['en'] !== street[lng]) {
    return street['en'];
  }
  return '';
};

export const formatCity = (city: string): TranslationFieldInterface =>
  testStringToJSON(city) ? JSON.parse(city) : { en: city, fr: city, nl: city };

const formatStreet = (city: string, street: string, streetTranslation?: string) => {
  if (!isCityBrussel(city)) {
    return { en: street, fr: street, nl: street };
  }

  const lng = getLng();
  const translatedStreet = streetTranslation || street;
  const isEnOrNl = lng === 'nl' || lng === 'en';
  return {
    en: isEnOrNl ? street : translatedStreet,
    fr: isEnOrNl ? translatedStreet : street,
    nl: isEnOrNl ? street : translatedStreet,
  };
};

export const getFormAddress = ({
  street,
  postCode,
  city,
  country,
  ...address
}: AddressInterface): AddressFormInterface => ({
  ...addressSchema.getDefault(),
  ...address,
  street: street[getLng()],
  streetTranslation: getStreetTranslation(street),
  postCode: isCountryBelgium(country)
    ? createPostCodeAndCityId(postCode, JSON.stringify(city))
    : postCode,
  city: isCountryBelgium(country)
    ? createPostCodeAndCityId(postCode, JSON.stringify(city))
    : city[getLng()],
  country: isTruthyValueInObject(country)
    ? JSON.stringify(country)
    : addressSchema.getDefault().country,
});

export const transformFormAddress = ({
  street,
  city,
  postCode,
  country,
  streetTranslation,
  ...address
}: AddressFormInterface): AddressInterface => ({
  ...address,
  street: formatStreet(city, street, streetTranslation),
  city: formatCity(getCityFromId(city)),
  postCode: getPostCodeFromId(postCode),
  country: country && JSON.parse(country),
});

export const isAddressIncomplete = ({
  street,
  houseNumber,
  postCode,
  city,
  country,
}: AddressInterface) => {
  if (!street.en || !houseNumber || !postCode || !city.en || !country.en) {
    return true;
  }
  return false;
};
