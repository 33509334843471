import { useState } from 'react';
import { createPostCodeAndCityId } from 'formData/common/address/utils';
import useCountryOptions from 'hooks/useCountryOptions';
import useFormMethods from 'hooks/useFormMethods';
import { getObjectEntries, getObjectKeys } from 'utils/object';
import { AddressFormInterface, TranslationFieldInterface } from 'api/common/types';
import { UseGoogleParamsProps } from './types';
import useLanguage from './useLanguage';
import { createCountryOption, isCityBrussel, isCountryBelgium } from './utils';

const useGoogleParams = ({
  prefix,
  fetchPostCodesWithCities,
  fetchStreetTranslation,
}: UseGoogleParamsProps) => {
  const [updatingAddressFields, setUpdatingAddressFields] = useState(false);

  const [countriesOptions] = useCountryOptions(createCountryOption);
  const lng = useLanguage();
  const { setValue } = useFormMethods<{ address: AddressFormInterface }>(prefix);

  const getBelgiumPostCodeAndCityId = async (postCode: string, city: string) => {
    let matchedCity: TranslationFieldInterface = { en: '', nl: '', fr: '' };
    let matchedPostCode = postCode;

    if (postCode) {
      const options = await fetchPostCodesWithCities({ postCode, city: '' });
      const option = options?.find((option) => option.city[lng] === city);

      if (option) {
        matchedCity = option.city;
      } else if (options.length === 1) {
        matchedCity = options[0].city;
      }
    } else if (city) {
      const options = await fetchPostCodesWithCities({ postCode: '', city });
      const option = options.find((option) => option.city[lng] === city);
      if (option) {
        matchedCity = option.city;
        matchedPostCode = option.postCode;
      }
    }

    if (matchedPostCode && matchedCity.en) {
      const id = createPostCodeAndCityId(matchedPostCode, JSON.stringify(matchedCity));
      return id;
    }
    return '';
  };

  const getCountryValue = (country: string) =>
    countriesOptions.find((option) =>
      getObjectKeys(option.country.name).some(
        (lng) => option.country.name[lng] === country,
      ),
    )?.value ?? '';

  const updateAddressWithGoogleParams = async ({
    city = '',
    postCode = '',
    country = '',
    ...fields
  }: Partial<AddressFormInterface>) => {
    setUpdatingAddressFields(true);
    const isBelgium = isCountryBelgium(country);

    if (fields.street && isBelgium && isCityBrussel(city)) {
      const streetTranslation = await fetchStreetTranslation(fields.street);
      setValue('address.streetTranslation', streetTranslation);
    } else {
      setValue('address.streetTranslation', undefined);
    }

    let cityValue = city;
    let postCodeValue = postCode;
    if (isBelgium) {
      const id = await getBelgiumPostCodeAndCityId(postCode, city);
      cityValue = id;
      postCodeValue = id;
    }

    getObjectEntries({
      ...fields,
      country: getCountryValue(country),
      city: cityValue,
      postCode: postCodeValue,
    }).forEach(([key, value]) => {
      setValue(`address.${key}`, value, { shouldDirty: true, shouldValidate: true });
    });

    setUpdatingAddressFields(false);
  };

  return { updateAddressWithGoogleParams, updatingAddressFields };
};

export default useGoogleParams;
