import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DossierStatusChip from 'atoms/DossierStatusChip';
import routes from 'router/routes';
import AssignedEntityCard from 'templates/AssignedEntityCard';
import {
  AssignedEstateProps,
  AssignedPersonsProps,
} from 'templates/AssignedEntityCard/types';
import { AssignedDossier, AssignedDossiersGridProps } from './types';
import { getAssignedDossierCardHeight } from './utils';

type Props = AssignedDossiersGridProps & AssignedDossier;

const AssignedDossierCard = ({
  id: dossierId,
  entityId,
  type,
  landlords,
  tenants,
  estate,
  ownReference,
  referenceNumber,
  status,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'assignedEntityCard' });

  const role = useMemo(() => {
    let role: 'Landlord' | 'Tenant' | null = null;
    if (type === 'Person') {
      if (landlords.some((landlord) => landlord.personId === entityId)) {
        role = 'Landlord';
      } else if (tenants.some((tenant) => tenant.personId === entityId)) {
        role = 'Tenant';
      }
    }
    return role;
  }, [entityId]);

  const subTitle2 = useMemo(
    () =>
      type === 'Person'
        ? t(role === 'Tenant' ? 'tenants' : 'landlords', { count: 1 })
        : null,
    [role, type],
  );

  const assignedEstate: AssignedEstateProps | null = useMemo(
    () => (type === 'Person' ? { label: t('estate'), address: estate.address } : null),
    [type, estate.address],
  );

  const assignedPersons: AssignedPersonsProps = useMemo(
    () => ({
      label: t(role === 'Tenant' ? 'landlords' : 'tenants'),
      data: (role === 'Tenant' ? landlords : tenants).map(({ personId, name, type }) => ({
        id: personId,
        name,
        type,
      })),
    }),
    [role, landlords, tenants],
  );

  return (
    <AssignedEntityCard
      minHeight={getAssignedDossierCardHeight(type)}
      link={routes.rentalDossierTab(dossierId).products}
      title={referenceNumber}
      subTitle1={ownReference}
      subTitle2={subTitle2}
      status={<DossierStatusChip status={status} />}
      assignedEstate={assignedEstate}
      assignedPersons={assignedPersons}
    />
  );
};

export default AssignedDossierCard;
