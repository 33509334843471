import { Box, CircularProgress } from '@mui/material';
import { useNotificationsTab } from 'hooks/useNotifications';
import NotificationsFooter from './NotificationsFooter';
import NotificationsHeader from './NotificationsHeader';
import NotificationsList from './NotificationsList';
import { NotificationsProps } from './types';

const Notifications = ({
  notifications,
  fetchNotificationsSummary,
  closeNotifications,
  loading,
}: NotificationsProps) => {
  const { activeTab, isFetchingInitially, notificationsByType, setActiveTab } =
    useNotificationsTab(notifications, loading);

  return (
    <Box width="400px">
      <NotificationsHeader
        activeTab={isFetchingInitially ? false : activeTab}
        setActiveTab={setActiveTab}
      />
      {!loading ? (
        <NotificationsList
          activeTab={activeTab}
          notifications={notificationsByType}
          closeNotifications={closeNotifications}
          fetchNotificationsSummary={fetchNotificationsSummary}
        />
      ) : (
        <Box display="flex" justifyContent="center" sx={{ px: 2, py: 1 }}>
          <CircularProgress />
        </Box>
      )}
      <NotificationsFooter
        activeTab={activeTab}
        notifications={notificationsByType}
        closeNotifications={closeNotifications}
        fetchNotificationsSummary={fetchNotificationsSummary}
      />
    </Box>
  );
};

export default Notifications;
