import { useTranslation } from 'react-i18next';
import CardRow, { RowValues } from 'templates/CardRow';
import { formatDate } from 'utils/date';
import { RentalConditions } from 'api/dossier/types';

const RentalDurationRow = ({
  entryDate,
  endDate,
  duration,
  durationNumberOfMonths,
}: Pick<
  RentalConditions,
  'entryDate' | 'endDate' | 'duration' | 'durationNumberOfMonths'
>) => {
  const { t } = useTranslation(['formFields', 'enums']);

  if (entryDate && duration === 'Indefinite') {
    return (
      <CardRow
        title={<RowValues primary={t('entryDate')} secondary={t('duration')} />}
        value={
          <RowValues
            primary={formatDate(entryDate) ?? ''}
            secondary={t('enums:rentalDossierDuration.Indefinite')}
          />
        }
      />
    );
  }

  if (entryDate && endDate) {
    return (
      <CardRow
        title={t('duration')}
        value={`${formatDate(entryDate)} - ${formatDate(endDate)}`}
      />
    );
  }

  if (entryDate) {
    return <CardRow title={t('entryDate')} value={formatDate(entryDate)} />;
  }

  if (duration) {
    const value =
      duration === 'Other' && durationNumberOfMonths
        ? t('months', { count: durationNumberOfMonths })
        : t(`enums:rentalDossierDuration.${duration}`);
    return <CardRow title={t('duration')} value={value} />;
  }

  return null;
};

export default RentalDurationRow;
