import { Box, TableCell, Typography } from '@mui/material';
import useFormMethods from 'hooks/useFormMethods';
import { formatDate } from 'utils/date';
import getDocumentDateLabel from 'utils/getDocumentDateLabel';
import { DocumentData } from 'api/documents/types';
import { ControlledDateInput } from 'components/inputs';
import { DocumentDateCellProps, DocumentsTableForm } from './types';

const DocumentDateCell = <TData extends DocumentData>({
  document,
  documentConfigData,
  isEditing,
}: DocumentDateCellProps<TData>) => {
  const { registerController } = useFormMethods<DocumentsTableForm>();

  const dateType = getDocumentDateLabel(
    document.documentType,
    documentConfigData?.date?.type,
  );

  return (
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {isEditing ? (
        <ControlledDateInput
          {...registerController('date')}
          disableFuture={documentConfigData.date?.type === 'Past'}
          disablePast={documentConfigData.date?.type === 'Future'}
        />
      ) : document.date ? (
        <Box>
          <Typography>{formatDate(document.date)}</Typography>
          <Typography
            variant="subtitle2"
            sx={({ palette }) => ({ color: palette.grey[500], fontSize: 12 })}
          >
            {dateType}
          </Typography>
        </Box>
      ) : null}
    </TableCell>
  );
};

export default DocumentDateCell;
