import { createContext, useMemo, useState } from 'react';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { areRelationsEqual } from 'modules/Relation/utils';
import { EstateFormContextInterface, EstateFormProviderProps } from './types';
import { updateEstateOwners } from './utils';

const EstateFormContext = createContext<EstateFormContextInterface>({
  estateId: null,
  areCopiedOwnersUnchanged: false,
  ownersToPreview: [],
  setOwnersToPreview: () => {},
  saveOwners: async () => false,
  shouldResetFormAfterSave: false,
  setShouldResetFormAfterSave: () => {},
  copyOwners: () => {},
  hideOwners: true,
  prevFormState: {},
  setPrevFormState: () => {},
  isCopy: false,
  setIsCopy: () => {},
});

const EstateFormProvider = ({
  children,
  estateId = null,
  defaultOwners = [],
  hideOwners,
  isCopyMode = false,
  defaultFormValues = {},
}: EstateFormProviderProps) => {
  const [prevFormState, setPrevFormState] =
    useState<Partial<EstateFormInterface>>(defaultFormValues);
  const [shouldResetFormAfterSave, setShouldResetFormAfterSave] = useState(false);
  const [copiedOwners, setCopiedOwners] = useState(estateId ? [] : defaultOwners);
  const [ownersToPreview, setOwnersToPreview] = useState(defaultOwners);
  const [isCopy, setIsCopy] = useState(isCopyMode);

  const saveOwners = async (estateId: string) => {
    if (!areRelationsEqual(ownersToPreview, defaultOwners) || isCopy) {
      const { allSaved } = await updateEstateOwners(estateId)(
        ownersToPreview,
        setOwnersToPreview,
        true,
      );
      return allSaved;
    }
    return true;
  };

  const copyOwners = () => {
    const owners = ownersToPreview.map((owner) => ({ ...owner, isSaved: false }));
    setCopiedOwners(owners);
    setOwnersToPreview(owners);
  };

  const areCopiedOwnersUnchanged = useMemo(
    () => !!copiedOwners.length && areRelationsEqual(copiedOwners, ownersToPreview),
    [copiedOwners.length, ownersToPreview.length],
  );

  return (
    <EstateFormContext.Provider
      value={{
        estateId,
        ownersToPreview,
        setOwnersToPreview,
        saveOwners,
        areCopiedOwnersUnchanged,
        setShouldResetFormAfterSave,
        shouldResetFormAfterSave,
        copyOwners,
        hideOwners,
        prevFormState,
        setPrevFormState,
        isCopy,
        setIsCopy,
      }}
    >
      {children}
    </EstateFormContext.Provider>
  );
};

export { EstateFormProvider, EstateFormContext };
