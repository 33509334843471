import {
  Control,
  ErrorOption,
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldPathValue,
  FieldValues,
  SetValueConfig,
  TriggerConfig,
  useFormContext,
} from 'react-hook-form';
import getFormError from 'utils/getFormError';
import { destructureValuesByPrefix, formatFieldName, getNameWithPrefix } from './utils';

const useFormMethods = <TForm extends FieldValues>(prefix?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { formState, ...methods } = useFormContext<any>();

  const getName = <TFieldName extends FieldPath<TForm>>(name: TFieldName) =>
    getNameWithPrefix(name, prefix);

  const getValues = <TFieldName extends FieldPath<TForm>>(
    name?: TFieldName,
  ): FieldPathValue<TForm, TFieldName> => {
    if (prefix && name) {
      return methods.getValues(String(getName(name)));
    }
    if (!name && prefix) {
      return methods.getValues(prefix);
    }
    if (name && !prefix) {
      return methods.getValues(String(name));
    }
    return methods.getValues();
  };

  const setValue = <TFieldName extends FieldPath<TForm>>(
    name: TFieldName,
    value: FieldPathValue<TForm, TFieldName>,
    options?: SetValueConfig,
  ) => methods.setValue(String(getName(name)), value, options);

  const setError = <TFieldName extends FieldPath<TForm>>(
    name: TFieldName,
    error: ErrorOption,
    options?: { shouldFocus: boolean },
  ) => methods.setError(String(getName(name)), error, options);

  const trigger = <TFieldName extends FieldPath<TForm>>(
    name?: TFieldName | TFieldName[],
    options?: TriggerConfig,
  ) => {
    if (
      name &&
      ((!Array.isArray(name) && getError(name)) ||
        (Array.isArray(name) && name.some(getError)))
    ) {
      return methods.trigger(formatFieldName(name, prefix), options);
    }
    return true;
  };

  const watch = <TFieldName extends FieldPath<TForm>>(name: TFieldName) =>
    methods.watch(getName(name));

  const getError = (name: FieldPath<TForm>) =>
    getFormError(getName(name), formState.errors);

  const clearErrors = <TFieldName extends FieldPath<TForm>>(
    name?: TFieldName | TFieldName[],
  ) => methods.clearErrors(formatFieldName(name, prefix));

  const defaultValues: Partial<TForm> | undefined = destructureValuesByPrefix(
    prefix,
    formState.defaultValues,
  );

  const dirtyFields: Partial<FieldNamesMarkedBoolean<TForm>> | undefined =
    destructureValuesByPrefix(prefix, formState.dirtyFields);

  const registerController = <TFieldName extends FieldPath<TForm>>(name: TFieldName) => ({
    name: getName(name),
    control: methods.control as Control<TForm>,
    error: getError(name),
  });

  const resetField = <TFieldName extends FieldPath<TForm>>(name: TFieldName) =>
    methods.resetField(getName(name));

  return {
    control: methods.control as Control<TForm>,
    registerController,
    getError,
    getValues,
    setValue,
    trigger,
    watch,
    getName,
    setError,
    clearErrors,
    resetField,
    formState: {
      submitCount: formState.submitCount,
      defaultValues,
      dirtyFields,
    },
  };
};

export default useFormMethods;
