import { StepInterface } from 'templates/StepWizard_V2/types';
import { DossierChecklistStep, DossierParametersStep } from './steps';

export const DOSSIER_STEP_NAME = {
  checklist: 'checklist',
  parameters: 'parameters',
} as const;

export const DOSSIER_STEPS: StepInterface[] = [
  {
    Component: DossierChecklistStep,
    name: DOSSIER_STEP_NAME.checklist,
    required: true,
  },
  {
    Component: DossierParametersStep,
    name: DOSSIER_STEP_NAME.parameters,
    required: true,
  },
];
