import addressToString, { getAddressCityPart } from 'utils/addressToString';
import getLng from 'utils/getLng';
import { AddressInterface } from 'api/common/types';

export const groupAddressValues = (address: AddressInterface) => {
  const primary = addressToString(address, { isFullAddress: false });
  const cityPart = getAddressCityPart(address);
  let secondary = cityPart;

  if (address.country) {
    secondary = cityPart
      ? `${cityPart}, ${address.country[getLng()]}`
      : address.country[getLng()];
  }

  return { primary, secondary };
};
