import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { ChangeStepHandler, SaveStepperHandler } from 'templates/StepWizard_V2/types';
import { LegalPersonFormData } from 'api/person/legal/types';
import { LegalPersonFormProps, LegalPersonStepName } from '../types';
import useLegalPersonStepsErrors from './useLegalPersonStepsError';
import useSubmitLegalPersonForm from './useSubmitLegalPersonForm';

interface Props {
  onSaveSuccess: LegalPersonFormProps['handleSave'];
  stepsIndex: Record<LegalPersonStepName, number>;
}

const useLegalPersonSteps = ({ onSaveSuccess, stepsIndex }: Props) => {
  const stepsErrors = useLegalPersonStepsErrors(stepsIndex);
  const submitForm = useSubmitLegalPersonForm({ onSaveSuccess });

  const { handleSubmit } = useFormContext<LegalPersonFormData>();
  const { isAnyDocumentEmpty, markEmptyDocuments } = useContext(FilesUploadContext);

  const onSave: SaveStepperHandler = (event) => handleSubmit(submitForm())(event);

  const changeStep: ChangeStepHandler = (event, changeStep, currentStep) =>
    handleSubmit(() =>
      currentStep === stepsIndex.documents && isAnyDocumentEmpty
        ? markEmptyDocuments()
        : changeStep(),
    )(event);

  return {
    stepsErrors,
    onNext: changeStep,
    onLabel: changeStep,
    onBack: changeStep,
    onSave,
  };
};

export default useLegalPersonSteps;
