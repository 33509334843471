import { Box } from '@mui/material';
import { ProductProcessInterface } from 'api/dossier/products/types';
import ProductStage from './ProductStage';
import { groupedElementsWrapperStyles, verticalLineStyles } from './styles';

const ProductStageGroup = ({ stages }: Pick<ProductProcessInterface, 'stages'>) => (
  <Box position="relative">
    <Box sx={groupedElementsWrapperStyles} gap={2}>
      {stages.map((stage) => (
        <ProductStage key={stage.name} {...stage} />
      ))}
    </Box>
    <Box sx={verticalLineStyles} />
  </Box>
);

export default ProductStageGroup;
