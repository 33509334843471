import { getVatMask, VALIDATED_COUNTRIES } from 'utils/vat';
import { CountryCodesForValidation } from 'utils/vat/types';
import yup from 'utils/yup';
import { VatInterface } from 'api/person/legal/types';

const isVatValid = (number: string, prefix: string) => {
  const length = number.length;
  const isChar = !/^[0-9]*$/.test(number[0]);

  switch (prefix) {
    case 'BE':
      return isBelgiumVatValid(number);
    case 'FR':
      return isFrenchVatValid(number);
    case 'LU':
      return isLuxembourgVatValid(number);
    case 'BG':
      return length === 9 || length === 10;
    case 'CZ':
      return length === 8 || length === 9 || length === 10;
    case 'GB':
      return isChar ? length === 5 : length === 12 || length === 9;
    case 'LT':
      return length === 9 || length == 12;
    case 'RO':
      return length >= 2 && length <= 10;
    default: {
      const mask = getVatMask(prefix, number);
      if (Array.isArray(mask)) {
        return mask.length === length;
      }
      return true;
    }
  }
};

const isBelgiumVatValid = (vatNumber: string) => {
  const rawValue = vatNumber.replaceAll(' ', ''); // custom mask added; if mask is changed, check if it is still correct
  if (rawValue.length < 10) {
    return false;
  }
  const coreNumber = Number(rawValue.substring(0, rawValue.length - 2));
  const lastTwoDigits = Number(rawValue.substring(rawValue.length - 2));

  let checkNumber = 97 - (coreNumber % 97);
  if (checkNumber === 0) {
    checkNumber = 97;
  }
  return checkNumber === lastTwoDigits;
};

const isFrenchVatValid = (vatNumber: string) => {
  const rawValue = vatNumber.replaceAll(' ', ''); // custom mask added; if mask is changed, check if it is still correct
  const checkDigitString = rawValue.substring(0, 2);
  if (isNaN(Number(checkDigitString))) {
    return rawValue.length === 11;
  }
  const siren = Number(rawValue.substring(2));
  const calculatedCheckDigit = (12 + 3 * (siren % 97)) % 97;
  return calculatedCheckDigit === Number(checkDigitString);
};

const isLuxembourgVatValid = (vatNumber: string) => {
  const num = Number(vatNumber.substring(0, 6));
  const rest = num % 89;
  return rest.toString().padStart(2, '0') === vatNumber.substring(6);
};

const vatSchema = yup
  .object({
    prefix: yup.string().default('BE').required(),
    number: yup.string().default('').label('vat'),
  })
  .when({
    is: (vat: VatInterface | null) =>
      vat?.number &&
      VALIDATED_COUNTRIES.some((code) => code === vat.prefix) &&
      !isVatValid(vat.number, vat.prefix as CountryCodesForValidation),
    then: (schema) => schema.invalid('vat.invalidFormat'),
  })
  .nullable();

export default vatSchema;
