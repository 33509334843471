import { RelationData, RelationResponses, SaveRelations } from 'modules/Relation/types';
import { updateRelationsState } from 'modules/Relation/utils';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData, PersonType } from 'api/person/types';
import {
  addOwnership,
  addPrivateRelation,
  addRepresentation,
  deleteOwnership,
  deletePrivateRelation,
  deleteRepresentation,
} from 'api/relations/requests';
import {
  AddRepresentationError,
  AddRepresentationInterface,
  FetchedPrivateRelation,
  PrivateRelationType,
} from 'api/relations/types';
import { PersonForRelationUpdateInterface } from './types';

export const updatePrivateRelation = async (
  personId: string,
  newRelation: RelationData<FetchedPersonData> | null,
  newRelationType: PrivateRelationType,
  prevRelation: RelationData<FetchedPersonData> | null,
): Promise<{ ok: boolean; relation: RelationData<FetchedPersonData> | null }> => {
  if (prevRelation?.responseId) {
    await deletePrivateRelation(prevRelation.responseId);
  }

  if (newRelation) {
    const res = await addPrivateRelation({
      type: newRelationType,
      side1: personId,
      side2: newRelation.id,
    });

    if (!res.ok && res.response) {
      const { errorCode } = res.response;

      return {
        ok: false,
        relation: {
          ...newRelation,
          errorMessage: {
            key: `${errorCode}_privateRelation`,
            values: { name: newRelation.name },
          },
        },
      };
    }

    return { ok: res.ok, relation: newRelation };
  }

  return { ok: true, relation: null };
};

const createNewRepresentative = (
  person: Omit<PersonForRelationUpdateInterface, 'name'>,
  representative: RelationData<FetchedPersonData>,
): AddRepresentationInterface => ({
  representedPartyId: person.id,
  representedPartyType: person.type,
  representativeId: representative.id,
  representativeType: representative.type,
});

const createNewRepresentedParty = (
  person: Omit<PersonForRelationUpdateInterface, 'name'>,
  representedParty: RelationData<FetchedPersonData>,
): AddRepresentationInterface => ({
  representativeId: person.id,
  representativeType: person.type,
  representedPartyId: representedParty.id,
  representedPartyType: representedParty.type,
});

const createNewRelation = (
  type: 'representative' | 'representedParty',
  person: Omit<PersonForRelationUpdateInterface, 'name'>,
  relation: RelationData<FetchedPersonData>,
) =>
  type === 'representative'
    ? createNewRepresentative(person, relation)
    : createNewRepresentedParty(person, relation);

const getErrorMessage = (
  personName: string,
  { type, name }: RelationData<FetchedPersonData>,
  { errorCode, errorMessage }: AddRepresentationError,
): RelationData['errorMessage'] => ({
  key: `${errorCode}_${type}`,
  values: {
    defaultValue: errorMessage,
    side1: personName,
    side2: name,
    name,
  },
});

const updateRelations =
  (
    relationType: 'representative' | 'representedParty',
    person: PersonForRelationUpdateInterface,
  ): SaveRelations<FetchedPersonData> =>
  async (relationsToPreview, setRelationsToPreview, showSaved) => {
    const responses: RelationResponses = [];

    for (const { isSaved, toDelete, responseId, ...relation } of relationsToPreview) {
      if (isSaved && responseId) {
        if (toDelete) {
          const { ok } = await deleteRepresentation(responseId);
          responses.push({
            ok,
            relationId: relation.id,
            responseId: ok ? null : responseId,
          });
        } else {
          responses.push({ ok: true, relationId: relation.id, responseId });
        }
      } else {
        const newRelation = createNewRelation(relationType, person, relation);
        const { ok, response } = await addRepresentation(newRelation);

        responses.push({
          ok,
          message:
            !ok && response ? getErrorMessage(person.name, relation, response) : '',
          relationId: relation.id,
          responseId: ok ? response.id : null,
        });
      }
    }

    return updateRelationsState(responses, setRelationsToPreview, showSaved);
  };

export const updateRepresentatives = (person: PersonForRelationUpdateInterface) =>
  updateRelations('representative', person);
export const updateRepresentedParties = (person: PersonForRelationUpdateInterface) =>
  updateRelations('representedParty', person);

export const updateOwnerships =
  (
    personId: string,
    personType: PersonType,
  ): SaveRelations<FetchedEstateListItem, FetchedPrivateRelation> =>
  async (relationsToPreview, setRelationsToPreview, showSaved, privateRelationData) => {
    const responses: RelationResponses = [];

    for (const { id, toDelete, isSaved, responseId } of relationsToPreview) {
      if (isSaved && responseId) {
        if (toDelete) {
          const { ok } = await deleteOwnership(responseId);
          responses.push({
            ok,
            relationId: id,
            responseId: ok ? null : responseId,
          });
        } else {
          responses.push({ ok: true, relationId: id, responseId });
        }
      } else {
        const res = await addOwnership({
          ownerId: personId,
          ownerType: personType,
          estateId: id,
        });
        responses.push({
          ok: res.ok,
          relationId: id,
          responseId: res.ok ? res.response.id : null,
          message: !res.ok ? res.message.replace(id, '') : '',
        });
        if (privateRelationData) {
          const res = await addOwnership({
            ownerId: privateRelationData.relative.id,
            ownerType: privateRelationData.relative.type,
            estateId: id,
          });
          responses.push({
            ok: res.ok,
            relationId: id,
            responseId: res.ok ? res.response.id : null,
            message: !res.ok ? res.message.replace(id, '') : '',
          });
        }
      }
    }

    return updateRelationsState(responses, setRelationsToPreview, showSaved);
  };
