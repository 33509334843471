import { UNITS } from 'consts/units';
import { managementTypeOptions } from 'formData/estate/details/selectOptions';
import useFormMethods from 'hooks/useFormMethods';
import { t } from 'i18next';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { findMissingField, getInputProps } from 'utils/missingFields';
import {
  ControlledCheckbox,
  ControlledNumberInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/inputs';
import { EstateDetailsFieldNames, EstateFieldsProps } from './types';

const EstateDetailsFields = ({ missingFields = [] }: EstateFieldsProps) => {
  const { registerController, setValue } = useFormMethods<EstateFormInterface>();

  const registerInput = (name: EstateDetailsFieldNames) => ({
    ...getInputProps(name, missingFields),
    ...registerController(`estateDetails.${name}`),
  });

  const isVisible = (name: EstateDetailsFieldNames) => {
    if (missingFields.length) {
      return !!findMissingField(name, missingFields);
    }
    return true;
  };

  return (
    <>
      {isVisible('habitableSurface') && (
        <ControlledNumberInput
          xs={12}
          md={4}
          {...registerInput('habitableSurface')}
          endAdornment={UNITS.squareMeter}
        />
      )}
      {isVisible('managementType') && (
        <ControlledSelect
          xs={12}
          md={4}
          {...registerInput('managementType')}
          options={managementTypeOptions}
          clearable
        />
      )}
      {(isVisible('syndic') || isVisible('hasSyndic')) && (
        <ControlledTextInput
          xs={12}
          md={4}
          label={t('syndic')}
          {...registerController('estateDetails.syndic')}
          onChange={(e) => {
            if (e.target.value) {
              setValue('estateDetails.hasSyndic', true);
            }
          }}
          endAdornment={
            <ControlledCheckbox
              size="small"
              disableRipple
              sx={{ mr: -1 }}
              {...registerController('estateDetails.hasSyndic')}
            />
          }
        />
      )}

      {isVisible('smokeDetectors') && (
        <ControlledNumberInput
          xs={4}
          isInteger
          {...registerController('estateDetails.smokeDetectors')}
          label={t('smokeDetectors')}
        />
      )}
      {isVisible('isFurnished') && (
        <ControlledCheckbox xs={12} {...registerInput('isFurnished')} />
      )}
    </>
  );
};

export default EstateDetailsFields;
