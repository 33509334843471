import { useContext, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { UploadContext } from 'contexts/fileUpload/types';
import { uploadDocumentsSchema } from 'formData/documents/schema';
import { resolver } from 'utils/yup';
import { DocumentsForm, DocumentToUpload, HandleSingleDocumentSave } from './types';
import {
  getDocumentToUpload,
  saveDocument,
  setPendingStatusForDocument,
  setWarningForEmptyDocument,
  updateDocumentStatus,
} from './utils';

interface Props {
  onSingleDocumentSave?: HandleSingleDocumentSave;
  defaultFields?: DocumentsForm['documents'];
}

export const useFileUpload = (props?: Props): UploadContext => {
  const { documentsConfig } = useContext(ConfigContext);

  const formMethods = useForm<DocumentsForm>({
    defaultValues: { documents: props?.defaultFields ?? [] },
    resolver: resolver(uploadDocumentsSchema),
    context: { documentConfigData: documentsConfig },
  });

  const { t } = useTranslation();

  const {
    fields: documents,
    append,
    remove,
    update,
    replace,
  } = useFieldArray({
    control: formMethods.control,
    name: 'documents',
  });

  const addDocument: UploadContext['addDocument'] = (data) => {
    const document = getDocumentToUpload(data);
    append(document);
  };

  const updateDocument: UploadContext['updateDocument'] = (fieldId, document) => {
    const index = documents.findIndex(({ id }) => id === fieldId);
    const data = { ...formMethods.getValues('documents')[index], ...document };

    update(index, { ...data, status: updateDocumentStatus(data) });
  };

  const removeDocument: UploadContext['removeDocument'] = (fieldId) => {
    const index = documents.findIndex(({ id }) => id === fieldId);
    remove(index);
  };

  const saveDocuments: UploadContext['saveDocuments'] = async (event, entityId) => {
    let isAnyDocumentSaved = false;
    let isAnyDocumentWithError = false;
    let isAnyDocumentWithWarning = false;

    await formMethods.handleSubmit(
      async ({ documents }) => {
        if (
          !documents.length ||
          documents.every(
            ({ status, documentType }) => status === 'SAVED' || !documentType,
          )
        ) {
          return;
        }

        const documentsToUpload: DocumentToUpload[] = documents.map((document) => ({
          ...document,
          entityId: entityId || document.entityId,
        }));

        replace(documentsToUpload.map(setPendingStatusForDocument));
        const processedDocuments: DocumentToUpload[] = [];

        for (const document of documentsToUpload) {
          if (document.status === 'READY' || document.status === 'ERROR') {
            const { documentId, status, message } = await saveDocument(document);
            if (props?.onSingleDocumentSave && documentId) {
              await props.onSingleDocumentSave(
                documentId,
                document.scope,
                document.entityId,
              );
            }

            if (status === 'SAVED') {
              isAnyDocumentSaved = true;
            } else {
              isAnyDocumentWithError = true;
            }

            processedDocuments.push({ ...document, documentId, status, message });
          } else {
            processedDocuments.push(setWarningForEmptyDocument(document));
          }
        }

        if (isAnyDocumentSaved) {
          toast.success(
            t('successMessages.DocumentsUploadedSuccessfully', {
              count: processedDocuments.filter(({ status }) => status === 'SAVED').length,
            }),
            { autoClose: 5000 },
          );
        }

        if (processedDocuments.some(({ status }) => status === 'WARNING')) {
          isAnyDocumentWithWarning = true;
        }

        replace(processedDocuments);
      },
      () => {
        isAnyDocumentWithError = true;
      },
    )(event);

    return { isAnyDocumentSaved, isAnyDocumentWithError, isAnyDocumentWithWarning };
  };

  const validateDocuments: UploadContext['validateDocuments'] = (
    e,
    onValid = () => {},
    onError,
  ) => formMethods.handleSubmit(onValid, onError)(e);

  const isAnyPending = useMemo(
    () => documents.some(({ status }) => status === 'PENDING'),
    [documents],
  );

  const isAnyDocumentEmpty = useMemo(
    () =>
      !!documents.length &&
      documents.some(({ files, documentType }) => documentType && !files.length),
    [documents],
  );

  return {
    addDocument,
    updateDocument,
    removeDocument,
    saveDocuments,
    validateDocuments,
    markEmptyDocuments: () => replace(documents.map(setWarningForEmptyDocument)),
    clearDocuments: () => replace([]),
    isAnyPending,
    documents,
    formMethods,
    isAnyDocumentEmpty,
  };
};
