import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { useCopyToClipboard } from 'usehooks-ts';

const ThirdPartySoftwareKeyData = ({ businessConnectReference }: { businessConnectReference: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [_, copyToClipboard] = useCopyToClipboard();

  const { t } = useTranslation('common', { keyPrefix: 'pageCard.thirdPartySoftware' });

  const copyBusinessConnectReference = async () => {
    const ok = await copyToClipboard(businessConnectReference);
    if (ok) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    }
  };

  return (
    <Box display="flex" gap={4} alignItems="center" justifyContent="space-between">
      <Typography fontWeight={500}>{t('businessConnectReference')}</Typography>
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography fontWeight={500}>{businessConnectReference}</Typography>
        <TooltipIconButton
          Icon={<ContentCopy fontSize="small" />}
          onClick={copyBusinessConnectReference}
          tooltipTitle={t(isCopied ? 'copied' : 'copy')}
        />
      </Box>
    </Box>
  );
};

export default ThirdPartySoftwareKeyData;
