import { Stack, Typography } from '@mui/material';
import transformPhone from 'utils/transformPhone';
import { AffiliatedPersonInterface } from 'api/common/types';
import PersonLabel from './AffiliatedPersonLabel';
import { getAffiliatedPersonData } from './utils';

const AffiliatedPersonContactData = ({ ...person }: AffiliatedPersonInterface) => {
  const { email, phone, address } = getAffiliatedPersonData(person);

  return (
    <Stack>
      {person.representative && <PersonLabel {...person.representative} />}
      {email && (
        <Typography
          component="a"
          href={`mailTo:${email}`}
          variant="caption"
          sx={({ mixins }) => ({ ...mixins.link, color: 'grey.600' })}
        >
          {email}
        </Typography>
      )}
      {phone?.number && (
        <Typography
          component="a"
          href={`tel:${transformPhone(phone)}`}
          variant="caption"
          sx={({ mixins }) => ({ ...mixins.link, color: 'grey.500' })}
        >
          {transformPhone(phone)}
        </Typography>
      )}
      {!email && !phone?.number ? (
        <Typography variant="caption">{address}</Typography>
      ) : null}
    </Stack>
  );
};

export default AffiliatedPersonContactData;
