import { EstateDestination } from 'api/estate/types';
import {
  nonResidentialComposition,
  residentialComposition,
  sharedComposition,
} from './static-data';

const residentialCompositionFields = [...sharedComposition, ...residentialComposition];

const nonResidentialCompositionFields = [
  ...sharedComposition,
  ...nonResidentialComposition,
];

export const getCompositionFields = (estateDestination: EstateDestination) =>
  estateDestination === 'NonResidential'
    ? nonResidentialCompositionFields
    : residentialCompositionFields;
