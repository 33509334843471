import { Fragment, PropsWithChildren } from 'react';
import { Box, DialogActions, DialogContent, Stack } from '@mui/material';

const GeneralContainer = ({ children }: PropsWithChildren) => (
  <Stack gap={2} flexGrow={1} sx={{ overflow: 'hidden' }}>
    <Box
      gap={2}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
    >
      {children}
    </Box>
  </Stack>
);

const ContentBox = ({ children }: PropsWithChildren) => (
  <DialogContent sx={{ scrollbarGutter: 'stable' }}>{children}</DialogContent>
);

export const standardComponents = {
  ContentBox: Fragment,
  ActionsBox: Box,
  GeneralBox: GeneralContainer,
};

// useful for handling dialog content scroll
export const dialogComponents = {
  ContentBox,
  ActionsBox: DialogActions,
  GeneralBox: Fragment,
};
