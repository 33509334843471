import { Trans, useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { Box, Stack, Typography } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { LogoutIcon } from 'icons';
import { errorPageStyles } from './styles';

const ErrorPage = () => {
  const { t } = useTranslation(['errors', 'common']);
  const { instance } = useMsal();

  const logout = () => {
    localStorage.clear();
    instance.logoutRedirect().catch((err) => console.error(err));
  };
  return (
    <>
      <Stack sx={errorPageStyles} alignItems="center">
        <Box sx={{ alignSelf: 'flex-end' }}>
          <TooltipIconButton
            onClick={logout}
            Icon={<LogoutIcon />}
            tooltipTitle={t('common:logout')}
          />
        </Box>
        <Stack sx={{ pt: 9 }} alignItems="center">
          <Trans
            t={t}
            i18nKey="errors:errorPage"
            defaults="<Alert>Oops!</Alert><Info>Something went wrong</Info><Instruction>Please, try again later</Instruction>"
            components={{
              Alert: <Typography sx={{ fontSize: '5rem', color: 'secondary.main' }} />,
              Info: <Typography sx={{ fontSize: '3rem' }} />,
              Instruction: <Typography sx={{ fontSize: '2rem' }} />,
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ErrorPage;
