const camelCaseRegex = new RegExp(/([a-z])([A-Z])/, 'g');
const dashRegex = new RegExp(/_|-/, 'g');

export const normalizeString = (s: string): string =>
  s.charAt(0).toUpperCase() +
  s
    .slice(1)
    .replace(camelCaseRegex, '$1 $2')
    .replace(dashRegex, ' ')
    .replace(/\s{2,}/g, ' ')
    .split(' ')
    // lowerCase every word except the first one
    .map((word, index) => (index ? word.toLowerCase() : word))
    .join(' ');
