import getNRNCheckNumber from './getNrnCheckNumber';

const transformNrnToDateOfBirth = (nrn: string) => {
  const plainValue = nrn.replaceAll(/[^\d]/g, '');
  const year = Number(plainValue.substring(0, 2));
  const month = Number(plainValue.substring(2, 4));
  const day = Number(plainValue.substring(4, 6));

  const checkNumber = Number(plainValue.slice(-2));

  const after2000YearNumber = getNRNCheckNumber(plainValue, true);

  if (checkNumber === after2000YearNumber) {
    // month is 0 indexed
    return new Date(2000 + year, month - 1, day);
  } else {
    return new Date(1900 + year, month - 1, day);
  }
};

export default transformNrnToDateOfBirth;
