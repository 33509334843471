import { Box } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import TableFilter from './TableFilter';
import TableFiltersCleaner from './TableFiltersCleaner';
import { TableFiltersProps } from './types';

const TableFilters = ({ visibleColumns }: TableFiltersProps) => {
  const isAnyFilter = visibleColumns.some(({ filterable }) => filterable);

  return isAnyFilter ? (
    <Box display="flex" py={1} borderBottom={({ shape }) => shape.border}>
      {visibleColumns.map((column) => {
        switch (column.field) {
          case GRID_CHECKBOX_SELECTION_FIELD:
            return <Box key={column.field} width={column.width} />;
          case 'actions':
            return <TableFiltersCleaner key={column.field} />;
          default:
            return <TableFilter key={column.field} {...column} />;
        }
      })}
    </Box>
  ) : null;
};

export default TableFilters;
