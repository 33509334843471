import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import { LegalPersonFormProvider } from 'contexts/personForm/legalPersonFormContext';
import { legalPersonSchema } from 'formData/legalPerson';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard_V2';
import { filterSteps, getStepsIndex } from 'templates/StepWizard_V2/utils';
import { resolver } from 'utils/yup';
import { LegalPersonFormData } from 'api/person/legal/types';
import { LEGAL_PERSON_STEPS } from './static-data';
import { LegalPersonFormDefaultValues, LegalPersonFormProps } from './types';
import useLegalPersonSteps from './useLegalPersonSteps';

const LegalPersonFormStepper = ({
  handleCancel,
  handleSave,
  stepsToHide,
}: LegalPersonFormProps) => {
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
    }
  };

  const steps = useMemo(() => filterSteps(LEGAL_PERSON_STEPS, stepsToHide), []);

  const stepsProps = useLegalPersonSteps({
    onSaveSuccess: handleSave,
    stepsIndex: getStepsIndex(steps),
  });

  return (
    <StepWizard tKey="legalPerson" steps={steps} onCancel={onCancel} {...stepsProps} />
  );
};

export const LegalPersonForm = ({
  legalPersonId,
  defaultValues,
  ...props
}: LegalPersonFormProps & {
  legalPersonId?: string | null;
  defaultValues?: LegalPersonFormDefaultValues;
}) => {
  const methods = useForm<LegalPersonFormData>({
    mode: legalPersonId ? 'onChange' : 'onSubmit',
    defaultValues: { ...legalPersonSchema.getDefault(), ...defaultValues?.legalPerson },
    resolver: resolver(legalPersonSchema),
  });

  useEffect(() => {
    if (legalPersonId) {
      methods.trigger();
    }
  }, []);

  usePrompt({ when: methods.formState.isDirty, id: 'legalPersonForm' });

  useEffect(() => {
    if (legalPersonId) {
      methods.trigger();
    }
  }, []);

  return (
    <FilesUploadProvider scope="legalPerson">
      <FormProvider {...methods}>
        <LegalPersonFormProvider
          personId={legalPersonId}
          defaultOwnerships={defaultValues?.ownerships}
          defaultRepresentatives={defaultValues?.representatives}
        >
          <LegalPersonFormStepper {...props} />
        </LegalPersonFormProvider>
      </FormProvider>
    </FilesUploadProvider>
  );
};
