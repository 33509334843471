import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from '@mui/material';
import { filterAccordionStyle } from './styles';
import { TableFilterSidebarContentProps } from './types';

const TableFilterSidebarContent = ({
  tKey,
  filters,
  updateFilter,
}: TableFilterSidebarContentProps) => {
  const { t } = useTranslation('table', { keyPrefix: tKey });

  return (
    <Stack
      height="100%"
      divider={<Divider orientation="horizontal" />}
      overflow="auto"
      sx={{ scrollbarGutter: 'stable' }}
    >
      {filters.map(({ name, Component, ...componentProps }) => (
        <Accordion
          key={name}
          variant="outlined"
          defaultExpanded
          disableGutters
          sx={filterAccordionStyle}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t(`filters.${name}`)}
          </AccordionSummary>
          <AccordionDetails>
            <Component updateFilter={updateFilter} {...componentProps} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default TableFilterSidebarContent;
