import { Badge, IconButton, Menu } from '@mui/material';
import { useNotificationsMenu } from 'hooks/useNotifications';
import { NotificationIcon } from 'icons';
import Notifications from 'modules/Notifications';

const NotificationsMenu = () => {
  const {
    loading,
    notifications,
    totalCount,
    menuProps,
    openMenu,
    closeMenu,
    fetchNotificationsSummary,
  } = useNotificationsMenu();

  return (
    <>
      <Badge
        color="secondary"
        badgeContent={totalCount}
        overlap="circular"
        sx={{ '.MuiBadge-badge': { color: 'white' } }}
        data-testid={'notification-badge'}
      >
        <IconButton onClick={openMenu} sx={({ mixins }) => mixins.headerIconButton}>
          <NotificationIcon fontSize="small" />
        </IconButton>
      </Badge>
      <Menu {...menuProps} onClose={closeMenu} sx={({ mixins }) => mixins.headerMenu}>
        <Notifications
          notifications={notifications}
          fetchNotificationsSummary={fetchNotificationsSummary}
          closeNotifications={closeMenu}
          loading={loading}
        />
      </Menu>
    </>
  );
};

export default NotificationsMenu;
