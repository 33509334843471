import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { releaseRentalDepositSchema } from 'formData/dossier/products/rentalDeposit/schema';
import useFileUpload from 'hooks/useFileUpload';
import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import UploadDocuments from 'modules/UploadDocuments';
import { resolver } from 'utils/yup';
import { AbolishmentForm } from 'api/dossier/products/rentalDeposit/types';
import { attachDossierTypeDocument } from 'api/dossier/requests';
import { ProductActionContent } from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import DepositDistributionSection from '../PrepareRelease/DepositDistributionSection';
import { formatAbolishmentData } from '../PrepareRelease/utils';
import { getAbolishmentData } from './utils';

const RentalDepositUploadReleaseForm = ({
  setLoading,
  sendRequest,
  setDisableSubmitButton,
  dossier: { id: dossierId, rentalConditions },
  product: { productId },
}: ProductActionComponentProps) => {
  const methods = useForm<AbolishmentForm>({
    resolver: resolver(releaseRentalDepositSchema),
    defaultValues: releaseRentalDepositSchema.getDefault(),
    context: {
      depositValue: rentalConditions?.depositAmount?.value,
    },
  });

  const depositDistribution = useWatch({
    control: methods.control,
    name: 'depositDistribution',
  });

  const attachDocument: HandleSingleDocumentSave = async (documentId) => {
    await attachDossierTypeDocument(dossierId, documentId, { attached: true });
  };

  const { saveDocuments, ...uploadFileProps } = useFileUpload({
    onSingleDocumentSave: attachDocument,
    defaultFields: [
      getDocumentToUpload({
        entityId: dossierId,
        scope: 'dossier',
        documentType: 'SignedReleaseForm',
      }),
    ],
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await getAbolishmentData(dossierId, productId);
    if (data) {
      methods.reset(data);
    }
    setLoading(false);
  }, [dossierId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit: SubmitHandler<AbolishmentForm> = async (data, e) => {
    setDisableSubmitButton(true);
    const { isAnyDocumentWithError, isAnyDocumentWithWarning } = await saveDocuments(e);
    setDisableSubmitButton(false);
    if (!isAnyDocumentWithError && !isAnyDocumentWithWarning) {
      sendRequest(formatAbolishmentData(data));
    }
  };

  return (
    <FormProvider {...methods}>
      <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <UploadDocuments isUploadingMissingDocuments {...uploadFileProps} />
          <WarningField tKey="rentalDepositReleaseUpload" fullWidth />
          <DepositDistributionSection
            abolishmentReason={methods.getValues('abolishmentReason')}
            depositDistribution={depositDistribution}
            withoutUnknown
            noEmail
          />
        </Stack>
      </ProductActionContent>
    </FormProvider>
  );
};

export default RentalDepositUploadReleaseForm;
