export const citiesWithPostCodes = {
  Brussel: {
    postCode: '1000',
    city: { en: 'Brussel', nl: 'Brussel', fr: 'Bruxelles' },
    region: 'BRUSSELS',
  },
  Brussel_X: {
    postCode: '1099',
    city: { en: 'Brussel X', nl: 'Brussel X', fr: 'Bruxelles X' },
    region: 'BRUSSELS',
  },
  Brugge: {
    postCode: '8000',
    city: { en: 'Brugge', nl: 'Brugge', fr: 'Brugge' },
    region: 'FLANDERS',
  },
  Galmaarden: {
    postCode: '1570',
    city: { en: 'Galmaarden', nl: 'Galmaarden', fr: 'Galmaarden' },
    region: 'FLANDERS',
  },
  Tollembeek: {
    postCode: '1570',
    city: { en: 'Tollembeek', nl: 'Tollembeek', fr: 'Tollembeek' },
    region: 'FLANDERS',
  },
} as const;

export const countries = {
  Belgium: {
    name: { en: 'Belgium', nl: 'België', fr: 'Belgique' },
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    vatPrefix: 'BE',
    phoneCode: '+32',
  },
  Dutch: {
    name: { en: 'Dutch', nl: 'Nederlands', fr: 'Néerlandais' },
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    vatPrefix: 'NL',
    phoneCode: '+31',
  },
  France: {
    name: { en: 'France', nl: 'Frankrijk', fr: 'France' },
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    vatPrefix: 'FR',
    phoneCode: '+33',
  },
  Germany: {
    name: { en: 'Germany', nl: 'Duitsland', fr: 'Allemagne' },
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    vatPrefix: 'DE',
    phoneCode: '+49',
  },
  Poland: {
    name: { en: 'Poland', nl: 'Polen', fr: 'Pologne' },
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    vatPrefix: 'PL',
    phoneCode: '+48',
  },
} as const;

export const document = new File(['blob'], 'document', { type: 'image/png' });
