import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import Select from './Select';
import { ControlledSelectProps } from './types';

const ControlledSelect: <
  TFields extends FieldValues = FieldValues,
  TName extends FieldPath<TFields> = FieldPath<TFields>,
  TValue extends string = string,
>(
  props: ControlledSelectProps<TFields, TName, TValue>,
) => React.ReactElement = ({
  name,
  control,
  error,
  warning,
  helperText,
  options,
  onChange: onSelectChange,
  onClear,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, ...field } }) => (
      <Select
        options={options}
        value={options.length ? value ?? '' : ''}
        onChange={(value, option) => {
          if (onSelectChange) {
            onSelectChange(value, option);
          }
          onChange(value);
        }}
        onClear={() => {
          onChange(null);
          if (onClear) {
            onClear();
          }
        }}
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...inputProps}
        {...field}
      />
    )}
  />
);

export default ControlledSelect;
