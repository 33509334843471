import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { InfoIcon } from 'icons';
import CardRow from 'templates/CardRow';
import { NaturalPersonData } from 'api/person/natural/types';

const PoliticallyExposedPersonRow = ({
  isPoliticallyExposedPerson,
}: Pick<NaturalPersonData, 'isPoliticallyExposedPerson'>) => {
  const { t } = useTranslation(['common', 'formFields']);

  return isPoliticallyExposedPerson ? (
    <CardRow
      title={
        <Box display="flex" alignItems="center">
          <Typography fontWeight={500} width="max-content" pr={0.5}>
            {t('formFields:isPoliticallyExposedPerson')}
          </Typography>
          <IconTooltip title={t('tooltips.politicallyExposedPerson')}>
            <InfoIcon fontSize="small" color="primary" />
          </IconTooltip>
        </Box>
      }
      value={t('yes')}
    />
  ) : null;
};

export default PoliticallyExposedPersonRow;
