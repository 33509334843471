import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import { documentsSchema } from 'formData/documents/schema';
import { useToggle } from 'usehooks-ts';
import { resolver } from 'utils/yup';
import { DocumentData } from 'api/documents/types';
import DocumentFiles from '../DocumentFiles';
import { DocumentRowProps, DocumentsContextInterface } from '../types';
import DocumentActions from './DocumentActions';
import DocumentCells from './DocumentCells';
import DocumentEditionButtons from './DocumentEditionButtons';
import DocumentExpanderCell from './DocumentExpanderCell';
import { DocumentsTableForm } from './types';
import { saveEditedDocument } from './utils';

const DocumentRow = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  document,
  context,
  getActions,
  updateDocuments,
  CellsComponent = DocumentCells,
}: DocumentRowProps<TData, TContext>) => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [submitting, setSubmitting] = useState(false);

  const { documentConfigData, entityId, scope, editable = false } = context;

  const methods = useForm<DocumentsTableForm>({
    defaultValues: { ...document },
    context: { documentConfigData },
    resolver: resolver(documentsSchema),
  });

  const onSubmit: SubmitHandler<DocumentsTableForm> = async (data) => {
    setSubmitting(true);
    const { ok } = await saveEditedDocument(
      { documentId: document.id, entityId, scope },
      data,
    );
    if (ok) {
      await updateDocuments();
      toggleIsEditing();
    }
    setSubmitting(false);
  };

  return (
    <>
      <TableRow>
        <DocumentExpanderCell
          expanded={expanded}
          toggleExpand={toggleExpanded}
          document={document}
        />
        <FormProvider {...methods}>
          <CellsComponent
            context={context}
            document={document}
            updateDocuments={updateDocuments}
            isEditing={isEditing}
          />
        </FormProvider>
        <TableCell sx={({ mixins }) => mixins.actionCellStyles}>
          {isEditing ? (
            <DocumentEditionButtons
              submitting={submitting}
              onSave={methods.handleSubmit(onSubmit)}
              onCancel={toggleIsEditing}
            />
          ) : (
            <DocumentActions
              document={document}
              actions={getActions({
                document,
                entityId,
                scope,
                editable,
                turnEditMode: toggleIsEditing,
              })}
              updateDocuments={updateDocuments}
            />
          )}
        </TableCell>
      </TableRow>
      <DocumentFiles
        open={expanded}
        document={document}
        context={context}
        updateDocuments={updateDocuments}
      />
    </>
  );
};

export default DocumentRow;
