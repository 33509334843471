import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import { StepsErrors } from 'templates/StepWizard_V2/types';
import { useUpdateEffect } from 'usehooks-ts';
import { hasObjectAnyProperty } from 'utils/object';
import { EstateFormInterface, EstateStepName } from '../types';

const useEstateStepsErrors = (stepsIndex: Record<EstateStepName, number>) => {
  const [stepperErrors, setStepperErrors] = useState<StepsErrors>(null);

  const { ownersToPreview } = useContext(EstateFormContext);
  const { uploadedFile } = useContext(UploadPhotoContext);
  const { documents } = useContext(FilesUploadContext);

  const {
    formState: { errors },
  } = useFormContext<EstateFormInterface>();

  useUpdateEffect(() => {
    const { ownReference: _, ...generalStepErrors } = errors.estate ?? {};
    const isError =
      hasObjectAnyProperty(generalStepErrors) ||
      !!errors.estateDetails?.isFurnished ||
      !!errors.estateDetails?.habitableSurface ||
      !!errors.estateDetails?.managementType ||
      ownersToPreview.some(({ errorMessage }) => errorMessage);

    setStepperErrors((prev) => ({ ...prev, [stepsIndex.general]: isError }));
  }, [ownersToPreview.length, Object.keys(errors.estate ?? {}).length]);

  useUpdateEffect(() => {
    const isError =
      !!errors.estateDetails?.electricity ||
      !!errors.estateDetails?.epc ||
      !!errors.estateDetails?.gas ||
      !!errors.estateDetails?.conformity;

    setStepperErrors((prev) => ({ ...prev, [stepsIndex.media]: isError }));
  }, [
    errors.estateDetails?.electricity,
    errors.estateDetails?.epc,
    errors.estateDetails?.gas,
    errors.estateDetails?.conformity,
  ]);

  useUpdateEffect(() => {
    setStepperErrors((prev) => ({
      ...prev,
      [stepsIndex.composition]: hasObjectAnyProperty(
        errors.estateDetails?.estateComposition,
      ),
    }));
  }, [errors.estateDetails?.estateComposition]);

  useUpdateEffect(() => {
    const isError =
      !!errors.estate?.ownReference ||
      !!errors.estateDetails?.smokeDetectors ||
      !!errors.estateDetails?.syndic ||
      hasObjectAnyProperty(errors.estateDetails?.descriptions) ||
      uploadedFile?.status === 'ERROR';

    setStepperErrors((prev) => ({ ...prev, [stepsIndex.additional]: isError }));
  }, [
    errors.estateDetails?.descriptions,
    errors.estate?.ownReference,
    errors.estateDetails?.smokeDetectors,
    errors.estateDetails?.syndic,
    uploadedFile?.status,
  ]);

  useUpdateEffect(() => {
    const isError = documents.some(
      ({ status }) => status === 'ERROR' || status === 'WARNING',
    );
    setStepperErrors((prev) => ({ ...prev, [stepsIndex.documents]: isError }));
  }, [documents]);

  return stepperErrors;
};

export default useEstateStepsErrors;
