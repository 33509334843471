import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getVatNumberWithMask } from 'utils/vat';
import { getErrorMessage } from '../utils';
import { ControlledVatInputProps } from './types';
import VatInput from './VatInput';

const ControlledVatInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledVatInputProps<T, N>,
) => React.ReactElement = ({ name, error, warning, helperText, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, value, ...field } }) => (
      <VatInput
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        vatNumber={value?.number}
        vatPrefix={value?.prefix}
        onNumberChange={(number) => onChange({ ...value, number })}
        onPrefixChange={(prefix) =>
          onChange({
            prefix,
            number: value?.number ? getVatNumberWithMask(value.number, prefix) : '',
          })
        }
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...field}
        {...props}
      />
    )}
  />
);

export default ControlledVatInput;
