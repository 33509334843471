import { Box, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { TableTranslationKey } from '../types';
import PaginationActions from './PaginationActions';
import RowsPerPageSelector from './RowsPerPageSelector';
import { TablePaginationProps } from './types';
import { getDisplayedRowsLabel } from './utils';

export const TablePagination = <Key extends TableTranslationKey>({
  tKey,
  rowsPerPage,
}: TablePaginationProps<Key>) => {
  const apiRef = useGridApiContext();
  const { page, pageSize } = apiRef.current.state.pagination.paginationModel;
  const { totalRowCount, loading } = apiRef.current.state.rows;

  const onPageChange = (page: number) =>
    apiRef.current.setPaginationModel({ page: page - 1, pageSize });

  const onRowsPerPageChange = (pageSize: string) =>
    apiRef.current.setPaginationModel({ page: page, pageSize: Number(pageSize) });

  return (
    <Box display="flex" width="100%" px={2}>
      <RowsPerPageSelector
        tKey={tKey}
        disabled={loading}
        pageSize={pageSize}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPage={rowsPerPage}
      />
      <PaginationActions
        page={page}
        pageSize={pageSize}
        totalRowCount={totalRowCount}
        loading={loading}
        onPageChange={onPageChange}
      />
      <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
        <Typography color="grey.500">
          {getDisplayedRowsLabel(page, pageSize, totalRowCount)}
        </Typography>
      </Box>
    </Box>
  );
};
