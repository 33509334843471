import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Step, StepButton, Stepper, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import { ErrorIcon } from 'icons';
import { useUpdateEffect } from 'usehooks-ts';
import { dialogComponents, standardComponents } from './components';
import NotAllowedLabel from './NotAllowedLabel';
import StepFooter from './StepFooter';
import { StepWizardProps } from './types';

export const StepWizard = ({
  steps,
  translationKey,
  disabledLabels,
  hideTitle,
  errors,
  minWidth,
  withDialogComponents = false,
  loadingContent,
  FooterElements,
  shouldResetStepper,
}: StepWizardProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const currentStep = steps[activeStep];

  const { t } = useTranslation('common', { keyPrefix: 'stepWizard' });

  useUpdateEffect(() => {
    if (shouldResetStepper) {
      setActiveStep(0);
    }
  }, [shouldResetStepper]);

  const prevStep = () => {
    if (activeStep > 0) {
      if (steps[activeStep - 1].disabled && !!steps[activeStep - 2]) {
        setActiveStep((prev) => prev - 2);
      } else {
        setActiveStep((prev) => prev - 1);
      }
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length - 1) {
      if (steps[activeStep + 1].disabled && !!steps[activeStep + 2]) {
        setActiveStep((prev) => prev + 2);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const changeStep =
    (step: number): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      const { isValid = true, labelButton } = currentStep;

      if (activeStep === step) {
        return;
      }

      if (labelButton) {
        if (!labelButton.changeStepManually) {
          labelButton.onClick?.(e);
          if (isValid) {
            setActiveStep(step);
          }
        } else {
          labelButton.onClick?.(() => setActiveStep(step))(e);
        }
      } else {
        setActiveStep(step);
      }
    };

  // useful for handling dialog content scroll or custom actions
  // eg. stepper - steps fixed, step content scrollable
  const { ContentBox, ActionsBox, GeneralBox } = useMemo(
    () => (withDialogComponents ? dialogComponents : standardComponents),
    [withDialogComponents],
  );

  return (
    <GeneralBox>
      <Stack spacing={4}>
        {!hideTitle && (
          <Typography p={2.5} pb={0} variant="h5">
            {t(`${translationKey}.title`)}
          </Typography>
        )}
        <Stepper
          nonLinear
          activeStep={activeStep}
          alternativeLabel
          sx={{ overflow: 'hidden', px: 2.5 }}
        >
          {steps.map(({ required, disabled }, step) => (
            <Step key={step} data-testid={'step'}>
              <StepButton
                optional={
                  disabled && (
                    <NotAllowedLabel step={step} translationKey={translationKey} />
                  )
                }
                onClick={changeStep(step)}
                disabled={disabled || disabledLabels || currentStep.isValid === false}
                icon={errors?.[step] && <ErrorIcon color="error" />}
              >
                {t(`${translationKey}.steps.${step}`, '') + (required ? '*' : '')}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <ContentBox>
        <Box
          minWidth={minWidth ?? 'unset'}
          pt={1}
          sx={{
            height: '100%',
            boxSizing: 'content-box',
            overflowY: loadingContent ? 'hidden' : 'auto',
            overflowX: 'hidden',
            ...(withDialogComponents ? {} : { px: 2, scrollbarGutter: 'stable' }),
          }}
        >
          {loadingContent ? <LoadingElement /> : currentStep.Component}
        </Box>
      </ContentBox>
      {/* Styles are temporary */}
      <ActionsBox
        {...(withDialogComponents
          ? {
              sx: ({ mixins }) => ({
                ...mixins.modalFooter,
                maxWidth: minWidth,
                boxSizing: 'content-box',
              }),
            }
          : { mt: 'auto', p: 2.5, pt: 1, maxWidth: minWidth, boxSizing: 'content-box' })}
      >
        <StepFooter
          step={currentStep}
          prevStep={prevStep}
          nextStep={nextStep}
          Elements={FooterElements}
        />
      </ActionsBox>
    </GeneralBox>
  );
};
