import isTruthyValueInObject from '../isTruthyValueInObject';

export const cleanObject = <T extends object>(obj: T | null): T | null =>
  obj
    ? (Object.entries(obj)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return { key, value: value.length ? value : null };
          }
          if (value !== null && typeof value === 'object') {
            return { key, value: isTruthyValueInObject(value) ? value : null };
          }
          return { key, value };
        })
        .reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {}) as T)
    : null;
