import { forwardRef, Ref, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CustomLink from 'atoms/Link';
import { faqNavItems, lists } from './static-data';
import { collapsedNavButtonStyles, expandedNavButtonStyles } from './styles';
import { NavItemProps } from './types';
import { isMatchPath } from './utils';

const NavItem = ({
  subLinks = [],
  to,
  label,
  isSelected,
  disabled,
  select,
  Icon,
  isSidebarExpanded,
  resetSelection,
  external,
  fetchRedirectionLink,
  customClickAction,
  forFaq,
}: NavItemProps) => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!external) {
      const matched = isMatchPath(pathname, to, subLinks);
      if (matched && !isSelected) {
        select();
      }
      const anyMatched = Object.values(forFaq ? faqNavItems : lists)
        .flat()
        .some(({ to, subLinks = [] }) => isMatchPath(pathname, to, subLinks));

      if (!anyMatched) {
        resetSelection();
      }
    }
  }, [pathname, external]);

  const Component = useMemo(
    () =>
      forwardRef((props, ref: Ref<HTMLAnchorElement> | undefined) => (
        <CustomLink
          ref={ref}
          {...props}
          to={to}
          onClick={select}
          title={i18n.language === 'cimode' ? label : ''}
          external={external}
          fetchRedirectionLink={fetchRedirectionLink}
          customClickAction={customClickAction}
        />
      )),
    [],
  );

  return (
    <ListItemButton
      selected={isSelected}
      disabled={disabled}
      component={Component}
      sx={isSidebarExpanded ? expandedNavButtonStyles : collapsedNavButtonStyles}
    >
      {isSidebarExpanded ? (
        <>
          <ListItemIcon sx={{ color: 'inherit', minWidth: 'unset' }}>
            {Icon && <Icon fontSize="medium" />}
          </ListItemIcon>
          <ListItemText
            sx={{ m: 0 }}
            primary={
              <Typography variant="h6" sx={{ color: 'inherit' }}>
                {label}
              </Typography>
            }
          />
        </>
      ) : (
        <ListItemAvatar sx={({ mixins }) => mixins.sidebarIcon}>
          <Avatar>{Icon && <Icon fontSize="medium" />}</Avatar>
        </ListItemAvatar>
      )}
    </ListItemButton>
  );
};

export default NavItem;
