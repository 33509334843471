const getFileNameWithoutExtension = (name: string) => {
  let fileName = name;

  if (name.includes('.')) {
    const lastIndex = name.lastIndexOf('.');
    fileName = name.substring(0, lastIndex);
  }

  return fileName;
};

export default getFileNameWithoutExtension;
