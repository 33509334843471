import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { FilePreview } from './FilePreview';
import { DocumentPreviewElementProps } from './types';

const StaticPreview = ({
  fileName,
  closePreview,
  ...props
}: DocumentPreviewElementProps) => {
  const { t } = useTranslation('modals', { keyPrefix: 'uploadDocumentsModal' });

  return (
    <Box flex={2}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Typography variant="h5">{fileName}</Typography>
          <Button variant="outlined" role="secondary" size="small" onClick={closePreview}>
            {t('closePreview')}
          </Button>
        </Box>
        <FilePreview {...props} />
      </Box>
    </Box>
  );
};

export default StaticPreview;
