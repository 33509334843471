import { ProductPartiesForm } from 'modules/ProductParties/types';
import { RequestStateReportBody } from './types';

export const transformRequestStateReportData = ({
  landlords,
  tenants,
}: ProductPartiesForm): RequestStateReportBody => {
  const [primaryLandlord, secondaryLandlord] = landlords;
  const [primaryTenant, secondaryTenant] = tenants;

  return {
    primaryTenantId: primaryTenant.id,
    primaryTenantPartnerId: primaryTenant.partnerId || undefined,
    primaryTenantRepresentativeId: primaryTenant.representativeId || undefined,
    secondaryTenantId: secondaryTenant?.id || undefined,
    primaryLandlordId: primaryLandlord.id,
    primaryLandlordPartnerId: primaryLandlord.partnerId || undefined,
    primaryLandlordRepresentativeId: primaryLandlord.representativeId || undefined,
    secondaryLandlordId: secondaryLandlord?.id || undefined,
  };
};
