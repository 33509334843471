import { useCallback, useEffect, useState } from 'react';
import { getEstateDetails } from 'api/estate/requests';
import { EstateDetailsForm } from 'api/estate/types';
import { getEstateDetailsData } from './utils';

export const useEstateDetails = (estateId: string) => {
  const [estateDetails, setEstateDetails] = useState<EstateDetailsForm | null>(null);

  const fetchEstateDetails = useCallback(async () => {
    const { ok, response } = await getEstateDetails(estateId);
    if (ok) {
      const data = getEstateDetailsData(response);
      setEstateDetails(data);
    }
  }, [estateId]);

  useEffect(() => {
    fetchEstateDetails();
  }, [fetchEstateDetails]);

  return { estateDetails, fetchEstateDetails };
};
