import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
} from 'libphonenumber-js/max';
import yup from 'utils/yup';

const phoneSchema = yup
  .object({
    number: yup
      .string()
      .default('')
      .when('countryId', {
        is: 'BE',
        then: (schema) =>
          schema.transform((value: string) =>
            value.startsWith('0') ? value.slice(1) : value,
          ),
      }),
    countryId: yup.string<CountryCode>().default('BE'),
    code: yup.string().default('+32'),
  })
  .label('phone')
  .test('phone-validation', (value, { createError }) => {
    if (value?.number) {
      const phoneNumber = `${value.code} ${value.number}`;
      const isValid = isValidPhoneNumber(phoneNumber);
      const error = validatePhoneNumberLength(phoneNumber);

      if (!isValid || error) {
        switch (error) {
          case 'INVALID_COUNTRY':
            return createError({
              type: 'invalid-country',
              message: { key: 'phone.invalidCountry' },
            });
          default:
            return createError({
              type: 'invalid-number',
              message: { key: 'phone.invalidNumber' },
            });
        }
      }

      const numberType = parsePhoneNumberFromString(phoneNumber)?.getType();
      if (isValid && numberType !== 'MOBILE') {
        return createError({
          type: 'invalid-number-type',
          message: { key: 'phone.invalidNumberType' },
        });
      }
    }
    return true;
  });

export default phoneSchema;
