import { PostCodesWithCities } from 'api/config/types';

export const DEFAULT_PARAMS: PostCodesWithCities = {
  postCode: '',
  city: '',
};

export const INPUT_LENGTH_TO_FETCH_OPTIONS = {
  postCode: 4,
  city: 2,
} as const;
