import { MetaData } from './types';

export const initMeta: MetaData = {
  count: 0,
  limit: 10,
  offset: 0,
  total: 0,
};

export const ROWS_PER_PAGE = [5, 10, 25];
