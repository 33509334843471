import { CustomDateOptions } from 'types/yup';
import yup from 'utils/yup';
import { isValidNrnAndDateOfBirth } from '../nationalRegisterNumber/schema';

export const dateOfBirthOptions: CustomDateOptions = {
  disableFuture: true,
  disableToday: true,
};

export const commonDateOfBirthSchema = yup
  .string()
  .label('dateOfBirth')
  .nullable()
  .default(null)
  .customDate({
    ...dateOfBirthOptions,
    minPastRange: { key: 'dateOfBirth.minRange', years: 17, months: 6 },
    maxPastRange: { key: 'dateOfBirth.maxRange', years: 120 },
  });

const dateOfBirthSchema = commonDateOfBirthSchema.customWhen({
  is: ({ parent }, dateOfBirth) =>
    parent.nationalRegisterNumber &&
    !isValidNrnAndDateOfBirth(parent.nationalRegisterNumber, dateOfBirth),
  then: (schema) => schema.invalid('dateOfBirth.invalidNrn'),
});

export default dateOfBirthSchema;
