import yup from 'utils/yup';

const contractSigningDate = yup
  .string()
  .nullable()
  .customDate({
    max: ({ entryDate }) => ({
      key: 'signingDateAfterEntryDate',
      date: Date.parse(entryDate),
    }),
    maxFutureRange: { months: 6 },
    maxPastRange: { years: 20 },
  });

export default contractSigningDate;
