export const testStringToJSON = (text: unknown) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    const json = JSON.parse(text);
    return typeof json === 'object';
  } catch (error) {
    return false;
  }
};
