import { useState } from 'react';
import { DocumentType } from 'api/documents/types';

export const useDocumentsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [missingDocumentType, setMissingDocumentType] = useState<DocumentType | null>(
    null,
  );

  const openModal = (missingDocumentType?: DocumentType) => {
    setIsOpen(true);
    setMissingDocumentType(missingDocumentType ?? null);
  };

  const closeModal = () => {
    setIsOpen(false);
    setMissingDocumentType(null);
  };

  return { isOpen, missingDocumentType, openModal, closeModal };
};
