const NRN_DIVISOR = 97;

const getNRNCheckNumber = (value: string, year2000 = false) => {
  const nineDigits = Number(value.slice(0, 9));
  const digits = year2000 ? nineDigits + 2000000000 : nineDigits;
  const remainder = digits % NRN_DIVISOR;

  return NRN_DIVISOR - remainder;
};

export default getNRNCheckNumber;
