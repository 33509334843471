import { useCallback } from 'react';
import { useAccount } from '@azure/msal-react';
import Table from 'templates/Table';
import { GetTableList } from 'templates/Table/types';
import { getDossiers } from 'api/dossier/requests';
import { FetchedDossierListItem } from 'api/dossier/types';
import { getLinkToDossier } from 'pages/Dossiers/List/static-data';
import { dashboardDossiersColumns } from './static-data';

export const AssignedDossiers = () => {
  const account = useAccount();

  const get: GetTableList<FetchedDossierListItem> = useCallback(
    (params) =>
      getDossiers({
        ...params,
        responsibleUsersIds: account?.localAccountId,
        withProductsStatuses: true,
        statuses: 'Draft,InTreatment',
      }),
    [],
  );

  return (
    <Table
      tKey="dossiers"
      get={get}
      columns={dashboardDossiersColumns}
      rowRedirectionLink={getLinkToDossier}
    />
  );
};
