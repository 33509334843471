import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import StorageKeys from 'consts/storage';
import Cookies from 'js-cookie';
import { getBusiness } from 'api/businesses/requests';
import { BusinessDetails } from 'api/businesses/types';

const BackOfficeContext = createContext<{
  impersonate: (id: string) => Promise<void>;
  finishImpersonating: () => void;
  impersonateId: string;
  impersonatedBusinessData: BusinessDetails | null;
}>({
  impersonate: async () => {},
  finishImpersonating: () => {},
  impersonateId: '',
  impersonatedBusinessData: null,
});

const BackOfficeProvider = ({ children }: PropsWithChildren) => {
  const [impersonatedBusinessData, setImpersonatedBusinessData] =
    useState<BusinessDetails | null>(null);
  const [impersonateId, setImpersonateId] = useState(
    () => Cookies.get(StorageKeys.ImpersonatedBusinessId) || '',
  );

  const { t } = useTranslation('errors');

  const impersonate = async (id: string) => {
    const { ok, response, status } = await getBusiness(id);
    if (ok) {
      Cookies.set(StorageKeys.ImpersonatedBusinessId, id);
      setImpersonatedBusinessData(response);
      setImpersonateId(id);
    } else {
      toast.error(t(`business.${status}`, t('business.fetch')));
    }
  };

  useEffect(() => {
    if (impersonateId && !impersonatedBusinessData) {
      impersonate(impersonateId);
    }
  }, []);

  const finishImpersonating = () => {
    Cookies.remove(StorageKeys.ImpersonatedBusinessId);
    setImpersonateId('');
    setImpersonatedBusinessData(null);
  };

  return (
    <BackOfficeContext.Provider
      value={{
        impersonate,
        finishImpersonating,
        impersonateId,
        impersonatedBusinessData,
      }}
    >
      {children}
    </BackOfficeContext.Provider>
  );
};

export { BackOfficeContext, BackOfficeProvider };
