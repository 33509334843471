export const INIT_RECORDS_TO_LOAD = 10;
export const ADDITIONAL_RECORDS_TO_LOAD = 5;

const HISTORY_ACTIVITIES = ['Estate', 'Dossier', 'Person', 'Document'] as const;

export const ESTATE_HISTORY_ACTIVITIES = HISTORY_ACTIVITIES;
export const PERSON_HISTORY_ACTIVITIES = HISTORY_ACTIVITIES;
export const DOSSIER_HISTORY_ACTIVITIES = [
  'DossierDetails',
  'Tenant',
  'Landlord',
  'Documents',
] as const;
