import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';

const EstateResetFormCheckbox = () => {
  const { t } = useTranslation();

  const { estateId, setShouldResetFormAfterSave, shouldResetFormAfterSave } =
    useContext(EstateFormContext);

  const isEditing = useMemo(() => !!estateId, [estateId]);

  return !isEditing ? (
    <FormControlLabel
      sx={{ width: '100%' }}
      label={t('stepWizard.estate.createCopy')}
      control={
        <Checkbox
          checked={shouldResetFormAfterSave}
          onChange={(_e, checked) => setShouldResetFormAfterSave(checked)}
        />
      }
    />
  ) : null;
};

export default EstateResetFormCheckbox;
