import { BaseSyntheticEvent, useContext, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { LegalPersonFormContext } from 'contexts/personForm/legalPersonFormContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { addLegalPerson, updateLegalPerson } from 'api/person/legal/requests';
import { LegalPersonFormData } from 'api/person/legal/types';
import { LegalPersonFormProps } from '../types';

interface Props {
  onSaveSuccess: LegalPersonFormProps['handleSave'];
}

const useSubmitLegalPersonForm = ({ onSaveSuccess }: Props) => {
  // TODO: Add saving state to disable all inputs in the form during saving
  const { saveRelations, personId } = useContext(LegalPersonFormContext);
  const [savedId, setSavedId] = useState(personId);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { reset, formState } = useFormContext<LegalPersonFormData>();

  const {
    saveDocuments,
    isAnyDocumentEmpty,
    markEmptyDocuments,
    documents,
    validateDocuments,
  } = useContext(FilesUploadContext);

  const saveForm = async (
    event: BaseSyntheticEvent | undefined,
    person: LegalPersonFormData,
  ) => {
    let isAnyDocumentToSave = false;
    let shouldProceed = true;
    let personId = savedId;

    if (documents.length && documents[0].documentType) {
      const onValid = () => {
        isAnyDocumentToSave = true;
      };
      const onError = () => {
        shouldProceed = false;
      };
      await validateDocuments(event, onValid, onError);
    }

    if (shouldProceed && formState.isDirty) {
      if (personId) {
        const { ok } = await updateLegalPerson(personId, person);
        shouldProceed = ok;
      } else {
        const { ok, response } = await addLegalPerson(person);
        shouldProceed = ok;
        personId = ok ? response.id : null;
      }
    }

    if (shouldProceed && personId) {
      setSavedId(personId);
      reset(person, { keepDirty: false });

      const ownersAndRelationsSaved = await saveRelations(personId, person);
      const { isAnyDocumentWithError } = isAnyDocumentToSave
        ? await saveDocuments(event, personId)
        : { isAnyDocumentWithError: false };

      if (!ownersAndRelationsSaved || isAnyDocumentWithError) {
        shouldProceed = false;
      }

      if (shouldProceed) {
        // TODO: Disable save button when form is not dirty
        if (formState.isDirty) {
          toast.success(t('successMessages.PersonSavedSuccessfully'), {
            toastId: personId,
            autoClose: 5000,
          });
        }
        if (onSaveSuccess) {
          onSaveSuccess(person, personId);
        } else {
          navigate(routes.legalPerson(personId), { withoutPrompt: true });
        }
      }
    }
  };

  const onSubmit = (): SubmitHandler<LegalPersonFormData> => (form, event) => {
    return isAnyDocumentEmpty ? markEmptyDocuments() : saveForm(event, form);
  };
  return onSubmit;
};

export default useSubmitLegalPersonForm;
