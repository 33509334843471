import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ProductActionContent from './ProductActionContent';
import { ProductActionComponentProps } from './types';

const ProductActionConfirmation = ({
  sendRequest,
  product,
}: ProductActionComponentProps) => {
  const { t } = useTranslation('modals', { keyPrefix: 'productActionModal' });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendRequest();
  };

  return (
    <ProductActionContent onSubmit={handleSubmit}>
      <Typography>{t(`${product.actionName}.content`, '')}</Typography>
    </ProductActionContent>
  );
};

export default ProductActionConfirmation;
