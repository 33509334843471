import i18n from 'i18n';
import { formatDate } from 'utils/date';
import { DocumentData, DocumentScope } from 'api/documents/types';
import { updateDossierDocument } from 'api/dossier/requests';
import { updateEstateDocument } from 'api/estate/requests';
import { updateLegalPersonDocument } from 'api/person/legal/requests';
import { updateNaturalPersonDocument } from 'api/person/natural/requests';
import { DocumentsTableForm, DocumentUpdate } from './types';

const updateDocument: DocumentUpdate = {
  estate: updateEstateDocument,
  naturalPerson: updateNaturalPersonDocument,
  legalPerson: updateLegalPersonDocument,
  dossier: updateDossierDocument,
};

export const saveEditedDocument = (
  {
    documentId,
    entityId,
    scope,
  }: { documentId: number; entityId: string; scope: DocumentScope },
  { date, ...newData }: DocumentsTableForm,
) => {
  const data = {
    ...newData,
    date: date ? formatDate(new Date(date), 'yyyy-MM-dd') : null,
  };
  return updateDocument[scope](entityId, documentId, data);
};

export const getDocumentTypeValue = (
  {
    documentType,
    otherCustomType,
  }: Pick<DocumentData, 'documentType' | 'otherCustomType'>,
  scope: DocumentScope,
) =>
  documentType === 'Other' && otherCustomType
    ? otherCustomType
    : i18n.t(`documents:${scope}.${documentType}`, documentType);
