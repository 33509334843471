import { Box, Chip, Stack, Typography } from '@mui/material';
import { ProductDetails } from 'modules/ProductCard';
import { SubProductAction, SubProductTitle } from 'modules/ProductCard/SubProducts';
import { normalizeString } from 'utils/string';
import { SubProductInterface } from 'api/dossier/products/types';
import SubProductDocuments from './SubProductDocuments';

const SubProductContent = ({
  name,
  active,
  status,
  action,
  details,
  index,
  statusComment,
  documentActions,
}: SubProductInterface) => (
  <Stack
    height="100%"
    minHeight={80}
    width="100%"
    justifyContent="space-between"
    p={1.5}
    gap={1.5}
    flex={1}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <SubProductTitle name={name} index={index} active={active} />
      <Chip
        size="small"
        label={normalizeString(status)}
        sx={({ mixins }) => ({ bgcolor: mixins.getProductStatusColor(status) })}
      />
    </Box>
    {details && <ProductDetails details={details} />}
    {statusComment && (
      <Typography
        variant="caption"
        fontStyle="italic"
        textAlign="center"
        color="GrayText"
      >{`"${statusComment}"`}</Typography>
    )}
    <Box display="flex" justifyContent="space-between" mt={0.5}>
      {!!documentActions?.length && (
        <SubProductDocuments documentActions={documentActions} />
      )}
      {action && (
        <Box ml="auto">
          <SubProductAction {...action} />
        </Box>
      )}
    </Box>
  </Stack>
);

export default SubProductContent;
