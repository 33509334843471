export const getProductButtonGridSize = (productsCount: number) => {
  switch (productsCount) {
    case 1:
      return 12;
    case 2:
      return 6;
    default:
      return 4;
  }
};
