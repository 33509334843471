import { FieldPath, FieldValues } from 'react-hook-form';

export const getNameWithPrefix = <TFieldName extends FieldPath<FieldValues>>(
  name: TFieldName,
  prefix?: string,
) => (prefix ? `${prefix}.${name}` : name) as TFieldName;

export const formatFieldName = <TFieldName extends FieldPath<FieldValues>>(
  name?: TFieldName | TFieldName[],
  prefix?: string,
) => {
  if (Array.isArray(name)) {
    return name.map((name) => getNameWithPrefix(name, prefix));
  } else if (name) {
    return getNameWithPrefix(name, prefix);
  }
};

export const destructureValuesByPrefix = (
  prefix: string | undefined,
  initDefaultValues: Partial<FieldValues> | undefined,
) => {
  if (!prefix) {
    return initDefaultValues;
  }

  let defaultValues = initDefaultValues;
  if (prefix.includes('.')) {
    const keys = prefix.split('.');
    for (const key of keys) {
      defaultValues = defaultValues?.[key];
    }
    return defaultValues;
  }
  return defaultValues?.[prefix];
};
