import errorOccurred from 'assets/errorOccurred.png';
import { ThemedStyle } from 'types/themedStyle';

export const ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT = 'md';

export const layoutWrapperStyles: ThemedStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'auto',
};

export const layoutContainerStyles: ThemedStyle = ({ mixins }) => ({
  height: `calc(100% - ${mixins.toolbar.minHeight}px)`,
  marginLeft: {
    xs: `${mixins.closedDrawer.width}px`,
    [ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT]: 0,
  },
  marginTop: 'auto',
  overflow: 'auto',
  flexGrow: 1,
  scrollbarGutter: 'stable',
  px: { xs: 2, sm: 3 },
});

export const errorPageStyles: ThemedStyle = {
  backgroundImage: `url(${errorOccurred})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  pt: 1,
  pr: 1,
  width: '100%',
  height: '100%',
  color: 'primary.main',
  fontWeight: 700,
};
