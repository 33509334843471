import { Language } from 'types/language';
import { Modify } from 'types/modify';
import api from 'api';
import { BACKEND_ADDRESS } from 'api/const';
import { FetchedDocumentsConfig } from 'api/documents/types';
import { LanguageType } from 'api/person/types';
import { UserRolesResponse } from 'api/users/types';
import { getSearchParamsUrl } from 'api/utils';
import {
  Countries,
  EstateDestinationResponse,
  EstateEpcTypeResponse,
  FeatureFlagsResponse,
  IbanData,
  Nationalities,
  PostCodesWithCities,
  PostCodesWithCitiesTranslations,
  RedirectionLink,
  UserSettings,
} from './types';

export const getEstateDestinationsConfig = () =>
  api.get<EstateDestinationResponse>('estateTypeConfigurations', {
    baseURL: BACKEND_ADDRESS,
  });

export const getEstateEpcTypes = (postCode: string) =>
  api.get<EstateEpcTypeResponse>(getSearchParamsUrl('epcTypes', { postCode }), {
    baseURL: BACKEND_ADDRESS,
  });

export const getNationalities = () =>
  api.get<Nationalities>('nationalities', { baseURL: BACKEND_ADDRESS });

export const getCountries = (lng: string) =>
  api.get<Countries>(getSearchParamsUrl('countries', { lng }), {
    baseURL: BACKEND_ADDRESS,
  });

export const getUeCountries = (lng: string) =>
  api.get<Countries>(getSearchParamsUrl('countries/eu', { lng }), {
    baseURL: BACKEND_ADDRESS,
  });

export const getPostCodesWithCity = (
  params: Modify<PostCodesWithCities, { lng: Language }>,
) =>
  api.get<PostCodesWithCitiesTranslations[] | null>(
    getSearchParamsUrl('postCodes', params),
    {
      baseURL: BACKEND_ADDRESS,
    },
  );
export const getStreetTranslations = (params: {
  name: string;
  translationTo: Language;
}) =>
  api.get<{ name: string }>(getSearchParamsUrl('streetAlternativeName', params), {
    baseURL: BACKEND_ADDRESS,
  });

export const validateVat = (params: { prefix: string; number: string }) =>
  api.get<{ isValid: boolean }>(getSearchParamsUrl('validateVat', params), {
    baseURL: BACKEND_ADDRESS,
  });

export const uploadLocales = (language: string, data: FormData) =>
  api.put(`locales/${language}`, data, { baseURL: BACKEND_ADDRESS });

export const getLocales = (language: string, fileName: string) =>
  api.get<object>(getSearchParamsUrl(`locales/${language}`, { fileName }), {
    baseURL: BACKEND_ADDRESS,
  });

export const getUserSettings = () =>
  api.get<UserSettings>('userSettings', { baseURL: BACKEND_ADDRESS });

export const updateUserLanguage = (language: LanguageType) =>
  api.patch<void>('userSettings', { language }, { baseURL: BACKEND_ADDRESS });

export const getUserRoles = () =>
  api.get<UserRolesResponse>('roles', { baseURL: BACKEND_ADDRESS });

export const getRedirectionLink = (link: string) =>
  api.get<RedirectionLink>(link, { baseURL: BACKEND_ADDRESS });

export const getIbanData = async (iban: string) =>
  api.get<IbanData>(`https://openiban.com/validate/${iban}?getBIC=true`);

export const getDocumentsConfig = async () =>
  api.get<FetchedDocumentsConfig>('documentsConfig', { baseURL: BACKEND_ADDRESS });

export const getFeatureFlags = async () =>
  api.get<FeatureFlagsResponse>('featureFlags', { baseURL: BACKEND_ADDRESS });
