import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Menu, MenuItem, MenuList } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { DossierPermissions } from 'consts/permissions';
import useMenu from 'hooks/useMenu';
import { SwitchIcon } from 'icons';
import hasPermission from 'utils/hasPermission';
import { updateRentalDossierStatus } from 'api/dossier/requests';
import { DossierStatus } from 'api/dossier/types';
import { DossierStatusButtonProps } from './types';
import { filterDossierStatuses } from './utils';

const DossierStatusButton = ({
  dossierId,
  fetchDossier,
  status,
}: DossierStatusButtonProps) => {
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const { t } = useTranslation(['common', 'enums']);
  const { anchorEl, closeMenu, openMenu } = useMenu();

  const onChangeStatus = async (status: DossierStatus) => {
    closeMenu();
    setUpdatingStatus(true);
    const { ok } = await updateRentalDossierStatus(dossierId, status);
    if (ok) {
      await fetchDossier();
    }
    setUpdatingStatus(false);
  };

  const disabled = updatingStatus || !hasPermission(DossierPermissions.Write);

  return (
    <>
      <TooltipIconButton
        size="small"
        Icon={
          updatingStatus ? (
            <CircularProgress size={20} />
          ) : (
            <SwitchIcon fontSize="small" />
          )
        }
        tooltipTitle={disabled ? '' : t('changeStatus')}
        disabled={disabled}
        onClick={openMenu}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuList
          disablePadding
          sx={{
            '&:focus-visible, .MuiTouchRipple-root:focus-visible': {
              outline: 'none',
            },
          }}
        >
          {filterDossierStatuses(status).map(({ translationKey, value }) => (
            <MenuItem
              key={value}
              sx={{ minWidth: 100 }}
              onClick={() => onChangeStatus(value)}
            >
              {t(translationKey, value)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default DossierStatusButton;
