import { useContext, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { isTruthyValueInObject } from 'utils/object';
import {
  addRentalDossier,
  updateRentalConditions,
  updateRentalDossier,
  updateResidents,
} from 'api/dossier/requests';
import { DossierFormInterface } from '../types';

const useSubmitDossierForm = () => {
  const { dossierId, mode } = useContext(DossierFormContext);
  const [savedId, setSavedId] = useState(dossierId);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { formState } = useFormContext<DossierFormInterface>();

  const saveDossier: SubmitHandler<DossierFormInterface> = async ({
    status,
    ownReference,
    checklistItems,
    estateId,
    landlords,
    tenants,
    responsibleUsers,
    rentalConditions,
    residents,
  }) => {
    let shouldProceed = true;
    let dossierId = savedId;

    if (formState.isDirty || mode === 'creation') {
      if (dossierId) {
        const { ok } = await updateRentalDossier(dossierId, {
          status,
          ownReference,
          landlords: landlords.map(({ id, ...person }) => ({ personId: id, ...person })),
          responsibleUsers: responsibleUsers.map(({ id }) => id),
          tenants: tenants.map(({ id, ...person }) => ({ personId: id, ...person })),
        });
        shouldProceed = ok;
      } else {
        const { ok, response } = await addRentalDossier({
          checklistItems: checklistItems.filter(({ checked }) => checked),
          estateId: estateId || '',
          landlords: landlords.map(({ id, ...person }) => ({ personId: id, ...person })),
          responsibleUsers: responsibleUsers.map(({ id }) => id),
          tenants: tenants.map(({ id, ...person }) => ({ personId: id, ...person })),
        });
        shouldProceed = ok;
        dossierId = ok ? response.id : null;
      }
    }

    if (shouldProceed && dossierId) {
      setSavedId(dossierId);

      if (
        rentalConditions &&
        isTruthyValueInObject(formState.dirtyFields.rentalConditions)
      ) {
        const { ok } = await updateRentalConditions(dossierId, rentalConditions);
        shouldProceed = ok;
      }

      if (residents && isTruthyValueInObject(formState.dirtyFields.residents)) {
        const { ok } = await updateResidents(dossierId, residents);
        shouldProceed = ok;
      }

      if (shouldProceed) {
        if (formState.isDirty) {
          toast.success(t('successMessages.DossierSavedSuccessfully'), {
            toastId: dossierId,
            autoClose: 5000,
          });
        }
        navigate(routes.rentalDossier(dossierId), { withoutPrompt: true });
      }
    }
  };

  return saveDossier;
};

export default useSubmitDossierForm;
