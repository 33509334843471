import { useCallback, useEffect, useState } from 'react';
import { getUserRolesOptions } from 'hooks/useUserFields/utils';
import { getUserRoles } from 'api/config/requests';
import { SelectOption } from 'components/inputs/Select/types';

export const useGetUserRoles = () => {
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  const fetchUserRoles = useCallback(async () => {
    const { ok, response } = await getUserRoles();
    if (ok) {
      const userRoles = getUserRolesOptions(response.roles);
      setRoleOptions(userRoles);
    }
  }, []);

  useEffect(() => {
    fetchUserRoles();
  }, [fetchUserRoles]);

  return roleOptions;
};
