import { useState } from 'react';
import { getIbanData } from 'api/config/requests';

export const useBicFromIban = () => {
  const [loadingBic, setLoadingBic] = useState(false);

  const getBicFromIban = async (iban: string) => {
    if (iban) {
      setLoadingBic(true);
      const { ok, response } = await getIbanData(iban);
      setLoadingBic(false);
      if (ok) {
        return response.bankData.bic || null;
      }
    }
    return null;
  };

  return { getBicFromIban, loadingBic };
};
