import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu } from '@mui/material';
import TooltipMenuItem from 'atoms/TooltipMenuItem';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useMenu from 'hooks/useMenu';
import { KebabMenuIcon } from 'icons';
import { TableAction, TableTranslationKey } from '../types';

interface ActionCellProps<Key extends TableTranslationKey = TableTranslationKey> {
  tKey: Key;
  actions: TableAction<Key>[];
  updateRows: () => Promise<void>;
}

const ActionCell = ({ tKey, actions, updateRows }: ActionCellProps) => {
  const { anchorEl, closeMenu, openMenu } = useMenu();
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const { t } = useTranslation('table', { keyPrefix: tKey });

  return (
    <>
      <IconButton onClick={openMenu} disableRipple size="small">
        <KebabMenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {actions
          .filter(({ hidden }) => !hidden)
          .map(({ name, reason, onClick, disabled }) => (
            <TooltipMenuItem
              key={name}
              disabled={disabled}
              reason={reason ? t(`tooltips.${reason}`, reason) : undefined}
              onClick={() => {
                onClick({ navigate, setUpAndOpenConfirmationModal, updateRows });
                closeMenu();
              }}
            >
              {t(`actions.${name}`, name)}
            </TooltipMenuItem>
          ))}
      </Menu>
    </>
  );
};

export default ActionCell;
