import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';

interface Props {
  handleUploadedFile: (file: File | null) => void;
  accept?: string[];
  isMissing?: boolean;
}

export const UploadButton = ({ handleUploadedFile, accept = [], isMissing }: Props) => {
  const [wrongFileType, setWrongFileType] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation(['common', 'validation']);

  const openFileUploader = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files?.[0] || null;
    if (accept.length && file && !accept.includes(file.type)) {
      setWrongFileType(true);
    } else {
      handleUploadedFile(file);
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Button onClick={openFileUploader}>
        <input
          ref={inputRef}
          type="file"
          style={{
            display: 'none',
          }}
          onChange={handleFileUpload}
          multiple={false}
          accept={accept.join(',')}
        />
        {t('upload')}
      </Button>
      {wrongFileType && (
        <Typography variant="caption" color="error">
          {t('validation:uploadButton.wrongFileType')}
        </Typography>
      )}
      {isMissing && (
        <Typography variant="caption" color="error">
          {t('validation:uploadButton.required')}
        </Typography>
      )}
    </Stack>
  );
};
