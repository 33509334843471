import api from 'api';
import { apiUrl } from 'api/const';
import { ResponseWithId } from 'api/types';
import { ProductInterface } from './types';

export const addProduct = (dossierId: string, data: { productName: string }) =>
  api.patch<ResponseWithId>(apiUrl.products(dossierId), data);

export const getProducts = (dossierId: string) =>
  api.get<ProductInterface[]>(apiUrl.products(dossierId));

export const getProduct = (dossierId: string, productId: string) =>
  api.get<ProductInterface>(apiUrl.product(dossierId, productId));
