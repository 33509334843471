import getLng from 'utils/getLng';
import api from 'api';
import { HistoryResponseInterface } from 'api/common/types';
import { apiUrl } from 'api/const';
import { UpdateDocumentInterface } from 'api/documents/types';
import { ResponseWithId, UrlParams } from 'api/types';
import { getBaseUrl } from 'api/utils';
import {
  AttachDossierDocumentData,
  AttachDossierTypeDocumentData,
  CustomDossierTodoItem,
  CustomDossierTodoItemBody,
  DossierDocumentsData,
  DossierPostInterface,
  DossierStatus,
  DossierUpdateInterface,
  DossierViewInterface,
  FetchedDossierChecklistItem,
  FetchedDossiersList,
  RentalConditions,
  ResidentsForm,
} from './types';
import {
  transformDossierResponse,
  transformPutData,
  transformRentalConditions,
  transformResidents,
} from './utils';

export const getRentalDossierChecklist = (businessId?: string) =>
  api.get<FetchedDossierChecklistItem[]>(apiUrl.rentalDossiersChecklist, {
    baseURL: getBaseUrl(businessId),
  });

// TODO: Pass DossierFormInterface
export const addRentalDossier = (data: DossierPostInterface) =>
  api.post<ResponseWithId>(apiUrl.rentalDossiers, data);

// TODO: Pass DossierFormInterface
export const updateRentalDossier = (dossierId: string, data: DossierUpdateInterface) =>
  api.put<ResponseWithId>(apiUrl.rentalDossier(dossierId), transformPutData(data));

export const getRentalDossierViewData = (dossierId: string) =>
  api.get<DossierViewInterface>(apiUrl.rentalDossierView(dossierId), {
    params: { lng: getLng() },
    transformResponseOnSuccess: transformDossierResponse,
  });

export const updateRentalDossierStatus = (dossierId: string, status: DossierStatus) =>
  api.patch(apiUrl.rentalDossierStatus(dossierId), { dossierStatus: status });

export const getDossiers = (params: UrlParams) =>
  api.get<FetchedDossiersList>(apiUrl.dossiersWithParams({ ...params, lng: getLng() }), {
    transformResponseOnSuccess: ({ meta, data }) => ({
      meta,
      data: data.map(transformDossierResponse),
    }),
  });

export const getRentalConditions = (dossierId: string) =>
  api.get<RentalConditions | null>(apiUrl.rentalConditions(dossierId));

export const updateRentalConditions = (dossierId: string, data: RentalConditions) =>
  api.put<void>(apiUrl.rentalConditions(dossierId), transformRentalConditions(data), {
    customErrorMessage: [
      {
        status: 400,
        text: 'rentalConditions.save',
      },
      {
        status: 422,
        text: 'rentalConditions.missingEstateAddress',
        errorCode: 'MissingAddressInformation',
      },
    ],
  });

export const updateResidents = (dossierId: string, data: ResidentsForm) =>
  api.put<void>(apiUrl.residents(dossierId), transformResidents(data));

export const getDossierDocuments = (dossierId: string) =>
  api.get<DossierDocumentsData>(apiUrl.dossierDocuments(dossierId));

export const getDossierDocument = (dossierId: string, documentId: number) =>
  api.get<Blob>(apiUrl.dossierDocument(dossierId, documentId), { responseType: 'blob' });

export const uploadDossierDocument = (dossierId: string, formData: FormData) =>
  api.post<ResponseWithId<number>>(apiUrl.dossierDocuments(dossierId), formData);

export const updateDossierDocument = (
  dossierId: string,
  documentId: number,
  data: UpdateDocumentInterface,
) => api.put<void>(apiUrl.dossierDocument(dossierId, documentId), data);

export const getDossierDocumentFile = (
  dossierId: string,
  documentId: number,
  fileId: number,
) =>
  api.get<Blob>(apiUrl.rentalDossierDocumentFile(dossierId, documentId, fileId), {
    responseType: 'blob',
  });

export const attachDossierDocument = (
  dossierId: string,
  data: AttachDossierDocumentData,
) => api.post(apiUrl.attachDossierDocument(dossierId), data);

export const attachDossierTypeDocument = (
  dossierId: string,
  documentId: number,
  data: AttachDossierTypeDocumentData,
) => api.patch(apiUrl.attachDossierTypeDocument(dossierId, documentId), data);

export const removeDossierDocument = (dossierId: string, documentId: number) =>
  api.delete(apiUrl.removeDossierDocument(dossierId, documentId));

export const updateCustomTodoList = (
  dossierId: string,
  // temporary type; order will be required and type separate ype will be created after merging PR for products reorder
  list: { id: string; order?: number }[],
) => api.patch(apiUrl.updateCustomTodoList(dossierId), list);

export const createCustomTodoItem = (
  dossierId: string,
  customTodoItem: CustomDossierTodoItemBody,
) => api.post(apiUrl.customTodoItems(dossierId), customTodoItem);

export const getCustomTodoItems = (dossierId: string) =>
  api.get<CustomDossierTodoItem[]>(apiUrl.customTodoItems(dossierId));

export const updateCustomTodoItem = (
  dossierId: string,
  todoItemId: string,
  customTodoItem: CustomDossierTodoItem,
) => api.put(apiUrl.customTodoItem(dossierId, todoItemId), customTodoItem);

export const getDossierHistory = (dossierId: string, params: UrlParams) =>
  api.get<HistoryResponseInterface>(
    apiUrl.dossierHistoryWithParams(dossierId, { ...params, lng: getLng() }),
  );
