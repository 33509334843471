import {
  attachDossierDocument,
  attachDossierTypeDocument,
  removeDossierDocument,
} from 'api/dossier/requests';
import { AttachDocumentAction } from './types';

export const handleAttachDocument: AttachDocumentAction = async (
  { id, attached, attachmentId },
  { entityId, entityType, scope, dossierId },
) => {
  let response;
  if (scope !== 'dossier') {
    if (attached && attachmentId) {
      response = await removeDossierDocument(dossierId, attachmentId);
    } else {
      response = await attachDossierDocument(dossierId, {
        entityId,
        entityType,
        documentId: id,
      });
    }
  } else {
    response = await attachDossierTypeDocument(entityId, id, { attached: !attached });
  }
  return response;
};
