import { Box, Card } from '@mui/material';
import { FullPageCardProps } from './types';

export const FullPageCard = ({
  children,
  header,
  shrinkHeight = false,
  minWidth,
  maxWidth = { lg: 1024 },
  fullWidth = true,
}: FullPageCardProps) => (
  <Box
    display="flex"
    flexDirection="column"
    height={shrinkHeight ? 'unset' : '100%'}
    gap={1.5}
    pb={3}
    minWidth={{ xs: '-webkit-fill-available', md: 600, ...minWidth }}
    maxWidth={maxWidth}
    width={fullWidth ? '100%' : 'auto'}
    mx="auto"
  >
    {header}
    <Box height="100%" sx={{ overflowY: 'auto' }}>
      <Card sx={({ mixins }) => mixins.pageCard}>{children}</Card>
    </Box>
  </Box>
);
