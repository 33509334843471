import { Stack, Typography } from '@mui/material';

interface Props {
  primary: string;
  secondary: string;
  asTitle?: boolean;
}

const RowValues = ({ primary, secondary, asTitle }: Props) => (
  <Stack>
    <Typography fontWeight={asTitle ? 500 : 600} data-testid="primaryValue">
      {primary}
    </Typography>
    <Typography variant="caption" color="grey.600" data-testid="secondaryValue">
      {secondary}
    </Typography>
  </Stack>
);

export default RowValues;
