import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  CircularProgress,
} from '@mui/material';
import { SearchIcon } from 'icons';
import { useUpdateEffect } from 'usehooks-ts';
import InputWrapper from '../InputWrapper';
import NumberInput from '../NumberInput';
import TextInput from '../TextInput';
import SearchInputMenuOption from './SearchInputMenuOption';
import { SearchInputOption, SearchInputProps } from './types';
import { getFilterOptions, getInitOption } from './utils';

const SearchInput = <T extends object>({
  value,
  options,
  defaultOption,
  noOptionsText = 'default',
  onChange = () => {},
  onInputChange,
  onClose,
  creatable = false,
  filterConfig,
  loading = false,
  loadingText = 'default',
  disableClearable,
  shouldFilter = false,
  type = 'text',
  disabled,
  groupBy,
  fillInputWithOption = true,
  Icon,
  PopperComponent,
  renderValue,
  ...inputProps
}: SearchInputProps<T>) => {
  const [focused, setFocused] = useState(false);
  const [option, setOption] = useState<SearchInputOption<T> | null>(
    defaultOption ?? getInitOption(options, value),
  );
  const [input, setInput] = useState('');

  const { i18n } = useTranslation();

  useUpdateEffect(() => {
    const option = getInitOption(options, value);
    if (!value) {
      setOption(null);
    } else if (option) {
      setOption(option);
    }
  }, [value, i18n.language]);

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    option: SearchInputOption<T> | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (
      (reason === 'clear' && e.type !== 'change') ||
      (!creatable && reason === 'createOption')
    ) {
      setOption(null);
      onChange(null, reason);
    } else if (option) {
      if (fillInputWithOption && !option.created) {
        setOption(option);
      } else {
        setInput(reason === 'selectOption' ? '' : input);
      }
      onChange(option, reason);
    }
  };

  const handleInputChange = (
    _e: SyntheticEvent<Element, Event>,
    input: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInput(input);
    if (onInputChange) {
      onInputChange(input, reason);
    }
  };

  const handleClose = (
    _e: SyntheticEvent<Element, Event> | null,
    reason: AutocompleteCloseReason,
  ) => {
    if (onClose) {
      onClose(option, reason);
    }
  };

  const { t } = useTranslation();

  const Input = useMemo(() => (type === 'number' ? NumberInput : TextInput), [type]);

  const getOptionLabel = useCallback(
    (option: SearchInputOption<object>) => {
      const { value, translationKey, label } = option;
      if (renderValue) {
        return renderValue(option as SearchInputOption<T>) || label;
      }

      if (translationKey) {
        return t(translationKey, { ns: 'enums', defaultValue: label });
      }
      return option?.created ? String(value) : label;
    },
    [i18n.language],
  );

  const popupIcon = useMemo(
    () =>
      loading && focused ? (
        <CircularProgress size={20} />
      ) : (
        Icon || <SearchIcon fontSize="small" />
      ),
    [loading, focused],
  );

  const filterOptions = useMemo(
    () => getFilterOptions({ config: filterConfig, shouldFilter, creatable, loading }),
    [filterConfig, shouldFilter, creatable, loading],
  );

  return (
    <Autocomplete
      value={option}
      onChange={(e, value, reason) => handleChange(e, value as typeof option, reason)}
      inputValue={input}
      onInputChange={handleInputChange}
      onClose={handleClose}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={SearchInputMenuOption}
      noOptionsText={t(`selectInput.noOptionsText.${noOptionsText}`)}
      disabled={disabled}
      isOptionEqualToValue={(option, selectedOption) =>
        !loading ? option.value === selectedOption.value : true
      }
      getOptionDisabled={(option) => !option.value}
      disableClearable={disableClearable}
      loading={loading}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      popupIcon={popupIcon}
      loadingText={t(`selectInput.loadingText.${loadingText}`)}
      filterOptions={filterOptions}
      renderInput={(params) => <Input {...params} {...inputProps} />}
      data-testid={inputProps.label}
      groupBy={groupBy}
      ListboxProps={{
        //@ts-ignore a can pass whatever i want as html attribute
        'data-testid': `${inputProps.name}-menu`,
      }}
      PopperComponent={PopperComponent}
    />
  );
};

const SearchInputWrapper = <T extends object>(props: SearchInputProps<T>) =>
  InputWrapper(SearchInput, props);

export default SearchInputWrapper;
