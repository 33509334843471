import { ProductRequirementPersonData } from 'api/dossier/products/types';

const getPersonLabel = ({
  name,
  isRepresentative,
  companyName,
}: ProductRequirementPersonData) =>
  isRepresentative && companyName ? `${name} (${companyName})` : name;

export default getPersonLabel;
