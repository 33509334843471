import { useTranslation } from 'react-i18next';
import { Chip, Stack, Typography } from '@mui/material';
import { WarningIcon } from 'icons';
import ProductIcon from 'icons/ProductIcon';
import { ProductInterface } from 'api/dossier/products/types';

interface Props extends Pick<ProductInterface, 'productName' | 'status'> {
  isError?: boolean;
}

const ProductHeader = ({ productName, status, isError }: Props) => {
  const { t } = useTranslation('dossierProducts');

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={1.5} alignItems="center">
        {isError ? (
          <WarningIcon color="error" />
        ) : (
          <ProductIcon name={productName} color="primary" />
        )}
        <Typography color={isError ? 'error.main' : 'primary.main'} variant="h4">
          {t(`${productName}.name`, productName)}
        </Typography>
      </Stack>
      <Chip
        label={t(`${productName}.statuses.${status}`, status)}
        sx={({ mixins }) => ({
          bgcolor: mixins.getProductStatusColor(status),
          cursor: 'inherit',
        })}
      />
    </Stack>
  );
};

export default ProductHeader;
