import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { TableOverlay } from 'atoms/TableOverlay/TableOverlay';
import UploadFaqDemoModal from 'modules/UploadFaqDemoModal';
import { tableContainerStyles } from 'templates/DocumentsTable/styles';
import { DemoInterface } from 'api/faq/types';
import { BodyDivider } from './BodyDivider';
import FaqTableHeader from './FaqTableHeader';
import FaqTableRow from './FaqTableRow';
import { getTableData } from './utils';

const FaqTable = ({
  data,
  loading,
  getList,
}: {
  data: DemoInterface[];
  loading: boolean;
  getList: () => Promise<void>;
}) => {
  const { t } = useTranslation('table');
  const [demoToUpdate, setDemoToUpdate] = useState<DemoInterface | null>(null);

  const displayData = useMemo(() => getTableData(data), [data]);
  return (
    <>
      <TableContainer sx={tableContainerStyles}>
        <Table size="small">
          <FaqTableHeader />
          <TableBody>
            {displayData.general.length ? (
              <>
                <BodyDivider sectionsDivider="general" />
                {displayData.general.map((demoData) => (
                  <FaqTableRow
                    key={demoData.id}
                    demoData={demoData}
                    getList={getList}
                    setDemoToUpdate={setDemoToUpdate}
                  />
                ))}
              </>
            ) : null}
            {displayData.products.length ? (
              <>
                <BodyDivider sectionsDivider="products" />
                {displayData.products.map((demoData) => (
                  <FaqTableRow
                    key={demoData.id}
                    demoData={demoData}
                    getList={getList}
                    setDemoToUpdate={setDemoToUpdate}
                  />
                ))}
              </>
            ) : null}
            {data.length ? null : (
              <TableRow>
                <TableCell colSpan={6} height={60} align="center">
                  {loading ? <CircularProgress size={40} /> : <>{t('defaultEmpty')}</>}
                </TableCell>
              </TableRow>
            )}
            {data.length && loading ? <TableOverlay /> : null}
          </TableBody>
        </Table>
      </TableContainer>
      {!!demoToUpdate && (
        <UploadFaqDemoModal
          onClose={() => setDemoToUpdate(null)}
          getList={getList}
          defaultValues={demoToUpdate}
        />
      )}
    </>
  );
};

export default FaqTable;
