import { useContext, useEffect } from 'react';
import { NavigationContext } from 'contexts/navigation/navigationContext';
import { PromptOptions } from 'contexts/navigation/types';

type PromptProps = Omit<PromptOptions, 'shouldBlock'> & { when: boolean };

export const usePrompt = ({ when, id, ...options }: PromptProps) => {
  const { blockNavigation, resetPrompt } = useContext(NavigationContext);

  useEffect(() => {
    blockNavigation({ shouldBlock: when, id, ...options });
  }, [when]);

  useEffect(() => {
    return () => {
      resetPrompt(id);
    };
  }, []);
};
