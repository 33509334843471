import { Modify } from 'types/modify';
import { transformAddress } from 'utils/transformAddressResponse';
import { AddressInterface, FetchedAddress } from 'api/common/types';

export const transformPersonAddress = <T extends object & { address: FetchedAddress }>(
  person: T,
): Modify<T, { address: AddressInterface }> => ({
  ...person,
  address: transformAddress(person.address),
});

export const transformPersonViewAddress = <
  T extends { data: { address: FetchedAddress } },
>(
  person: T,
): Modify<T, { data: { address: AddressInterface } }> => ({
  ...person,
  data: {
    ...person.data,
    address: transformAddress(person.data.address),
  },
});
