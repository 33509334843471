import { useMemo } from 'react';
import PreviewModal from './PreviewModal';
import StaticPreview from './StaticPreview';
import { DocumentPreviewProps } from './types';

const DocumentPreview = ({ isModal, file, ...props }: DocumentPreviewProps) => {
  const fileType = useMemo(
    () => (file.type.split('/')[0] === 'image' ? 'image' : 'document'),
    [file.type],
  );

  return isModal ? (
    <PreviewModal fileName={file.name} fileType={fileType} {...props} />
  ) : (
    <StaticPreview fileName={file.name} fileType={fileType} {...props} />
  );
};

export default DocumentPreview;
