import { toast } from 'react-toastify';
import { BackofficePermissions } from 'consts/permissions';
import i18n from 'i18n';
import { TableAction, TableColumn } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import { downloadFile } from 'utils/handleFile';
import hasPermission from 'utils/hasPermission';
import { getMigrationResponseFile } from 'api/businesses/requests';
import { BusinessMigration, Migration } from 'api/businesses/types';
import BoolCell from '../../../templates/Table/Cells/BooleanCell';

export const dossierMigrationColumns: TableColumn<Migration, 'migrationQueue'>[] = [
  { field: 'name', flex: 5, filterable: true },
  { field: 'businessId', flex: 5 },
  { field: 'externalGuid', flex: 5 },
  { field: 'fileFolderPrefix', flex: 5 },
  {
    field: 'withSync',
    flex: 2,
    renderCell: BoolCell,
  },
  {
    field: 'withSafetyCheck',
    flex: 2,
    renderCell: BoolCell,
  },
  { field: 'status', flex: 5 },
  {
    field: 'migrationDate',
    flex: 4,
    valueFormatter: (migrationDate) =>
      formatDate(migrationDate, 'dd/MM/yyyy HH:mm') || '',
  },
];

export const getDossierMigrationQueueActions = ({
  migrationDate,
  fileFolderPrefix,
  id,
}: Migration): TableAction<'migrationQueue'>[] => [
  {
    name: 'downloadResponse',
    disabled: !migrationDate || !hasPermission(BackofficePermissions.Read),
    onClick: async () => {
      const { ok, response } = await getMigrationResponseFile(fileFolderPrefix, id);
      if (ok) {
        downloadFile(response, `MigrationResponse-${id}`);
      } else {
        toast.error(i18n.t('errors:migration.migrationResponse.404'));
      }
    },
  },
];

export const businessMigrationColumns: TableColumn<
  BusinessMigration,
  'migrationQueue'
>[] = [
  { field: 'name', flex: 5, filterable: true },
  { field: 'businessId', flex: 5 },
  { field: 'externalBusinessId', flex: 5 },
  { field: 'status', flex: 5 },
  {
    field: 'migrationDate',
    flex: 4,
    valueFormatter: (migrationDate) =>
      formatDate(migrationDate, 'dd/MM/yyyy HH:mm') || '',
  },
];
