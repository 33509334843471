import { isAfter, isBefore, isSameDay } from 'date-fns';
import i18n from 'i18n';
import { formatDate } from 'utils/date';

export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameDay(d1, d2) || isBefore(d1, d2);
};

export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameDay(d1, d2) || isAfter(d1, d2);
};

export const isBetween = (
  dayA: Date | undefined,
  dayB: Date | null | undefined,
  day: Date,
) => {
  if (!dayB || !dayA) {
    return false;
  }
  return sameOrBefore(day, dayB) && sameOrAfter(day, dayA);
};

export const getDateRangeInputValue = (dateFrom: Date | null, dateTo: Date | null) => {
  if (dateFrom && !dateTo) {
    return `${formatDate(dateFrom)} - ${i18n.t('common:indefinite')}`;
  }
  if (dateFrom && dateTo) {
    return `${formatDate(dateFrom)} - ${formatDate(dateTo)}`;
  }
  return '';
};
