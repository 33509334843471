import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { languages } from 'i18n';
import { DownloadIcon, WarningIcon } from 'icons';
import FullPageCard from 'templates/FullPageCard';
import { downloadJSON } from 'utils/handleFile';
import { getLocales, uploadLocales } from 'api/config/requests';
import Dropzone from 'components/Dropzone';
import { Select } from 'components/inputs';
import { UploadedTable } from './UploadedTable';
import { fileNames, filenameValidation } from './utils';

export const Translations = () => {
  const { t, i18n } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [languageToProcess, setLanguageToProcess] = useState(i18n.language ?? 'EN');
  const [uploaded, setUploaded] = useState<File[]>([]);
  const [displayInfo, setDisplayInfo] = useState(false);

  const onDrop = useCallback((files: File[]) => {
    setDisplayInfo(false);
    setUploaded((prev) => {
      const filesCopy = [...files];
      const newArray: File[] = [];
      prev.forEach((file) => {
        const index = filesCopy.findIndex(({ name }) => name === file.name);
        if (index > -1) {
          newArray.push(filesCopy[index]);
          filesCopy.splice(index, 1);
        } else {
          newArray.push(file);
        }
      });
      return [...newArray, ...filesCopy];
    });
  }, []);

  const onDownload = () => {
    fileNames.forEach(async (filename) => {
      const { ok, response } = await getLocales(languageToProcess, filename);
      if (ok) {
        downloadJSON(response, filename, false);
      }
    });
  };

  const onUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    uploaded.forEach((file) => {
      formData.append('files', file);
    });

    const { ok } = await uploadLocales(languageToProcess, formData);
    if (ok) {
      setUploaded([]);
      setDisplayInfo(true);
    }
    setUploading(false);
  };

  return (
    <FullPageCard shrinkHeight>
      <Stack rowGap={4} minWidth={{ xs: 400, sm: 500, lg: 800 }} mx="auto" pt={3} px={3}>
        <Typography variant="h3">{t('translationsView.title')}</Typography>
        <Stack direction="row" columnGap={2}>
          <Select
            options={languages.map((lng) => ({
              value: lng,
              label: lng,
              translationKey: `translations.${lng}`,
            }))}
            defaultValue={i18n.language}
            onChange={setLanguageToProcess}
            value={languageToProcess}
            wrapWithGrid={false}
          />
          <Button
            onClick={onDownload}
            size="small"
            disableElevation
            endIcon={<DownloadIcon />}
            loading={uploading}
          >
            {languageToProcess.toUpperCase()}
          </Button>
        </Stack>
        <Dropzone
          onDrop={onDrop}
          dropzoneConfig={{
            accept: {
              'application/json': [],
            },
            maxFiles: fileNames.length,
            validator: filenameValidation,
          }}
        />
        {!!uploaded.length && (
          <UploadedTable uploaded={uploaded} setUploaded={setUploaded} />
        )}
      </Stack>
      <Stack direction="row" p={3} columnGap={2} justifyContent="flex-end">
        {displayInfo && (
          <Stack mr="auto" direction="row" alignItems="flex-end" columnGap={1}>
            <WarningIcon color="warning" />
            <Typography>{t('translationsView.uploadInfo')}</Typography>
          </Stack>
        )}
        <Button
          onClick={onUpload}
          size="small"
          disableElevation
          disabled={!uploaded.length}
          loading={uploading}
        >
          {`${t('upload')} (${languageToProcess.toUpperCase()})`}
        </Button>
      </Stack>
    </FullPageCard>
  );
};
