import { Box, CardActionArea, SvgIcon } from '@mui/material';

interface Props {
  onClick: () => void;
  disabled: boolean;
  Icon: typeof SvgIcon;
}

const SubProductNavigator = ({ disabled, onClick, Icon }: Props) => (
  <Box>
    <CardActionArea onClick={onClick} sx={{ height: '100%' }} disabled={disabled}>
      <Icon color={disabled ? 'disabled' : 'action'} />
    </CardActionArea>
  </Box>
);

export default SubProductNavigator;
