import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Button from 'atoms/Button';
import { CardEditionButtonsProps } from './types';

export const CardEditionButtons = ({
  onCancel,
  onSave,
  saveDisabled,
  saving,
  variant = 'dark',
}: CardEditionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        role="text"
        size="small"
        onClick={onCancel}
        disabled={saving}
        sx={({ palette }) => ({
          color: variant === 'light' ? palette.primary.main : palette.secondary.light,
        })}
      >
        {t('cancel')}
      </Button>
      <Button onClick={onSave} size="small" disabled={saveDisabled} loading={saving}>
        {t('save')}
      </Button>
    </Stack>
  );
};
