import { ThemedStyle } from 'types/themedStyle';

export const getTodoItemContainerStyles =
  (isUnderDrop: boolean, draggable: boolean): ThemedStyle =>
  ({ palette }) => ({
    cursor: draggable ? 'grab' : 'default',
    backgroundColor: isUnderDrop ? palette['primary']['200'] : '',
    transform: 'translate3d(0,0,0)',
    ':nth-of-type(odd)': {
      backgroundColor: isUnderDrop
        ? palette['primary']['200']
        : palette['background']['default'],
    },
  });
