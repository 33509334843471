import { ChangeEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from 'atoms/Button';
import { translationSchema } from 'formData/common/schema';
import { TranslationField } from 'modules/TranslationField/TranslationField';
import { Language } from 'types/language';
import mapObject from 'utils/object/mapObject';
import { createCustomTodoItem, updateCustomTodoItem } from 'api/dossier/requests';
import { TextInput } from 'components/inputs';
import { CustomToDoItemModalProps } from './types';

export const CustomToDoItemModal = ({
  editingToDo,
  closeModal,
  fetchCustomTodoItems,
  dossierId,
}: CustomToDoItemModalProps) => {
  const initToDo = useMemo(
    () => editingToDo?.title || translationSchema.getDefault(),
    [editingToDo?.title],
  );

  const [toDo, setToDo] = useState(initToDo);
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();

  const onChange =
    (lng: Language): ChangeEventHandler<HTMLTextAreaElement> =>
    ({ target }) => {
      setToDo((prev) => ({ ...prev, [lng]: target.value }));
    };

  const onSave = async () => {
    setSaving(true);
    const toDoItem = mapObject(
      toDo,
      (value) => String(value) || Object.values(toDo).find(Boolean) || '',
    );

    if (editingToDo) {
      await updateCustomTodoItem(dossierId, editingToDo.id, {
        ...editingToDo,
        title: toDoItem,
      });
      fetchCustomTodoItems();
    } else {
      await createCustomTodoItem(dossierId, { title: toDoItem });
      fetchCustomTodoItems();
    }

    setSaving(false);
    closeModal();
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle variant="h4">
        {t(`title.${editingToDo ? 'editToDoItem' : 'addToDoItem'}`, { ns: 'modals' })}
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        <TranslationField
          render={(lng) => (
            <Box pt={2}>
              <TextInput
                name={`item.${lng}`}
                value={toDo[lng]}
                onChange={onChange(lng)}
                placeholder={t('toDoItem', { ns: 'formFields' })}
                multiline
                rows={4}
              />
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button role="text" onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button
          disableElevation
          onClick={onSave}
          disabled={!Object.values(toDo).some(Boolean)}
          loading={saving}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
