import { SubProductInterface } from 'api/dossier/products/types';

export const getSubProductsIndexes = (subProducts: SubProductInterface[]) => {
  const indexes = subProducts
    .map(({ index }) => index)
    .filter((index): index is number => index !== undefined);

  const firstIndex = Math.min(...indexes);
  const lastIndex = Math.max(...indexes);

  return { firstIndex, lastIndex };
};

export const filterSubProducts = (subProducts: SubProductInterface[]) =>
  subProducts.filter(({ name, index = 0 }) => {
    const groupedSubProducts = subProducts.filter(
      (subProduct) => subProduct.name === name,
    );
    if (groupedSubProducts.length > 1) {
      const { lastIndex } = getSubProductsIndexes(groupedSubProducts);
      return lastIndex === index;
    }
    return true;
  });
