import { addMonths, addYears, format, subDays } from 'date-fns';
import { DateFormat } from 'types/date';
import { RentalDuration } from 'api/dossier/types';

type CalculateEndOfRental = {
  (
    entryDate: Date | string | null,
    duration: RentalDuration | null,
    dateFormat?: DateFormat,
  ): string | null;
  (entryDate: Date | string | null, months: number, dateFormat?: DateFormat):
    | string
    | null;
};

export const calculateEndOfRental: CalculateEndOfRental = (
  entryDate,
  durationOrMonths,
  dateFormat = 'yyyy-MM-dd',
) => {
  if (!entryDate || durationOrMonths === null) return null;

  if (durationOrMonths === 0) {
    return format(new Date(entryDate), dateFormat);
  }

  let date: Date | null;
  if (typeof durationOrMonths === 'number') {
    date = addMonths(new Date(entryDate), durationOrMonths);
  } else {
    let years: number;
    switch (durationOrMonths) {
      case 'OneYear':
        years = 1;
        break;
      case 'ThreeYears':
        years = 3;
        break;
      case 'NineYears':
        years = 9;
        break;
      default:
        return null;
    }
    date = addYears(new Date(entryDate), years);
  }
  return format(subDays(date, 1), dateFormat);
};
