import { TKey } from 'i18next';
import yup from 'utils/yup';
import addressSchema from './address/schema';
import bicSchema from './bic/schema';
import ibanSchema from './iban/schema';
import paymentSchema from './payment/schema';
import phoneSchema from './phone/schema';

export { addressSchema, bicSchema, ibanSchema, paymentSchema, phoneSchema };

export const languageSchema = yup
  .string<TKey<'enums', 'language'>>()
  .label('language')
  .defined();

export const translationSchema = yup.object({
  en: yup.string().default(''),
  nl: yup.string().default(''),
  fr: yup.string().default(''),
});

export const bivSchema = yup.string().label('biv').nullable().default(null).max(20);
