import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { namespaces } from 'i18n';
import { TabsProps, TabsTranslationKey } from './types';

export const Tabs = <T extends TabsTranslationKey>({
  activeTab,
  setActiveTab,
  translationKey,
  variant = 'fullWidth',
  hiddenTabs,
}: TabsProps<T>) => {
  const { t } = useTranslation('common', { keyPrefix: 'tabs' });

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const labels = Object.keys(namespaces.common.tabs[translationKey]);

  return (
    <MuiTabs
      value={activeTab}
      variant={variant}
      onChange={handleChange}
      scrollButtons="auto"
    >
      {labels
        .filter((label) => !hiddenTabs?.some((tab) => tab === label))
        .map((label) => (
          <Tab key={label} label={t(`${translationKey}.${label}`, label)} />
        ))}
    </MuiTabs>
  );
};
