import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GridValidRowModel } from '@mui/x-data-grid';
import i18n from 'i18n';
import { initMeta } from '../static-data';
import { FetchedTableData, TableProps } from '../types';
import { getListSearchParams, removeEmptySearchParams } from './utils';

type Props<T extends GridValidRowModel> = Pick<
  TableProps<T>,
  'get' | 'withUrlSearchParams'
>;

const useTableData = <T extends GridValidRowModel>({
  get,
  withUrlSearchParams,
}: Props<T>) => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initMeta);
  const [rows, setRows] = useState<FetchedTableData<T>['data']>([]);

  const { state } = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [searchParams, setSearchParams] = useState(
    withUrlSearchParams ? Object.fromEntries(urlSearchParams.entries()) : {},
  );

  const fetchData = useCallback(
    async (newParams = {}) => {
      setLoading(true);
      const clearedParams = removeEmptySearchParams(newParams);
      const { filters, ...params } = getListSearchParams(clearedParams);
      const { ok, response } = await get({ ...params, ...filters });

      if (ok) {
        setRows(response.data);
        setMeta(response.meta);
        setSearchParams(clearedParams);
        if (withUrlSearchParams) {
          setUrlSearchParams(clearedParams, { replace: true, state });
        }
      }
      setLoading(false);
    },
    [get, i18n.language],
  );

  useEffect(() => {
    fetchData(Object.fromEntries(urlSearchParams.entries()));
  }, [fetchData]);

  const updateRows = useCallback(
    async (newParams?: object) => {
      await fetchData({ ...searchParams, ...newParams });
    },
    [searchParams],
  );

  return { rows, meta, loading, searchParams, updateRows };
};

export default useTableData;
