import { useMemo } from 'react';
import { Card, CardActionArea, CardContent, Stack } from '@mui/material';
import CustomLink from 'atoms/Link';
import { ProductContextProvider } from 'contexts/product/productContext';
import { mapProductDetails } from 'utils/products';
import { ProductDocument, ProductInterface } from 'api/dossier/products/types';
import ProductCardFooter from './ProductCardFooter';
import ProductDetails from './ProductDetails';
import ProductHeader from './ProductHeader';
import ProductProcess from './ProductProcess';
import ProductSecondaryActions from './ProductSecondaryActions';
import { getProductCardStyles } from './styles';
import SubProducts from './SubProducts';

export const ProductCard = ({
  id,
  deletable,
  productName,
  process,
  subProducts,
  status,
  productDetails,
  chosenParties,
}: ProductInterface) => {
  const missingDocuments = useMemo(
    () =>
      process?.stages
        .flatMap(({ requiredDocuments, name }) =>
          name === 'UploadDocuments' ? requiredDocuments : [],
        )
        .filter((doc): doc is ProductDocument => !!doc && !doc.isCompleted),
    [process?.stages],
  );

  return (
    <ProductContextProvider
      productId={id}
      productName={productName}
      chosenParties={chosenParties}
      reportedErrors={process?.reportedErrors || null}
    >
      <Card
        variant="outlined"
        sx={getProductCardStyles(!!process?.reportedErrors?.length)}
      >
        <CardActionArea>
          <CustomLink to={id}>
            <ProductHeader
              productName={productName}
              status={status}
              isError={!!process?.reportedErrors?.length}
            />
          </CustomLink>
        </CardActionArea>
        <CardContent>
          {productDetails && (
            <ProductDetails details={mapProductDetails(productName, productDetails)} />
          )}
          <Stack pt={2} mt="auto" gap={2}>
            {process && <ProductProcess {...process} />}
            {!!subProducts?.length && <SubProducts subProducts={subProducts} />}
            {!!process?.actions?.length && (
              <ProductSecondaryActions actions={process?.actions} />
            )}
          </Stack>
        </CardContent>
        <ProductCardFooter deletable={deletable} missingDocuments={missingDocuments} />
      </Card>
    </ProductContextProvider>
  );
};
