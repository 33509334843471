import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import useLoader from 'hooks/useLoader';
import { NaturalPersonDetailsCard, PersonViewHeader } from 'modules/PersonView';
import routes, { personTabRoutes } from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { RouteTabs } from 'components/tabs';
import { getNaturalPersonConfig, isNaturalPersonMissingRequiredData } from './utils';

const NaturalPersonDetails = () => {
  const { naturalPersonId } = useParams();
  if (!naturalPersonId) return null;

  const { isFeatureActive } = useContext(FeatureFlagsContext);
  const { data: naturalPerson, Loader } = useLoader(
    getNaturalPersonConfig(naturalPersonId),
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const personRoutes = useMemo(() => {
    const { details, ...routesWithoutDetails } = personTabRoutes;
    return matches ? routesWithoutDetails : { details, ...routesWithoutDetails };
  }, [matches]);

  return (
    <Loader>
      {naturalPerson && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              {!matches && (
                <PersonViewHeader
                  type="NaturalPerson"
                  id={naturalPersonId}
                  data={naturalPerson.data}
                />
              )}
              <RouteTabs
                routes={personRoutes}
                parentPath={routes.naturalPerson(naturalPersonId)}
                translationKey="personView"
                hiddenTabs={!isFeatureActive('HistoryLogging') ? ['history'] : []}
              />
            </Stack>
            <Outlet context={naturalPerson} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
          >
            <PersonViewHeader
              type="NaturalPerson"
              id={naturalPersonId}
              data={naturalPerson.data}
            />
            <Stack spacing={3}>
              {isNaturalPersonMissingRequiredData(naturalPerson.data) && (
                <WarningField tKey="missingFormRequiredData" fullWidth />
              )}
              <NaturalPersonDetailsCard {...naturalPerson} />
              <AffiliatedPersonsCard
                persons={naturalPerson.representativeOf}
                title="representativeOf"
              />
              <AffiliatedPersonsCard
                persons={naturalPerson.representedBy}
                title="representedBy"
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default NaturalPersonDetails;
