import { Box, CircularProgress, IconButton } from '@mui/material';
import { CancelIcon, SaveIcon } from 'icons';
import { DocumentEditionButtonsProps } from './types';

const DocumentEditionButtons = ({
  onCancel,
  onSave,
  submitting,
}: DocumentEditionButtonsProps) => (
  <Box display="flex" gap={0.5} alignItems="center">
    <IconButton size="small" onClick={onSave} disabled={submitting}>
      {!submitting ? <SaveIcon fontSize="small" /> : <CircularProgress size={20} />}
    </IconButton>
    <IconButton size="small" onClick={onCancel} disabled={submitting}>
      <CancelIcon fontSize="small" />
    </IconButton>
  </Box>
);

export default DocumentEditionButtons;
