import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { Box, Card, IconButton, Popover, Typography } from '@mui/material';
import useMenu from 'hooks/useMenu';
import { CalendarIcon } from 'icons';
import CardRow, { RowValues } from 'templates/CardRow';
import { formatDate } from 'utils/date';
import { Residents } from 'api/dossier/types';

const ResidentsSection = ({
  numberOfAdults,
  numberOfChildren,
  childrenDateOfBirth,
}: Residents) => {
  const { t } = useTranslation('common', { keyPrefix: 'pageCard.dossier' });
  const { anchorEl, closeMenu, openMenu } = useMenu();

  return (
    <Box sx={({ mixins }) => mixins.miniCardsGrid}>
      <Card variant="outlined" sx={({ mixins }) => mixins.cardContent}>
        <Typography fontWeight={600}>{t('adults')}</Typography>
        <Typography fontWeight={600}>{numberOfAdults}</Typography>
      </Card>
      <Card variant="outlined" sx={({ mixins }) => mixins.cardContent}>
        <Typography fontWeight={600}>{t('children')}</Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Typography fontWeight={600}>{numberOfChildren || 0}</Typography>
          {childrenDateOfBirth?.length ? (
            <>
              <IconButton size="small" onClick={openMenu}>
                <ExpandMore fontSize="small" />
              </IconButton>
              <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
                {childrenDateOfBirth.map((date, index) => (
                  <CardRow
                    key={index}
                    gap={1.5}
                    title={<CalendarIcon />}
                    value={
                      <RowValues
                        primary={formatDate(date) ?? ''}
                        secondary={t('dateOfBirth')}
                      />
                    }
                  />
                ))}
              </Popover>
            </>
          ) : null}
        </Box>
      </Card>
    </Box>
  );
};

export default ResidentsSection;
