import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { RemoveFilterIcon } from 'icons';
import { useTableFiltersState } from 'templates/Table/hooks';
import { TABLE_FILTER_PADDING_X } from './static-data';

const TableFiltersCleaner = () => {
  const [filters, setFilters] = useTableFiltersState();
  const { t } = useTranslation('table');

  const clearAllFilters = () => {
    const clearedFilters = filters.map((filter) => ({ ...filter, value: '' }));
    setFilters(clearedFilters);
  };

  const disabled = useMemo(
    () =>
      filters
        .filter(({ name }) => name !== 'limit' && name !== 'page')
        .every(({ value }) => !value),
    [filters],
  );

  return (
    <Box ml="auto" pr={TABLE_FILTER_PADDING_X}>
      <TooltipIconButton
        tooltipTitle={t('filters.clearAll')}
        disabled={disabled}
        onClick={clearAllFilters}
        Icon={<RemoveFilterIcon />}
      />
    </Box>
  );
};

export default TableFiltersCleaner;
