import { downloadFile } from 'utils/handleFile';
import { apiUrl } from 'api/const';
import { getDossierDocument } from 'api/dossier/requests';
import { getEstateDocument } from 'api/estate/requests';
import { getLegalPersonDocument } from 'api/person/legal/requests';
import { getNaturalPersonDocument } from 'api/person/natural/requests';
import { handleFile } from './DocumentFiles/utils';
import { DocumentGet, DocumentUrl, HandleDocumentAction } from './types';

export const getDocumentUrl: DocumentUrl = {
  estate: apiUrl.estateDocument,
  naturalPerson: apiUrl.naturalPersonDocument,
  legalPerson: apiUrl.legalPersonDocument,
  dossier: apiUrl.dossierDocument,
};

export const getDocument: DocumentGet = {
  estate: getEstateDocument,
  naturalPerson: getNaturalPersonDocument,
  legalPerson: getLegalPersonDocument,
  dossier: getDossierDocument,
};

export const handleDownloadDocument: HandleDocumentAction =
  (document, entityId, scope) => async () => {
    if (document.files.length === 1) {
      handleFile('download', { file: document.files[0], document, entityId, scope });
    } else {
      const { ok, response } = await getDocument[scope](entityId, document.id);
      if (ok && response) {
        downloadFile(response, document.documentName);
      }
    }
  };

export const handleDeleteDocument: HandleDocumentAction =
  ({ id, documentName }, entityId, scope, context) =>
  ({ setUpAndOpenConfirmationModal, updateRows }) => {
    const url = getDocumentUrl[scope](entityId, id);
    setUpAndOpenConfirmationModal({
      url,
      translationKey: 'document',
      onSuccess: updateRows,
      shouldAwaitOnSuccess: false,
      translationValues: { documentName, context },
    });
  };
