import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { personTypeOptions } from 'formData/common/selectOptions';
import { LegalPersonForm, NaturalPersonForm } from 'modules/PersonForm';
import FullPageCard from 'templates/FullPageCard';
import { PersonType } from 'api/person/types';
import { Select } from 'components/inputs';

const AddNewPerson = () => {
  const { state } = useLocation();

  const [selectedPersonType, setSelectedPersonType] = useState<PersonType>(
    state?.personType || 'NaturalPerson',
  );

  const handleChange = (value: string) => {
    setSelectedPersonType(value as PersonType);
  };

  return (
    <FullPageCard
      header={
        <Box maxWidth="50%">
          <Select
            value={selectedPersonType}
            options={personTypeOptions}
            onChange={handleChange}
            name="personTypeSelector"
          />
        </Box>
      }
    >
      {selectedPersonType === 'NaturalPerson' ? (
        <NaturalPersonForm />
      ) : (
        <LegalPersonForm />
      )}
    </FullPageCard>
  );
};

export default AddNewPerson;
