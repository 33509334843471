import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LoadingElement from 'atoms/LoadingElement';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import BackOfficeProvider from 'contexts/backofficeContext';
import { ConfigProvider } from 'contexts/config/ConfigContext';
import { ConfirmationProvider } from 'contexts/confirmation/ConfirmationContext';
import { FeatureFlagsProvider } from 'contexts/featureFlags/FeatureFlagsContext';
import { LoadersProvider } from 'contexts/loaders/loadersContext';
// import GdprModal from 'modules/GdprModal/GdprModal';
import { useUpdateEffect } from 'usehooks-ts';
import hasPermission from 'utils/hasPermission';
import hasRole from 'utils/hasRole';
import { PromptModal } from 'components/modals';
import ErrorPage from './ErrorPage';
import Header from './Header';
import Sidebar from './Sidebar';
import {
  ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT,
  layoutContainerStyles,
  layoutWrapperStyles,
} from './styles';

export const Layout = ({ forFaq }: { forFaq?: boolean }) => {
  const theme = useTheme();
  const matchesBreakpoint = useMediaQuery(
    theme.breakpoints.up(ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT),
  );
  const [isSidebarExpanded, setIsSidebarOpen] = useState(matchesBreakpoint);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useUpdateEffect(() => {
    if (!matchesBreakpoint) {
      setIsSidebarOpen(false);
    }
  }, [matchesBreakpoint]);

  const { loading, error } = useContext(AccountContext);

  if (loading) {
    return <LoadingElement />;
  } else if (error) {
    return <ErrorPage />;
  }

  return (
    <AuthenticatedTemplate>
      <BackOfficeProvider>
        <FeatureFlagsProvider>
          <ConfigProvider>
            <LoadersProvider>
              <ConfirmationProvider>
                <Box sx={layoutWrapperStyles}>
                  <Header
                    isSidebarExpanded={isSidebarExpanded}
                    matchesBreakpoint={matchesBreakpoint}
                    hideNotifications={
                      hasRole('SuperAdmin') ||
                      hasRole('Admin') ||
                      !hasPermission('BusinessDataWrite') ||
                      forFaq
                    }
                  />
                  <Sidebar
                    expanded={isSidebarExpanded}
                    toggleSidebar={toggleSidebar}
                    matchesBreakpoint={matchesBreakpoint}
                    forFaq={forFaq}
                  />
                  <Box component="main" sx={layoutContainerStyles}>
                    <Outlet />
                  </Box>
                  {/*
                  TO DO: finish up gdpr modal flow
                  uncomment below when back-end is ready
                */}
                  {/* {businessData && !businessData.isDataConfirmed && (
                  <GdprModal businessData={businessData} />
                )} */}
                </Box>
              </ConfirmationProvider>
              <PromptModal />
            </LoadersProvider>
          </ConfigProvider>
        </FeatureFlagsProvider>
      </BackOfficeProvider>
    </AuthenticatedTemplate>
  );
};
