import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { TKey } from 'i18next';
import { InfoIcon } from 'icons';

interface Props {
  label: ReactNode;
  tooltip?: TKey;
}

const LabelWithTooltip = ({ label, tooltip }: Props) => {
  const { t } = useTranslation();

  return tooltip ? (
    <Box display="flex" gap={1} alignItems="center">
      {label}
      <IconTooltip title={t(tooltip)} PopperProps={{ sx: { whiteSpace: 'pre-line' } }}>
        <InfoIcon fontSize="small" color="primary" />
      </IconTooltip>
    </Box>
  ) : (
    <Typography>{label}</Typography>
  );
};

export default LabelWithTooltip;
