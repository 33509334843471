import { useOutletContext } from 'react-router-dom';
import HistoryOfChanges from 'templates/HistoryOfChanges';
import { PERSON_HISTORY_ACTIVITIES } from 'templates/HistoryOfChanges/static-data';
import { NaturalPersonViewInterface } from 'api/person/natural/types';
import { getPersonHistory } from 'api/person/requests';

const NaturalPersonHistoryTab = () => {
  const naturalPerson = useOutletContext<NaturalPersonViewInterface>();

  return (
    <HistoryOfChanges
      getHistory={getPersonHistory}
      entityId={naturalPerson.id}
      activities={PERSON_HISTORY_ACTIVITIES}
    />
  );
};

export default NaturalPersonHistoryTab;
