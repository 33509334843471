import { PropsWithChildren } from 'react';
import { Zoom } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

type Props = PropsWithChildren &
  DialogProps & {
    closeOnBackdrop?: boolean;
  };

const ModalWrapper = ({
  onClose,
  closeOnBackdrop = false,
  children,
  ...props
}: Props) => {
  const handleClose: DialogProps['onClose'] = (e, reason) => {
    if (reason === 'backdropClick' && !closeOnBackdrop) {
      return;
    } else if (onClose) {
      onClose(e, reason);
    }
  };

  return (
    <Dialog onClose={handleClose} TransitionComponent={Zoom} {...props}>
      {children}
    </Dialog>
  );
};

export default ModalWrapper;
