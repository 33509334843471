import { useMemo } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';
import { MetaData } from '../types';

const useTablePagination = (
  meta: MetaData,
  updateRows: (params?: object) => Promise<void>,
) => {
  const paginationModel: GridPaginationModel = useMemo(
    () => ({
      page: meta?.offset ? meta.offset / meta.limit : 0,
      pageSize: meta?.limit ?? 0,
    }),
    [meta?.offset, meta?.limit],
  );

  const onPageChange = ({ page, pageSize }: GridPaginationModel) => {
    // page + 1 because in URL page is displayed starting from 1, not 0
    let newParams = { limit: pageSize, page: page + 1 };
    const currentPageSize = paginationModel.pageSize;

    if (pageSize !== currentPageSize) {
      const firstVisibleRow = paginationModel.page * currentPageSize + 1;
      const previousPages = Math.floor(firstVisibleRow / pageSize);
      const isAdditionalPage = previousPages ? !!(firstVisibleRow % pageSize) : false;
      const updatedPage = previousPages + (isAdditionalPage ? 1 : 0);
      newParams = { ...newParams, page: updatedPage };
    }
    updateRows(newParams);
  };

  return { paginationModel, onPageChange };
};

export default useTablePagination;
