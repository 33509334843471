import transformName from 'utils/transformName';
import { RentalDepositPartyData } from '../types';

export const getPartiesNames = (
  contact: RentalDepositPartyData,
  representative: RentalDepositPartyData | null,
  partner?: RentalDepositPartyData | null,
) => {
  let contactName = transformName(contact);
  if (representative) {
    contactName = `${contactName} (${transformName(representative)})`;
  }
  if (partner) {
    return [contactName, transformName(partner)];
  }
  return [contactName];
};
