import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { DossierStatus, DossierType } from 'api/dossier/types';

export const dossierTypes: TranslatedOption<DossierType>[] = getObjectKeys(
  namespaces.enums.dossierTypes,
).map((type) => ({
  value: type,
  label: type,
  translationKey: `dossierTypes.${type}`,
}));

export const dossierStatuses: TranslatedOption<DossierStatus>[] = getObjectKeys(
  namespaces.enums.dossierStatuses,
).map((status) => ({
  value: status,
  label: status,
  translationKey: `enums:dossierStatuses.${status}`,
}));

export const assignableDossierStatuses = dossierStatuses.filter(
  ({ value }) => value !== 'Migrated',
);
