import { msalInstance, msalScopes as scopes } from 'authConfig';

const getAuthorizationHeader = () =>
  msalInstance
    .acquireTokenSilent({
      scopes,
      account: msalInstance.getAllAccounts()[0],
    })
    .then(({ accessToken }) => `Bearer ${accessToken}`)
    .catch(async () => {
      await msalInstance.acquireTokenRedirect({ scopes });
      return null;
    });

export default getAuthorizationHeader;
