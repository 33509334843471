import { useTranslation } from 'react-i18next';
import { UNITS } from 'consts/units';
import CardRow, { RowValues } from 'templates/CardRow';
import { EstateEpc } from 'api/estate/types';

const EpcEnergyConsumptionRow = ({
  primaryEnergyConsumption,
  referenceEnergyConsumption,
}: Pick<
  NonNullable<EstateEpc>,
  'primaryEnergyConsumption' | 'referenceEnergyConsumption'
>) => {
  const { t } = useTranslation('formFields');

  if (primaryEnergyConsumption && referenceEnergyConsumption) {
    return (
      <CardRow
        title={
          <RowValues
            asTitle
            primary={t('epc.referenceEnergyConsumption')}
            secondary={t('epc.primaryEnergyConsumption')}
          />
        }
        value={
          <RowValues
            primary={`${referenceEnergyConsumption} ${UNITS.energyConsumption}`}
            secondary={`${primaryEnergyConsumption} ${UNITS.energyConsumption}`}
          />
        }
      />
    );
  }

  if (primaryEnergyConsumption) {
    return (
      <CardRow
        title={t('epc.primaryEnergyConsumption')}
        value={`${primaryEnergyConsumption} ${UNITS.energyConsumption}`}
      />
    );
  }

  if (referenceEnergyConsumption) {
    return (
      <CardRow
        title={t('epc.referenceEnergyConsumption')}
        value={`${referenceEnergyConsumption} ${UNITS.energyConsumption}`}
      />
    );
  }

  return null;
};

export default EpcEnergyConsumptionRow;
