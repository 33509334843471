import { ThemedStyle } from 'types/themedStyle';

export const businessInfoBoxStyle: ThemedStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 0.75,
  backgroundColor: 'primary.100',
  p: 2,
  m: 1,
  borderRadius: 2,
};
