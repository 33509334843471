import { getCountriesVatMask, VALIDATED_COUNTRIES } from './euCountriesVat';
import { CountryCodesForValidation } from './types';

export const isMask = (mask: (string | RegExp)[]) => {
  if (mask.length === 1 && String(mask[0]) === String(/\w|\d/)) {
    return false;
  }
  return true;
};

export const getVatMask = (vatPrefix: string, value?: string) => {
  if (!vatPrefix || !VALIDATED_COUNTRIES.find((code) => code === vatPrefix)) {
    return false;
  }

  const mask = getCountriesVatMask(value)[vatPrefix as CountryCodesForValidation];
  const formattedMask = mask[0] === ' ' ? mask.slice(1) : mask;
  return isMask(formattedMask) ? formattedMask : false;
};
