import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { RemoveIcon } from 'icons';
import { SetState } from 'types/setState';

interface Props {
  uploaded: File[];
  setUploaded: SetState<File[]>;
}
export const UploadedTable = ({ uploaded, setUploaded }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: `translationsView` });

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('filename')}</TableCell>
          <TableCell>{t('lastModified')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {uploaded.map((file) => (
          <TableRow key={file.name}>
            <TableCell>{file.name}</TableCell>
            <TableCell>{new Date(file.lastModified).toLocaleString()}</TableCell>
            <TableCell align="right">
              <IconButton
                size="small"
                onClick={() =>
                  setUploaded((prev) => prev.filter(({ name }) => file.name !== name))
                }
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
