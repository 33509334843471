import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'router/routes';
import { ActiveNotificationTab } from './types';

const useNotificationsTabState = (activeTab: number) => {
  const { pathname, state } = useLocation();

  const isViewAllNotificationsDisabled = useMemo(
    () =>
      (pathname === routes.notifications &&
        state?.notificationType === 'noAction' &&
        activeTab === ActiveNotificationTab.noAction) ||
      (pathname === routes.notifications &&
        state?.notificationType === 'actionRequired' &&
        activeTab === ActiveNotificationTab.actionRequired),
    [activeTab, pathname],
  );

  return {
    isViewAllNotificationsDisabled,
  };
};

export default useNotificationsTabState;
