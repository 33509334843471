import { normalizeString } from 'utils/string';
import { EstateDestination, EstateSubType, EstateType } from 'api/estate/types';
import { SelectOption } from 'components/inputs/Select/types';

export const createDestinationOptions = (
  destinations: EstateDestination[],
): SelectOption<EstateDestination>[] =>
  destinations.map((destination) => ({
    value: destination,
    label: normalizeString(destination),
    translationKey: `estateDestination.${destination}`,
  }));

export const createTypeOptions = (types: EstateType[]): SelectOption<EstateType>[] =>
  types.map((type) => ({
    value: type,
    label: normalizeString(type),
    translationKey: `estateType.${type}`,
  }));

export const createSubTypeOptions = (
  subTypes: EstateSubType[],
): SelectOption<EstateSubType>[] =>
  subTypes.map((subType) => ({
    value: subType,
    label: normalizeString(subType),
    translationKey: `estateSubtype.${subType}`,
  }));
