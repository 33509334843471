import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from 'contexts/config/ConfigContext';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { useUpdateEffect } from 'usehooks-ts';
import getObjectKeys from 'utils/object/getObjectKeys';
import { EstateDestination, EstateType } from 'api/estate/types';
import { HandleSelectChange } from 'components/inputs/Select/types';
import { FieldWarning } from 'components/inputs/types';
import {
  createDestinationOptions,
  createSubTypeOptions,
  createTypeOptions,
} from './utils';

export const useEstatePurposeFields = (previousDestination?: EstateDestination) => {
  const { destinationConfig, subTypesConfig } = useContext(ConfigContext);
  const { t } = useTranslation('formFields');

  const {
    getValues,
    setValue,
    trigger,
    formState: { defaultValues },
  } = useFormMethods<EstateFormInterface>();

  const [destination, setDestination] = useState(getValues('estate.destination') || '');
  const [type, setType] = useState(getValues('estate.type') || null);

  useUpdateEffect(() => {
    if (defaultValues?.estate?.destination) {
      setDestination(defaultValues.estate.destination);
    }
    if (defaultValues?.estate?.type) {
      setType(defaultValues.estate.type);
    }
  }, [defaultValues?.estate?.destination, defaultValues?.estate?.type]);

  const handleDestinationChange: HandleSelectChange<EstateDestination> = (
    destination,
  ) => {
    setDestination(destination);
    setValue('estate.type', null);
    setType(null);
    setValue('estate.subType', null);

    trigger('estate.type');
    trigger('estate.subType');
  };

  const destinationWarning: FieldWarning | undefined = useMemo(() => {
    if (
      getValues('estateDetails.estateComposition') &&
      previousDestination &&
      previousDestination !== destination
    ) {
      return { message: t('destinationAffectsComposition') };
    }
  }, [destination]);

  const handleTypeChange: HandleSelectChange<EstateType> = async (type) => {
    setType(type);
    setValue('estate.subType', null);
    trigger('estate.subType');

    if (type !== 'Apartment') {
      setValue('estate.floorLevel', null);
    }
  };

  const estateDestinations = useMemo(
    () => createDestinationOptions(getObjectKeys(destinationConfig)),
    [destinationConfig],
  );

  const estateTypes = useMemo(
    () => (destination ? createTypeOptions(destinationConfig[destination]) : []),
    [destination],
  );

  const estateSubTypes = useMemo(
    () => (type ? createSubTypeOptions(subTypesConfig[type]) : []),
    [type],
  );

  const isTypeDisabled = useMemo(
    () => !!(destination && !estateTypes.length),
    [destination, estateTypes.length],
  );

  const isSubTypeDisabled = useMemo(
    () => isTypeDisabled || !!(type && !estateSubTypes.length),
    [isTypeDisabled, type, estateSubTypes.length],
  );

  return {
    handleTypeChange,
    handleDestinationChange,
    estateDestinations,
    estateTypes,
    estateSubTypes,
    isTypeDisabled,
    isSubTypeDisabled,
    type,
    destinationWarning,
  };
};
