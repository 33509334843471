import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Stack } from '@mui/material';
import Button from 'atoms/Button';
import { AddIcon, RemoveIcon } from 'icons';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { ControlledDateInput, ControlledNumberInput } from 'components/inputs';

const ResidentsFields = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<DossierFormInterface>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'residents.childrenDateOfBirth',
  });

  const updateNumberOfChildren = (count: number) => {
    setValue('residents.numberOfChildren', count, { shouldDirty: true });
  };

  const addField = () => {
    append({ dateOfBirth: null });
    updateNumberOfChildren(fields.length + 1);
  };

  const removeField = (index: number) => () => {
    remove(index);
    updateNumberOfChildren(fields.length - 1);
    if (errors.residents?.numberOfAdults) {
      trigger('residents.numberOfAdults');
    }
  };

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2} alignItems="flex-start">
        <ControlledNumberInput
          label={t('numberOfAdults')}
          control={control}
          name="residents.numberOfAdults"
          error={errors.residents?.numberOfAdults}
          fullWidth={false}
          isInteger
        />
        <Button
          size="small"
          role="secondary"
          startIcon={<AddIcon />}
          sx={{ minHeight: ({ shape }) => shape.inputMinHeight }}
          onClick={addField}
        >
          {t('addChildButton')}
        </Button>
      </Stack>
      {!!fields.length && (
        <Stack direction="row" flexWrap="wrap" rowGap={2} columnGap={4}>
          {fields.map(({ id }, index) => (
            <Stack key={id} direction="row" alignItems="flex-start" gap={1}>
              <ControlledDateInput
                label={t('childrenDateOfBirthLabel', { count: index + 1 })}
                name={`residents.childrenDateOfBirth.${index}.dateOfBirth`}
                control={control}
                error={errors.residents?.childrenDateOfBirth?.[index]?.dateOfBirth}
                fullWidth={false}
                disableFuture
              />
              <Box
                height={({ shape }) => shape.inputMinHeight}
                display="flex"
                alignItems="center"
              >
                <IconButton size="small" onClick={removeField(index)}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default ResidentsFields;
