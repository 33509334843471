import { XYCoord } from 'react-dnd';
import { CustomDossierTodoItem } from 'api/dossier/types';
import { DragAndDropHover } from './types';

export const dragAndDropHover = ({ ref, item, index, monitor }: DragAndDropHover) => {
  if (!ref.current) {
    return;
  }

  const dragIndex = item.index;
  const hoverIndex = index;

  if (dragIndex === hoverIndex) {
    return;
  }

  const hoverBoundingRect = ref.current?.getBoundingClientRect();
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return;
  }
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return;
  }
  item.index = hoverIndex;
};

const mapTodoListItemsOrder = (list: CustomDossierTodoItem[]): CustomDossierTodoItem[] =>
  list.map((item, index) => ({
    ...item,
    order: index,
  }));

export const reorderTodoList = (
  list: CustomDossierTodoItem[],
  itemIndex: number,
  destinationIndex: number,
): CustomDossierTodoItem[] => {
  const newList = [...list];
  const [moved] = newList.splice(itemIndex, 1);
  newList.splice(destinationIndex, 0, moved);

  return mapTodoListItemsOrder(newList);
};
