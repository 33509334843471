import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isToday } from 'date-fns';
import BaseInput from '../BaseInput';
import { DateInputProps } from './types';

const DateInput = ({
  clearable = true,
  disableFuture,
  disablePast,
  disableToday,
  openTo = 'day',
  views = ['year', 'month', 'day'],
  onChange,
  value: initValue,
  minDate,
  maxDate,
  ...textInputProps
}: DateInputProps) => (
  <DatePicker
    value={initValue ? new Date(initValue) : null}
    onChange={onChange}
    openTo={openTo}
    format="dd/MM/yyyy"
    views={views}
    disableFuture={disableFuture}
    disablePast={disablePast}
    shouldDisableDate={(day) => (disableToday ? isToday(day) : false)}
    slots={{
      textField: BaseInput,
    }}
    slotProps={{
      actionBar: {
        actions: clearable ? ['clear'] : [],
      },
      textField: {
        ...textInputProps,
      },
    }}
    disabled={textInputProps.disabled}
    minDate={minDate}
    maxDate={maxDate}
  />
);

export default DateInput;
