import { Box, CircularProgress } from '@mui/material';

export const TableOverlay = () => (
  <Box
    sx={({ mixins }) => mixins.centered}
    height="100%"
    width="100%"
    position="absolute"
    bgcolor="#7E7E7E18"
    top={0}
  >
    <CircularProgress size={40} />
  </Box>
);
