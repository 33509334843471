import { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { RelationData } from 'modules/Relation/types';
import { estateToRelation, fetchEstateOptions } from 'modules/Relation/utils';
import { useTableInitFilter } from 'templates/Table/hooks';
import { TableSidebarFilterComponentProps } from 'templates/Table/types';
import { getEstates } from 'api/estate/requests';
import { FetchedEstateListItem } from 'api/estate/types';

const EstateFilter = ({ filterName, updateFilter }: TableSidebarFilterComponentProps) => {
  const initState = useTableInitFilter(filterName);

  const [fetchingInitData, setFetchingInitData] = useState(false);
  const [estatesToPreview, setEstatesToPreview] = useState<
    RelationData<FetchedEstateListItem>[]
  >([]);

  const getInitEstates = async () => {
    if (initState) {
      setFetchingInitData(true);
      const { ok, response } = await getEstates({ ids: initState });
      if (ok) {
        setEstatesToPreview(response.data.map(estateToRelation));
      }
      setFetchingInitData(false);
    }
  };

  useEffect(() => {
    getInitEstates();
  }, []);

  const estatesToFilter = useMemo(
    () => estatesToPreview.map(({ id }) => id),
    [estatesToPreview],
  );

  const onSelect = (option: RelationData<FetchedEstateListItem>) => {
    const estates = [option, ...estatesToPreview];
    updateFilter(filterName, estates.map(({ id }) => id).join(','));
    setEstatesToPreview(estates);
  };

  const onRemove = (option: RelationData<FetchedEstateListItem>) => {
    const estates = estatesToPreview.filter(({ id }) => id !== option.id);
    updateFilter(filterName, estates.map(({ id }) => id).join(','));
    setEstatesToPreview(estates);
  };

  return (
    <Stack gap={2}>
      <AddRelation
        translationKey="estate"
        withLabel={false}
        relationsToFilter={estatesToFilter}
        fetchOptions={fetchEstateOptions}
        onSelectOption={onSelect}
        disabled={fetchingInitData}
      />
      {fetchingInitData ? (
        <Box sx={({ mixins }) => mixins.centered}>
          <CircularProgress size={36} />
        </Box>
      ) : (
        <RelationPreviewList
          withLink={false}
          relationsToPreview={estatesToPreview}
          setRelationsToPreview={setEstatesToPreview}
          onRemove={onRemove}
        />
      )}
    </Stack>
  );
};

export default EstateFilter;
