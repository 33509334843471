import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import { PersonType } from 'api/person/types';

const renderPersonIcon = (personType: PersonType) => {
  const Icon = personType === 'NaturalPerson' ? NaturalPersonIcon : LegalPersonIcon;

  return <Icon sx={({ palette }) => ({ color: palette.common.black })} />;
};

export default renderPersonIcon;
