import { GridFilterInitialState } from '@mui/x-data-grid';
import { TranslatedOption } from 'formData/types';
import { TableFilter, TableSearchParams } from './types';

export const getFilterInitState = (
  searchParams: TableSearchParams,
): GridFilterInitialState => ({
  filterModel: {
    items: [],
    quickFilterValues: Object.keys(searchParams).map((key) => ({
      name: key,
      value: searchParams[key] ?? '',
    })),
  },
});

export const filtersToParams = (filters: TableFilter[]) =>
  filters.reduce((prev, { name, value }) => ({ ...prev, [name]: value }), {});

export const paramsToFilter = (params: Record<string, string>) =>
  Object.entries(params).map(([name, value]) => ({ name, value }));

export const optionsToFilterOptions = <Value extends string>(
  options: TranslatedOption<Value>[],
) =>
  options.map(({ value, translationKey }) => ({
    name: value,
    label: translationKey,
  }));
