import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Grid,
  List,
  Typography,
} from '@mui/material';
import { useNotificationsTabState } from 'hooks/useNotifications';
import ProductIcon from 'icons/ProductIcon';
import SingleNotificationContent from './SingleNotificationContent';
import { SingleNotificationProps } from './types';

const SingleNotification = ({
  notificationSummary: { productName, notifications = [], count = 0 },
  fetchNotificationsSummary,
  closeNotifications,
  expanded,
  toggleNotification,
  activeTab,
  productsAmount,
}: SingleNotificationProps) => {
  const { t } = useTranslation(['dossierProducts', 'common']);
  const { isViewAllNotificationsDisabled } = useNotificationsTabState(activeTab);

  const moreNotificationsMessage = useMemo(() => {
    if (isViewAllNotificationsDisabled) {
      return t('common:notifications.checkNotificationsTable');
    }
    return t('common:notifications.seeMoreNotifications');
  }, [isViewAllNotificationsDisabled]);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={toggleNotification}
      sx={{ boxShadow: 'none', '&.Mui-expanded:before': { opacity: 1 } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          width="95%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            container
            item
            display="flex"
            alignItems="center"
            data-testid="product-title"
          >
            <ProductIcon name={productName} fontSize="small" />
            <Typography pl={2}>{t(`${productName}.name`)}</Typography>
          </Grid>
          <Badge
            color="secondary"
            badgeContent={count}
            overlap="circular"
            data-testid={`${productName}-badge-count`}
            sx={{ '.MuiBadge-badge': { color: 'white' } }}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          px: 0,
          maxHeight: `calc(75vh - 75px - ${productsAmount * 48}px)`,
          overflowY: 'auto',
        }}
      >
        <List dense disablePadding>
          {notifications.map((notification) => (
            <SingleNotificationContent
              key={notification.id}
              notification={notification}
              fetchNotificationsSummary={fetchNotificationsSummary}
              closeNotifications={closeNotifications}
            />
          ))}
        </List>
        {count > 10 && (
          <Box display="flex" justifyContent="center" pt={2}>
            <Typography color="theme.primary" variant="subtitle2">
              {moreNotificationsMessage}
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SingleNotification;
