import { Trans, useTranslation } from 'react-i18next';
import { Box, IconButton, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { HelpIcon } from 'icons';
import { tableHeaderStyles } from './styles';
import { DocumentsHeaderProps } from './types';

const DocumentsHeader = ({ columns }: DocumentsHeaderProps) => {
  const { t } = useTranslation('table', { keyPrefix: 'documents' });

  return (
    <TableHead>
      <TableRow>
        {/* Expander */}
        <TableCell sx={({ mixins }) => mixins.actionCellStyles} />
        {/* Columns */}
        {columns.map(({ name, headerTooltip }) => (
          <TableCell sx={tableHeaderStyles} key={name}>
            {headerTooltip ? (
              <Box>
                {t(`columns.${name}`)}
                <Tooltip
                  title={
                    <Trans
                      t={t}
                      i18nKey={`tooltips.${headerTooltip}`}
                      components={{
                        list: <ul />,
                        item: <li />,
                      }}
                    />
                  }
                  placement="top"
                >
                  <IconButton size="small" disableRipple>
                    <HelpIcon color="primary" sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              t(`columns.${name}`)
            )}
          </TableCell>
        ))}
        {/* Actions */}
        <TableCell sx={({ mixins }) => mixins.actionCellStyles} />
      </TableRow>
    </TableHead>
  );
};

export default DocumentsHeader;
