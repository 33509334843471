import { ThemedStyle } from 'types/themedStyle';

export const actionButtonsBoxStyles: ThemedStyle = {
  '.MuiIconButton-root, .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: '8px',
  },
};

export const pageButtonStyles: ThemedStyle = ({ palette }) => ({
  '&.active': {
    border: `1px solid ${palette.primary.main}`,
    bgcolor: palette.other.lightBlue,
  },
  '&:hover': {
    bgcolor: palette.other.lightBlue,
  },
});
