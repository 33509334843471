import { Box } from '@mui/material';
import { TableSidebarFilterComponentProps } from 'templates/Table/types';
import DateFilter from './DateFilter';

const DateRangeFilter = ({
  filterName,
  updateFilter,
}: TableSidebarFilterComponentProps) => (
  <Box display="flex" gap={2}>
    <DateFilter
      label="From"
      filterName={`${filterName}From`}
      updateFilter={updateFilter}
    />
    <DateFilter label="To" filterName={`${filterName}To`} updateFilter={updateFilter} />
  </Box>
);

export default DateRangeFilter;
