import { useCallback, useEffect, useState } from 'react';
import { getPostCodeFromId } from 'formData/common/address/utils';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { getEstateEpcTypes } from 'api/config/requests';
import { EstateTypeOption } from './types';
import { getEpcTypesOptions } from './utils';

export const useEpcFields = () => {
  const [options, setOptions] = useState<EstateTypeOption[]>([]);

  const { setValue, getValues, watch } = useFormMethods<EstateFormInterface>();

  const postCode = watch('estate.address.postCode');

  const updateCheckbox = () => {
    if (!getValues('estateDetails.hasEpc')) {
      setValue('estateDetails.hasEpc', true);
    }
  };

  const onTypeChange = (_value: string, option?: EstateTypeOption) => {
    setValue(
      'estateDetails.epc.primaryEnergyConsumption',
      option?.primaryEnergyConsumption ?? null,
    );
    updateCheckbox();
  };

  const onTypeClear = () => {
    setValue('estateDetails.epc.primaryEnergyConsumption', null);
  };

  const fetchEpcOptions = useCallback(async () => {
    if (postCode) {
      const postCodeValue = getPostCodeFromId(postCode);
      const { ok, response } = await getEstateEpcTypes(postCodeValue);
      if (ok) {
        setOptions(getEpcTypesOptions(response));

        const currentEpc = getValues('estateDetails.epc');
        const epc = response.find(({ type }) => type === currentEpc?.type);
        setValue('estateDetails.epc.type', epc?.type || null);
        setValue(
          'estateDetails.epc.primaryEnergyConsumption',
          epc?.primaryEnergyConsumption || null,
        );
        if (!epc && !getValues('estateDetails.epc.referenceEnergyConsumption')) {
          setValue('estateDetails.hasEpc', false);
        }
      }
    }
  }, [postCode]);

  useEffect(() => {
    fetchEpcOptions();
  }, [fetchEpcOptions]);

  return { updateCheckbox, options, onTypeChange, onTypeClear };
};
