import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import StatusIndicator from 'atoms/StatusIndicator';
import routes from 'router/routes';
import AssignedEntityCard from 'templates/AssignedEntityCard';
import addressToString from 'utils/addressToString';
import { PersonOwnedEstateInterface } from 'api/person/types';

const AssignedEstatesGrid = ({ estates }: { estates: PersonOwnedEstateInterface[] }) => {
  const { t } = useTranslation(['common', 'enums']);

  return estates.length ? (
    <Box sx={({ mixins }) => mixins.cardsGrid}>
      {estates.map(
        ({ id, status, referenceNumber, ownReference, type, address, owners }) => (
          <AssignedEntityCard
            key={id}
            title={referenceNumber}
            subTitle1={ownReference}
            subTitle2={type ? t(`enums:estateType.${type}`) : null}
            status={
              <StatusIndicator
                size="small"
                color={status === 'Active' ? 'success.main' : 'grey.400'}
                status={t(`enums:estateStatus.${status}`)}
              />
            }
            link={routes.estateTab(id).estateInfo}
            assignedEstate={{
              label: t('assignedEntityCard.address'),
              address: addressToString(address),
            }}
            assignedPersons={{ label: t('assignedEntityCard.owners'), data: owners }}
          />
        ),
      )}
    </Box>
  ) : (
    <Box display="flex" justifyContent="center">
      <Typography variant="h4">{t('assignedEntityCard.empty.estate')}</Typography>
    </Box>
  );
};

export default AssignedEstatesGrid;
