import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import PersonChip from 'atoms/PersonChip';
import { AssignedPersonsProps } from './types';

const AssignedPersons = ({ label, data }: AssignedPersonsProps) => {
  const { t } = useTranslation();
  const personsToDisplay = useMemo(() => data.slice(0, 2), [data]);

  return (
    <Stack
      height="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
    >
      <Typography color="grey.500">{label}</Typography>
      <Stack gap={1}>
        <Stack direction="row" flexWrap="wrap" gap={1} justifyContent="flex-end">
          {personsToDisplay.map((person) => (
            <PersonChip key={person.id} {...person} />
          ))}
        </Stack>
        {data.length > 2 && (
          <Typography variant="caption" color="grey.500" textAlign="end">
            {t('assignedEntityCard.morePersonsLabel', {
              count: data.length,
              label: label.toLowerCase(),
            })}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default AssignedPersons;
