import { createContext, PropsWithChildren, useState } from 'react';
import { NavData, NavigationContextInterface, PromptData, PromptOptions } from './types';

const NavigationContext = createContext<NavigationContextInterface>({
  navData: null,
  setNavData: () => {},
  isPrompt: false,
  blockNavigation: () => {},
  closePrompt: () => {},
  resetPrompt: () => {},
  openPrompt: () => {},
  promptData: null,
});

const NavigationProvider = ({ children }: PropsWithChildren) => {
  const [navData, setNavData] = useState<NavData | null>(null);
  const [isPrompt, setIsPrompt] = useState(false);
  const [promptData, setPromptData] = useState<PromptData | null>(null);

  const blockNavigation = ({
    shouldBlock,
    id = 'prompt',
    pathnamesToExclude,
  }: PromptOptions) => {
    setPromptData((prev) => ({ ...prev, [id]: { shouldBlock, pathnamesToExclude } }));
  };

  const openPrompt = () => {
    setIsPrompt(true);
  };

  const closePrompt = () => {
    setIsPrompt(false);
  };

  const resetPrompt = (id?: string) => {
    setPromptData((prev) => (id ? { ...prev, [id]: null } : null));
    setNavData(null);
  };

  return (
    <NavigationContext.Provider
      value={{
        navData,
        setNavData,
        isPrompt,
        promptData,
        openPrompt,
        closePrompt,
        resetPrompt,
        blockNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
