import { Stack } from '@mui/material';
import { PersonIcon } from 'icons';
import { AffiliatedPersonInterface } from 'api/common/types';
import PersonContactData from './AffiliatedPersonContactData';
import PersonLabel from './AffiliatedPersonLabel';

const AffiliatedPersonRowValue = (person: AffiliatedPersonInterface) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1.5}>
    <Stack>
      <PersonLabel {...person} />
      <PersonContactData {...person} />
    </Stack>
    <PersonIcon personType={person.type} color="primary" />
  </Stack>
);

export default AffiliatedPersonRowValue;
