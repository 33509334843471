import { TKey } from 'i18next';
import yup from 'utils/yup';
import { PersonType } from 'api/person/types';

export const partyPersonSchema = yup.object({
  id: yup.string().defined(),
  type: yup.string<PersonType>().defined(),
  name: yup.string().defined(),
  address: yup.string().defined(),
  representativeId: yup.string().nullable(),
  relation: yup.string<TKey<'enums', 'privateRelation'>>(),
});

export const productPartySchema = yup.object({
  index: yup.number(), // for validation - first field is required
  id: yup
    .string()
    .defined()
    .label('field')
    .when('index', {
      is: 0,
      then: (schema) => schema.required(),
    }),
  type: yup.string<PersonType>(), // temporary
  personType: yup.string<PersonType>(),
  representativeId: yup
    .string()
    .label('representative')
    .nullable()
    .when('personType', {
      is: 'LegalPerson',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  representatives: yup.array().of(partyPersonSchema),
  partner: partyPersonSchema.optional().default(undefined),
  partnerId: yup.string(),
  hasPrivateRelation: yup.boolean(),
  partnerOption: partyPersonSchema.optional().default(undefined),
  relation: yup
    .string<TKey<'enums', 'privateRelation_orderProduct'>>()
    .nullable()
    .default(undefined),
});

export const productPartyListSchema = yup
  .array()
  .of(productPartySchema)
  .default([{ index: 0, id: '' }]);

export const productPartiesSchema = yup.object({
  tenants: productPartyListSchema,
  landlords: productPartyListSchema,
});

export const productPartyV2Schema = productPartySchema.shape({
  name: yup.string().defined(),
  type: yup.string<PersonType>().defined(),
  representativeId: yup
    .string()
    .label('representative')
    .nullable()
    .when('type', {
      is: 'LegalPerson',
      then: (schema) => schema.required(),
    }),
});

export const productPartyListV2Schema = yup
  .array()
  .min(1)
  .of(productPartyV2Schema)
  .default([]);

export const productPartiesV2Schema = yup.object({
  tenants: productPartyListV2Schema,
  landlords: productPartyListV2Schema,
});
