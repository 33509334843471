import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import useComposition from 'hooks/useEstateCompositionCustomFields';
import TranslationField from 'modules/TranslationField';
import { Language } from 'types/language';
import { TranslationFieldInterface } from 'api/common/types';
import { NumberInput, TextInput } from 'components/inputs';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';
import { compositionNameExists, getAdditionalCompositionFieldName } from './utils';

const CustomCompositionFieldModal = ({
  closeCustomFieldModal,
  prepend,
  update,
  fields,
  editingIndex,
  estateDestination,
}: ReturnType<typeof useComposition>['customFieldModalProps']) => {
  const [name, setName] = useState<TranslationFieldInterface>(
    editingIndex !== undefined ? fields[editingIndex].name : { en: '', nl: '', fr: '' },
  );
  const [count, setCount] = useState(
    editingIndex !== undefined ? fields[editingIndex].count : 0,
  );
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation(['modals', 'common', 'validation', 'formFields']);

  const onNameChange =
    (lng: Language): ChangeEventHandler<HTMLInputElement> =>
    ({ target }) => {
      setName((prev) => ({ ...prev, [lng]: target.value }));
      setErrorMessage('');
    };

  const onCountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCount(e.target.valueAsNumber);
  };

  const onConfirm = () => {
    const isNameDuplicated = compositionNameExists(
      name,
      fields.filter((_, index) => index !== editingIndex),
      estateDestination,
    );

    if (isNameDuplicated) {
      setErrorMessage(t('validation:composition.fieldExists'));
    } else {
      const fieldName = getAdditionalCompositionFieldName(name);
      if (editingIndex !== undefined) {
        update(editingIndex, { name: fieldName, count });
      } else {
        prepend({ name: fieldName, count });
      }
      setErrorMessage('');
      closeCustomFieldModal();
    }
  };

  return (
    <ModalWrapper open onClose={closeCustomFieldModal}>
      <ModalTitle
        title={t(
          editingIndex ? 'title.editNewCompositionField' : 'title.addNewCompositionField',
        )}
        closeModal={closeCustomFieldModal}
      />
      <DialogContent>
        <TranslationField
          render={(lng) => (
            <Box display="flex" gap={1} mt={1}>
              <TextInput
                xs={6}
                placeholder={t('formFields:newCompositionField')}
                onChange={onNameChange(lng)}
                helperText={errorMessage}
                error={!!errorMessage}
                value={name[lng]}
              />
              <NumberInput
                xs={6}
                isInteger
                placeholder="#"
                value={count}
                onChange={onCountChange}
              />
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={closeCustomFieldModal}>
          {t('common:cancel')}
        </Button>
        <Button onClick={onConfirm}>{t('common:confirm')}</Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default CustomCompositionFieldModal;
