import mapObject from 'utils/object/mapObject';
import { PersonType } from 'api/person/types';

const routes = {
  dashboard: '/',
  estates: '/estates',
  estate: (id: string) => `/estates/${id}`,
  estateEdition: (id: string) => `/estates/${id}/edition`,
  estateTab: (id: string) => mapObject(estateTabRoutes, (tab) => `/estates/${id}/${tab}`),
  newEstate: '/estates/new',

  persons: '/persons',
  personTab: (id: string, type: PersonType) =>
    type === 'LegalPerson' ? routes.legalPersonTab(id) : routes.naturalPersonTab(id),
  personEdition: (id: string, type: PersonType) =>
    type === 'LegalPerson'
      ? routes.legalPersonEdition(id)
      : routes.naturalPersonEdition(id),

  newPerson: '/persons/new',
  naturalPerson: (id: string) => `/naturalPersons/${id}`,
  naturalPersonEdition: (id: string) => `/naturalPersons/${id}/edition`,
  naturalPersonTab: (id: string) =>
    mapObject(personTabRoutes, (tab) => `/naturalPersons/${id}/${tab}`),

  legalPerson: (id: string) => `/legalPersons/${id}`,
  legalPersonTab: (id: string) =>
    mapObject(personTabRoutes, (tab) => `/legalPersons/${id}/${tab}`),
  legalPersonEdition: (id: string) => `/legalPersons/${id}/edition`,

  dossiers: '/dossiers',
  newDossier: '/dossiers/new',

  rentalDossier: (id: string) => `/rentalDossiers/${id}`,
  rentalDossierEdition: (id: string) => `/rentalDossiers/${id}/edition`,
  rentalDossierTab: (id: string) =>
    mapObject(dossierTabRoutes, (tab) => `/rentalDossiers/${id}/${tab}`),
  rentalDossierProduct: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/${dossierTabRoutes.products}/${productId}`,
  rentalDossierDocumentsSubTab: (id: string) =>
    mapObject(
      dossierDocumentsTabRoutes,
      (subTab) => `/rentalDossiers/${id}/documents/${subTab}`,
    ),

  users: '/users',
  user: (id: string) => `/users/${id}`,
  userEdition: (id: string) => `/users/${id}/edition`,
  newUser: '/users/new',

  settings: '/settings',

  businesses: '/businesses',
  migrationQueue: '/migrationQueue',
  translations: '/translations',
  versions: '/versions',

  notifications: '/notifications',

  faq: '/faq',
  faqPersons: '/faq/general/persons',
  faqEstates: '/faq/general/estates',
  faqDossiers: '/faq/general/dossiers',
  faqRentalDeposit: '/faq/products/rentalDeposit',
  faqFireInsurance: '/faq/products/fireInsurance',
  faqLegalAssistance: '/faq/products/legalAssistance',

  faqManagement: '/faqManagement',
} as const;

export default routes;

export const estateTabRoutes = {
  estateInfo: 'estate-info',
  dossiers: 'dossiers',
  documents: 'documents',
  history: 'history',
} as const;

export const personTabRoutes = {
  details: 'details',
  dossiers: 'dossiers',
  estates: 'estates',
  documents: 'documents',
  history: 'history',
} as const;

export const dossierTabRoutes = {
  products: 'products',
  details: 'details',
  documents: 'documents',
  history: 'history',
} as const;

export const dossierDocumentsTabRoutes = {
  products: 'products',
  estate: 'estate',
  tenants: 'tenants',
  landlords: 'landlords',
} as const;
