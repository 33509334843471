import { useState } from 'react';
import { getCityFromId, getPostCodeFromId } from 'formData/common/address/utils';
import useFormMethods from 'hooks/useFormMethods';
import { AddressFormInterface } from 'api/common/types';
import { PostCodesWithCitiesTranslations } from 'api/config/types';
import { UsePostCodeAndCityValidationProps } from './types';
import { isCountryBelgium, translateCity } from './utils';

const usePostCodeAndCityValidation = ({
  fetchPostCodesWithCities,
  prefix,
}: UsePostCodeAndCityValidationProps) => {
  const [initPostCodesWithCities, setInitPostCodesWithCities] =
    useState<PostCodesWithCitiesTranslations[]>();

  const { formState, watch, getValues, setError } = useFormMethods<{
    address: AddressFormInterface;
  }>(prefix);

  const isBelgium = isCountryBelgium(watch('address.country'));

  const validate = async () => {
    const { city, postCode } = formState.defaultValues?.address ?? {};

    if (
      isBelgium &&
      city &&
      city === getValues('address.city') &&
      postCode &&
      postCode === getValues('address.postCode')
    ) {
      let options = initPostCodesWithCities;

      if (!options) {
        options = await fetchPostCodesWithCities({
          postCode: getPostCodeFromId(postCode),
          city: translateCity(getCityFromId(city)),
        });
        setInitPostCodesWithCities(options);
      }

      const isValid = options?.some(
        (option) =>
          JSON.stringify(option.city) === getCityFromId(city) &&
          option.postCode === getPostCodeFromId(postCode),
      );

      if (!isValid) {
        setError('address.postCode', { message: 'address.postCodeDoesNotMatchCity' });
        setError('address.city', { message: 'address.cityDoesNotMatchPostcode' });
      }
    }
  };

  return validate;
};

export default usePostCodeAndCityValidation;
