import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import useLoader from 'hooks/useLoader';
import {
  DossierEstateCard,
  DossierViewHeader,
  RentalConditionsCard,
  ResidentsSection,
  ResponsibleUsersSection,
} from 'modules/DossierView';
import routes, { dossierDocumentsTabRoutes, dossierTabRoutes } from 'router/routes';
import { AffiliatedPersonsCard, OwnReferenceCard } from 'templates/PageCard';
import { RouteTabs } from 'components/tabs';
import { DossierOutletContext } from './types';
import { getDossierConfig } from './utils';

export const DossierDetails = () => {
  const { dossierId } = useParams();
  if (!dossierId) return null;

  const { isFeatureActive } = useContext(FeatureFlagsContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    data: dossier,
    updateData: fetchRentalDossier,
    Loader,
  } = useLoader(getDossierConfig(dossierId));

  const dossierRoutes = useMemo(() => {
    if (matches) {
      const { details: _details, ...routes } = dossierTabRoutes;
      return routes;
    }
    return dossierTabRoutes;
  }, [matches]);

  return (
    <Loader>
      {dossier && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={8} xl={8.5}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              {!matches && (
                <DossierViewHeader dossier={dossier} fetchDossier={fetchRentalDossier} />
              )}
              <RouteTabs
                routes={dossierRoutes}
                subRoutes={{
                  documents: {
                    tKey: 'dossierDocuments',
                    defaultRoute: 'products',
                    routes: dossierDocumentsTabRoutes,
                  },
                  products: routes.rentalDossierProduct(':dossierId', ':productId'),
                }}
                parentPath={routes.rentalDossier(dossierId)}
                translationKey="dossierView"
                hiddenTabs={!isFeatureActive('HistoryLogging') ? ['history'] : []}
              />
            </Stack>
            <Outlet
              context={{ ...dossier, fetchRentalDossier } as DossierOutletContext}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            xl={3.5}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
          >
            <DossierViewHeader dossier={dossier} fetchDossier={fetchRentalDossier} />
            <Stack spacing={3}>
              <DossierEstateCard estate={dossier.estate} />
              {dossier.ownReference && (
                <OwnReferenceCard ownReference={dossier.ownReference} />
              )}
              <AffiliatedPersonsCard title="landlords" persons={dossier.landlords} />
              <AffiliatedPersonsCard title="tenants" persons={dossier.tenants} />
              {dossier.rentalConditions && (
                <RentalConditionsCard {...dossier.rentalConditions} />
              )}
              {dossier.residents && <ResidentsSection {...dossier.residents} />}
              <ResponsibleUsersSection responsibleUsers={dossier.responsibleUsers} />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
