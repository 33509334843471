import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconTooltip from 'atoms/IconTooltip';
import { WarningIcon } from 'icons';
import { DossierTableDocumentData } from 'templates/DossierDocumentsTable/types';
import { DocumentScope, DocumentType } from 'api/documents/types';

interface Props {
  documentsDataToDisplay: DossierTableDocumentData[];
  scope: DocumentScope;
  missingDocumentTypes: DocumentType[];
}

const DocumentActionIcon = ({
  scope,
  documentsDataToDisplay,
  missingDocumentTypes,
}: Props) => {
  const { t } = useTranslation(['table', 'documents']);

  const missingTypes = useMemo(
    () =>
      documentsDataToDisplay
        .filter(({ isMissing }) => isMissing)
        .map(({ documentType }) => documentType),
    [documentsDataToDisplay],
  );

  const notAttachedTypes = useMemo(
    () => [
      ...new Set(
        documentsDataToDisplay
          .filter(
            ({ isMissing, documentType }) =>
              scope !== 'dossier' &&
              !isMissing &&
              missingDocumentTypes.includes(documentType) &&
              documentsDataToDisplay
                .filter((document) => document.documentType === documentType)
                .every(({ attached }) => !attached),
          )
          .map(({ documentType }) => documentType),
      ),
    ],
    [documentsDataToDisplay],
  );

  const tooltipTitle = useMemo(() => {
    if (missingTypes.length) {
      return t('documents.tooltips.uploadMissingFile', {
        missingTypes: missingTypes
          .map((type) => t(`documents:${scope}.${type}`, type))
          .join(', '),
      });
    }

    if (notAttachedTypes.length) {
      return t('documents.tooltips.attachMissingFile', {
        missingTypes: notAttachedTypes
          .map((type) => t(`documents:${scope}.${type}`, type))
          .join(', '),
      });
    }
    return null;
  }, [missingTypes, notAttachedTypes]);

  return missingTypes.length || notAttachedTypes.length ? (
    <IconTooltip title={tooltipTitle}>
      <WarningIcon color="error" />
    </IconTooltip>
  ) : null;
};

export default DocumentActionIcon;
