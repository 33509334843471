import { Box } from '@mui/material';
import { ProductProcessInterface } from 'api/dossier/products/types';
import ProductAction from './ProductAction';

const ProductSecondaryActions = ({
  actions,
}: Required<Pick<ProductProcessInterface, 'actions'>>) => (
  <Box
    display="flex"
    flexWrap="wrap"
    alignItems="center"
    justifyContent="flex-end"
    gap={1}
  >
    {actions.map((action) => (
      <ProductAction
        key={action.name}
        action={action}
        buttonProps={{
          variant: 'outlined',
          size: 'small',
          // Until we do not have icons for all actions, it should not be visible
          // startIcon: <ProductActionIcon name={action.name} />,
        }}
      />
    ))}
  </Box>
);

export default ProductSecondaryActions;
