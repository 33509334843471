import { isSameDay } from 'date-fns';
import CalendarDaySelectorBox from './CalendarDaySelectorBox';
import CalendarSelectedDayBox from './CalendarSelectedDayBox';
import CalendarDayPicker from './CustomPickersDay';
import { CustomCalendarDayProps } from './types';
import { isBetween } from './utils';

const CustomCalendarDay = ({
  day,
  dateFrom,
  dateTo,
  hoveredDay,
  ...other
}: CustomCalendarDayProps) => {
  const extendToLeft =
    (!!hoveredDay && isBetween(hoveredDay, dateFrom, day)) ||
    (!dateTo && !!dateFrom && !!hoveredDay && isBetween(hoveredDay, dateFrom, day));

  const extendToRight =
    (!!hoveredDay && !!dateTo && isBetween(dateTo, hoveredDay, day)) ||
    (!dateTo && !!dateFrom && isBetween(dateFrom, hoveredDay, day));

  const isDaySelected =
    (!!dateFrom && isSameDay(day, dateFrom)) || (!!dateTo && isSameDay(day, dateTo));

  const isDayHovered = !!hoveredDay && isSameDay(day, hoveredDay);

  const isDayInRange =
    !!dateTo &&
    !!dateFrom &&
    !isSameDay(dateFrom, dateTo) &&
    isBetween(dateFrom, dateTo, day);

  return (
    <CalendarSelectedDayBox
      isDayInRange={isDayInRange}
      isFirst={!!dateFrom && isSameDay(day, dateFrom)}
      isLast={!!dateTo && isSameDay(day, dateTo)}
    >
      <CalendarDaySelectorBox
        isSelected={isDaySelected}
        isHovered={isDayHovered}
        isHoverBefore={extendToLeft}
        isHoverAfter={extendToRight}
      >
        <CalendarDayPicker
          {...other}
          day={day}
          disableMargin
          isSelected={isDaySelected}
          isHovered={isDayHovered}
        />
      </CalendarDaySelectorBox>
    </CalendarSelectedDayBox>
  );
};

export default CustomCalendarDay;
