import { RolePermissions } from 'api/config/types';

interface PermissionConfig {
  Read: RolePermissions;
  Write: RolePermissions;
}

export const DashboardPermissions: PermissionConfig = {
  Read: 'BusinessDataRead',
  Write: 'BusinessDataWrite',
};

export const EstatePermissions: PermissionConfig = {
  Read: 'BusinessDataRead',
  Write: 'BusinessDataWrite',
};

export const PersonPermissions: PermissionConfig = {
  Read: 'BusinessDataRead',
  Write: 'BusinessDataWrite',
};

export const DossierPermissions: PermissionConfig = {
  Read: 'BusinessDataRead',
  Write: 'BusinessDataWrite',
};

export const UserPermissions: PermissionConfig = {
  Read: 'UsersDataRead',
  Write: 'UsersDataWrite',
};

export const BackofficePermissions: PermissionConfig = {
  Read: 'BackofficeData',
  Write: 'BackofficeData',
};
