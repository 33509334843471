import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { clearPostCodeAndCitiesIds } from 'formData/common/address/utils';
import {
  DEFAULT_PARAMS,
  INPUT_LENGTH_TO_FETCH_OPTIONS,
} from 'hooks/useAddressFields/static-data';
import { PostCodeAndCityOption } from 'hooks/useAddressFields/types';
import usePostCodeAndCityValidation from 'hooks/useAddressFields/usePostCodeAndCityValidation';
import {
  createCityOption,
  createPostCodeOption,
  translateCity,
} from 'hooks/useAddressFields/utils';
import useFormMethods from 'hooks/useFormMethods';
import { getInputLabel } from 'utils/missingFields';
import { AddressFormInterface } from 'api/common/types';
import { ControlledSearchInput } from 'components/inputs';
import {
  HandleSearchChange,
  HandleSearchClose,
  HandleSearchInputChange,
} from 'components/inputs/SearchInput/types';
import { BelgiumPostCodeAndCityProps } from './types';

const BelgiumPostCodeAndCity = ({
  prefix,
  isMissingFieldsModal,
  postCodes,
  cities,
  fetchingPostCodesWithCities,
  postCodeAndCityParams: params,
  updatePostCodeAndCityParams: updateParams,
  fetchPostCodesWithCities,
  disableFields,
}: BelgiumPostCodeAndCityProps) => {
  const { t } = useTranslation();
  const { registerController, setValue, trigger, getValues, formState } = useFormMethods<{
    address: AddressFormInterface;
  }>(prefix);

  const validateInitPostCodeAndCity = usePostCodeAndCityValidation({
    prefix,
    fetchPostCodesWithCities,
  });

  useEffect(() => {
    validateInitPostCodeAndCity();
  }, [formState.submitCount]);

  const onCityChange: HandleSearchChange<PostCodeAndCityOption> = (option) => {
    if (option?.postCode && option?.city) {
      const { postCode, city, value } = option;
      setValue(`address.postCode`, value, { shouldDirty: true });
      trigger(['address.city', 'address.postCode']);
      updateParams({ postCode, city: translateCity(city) });
    }
  };

  const onPostCodeChange: HandleSearchChange<PostCodeAndCityOption> = (option) => {
    if (option?.postCode && option?.city) {
      const { postCode, city, value } = option;
      setValue(`address.city`, value, { shouldDirty: true });
      trigger(['address.city', 'address.postCode']);
      updateParams({ postCode, city: translateCity(city) });
    }
  };

  const onInputChange =
    (key: 'postCode' | 'city'): HandleSearchInputChange =>
    (input, reason) => {
      if (reason === 'input') {
        if (input.length >= INPUT_LENGTH_TO_FETCH_OPTIONS[key]) {
          updateParams({ ...DEFAULT_PARAMS, [key]: input }, true);
        } else {
          updateParams(DEFAULT_PARAMS, true);
        }
      } else if (reason === 'clear') {
        updateParams({ [key]: '' });
      }
    };

  const onPostCodeClose: HandleSearchClose<PostCodeAndCityOption> = (option, reason) => {
    if (reason === 'blur') {
      if (option && params.postCode !== option.postCode) {
        const city = translateCity(option.city ?? '');
        updateParams({ city, postCode: option.postCode });
      } else if (!option && params.postCode) {
        updateParams({ postCode: '' });
      }
    }
  };

  const onCityClose: HandleSearchClose<PostCodeAndCityOption> = (option, reason) => {
    if (reason === 'blur') {
      if (option) {
        const city = translateCity(option.city ?? '');
        if (params.city !== city) {
          updateParams({ postCode: option.postCode ?? '', city });
        }
      } else if (!option && params.city) {
        updateParams({ city: '' });
      }
    }
  };

  const registerInput = (name: 'postCode' | 'city') => {
    const label = getInputLabel(`address.${name}`, isMissingFieldsModal);
    return {
      label,
      labelVariant: isMissingFieldsModal ? 'separate' : 'float',
      loading: fetchingPostCodesWithCities,
      disabled: disableFields,
      ...registerController(`address.${name}`),
    } as const;
  };

  return (
    <>
      <ControlledSearchInput
        xs={6}
        md={3}
        {...registerInput('postCode')}
        options={postCodes}
        onClose={onPostCodeClose}
        onChange={onPostCodeChange}
        onInputChange={onInputChange('postCode')}
        renderValue={(option) => option.postCode ?? ''}
        placeholder={t('selectInput.placeholder.postCode')}
        defaultOption={
          getValues('address.postCode')
            ? createPostCodeOption(clearPostCodeAndCitiesIds(getValues('address')))
            : undefined
        }
      />
      <ControlledSearchInput
        xs={6}
        md={3}
        {...registerInput('city')}
        options={cities}
        onClose={onCityClose}
        onChange={onCityChange}
        onInputChange={onInputChange('city')}
        renderValue={(option) => translateCity(option.city ?? '')}
        placeholder={t('selectInput.placeholder.city')}
        defaultOption={
          getValues('address.city')
            ? createCityOption(clearPostCodeAndCitiesIds(getValues('address')))
            : undefined
        }
      />
    </>
  );
};

export default BelgiumPostCodeAndCity;
