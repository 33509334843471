import { getObjectEntries } from 'utils/object';
import { matchRoute } from 'utils/route';
import { RouteTabsProps, SubTabsTranslationKey, TabsTranslationKey } from './types';

export const getRoutesToDisplay = <
  TabsKey extends TabsTranslationKey,
  SubTabsKey extends SubTabsTranslationKey,
>(
  {
    parentPath,
    routes,
    translationKey,
    hiddenTabs,
    subRoutes,
  }: RouteTabsProps<TabsKey, SubTabsKey>,
  pathname: string,
) =>
  getObjectEntries(routes)
    .filter(([routeKey]) => !hiddenTabs?.some((tab) => tab === routeKey))
    .map(([routeKey, routePath]) => {
      const subRoutesData = subRoutes?.[routeKey];
      const fullPath = `${parentPath}/${routePath}`;

      if (typeof subRoutesData === 'object') {
        const mappedSubRoutes = getObjectEntries(subRoutesData.routes).map(
          ([subRouteKey, subRoutePath]) => ({
            tKey: subRoutesData.tKey,
            label: subRouteKey,
            to: `${fullPath}/${subRoutePath}`,
            isSubRouteActive: !!matchRoute(`${fullPath}/${subRoutePath}`, pathname),
          }),
        );

        return {
          tKey: translationKey,
          label: routeKey,
          to: `${fullPath}/${subRoutesData.defaultRoute}`,
          isRouteActive: mappedSubRoutes.some(({ isSubRouteActive }) => isSubRouteActive),
          subRoutes: mappedSubRoutes,
        };
      }

      let isRouteActive = !!matchRoute(fullPath, pathname);
      if (typeof subRoutesData === 'string') {
        const nestedPath = subRoutesData;
        isRouteActive = !!matchRoute([fullPath, nestedPath], pathname);
      }

      return {
        tKey: translationKey,
        label: routeKey,
        to: fullPath,
        isRouteActive,
        subRoutes: [],
      };
    });
