import { Navigate, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { FeatureFlagsProvider } from 'contexts/featureFlags/FeatureFlagsContext';
import Layout from 'Layout';
import routes from 'router/routes';
import ThemeCustomization from 'themes';

const Faq = () => {
  const { pathname } = useLocation();

  if (pathname === routes.faq) {
    return <Navigate to={routes.faqPersons} />;
  }

  return (
    <ThemeCustomization>
      <AuthenticatedTemplate>
        <FeatureFlagsProvider>
          <Layout forFaq />
        </FeatureFlagsProvider>
      </AuthenticatedTemplate>
    </ThemeCustomization>
  );
};

export default Faq;
