import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { PersonIcon } from 'icons';
import routes from 'router/routes';
import { firstCharLowerCase } from 'utils/string';
import transformName from 'utils/transformName';
import { apiUrl } from 'api/const';
import { PersonType } from 'api/person/types';

interface Props {
  id: string;
  type: PersonType;
  data: { firstName?: string; name: string };
}

const PersonViewHeader = ({ id, type, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: `${firstCharLowerCase(type)}s`,
      url: apiUrl[firstCharLowerCase(type)](id),
      onSuccess: () => {
        navigate(routes.persons);
      },
      translationValues: { personName: transformName(data) },
    });

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Box display="flex" alignItems="center" gap={1} mr="auto">
        <PersonIcon personType={type} />
        <Typography variant="h4" data-testid="personName">
          {transformName(data)}
        </Typography>
      </Box>
      <Button
        onClick={() => navigate(routes.personEdition(id, type))}
        role="secondary"
        size="small"
        data-testid="editButton"
      >
        {t('edit')}
      </Button>
      <Button
        onClick={onDelete}
        role="unsafe"
        variant="outlined"
        size="small"
        data-testid="deleteButton"
      >
        {t('delete')}
      </Button>
    </Box>
  );
};

export default PersonViewHeader;
