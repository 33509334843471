import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { PhoneIcon } from 'icons';
import { getAgentContactBoxStyles } from './styles';

interface Props {
  isSidebarExpanded: boolean;
  onClick: () => void;
}

const PhoneWrapper = ({ children }: PropsWithChildren) => (
  <Typography fontWeight="bold">{children}</Typography>
);
const TextWrapper = ({ children }: PropsWithChildren) => (
  <Typography variant="caption" sx={{ textWrap: 'wrap' }}>
    {children}
  </Typography>
);

const AgentContactBox = ({ isSidebarExpanded, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Box onClick={onClick} sx={getAgentContactBoxStyles(isSidebarExpanded)}>
      <PhoneIcon fontSize="medium" sx={({ mixins }) => mixins.sidebarIcon} />
      <Stack flex={1} gap={1}>
        <Stack gap={0}>
          <Trans
            t={t}
            i18nKey="aside.agentNumber"
            defaults="<Phone>+32 (0)56 43 11 55</Phone><Text>Your real estate agent line</Text>"
            components={{
              Phone: <PhoneWrapper />,
              Text: <TextWrapper />,
            }}
          />
        </Stack>
        <Stack>
          <Trans
            t={t}
            i18nKey="aside.korfineNumber"
            defaults="<Phone>+32 (0)56 21 61 82</Phone><Text>General phone number to Korfine</Text>"
            components={{
              Phone: <PhoneWrapper />,
              Text: <TextWrapper />,
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AgentContactBox;
