import { namespaces } from 'i18n';

export const fileNames = Object.keys(namespaces).map((name) => name + '.json');

export const filenameValidation = (file: File) =>
  fileNames.includes(file.name)
    ? null
    : {
        code: 'wrong-name',
        message: `Name can not be ${file.name}`,
      };
