import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import CardRow from 'templates/CardRow';
import { formatDate } from 'utils/date';
import paymentToString from 'utils/paymentToString';
import { RentalConditions } from 'api/dossier/types';
import RentalDurationRow from './RentalDurationRow';
import RentalPaymentRow from './RentalPaymentRow';
import { formatTaxPayableByTenant } from './utils';

const RentalConditionsCard = ({
  rentType,
  contractSigningDate,
  entryDate,
  endDate,
  duration,
  durationNumberOfMonths,
  rentalPrice,
  rentFrequency,
  depositAmount,
  charges,
  isTaxPayableByTenant,
  taxPayableByTenant,
  isAdditionalVatRegimeApplicable,
  isVatRegimeApplicable,
}: RentalConditions) => {
  const { t } = useTranslation(['common', 'formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.dossier.rentalConditions')} />
      <CardRow title={t('formFields:rentType')} value={t(`enums:rentType.${rentType}`)} />
      {contractSigningDate && (
        <CardRow
          title={t('formFields:contractSigningDate')}
          value={formatDate(contractSigningDate)}
        />
      )}
      <RentalDurationRow
        entryDate={entryDate}
        endDate={endDate}
        duration={duration}
        durationNumberOfMonths={durationNumberOfMonths}
      />
      <RentalPaymentRow rentFrequency={rentFrequency} rentalPrice={rentalPrice} />
      {depositAmount && (
        <CardRow
          title={t('formFields:depositAmount')}
          value={paymentToString(depositAmount)}
        />
      )}
      {charges && (
        <CardRow title={t('formFields:charges')} value={paymentToString(charges)} />
      )}
      {isTaxPayableByTenant && taxPayableByTenant && (
        <CardRow
          title={t('formFields:isTaxPayableByTenant')}
          value={formatTaxPayableByTenant(taxPayableByTenant)}
        />
      )}
      {isVatRegimeApplicable && (
        <CardRow
          title={t('formFields:isVatRegimeApplicable')}
          value={t('common:yes')}
          footer={
            isAdditionalVatRegimeApplicable && (
              <Typography variant="caption" color="grey.500">
                {t('formFields:isAdditionalVatRegimeApplicable')}
              </Typography>
            )
          }
        />
      )}
    </Card>
  );
};

export default RentalConditionsCard;
