import { addressSchema } from 'formData/common/schema';
import { TKey } from 'i18next';
import yup, { numberSchema } from 'utils/yup';

const estateSchema = yup.object({
  status: yup.string<TKey<'enums', 'estateStatus'>>().default('Active'),
  ownReference: yup
    .string()
    .label('ownReference')
    .nullable()
    .default(null)
    .transform((value) => value || null),
  photo: yup.string().nullable(),
  address: addressSchema,
  destination: yup
    .string<TKey<'enums', 'estateDestination'>>()
    .label('destination')
    .required(),
  type: yup
    .string<TKey<'enums', 'estateType'>>()
    .label('type')
    .nullable()
    .default(null)
    .when('destination', {
      is: (destination: TKey<'enums', 'estateDestination'>) =>
        destination && destination !== 'Agriculture',
      then: (schema) => schema.required(),
    }),
  subType: yup
    .string<TKey<'enums', 'estateSubtype'>>()
    .label('subType')
    .nullable()
    .default(null)
    .when('type', {
      is: (type: TKey<'enums', 'estateType'>) =>
        type === 'House' || type === 'Apartment' || type === 'Room',
      then: (schema) => schema.required(),
    }),
  floorLevel: numberSchema.label('floorLevel').nullable().min(0).max(108),
});

export default estateSchema;
