import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { getDocumentLabel } from 'utils/products';
import { ProductCardFooterProps } from './types';

const DocumentButtonTooltip = ({
  missingDocuments = [],
}: Pick<ProductCardFooterProps, 'missingDocuments'>) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ '& > ul': { mb: 0.5, pl: 3, pr: 1 } }}>
      <Typography variant="caption" color="white">
        {t('tooltips.uploadMissingDocuments')}
      </Typography>
      <ul>
        {missingDocuments.map((document) => (
          <li key={`${document.name}_${document.relatedEntityId}`}>
            <Typography variant="caption" color="white">
              {getDocumentLabel(document)}
            </Typography>
          </li>
        ))}
      </ul>
    </Stack>
  );
};

export default DocumentButtonTooltip;
