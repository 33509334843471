import { Box } from '@mui/material';
import { TranslatedTableColumn } from '../types';
import { TABLE_FILTER_PADDING_X } from './static-data';
import TableDateFilter from './TableDateFilter';
import TableDateRangeFilter from './TableDateRangeFilter';
import TableSelectFilter from './TableSelectFilter';
import TableTextFilter from './TableTextFilter';

const Input = (props: TranslatedTableColumn) => {
  switch (props.filterType) {
    case 'select':
      return <TableSelectFilter {...props} />;
    case 'date':
      return <TableDateFilter {...props} />;
    case 'dateRange':
      return <TableDateRangeFilter {...props} />;
    default:
      return <TableTextFilter {...props} />;
  }
};

const TableFilter = (column: TranslatedTableColumn) => (
  <Box
    width={'computedWidth' in column ? (column.computedWidth as number) : undefined}
    visibility={column.filterable ? 'visible' : 'hidden'}
    px={TABLE_FILTER_PADDING_X}
  >
    <Input {...column} />
  </Box>
);

export default TableFilter;
