import { fetchPersonOptions } from 'hooks/useSearchInput/utils';
import i18n from 'i18n';
import { EstateIcon, LegalPersonIcon, NaturalPersonIcon } from 'icons';
import routes from 'router/routes';
import { SetState } from 'types/setState';
import transformName from 'utils/transformName';
import { DossierResponsibleUserInterface } from 'api/dossier/types';
import { getEstates } from 'api/estate/requests';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { getPrivateRelation } from 'api/relations/requests';
import { getUsers } from 'api/users/requests';
import {
  FetchOptions,
  RelationData,
  RelationOption,
  RelationResponses,
  UpdateRelationsState,
} from './types';

export const personToRelation = (
  person: FetchedPersonData,
): RelationData<FetchedPersonData> => ({
  ...person,
  details: person.address,
  link: routes.personTab(person.id, person.type).details,
});

export const personToRelationOption = (
  person: FetchedPersonData,
): RelationOption<FetchedPersonData> => ({
  value: person.id,
  label: person.name,
  Icon: person.type === 'LegalPerson' ? LegalPersonIcon : NaturalPersonIcon,
  caption: person.address,
  id: person.id,
  query: person.name,
  data: personToRelation(person),
  suffix: person.email,
});

export const fetchPersonRelationOptions: FetchOptions<FetchedPersonData> = async (
  params,
) => {
  const persons = await fetchPersonOptions(params);
  return persons.map(personToRelationOption);
};

export const fetchPersonRelationOptionsByType =
  (type: PersonType): FetchOptions<FetchedPersonData> =>
  (params) =>
    fetchPersonRelationOptions({ ...params, type });

export const estateToRelation = (
  estate: FetchedEstateListItem,
): RelationData<FetchedEstateListItem> => ({
  ...estate,
  name: estate.address,
  details: `${estate.postCode} ${estate.city}`,
  link: routes.estateTab(estate.id).estateInfo,
});

export const estateToRelationOption = (
  estate: FetchedEstateListItem,
): RelationOption<FetchedEstateListItem> => ({
  value: estate.id,
  label: estate.address,
  caption: `${estate.postCode} ${estate.city}`,
  Icon: EstateIcon,
  id: estate.id,
  query: estate.address,
  data: estateToRelation(estate),
});

export const fetchEstateOptions: FetchOptions<FetchedEstateListItem> = async ({
  query,
}) => {
  const { ok, response } = await getEstates({ address: query, limit: 15 });
  if (ok) {
    return response.data.map(estateToRelationOption);
  }
  return [];
};

export const responsibleUserToRelation = (
  user: DossierResponsibleUserInterface,
): RelationData<DossierResponsibleUserInterface> => ({
  ...user,
  name: transformName(user),
  link: routes.user(user.id),
});

const responsibleUserToRelationOption = (
  user: DossierResponsibleUserInterface,
): RelationOption<DossierResponsibleUserInterface> => ({
  value: user.id,
  label: transformName(user),
  id: user.id,
  data: responsibleUserToRelation(user),
});

export const fetchResponsibleUsersOptions: FetchOptions<
  DossierResponsibleUserInterface
> = async ({ query }) => {
  const { ok, response } = await getUsers({ name: query, limit: 25 });
  if (ok) {
    return response.data.map(responsibleUserToRelationOption);
  }
  return [];
};

const getRelation = (responses: RelationResponses, id: string) =>
  responses.find(({ relationId }) => relationId === id);

const isRelationSaved = (responses: RelationResponses, id: string) =>
  !!getRelation(responses, id)?.ok;

export const updateRelationsState: UpdateRelationsState = async (
  responses,
  setRelationsToPreview,
  showSaved,
) => {
  const allSaved = responses.every(({ ok }) => ok);
  const anySaved = responses.some(({ ok }) => ok);

  if (allSaved && !showSaved) {
    setRelationsToPreview([]);
  } else {
    setRelationsToPreview((prev) => {
      return prev
        .filter((relation) =>
          showSaved ? true : !isRelationSaved(responses, relation.id),
        )
        .map((relation) => ({
          ...relation,
          responseId: getRelation(responses, relation.id)?.responseId,
          isSaved: isRelationSaved(responses, relation.id),
          errorMessage: getRelation(responses, relation.id)?.message,
        }));
    });
  }
  return { allSaved, anySaved };
};

export const findPersonRelative = async (person: RelationData<FetchedPersonData>) => {
  if (person.hasPrivateRelation) {
    const { ok, response } = await getPrivateRelation(person.id);
    if (ok && response) {
      return personToRelation(response.relative);
    }
  }
};

export const getPersonsIdsToFilter = <
  T extends Partial<{
    id: string;
    personId: string;
    representativeId: string | null;
    representeeId: string | null;
  }>,
>(
  relations: T[],
) =>
  relations
    .flatMap(({ id, personId, representativeId, representeeId }) => [
      id,
      personId,
      representativeId,
      representeeId,
    ])
    .filter((id): id is string => !!id);

export const selectPersonWithPartner =
  (setState: SetState<RelationData<FetchedPersonData>[]>, personsIdsToFilter: string[]) =>
  async (person: RelationData<FetchedPersonData>) => {
    setState((prev) => [person, ...prev]);
    const relative = await findPersonRelative(person);
    if (relative) {
      setState((persons) =>
        !personsIdsToFilter.find((id) => id === relative.id)
          ? [relative, ...persons]
          : persons,
      );
    }
  };

export const getRelationErrorMessage = (relation?: RelationData | null) => {
  if (!relation?.errorMessage) return null;

  const { errorMessage } = relation;
  if (typeof errorMessage === 'string') {
    return errorMessage;
  }
  return i18n.t(`errors:${errorMessage.key}`, { ...errorMessage.values });
};

export const areRelationsEqual = <T extends object>(
  a: RelationData<T>[],
  b: RelationData<T>[],
) => {
  if (!a.length && !b.length) {
    return true;
  }

  if (a.length !== b.length) {
    return false;
  }

  return (
    JSON.stringify(a.map(({ id, toDelete = false }) => `${id}${toDelete}`).sort()) ===
    JSON.stringify(b.map(({ id, toDelete = false }) => `${id}${toDelete}`).sort())
  );
};

export const getRelationsIds = <T extends object>(relations: RelationData<T>[]) =>
  relations.filter(({ toDelete }) => !toDelete).map(({ id }) => id);
