import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { initMeta } from '../static-data';
import { ListSearchParams, TableSearchParams } from '../types';

export const stringToSortModel = (sort: string | undefined): GridSortModel => {
  const [field, direction] = sort ? sort.split(':') : [];
  return field && direction ? [{ field, sort: direction as GridSortDirection }] : [];
};

export const removeEmptySearchParams = (params: object): Record<string, string> =>
  Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    ),
  );

export const getListSearchParams = (params?: TableSearchParams): ListSearchParams => {
  const { limit = initMeta.limit, page = 1, sort, ...filters } = params ?? {};

  return {
    limit: Number(limit),
    offset: (Number((page as number) > 0 ? page : 1) - 1) * Number(limit),
    sort,
    filters,
  };
};
