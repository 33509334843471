import { Grid } from '@mui/material';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';
import { FormContainer } from 'templates/FormTemplates';

const NaturalPersonDocumentsStep = () => (
  <FormContainer>
    <Grid item xs={12}>
      <UploadDocumentsWithContext scope="naturalPerson" />
    </Grid>
  </FormContainer>
);

export default NaturalPersonDocumentsStep;
