import { useCallback, useEffect, useState } from 'react';
import { getRegion } from './utils';

export const useGetRegion = (postCode: string | undefined, city: string | undefined) => {
  const [region, setRegion] = useState('');

  const fetchRegion = useCallback(async () => {
    if (postCode && city) {
      const region = await getRegion(postCode, city);
      setRegion(region || '');
    }
  }, [postCode, city]);

  useEffect(() => {
    fetchRegion();
  }, [fetchRegion]);

  return region;
};
