import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { EstateIcon } from 'icons';
import routes from 'router/routes';
import { groupAddressValues } from 'templates/CardRow/utils';
import addressToString from 'utils/addressToString';
import { apiUrl } from 'api/const';
import { EstateViewInterface } from 'api/estate/types';

const EstateViewHeader = ({
  id,
  data: { photo, address },
}: Pick<EstateViewInterface, 'id' | 'data'>) => {
  const { t, i18n } = useTranslation();
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onCopy = () => {
    navigate(routes.newEstate, { state: { estateId: id } });
  };

  const onEdit = () => {
    navigate(routes.estateEdition(id));
  };

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'estates',
      translationValues: {
        estateAddress: addressToString(address, { isFullAddress: false }),
      },
      url: apiUrl.estate(id),
      errorMessage: { status: 409, text: 'estates.409' },
      onSuccess: () => {
        navigate(routes.estates);
      },
    });

  const formattedAddress = useMemo(
    () => groupAddressValues(address),
    [address, i18n.language],
  );

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Box display="flex" alignItems="center" gap={1} mr="auto">
        {photo ? (
          <Avatar src={photo} sx={({ mixins }) => mixins.estatePhoto} />
        ) : (
          <EstateIcon />
        )}
        <Box display="flex" flexDirection="column">
          <Typography variant="h4">{formattedAddress.primary}</Typography>
          <Typography variant="caption">{formattedAddress.secondary}</Typography>
        </Box>
      </Box>

      <Button onClick={onCopy} role="secondary" size="small">
        {t('copy')}
      </Button>
      <Button onClick={onEdit} role="secondary" size="small">
        {t('edit')}
      </Button>
      <Button onClick={onDelete} role="unsafe" variant="outlined" size="small">
        {t('delete')}
      </Button>
    </Box>
  );
};

export default EstateViewHeader;
