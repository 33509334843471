import { PropsWithChildren, ReactNode } from 'react';
import { MenuItem, MenuItemProps, Tooltip } from '@mui/material';

interface Props extends MenuItemProps, PropsWithChildren {
  reason?: ReactNode;
}

export const TooltipMenuItem = ({
  children,
  reason,
  disabled,
  ...menuItemProps
}: Props) => {
  return disabled && reason ? (
    <Tooltip title={reason} placement="top">
      <div>
        <MenuItem disabled={disabled} {...menuItemProps}>
          {children}
        </MenuItem>
      </div>
    </Tooltip>
  ) : (
    <MenuItem disabled={disabled} {...menuItemProps}>
      {children}
    </MenuItem>
  );
};
