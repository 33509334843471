import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mui/material';
import { ActionCell } from 'templates/Table/Cells';
import { SetState } from 'types/setState';
import { DemoInterface } from 'api/faq/types';
import { faqTableColumns } from 'pages/FaqManagement/static-data';
import { getActions } from './utils';

interface Props {
  demoData: DemoInterface;
  getList: () => Promise<void>;
  setDemoToUpdate: SetState<DemoInterface | null>;
}
const FaqTableRow = ({ demoData, getList, setDemoToUpdate }: Props) => {
  const { t } = useTranslation('enums');
  return (
    <TableRow>
      {faqTableColumns.map(({ name, translationPrefix }) => (
        <TableCell key={name} sx={({ mixins }) => mixins.tableCellStyles}>
          {translationPrefix
            ? t(`${translationPrefix}.${demoData[name]}`, demoData[name])
            : demoData[name]}
        </TableCell>
      ))}
      <TableCell sx={({ mixins }) => mixins.actionCellStyles}>
        <ActionCell
          tKey="faq"
          actions={getActions(demoData, setDemoToUpdate)}
          updateRows={getList}
        />
      </TableCell>
    </TableRow>
  );
};

export default FaqTableRow;
