import { PropsWithChildren } from 'react';
import { Divider, Grid, GridProps } from '@mui/material';
import TextDivider from 'atoms/TextDivider';

export const FormContainer = ({ children, rowSpacing = 4, ...props }: GridProps) => (
  <Grid container width="100%" rowSpacing={rowSpacing} {...props}>
    {children}
  </Grid>
);

const SectionWrapper = ({ children, ...props }: GridProps) => (
  <Grid item spacing={props.container ? 2 : undefined} pt={2} {...props}>
    {children}
  </Grid>
);

export const FormSection = ({
  children,
  label,
  ...props
}: GridProps & { label?: string }) =>
  label ? (
    <Grid item xs={12}>
      <TextDivider label={label} />
      <SectionWrapper {...props}>{children}</SectionWrapper>
    </Grid>
  ) : (
    <SectionWrapper {...props}>{children}</SectionWrapper>
  );

export const FormSide = ({ children }: PropsWithChildren) => (
  <Grid container item xs={12} md={5.75} rowSpacing={1}>
    {children}
  </Grid>
);

export const FormVerticalDivider = () => (
  <Grid
    item
    md={0.5}
    justifyContent="center"
    alignItems="center"
    display={{ xs: 'none', md: 'flex' }}
  >
    <Divider orientation="vertical" />
  </Grid>
);
