import { translationSchema } from 'formData/common/schema';
import { namespaces } from 'i18n';
import { isTruthyValueInObject, mapObject } from 'utils/object';
import yup, { numberSchema } from 'utils/yup';

const MAX_COUNT = 999;

const estateCompositionSchema = yup
  .object({
    ...mapObject(namespaces.enums.estateComposition, () =>
      numberSchema.label('field').nullable().min(0).max(MAX_COUNT),
    ),
    additionalItems: yup
      .array()
      .of(
        yup.object({
          name: translationSchema,
          count: numberSchema.label('field').required().min(0).max(MAX_COUNT),
        }),
      )
      .nullable(),
  })
  .nullable()
  .default(null)
  .transform((value) => (isTruthyValueInObject(value) ? value : null));

export default estateCompositionSchema;
