import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EstateFormProvider } from 'contexts/estateForm/estateFormContext';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoProvider } from 'contexts/photoUpload/uploadPhotoContext';
import estateStepperDefaultValues from 'formData/estate/stepper/defaultValues';
import estateStepperSchema from 'formData/estate/stepper/schema';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard_V2';
import { filterSteps, getStepsIndex } from 'templates/StepWizard_V2/utils';
import { resolver } from 'utils/yup';
import EstateResetFormCheckbox from './EstateResetFormCheckbox';
import { ESTATE_STEPS } from './static-data';
import { EstateFormDefaultValues, EstateFormInterface, EstateFormProps } from './types';
import useEstateSteps from './useEstateSteps';

const EstateFormStepper = ({
  handleCancel,
  handleSave,
  stepsToHide,
}: EstateFormProps) => {
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
    }
  };

  const steps = useMemo(() => filterSteps(ESTATE_STEPS, stepsToHide), []);

  const stepsProps = useEstateSteps({
    onSaveSuccess: handleSave,
    stepsIndex: getStepsIndex(steps),
  });

  return (
    <StepWizard
      tKey="estate"
      steps={steps}
      {...stepsProps}
      onCancel={onCancel}
      Footer={<EstateResetFormCheckbox />}
    />
  );
};

const EstateForm = ({
  estateId,
  defaultValues,
  hideOwners = false,
  isCopyMode = false,
  ...props
}: EstateFormProps & {
  hideOwners?: boolean;
  estateId?: string | null;
  defaultValues?: EstateFormDefaultValues;
  isCopyMode?: boolean;
}) => {
  const { owners: defaultOwners, estate, estateDetails } = defaultValues ?? {};

  const defaultFormValues: Partial<EstateFormInterface> = useMemo(
    () =>
      estate
        ? {
            estate: { ...estateStepperDefaultValues.estate, ...estate },
            estateDetails: {
              ...estateStepperDefaultValues.estateDetails,
              ...estateDetails,
            },
          }
        : {},
    [],
  );

  const methods = useForm<EstateFormInterface>({
    mode: estateId ? 'onChange' : 'onSubmit',
    resolver: resolver(estateStepperSchema),
    defaultValues: { ...estateStepperDefaultValues, ...defaultFormValues },
  });

  useEffect(() => {
    if (estateId) {
      methods.trigger();
    }
  }, []);

  usePrompt({ when: methods.formState.isDirty, id: 'estateForm' });

  useEffect(() => {
    if (estateId) {
      methods.trigger();
    }
  }, []);

  return (
    <FilesUploadProvider scope="estate">
      <UploadPhotoProvider photoUrl={defaultValues?.estate?.photo}>
        <FormProvider {...methods}>
          <EstateFormProvider
            estateId={estateId}
            defaultOwners={defaultOwners}
            hideOwners={hideOwners}
            isCopyMode={isCopyMode}
            defaultFormValues={defaultFormValues}
          >
            <EstateFormStepper {...props} />
          </EstateFormProvider>
        </FormProvider>
      </UploadPhotoProvider>
    </FilesUploadProvider>
  );
};

export default EstateForm;
