import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer, Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { useTableFiltersState } from '../hooks';
import { filtersToParams, paramsToFilter } from '../utils';
import TableFilterSidebarContent from './TableFilterSidebarContent';
import { TableFilterSidebarProps } from './types';

const TableFilterSidebar = ({
  tKey,
  isOpen,
  filters,
  closeSidebar,
}: TableFilterSidebarProps) => {
  const [params, setParams] = useState<Record<string, string>>({});
  const [filtersState, setFiltersState] = useTableFiltersState();

  const { t } = useTranslation('table', { keyPrefix: 'filters' });

  const handleClearAll = () => {
    const filters = Object.keys(params).map((name) => ({ name, value: '' }));
    setFiltersState(filters);
    closeSidebar();
  };

  const handleShowResults = () => {
    const filters = paramsToFilter(params);
    setFiltersState(filters);
    closeSidebar();
  };

  const updateFilter = (filterName: string, value: string) => {
    setParams((prev) => ({ ...prev, [filterName]: value }));
  };

  useEffect(() => {
    const params = filtersToParams(filtersState);
    setParams(isOpen ? params : {});
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      variant="temporary"
      onClose={closeSidebar}
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <Stack
        justifyContent="space-between"
        height="100%"
        width={450}
        divider={<Divider orientation="horizontal" />}
      >
        <Typography variant="h3" color="primary.main" p={2.5}>
          {t('title')}
        </Typography>
        <TableFilterSidebarContent
          tKey={tKey}
          filters={filters}
          updateFilter={updateFilter}
        />
        <Box display="flex" justifyContent="space-between" gap={2.5} p={2.5}>
          <Button role="primary" fullWidth onClick={handleClearAll}>
            {t('clearAll')}
          </Button>
          <Button variant="contained" fullWidth onClick={handleShowResults}>
            {t('showResults')}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default TableFilterSidebar;
