export enum TestId {
  Table = 'table',
  TableHeader = 'table-header',
  TableMultipleActions = 'table-multiple-actions',
  TableSingleAction = 'table-single-action',
  TableEmptyCell = 'table-empty-cell',
  TableStringLink = 'table-string-link',
  TableComponentLink = 'table-component-link',
  TableFilterSidebar = 'table-filter-sidebar',
  TableGlobalSearch = 'table-global-search',
  TableFilterButton = 'table-filter-button',
  TablePagination = 'table-pagination',
  TableLoader = 'table-loader',
  NotificationTitle = 'notification-title',
  NotificationSubtitle = 'notification-subtitle',
  NotificationDate = 'notification-date',
}
