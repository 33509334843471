import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { WarningIcon } from 'icons';
import { ProductProcessInterface } from 'api/dossier/products/types';

interface Props {
  errors: NonNullable<ProductProcessInterface['reportedErrors']>;
}

export const ProductErrors = ({ errors }: Props) => {
  const { t } = useTranslation(['errors', 'common']);

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="h5" color="white">
            {t('common:productCard.errors')}
          </Typography>
        }
        sx={({ palette }) => ({ bgcolor: palette.error.main })}
      />
      <Stack gap={1.5} p={1.5}>
        {errors.map((error) => (
          <Box key={error} display="flex" alignItems="center" gap={1}>
            <WarningIcon color="error" />
            <Typography>{t(`codes.${error}`, error)}</Typography>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};
