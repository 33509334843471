import { Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import AssignedEstate from './AssignedEstate';
import AssignedPersons from './AssignedPersons';
import { AssignedEntityCardProps } from './types';

const AssignedEntityCard = ({
  link,
  minHeight,
  title,
  subTitle1,
  subTitle2,
  status,
  assignedEstate,
  assignedPersons,
}: AssignedEntityCardProps) => (
  <Card variant="outlined" sx={({ mixins }) => ({ ...mixins.actionCard, minHeight })}>
    <CardActionArea sx={{ height: '100%' }}>
      <CustomLink to={link}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack>
              <Typography color="primary.main" variant="h5">
                {title}
              </Typography>
              {subTitle1 && <Typography color="primary.main">{subTitle1}</Typography>}
              {subTitle2 && (
                <Typography variant="h6" fontWeight="bold" color="secondary.main">
                  {subTitle2}
                </Typography>
              )}
            </Stack>
            {status}
          </Stack>
          {assignedEstate && <AssignedEstate {...assignedEstate} />}
          {assignedPersons.data.length > 0 && <AssignedPersons {...assignedPersons} />}
        </CardContent>
      </CustomLink>
    </CardActionArea>
  </Card>
);

export default AssignedEntityCard;
