import { CountryCode } from 'libphonenumber-js';
import { TAX_RESIDENCE_DATA } from './static-data';

export const isTinValid = (countryCode: CountryCode, tin: string) => {
  const tinLength = TAX_RESIDENCE_DATA[countryCode]?.length;
  if (Array.isArray(tinLength)) {
    return tinLength.some((length) => length === tin.length);
  } else if (tinLength) {
    return tin.length === tinLength;
  }
  return !!tin.length && tin.length < 26;
};
