import { getFormAddress } from 'formData/common/address/utils';
import phoneSchema from 'formData/common/phone/schema';
import { naturalPersonSchema } from 'formData/naturalPerson';
import IBAN from 'iban';
import { formatDate } from 'utils/date';
import getObjectKeys from 'utils/object/getObjectKeys';
import { NaturalPersonData, NaturalPersonFormData } from 'api/person/natural/types';
import { formatPhone } from 'components/inputs/PhoneInput/utils';

const getTaxResidenceBelgium = (taxResidenceBelgium: boolean) =>
  taxResidenceBelgium ? 'Belgium' : 'NotBelgium';

// TODO: Move it into NaturalPersonForm utils
export const getNaturalPersonDefaultValues = ({
  referenceNumber: _referenceNumber,
  dateOfBirth,
  address,
  phone,
  taxResidenceBelgium,
  iban,
  ...initialValues
}: NaturalPersonData): NaturalPersonFormData => ({
  ...naturalPersonSchema.getDefault(),
  ...initialValues,
  address: getFormAddress(address),
  iban: iban ? IBAN.printFormat(iban) : null,
  taxResidenceBelgium:
    taxResidenceBelgium === null ? null : getTaxResidenceBelgium(taxResidenceBelgium),
  dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd'),
  phone:
    phone && getObjectKeys(phone).every((key) => phone?.[key] !== null)
      ? {
          ...phone,
          number: formatPhone(phone.number, phone.code, phone.countryId),
        }
      : phoneSchema.getDefault(),
});
