import { matchPath } from 'react-router-dom';
import { LoaderData } from 'contexts/loaders/types';
import i18n from 'i18n';
import routes from 'router/routes';
import transformName from 'utils/transformName';
import { BreadcrumbsConstructor, BreadcrumbsInterface } from './types';

export const getBreadcrumbs = (
  loaderData: LoaderData | null,
  pathname: string,
): BreadcrumbsInterface[] => {
  let breadcrumbs: BreadcrumbsConstructor[] = [];

  switch (pathname) {
    case routes.newPerson:
      breadcrumbs = [
        { translationKey: 'Persons', to: routes.persons },
        { translationKey: 'Creation' },
      ];
      break;
    case routes.newEstate:
      breadcrumbs = [
        { translationKey: 'Estates', to: routes.estates },
        { translationKey: 'Creation' },
      ];
      break;
    case routes.newDossier:
      breadcrumbs = [
        { translationKey: 'Dossiers', to: routes.dossiers },
        { translationKey: 'Creation' },
      ];
      break;
    case routes.newUser:
      breadcrumbs = [
        { translationKey: 'Users', to: routes.users },
        { translationKey: 'Creation' },
      ];
      break;
  }

  if (loaderData) {
    const { data, scope, id } = loaderData;

    switch (scope) {
      case 'estate':
        if (matchPath(routes.estateEdition(':estateId'), pathname)) {
          breadcrumbs = [
            { translationKey: 'Estates', to: routes.estates },
            { label: data.referenceNumber, to: routes.estateTab(id).estateInfo },
            { translationKey: 'Edition' },
          ];
        } else {
          breadcrumbs = [
            { translationKey: 'Estates', to: routes.estates },
            {
              label: data.referenceNumber,
            },
          ];
        }
        break;
      case 'dossier':
        if (matchPath(routes.rentalDossierEdition(':dossierId'), pathname)) {
          breadcrumbs = [
            { translationKey: 'Dossiers', to: routes.dossiers },
            { label: data.referenceNumber, to: routes.rentalDossier(id) },
            { translationKey: 'Edition' },
          ];
        } else {
          breadcrumbs = [
            { translationKey: 'Dossiers', to: routes.dossiers },
            { label: data.referenceNumber },
          ];
        }
        break;
      case 'legalPerson':
        if (matchPath(routes.personEdition(':personId', 'LegalPerson'), pathname)) {
          breadcrumbs = [
            { translationKey: 'Persons', to: routes.persons },
            { label: data.referenceNumber, to: routes.legalPersonTab(id).details },
            { translationKey: 'Edition' },
          ];
        } else {
          breadcrumbs = [
            { translationKey: 'Persons', to: routes.persons },
            { label: data.referenceNumber },
          ];
        }
        break;
      case 'naturalPerson':
        if (matchPath(routes.personEdition(':personId', 'NaturalPerson'), pathname)) {
          breadcrumbs = [
            { translationKey: 'Persons', to: routes.persons },
            { label: data.referenceNumber, to: routes.naturalPersonTab(id).details },
            { translationKey: 'Edition' },
          ];
        } else {
          breadcrumbs = [
            { translationKey: 'Persons', to: routes.persons },
            { label: data.referenceNumber },
          ];
        }
        break;
      case 'user':
        if (matchPath(routes.userEdition(':personId'), pathname)) {
          breadcrumbs = [
            { translationKey: 'Users', to: routes.users },
            { label: transformName(data), to: routes.user(id) },
            { translationKey: 'Edition' },
          ];
        } else {
          breadcrumbs = [
            { translationKey: 'Users', to: routes.users },
            { label: transformName(data) },
          ];
        }
        break;
    }
  }

  return breadcrumbs.map(({ label = '', translationKey, to }) => ({
    label: translationKey ? i18n.t(`enums:breadcrumbs.${translationKey}`) : label,
    to,
  }));
};
