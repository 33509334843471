import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AccountProvider } from 'contexts/accountContext/AccountContext';
import { NavigationProvider } from 'contexts/navigation/navigationContext';
import Layout from 'Layout';
import ThemeCustomization from 'themes';

function App() {
  return (
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AccountProvider>
          <NavigationProvider>
            <Layout />
          </NavigationProvider>
        </AccountProvider>
      </LocalizationProvider>
    </ThemeCustomization>
  );
}

export default App;
