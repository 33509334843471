import { useMemo } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import AddressFields from 'modules/AddressFields';
import LegalPersonFields from 'modules/LegalPersonFields';
import routes from 'router/routes';
import { isTruthyValueInObject } from 'utils/object';
import { MissingDataForm } from '../types';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { LegalPersonMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getRequiredAndNotRequiredFields,
  isAnyAddressFieldMissing,
} from './utils';

const LegalPersonMissingDataForm = ({
  id,
  index,
  fields,
}: LegalPersonMissingDataFormProps) => {
  const methods = useFormContext<MissingDataForm>();

  const {
    formState: { isDirty, dirtyFields, errors: formErrors, defaultValues },
  } = methods;

  const prefix = useMemo(() => `legalPersons.${index}` as const, [index]);
  const errors = useMemo(
    () => formErrors.legalPersons?.[index],
    [formErrors.legalPersons, index],
  );

  const missingImportantFields = getMissingRequiredFields(errors, fields);

  const areLegalPersonFieldsDirty =
    isDirty && !!isTruthyValueInObject(dirtyFields.legalPersons?.[index]);

  const [requiredFields, notRequiredFields] = getRequiredAndNotRequiredFields(fields);
  const shouldDisplayAddressFields = isAnyAddressFieldMissing(fields);

  const name = useMemo(
    () => defaultValues?.legalPersons?.[index]?.name,
    [defaultValues?.legalPersons?.[index]?.name],
  );

  return (
    <Stack spacing={3} data-testid={id}>
      <Stack spacing={1}>
        <Typography fontWeight="bold">{name}</Typography>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            {!!requiredFields.length && (
              <LegalPersonFields prefix={prefix} missingFields={requiredFields} />
            )}
            {shouldDisplayAddressFields && (
              <AddressFields prefix={prefix} isMissingFieldsModal />
            )}
            {!!notRequiredFields.length && (
              <LegalPersonFields prefix={prefix} missingFields={notRequiredFields} />
            )}
          </Stack>
        </FormProvider>
        {missingImportantFields.length && areLegalPersonFieldsDirty ? (
          <MissingRequiredFieldsError
            tKey="missingPersonRequiredFields"
            to={routes.legalPersonTab(id).details}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default LegalPersonMissingDataForm;
