import i18next, { defaultLng } from 'i18n';
import { Language } from 'types/language';

/**
 * @param i18n pass `i18n` instance when using useTranslation hook
 * @usage when using `useMemo`, `useCallback`, or `useEffect`, remember to add `i18n.language` to dependencies
 */
const getLng = (i18n = i18next) =>
  i18n.language === 'cimode' ? defaultLng : (i18n.language as Language);

export default getLng;
