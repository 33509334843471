import { ComponentProps } from 'react';
import Button from 'atoms/Button';
import CustomLink from 'atoms/Link';

interface Props extends ComponentProps<typeof Button> {
  to: string;
  state?: object;
}

export const RedirectButton = ({ to, state, ...buttonProps }: Props) => (
  <CustomLink to={to} state={state}>
    <Button {...buttonProps} />
  </CustomLink>
);
