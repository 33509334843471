import routes from 'router/routes';
import matchRoute from './matchRoute';

const getRouteMatch = (pathname: string) => ({
  estate: matchRoute(
    Object.values(routes.estateTab(':estateId')),
    pathname,
    routes.newEstate,
  ),
  legalPerson: matchRoute(
    Object.values(routes.legalPersonTab(':personId')),
    pathname,
    routes.newPerson,
  ),
  naturalPerson: matchRoute(
    Object.values(routes.naturalPersonTab(':personId')),
    pathname,
    routes.newPerson,
  ),
  rentalDossier: matchRoute(
    [
      ...Object.values(routes.rentalDossierTab(':dossierId')),
      ...Object.values(routes.rentalDossierDocumentsSubTab(':dossierId')),
      routes.rentalDossierProduct(':dossierId', ':productId'),
    ],
    pathname,
    routes.newDossier,
  ),
  user: matchRoute(routes.user(':userId'), pathname, routes.newUser),
});

export default getRouteMatch;
