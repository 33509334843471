import LegalPersonMissingDataForm from './LegalPersonMissingDataForm';
import NaturalPersonMissingDataForm from './NaturalPersonMissingDataForm';
import { PersonMissingDataFormProps } from './types';

const PersonMissingDataForm = ({
  id,
  personType,
  fields,
  ...props
}: PersonMissingDataFormProps) => {
  switch (personType) {
    case 'LegalPerson': {
      const legalPersonMissingData = fields.find(({ personId }) => personId === id);
      return legalPersonMissingData ? (
        <LegalPersonMissingDataForm
          id={id}
          fields={legalPersonMissingData?.fields ?? []}
          {...props}
        />
      ) : null;
    }
    case 'NaturalPerson': {
      const naturalPersonMissingData = fields.find(({ personId }) => personId === id);
      return naturalPersonMissingData ? (
        <NaturalPersonMissingDataForm
          id={id}
          fields={naturalPersonMissingData.fields}
          {...props}
        />
      ) : null;
    }
    default:
      return null;
  }
};

export default PersonMissingDataForm;
