export const generalSections = ['Persons', 'Estates', 'Dossiers'];
export const productsSections = ['RentalDeposit', 'FireInsurance', 'LegalAssistance'];

export const generalOptions = generalSections.map((type) => ({
  value: type,
  label: type,
  translationKey: `faqSections.${type}`,
}));

export const productsOptions = productsSections.map((type) => ({
  value: type,
  label: type,
  translationKey: `faqSections.${type}`,
}));
