import { ThemedStyle } from 'types/themedStyle';

export const tableToolbarStyles: ThemedStyle = {
  minHeight: '52px',
  backgroundColor: ({ palette }) => palette.primary.main,
  paddingBottom: 0,
  borderRadius: 2,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  justifyContent: 'flex-end',
};
