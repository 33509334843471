import { DossierPermissions } from 'consts/permissions';
import { assignableDossierStatuses } from 'formData/dossier/selectOptions';
import routes from 'router/routes';
import { BoldCell, DossierStatusCell, PersonsCell } from 'templates/Table/Cells';
import { TableAction, TableColumn } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { apiUrl } from 'api/const';
import { updateRentalDossierStatus } from 'api/dossier/requests';
import { FetchedDossierListItem } from 'api/dossier/types';

export const getLinkToDossier = (id: string) => routes.rentalDossierTab(id).products;

export const dossiersColumns: TableColumn<FetchedDossierListItem, 'dossiers'>[] = [
  {
    field: 'referenceNumber',
    flex: 1.5,
    filterable: true,
    sortable: true,
    renderCell: BoldCell,
  },
  {
    field: 'estate',
    flex: 2.5,
    filterable: true,
    sortable: true,
    valueGetter: (_value, row) => row.estate.address,
  },
  {
    field: 'tenants',
    flex: 3,
    filterable: true,
    filterName: 'tenant',
    renderCell: PersonsCell,
  },
  {
    field: 'landlords',
    flex: 3,
    filterable: true,
    filterName: 'landlord',
    renderCell: PersonsCell,
  },
  {
    field: 'status',
    flex: 1.5,
    filterable: true,
    filterName: 'statuses',
    filterType: 'select',
    filterOptions: assignableDossierStatuses,
    renderCell: DossierStatusCell,
  },
  {
    field: 'entryDate',
    flex: 1.5,
    filterable: true,
    sortable: true,
    filterType: 'dateRange',
    valueGetter: (value) => formatDate(value),
  },
];

export const getDossiersActions = ({
  id,
  referenceNumber,
  status,
  deletable,
}: FetchedDossierListItem): TableAction<'dossiers'>[] => [
  {
    name: 'delete',
    disabled: !deletable || !hasPermission(DossierPermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        url: apiUrl.rentalDossier(id),
        translationKey: 'rentalDossiers',
        onSuccess: updateRows,
        errorMessage: { status: 404, text: 'dossiers.404' },
        translationValues: { referenceNumber },
      });
    },
    reason: 'productsInitiated',
  },
  {
    name: 'restore',
    hidden: status !== 'Cancelled' || !hasPermission(DossierPermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        onSubmit: () => updateRentalDossierStatus(id, 'Draft'),
        translationKey: 'restoreRentalDossier',
        onSuccess: updateRows,
        translationValues: { referenceNumber },
        type: 'confirmation',
      });
    },
  },
  {
    name: 'cancel',
    hidden: status !== 'Draft' || !hasPermission(DossierPermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        onSubmit: () => updateRentalDossierStatus(id, 'Cancelled'),
        translationKey: 'cancelRentalDossier',
        onSuccess: updateRows,
        translationValues: {
          referenceNumber,
        },
        type: 'confirmation',
      });
    },
  },
];
