import { SearchOptionsParams } from 'hooks/useSearchInput/types';
import { fetchPersonOptions } from 'hooks/useSearchInput/utils';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import transformName from 'utils/transformName';
import { DossierPartyInterface } from 'api/dossier/types';
import { PartyOption, ProductParty } from './types';

export const partyToOption = (party: DossierPartyInterface) => ({
  value: party.id,
  label: transformName(party),
  personId: party.id,
  Icon: party.type === 'NaturalPerson' ? NaturalPersonIcon : LegalPersonIcon,
  ...party,
});

export const fetchPartyOptions = async (
  params: SearchOptionsParams,
  subHeader: string,
): Promise<PartyOption[]> => {
  const persons = await fetchPersonOptions(params);
  return persons.map((person) => ({ subHeader, ...partyToOption(person) }));
};

export const formatPersonOptions = (
  persons: DossierPartyInterface[],
  subHeader: string,
  hideLegalPerson = true,
): PartyOption[] =>
  persons
    .filter(({ type }) => (hideLegalPerson ? type === 'NaturalPerson' : true))
    .map((person) => ({ subHeader, ...partyToOption(person) }));

export const getRelation = (
  primaryContact: ProductParty,
  secondaryContact: ProductParty,
) => {
  if (primaryContact.partnerId === secondaryContact.id) {
    return primaryContact.relation;
  }
  if (
    !primaryContact.hasPrivateRelation &&
    !secondaryContact.hasPrivateRelation &&
    primaryContact.type === 'NaturalPerson'
  ) {
    return 'Other';
  }
  return null;
};
