import { MouseEventHandler, useState } from 'react';
import { StepsProps } from './types';

const useSteps = ({
  steps,
  disabledSteps,
  onBack,
  onLabel,
  onNext,
  onSave,
}: StepsProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [saving, setSaving] = useState(false);

  const setPrevStep = () => {
    if (activeStep > 0) {
      if (disabledSteps?.[activeStep - 1] && !!steps[activeStep - 2]) {
        setActiveStep((prev) => prev - 2);
      } else {
        setActiveStep((prev) => prev - 1);
      }
    }
  };

  const setNextStep = () => {
    if (activeStep < steps.length - 1) {
      if (disabledSteps?.[activeStep + 1] && !!steps[activeStep + 2]) {
        setActiveStep((prev) => prev + 2);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handlePrevStep: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onBack) {
      onBack(e, setPrevStep, activeStep);
    } else {
      setPrevStep();
    }
  };

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onNext) {
      onNext(e, setNextStep, activeStep);
    } else {
      setNextStep();
    }
  };

  const handleChangeStep =
    (step: number): MouseEventHandler<HTMLButtonElement> =>
    async (e) => {
      if (onLabel) {
        await onLabel(e, () => setActiveStep(step), activeStep);
      } else {
        setActiveStep(step);
      }
    };

  const handleSave: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setSaving(true);
    const resetStepper = () => setActiveStep(0);
    await onSave(e, resetStepper);
    setSaving(false);
  };

  return {
    handleChangeStep,
    handleSave,
    handleNextStep,
    handlePrevStep,
    saving,
    activeStep,
  };
};

export default useSteps;
