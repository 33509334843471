import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { LegalPersonFormContext } from 'contexts/personForm/legalPersonFormContext';
import { StepsErrors } from 'templates/StepWizard_V2/types';
import { useUpdateEffect } from 'usehooks-ts';
import { hasObjectAnyProperty } from 'utils/object';
import { LegalPersonFormData } from 'api/person/legal/types';
import { LegalPersonStepName } from '../types';

const useLegalPersonStepsErrors = (stepsIndex: Record<LegalPersonStepName, number>) => {
  const [stepperErrors, setStepperErrors] = useState<StepsErrors>(null);

  const { representativesToPreview, ownershipsToPreview } =
    useContext(LegalPersonFormContext);
  const { documents } = useContext(FilesUploadContext);

  const {
    formState: { errors },
  } = useFormContext<LegalPersonFormData>();

  useUpdateEffect(() => {
    const isError = hasObjectAnyProperty(errors);

    setStepperErrors((prev) => ({
      ...prev,
      [stepsIndex.general]: isError,
    }));
  }, [Object.keys(errors ?? {}).length]);

  useUpdateEffect(() => {
    const isError =
      representativesToPreview.some(({ errorMessage }) => errorMessage) ||
      ownershipsToPreview.some(({ errorMessage }) => errorMessage);

    setStepperErrors((prev) => ({
      ...prev,
      [stepsIndex.affiliations]: isError,
    }));
  }, [ownershipsToPreview.length, representativesToPreview.length]);

  useUpdateEffect(() => {
    const isError = documents.some(
      ({ status }) => status === 'ERROR' || status === 'WARNING',
    );
    setStepperErrors((prev) => ({
      ...prev,
      [stepsIndex.documents]: isError,
    }));
  }, [documents]);

  return stepperErrors;
};

export default useLegalPersonStepsErrors;
