import { DialogContent } from '@mui/material';
import { DemoInterface } from 'api/faq/types';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';

const Frame = ({ demoLink }: { demoLink: DemoInterface['howdyGoLink'] }) => (
  <div
    style={{
      boxSizing: 'content-box',
      position: 'relative',
      maxWidth: '100%',
      height: '100%',
      overflow: 'hidden',
    }}
  >
    <script src="https://js.howdygo.com/v1.1x/index.js"></script>
    <iframe
      src={demoLink}
      allowFullScreen
      width="100%"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none',
      }}
    ></iframe>
  </div>
);

interface DemoPreviewModalProps {
  demo: DemoInterface;
  onClose: () => void;
}

const DemoPreviewModal = ({ demo, onClose }: DemoPreviewModalProps) => {
  return (
    <ModalWrapper open onClose={onClose} fullScreen>
      <ModalTitle title={demo.name} closeModal={onClose} />
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Frame demoLink={demo.howdyGoLink} />
      </DialogContent>
    </ModalWrapper>
  );
};

export default DemoPreviewModal;
