import { ThemedStyle } from 'types/themedStyle';

interface DropzoneProps {
  disabled: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

const getDropzoneBorderColor = ({
  isDragAccept,
  isDragReject,
}: Omit<DropzoneProps, 'disabled'>) => {
  if (isDragReject) {
    return 'error.main';
  }
  if (isDragAccept) {
    return 'success.main';
  }
  return 'grey.400';
};

export const getDropzoneStyles =
  ({ disabled, ...props }: DropzoneProps): ThemedStyle =>
  ({ palette }) => ({
    backgroundColor: palette.grey[50],
    border: '3px dashed',
    borderColor: getDropzoneBorderColor(props),
    borderRadius: 2,
    cursor: disabled ? 'not-allowed' : 'default',
  });

export const getUploadIconStyles =
  (disabled: boolean): ThemedStyle =>
  ({ palette }) => ({
    color: disabled ? palette.grey[300] : palette.secondary.main,
    boxSizing: 'content-box',
    borderRadius: '50%',
    pt: 1,
    pb: 2,
    width: '3rem',
    height: '3rem',
    svg: {
      fontSize: '3rem',
    },
  });

export const getBaseTypographyStyles: (disabled: boolean) => ThemedStyle =
  (disabled) =>
  ({ palette }) => ({
    fontSize: '.9rem',
    fontWeight: 'bold',
    my: 0.5,
    color: disabled ? palette.grey[400] : palette.text.primary,
  });

export const getLinkedTypographyStyles: (disabled: boolean) => ThemedStyle =
  (disabled) =>
  ({ palette }) => ({
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: disabled ? palette.grey[400] : palette.secondary.main,
    cursor: disabled ? 'not-allowed' : 'pointer',
  });
