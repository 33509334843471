import { Paper, Stack } from '@mui/material';
import { ProductInterface } from 'api/dossier/products/types';
import SubProductContent from './SubProductContent';
import SubProductsGroup from './SubProductsGroup';
import { groupSubProducts } from './utils';

export const ExtendedSubProducts = ({
  subProducts,
}: Required<Pick<ProductInterface, 'subProducts'>>) => (
  <Stack direction="row" gap={1} width="100%" flexWrap="wrap">
    {groupSubProducts(subProducts).map((group) => (
      <Paper key={group[0].name} variant="outlined" sx={{ flex: 1 }}>
        {group.length === 1 ? (
          <SubProductContent {...group[0]} />
        ) : (
          <SubProductsGroup subProducts={group} />
        )}
      </Paper>
    ))}
  </Stack>
);
