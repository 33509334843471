import { Circle } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';

interface Props {
  status: string;
  color: string | ((theme: Theme) => string);
  size?: 'small' | 'normal';
}

const StatusIndicator = ({ status, color, size = 'normal' }: Props) => (
  <Box display="flex" alignItems="center" gap={size === 'small' ? 0.5 : 1}>
    <Circle sx={{ fontSize: size === 'small' ? 10 : 16, color }} />
    <Typography fontWeight={600} variant={size === 'small' ? 'h6' : 'h4'}>
      {status}
    </Typography>
  </Box>
);

export default StatusIndicator;
