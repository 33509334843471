import yup from 'utils/yup';

const bicSchema = yup
  .string()
  .label('bic')
  .default(null)
  .nullable()
  .transform((value) => value || null)
  .uppercase()
  .min(8)
  .max(11)
  .when({
    is: (value: string | null) => value && value.length > 8 && value.length < 11,
    then: (schema) => schema.invalid(),
  })
  .when({
    is: (value: string | null) => value?.length === 8,
    then: (schema) => schema.matches(/^[A-Z]{6}[A-Z0-9]{2}/),
  })
  .when({
    is: (value: string | null) => value?.length === 11,
    then: (schema) => schema.matches(/^[A-Z]{6}[A-Z0-9]{5}/),
  });

export default bicSchema;
