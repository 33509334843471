import { MissingField } from 'api/missingData/types';
import findMissingField from './findMissingField';

const isMissingFieldRequired = (name: string, missingFields: MissingField[]) => {
  if (missingFields?.length) {
    return !!findMissingField(name, missingFields)?.required;
  }
  return false;
};

export default isMissingFieldRequired;
