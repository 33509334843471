import { estateSchema } from 'formData/estate';
import { legalPersonSchema } from 'formData/legalPerson';
import yup from 'utils/yup';
import { naturalPersonMissingDataSchema } from './naturalPerson';
import { rentalConditionsMissingDataSchema } from './rentalConditions';

export const productMissingDataSchema = yup.object({
  rentalConditions: rentalConditionsMissingDataSchema.optional().default(undefined),
  naturalPersons: yup
    .array()
    .of(naturalPersonMissingDataSchema.optional().default(undefined)),
  legalPersons: yup.array().of(legalPersonSchema.optional().default(undefined)),
  estate: estateSchema.optional().default(undefined),
});
