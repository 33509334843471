import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import { Documents } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { NaturalPersonViewInterface } from 'api/person/natural/types';

const NaturalPersonDocumentsTab = () => {
  const naturalPerson = useOutletContext<NaturalPersonViewInterface>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Documents
          entityId={naturalPerson.id}
          scope="naturalPerson"
          editable={hasPermission(PersonPermissions.Write)}
        />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonDocumentsTab;
