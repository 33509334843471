import { PersonPermissions } from 'consts/permissions';
import { DossierLocationState } from 'modules/DossierForm/types';
import routes from 'router/routes';
import { BoldCell } from 'templates/Table/Cells';
import { TableAction, TableColumn } from 'templates/Table/types';
import hasPermission from 'utils/hasPermission';
import { transformName } from 'utils/transformName/transformName';
import { apiUrl } from 'api/const';
import { FetchedPersonData } from 'api/person/types';
import { getPrivateRelation } from 'api/relations/requests';
import { PersonTypeCell } from './PersonTypeCell';

export const getLinkToPerson = (id: string, { type }: FetchedPersonData) =>
  routes.personTab(id, type).details;

export const personsColumns: TableColumn<FetchedPersonData, 'persons'>[] = [
  {
    field: 'type',
    flex: 1,
    sortable: true,
    renderCell: PersonTypeCell,
  },
  {
    field: 'name',
    flex: 5,
    filterable: true,
    sortable: true,
    renderCell: BoldCell,
  },
  {
    field: 'address',
    flex: 4,
    filterable: true,
    sortable: true,
  },
];

export const getPersonsActions = (
  person: FetchedPersonData,
): TableAction<'persons'>[] => [
  {
    name: 'delete',
    hidden: !hasPermission(PersonPermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      person.type === 'NaturalPerson'
        ? setUpAndOpenConfirmationModal({
            url: apiUrl.naturalPerson(person.id),
            translationKey: 'naturalPersons',
            onSuccess: updateRows,
            errorMessage: { status: 404, text: 'persons.404' },
            translationValues: {
              personName: transformName(person),
            },
          })
        : setUpAndOpenConfirmationModal({
            url: apiUrl.legalPerson(person.id),
            translationKey: 'legalPersons',
            onSuccess: updateRows,
            errorMessage: { status: 404, text: 'persons.404' },
            translationValues: { personName: person.name },
          });
    },
  },
  {
    name: 'createDossierAsLandlord',
    onClick: async ({ navigate }) => {
      const landlordIds = [person.id];
      if (person.hasPrivateRelation && person.type === 'NaturalPerson') {
        const { ok, response } = await getPrivateRelation(person.id);
        if (ok && response) {
          landlordIds.push(response.relative.id);
        }
      }
      const state: DossierLocationState = { landlordIds };
      navigate(routes.newDossier, { state });
    },
  },
  {
    name: 'createDossierAsTenant',
    onClick: async ({ navigate }) => {
      const tenantIds = [person.id];
      if (person.hasPrivateRelation && person.type === 'NaturalPerson') {
        const { ok, response } = await getPrivateRelation(person.id);
        if (ok && response) {
          tenantIds.push(response.relative.id);
        }
      }
      const state: DossierLocationState = { tenantIds };
      navigate(routes.newDossier, { state });
    },
  },
];
