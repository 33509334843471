import { RefCallback } from 'react';
import Input from 'react-text-mask';
import BaseInput from '../BaseInput';
import { MaskedInputProps } from './types';

const MaskedInput = ({
  mask,
  pipe,
  onChange: handleChange,
  onBlur,
  inputRef,
  ...inputProps
}: MaskedInputProps) => (
  <Input
    mask={mask}
    guide={false}
    onBlur={onBlur}
    pipe={pipe}
    render={(ref, { onChange, ...props }) => (
      <BaseInput
        onChange={(e) => {
          onChange(e);
          if (handleChange) {
            handleChange(e.target.value);
          }
        }}
        inputRef={(instance) => {
          if (inputRef) {
            (inputRef as RefCallback<HTMLInputElement>)(instance);
          }
          ref(instance);
        }}
        {...props}
        {...inputProps}
      />
    )}
  />
);

export default MaskedInput;
