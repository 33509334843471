import Grid from '@mui/material/Grid';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';
import { FormContainer } from 'templates/FormTemplates';

const LegalPersonDocumentsStep = () => (
  <FormContainer>
    <Grid item xs={12}>
      <UploadDocumentsWithContext scope="legalPerson" />
    </Grid>
  </FormContainer>
);

export default LegalPersonDocumentsStep;
