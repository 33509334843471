import { CountryCode } from 'libphonenumber-js';
import yup from 'utils/yup';
import { isTinValid } from './utils';

const taxResidencesSchema = yup
  .array()
  .of(
    yup.object({
      taxResidenceCountry: yup.string<CountryCode>().default('NL'),
      taxIdentificationNumber: yup
        .string()
        .default('')
        .customWhen({
          is: ({ grandParent: person, parent: tin }) =>
            person.taxResidenceBelgium === 'NotBelgium' &&
            tin.taxIdentificationNumber &&
            !isTinValid(tin.taxResidenceCountry, tin.taxIdentificationNumber),
          then: (schema) => schema.invalid('tin.invalid'),
        }),
    }),
  )
  .default([])
  .transform((value) =>
    value.filter(
      (tax: { taxIdentificationNumber?: string }) => tax?.taxIdentificationNumber,
    ),
  );

export default taxResidencesSchema;
