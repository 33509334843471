import { Stack, Typography } from '@mui/material';
import { WarningIcon } from 'icons';
import { InputHelperTextProps } from './types';

const InputHelperText = ({
  helperText,
  warning = false,
  error = false,
}: InputHelperTextProps) => (
  <Stack
    component="span"
    direction="row"
    alignItems="center"
    columnGap={0.5}
    sx={{ ml: 0, mr: 0, fontSize: 14 }}
  >
    {!error && warning && <WarningIcon fontSize="inherit" color="warning" />}
    <Typography
      component="div"
      width="100%"
      color={error ? 'error' : 'default'}
      sx={{ fontSize: 11 }}
    >
      {helperText}
    </Typography>
  </Stack>
);

export default InputHelperText;
