import { isAddressIncomplete } from 'formData/common/address/utils';
import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getNaturalPersonViewData } from 'api/person/natural/requests';
import { NaturalPersonViewInterface } from 'api/person/natural/types';

export const getNaturalPersonConfig = (
  naturalPersonId: string,
): LoaderConfig<NaturalPersonViewInterface> => ({
  id: naturalPersonId,
  scope: 'naturalPerson',
  fetch: getNaturalPersonViewData,
  shouldRedirect: (pathname) => pathname === routes.naturalPerson(naturalPersonId),
  redirectUrl: routes.naturalPersonTab(naturalPersonId).details,
});

export const isNaturalPersonMissingRequiredData = ({
  address,
  language,
  name,
  firstName,
  email,
  sex,
}: NaturalPersonViewInterface['data']) => {
  if (
    !firstName ||
    !name ||
    !language ||
    !sex ||
    !email ||
    isAddressIncomplete(address)
  ) {
    return true;
  }
  return false;
};
