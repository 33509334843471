import { CountryCode } from 'libphonenumber-js/types';
import getLng from 'utils/getLng';
import { Country } from 'api/config/types';
import { SelectOption } from '../Select/types';

export const mapCountriesCodes = ({
  alpha2Code,
  name,
}: Country): SelectOption<CountryCode> => ({
  code: alpha2Code,
  label: name[getLng()],
  value: alpha2Code,
});
