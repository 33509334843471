import { Stack } from '@mui/material';
import BusinessMigrationQueueList from './BusinessMigrationQueueList';
import DossierMigrationQueueList from './DossierMigrationQueueList';

const MigrationList = () => (
  <Stack gap={2}>
    <DossierMigrationQueueList />
    <BusinessMigrationQueueList />
  </Stack>
);

export default MigrationList;
