import { getFormAddress } from 'formData/common/address/utils';
import { estateDetailsSchema, estateSchema } from 'formData/estate';
import { EstateData, EstateDetailsForm, EstateFormData } from 'api/estate/types';

export const getEstateDetailsDefaultValues = (
  data: EstateDetailsForm | null,
): EstateDetailsForm => ({
  ...estateDetailsSchema.getDefault(),
  ...data,
  electricity: data?.hasElectricity
    ? data.electricity
    : estateDetailsSchema.getDefault().electricity,
  gas: data?.hasGas ? data.gas : estateDetailsSchema.getDefault().gas,
});

export const getEstateDefaultValues = ({
  referenceNumber: _referenceNumber,
  address,
  ...data
}: EstateData): EstateFormData => ({
  ...estateSchema.getDefault(),
  ...data,
  address: getFormAddress(address),
});
