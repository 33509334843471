import { UrlParams } from 'api/types';
import { getSearchParamsUrl } from 'api/utils';

export default {
  dossiersWithParams: (params: UrlParams) => getSearchParamsUrl('dossiers', params),
  rentalDossiersChecklist: '/rentalDossiers/checklist',
  rentalDossiers: '/rentalDossiers',
  rentalDossier: (dossierId: string) => `/rentalDossiers/${dossierId}`,
  rentalDossierView: (dossierId: string) => `/rentalDossiers/${dossierId}/dossier-view`,
  rentalDossierStatus: (dossierId: string) => `/rentalDossiers/${dossierId}/status`,
  rentalConditions: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/rentalConditions`,
  residents: (dossierId: string) => `/rentalDossiers/${dossierId}/residents`,
  dossierTodoList: (dossierId: string) => `/rentalDossiers/${dossierId}/toDoList`,

  dossierDocuments: (dossierId: string) => `/rentalDossiers/${dossierId}/documents`,
  dossierDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}`,
  rentalDossierDocumentFile: (dossierId: string, documentId: number, fileId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}/files/${fileId}`,
  attachDossierDocument: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/attachments`,
  attachDossierTypeDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}/attach`,
  removeDossierDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/attachments/${documentId}`,

  customTodoItems: (dossierId: string) => `/rentalDossiers/${dossierId}/customToDoItems`,
  updateCustomTodoList: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/customToDoItems/order`,
  customTodoItem: (dossierId: string, todoItemId: string) =>
    `rentalDossiers/${dossierId}/customToDoItems/${todoItemId}`,

  dossierHistoryWithParams: (dossierId: string, params: UrlParams) =>
    getSearchParamsUrl(`rentalDossiers/${dossierId}/history`, { ...params }),
};
