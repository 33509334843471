import getLng from 'utils/getLng';
import api from 'api';
import { HistoryResponseInterface } from 'api/common/types';
import { apiUrl } from 'api/const';
import { UrlParams } from 'api/types';
import { FetchedPersonData, FetchedPersonList } from './types';

export const getPersons = (params: UrlParams) =>
  api.get<FetchedPersonList>(apiUrl.personsWithParams({ ...params, lng: getLng() }), {
    transformResponseOnSuccess: ({ meta, data }) => ({
      meta,
      data: data.map((person: FetchedPersonData) => ({
        ...person,
        address: person.address ?? '',
      })),
    }),
  });

export const getPersonHistory = (personId: string, params: UrlParams) =>
  api.get<HistoryResponseInterface>(
    apiUrl.personHistoryWithParams(personId, { ...params, lng: getLng() }),
  );
