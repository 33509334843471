import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import useEstateDetails from 'hooks/useEstateDetails';
import { DossierEstateInterface, RentalConditions } from 'api/dossier/types';
import { ControlledCheckbox } from 'components/inputs';
import { OrderRentalDepositForm } from './types';
import { getFireInsuranceMessage } from './utils';

interface Props {
  methods: UseFormReturn<OrderRentalDepositForm>;
  rentalConditions: RentalConditions | null;
  estate: DossierEstateInterface;
}

const FireInsuranceController = ({ methods, rentalConditions, estate }: Props) => {
  const { t } = useTranslation(['common', 'enums']);

  const { estateDetails } = useEstateDetails(estate.id);

  const fireInsuranceMessage = useMemo(
    () =>
      rentalConditions &&
      getFireInsuranceMessage(rentalConditions, estate, estateDetails?.habitableSurface),
    [rentalConditions, estateDetails?.habitableSurface, estate],
  );

  useEffect(() => {
    methods.setValue('isFireInsurance', !fireInsuranceMessage);
  }, [fireInsuranceMessage]);
  return (
    <>
      <ControlledCheckbox
        name="isFireInsurance"
        label={t('isFireInsurance')}
        control={methods.control}
        disabled
      />
      {fireInsuranceMessage && rentalConditions && (
        <Typography variant="body2" pl={3.8} mt={-1} color="error">
          {t(`common:helperText.${fireInsuranceMessage}`, {
            type: t(`enums:rentType.${rentalConditions.rentType}`),
          })}
        </Typography>
      )}
    </>
  );
};

export default FireInsuranceController;
