import { ComponentType } from 'react';
import { styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { CustomDayPickerProps } from './types';

export const CalendarDayPicker = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomDayPickerProps>(({ theme, isSelected, isHovered }) => ({
  '&:focus': {
    backgroundColor: 'transparent',
  },
  ...(isSelected && {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
})) as ComponentType<CustomDayPickerProps>;

export default CalendarDayPicker;
