import { Box } from '@mui/material';
import { ProductProcessInterface } from 'api/dossier/products/types';
import ProductStage from './ProductStage';
import ProductStageGroup from './ProductStageGroup';
import { horizontalLineStyles } from './styles';
import { groupStages } from './utils';

export const ProductFlowTree = ({ stages }: Pick<ProductProcessInterface, 'stages'>) =>
  stages.length ? (
    <Box position="relative">
      <Box display="flex" justifyContent="space-evenly" gap={2} mx={1}>
        {groupStages(stages).map((stage) =>
          stage.length === 1 ? (
            <ProductStage key={stage[0].index} {...stage[0]} />
          ) : (
            <ProductStageGroup key={stage[0].index} stages={stage} />
          ),
        )}
      </Box>
      <Box sx={horizontalLineStyles} />
    </Box>
  ) : null;
