import { useOutletContext } from 'react-router-dom';
import AssignedEstatesGrid from 'modules/AssignedEstatesGrid';
import { LegalPersonViewInterface } from 'api/person/legal/types';

const LegalPersonEstatesTab = () => {
  const legalPerson = useOutletContext<LegalPersonViewInterface>();

  return <AssignedEstatesGrid estates={legalPerson.estates} />;
};

export default LegalPersonEstatesTab;
