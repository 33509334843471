import { useTranslation } from 'react-i18next';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';

const EstateSyndicRow = ({
  syndic,
  hasSyndic,
}: Pick<EstateViewInterface['data'], 'syndic' | 'hasSyndic'>) => {
  const { t } = useTranslation(['common', 'formFields']);

  if (syndic) {
    return <CardRow title={t('formFields:syndic')} value={syndic} />;
  }

  if (hasSyndic) {
    return <CardRow title={t('formFields:syndic')} value={t('yes')} />;
  }

  return null;
};

export default EstateSyndicRow;
