import { LoaderConfig } from 'hooks/useLoader/types';
import { getProduct } from 'api/dossier/products/requests';
import { ProductInterface, ProductStageInterface } from 'api/dossier/products/types';

export const getProductConfig = (
  dossierId: string,
  productId: string,
): LoaderConfig<ProductInterface> => ({
  scope: 'product',
  id: productId,
  fetch: (productId) => getProduct(dossierId, productId),
  withContext: false,
});

export const filterStages = (stages: ProductStageInterface[]) =>
  stages.filter(({ name, requiredDocuments }) =>
    name === 'UploadDocuments' ? !!requiredDocuments?.length : true,
  );
