import { useContext, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import PRODUCTS from 'consts/products';
import { ConfigContext } from 'contexts/config/ConfigContext';
import HistoryOfChanges from 'templates/HistoryOfChanges';
import { DOSSIER_HISTORY_ACTIVITIES } from 'templates/HistoryOfChanges/static-data';
import { getDossierHistory } from 'api/dossier/requests';
import { DossierViewInterface } from 'api/dossier/types';

const DossierHistoryTab = () => {
  const { id } = useOutletContext<DossierViewInterface>();
  const { rentalDossierChecklist } = useContext(ConfigContext);

  const products = useMemo(
    () =>
      PRODUCTS.filter((activity) =>
        rentalDossierChecklist.find(({ name }) => name === activity),
      ),
    [rentalDossierChecklist],
  );

  return (
    <HistoryOfChanges
      getHistory={getDossierHistory}
      entityId={id}
      activities={[...DOSSIER_HISTORY_ACTIVITIES, ...products]}
    />
  );
};

export default DossierHistoryTab;
