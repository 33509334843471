import { numberOfMonthlyRentValues } from 'formData/dossier/rentalConditions/static-data';
import { NumberOfMonthlyRent } from 'api/dossier/types';

export const calculateDepositAmount = (
  numberOfMonthlyRent: NonNullable<NumberOfMonthlyRent>,
  rentalPriceValue: number,
) => {
  if (rentalPriceValue > 0) {
    const value = numberOfMonthlyRentValues[numberOfMonthlyRent] * rentalPriceValue;
    return Math.round(value * 100) / 100;
  }
  return 0;
};
