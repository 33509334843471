import { Box, styled } from '@mui/material';
import { CalendarDaySelectorBoxProps } from './types';

const CalendarDaySelectorBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' &&
    prop !== 'isHovered' &&
    prop !== 'isHoverBefore' &&
    prop !== 'isHoverAfter',
})<CalendarDaySelectorBoxProps>(
  ({ theme, isSelected, isHovered, isHoverBefore, isHoverAfter }) => ({
    borderWidth: 2,
    borderStyle: 'dashed',
    ...(!isSelected && {
      boxSizing: 'border-box',
      width: 'fit-content',
      height: 'fit-content',
      borderRadius:
        isHovered && isHoverBefore
          ? '50% 0 0 50%'
          : isHovered && isHoverAfter
          ? '0 50% 50% 0'
          : '0',

      ...((isHoverBefore || isHoverAfter) && {
        borderTopColor: theme.palette.secondary.main,
        borderBottomColor: theme.palette.secondary.main,
      }),
      borderTopColor:
        isHoverBefore || isHoverAfter ? theme.palette.secondary.main : 'transparent',
      borderBottomColor:
        isHoverBefore || isHoverAfter ? theme.palette.secondary.main : 'transparent',
      borderLeftColor:
        isHoverBefore && isHovered ? theme.palette.secondary.main : 'transparent',
      borderRightColor:
        isHoverAfter && isHovered ? theme.palette.secondary.main : 'transparent',
    }),
    ...(isSelected && {
      borderColor: 'transparent',
    }),
  }),
);

export default CalendarDaySelectorBox;
