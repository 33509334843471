export default {
  rentalDeposit: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/rentalDeposit`,
  rentalDepositAbolishmentData: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/rentalDeposit/abolishmentData`,

  rentalContract: (dossierId: string, productId: string) =>
    `rentalDossiers/${dossierId}/products/${productId}/rentalContract`,

  productMissingFields: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/missingFields`,

  // PRODUCTS
  products: (dossierId: string) => `/rentalDossiers/${dossierId}/products`,
  product: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}`,
  productsOrder: (dossierId: string) => `/rentalDossiers/${dossierId}/productsOrder`,
};
