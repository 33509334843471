import { Box } from '@mui/material';
import { Tabs } from 'components/tabs';
import { NotificationHeaderProps } from './types';

const NotificationsHeader = ({ activeTab, setActiveTab }: NotificationHeaderProps) => (
  <Box sx={({ mixins }) => mixins.tabsWrapper}>
    <Tabs
      translationKey="notifications"
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data-testid="tabs"
    />
  </Box>
);

export default NotificationsHeader;
