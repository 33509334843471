import { useTranslation } from 'react-i18next';
import { addressToString } from 'utils/addressToString/addressToString';
import { DossierViewInterface } from 'api/dossier/types';
import ProductActionInfoRow from './ProductActionInfoRow';

const ProductActionEstateInfo = ({ estate }: Pick<DossierViewInterface, 'estate'>) => {
  const { t } = useTranslation('modals');

  return (
    <ProductActionInfoRow
      title={t('data.estateAddress')}
      value={addressToString(estate.address)}
    />
  );
};

export default ProductActionEstateInfo;
