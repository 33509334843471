import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import InputWrapper from '../InputWrapper';
import InputHelperText from './InputHelperText';
import { CHARACTERS_LIMIT } from './static-data';
import { getBaseInputStyles } from './styles';
import { BaseInputProps } from './types';

const BaseInput = ({
  name,
  type = 'text',
  size = 'small',
  fullWidth = true,
  helperText,
  variant = 'outlined',
  startAdornment,
  endAdornment,
  InputLabelProps,
  InputProps,
  inputProps,
  error = false,
  warning = false,
  fullWidthWarning,
  disabled,
  loading,
  ...props
}: BaseInputProps) => (
  <TextField
    id={name}
    name={name}
    size={size}
    type={type}
    variant={variant}
    fullWidth={fullWidth}
    helperText={
      helperText ? (
        <InputHelperText helperText={helperText} warning={warning} error={error} />
      ) : undefined
    }
    disabled={disabled || loading}
    error={error}
    sx={getBaseInputStyles(error, warning, fullWidthWarning)}
    // @ts-ignore it exists
    FormHelperTextProps={{ component: 'div' }}
    inputProps={{
      'data-testid': name,
      maxLength:
        'multiline' in props && props.multiline
          ? CHARACTERS_LIMIT.textArea
          : CHARACTERS_LIMIT.textInput,
      ...inputProps,
    }}
    InputLabelProps={{
      shrink: true,
      ...InputLabelProps,
    }}
    InputProps={{
      notched: true,
      startAdornment: startAdornment && (
        <InputAdornment position="start" data-testid={`${name}_start-adornment`}>
          {startAdornment}
        </InputAdornment>
      ),
      endAdornment: (endAdornment || loading) && (
        <InputAdornment position="end" data-testid={`${name}_end-adornment`}>
          {loading ? <CircularProgress size={20} /> : endAdornment}
        </InputAdornment>
      ),
      ...InputProps,
    }}
    {...props}
  />
);

const BaseInputWrapper = (props: BaseInputProps) => InputWrapper(BaseInput, props);

export default BaseInputWrapper;
