import { DocumentToUpload } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import { firstCharLowerCase } from 'utils/string';
import { ProductDocument } from 'api/dossier/products/types';
import getDocumentPersonLabel from './getPersonLabel';

const mapMissingDocuments = (documents: ProductDocument[]): DocumentToUpload[] =>
  documents.map(({ name, person, relatedEntityId, relatedEntityType }) =>
    getDocumentToUpload({
      entityId: relatedEntityId,
      scope: firstCharLowerCase(relatedEntityType),
      documentType: name,
      label: person ? getDocumentPersonLabel(person) : relatedEntityType,
    }),
  );

export default mapMissingDocuments;
