import yup from 'utils/yup';
import estateDetailsSchema from '../details/schema';
import estateSchema from '../schema';

const estateStepperSchema = yup.object({
  estate: estateSchema,
  estateDetails: estateDetailsSchema.optional(),
});

export default estateStepperSchema;
