import { Navigate, useParams } from 'react-router-dom';
import useLoader from 'hooks/useLoader';
import DossierForm from 'modules/DossierForm';
import routes from 'router/routes';
import FullPageCard from 'templates/FullPageCard';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { getRentalDossierViewData } from 'api/dossier/requests';
import { getAssignedParameters, getDossierDefaultValues } from './utils';

const DossierEdition = () => {
  const { dossierId } = useParams();
  if (!dossierId) {
    return <Navigate to={routes.dossiers} />;
  }

  const { Loader, data } = useLoader({
    fetch: getRentalDossierViewData,
    shouldRedirect: (_, data) =>
      data ? !shouldDossierFeatureBeActive(data.status) : false,
    redirectUrl: routes.rentalDossierTab(dossierId).details,
    id: dossierId,
    scope: 'dossier',
  });

  return (
    <Loader>
      {data ? (
        <FullPageCard>
          <DossierForm
            dossierId={dossierId}
            stepsToHide={['checklist']}
            assignedParameters={getAssignedParameters(data)}
            defaultValues={getDossierDefaultValues(data)}
            editableFields={{
              estate: false,
              parties: data.status === 'Draft',
              rentalConditions: data.status === 'Draft',
            }}
          />
        </FullPageCard>
      ) : null}
    </Loader>
  );
};

export default DossierEdition;
