import { CircularProgress, SvgIconProps } from '@mui/material';

interface Props {
  loading: boolean;
  Icon: (props: SvgIconProps) => JSX.Element;
}

const IconWithLoader = ({ Icon, loading }: Props) =>
  loading ? <CircularProgress size={20} /> : <Icon fontSize="small" />;

export default IconWithLoader;
