import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import TextDivider from 'atoms/TextDivider';
import useSearchInput from 'hooks/useSearchInput';
import { RemoveIcon } from 'icons';
import { renderPersonIcon } from 'utils/ui';
import { DossierPartyInterface } from 'api/dossier/types';
import { ControlledSelect, SearchInput } from 'components/inputs';
import { HandleSearchChange } from 'components/inputs/SearchInput/types';
import { getErrorMessage } from 'components/inputs/utils';
import { ContactField, defaultFieldsConfig, relationOptions } from '../static-data';
import { ProductPartiesProps } from '../types';
import { fetchPrivateRelation, fetchRepresentatives } from '../utils';
import RepresentativeInput from './RepresentativeInput';
import { PartyOption, ProductPartiesForm, ProductParty } from './types';
import { fetchPartyOptions, formatPersonOptions, getRelation } from './utils';

const SELECTOR_WIDTH = 260;

export const ProductPartiesV2 = ({ persons, partyType, config }: ProductPartiesProps) => {
  const [isAddingPerson, setIsAddingPerson] = useState(false);

  const {
    context,
    maxFieldsCount,
    shouldDisplaySecondaryField,
    showLegalPersonInSecondaryContact,
    showPartner,
    showRelationDropdown,
  } = useMemo(() => ({ ...defaultFieldsConfig, ...config }), [config]);

  const { t } = useTranslation('modals', {
    keyPrefix: 'productActionModal.contactComponent',
  });

  const {
    control,
    formState: { errors },
  } = useFormContext<ProductPartiesForm>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: partyType,
    keyName: 'fieldId',
  });

  const watchedFields = useWatch({ control, name: partyType });
  const watchedSecondPartyFields = useWatch({
    control,
    name: partyType === 'landlords' ? 'tenants' : 'landlords',
  });

  const hideLegalPersonOptions = useMemo(
    () => !showLegalPersonInSecondaryContact && watchedFields.length === 1,
    [watchedFields.length],
  );

  const partiesAssignedToDossier = useMemo(
    () => formatPersonOptions(persons, t('partiesInsideDossier'), hideLegalPersonOptions),
    [persons, hideLegalPersonOptions],
  );

  const optionsToFilter = useMemo(
    () => [...watchedFields, ...watchedSecondPartyFields].map(({ id }) => id),
    [watchedFields, watchedSecondPartyFields],
  );

  const { updateParams, searchInputProps } = useSearchInput({
    contextOptions: partiesAssignedToDossier,
    fetchOptions: (params) => fetchPartyOptions(params, t('partiesOutsideDossier')),
    optionsToFilter,
    initParams: {
      type:
        (persons.length === 1 || fields.length === 1) &&
        !showLegalPersonInSecondaryContact
          ? 'NaturalPerson'
          : null,
    },
  });

  const fetchOptions = (action: 'append' | 'remove') => {
    const displayOnlyNaturalPersons =
      !showLegalPersonInSecondaryContact &&
      ((action === 'append' && !fields.length) ||
        (action === 'remove' && fields.length === 2));

    updateParams({ type: displayOnlyNaturalPersons ? 'NaturalPerson' : null });
  };

  const setPartiesValues = async (person: DossierPartyInterface, index: number) => {
    let representatives: ProductParty['representatives'];
    let partner: ProductParty['partner'];
    let relation: ProductParty['relation'];

    append(
      { name: t('addingPerson'), id: '', type: person.type },
      { shouldFocus: false },
    );

    if (person.type === 'LegalPerson') {
      representatives = await fetchRepresentatives(person.id);
    } else {
      partner = await fetchPrivateRelation(person.id);
    }

    if (showRelationDropdown && showPartner === 'asSecondaryContact') {
      if (index === ContactField.Primary) {
        relation = partner?.relation ?? null;
      } else if (index === ContactField.Secondary) {
        const primaryContact = fields[ContactField.Primary];
        relation = getRelation(primaryContact, person);
      }
    }

    const field: ProductParty = {
      ...person,
      hasPrivateRelation: !!partner,
      representatives,
      representativeId: representatives?.length === 1 ? representatives[0].id : null,
      partner,
      partnerId: partner?.id,
      relation,
    };

    update(index, field);

    if (
      showPartner === 'asSecondaryContact' &&
      index === ContactField.Primary &&
      maxFieldsCount === 2 &&
      partner &&
      shouldDisplaySecondaryField(field)
    ) {
      append({ ...partner, hasPrivateRelation: true }, { shouldFocus: false });
    }
  };

  const setInitValues = useCallback(async () => {
    if (persons.length === 1 && !fields.length) {
      setIsAddingPerson(true);
      await setPartiesValues(persons[0], ContactField.Primary);
      setIsAddingPerson(false);
    }
  }, []);

  useEffect(() => {
    setInitValues();
  }, []);

  const onChange: HandleSearchChange = async (option) => {
    if (option) {
      setIsAddingPerson(true);
      const index = fields.length;
      const partyOption = option as PartyOption;

      if (
        fields.length + 1 < maxFieldsCount &&
        !(index === ContactField.Primary && !shouldDisplaySecondaryField(partyOption))
      ) {
        fetchOptions('append');
      }

      await setPartiesValues(partyOption, index);
      setIsAddingPerson(false);
    }
  };

  const onRemove = (index: number) => () => {
    fetchOptions('remove');
    remove(index);
  };

  const helperTextWhenDisabled = useMemo(() => {
    if (watchedFields.length === maxFieldsCount) {
      return t('partiesLimit', { partyType });
    } else if (
      watchedFields.length === 1 &&
      !shouldDisplaySecondaryField(watchedFields[0])
    ) {
      return t(`onlyOnePersonCanBeAdded_${partyType}`);
    }
  }, [watchedFields.length]);

  const onRelationChange = (index: number) => (value: string) => {
    update(index, {
      ...watchedFields[index],
      relation: value as ProductParty['relation'],
    });
  };

  return (
    <Grid item container spacing={2}>
      <TextDivider label={t('party', { partyType })} />
      <Grid item xs={12}>
        <Stack gap={2}>
          {fields.map(
            (
              { fieldId, name, type, representatives = [], relation, hasPrivateRelation },
              index,
            ) => {
              const primaryContact = fields.at(ContactField.Primary);
              const isRelationDisabled =
                hasPrivateRelation ||
                !!primaryContact?.hasPrivateRelation ||
                primaryContact?.type === 'LegalPerson';

              return (
                <Box display="flex" alignItems="center" gap={1.5} key={fieldId}>
                  {renderPersonIcon(type)}
                  <Stack>
                    <Typography fontWeight={600}>{name}</Typography>
                    <Typography fontWeight={600} variant="caption" color="grey.500">
                      {t(`${partyType}.label`, {
                        context,
                        count: index + 1,
                      })}
                    </Typography>
                  </Stack>
                  <Box sx={{ ml: 'auto' }} display="flex" alignItems="center" gap={1.5}>
                    {type === 'LegalPerson' && (
                      <RepresentativeInput
                        index={index}
                        partyType={partyType}
                        disabled={isAddingPerson}
                        assignedRepresentatives={representatives}
                        optionsToFilter={watchedFields.map(({ id }) => id)}
                      />
                    )}
                    {showRelationDropdown &&
                      index === ContactField.Secondary &&
                      showPartner === 'asSecondaryContact' &&
                      type === 'NaturalPerson' && (
                        <ControlledSelect
                          staticWidth={SELECTOR_WIDTH}
                          control={control}
                          name={`${partyType}.${index}.relation`}
                          label={t('relation')}
                          error={errors?.[partyType]?.[index]?.relation}
                          disabled={isRelationDisabled || isAddingPerson}
                          onChange={onRelationChange(index)}
                          options={relationOptions}
                          helperText={
                            !isAddingPerson && !relation && isRelationDisabled
                              ? t('relationExists', { contactType: partyType })
                              : undefined
                          }
                          warning={
                            !isAddingPerson && !isRelationDisabled && relation !== 'Other'
                              ? {
                                  message: { key: 'contactRelation.addingNewRelation' },
                                }
                              : undefined
                          }
                        />
                      )}
                    <IconButton onClick={onRemove(index)} disabled={isAddingPerson}>
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                </Box>
              );
            },
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <SearchInput
          placeholder={t('chooseParty', { partyType })}
          fillInputWithOption={false}
          onChange={onChange}
          disabled={!!helperTextWhenDisabled}
          helperText={getErrorMessage(errors?.[partyType]) || helperTextWhenDisabled}
          error={errors?.[partyType]?.type === 'min'}
          {...searchInputProps}
        />
      </Grid>
    </Grid>
  );
};
