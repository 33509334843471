import { useEffect, useMemo, useRef, useState } from 'react';
import { getObjectEntries } from 'utils/object';
import { ActiveNotificationTab, NotificationsWithCount, NotificationTabs } from './types';

const useNotificationsTab = (
  notifications: NotificationsWithCount,
  isLoading: boolean,
) => {
  const [activeTab, setActiveTab] = useState(ActiveNotificationTab.actionRequired);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (!isLoading && isFirstLoad.current) {
      if (
        !Object.keys(notifications?.actionRequired).length &&
        Object.keys(notifications?.noAction).length
      ) {
        setActiveTab(ActiveNotificationTab.noAction);
      } else {
        setActiveTab(ActiveNotificationTab.actionRequired);
      }
      isFirstLoad.current = false;
    }
  }, [isLoading]);

  const notificationsByType = useMemo(
    () =>
      getObjectEntries(
        notifications[ActiveNotificationTab[activeTab] as NotificationTabs],
      )?.map(([key, value]) => ({
        productName: key,
        ...value,
      })),
    [notifications, activeTab],
  );

  return {
    notificationsByType,
    activeTab,
    setActiveTab,
    isFetchingInitially: isFirstLoad.current && isLoading,
  };
};

export default useNotificationsTab;
