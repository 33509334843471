import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getRentalDossierViewData } from 'api/dossier/requests';
import { DossierViewInterface } from 'api/dossier/types';

export const getDossierConfig = (
  dossierId: string,
): LoaderConfig<DossierViewInterface> => ({
  id: dossierId,
  scope: 'dossier',
  fetch: getRentalDossierViewData,
  shouldRedirect: (pathname) => pathname === routes.rentalDossier(dossierId),
  redirectUrl: routes.rentalDossierTab(dossierId).products,
});
