import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingElement from 'atoms/LoadingElement';
import { ConfigContext } from 'contexts/config/ConfigContext';
import DossierForm from 'modules/DossierForm';
import { DossierAssignedParameters } from 'modules/DossierForm/types';
import FullPageCard from 'templates/FullPageCard';
import { fetchDossierAssignedParameters, formatRentalDossierChecklist } from './utils';

const AddNewDossier = () => {
  const [loadingParameters, setLoadingParameters] = useState(true);
  const [assignedParameters, setAssignedParameters] = useState<DossierAssignedParameters>(
    {
      assignedEstates: [],
      assignedLandlords: [],
      assignedTenants: [],
    },
  );

  const { state } = useLocation();
  const { rentalDossierChecklist, loadingChecklist } = useContext(ConfigContext);

  const fetchAssignedParameters = useCallback(async () => {
    const params = await fetchDossierAssignedParameters(state);
    setAssignedParameters(params);
    setLoadingParameters(false);
  }, []);

  useEffect(() => {
    fetchAssignedParameters();
  }, [fetchAssignedParameters]);

  return loadingParameters || loadingChecklist ? (
    <LoadingElement />
  ) : (
    <FullPageCard>
      <DossierForm
        defaultValues={{
          checklistItems: rentalDossierChecklist.map(formatRentalDossierChecklist),
          estateId:
            assignedParameters.assignedEstates.length === 1 && state?.estateId
              ? assignedParameters.assignedEstates.map(({ value }) => value)[0]
              : null,
          landlords: assignedParameters.assignedLandlords.map(({ data }) => data),
          tenants: assignedParameters.assignedTenants.map(({ data }) => data),
        }}
        assignedParameters={assignedParameters}
      />
    </FullPageCard>
  );
};

export default AddNewDossier;
