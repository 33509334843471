import { ThemedStyle } from 'types/themedStyle';

export const tableContainerStyles: ThemedStyle = {
  borderRadius: 2,
  border: ({ shape }) => shape.border,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

export const tableHeaderStyles: ThemedStyle = ({ palette }) => ({
  color: palette.grey[500],
  fontWeight: 500,
  fontSize: 14,
});

export const tableToolbarStyles: ThemedStyle = {
  minHeight: '52px',
  backgroundColor: ({ palette }) => palette.primary.main,
  paddingBottom: 0,
  borderRadius: 2,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};
