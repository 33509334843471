import { ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT } from 'Layout/styles';
import { ThemedStyle } from 'types/themedStyle';

export const getDrawerStyles =
  (isSidebarExpanded: boolean): ThemedStyle =>
  ({ mixins, zIndex }) => ({
    zIndex: zIndex.drawer,
    position: { xs: 'absolute', [ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT]: 'relative' },
    left: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(isSidebarExpanded
      ? {
          ...mixins.openedDrawer,
          '& .MuiDrawer-paper': mixins.openedDrawer,
        }
      : {
          ...mixins.closedDrawer,
          '& .MuiDrawer-paper': mixins.closedDrawer,
        }),
  });

export const getSidebarHeaderStyles =
  (isSidebarExpanded: boolean): ThemedStyle =>
  (theme) => ({
    ...theme.mixins.toolbar,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(isSidebarExpanded ? 3 : 2),
    paddingRight: theme.spacing(isSidebarExpanded ? 3 : 2),
    width: isSidebarExpanded
      ? theme.mixins.openedDrawer.width
      : theme.mixins.closedDrawer.width,
    backgroundColor:
      window._env_.REACT_APP_ENVIRONMENT === 'TEST'
        ? theme.palette.secondary.light
        : 'unset',
  });

export const toggleSidebarButtonStyles: ThemedStyle = {
  mt: 'auto',
  ml: 'auto',
  pt: 0.75,
  mr: 0.75,
  mb: 0.75,
};

export const getAgentContactBoxStyles = (isSidebarExpanded: boolean): ThemedStyle => ({
  minHeight: '56px',
  maxHeight: isSidebarExpanded ? 'unset' : '56px',
  overflow: isSidebarExpanded ? 'visible' : 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  px: 2,
  py: 1,
  mt: 'auto',
  bgcolor: ({ palette }) => palette.primary[100],
  cursor: !isSidebarExpanded ? 'pointer' : 'default',
  '&, .MuiTypography-root': {
    color: ({ palette }) => palette.primary.light,
  },
});
