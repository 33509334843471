import { format, parseISO } from 'date-fns';
import { DateFormat } from 'types/date';
import { isDate, isISODateString } from '../index';

type DateValue = Date | string | null;

/**
 * Formats date when passed value is valid. Otherwise returns null.
 *
 * @param value - value to be formatted
 * @param dateFormat - format of the date, default: dd/MM/yyyy
 */
export const formatDate = (
  value: unknown,
  dateFormat: DateFormat = 'dd/MM/yyyy',
): string | null => {
  if (!value) {
    return null;
  }

  let date = value as DateValue;

  if (isDateWithSlashes(date)) {
    date = formatDateWithSlashes(date);
  }

  if (isISODateString(date)) {
    date = parseISO(date);
  }

  if (isDate(date)) {
    date = new Date(date);
    const dateWithTimezone = date.getTime() - date.getTimezoneOffset() * 60000;
    return format(dateWithTimezone, dateFormat);
  }

  return null;
};

const isDateWithSlashes = (value: DateValue): value is string =>
  typeof value === 'string' && value.includes('/');

const formatDateWithSlashes = (value: string) => {
  const parts = value.split('/');
  // Check if value has format "DD/MM/YYYY"
  if (parts.length === 3) {
    const [day, month, year] = parts;
    if (year.length === 4 && Number(month) <= 12 && Number(day) <= 31) {
      return value.split('/').reverse().join('-');
    }
  }
  return value;
};
