import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { AddIcon } from 'icons';
import { DataUrl } from 'types/data';
import compressToDataUrl from 'utils/compressToDataUrl';
import Document from './Document';
import DocumentPreview from './DocumentPreview';
import { UploadDocumentsComponentProps } from './types';

export const UploadDocuments = ({
  entityId,
  scope,
  documents,
  addDocument,
  requiredDocumentTypes = [],
  excludedDocumentTypes,
  isUploadingMissingDocuments,
  setModalWidth,
  formMethods,
  previewAsModal = false,
  updateDocument,
  removeDocument,
  onDocumentTypeChange,
}: UploadDocumentsComponentProps) => {
  const { t } = useTranslation('modals', { keyPrefix: 'uploadDocumentsModal' });

  const [fileDataUrl, setFileDataUrl] = useState<DataUrl>(null);
  const [fileToPreview, setFileToPreview] = useState<File | null>(null);

  const onFilePreview = (fileToPreview: File) => {
    if (fileToPreview.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        setFileDataUrl(event.target?.result || null);
      };
      reader.readAsDataURL(fileToPreview);
      setFileToPreview(fileToPreview);
    } else {
      compressToDataUrl(fileToPreview, setFileDataUrl);
      setFileToPreview(fileToPreview);
    }
    if (setModalWidth) {
      setModalWidth('xl');
    }
  };

  const closePreview = () => {
    setFileDataUrl(null);
    setFileToPreview(null);
    if (setModalWidth) {
      setModalWidth('sm');
    }
  };

  return (
    <Box display="flex" gap={3}>
      <Box flex={1} display="flex" flexDirection="column" justifyItems="center" gap={2}>
        {documents.map((document, index) => (
          <Document
            key={document.id}
            index={index}
            document={document}
            formMethods={formMethods}
            documentActions={{ updateDocument, removeDocument }}
            fieldsConfig={{
              requiredDocumentTypes,
              isUploadingMissingDocuments,
              excludedDocumentTypes,
            }}
            onFilePreview={onFilePreview}
            onClosePreview={closePreview}
            isPreviewOpen={!!fileToPreview}
            onDocumentTypeChange={onDocumentTypeChange}
          />
        ))}
        {/* Hide when there is only one document type to choose */}
        {!isUploadingMissingDocuments && scope && (
          <Box display="flex" justifyContent="center" pt={!documents.length ? 2 : 0}>
            <Button
              size="small"
              role="primary"
              startIcon={<AddIcon />}
              onClick={() => addDocument({ scope, entityId })}
            >
              {t('addDocument')}
            </Button>
          </Box>
        )}
      </Box>
      {fileDataUrl && fileToPreview && (
        <DocumentPreview
          fileUrl={fileDataUrl.toString()}
          file={fileToPreview}
          closePreview={closePreview}
          isModal={previewAsModal}
        />
      )}
    </Box>
  );
};

export const UploadDocumentsWithContext = ({
  entityId,
  scope,
}: Pick<UploadDocumentsComponentProps, 'entityId' | 'scope'>) => {
  const { removeDocument, addDocument, documents, formMethods, updateDocument } =
    useContext(FilesUploadContext);

  return (
    <UploadDocuments
      entityId={entityId}
      scope={scope}
      removeDocument={removeDocument}
      addDocument={addDocument}
      documents={documents}
      formMethods={formMethods}
      updateDocument={updateDocument}
      previewAsModal
    />
  );
};
