import { Accept, FileRejection } from 'react-dropzone';
import i18n from 'i18n';
import { MAX_FILE_SIZE } from './static-data';
import { DropzoneProps } from './types';

export const getAcceptedFormats = (accept: Accept | undefined) =>
  accept
    ? Object.keys(accept)
        .map((format) => format.split('/')[1].toUpperCase())
        .join(', ')
    : undefined;

export const getErrorMessage = (
  fileRejections: FileRejection[],
  config: DropzoneProps['dropzoneConfig'],
) => {
  if (fileRejections.length) {
    const { accept, maxFiles, maxSize = MAX_FILE_SIZE } = config ?? {};
    const { file, errors } = fileRejections[0];
    const { code, message } = errors[0];

    return i18n.t(code, {
      ns: 'validation',
      accept: getAcceptedFormats(accept),
      maxSize: Math.floor(maxSize / 10 ** 6),
      maxFiles,
      file,
      defaultValue: message,
    }) as string;
  }
};
