import { PropsWithChildren } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider,
  useTheme,
} from '@mui/material/styles';
import componentsOverrides from './componentsOverrides';
import mixins from './mixins';
import palette from './palette';
import shape from './shape';
import typography from './typography';

export default function ThemeCustomization({ children }: PropsWithChildren) {
  const theme = useTheme();
  const themeOptions: ThemeOptions = {
    palette: palette as ThemeOptions['palette'],
    mixins: mixins(theme),
    typography: typography("'Poppins', sans-serif"),
    components: componentsOverrides({ ...theme, shape }),
    shape: shape as unknown as ThemeOptions['shape'],
  };

  const themes = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
