import { PublicClientApplication } from '@azure/msal-browser';
import StorageKeys from 'consts/storage';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: window._env_.B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: window._env_.B2C_AUTHORITY, // Choose SUSI as your default authority.
    knownAuthorities: window._env_.B2C_KNOWN_AUTHORITIES?.split(',') || [], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
};

export const msalScopes = window._env_.B2C_SCOPES?.split(',') || [];

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: msalScopes,
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

export const signOutFromOtherTabs = () => {
  const activeId = msalInstance.getActiveAccount()?.localAccountId;
  if (activeId !== localStorage.getItem(StorageKeys.LoggedId)) {
    msalInstance.logoutRedirect();
  }
};
