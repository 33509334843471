import { useTranslation } from 'react-i18next';
import { Box, TableCell } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { WarningIcon } from 'icons';
import { getDocumentTypeValue } from 'templates/DocumentsTable/DocumentRow/utils';
import { DocumentTypeCellProps } from './types';

const DocumentTypeCell = ({
  document: { isMissing, documentType, otherCustomType },
  scope,
}: DocumentTypeCellProps) => {
  const { t } = useTranslation('table');

  const content = getDocumentTypeValue({ documentType, otherCustomType }, scope);

  return (
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {isMissing ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
          {content}
          <IconTooltip title={t('documents.tooltips.uploadFilePlaceholder')}>
            <WarningIcon color="error" />
          </IconTooltip>
        </Box>
      ) : (
        content
      )}
    </TableCell>
  );
};

export default DocumentTypeCell;
