import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { tableHeaderStyles } from 'templates/DocumentsTable/styles';
import { normalizeString } from 'utils/string';
import { faqTableColumns } from 'pages/FaqManagement/static-data';

const FaqTableHeader = () => {
  const { t } = useTranslation('table', { keyPrefix: 'faq' });

  return (
    <TableHead>
      <TableRow>
        {faqTableColumns.map(({ name }) => (
          <TableCell sx={tableHeaderStyles} key={name}>
            {t(`columns.${name}`, normalizeString(name))}
          </TableCell>
        ))}
        <TableCell sx={({ mixins }) => mixins.actionCellStyles} />
      </TableRow>
    </TableHead>
  );
};

export default FaqTableHeader;
