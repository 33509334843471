import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import AssignedDossiersGrid from 'modules/AssignedDossiersGrid';
import { LegalPersonViewInterface } from 'api/person/legal/types';

const LegalPersonDossiersTab = () => {
  const legalPerson = useOutletContext<LegalPersonViewInterface>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AssignedDossiersGrid entityId={legalPerson.id} type="Person" />
      </Grid>
    </Grid>
  );
};

export default LegalPersonDossiersTab;
