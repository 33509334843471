import { getFormAddress } from 'formData/common/address/utils';
import { legalPersonSchema } from 'formData/legalPerson';
import i18n from 'i18n';
import { LegalPersonFormDefaultValues } from 'modules/PersonForm/LegalPersonForm/types';
import { RelationData } from 'modules/Relation/types';
import { estateToRelation, personToRelation } from 'modules/Relation/utils';
import { getVatNumberWithMask } from 'utils/vat';
import { FetchedEstateListItem } from 'api/estate/types';
import {
  LegalPersonData,
  LegalPersonFormData,
  VatInterface,
} from 'api/person/legal/types';
import { FetchedPersonData } from 'api/person/types';
import {
  getLegalPersonEstates,
  getLegalPersonRepresentatives,
} from 'api/relations/requests';

export const getLegalPersonDefaultValues = ({
  referenceNumber: _referenceNumber,
  address,
  vat,
  ...initialValues
}: LegalPersonData): LegalPersonFormData => ({
  ...legalPersonSchema.getDefault(),
  ...initialValues,
  address: getFormAddress(address),
  vat: vat ? formatVatWithMask(vat) : null,
});

const formatVatWithMask = ({ number, prefix }: VatInterface): VatInterface => ({
  prefix,
  number: number ? getVatNumberWithMask(number, prefix) : number,
});

export const fetchLegalPersonAffiliations = async (
  legalPersonId: string,
): Promise<Omit<LegalPersonFormDefaultValues, 'legalPerson'>> => {
  let representatives: RelationData<FetchedPersonData>[] = [];
  let ownerships: RelationData<FetchedEstateListItem>[] = [];

  const representativesResponse = await getLegalPersonRepresentatives(legalPersonId);
  if (representativesResponse.ok && representativesResponse.response.length) {
    representatives = representativesResponse.response.map(
      ({ relationId, representative, isDeletable }) => ({
        ...personToRelation(representative),
        responseId: relationId,
        isSaved: true,
        deletable: isDeletable,
        notDeletableReason: !isDeletable
          ? i18n.t('tooltips.representativeNoDeletable')
          : undefined,
      }),
    );
  }

  const estatesResponse = await getLegalPersonEstates(legalPersonId);
  if (estatesResponse.ok && estatesResponse.response.length) {
    ownerships = estatesResponse.response.map(({ id, estate }) => ({
      ...estateToRelation(estate),
      responseId: id,
      isSaved: true,
    }));
  }

  return { representatives, ownerships };
};
