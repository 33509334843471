import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { ProductActionInfoRow } from 'components/modals/ProductActionModal';
import { RentalDepositData } from '../types';
import { getPartiesNames } from './utils';

interface Props {
  data: RentalDepositData | null;
}

export const RentalDepositPartiesDetails = ({ data }: Props) => {
  const { t } = useTranslation(['modals', 'formFields']);
  return data ? (
    <Stack gap={1.5}>
      {data.landlord && (
        <ProductActionInfoRow
          title={t('data.landlords')}
          value={getPartiesNames(
            data.landlord,
            data.landlordRepresentative,
            data.landlordPartner,
          )}
        />
      )}
      {data.tenant && (
        <ProductActionInfoRow
          title={t('data.tenants')}
          value={getPartiesNames(
            data.tenant,
            data.tenantRepresentative,
            data.tenantPartner,
          )}
        />
      )}
    </Stack>
  ) : null;
};
