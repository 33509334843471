import MenuItemContent from '../Select/MenuItemContent';
import { SearchInputOption } from './types';

const SearchInputMenuOption = <T extends object = object>(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: SearchInputOption<T>,
) => (
  <li {...props} key={option.label + option.value}>
    <MenuItemContent {...option} />
  </li>
);

export default SearchInputMenuOption;
