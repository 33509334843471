import { FormEventHandler, PropsWithChildren } from 'react';
import { Box, DialogContent } from '@mui/material';
import { PRODUCT_ACTION_FORM_ID } from './static-data';

const ProductActionContent = ({
  onSubmit,
  customContent = false,
  spaceForScroll = true,
  children,
}: PropsWithChildren<{
  onSubmit: FormEventHandler<HTMLFormElement>;
  customContent?: boolean;
  spaceForScroll?: boolean;
}>) => (
  <Box
    component="form"
    id={PRODUCT_ACTION_FORM_ID}
    onSubmit={onSubmit}
    sx={{ overflowY: 'auto' }}
  >
    {customContent ? (
      children
    ) : (
      <DialogContent
        sx={{ pb: 1, pt: 0, scrollbarGutter: spaceForScroll ? 'stable' : undefined }}
      >
        {children}
      </DialogContent>
    )}
  </Box>
);

export default ProductActionContent;
