import { forwardRef, Ref, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import {
  DataGrid,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCell,
  GridCellProps,
  GridColumnHeaders,
  GridFilterModel,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import CustomLink from 'atoms/Link';
import { ArrowDownIcon, ArrowUpIcon, SortIcon } from 'icons';
import { useTranslateColumns } from './hooks/useTranslateColumns';
import { ActionCell } from './Cells';
import { useTableData, useTablePagination, useTableSort } from './hooks';
import tableStyles from './styles';
import TableFilters from './TableFilters';
import TablePagination from './TablePagination';
import TableToolbar from './TableToolbar';
import { TableProps, TranslatedTableColumn } from './types';
import { filtersToParams, getFilterInitState } from './utils';

const Table = <TRow extends GridValidRowModel>({
  rowIdKey = 'id',
  tKey,
  columns,
  rowRedirectionLink,
  getActions,
  toolbarProps,
  rowsPerPage,
  ...tableDataProps
}: TableProps<TRow>) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const { rows, meta, loading, searchParams, updateRows } = useTableData(tableDataProps);
  const { paginationModel, onPageChange } = useTablePagination(meta, updateRows);
  const { sortModel, onSortChange } = useTableSort(searchParams, updateRows);

  const onFilterChange = ({ quickFilterValues = [] }: GridFilterModel) => {
    const params = filtersToParams(quickFilterValues);
    updateRows(params);
  };

  const CustomColumnHeaders = useMemo(
    () =>
      forwardRef(
        (props: Parameters<typeof GridColumnHeaders>[0], ref: Ref<HTMLDivElement>) => (
          <Stack>
            <GridColumnHeaders {...props} ref={ref} />
            {/* TODO: On smaller screens these filters can be replaced with filter sidebar */}
            <TableFilters
              visibleColumns={props.visibleColumns as unknown as TranslatedTableColumn[]}
            />
          </Stack>
        ),
      ),
    [],
  );

  const CustomCell = useMemo(
    () =>
      forwardRef((props: GridCellProps, ref: Ref<HTMLDivElement>) => {
        const row = rows.find((row) => row.id === props.rowId);

        return row &&
          rowRedirectionLink &&
          props.field !== GRID_CHECKBOX_SELECTION_FIELD &&
          props.column.type !== GRID_ACTIONS_COLUMN_TYPE ? (
          <CustomLink to={rowRedirectionLink(props.rowId.toString(), row)}>
            <GridCell {...props} ref={ref} />
          </CustomLink>
        ) : (
          <GridCell {...props} ref={ref} />
        );
      }),
    [rows],
  );

  const { t } = useTranslation('table');
  const translatedColumns = useTranslateColumns(t, tKey, columns);

  return (
    <Box width="100%">
      <DataGrid
        sx={tableStyles}
        initialState={{
          filter: getFilterInitState(searchParams),
        }}
        localeText={{
          noRowsLabel: t(`${tKey}.noRowsMessage`, t('defaultEmpty')),
          noResultsOverlayLabel: t(`${tKey}.noRowsMessage`, t('defaultEmpty')),
        }}
        // rows
        rows={rows}
        loading={loading}
        autoHeight
        getRowHeight={() => 'auto'}
        getRowId={(row) => row[rowIdKey]}
        // columns
        disableColumnMenu
        columns={
          getActions && !translatedColumns.some(({ field }) => field === 'actions')
            ? [
                ...translatedColumns,
                {
                  field: 'actions',
                  type: 'actions',
                  flex: 0.25,
                  renderCell: ({ row }) => (
                    <ActionCell
                      tKey={tKey}
                      actions={getActions(row)}
                      updateRows={updateRows}
                    />
                  ),
                },
              ]
            : translatedColumns
        }
        // selection
        checkboxSelection={!!toolbarProps?.rowSelection}
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={toolbarProps?.rowSelection?.isRowSelectable}
        onRowSelectionModelChange={(model) => {
          const selectedRow = rows.find((row) => row[rowIdKey] === model.at(-1));
          toolbarProps?.rowSelection?.onRowSelection?.(selectedRow);
          setRowSelectionModel(model);
        }}
        disableMultipleRowSelection={
          toolbarProps?.rowSelection?.disableMultipleRowSelection
        }
        keepNonExistentRowsSelected
        hideFooterSelectedRowCount
        // pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPageChange}
        rowCount={meta?.total}
        //filtering
        filterMode="server"
        onFilterModelChange={onFilterChange}
        // sorting
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortChange}
        // custom components
        slots={{
          cell: CustomCell,
          columnHeaders: CustomColumnHeaders,
          columnUnsortedIcon: ({ sortingOrder: _, ...props }) => <SortIcon {...props} />,
          columnSortedAscendingIcon: ArrowUpIcon,
          columnSortedDescendingIcon: ArrowDownIcon,
          pagination: () => <TablePagination tKey={tKey} rowsPerPage={rowsPerPage} />,
          toolbar: toolbarProps
            ? () => <TableToolbar tKey={tKey} updateRows={updateRows} {...toolbarProps} />
            : null,
        }}
      />
    </Box>
  );
};

export default Table;
