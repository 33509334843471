import { Navigate, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';
import { personTabRoutes } from 'router/routes';
import {
  NaturalPersonDetailsTab,
  NaturalPersonDocumentsTab,
  NaturalPersonDossiersTab,
  NaturalPersonEstatesTab,
  NaturalPersonHistoryTab,
} from 'pages/Persons/NaturalPersons/Details/tabs';

export const naturalPersonTabs: RouteObject[] = [
  { path: personTabRoutes.details, element: <NaturalPersonDetailsTab /> },
  { path: personTabRoutes.estates, element: <NaturalPersonEstatesTab /> },
  { path: personTabRoutes.dossiers, element: <NaturalPersonDossiersTab /> },
  { path: personTabRoutes.documents, element: <NaturalPersonDocumentsTab /> },
  {
    path: personTabRoutes.history,
    element: (
      <ProtectedRoute featureFlag="HistoryLogging" fallback={personTabRoutes.details}>
        <NaturalPersonHistoryTab />
      </ProtectedRoute>
    ),
  },
  { path: '*', element: <Navigate to={personTabRoutes.details} /> },
];
