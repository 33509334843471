import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { apiUrl } from 'api/const';

interface Props {
  id: string;
  userName: string;
}

const UserViewHeader = ({ id, userName }: Props) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'user',
      url: apiUrl.deleteUser(id),
      onSuccess: () => {
        navigate(routes.users);
      },
      translationValues: { userName },
    });

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Box display="flex" alignItems="center" gap={1} mr="auto">
        <Typography variant="h4">{userName}</Typography>
      </Box>
      <Button
        onClick={() => navigate(routes.userEdition(id))}
        role="secondary"
        size="small"
      >
        {t('edit')}
      </Button>
      <Button onClick={onDelete} role="unsafe" variant="outlined" size="small">
        {t('delete')}
      </Button>
    </Box>
  );
};

export default UserViewHeader;
