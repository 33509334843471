import { UrlParams } from 'api/types';
import { getSearchParamsUrl } from 'api/utils';

export default {
  estates: 'estates',
  estatesWithParams: (params: UrlParams) =>
    getSearchParamsUrl('estates', { ...params, statuses: 'Active,Archived' }),
  estate: (estateId: string) => `estates/${estateId}`,
  estateView: (estateId: string) => `estates/${estateId}/estate-view`,
  estateDocuments: (estateId: string) => `estates/${estateId}/documents`,
  estateDocument: (estateId: string, documentId: number) =>
    `estates/${estateId}/documents/${documentId}`,
  estateDetails: (estateId: string) => `estates/${estateId}/details`,
  estatePhoto: (estateId: string) => `estates/${estateId}/photo`,
  estateDocumentFile: (estateId: string, documentId: number, fileId: number) =>
    `estates/${estateId}/documents/${documentId}/files/${fileId}`,
  estateHistoryWithParams: (estateId: string, params: UrlParams) =>
    getSearchParamsUrl(`estates/${estateId}/history`, { ...params }),
};
