import { useTableDebouncedFilter } from 'templates/Table/hooks';
import { formatDate } from 'utils/date';
import { DateInput } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableDateFilter = ({ field, filterName = field }: TranslatedTableColumn) => {
  const { value, setValue } = useTableDebouncedFilter(filterName);

  return (
    <DateInput
      value={value}
      onChange={(date) => setValue(formatDate(date, 'yyyy-MM-dd') ?? '')}
    />
  );
};

export default TableDateFilter;
