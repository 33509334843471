import { ThemedStyle } from 'types/themedStyle';

const PRODUCT_MIN_HEIGHT = 300;

export const getProductCardStyles =
  (isError: boolean): ThemedStyle =>
  ({ palette, mixins }) => ({
    ...mixins.actionCard,
    display: 'flex',
    flexDirection: 'column',
    minHeight: PRODUCT_MIN_HEIGHT,
    ...(isError
      ? {
          borderWidth: '2px',
          borderColor: palette.error.main,
        }
      : {}),
    '& > .MuiCardActionArea-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      a: {
        display: 'block',
        p: 2,
      },
    },
    '& > .MuiCardContent-root': {
      py: 1,
    },
  });

export const subProductsCardStyles: ThemedStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 1,
  p: 1,
};
