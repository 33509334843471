import { Grid } from '@mui/material';
import TaxResidenceInputs from './TaxResidenceInputs';
import TaxResidenceVariant from './TaxResidenceVariant';
import { TaxResidenceFieldProps } from './types';

const TaxResidenceField = ({
  prefix,
  labelVariant,
  isTaxResidenceInputsRequired,
  isTaxResidenceVariantRequired,
}: TaxResidenceFieldProps) => (
  <>
    <Grid item xs={12}>
      <TaxResidenceVariant prefix={prefix} isRequired={isTaxResidenceVariantRequired} />
    </Grid>
    <TaxResidenceInputs
      prefix={prefix}
      labelVariant={labelVariant}
      isRequired={isTaxResidenceInputsRequired}
    />
  </>
);

export default TaxResidenceField;
