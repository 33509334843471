import { useMemo } from 'react';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { actionButtonsBoxStyles, pageButtonStyles } from './styles';
import { PaginationActionsProps } from './types';
import { getPagesToRender } from './utils';

const PaginationActions = ({
  onPageChange,
  page,
  pageSize,
  totalRowCount,
  loading,
}: PaginationActionsProps) => {
  const currentPage = useMemo(() => page + 1, [page]);
  const lastPage = useMemo(
    () => Math.ceil(totalRowCount / pageSize),
    [totalRowCount, pageSize],
  );
  const pages = useMemo(
    () => getPagesToRender(currentPage, lastPage),
    [currentPage, lastPage],
  );

  const changePage = (page: number) => () => onPageChange(page);

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={0.5}
      sx={actionButtonsBoxStyles}
    >
      <IconButton disabled={currentPage === 1 || loading} onClick={changePage(1)}>
        <FirstPage />
      </IconButton>
      <IconButton
        disabled={currentPage === 1 || loading}
        onClick={changePage(currentPage - 1)}
      >
        <ChevronLeft />
      </IconButton>
      {!!totalRowCount &&
        pages.map((page) => (
          <IconButton
            color="secondary"
            key={page}
            className={page === currentPage ? 'active' : ''}
            onClick={changePage(page)}
            disabled={page === currentPage || loading}
            sx={pageButtonStyles}
          >
            <Typography color={loading ? 'grey.400' : 'grey.600'} lineHeight={0}>
              {page}
            </Typography>
          </IconButton>
        ))}
      <IconButton
        disabled={currentPage === lastPage || loading}
        onClick={changePage(currentPage + 1)}
      >
        <ChevronRight />
      </IconButton>
      <IconButton
        disabled={currentPage === lastPage || loading}
        onClick={changePage(lastPage)}
      >
        <LastPage />
      </IconButton>
    </Box>
  );
};

export default PaginationActions;
