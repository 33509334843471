import { ProductStageInterface } from 'api/dossier/products/types';

export const groupStages = (stages: ProductStageInterface[]) => {
  const groupedStages: ProductStageInterface[][] = [];
  stages
    .sort((a, b) => {
      if (a.index === b.index) {
        return (a.secondaryIndex ?? 0) - (b.secondaryIndex ?? 0);
      }
      return a.index - b.index;
    })
    .forEach((stage) => {
      const existingStage = groupedStages.find((group) =>
        group.some(({ index }) => index === stage.index),
      );

      if (existingStage) {
        existingStage.push(stage);
      } else {
        groupedStages.push([stage]);
      }
    });

  return groupedStages;
};
