import { Icons } from './Icons';

const {
  BookIcon,
  EstateIcon,
  NaturalPersonIcon,
  LegalPersonIcon,
  DossierIcon,
  PersonsIcon,
  ErrorIcon,
  FileIcon,
  WarningIcon,
  SearchIcon,
  KebabMenuIcon,
  AddIcon,
  ClearIcon,
  DeleteIcon,
  RemoveIcon,
  SettingsIcon,
  DownloadIcon,
  OpenInNewIcon,
  CancelIcon,
  SaveIcon,
  MenuIcon,
  DashboardIcon,
  NotificationIcon,
  CheckIcon,
  CalendarIcon,
  Layers,
  LanguageIcon,
  BusinessesIcon,
  LogoutIcon,
  UploadIcon,
  FilterIcon,
  HelpIcon,
  FireIcon,
  EditIcon,
  MoveIcon,
  CourtIcon,
  RegistrationIcon,
  InfoIcon,
  ReportIcon,
  CodeIcon,
  PhoneIcon,
  DropIcon,
  AttachIcon,
  FolderIcon,
  RouterIcon,
  LightningIcon,
  HourglassIcon,
  EyeShowIcon,
  MapTagIcon,
  MailIcon,
  LockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  SortIcon,
  PlayIcon,
  LoginIcon,
  RemoveFilterIcon,
  BlockIcon,
  ShopIcon,
  BoxIcon,
  SwitchIcon,
  SupportAgent,
  StateReport,
  IntermediateStateReport,
  RentalDeposit,
  EndStateReport,
  RentalContract,
  RestoreIcon,
} = Icons;

export {
  BoxIcon,
  ShopIcon,
  BookIcon,
  EstateIcon,
  NaturalPersonIcon,
  LegalPersonIcon,
  DossierIcon,
  PersonsIcon,
  ErrorIcon,
  FileIcon,
  WarningIcon,
  SearchIcon,
  KebabMenuIcon,
  AddIcon,
  ClearIcon,
  DeleteIcon,
  RemoveIcon,
  SettingsIcon,
  DownloadIcon,
  OpenInNewIcon,
  CancelIcon,
  SaveIcon,
  MenuIcon,
  DashboardIcon,
  NotificationIcon,
  CheckIcon,
  CalendarIcon,
  LanguageIcon,
  BusinessesIcon,
  LogoutIcon,
  UploadIcon,
  FilterIcon,
  HelpIcon,
  FireIcon,
  EditIcon,
  MoveIcon,
  CourtIcon,
  RegistrationIcon,
  InfoIcon,
  ReportIcon,
  CodeIcon,
  PhoneIcon,
  DropIcon,
  AttachIcon,
  FolderIcon,
  RouterIcon,
  LightningIcon,
  HourglassIcon,
  EyeShowIcon,
  MapTagIcon,
  MailIcon,
  LockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  SortIcon,
  Layers,
  PlayIcon,
  LoginIcon,
  RemoveFilterIcon,
  BlockIcon,
  SwitchIcon,
  SupportAgent,
  StateReport,
  IntermediateStateReport,
  RentalDeposit,
  EndStateReport,
  RentalContract,
  RestoreIcon,
};

export { default as PersonIcon } from './PersonIcon';
