import yup, { numberSchema } from 'utils/yup';
import { MAX_CHILDREN_NUMBER } from './static-data';

const residentsSchema = yup.object({
  numberOfAdults: numberSchema
    .nullable()
    .default(null)
    .positive()
    .label('numberOfAdults')
    .when('childrenDateOfBirth', {
      is: (value: []) => !!value?.length,
      then: (schema) => schema.required(),
    }),
  numberOfChildren: numberSchema.nullable().default(null).max(MAX_CHILDREN_NUMBER),
  childrenDateOfBirth: yup
    .array()
    .of(
      yup.object({
        dateOfBirth: yup
          .string()
          .label('dateOfBirth')
          .nullable()
          .requiredForNullable()
          .default(null)
          .customDate({ disableFuture: true }),
      }),
    )
    .nullable()
    .default(null),
});

export default residentsSchema;
