import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import useFormMethods from 'hooks/useFormMethods';
import { EstateDetailsFields } from 'modules/EstateFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import EstateDescriptionField from 'modules/TranslationField';
import { FormContainer, FormSection } from 'templates/FormTemplates';
import { ControlledTextInput } from 'components/inputs';
import { UploadPhoto } from 'components/UploadPhoto/UploadPhoto';

const EstateAdditionalStep = () => {
  const { t } = useTranslation(['formFields', 'common']);
  const { registerController } = useFormMethods<EstateFormInterface>();
  const uploadPhotoProps = useContext(UploadPhotoContext);

  return (
    <FormContainer>
      <FormSection container>
        <EstateDetailsFields />
      </FormSection>
      <FormSection label={t('stepWizard.estate.sections.description')}>
        <EstateDescriptionField
          render={(lng) => (
            <Box pt={1}>
              <ControlledTextInput
                {...registerController(`estateDetails.descriptions.${lng}`)}
                placeholder={t('description')}
                multiline
                rows={10}
              />
            </Box>
          )}
        />
      </FormSection>
      <FormSection label={t('stepWizard.estate.sections.photo')}>
        <UploadPhoto {...uploadPhotoProps} showStatus />
      </FormSection>
    </FormContainer>
  );
};

export default EstateAdditionalStep;
