import { matchPath } from 'react-router-dom';
import routes from 'router/routes';
import hasPermission from 'utils/hasPermission';
import hasRole from 'utils/hasRole';
import { getObjectEntries } from 'utils/object';
import { getRouteMatch, matchRoute } from 'utils/route';
import { FeatureFlagName } from 'api/config/types';
import { ProductName } from 'api/dossier/products/types';
import { FetchedDossierChecklistItem } from 'api/dossier/types';
import { faqNavItems, lists } from './static-data';
import { NavItem, NavItemLabel } from './types';

export const isMatchPath = (pathname: string, to: string, subLinks: string[]) =>
  pathname === to || subLinks.some((path) => Boolean(matchPath(path, pathname)));

const transformNavItemLabel = (label: NavItemLabel): ProductName | undefined => {
  switch (label) {
    case 'myMove':
      return 'MyMove';
    case 'checkNet':
      return 'StateReport';
  }
};

const getVisibleNavItems = (
  rentalDossierChecklist: FetchedDossierChecklistItem[],
  { permission, forbiddenRoles, label }: NavItem,
) => {
  if (forbiddenRoles?.some(hasRole)) {
    return false;
  }

  if (permission && !hasPermission(permission)) {
    return false;
  }
  const productName = transformNavItemLabel(label);
  if (productName) {
    return !!rentalDossierChecklist.find(({ name }) => name === productName);
  }

  return true;
};

export const getFaqNavItems = () =>
  getObjectEntries(faqNavItems).map(([subheader, items]) => ({
    subheader,
    items,
  }));

export const getNavItems = (
  rentalDossierChecklist: FetchedDossierChecklistItem[],
  isFeatureActive: (flagName: FeatureFlagName) => boolean,
) =>
  getObjectEntries(lists)
    .map(([subheader, items]) => ({
      subheader,
      items: items.filter((item) => getVisibleNavItems(rentalDossierChecklist, item)),
    }))
    .filter(({ items }) => !!items.length)
    .map(({ subheader, items }) => ({
      subheader,
      items: items.map((item) => ({
        ...item,
        disabled: item.featureFlag ? !isFeatureActive(item.featureFlag) : item.disabled,
      })),
    }));

export const shouldRedirectWhenImpersonatingIsFinished = (pathname: string) => {
  const matches = getRouteMatch(pathname);
  return (
    matchRoute(
      [
        routes.estates,
        routes.newEstate,
        routes.persons,
        routes.newPerson,
        routes.dossiers,
        routes.newDossier,
      ],
      pathname,
    ) ||
    matches.estate ||
    matches.legalPerson ||
    matches.naturalPerson ||
    matches.rentalDossier
  );
};
