import { Grid } from '@mui/material';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';

const EstateDocumentsStep = () => (
  <Grid container>
    <Grid item xs={12}>
      <UploadDocumentsWithContext scope="estate" />
    </Grid>
  </Grid>
);

export default EstateDocumentsStep;
