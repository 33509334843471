import BooleanCell from 'templates/Table/Cells/BooleanCell';
import { TableColumn } from 'templates/Table/types';
import { FetchedBusinessListItem } from 'api/businesses/types';
import BusinessActions from './BusinessActions';

export const businessesColumns: TableColumn<FetchedBusinessListItem, 'businesses'>[] = [
  { field: 'businessId', flex: 4 },
  { field: 'externalGuid', flex: 4 },
  { field: 'korfinaId', flex: 4, filterable: true, type: 'number' },
  { field: 'name', flex: 6, filterable: true },
  {
    field: 'migrated',
    headerAlign: 'center',
    align: 'center',
    flex: 2,
    renderCell: BooleanCell,
    sortable: true,
  },
  {
    field: 'actions',
    headerAlign: 'right',
    align: 'right',
    flex: 2,
    renderCell: BusinessActions,
  },
];
