import { Stack, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import AccountMenu from 'modules/AccountMenu';
import Breadcrumbs from 'modules/Breadcrumbs';
import ImpersonatedBusinessBar from 'modules/ImpersonatedBusinessBar';
import NotificationsMenu from 'modules/NotificationsMenu';
import { getHeaderToolbarStyles, headerStyles } from './styles';

interface Props {
  isSidebarExpanded: boolean;
  matchesBreakpoint: boolean;
  hideNotifications?: boolean;
}

const Header = ({ isSidebarExpanded, matchesBreakpoint, hideNotifications }: Props) => (
  <AppBar sx={headerStyles}>
    <Toolbar sx={getHeaderToolbarStyles(isSidebarExpanded, matchesBreakpoint)}>
      <ImpersonatedBusinessBar />
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        px={3}
        py={1}
      >
        <Breadcrumbs />
        <Stack direction="row" alignItems="center" gap={2}>
          {hideNotifications ? null : <NotificationsMenu />}
          <AccountMenu />
        </Stack>
      </Stack>
    </Toolbar>
  </AppBar>
);

export default Header;
