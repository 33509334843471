import { ChangeEventHandler, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BelgiumCountry } from 'formData/common/address/static-data';
import useCountryOptions from 'hooks/useCountryOptions';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js/max';
import BaseInput from '../BaseInput';
import InputWrapper from '../InputWrapper';
import Select from '../Select';
import { HandleSelectChange } from '../Select/types';
import { PhoneInputProps } from './types';
import { formatPhone, getPhonePlaceholder, mapPhoneCodes } from './utils';

const PhoneInput = ({
  number: initNumber = '',
  code: initCode = BelgiumCountry.phoneCode,
  countryId: initCountryId = BelgiumCountry.alpha2Code,
  onNumberChange,
  onCountryChange,
  ...props
}: PhoneInputProps) => {
  const [input, setInput] = useState(initNumber);
  const [code, setCode] = useState(initCode);
  const [countryId, setCountryId] = useState(initCountryId);

  const [options, groupedOptions] = useCountryOptions(mapPhoneCodes);

  const handleCountryChange: HandleSelectChange<CountryCode> = (countryId) => {
    setCountryId(countryId);

    const optionCode = options?.find(({ value }) => value === countryId)?.code;
    const code = optionCode ? String(optionCode) : '';

    setCode(code);
    const { nationalNumber } = parsePhoneNumberFromString(input, countryId) ?? {};
    const formatted = formatPhone(nationalNumber ?? input, code, countryId);
    setInput(formatted);

    if (onCountryChange) {
      onCountryChange(countryId, code, formatted);
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const input = e.target.value;
    if (input !== '+') {
      const formatted = formatPhone(input, code, countryId);
      setInput(formatted);

      if (onNumberChange) {
        onNumberChange(formatted);
      }
    }
  };

  return (
    <Box display="flex" gap={1} justifyContent="space-between">
      <Box minWidth={75}>
        <Select
          name="phone_country"
          options={options}
          groupedOptions={groupedOptions}
          value={countryId}
          onChange={handleCountryChange}
          renderValue={({ value }) => value}
        />
      </Box>
      <Box width="100%">
        <BaseInput
          {...props}
          type="tel"
          startAdornment={
            code ? <Typography lineHeight={0}>{code}</Typography> : undefined
          }
          value={input}
          placeholder={getPhonePlaceholder(countryId)}
          onChange={handleInputChange}
        />
      </Box>
    </Box>
  );
};

const PhoneInputWrapper = (props: PhoneInputProps) => InputWrapper(PhoneInput, props);

export default PhoneInputWrapper;
