import i18n from 'i18n';
import { RentalDepositData } from 'modules/ProductRentalDeposit/types';
import transformName from 'utils/transformName';

export const getDeceasedTenantName = (
  { tenant, tenantPartner }: RentalDepositData,
  deceasedPersonReference: string | null | undefined,
) => {
  if (tenant && tenant.personReference === deceasedPersonReference) {
    return transformName(tenant);
  } else if (tenantPartner && tenantPartner.personReference === deceasedPersonReference) {
    return transformName(tenantPartner);
  }
  return i18n.t(
    'modals:prepareReleaseModal.sections.requiredDocuments.oneOfTenantsDied.deceasedTenant',
  );
};
