import { transformFormAddress } from 'formData/common/address/utils';
import { clearVatMask } from 'utils/vat';
import { LegalPersonData, LegalPersonFormData } from './types';

export const transformData = ({
  address,
  vat,
  ...data
}: LegalPersonFormData): Omit<LegalPersonData, 'referenceNumber'> => ({
  ...data,
  address: transformFormAddress(address),
  vat: vat?.number ? { prefix: vat.prefix, number: clearVatMask(vat.number) } : null,
});
