import { RelationResponses, SaveRelations } from 'modules/Relation/types';
import { updateRelationsState } from 'modules/Relation/utils';
import { FetchedPersonData } from 'api/person/types';
import { addOwnership, deleteOwnership } from 'api/relations/requests';

export const updateEstateOwners =
  (estateId: string): SaveRelations<FetchedPersonData> =>
  async (relationsToPreview, setRelationsToPreview, showSaved) => {
    const responses: RelationResponses = [];

    for (const { id, type, isSaved, responseId, toDelete } of relationsToPreview) {
      if (isSaved && responseId) {
        if (toDelete) {
          const { ok } = await deleteOwnership(responseId);
          responses.push({
            ok,
            relationId: id,
            responseId: ok ? null : responseId,
          });
        } else {
          responses.push({ ok: true, relationId: id, responseId });
        }
      } else {
        const res = await addOwnership({ ownerId: id, ownerType: type, estateId });
        responses.push({
          ok: res.ok,
          relationId: id,
          responseId: res.ok ? res.response.id : null,
          message: !res.ok ? res.message.replace(id, '') : '',
        });
      }
    }

    return updateRelationsState(responses, setRelationsToPreview, showSaved);
  };
