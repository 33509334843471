import { Box } from '@mui/material';
import UserForm from 'modules/UserForm';
import FullPageCard from 'templates/FullPageCard';

export const AddNewUser = () => (
  <FullPageCard shrinkHeight>
    <Box p={2.5}>
      <UserForm />
    </Box>
  </FullPageCard>
);
