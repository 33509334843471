import { useMemo } from 'react';
import { filtersToParams, paramsToFilter } from '../utils';
import useTableFiltersState from './useTableFiltersState';

type FilterValues = Record<string, string>;
type HandleSingleFilter = { setValue: (newValue: string) => void; value: string };
type HandleMultiFilters = {
  setValues: (newValues: FilterValues) => void;
  values: FilterValues;
};

type UseTableFilters = <TField extends string | undefined = undefined>(
  field?: TField,
) => TField extends string ? HandleSingleFilter : HandleMultiFilters;

const useTableFilters: UseTableFilters = (field) => {
  const [filters, setFilters] = useTableFiltersState();

  const values: FilterValues = useMemo(() => filtersToParams(filters), [filters]);

  const setValues = (newValues: FilterValues) => {
    const filters = paramsToFilter({ ...values, ...newValues });
    setFilters(filters);
  };

  const setValue = (newValue: string) => {
    if (!field) return;

    const filters = paramsToFilter({ ...values, [field]: newValue });
    setFilters(filters);
  };

  return { values, setValues, value: field ? values[field] ?? '' : '', setValue };
};

export default useTableFilters;
