import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Checkbox, FormControlLabel, Grid, IconButton, Stack } from '@mui/material';
import { DossierPermissions } from 'consts/permissions';
import { DeleteIcon, EditIcon } from 'icons';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import getLng from 'utils/getLng';
import hasPermission from 'utils/hasPermission';
import { updateCustomTodoItem } from 'api/dossier/requests';
import { CustomDossierTodoItem } from 'api/dossier/types';
import { getTodoItemContainerStyles } from './styles';
import { DndItem, TodoItemProps } from './types';
import { dragAndDropHover } from './utils';

export const CustomTodoListItem = ({
  toDo,
  handleComplete,
  handleEdit,
  handleDelete,
  index,
  updateListOrder,
  dossierId,
}: TodoItemProps) => {
  const isEditable = hasPermission(DossierPermissions.Write);

  const ref = useRef<HTMLDivElement>(null);
  const [{ canDrop, isOver }, drop] = useDrop<
    DndItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >({
    canDrop: isEditable,
    accept: 'product',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    hover: (item: DndItem, monitor) => {
      dragAndDropHover({ ref, item, index, monitor });
    },
  });
  const [_, drag] = useDrag({
    type: 'product',
    canDrag: isEditable,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (draggedItem) => {
      updateListOrder(index, draggedItem.index);
    },
  });

  drag(drop(ref));

  const debouncedItem = useDebounce<CustomDossierTodoItem>(toDo, 500);

  useUpdateEffect(() => {
    updateCustomTodoItem(dossierId, debouncedItem.id, debouncedItem);
  }, [debouncedItem.completed]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="space-between"
      ref={ref}
      sx={getTodoItemContainerStyles(canDrop && isOver, isEditable)}
      paddingX={2.5}
    >
      <Grid item>
        <FormControlLabel
          label={toDo.title[getLng()]}
          control={
            <Checkbox
              color="secondary"
              checked={toDo.completed}
              onChange={handleComplete(toDo.id)}
              disabled={!isEditable}
            />
          }
        />
      </Grid>
      <Grid item>
        <Stack direction="row">
          <IconButton onClick={handleEdit(toDo)} disabled={!isEditable}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDelete(toDo.id)} disabled={!isEditable}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
