import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import NaturalPersonDetailsCard from 'modules/PersonView/NaturalPersonDetailsCard';
import routes from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { NaturalPersonViewInterface } from 'api/person/natural/types';

const NaturalPersonDetailsTab = () => {
  const { naturalPersonId = '' } = useParams();
  const naturalPerson = useOutletContext<NaturalPersonViewInterface>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.naturalPersonTab(naturalPersonId).dossiers} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NaturalPersonDetailsCard {...naturalPerson} />
      </Grid>
      <Grid item xs={12}>
        <AffiliatedPersonsCard
          persons={naturalPerson.representativeOf}
          title="representativeOf"
        />
      </Grid>
      <Grid item xs={12}>
        <AffiliatedPersonsCard
          persons={naturalPerson.representedBy}
          title="representedBy"
        />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonDetailsTab;
