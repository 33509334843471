import { EstateData, EstateElectricityType } from 'api/estate/types';

export const isEstateElectricityMeterNumber2 = (type: EstateElectricityType) =>
  type === 'Double' || type === 'DoubleExclusiveNight';

export const isEstateComposition = (
  estate: Pick<EstateData, 'destination' | 'type'> | null,
) => {
  switch (estate?.destination) {
    case 'Living':
      return estate.type === 'Apartment' || estate.type === 'House';
    case 'NonResidential':
      return true;
    default:
      return false;
  }
};
