import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';
import LoadingElement from 'atoms/LoadingElement';
import { ProductName } from 'api/dossier/products/types';
import ModalTitle from '../ModalTitle';
import ModalWrapper from '../ModalWrapper';
import { PRODUCT_ACTION_FORM_ID } from './static-data';
import { HandleSendRequest, ProductActionModalProps } from './types';
import { fetchProductAction, getProductActionData } from './utils';

export const ProductActionModal = <TName extends ProductName>({
  actionData: { productId, productName, httpMethod, link, name, reportedErrors },
  dossier,
  onSubmit,
  onClose,
  chosenParties,
}: ProductActionModalProps<TName>) => {
  const [submitting, setSubmitting] = useState(false);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['modals', 'common']);

  const sendRequest: HandleSendRequest = async (data, resolvePromise, handleError) => {
    const onSuccess = async () => {
      await onSubmit();
      onClose();
    };

    setSubmitting(true);
    if (resolvePromise) {
      const isValid = await resolvePromise;

      if (isValid) {
        await onSuccess();
      } else if (handleError) {
        setSubmitting(false);
        await handleError();
        await onSubmit();
      }
    } else if (link && httpMethod) {
      const onError = (status?: number) => handleError?.(status, onClose);
      await fetchProductAction({ link, httpMethod, data, onSuccess, onError });
    } else {
      await onSuccess();
    }
    setSubmitting(false);
  };

  const { Component, hideFooterButtons, maxWidth } = useMemo(
    () => getProductActionData(name),
    [name],
  );

  const footer = useMemo(() => t(`productActionModal.${name}.footer`, ''), []);

  return (
    <ModalWrapper open={true} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <ModalTitle
        title={t(`productActionModal.${name}.title`, name)}
        closeModal={onClose}
      />
      {loading && <LoadingElement />}
      {Component && (
        <Box sx={{ display: loading ? 'none' : 'block', overflowY: 'auto' }}>
          <Component
            dossier={dossier}
            product={{
              productId,
              productName,
              actionName: name,
              chosenParties,
              reportedErrors,
            }}
            sendRequest={sendRequest}
            setDisableSubmitButton={setDisableSubmitButton}
            loading={loading}
            setLoading={setLoading}
            onClose={onClose}
          />
        </Box>
      )}
      {!hideFooterButtons && (
        <DialogActions sx={({ mixins }) => mixins.modalFooter}>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
          >
            {footer ? (
              <Typography variant="caption" mr="auto">
                {footer}
              </Typography>
            ) : null}

            <Stack direction="row" gap={1}>
              <Button role="text" onClick={onClose} disabled={loading || submitting}>
                {t(`productActionModal.${name}.cancelButton`, t('common:cancel'))}
              </Button>
              <Button
                type="submit"
                form={PRODUCT_ACTION_FORM_ID}
                loading={submitting}
                disabled={disabledSubmitButton || loading}
              >
                {t(`productActionModal.${name}.submitButton`, t('submitButton.request'))}
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      )}
    </ModalWrapper>
  );
};
