import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import AddressFields from 'modules/AddressFields';
import { EstateDetailsFields, EstatePurposeFields } from 'modules/EstateFields';
import routes from 'router/routes';
import { isTruthyValueInObject } from 'utils/object';
import { EstateType } from 'api/estate/types';
import { getDefaultRentType } from 'pages/Dossiers/Edition/utils';
import { MissingDataForm } from '../types';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { EstateMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getRequiredAndNotRequiredFields,
  isAnyAddressFieldMissing,
} from './utils';

const EstateMissingDataForm = ({
  id,
  fields = [],
  isRentTypeMissing = false,
}: EstateMissingDataFormProps) => {
  const {
    formState: { errors, touchedFields, isDirty, dirtyFields },
    getValues,
    setValue,
  } = useFormContext<MissingDataForm>();

  const missingImportantFields = getMissingRequiredFields(errors.estate, fields);

  const areEstateFieldsDirty = isDirty && isTruthyValueInObject(dirtyFields.estate);

  const onEstateTypeChange = (type: EstateType) => {
    if (isRentTypeMissing && !touchedFields.rentalConditions?.rentType) {
      const rentType = getDefaultRentType({
        type,
        destination: getValues('estate.destination'),
      });
      setValue('rentalConditions.rentType', rentType);
    }
  };

  const [requiredFields, notRequiredFields] = getRequiredAndNotRequiredFields(fields);
  const shouldDisplayAddressFields = isAnyAddressFieldMissing(fields);

  return (
    <Stack spacing={1}>
      {shouldDisplayAddressFields && (
        <AddressFields prefix="estate" isMissingFieldsModal />
      )}
      {!!requiredFields.length && (
        <>
          <EstatePurposeFields
            missingFields={requiredFields}
            onEstateTypeChange={onEstateTypeChange}
          />
          <EstateDetailsFields missingFields={requiredFields} />
        </>
      )}
      {!!notRequiredFields.length && (
        <>
          <EstatePurposeFields
            missingFields={notRequiredFields}
            onEstateTypeChange={onEstateTypeChange}
          />
          <EstateDetailsFields missingFields={notRequiredFields} />
        </>
      )}
      {missingImportantFields.length && areEstateFieldsDirty ? (
        <MissingRequiredFieldsError
          tKey="missingEstateRequiredFields"
          to={routes.estateTab(id).estateInfo}
        />
      ) : null}
    </Stack>
  );
};

export default EstateMissingDataForm;
