import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Toolbar, Typography } from '@mui/material';
import Button from 'atoms/Button';
import FaqTable from 'modules/FaqTable';
import UploadFaqDemoModal from 'modules/UploadFaqDemoModal';
import { getDemoList } from 'api/faq/requests';
import { DemoInterface } from 'api/faq/types';
import { tableToolbarStyles } from './styles';

const FaqManagement = () => {
  const { t } = useTranslation(['common', 'table']);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<DemoInterface[]>([]);

  const fetchDemos = useCallback(async () => {
    setLoading(true);
    const { ok, response } = await getDemoList();
    if (ok) {
      setData(response);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchDemos();
  }, []);

  return (
    <Box sx={({ mixins }) => ({ ...mixins.tablePageWrapper, gap: 0 })}>
      <Toolbar sx={tableToolbarStyles}>
        <Typography
          sx={({ palette }) => ({ flex: '1 1 100%', color: palette.common.white })}
          variant="h5"
        >
          {t('table:faq.title')}
        </Typography>
        <Button size="small" onClick={() => setIsOpen(true)}>
          {t('add')}
        </Button>
      </Toolbar>
      <FaqTable data={data} loading={loading} getList={fetchDemos} />
      {isOpen && (
        <UploadFaqDemoModal onClose={() => setIsOpen(false)} getList={fetchDemos} />
      )}
    </Box>
  );
};

export default FaqManagement;
