import { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { MAX_OWNERS_COUNT } from 'modules/Relation/static-data';
import {
  fetchPersonRelationOptions,
  selectPersonWithPartner,
} from 'modules/Relation/utils';

const EstateOwners = () => {
  const { ownersToPreview, setOwnersToPreview } = useContext(EstateFormContext);

  const ownershipsToFilter = useMemo(
    () => ownersToPreview.filter(({ toDelete }) => !toDelete).map(({ id }) => id),
    [ownersToPreview],
  );

  return (
    <Box pb={ownersToPreview.length ? 0 : 6.5}>
      <AddRelation
        translationKey="estateForm"
        maxRelations={MAX_OWNERS_COUNT}
        relationsToFilter={ownershipsToFilter}
        onSelectOption={selectPersonWithPartner(setOwnersToPreview, ownershipsToFilter)}
        fetchOptions={fetchPersonRelationOptions}
        AddNewOptionModal={AddPersonModal}
      />
      <RelationPreviewList
        relationsToPreview={ownersToPreview}
        setRelationsToPreview={setOwnersToPreview}
        showIcons
      />
    </Box>
  );
};

export default EstateOwners;
