import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import CardWithPlus from 'templates/CardWithPlus';
import { DOSSIER_ROLES } from './static-data';
import { AssignedDossiersGridProps, DossierRole } from './types';
import { getAssignedDossierCardHeight, getNavigationState } from './utils';

const AddNewDossierCard = ({ type, entityId }: AssignedDossiersGridProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'assignedEntityCard' });
  const navigate = useCustomNavigate();

  const redirectToDossierCreation = async (role?: DossierRole) => {
    const state = await getNavigationState(entityId, role);
    navigate(routes.newDossier, { state });
  };

  return (
    <CardWithPlus minHeight={getAssignedDossierCardHeight(type)}>
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {type === 'Estate' ? (
          <Button
            role="text"
            color="secondary"
            onClick={() => redirectToDossierCreation()}
            sx={{ fontSize: '16px', fontWeight: 'bold' }}
          >
            {t('createDossierFromEstate')}
          </Button>
        ) : (
          <>
            <Typography variant="h4" color="primary.main" textAlign="center">
              {t('createDossierFromPerson')}
            </Typography>
            <Box display="flex" justifyContent="space-evenly" width="100%" gap={2}>
              {DOSSIER_ROLES.map((role) => (
                <Button
                  key={role}
                  role="text"
                  color="secondary"
                  onClick={() => redirectToDossierCreation(role)}
                  sx={{ fontSize: '16px', fontWeight: 'bold' }}
                >
                  {t(`${role}s`, { count: 1 })}
                </Button>
              ))}
            </Box>
          </>
        )}
      </Stack>
    </CardWithPlus>
  );
};

export default AddNewDossierCard;
