import yup from 'utils/yup';
import {
  AbolishmentReason,
  DepositDistribution,
} from 'api/dossier/products/rentalDeposit/types';
import { productPartyListSchema, productPartyListV2Schema } from '../schema';
import { getAbolishmentPersonSchema } from './utils';

export const orderRentalDepositSchema = yup.object({
  tenants: productPartyListSchema,
  landlords: productPartyListSchema,
  multipleTenants: yup.boolean().default(false),
  isFireInsurance: yup.boolean().default(false),
  isLegalAssistance: yup.boolean().default(false),
});

export const orderRentalDepositSchemaV2 = yup.object({
  isFireInsurance: yup.boolean().default(false),
  isLegalAssistance: yup.boolean().default(false),
  multipleTenants: yup.boolean().default(false),
});

export const rentalDepositChoosePartiesSchema = yup.object({
  tenants: productPartyListV2Schema,
  landlords: productPartyListV2Schema,
  multipleTenants: yup.boolean().default(false),
});

export const releaseRentalDepositSchema = yup.object({
  abolishmentReason: yup.string<AbolishmentReason>().required(),
  depositDistribution: yup.string<DepositDistribution>().required(),
  emailConsent: yup.boolean(),
  tenant: getAbolishmentPersonSchema('tenant'),
  landlord: getAbolishmentPersonSchema('landlord'),
  broker: getAbolishmentPersonSchema('broker'),
  deceasedPersonReference: yup
    .string()
    .nullable()
    .when('abolishmentReason', {
      is: (reason: AbolishmentReason) => reason === 'oneOfTenantsDied',
      then: (schema) => schema.required(),
    }),
});
