import { useCallback } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FilePreviewProps } from './types';

export const FilePreview = ({ fileType, fileUrl, isModal = false }: FilePreviewProps) => {
  const theme = useTheme();
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

  const getPreviewHeight = useCallback(() => {
    if (isModal) {
      return '100%';
    }
    if (matchesXL) {
      return 800;
    }
    if (matchesLG) {
      return 600;
    }
    return 400;
  }, [matchesLG, matchesXL]);

  return fileType === 'image' ? (
    <Box component="img" src={fileUrl} maxWidth="100%" />
  ) : (
    <iframe src={fileUrl} width="100%" height={getPreviewHeight()} />
  );
};
