import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalPersonFormContext } from 'contexts/personForm/legalPersonFormContext';
import { AddEstateModal } from 'modules/ModalForm/AddEstateModal';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import {
  MAX_DOSSIER_PARTIES_COUNT,
  MAX_ESTATES_COUNT,
} from 'modules/Relation/static-data';
import {
  fetchEstateOptions,
  fetchPersonRelationOptionsByType,
  getRelationsIds,
} from 'modules/Relation/utils';
import { FormContainer, FormSection } from 'templates/FormTemplates';

const LegalPersonAffiliationsStep = () => {
  const {
    representativesToPreview,
    setRepresentativesToPreview,
    ownershipsToPreview,
    setOwnershipsToPreview,
  } = useContext(LegalPersonFormContext);

  const { t } = useTranslation('common', {
    keyPrefix: 'stepWizard.legalPerson.sections',
  });

  const representativesToFilter = useMemo(
    () => getRelationsIds(representativesToPreview),
    [representativesToPreview],
  );

  const ownershipsToFilter = useMemo(
    () => getRelationsIds(ownershipsToPreview),
    [ownershipsToPreview],
  );

  return (
    <FormContainer>
      <FormSection label={t('representedBy')}>
        <AddRelation
          translationKey="legalPersonRepresentative"
          withLabel={false}
          maxRelations={MAX_DOSSIER_PARTIES_COUNT}
          fetchOptions={fetchPersonRelationOptionsByType('NaturalPerson')}
          setRelationsToPreview={setRepresentativesToPreview}
          relationsToFilter={representativesToFilter}
          AddNewOptionModal={AddNaturalPersonModal}
        />
        <RelationPreviewList
          showIcons
          relationsToPreview={representativesToPreview}
          setRelationsToPreview={setRepresentativesToPreview}
        />
      </FormSection>
      <FormSection label={t('ownerOf')}>
        <AddRelation
          translationKey="estate"
          withLabel={false}
          maxRelations={MAX_ESTATES_COUNT}
          fetchOptions={fetchEstateOptions}
          setRelationsToPreview={setOwnershipsToPreview}
          relationsToFilter={ownershipsToFilter}
          AddNewOptionModal={AddEstateModal}
        />
        <RelationPreviewList
          showIcons
          relationsToPreview={ownershipsToPreview}
          setRelationsToPreview={setOwnershipsToPreview}
        />
      </FormSection>
    </FormContainer>
  );
};

export default LegalPersonAffiliationsStep;
