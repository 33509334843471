import api from 'api';
import { apiUrl, BACKEND_ADDRESS } from 'api/const';
import { UrlParams } from 'api/types';
import { getBaseUrl } from 'api/utils';
import {
  BusinessDetails,
  BusinessMigrationsList,
  DossierMigrationsList,
  FetchedBusinessList,
  ThirdPartySoftwareInterface,
} from './types';

export const getBusiness = (businessId?: string) =>
  api.get<BusinessDetails>(getBaseUrl(businessId));

export const getBusinesses = (params: UrlParams) =>
  api.get<FetchedBusinessList>(apiUrl.businessesWithParams(params), {
    baseURL: BACKEND_ADDRESS,
  });

export const migrateBusinesses = async (data: { idsToMigrate: string[] }) =>
  api.post(apiUrl.runBusinessMigration, data, { baseURL: BACKEND_ADDRESS });

export const getDossierMigrationQueue = async (params: UrlParams) =>
  api.get<DossierMigrationsList>(apiUrl.dossierMigrationQueue(params), {
    baseURL: BACKEND_ADDRESS,
  });

export const getBusinessMigrationQueue = async (params: UrlParams) =>
  api.get<BusinessMigrationsList>(apiUrl.businessMigrationQueue(params), {
    baseURL: BACKEND_ADDRESS,
  });

export const getMigrationResponseFile = async (
  fileFolderPrefix: string,
  queueId: number,
) =>
  api.get<Blob>(apiUrl.getMigrationResponseFile({ fileFolderPrefix, queueId }), {
    baseURL: BACKEND_ADDRESS,
    responseType: 'blob',
  });

export const runDataMigration = async (businessId: string) =>
  api.get(apiUrl.runDataMigration(businessId), {
    baseURL: BACKEND_ADDRESS,
  });

export const getThirdPartySoftware = () =>
  api.get<ThirdPartySoftwareInterface>(apiUrl.getThirdPartySoftwareConnection);

export const connectThirdPartySoftware = (softwareName: string) =>
  api.put<string>(apiUrl.connectThirdPartySoftware, { name: softwareName });
