import { ProductName } from 'api/dossier/products/types';
import { getNotifications } from 'api/notifications/requests';
import {
  NotificationsSummary,
  NotificationsSummaryPerProduct,
  ProductNotification,
} from 'api/notifications/types';

export const transformNotifications = (
  notificationsResponse: {
    data: ProductNotification[];
    count: number;
    productName: ProductName;
  }[],
) =>
  Object.fromEntries(
    notificationsResponse.map(({ data, count, productName }) => [
      productName,
      { notifications: data, count },
    ]),
  );

export const getNotificationsComposition =
  (isActionRequired: boolean) =>
  async ({ productName, count }: NotificationsSummaryPerProduct) => {
    const { ok, response } = await getNotifications({
      productName: productName,
      isCleared: false,
      isActionRequired,
    });

    return {
      data: ok ? response.data : [],
      productName,
      count,
    };
  };

export const countAllNotifications = ({
  actionRequired,
  noAction,
}: NotificationsSummary) => {
  let total = 0;
  actionRequired.forEach(({ count }) => (total += count));
  noAction.forEach(({ count }) => (total += count));
  return total;
};

export const getNotificationsFromSummary = async ({
  actionRequired,
  noAction,
}: NotificationsSummary) => {
  const notificationsRequireAction = await Promise.all(
    actionRequired.map(getNotificationsComposition(true)),
  );
  const notificationsNoAction = await Promise.all(
    noAction.map(getNotificationsComposition(false)),
  );

  return {
    actionRequired: transformNotifications(notificationsRequireAction),
    noAction: transformNotifications(notificationsNoAction),
  };
};
