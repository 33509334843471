import { Navigate, useParams } from 'react-router-dom';
import useEstateFormDefaultValues from 'hooks/useEstateFormDefaultValues';
import useLoader from 'hooks/useLoader';
import EstateForm from 'modules/EstateForm';
import { getEstateDefaultValues } from 'modules/EstateView/utils';
import routes from 'router/routes';
import FullPageCard from 'templates/FullPageCard';
import { getEstate } from 'api/estate/requests';

const EstateEdition = () => {
  const { estateId } = useParams();
  if (!estateId) {
    return <Navigate to={routes.estates} />;
  }

  const { loading, defaultValues } = useEstateFormDefaultValues(estateId);

  const { Loader, data } = useLoader({
    id: estateId,
    scope: 'estate',
    fetch: getEstate,
  });

  return (
    <Loader loadingAdditionalData={loading}>
      {data ? (
        <FullPageCard>
          <EstateForm
            defaultValues={{ estate: getEstateDefaultValues(data), ...defaultValues }}
            estateId={estateId}
            stepsToHide={['documents']}
          />
        </FullPageCard>
      ) : null}
    </Loader>
  );
};

export default EstateEdition;
