import getLng from 'utils/getLng';
import api from 'api';
import { HistoryResponseInterface, PhotoBody } from 'api/common/types';
import { apiUrl } from 'api/const';
import { DocumentData, UpdateDocumentInterface } from 'api/documents/types';
import { ResponseWithId, UrlParams } from 'api/types';
import {
  EstateDetailsForm,
  EstateFormData,
  EstateViewInterface,
  FetchedEstateData,
  FetchedEstateDetails,
  FetchedEstateList,
} from './types';
import {
  transformEstate,
  transformEstateListResponse,
  transformEstateResponse,
} from './utils';

export const getEstates = (params: UrlParams) =>
  api.get<FetchedEstateList>(apiUrl.estatesWithParams({ ...params, lng: getLng() }), {
    transformResponseOnSuccess: transformEstateListResponse,
  });

export const getEstate = (estateId: string) =>
  api.get<FetchedEstateData>(apiUrl.estate(estateId), {
    transformResponseOnSuccess: transformEstateResponse,
  });

export const addEstate = async (data: EstateFormData) =>
  api.post<ResponseWithId>(apiUrl.estates, transformEstate(data));

export const updateEstate = (id: string, data: EstateFormData) =>
  api.put<void>(apiUrl.estate(id), transformEstate(data));

// DETAILS
export const getEstateDetails = (estateId: string) =>
  api.get<FetchedEstateDetails>(apiUrl.estateDetails(estateId));

export const updateEstateDetails = (id: string, data: EstateDetailsForm) =>
  api.put<void>(apiUrl.estateDetails(id), data);

// VIEW
export const getEstateViewData = (estateId: string) =>
  api.get<EstateViewInterface>(apiUrl.estateView(estateId));

// PHOTO
export const uploadEstatePhoto = (estateId: string, photoData: PhotoBody) =>
  api.patch<void>(apiUrl.estatePhoto(estateId), photoData);

export const deleteEstatePhoto = (estateId: string) =>
  api.delete<void>(apiUrl.estatePhoto(estateId));

// DOCUMENTS
export const uploadEstateDocument = (estateId: string, formData: FormData) =>
  api.post<ResponseWithId<number>>(apiUrl.estateDocuments(estateId), formData);

export const getEstateDocuments = (estateId: string) =>
  api.get<DocumentData[]>(apiUrl.estateDocuments(estateId));

export const getEstateDocument = (estateId: string, documentId: number) =>
  api.get<Blob>(apiUrl.estateDocument(estateId, documentId), { responseType: 'blob' });

export const getEstateDocumentFile = (
  estateId: string,
  documentId: number,
  fileId: number,
) =>
  api.get<Blob>(apiUrl.estateDocumentFile(estateId, documentId, fileId), {
    responseType: 'blob',
  });

export const updateEstateDocument = (
  estateId: string,
  documentId: number,
  data: UpdateDocumentInterface,
) => api.put<void>(apiUrl.estateDocument(estateId, documentId), data);

// HISTORY
export const getEstateHistory = (estateId: string, params: UrlParams) =>
  api.get<HistoryResponseInterface>(
    apiUrl.estateHistoryWithParams(estateId, { ...params, lng: getLng() }),
  );
