import { EstateDestinationResponse } from 'api/config/types';
import { DocumentsConfig } from 'api/documents/types';
import {
  ConfigContextValues,
  DestinationConfig,
  DestinationConfiguration,
  SubTypesConfig,
} from './types';

export const defaultValue: ConfigContextValues = {
  destinationConfig: {} as DestinationConfig,
  subTypesConfig: {} as SubTypesConfig,
  nationalities: { main: [], other: [] },
  countries: { main: [], other: [] },
  ueCountries: { main: [], other: [] },
  loadingChecklist: false,
  rentalDossierChecklist: [],
  documentsConfig: {} as DocumentsConfig,
  fetchChecklist: async () => {},
};

export const mapConfiguration = (
  response: EstateDestinationResponse,
): DestinationConfiguration => {
  const destinationConfig = {} as DestinationConfig;
  const subTypesConfig = {} as SubTypesConfig;
  response.forEach(({ destination, types }) => {
    destinationConfig[destination] = [];
    types.forEach(({ type, subTypes }) => {
      subTypesConfig[type] = subTypes;
      destinationConfig[destination].push(type);
    });
  });

  return {
    destinationConfig,
    subTypesConfig,
  };
};
