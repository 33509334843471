const grey = {
  0: '#ffffff',
  50: '#F9F9F9',
  100: '#F4F4F4',
  200: '#E9E9E9 ',
  300: '#d9d9d9',
  400: '#C3C3C3',
  500: '#7E7E7E',
  600: '#444443',
  700: '#262626',
  800: '#141414',
  900: '#000000',
  A50: '#fcfdfd',
  A100: '#434343',
  A200: '#1f1f1f',
  A300: '#fafafb',
};

const palette = {
  mode: 'light',
  common: {
    black: '#3D3D3D',
    white: '#fff',
  },
  grey,
  text: {
    primary: grey[600],
    secondary: grey[500],
    disabled: grey[400],
    emphasisLower: 'rgba(0, 0, 0, 0.12)',
  },
  action: {
    disabled: grey[300],
  },
  divider: grey[200],
  background: {
    paper: grey[0],
    default: grey.A50,
  },
  primary: {
    50: '#3a5267',
    100: 'rgba(59, 144, 175, 0.08)',
    200: '#F5F8FA',
    light: '#42728A',
    medium: '#345e7d',
    main: '#284553',
    dark: '#223B47',
    700: '#202955',
  },
  secondary: {
    main: '#F87B17',
    light: '#FEF4EB',
    dark: '#D16108',
    700: '#3B1B02',
  },
  error: {
    main: '#FF562E',
  },
  success: {
    main: '#67C197',
    light: '#d3f0dd',
  },
  info: {
    main: '#8c8c8c',
  },
  warning: {
    main: '#EC8624',
    background: '#FFF7D6',
  },
  other: {
    lightBlue: '#F1F6FA',
    yellow: '#F2C94C',
    border: '#EDF1F3',
    borderDark: '#D0DADF',
    evenRow: '#E1EBF1',
  },
};

export default palette;
