import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useSearchInput from 'hooks/useSearchInput';
import { FormModalProps } from 'modules/ModalForm/types';
import {
  AddRelationProps,
  HandleNewOptionSubmit,
  RelationData,
  RelationOption,
} from 'modules/Relation/types';
import { SearchInput } from 'components/inputs';
import { HandleSearchChange } from 'components/inputs/SearchInput/types';

const AddRelation = <T extends object>({
  relationsToFilter,
  selectedRelations = relationsToFilter,
  setRelationsToPreview,
  onInputClear,
  maxRelations,
  fetchOptions,
  translationKey,
  AddNewOptionModal,
  onSelectOption,
  errorMessage,
  contextOptions = [],
  withPadding,
  withHelperText,
  shouldFilter,
  creatable = true,
  disabled,
  withLabel = true,
  fillInputWithOption = false,
  defaultOption,
  getInitialNewOptionValues,
}: AddRelationProps<T>) => {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [newOptionDefaultValues, setNewOptionDefaultValues] = useState<
    undefined | Partial<FormModalProps<T>['defaultValues']>
  >();

  const { clearQuery, searchInputProps, updateParams, getOptions } = useSearchInput({
    contextOptions,
    optionsToFilter: relationsToFilter,
    fetchOptions,
    initParams: defaultOption ? { query: defaultOption.query } : undefined,
    shouldClearParamsOnBlur: !fillInputWithOption,
  });

  const { t } = useTranslation();

  const disableAdding = useMemo(
    () => !!maxRelations && selectedRelations.length >= maxRelations,
    [selectedRelations.length],
  );

  const setRelations = async (data: RelationData<T>) => {
    if (onSelectOption) {
      onSelectOption(data);
    } else if (setRelationsToPreview && maxRelations === 1) {
      setRelationsToPreview([data]);
    } else if (setRelationsToPreview) {
      setRelationsToPreview((prev) => {
        const relationToDelete = prev.find(
          ({ id, toDelete }) => id === data.id && toDelete,
        );
        if (relationToDelete) {
          return [
            { ...relationToDelete, ...data, toDelete: false },
            ...prev.filter(({ id }) => id !== data.id),
          ];
        }
        return [data, ...prev];
      });
    }
  };

  const onChange: HandleSearchChange<RelationOption<T>> = async (option, reason) => {
    if (reason === 'clear' && onInputClear) {
      onInputClear();
    } else if (option?.created) {
      setOpenAddNewModal(true);
      if (getInitialNewOptionValues) {
        setNewOptionDefaultValues(getInitialNewOptionValues());
      }
      clearQuery();
    } else if (option) {
      setRelations(option.data);
    }

    if (fillInputWithOption) {
      updateParams({ query: option?.query ?? '' });
    } else {
      clearQuery();
    }
  };

  const onModalSubmit: HandleNewOptionSubmit<T> = async (data) => {
    await getOptions({ query: data.name });
    setRelations(data);
  };

  const onModalClose = () => {
    setOpenAddNewModal(false);
  };

  return (
    <Stack padding={withPadding ? 2.5 : 0} width="100%">
      <SearchInput
        label={withLabel ? t(`relationInput.${translationKey}.label`) : undefined}
        name={`${translationKey}_search`}
        creatable={creatable}
        onChange={onChange}
        disabled={disabled || disableAdding}
        defaultOption={defaultOption}
        error={!!errorMessage}
        helperText={
          errorMessage ||
          (disableAdding || withHelperText
            ? t(`relationInput.${translationKey}.helperText`)
            : '')
        }
        placeholder={t(`relationInput.${translationKey}.placeholder`)}
        fillInputWithOption={fillInputWithOption}
        shouldFilter={shouldFilter}
        value={defaultOption?.id}
        {...searchInputProps}
      />
      {AddNewOptionModal ? (
        <AddNewOptionModal
          onSubmit={onModalSubmit}
          open={openAddNewModal}
          closeModal={onModalClose}
          defaultValues={newOptionDefaultValues}
        />
      ) : null}
    </Stack>
  );
};

export default AddRelation;
