import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFormMethods from 'hooks/useFormMethods';
import { getInputLabel } from 'utils/missingFields';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { ControlledTaxResidenceInput } from 'components/inputs';
import TaxResidenceTooltip from './TaxResidenceTooltip';
import { TaxResidenceInputsProps } from './types';

const TaxResidenceInputs = ({
  prefix,
  labelVariant,
  isRequired,
}: TaxResidenceInputsProps) => {
  const { t } = useTranslation('formFields');
  const { watch, registerController, getError } =
    useFormMethods<NaturalPersonFormData>(prefix);

  const taxResidences = watch('taxResidences');
  const taxResidenceBelgium = watch('taxResidenceBelgium');

  const isOptionalFieldDisabled = useMemo(() => {
    return !taxResidences.at(0)?.taxIdentificationNumber;
  }, [taxResidences.at(0)?.taxIdentificationNumber]);

  return taxResidenceBelgium === 'NotBelgium' ? (
    <>
      <ControlledTaxResidenceInput
        xs={12}
        {...registerController('taxResidences.0')}
        error={getError('taxResidences.0.taxIdentificationNumber')}
        label={getInputLabel('taxResidence', isRequired)}
        labelVariant={labelVariant}
        endAdornment={
          <TaxResidenceTooltip
            countryCode={taxResidences.at(0)?.taxResidenceCountry || 'NL'}
          />
        }
      />
      <ControlledTaxResidenceInput
        xs={12}
        {...registerController('taxResidences.1')}
        error={getError('taxResidences.1.taxIdentificationNumber')}
        label={t('taxResidenceOptional')}
        labelVariant={labelVariant}
        disabled={isOptionalFieldDisabled}
        endAdornment={
          !isOptionalFieldDisabled && (
            <TaxResidenceTooltip
              countryCode={taxResidences.at(1)?.taxResidenceCountry || 'NL'}
            />
          )
        }
      />
    </>
  ) : null;
};

export default TaxResidenceInputs;
