import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { defaultLng, namespaces } from 'i18n';
import { TranslationFieldInterface } from 'api/common/types';
import { TabPanel, Tabs } from 'components/tabs';

interface Props {
  render: (lng: keyof TranslationFieldInterface) => JSX.Element;
}

export const TranslationField = ({ render }: Props) => {
  const translationKeys = Object.keys(
    namespaces.common.tabs['translations'],
  ) as (keyof TranslationFieldInterface)[];
  const { i18n } = useTranslation('enums');

  const defaultIndex = useMemo(
    () =>
      translationKeys.findIndex((lng) =>
        i18n.language ? lng === i18n.language : lng === defaultLng,
      ),
    [i18n.language],
  );

  const [activeTab, setActiveTab] = useState(defaultIndex);

  return (
    <Box width="100%">
      <Box sx={({ mixins }) => mixins.tabsWrapper}>
        <Tabs
          translationKey="translations"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </Box>
      {translationKeys.map((lng, index) => (
        <TabPanel key={`description_${lng}`} index={index} value={activeTab}>
          {render(lng)}
        </TabPanel>
      ))}
    </Box>
  );
};
