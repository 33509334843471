import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import * as resources from 'locales';
import { Language } from 'types/language';
import getAuthorizationHeader from 'utils/getAuthorizationHeader';

export const namespaces = { ...resources.en } as const;
export const defaultNS: keyof typeof namespaces = 'common';
export const defaultLng: Language = 'en';
export const languages: Language[] = ['en', 'nl', 'fr'];

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    backend: {
      backends: [HttpBackend, resourcesToBackend(resources)],
      backendOptions: [
        {
          loadPath: `${
            process.env.NODE_ENV !== 'production'
              ? '/'
              : window._env_.REACT_APP_BACKEND_URL
          }locales/{{lng}}?filename={{ns}}.json`,
          request: async (
            _options: object, // this entire options object
            url: string, // passed the value of 'loadPath'
            _payload: object, // a key:value object used when saving missing translations
            callback: (
              _error: unknown, // taken from library
              res: { status: number; data: string } | unknown,
            ) => void,
          ) => {
            try {
              const Authorization = await getAuthorizationHeader();
              const { data } = await axios.get(url, { headers: { Authorization } });
              callback(undefined, { status: 200, data: JSON.stringify(data) });
            } catch (e) {
              callback(e, { status: 400, data: null });
            }
          },
        },
      ],
    },
    interpolation: {
      defaultVariables: {
        field: 'field',
        rows: 'Rows',
      },
    },
    fallbackNS: ['formFields', defaultNS],
    debug: true,
    ns: Object.keys(resources.en),
    defaultNS,
    resources: process.env.NODE_ENV !== 'production' ? resources : undefined,
    fallbackLng: defaultLng,
    supportedLngs: languages,
    appendNamespaceToCIMode: true,
  });

export default i18n;
