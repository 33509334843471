import { useCallback, useEffect } from 'react';
import useFormMethods from 'hooks/useFormMethods';
import { AddressFormInterface } from 'api/common/types';
import { getStreetTranslations } from 'api/config/requests';
import useLanguage from './useLanguage';

const useStreetTranslation = (prefix: string | undefined) => {
  const { getValues } = useFormMethods<{ address: AddressFormInterface }>(prefix);
  const lng = useLanguage();

  const fetchStreetTranslation = useCallback(
    async (street: string) => {
      if (street) {
        const translationTo = lng === 'en' || lng === 'nl' ? 'fr' : 'nl';
        const { ok, response } = await getStreetTranslations({
          name: street,
          translationTo,
        });
        if (ok) {
          return response.name || undefined;
        }
      }
    },
    [lng],
  );

  useEffect(() => {
    fetchStreetTranslation(getValues('address.street'));
  }, [lng]);

  return { fetchStreetTranslation };
};

export default useStreetTranslation;
