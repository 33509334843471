import { useOutletContext } from 'react-router-dom';
import AssignedEstatesGrid from 'modules/AssignedEstatesGrid';
import { NaturalPersonViewInterface } from 'api/person/natural/types';

const NaturalPersonEstatesTab = () => {
  const naturalPerson = useOutletContext<NaturalPersonViewInterface>();

  return <AssignedEstatesGrid estates={naturalPerson.estates} />;
};

export default NaturalPersonEstatesTab;
