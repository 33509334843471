import getLng from 'utils/getLng';
import api from 'api';
import { apiUrl } from 'api/const';
import { DocumentData, UpdateDocumentInterface } from 'api/documents/types';
import { ResponseWithId } from 'api/types';
import { transformPersonAddress, transformPersonViewAddress } from '../utils';
import {
  FetchedLegalPersonData,
  LegalPersonFormData,
  LegalPersonViewInterface,
} from './types';
import { transformData } from './utils';

export const addLegalPerson = async (data: LegalPersonFormData) =>
  api.post<{ id: string }>(apiUrl.legalPersons, transformData(data));

export const getLegalPerson = (legalPersonId: string) =>
  api.get<FetchedLegalPersonData>(apiUrl.legalPerson(legalPersonId), {
    transformResponseOnSuccess: transformPersonAddress,
  });

export const updateLegalPerson = (legalPersonId: string, data: LegalPersonFormData) =>
  api.put<void>(apiUrl.legalPerson(legalPersonId), transformData(data));

export const getLegalPersonViewData = (legalPersonId: string) =>
  api.get<LegalPersonViewInterface>(apiUrl.legalPersonView(legalPersonId), {
    transformResponseOnSuccess: transformPersonViewAddress,
    params: { lng: getLng() },
  });

// LEGAL PERSON DOCUMENTS
export const uploadLegalPersonDocument = (legalPersonId: string, formData: FormData) =>
  api.post<ResponseWithId<number>>(apiUrl.legalPersonDocuments(legalPersonId), formData);

export const getLegalPersonDocuments = (legalPersonId: string) =>
  api.get<DocumentData[]>(apiUrl.legalPersonDocuments(legalPersonId));

export const getLegalPersonDocument = (legalPersonId: string, documentId: number) =>
  api.get<Blob>(apiUrl.legalPersonDocument(legalPersonId, documentId), {
    responseType: 'blob',
  });

export const updateLegalPersonDocument = (
  legalPersonId: string,
  documentId: number,
  data: UpdateDocumentInterface,
) => api.put<void>(apiUrl.legalPersonDocument(legalPersonId, documentId), data);

export const getLegalPersonDocumentFile = (
  legalPersonId: string,
  documentId: number,
  fileId: number,
) =>
  api.get<Blob>(apiUrl.legalPersonDocumentFile(legalPersonId, documentId, fileId), {
    responseType: 'blob',
  });
