import {
  addressSchema,
  bicSchema,
  ibanSchema,
  languageSchema,
} from 'formData/common/schema';
import yup from 'utils/yup';
import vatSchema from './vat/schema';

const legalPersonSchema = yup.object({
  name: yup.string().label('name').required(),
  language: languageSchema.required(),
  address: addressSchema,
  vat: vatSchema,
  iban: ibanSchema,
  bic: bicSchema,
});

export default legalPersonSchema;
