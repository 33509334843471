import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuList, Typography } from '@mui/material';
import { HandleAccordionChange } from 'types/handleChange';
import { useUpdateEffect } from 'usehooks-ts';
import SingleNotification from './SingleNotification';
import { NotificationListProps } from './types';

const NotificationsList = ({
  notifications,
  fetchNotificationsSummary,
  closeNotifications,
  activeTab,
}: NotificationListProps) => {
  const [expandedItem, setExpandedItem] = useState('');

  const { t } = useTranslation();

  useUpdateEffect(() => {
    setExpandedItem('');
  }, [activeTab]);

  const toggleNotification =
    (itemKey: string): HandleAccordionChange =>
    (_event, expanded) => {
      setExpandedItem(expanded ? itemKey : '');
    };

  return (
    <MenuList disablePadding>
      {!notifications.length ? (
        <Box p={2}>
          <Typography>{t('notifications.noNotifications')}</Typography>
        </Box>
      ) : (
        notifications.map((notification) => {
          const key = `${activeTab}_${notification.productName}`;
          return (
            <SingleNotification
              key={key}
              notificationSummary={notification}
              fetchNotificationsSummary={fetchNotificationsSummary}
              closeNotifications={closeNotifications}
              expanded={expandedItem === key}
              toggleNotification={toggleNotification(key)}
              activeTab={activeTab}
              productsAmount={notifications.length}
            />
          );
        })
      )}
    </MenuList>
  );
};

export default NotificationsList;
