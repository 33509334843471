import { useContext } from 'react';
import { Divider, IconButton, ListItem, Menu, MenuList, Typography } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import useMenu from 'hooks/useMenu';
import { NaturalPersonIcon } from 'icons';
import CompanyInfo from './CompanyInfo';
import LanguageItem from './LanguageItem';
import LogoutItem from './LogoutItem';

export const AccountMenu = () => {
  const { anchorEl, closeMenu, openMenu } = useMenu();
  const { username } = useContext(AccountContext);

  return (
    <>
      <IconButton onClick={openMenu} sx={({ mixins }) => mixins.headerIconButton}>
        <NaturalPersonIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        sx={({ mixins }) => mixins.headerMenu}
      >
        <ListItem sx={{ outline: 'none' }}>
          <Typography fontWeight={500}>{username}</Typography>
        </ListItem>
        <Divider />
        <MenuList>
          <LanguageItem closeMenu={closeMenu} />
          <LogoutItem />
        </MenuList>
        <CompanyInfo />
      </Menu>
    </>
  );
};
