import { useTranslation } from 'react-i18next';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { FormContainer } from 'templates/FormTemplates';
import { ControlledDateInput } from 'components/inputs';
import ElectricityField from './ElectricityField';
import EpcField from './EpcField';
import GasField from './GasField';

const EstateMediaStep = () => {
  const { registerController } = useFormMethods<EstateFormInterface>();

  const { t } = useTranslation(['formFields', 'common']);

  return (
    <FormContainer>
      <ElectricityField />
      <EpcField />
      <GasField />
      <ControlledDateInput
        xs={12}
        md={4}
        label={t('formFields:conformity')}
        {...registerController('estateDetails.conformity')}
        helperText={t('common:helperText.conformity')}
        disableFuture
      />
    </FormContainer>
  );
};

export default EstateMediaStep;
