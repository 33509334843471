import { FormState } from 'react-hook-form';
import { getEstateDetailsData } from 'hooks/useEstateDetails/utils';
import {
  getEstateDefaultValues,
  getEstateDetailsDefaultValues,
} from 'modules/EstateView/utils';
import { DossierEstateInterface } from 'api/dossier/types';
import {
  getEstate,
  getEstateDetails,
  updateEstate,
  updateEstateDetails,
} from 'api/estate/requests';
import { EstateDetailsForm } from 'api/estate/types';
import { getEstateData } from 'pages/Estates/Details/utils';
import { EstateForFireInsuranceForm } from './types';

const getSelectorsDefaultValues = (
  estateComposition: EstateDetailsForm['estateComposition'],
) => {
  let attic, basement, extraBuildings;

  if (estateComposition?.attics || estateComposition?.unfurnishedAttic) {
    attic = 'yes';
  } else if (
    estateComposition?.attics === 0 ||
    estateComposition?.unfurnishedAttic === 0
  ) {
    attic = 'no';
  }

  if (estateComposition?.basements || estateComposition?.unfurnishedCellar) {
    basement = 'yes';
  } else if (
    estateComposition?.basements === 0 ||
    estateComposition?.unfurnishedCellar === 0
  ) {
    basement = 'no';
  }

  if (
    estateComposition?.outhouses ||
    estateComposition?.unfurnishedOutbuildings ||
    estateComposition?.gardenSheds
  ) {
    extraBuildings = 'yes';
  } else if (
    estateComposition?.outhouses === 0 ||
    estateComposition?.unfurnishedOutbuildings === 0 ||
    estateComposition?.gardenSheds === 0
  ) {
    extraBuildings = 'no';
  }

  return { attic, basement, extraBuildings };
};

export const getEstateFormData = async (
  estate: DossierEstateInterface,
  currentValues: EstateForFireInsuranceForm,
) => {
  let form: EstateForFireInsuranceForm = {};

  if (estate.type === 'Apartment') {
    const { ok, response } = await getEstate(estate.id);
    if (ok) {
      const values = currentValues.estate
        ? { ...currentValues.estate, ...response }
        : response;
      form = { ...form, estate: getEstateDefaultValues(getEstateData(values)) };
    }
  }

  const { ok, response } = await getEstateDetails(estate.id);
  if (ok) {
    const values = currentValues.estateDetails
      ? { ...currentValues.estateDetails, ...response }
      : response;
    const data = getEstateDetailsData(values);
    const { estateComposition = null, ...defaultValues } =
      getEstateDetailsDefaultValues(data);
    const selectors = getSelectorsDefaultValues(estateComposition);
    form = {
      ...form,
      estateDetails: { ...defaultValues, estateComposition, ...selectors },
    };
  }

  return form;
};

export const handleUpdateEstate = async (
  { estate, estateDetails }: EstateForFireInsuranceForm,
  estateId: string,
  dirtyFields: FormState<EstateForFireInsuranceForm>['dirtyFields'],
) => {
  let isValid = true;

  if (dirtyFields.estate && estate) {
    const { ok } = await updateEstate(estateId, estate);
    isValid = ok;
  }
  if (dirtyFields.estateDetails && estateDetails) {
    const { ok } = await updateEstateDetails(estateId, estateDetails);
    isValid = isValid && ok;
  }
  return isValid;
};
