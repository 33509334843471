import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import { ADDITIONAL_RECORDS_TO_LOAD } from './static-data';

interface Props {
  visibleRecordsCount: number;
  totalRecordsCount: number;
  fetchRecords: (count: number, withSpinner: boolean) => Promise<void>;
}

const HistoryOfChangesFooter = ({
  fetchRecords,
  visibleRecordsCount,
  totalRecordsCount,
}: Props) => {
  const [loadingMoreRecords, setLoadingMoreRecords] = useState(false);

  const { t } = useTranslation('history');

  const showMoreRecords = async () => {
    setLoadingMoreRecords(true);
    await fetchRecords(visibleRecordsCount + ADDITIONAL_RECORDS_TO_LOAD, false);
    setLoadingMoreRecords(false);
  };

  return (
    <Box alignSelf="center">
      {loadingMoreRecords ? (
        <LoadingElement />
      ) : (
        <Button
          role="text"
          color="secondary"
          onClick={showMoreRecords}
          disabled={visibleRecordsCount >= totalRecordsCount}
        >
          {t('showMoreRecords')}
        </Button>
      )}
    </Box>
  );
};

export default HistoryOfChangesFooter;
