import { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import useDocumentsModal from 'hooks/useDocumentsModal';
import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import useProductsDocumentsModal from 'hooks/useProductsDocumentsModal';
import { PRODUCT_ACTION_DOCUMENTS } from 'modules/UploadDocuments/static-data';
import DossierDocumentsTable from 'templates/DossierDocumentsTable';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { DocumentType } from 'api/documents/types';
import { attachDossierTypeDocument } from 'api/dossier/requests';
import { DossierViewInterface } from 'api/dossier/types';
import { DocumentsModal, ProductActionModal } from 'components/modals';
import { DossierDocumentsCardProps } from './types';
import {
  createMissingDocumentType,
  getExcludedDossierDocuments,
  handleFileDeletion,
  transformDossierDocumentsData,
} from './utils';

export const DossierDocumentsCard = ({
  entityType,
  editable,
  fetchDossierAndDossierDocuments,
  dossierDocumentsInfo: {
    documents,
    missingDocumentTypes = [],
    requiredDocumentTypes,
    attachedDocuments,
  },
}: DossierDocumentsCardProps) => {
  const dossier = useOutletContext<DossierViewInterface>();

  const transformedDocuments = useMemo(
    () => transformDossierDocumentsData(documents, attachedDocuments, dossier.products),
    [documents, attachedDocuments],
  );

  const [documentsDataToDisplay, setDocumentsDataToDisplay] =
    useState(transformedDocuments);

  const { openProductActionModal, productActionModalProps } = useProductsDocumentsModal(
    dossier.products,
  );

  const {
    isOpen,
    missingDocumentType,
    openModal,
    closeModal: closeDocumentsModal,
  } = useDocumentsModal();

  const onSingleDocumentSave: HandleSingleDocumentSave = async (documentId) => {
    await attachDossierTypeDocument(dossier.id, documentId, { attached: true });
    if (missingDocumentType) {
      closeDocumentsModal();
    }
  };

  useEffect(() => {
    if (shouldDossierFeatureBeActive(dossier.status)) {
      const missingDocuments = missingDocumentTypes.map(createMissingDocumentType);
      setDocumentsDataToDisplay([...transformedDocuments, ...missingDocuments]);
    } else {
      setDocumentsDataToDisplay(transformedDocuments);
    }
  }, [transformedDocuments, missingDocumentTypes, dossier.status]);

  const handleProductActionDocuments = (documentType?: DocumentType) => {
    if (documentType === 'SignedReleaseForm') {
      openProductActionModal('RentalDeposit', 'RentalDepositUploadSignedReleaseForm');
    } else if (documentType === 'Verdict') {
      openProductActionModal('RentalDeposit', 'RentalDepositUploadVerdict');
    }
  };

  const openDocumentsModal = (documentType?: DocumentType) => {
    if (documentType && PRODUCT_ACTION_DOCUMENTS.includes(documentType)) {
      handleProductActionDocuments(documentType);
    } else {
      openModal(documentType);
    }
  };

  const handleDocumentTypeChange = (documentType: DocumentType) => {
    if (documentType && PRODUCT_ACTION_DOCUMENTS.includes(documentType)) {
      handleProductActionDocuments(documentType);
      closeDocumentsModal();
    }
  };

  const excludedDocumentTypes = useMemo(
    () => getExcludedDossierDocuments(dossier.products),
    [dossier.products],
  );

  return (
    <>
      <DossierDocumentsTable
        data={documentsDataToDisplay}
        updateDocuments={fetchDossierAndDossierDocuments}
        openDocumentsModal={openDocumentsModal}
        context={{
          scope: 'dossier',
          entityId: dossier.id,
          fileDeletion: handleFileDeletion(dossier.status),
          dossierId: dossier.id,
          dossierStatus: dossier.status,
          entityType,
          editable,
        }}
      />
      {isOpen && (
        <DocumentsModal
          scope="dossier"
          entityId={dossier.id}
          missingDocuments={
            missingDocumentType
              ? [
                  getDocumentToUpload({
                    entityId: dossier.id,
                    scope: 'dossier',
                    documentType: missingDocumentType,
                  }),
                ]
              : undefined
          }
          onClose={closeDocumentsModal}
          onSubmit={fetchDossierAndDossierDocuments}
          onSingleDocumentSave={onSingleDocumentSave}
          requiredDocumentTypes={requiredDocumentTypes}
          excludedDocumentTypes={excludedDocumentTypes}
          onDocumentTypeChange={handleDocumentTypeChange}
        />
      )}
      {productActionModalProps && <ProductActionModal {...productActionModalProps} />}
    </>
  );
};
