import { Navigate, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';
import { personTabRoutes } from 'router/routes';
import {
  LegalPersonDetailsTab,
  LegalPersonDocumentsTab,
  LegalPersonDossiersTab,
  LegalPersonEstatesTab,
  LegalPersonHistoryTab,
} from 'pages/Persons/LegalPersons/Details/tabs';

export const legalPersonTabs: RouteObject[] = [
  { path: personTabRoutes.details, element: <LegalPersonDetailsTab /> },
  { path: personTabRoutes.estates, element: <LegalPersonEstatesTab /> },
  { path: personTabRoutes.dossiers, element: <LegalPersonDossiersTab /> },
  { path: personTabRoutes.documents, element: <LegalPersonDocumentsTab /> },
  {
    path: personTabRoutes.history,
    element: (
      <ProtectedRoute featureFlag="HistoryLogging" fallback={personTabRoutes.details}>
        <LegalPersonHistoryTab />
      </ProtectedRoute>
    ),
  },
  { path: '*', element: <Navigate to={personTabRoutes.details} /> },
];
