import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import Button from 'atoms/Button';
import StatusIndicator from 'atoms/StatusIndicator';
import { DossierPermissions } from 'consts/permissions';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import hasPermission from 'utils/hasPermission';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { apiUrl } from 'api/const';
import DossierStatusButton from './DossierStatusButton';
import { DossierViewHeaderProps } from './types';

const DossierViewHeader = ({
  dossier: { id, status, referenceNumber, deletable },
  fetchDossier,
}: DossierViewHeaderProps) => {
  const { t } = useTranslation(['common', 'enums', 'formFields']);
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'rentalDossiers',
      url: apiUrl.rentalDossier(id),
      onSuccess: () => {
        navigate(routes.dossiers);
      },
      translationValues: { referenceNumber },
    });

  const onEdit = () => {
    navigate(routes.rentalDossierEdition(id));
  };

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Box display="flex" gap={1} mr="auto">
        <StatusIndicator
          status={t(`enums:dossierStatuses:${status}`, status)}
          color={({ mixins }) => mixins.getDossierStatusColor(status)}
        />
        <DossierStatusButton dossierId={id} fetchDossier={fetchDossier} status={status} />
      </Box>
      <Button
        role="secondary"
        size="small"
        onClick={onEdit}
        disabled={!shouldDossierFeatureBeActive(status)}
      >
        {t('edit')}
      </Button>
      <Tooltip
        placement="top"
        title={
          !deletable && hasPermission(DossierPermissions.Write) && status !== 'Terminated'
            ? t('tooltips.dossierNotDeletable')
            : ''
        }
      >
        <span>
          <Button
            onClick={onDelete}
            disabled={!deletable || !hasPermission(DossierPermissions.Write)}
            role="unsafe"
            variant="outlined"
            size="small"
          >
            {t('delete')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default DossierViewHeader;
