import { useOutletContext, useParams } from 'react-router-dom';
import { Card, CardContent, Stack } from '@mui/material';
import { ProductContextProvider } from 'contexts/product/productContext';
import useLoader from 'hooks/useLoader';
import {
  ProductDetails,
  ProductHeader,
  ProductSecondaryActions,
} from 'modules/ProductCard';
import {
  ExtendedSubProducts,
  ProductDeletionButton,
  ProductErrors,
  ProductFlowTree,
  ProductTasks,
} from 'modules/ProductDetailsView';
import { useUpdateEffect } from 'usehooks-ts';
import { mapProductDetails } from 'utils/products';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import { filterStages, getProductConfig } from './utils';

export const SingleProduct = () => {
  const { productId } = useParams();

  if (!productId) {
    return null;
  }

  const {
    id: dossierId,
    tenants,
    landlords,
    rentalConditions,
  } = useOutletContext<DossierOutletContext>();

  const {
    Loader,
    data: product,
    updateData,
  } = useLoader(getProductConfig(dossierId, productId));

  useUpdateEffect(() => {
    updateData();
  }, [tenants.length, landlords.length, rentalConditions]);

  return (
    <Loader>
      {product ? (
        <ProductContextProvider
          fetchProduct={updateData}
          productId={product.id}
          productName={product.productName}
          chosenParties={product.chosenParties}
          reportedErrors={product.process?.reportedErrors || null}
        >
          <Card variant="outlined">
            <CardContent sx={{ '&:last-child': { pb: 2 } }}>
              <Stack spacing={3}>
                <ProductHeader
                  productName={product.productName}
                  status={product.status}
                />
                {product.productDetails && (
                  <ProductDetails
                    details={mapProductDetails(
                      product.productName,
                      product.productDetails,
                    )}
                  />
                )}
                {product.process?.stages && (
                  <ProductFlowTree stages={filterStages(product.process.stages)} />
                )}
                {!!product.subProducts?.length && (
                  <ExtendedSubProducts subProducts={product.subProducts} />
                )}
                {!!product.process?.actions?.length && (
                  <ProductSecondaryActions actions={product.process?.actions} />
                )}
                {!!product.process?.reportedErrors?.length && (
                  <ProductErrors errors={product.process?.reportedErrors} />
                )}
                <ProductTasks />
                {product.deletable && <ProductDeletionButton />}
              </Stack>
            </CardContent>
          </Card>
        </ProductContextProvider>
      ) : null}
    </Loader>
  );
};
