import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import ModalTitle from './ModalTitle';
import ModalWrapper from './ModalWrapper';

interface ModalProps {
  open: boolean;
  closeModal: () => void;
}

export const EmailInformationModal = ({ closeModal, open }: ModalProps) => {
  const { t } = useTranslation(['modals', 'common']);

  return (
    <ModalWrapper open={open} onClose={closeModal} fullWidth>
      <ModalTitle title={t('title.createdUser')} closeModal={closeModal} />
      <DialogContent sx={{ mt: 1, pl: 3 }}>{t('data.emailSentMessage')}</DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={closeModal}>
          {t('common:close')}
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};
