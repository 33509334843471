import { useTranslation } from 'react-i18next';
import useFormMethods from 'hooks/useFormMethods';
import { LegalPersonFieldNames } from 'modules/LegalPersonFields/types';
import { NaturalPersonFieldNames } from 'modules/NaturalPersonFields/types';
import { FormSection } from 'templates/FormTemplates';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { PersonType } from 'api/person/types';
import {
  ControlledDateInput,
  ControlledTextInput,
  ControlledVatInput,
} from 'components/inputs';

interface BasicInformationProps {
  index: number;
  type: 'landlords' | 'tenants';
  personType: PersonType;
}

export const BasicInformation = ({ index, type, personType }: BasicInformationProps) => {
  const { t } = useTranslation(['formFields', 'modals']);
  const { registerController } = useFormMethods<RegisterAddendumForm>();

  const registerInput = (name: NaturalPersonFieldNames | LegalPersonFieldNames) => ({
    xs: 6,
    label: t(`formFields:${name}`),
    ...registerController(`${type}.${index}.${name}`),
    disabled: true,
  });

  return (
    <FormSection
      label={t(
        'modals:productActionModal.RentalContractRegisterAddendum.personsSections.basicInformation',
      )}
    >
      <ControlledTextInput {...registerInput('name')} />
      {personType === 'NaturalPerson' ? (
        <>
          <ControlledTextInput {...registerInput('firstName')} />
          <ControlledTextInput {...registerInput('nationalRegisterNumber')} />
          <ControlledDateInput {...registerInput('dateOfBirth')} />
          <ControlledTextInput {...registerInput('placeOfBirth')} />
        </>
      ) : (
        <ControlledVatInput {...registerInput('vat')} />
      )}
    </FormSection>
  );
};
