import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Step, StepButton, Stepper, Tooltip } from '@mui/material';
import Button from 'atoms/Button';
import LoadingElement from 'atoms/LoadingElement';
import StepIcon from './StepIcon';
import { StepWizardProps } from './types';
import useSteps from './useSteps';

const StepWizard = ({
  tKey,
  steps,
  disabledSteps,
  stepsErrors,
  Footer,
  width,
  loading,
  onCancel,
  ...stepsHandlers
}: StepWizardProps) => {
  const { t } = useTranslation();

  const {
    activeStep,
    saving,
    handleChangeStep,
    handleNextStep,
    handlePrevStep,
    handleSave,
  } = useSteps({ steps, disabledSteps, ...stepsHandlers });

  const CurrentStep = useMemo(() => steps[activeStep].Component, [activeStep]);

  return (
    <Stack height="100%" width={width} sx={{ overflowY: 'auto' }}>
      {steps.length > 1 && (
        <Stepper sx={{ p: 2, pt: 4 }} nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map(({ name, required }, step) => (
            <Tooltip placement="top" key={name} title={disabledSteps?.[step]}>
              <Step data-testid="step">
                <StepButton
                  onClick={handleChangeStep(step)}
                  disabled={!!disabledSteps?.[step] || loading || saving}
                  icon={
                    (stepsErrors?.[step] || disabledSteps?.[step]) && (
                      <StepIcon
                        isError={stepsErrors?.[step]}
                        isDisabled={!!disabledSteps?.[step]}
                      />
                    )
                  }
                >
                  {t(`stepWizard.${tKey}.steps.${name}`, name) + (required ? '*' : '')}
                </StepButton>
              </Step>
            </Tooltip>
          ))}
        </Stepper>
      )}
      {loading ? (
        <LoadingElement />
      ) : (
        <Box px={2.5} pt={1} mt={2} sx={{ overflowY: 'auto', scrollbarGutter: 'stable' }}>
          <CurrentStep />
        </Box>
      )}
      <Box
        mt="auto"
        width="100%"
        display="flex"
        justifyContent="flex-end"
        gap={8}
        p={2.5}
      >
        {Footer ? <Box mr="auto">{Footer}</Box> : null}
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" gap={2}>
          <Button
            role="text"
            disabled={saving}
            onClick={onCancel}
            data-testid="cancelButton"
          >
            {t('cancel')}
          </Button>
          {steps.length > 1 && (
            <>
              <Button
                role="secondary"
                color="primary"
                disabled={activeStep === 0 || saving || loading}
                onClick={handlePrevStep}
                data-testid="backButton"
              >
                {t('back')}
              </Button>
              <Button
                role="primary"
                disabled={
                  activeStep === steps.length - 1 ||
                  saving ||
                  loading ||
                  (!!disabledSteps?.[activeStep + 1] && !steps[activeStep + 2])
                }
                onClick={handleNextStep}
                data-testid="nextButton"
              >
                {t('next')}
              </Button>
            </>
          )}
          <Button
            type="submit"
            loading={saving}
            disabled={loading}
            onClick={handleSave}
            data-testid="saveButton"
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default StepWizard;
