import {
  BackofficePermissions,
  DashboardPermissions,
  DossierPermissions,
  EstatePermissions,
  PersonPermissions,
  UserPermissions,
} from 'consts/permissions';
import i18n from 'i18n';
import {
  BookIcon,
  BusinessesIcon,
  CodeIcon,
  CourtIcon,
  DashboardIcon,
  DossierIcon,
  EstateIcon,
  FireIcon,
  LanguageIcon,
  Layers,
  MoveIcon,
  NaturalPersonIcon,
  PersonsIcon,
  RentalDeposit,
  ReportIcon,
  SettingsIcon,
  SupportAgent,
} from 'icons';
import routes, {
  dossierDocumentsTabRoutes,
  dossierTabRoutes,
  estateTabRoutes,
  personTabRoutes,
} from 'router/routes';
import { apiUrl } from 'api/const';
import { NavItem } from './types';

const getTabRoutes = (tabRoutes: object, baseRoute: string): string[] =>
  Object.values(tabRoutes).map((route) => `${baseRoute}/${route}`);

/**
 * This are a common navigation items
 * @permission defines for which permission, item should be displayed
 * @forbiddenRoles is an array of roles that have correct permission but despite this, item should not be displayed
 *
 * additional navigation for impersonated business is handled in ImpersonatedBusinessNav component
 *  */
const navItems: NavItem[] = [
  {
    to: routes.dashboard,
    label: 'dashboard',
    Icon: DashboardIcon,
    permission: DashboardPermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
  },
  {
    to: routes.dossiers,
    label: 'dossiers',
    Icon: DossierIcon,
    permission: DossierPermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    subLinks: [
      routes.newDossier,
      routes.rentalDossier(':dossierId'),
      routes.rentalDossierEdition(':dossierId'),
      ...getTabRoutes(dossierTabRoutes, routes.rentalDossier(':dossierId')),
      ...getTabRoutes(
        dossierDocumentsTabRoutes,
        routes.rentalDossierTab(':dossierId').documents,
      ),
      routes.rentalDossierProduct(':dossierId', ':productId'),
    ],
  },
  {
    to: routes.estates,
    label: 'estates',
    Icon: EstateIcon,
    permission: EstatePermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    subLinks: [
      routes.newEstate,
      routes.estate(':estateId'),
      routes.estateEdition(':estateId'),
      ...getTabRoutes(estateTabRoutes, routes.estate(':estateId')),
    ],
  },
  {
    to: routes.persons,
    label: 'persons',
    Icon: PersonsIcon,
    permission: PersonPermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    subLinks: [
      routes.legalPerson(':legalPersonId'),
      routes.legalPersonEdition(':legalPersonId'),
      ...getTabRoutes(personTabRoutes, routes.legalPerson(':legalPersonId')),
      routes.newPerson,
      routes.naturalPerson(':naturalPersonId'),
      routes.naturalPersonEdition(':naturalPersonId'),
      ...getTabRoutes(personTabRoutes, routes.naturalPerson(':naturalPersonId')),
    ],
  },
];

const externalServices: NavItem[] = [
  {
    to: '',
    label: 'myMove',
    Icon: MoveIcon,
    external: true,
    permission: DashboardPermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    fetchRedirectionLink: apiUrl.myMoveRedirection,
  },
  {
    to: '',
    label: 'checkNet',
    Icon: ReportIcon,
    external: true,
    permission: DashboardPermissions.Read,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    fetchRedirectionLink: apiUrl.checknetRedirection,
  },
];

const preferencesItems: NavItem[] = [
  {
    to: routes.settings,
    label: 'settings',
    Icon: SettingsIcon,
    featureFlag: 'ThirdPartySoftware',
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    // TODO: add disabling settings if business has no license
  },
  {
    to: routes.users,
    label: 'users',
    Icon: SupportAgent,
    permission: UserPermissions.Write,
    forbiddenRoles: ['SuperAdmin', 'Admin'],
    subLinks: [routes.newUser, routes.user(':userId')],
  },
];

const backofficeItems: NavItem[] = [
  {
    to: routes.businesses,
    label: 'businesses',
    Icon: BusinessesIcon,
    permission: BackofficePermissions.Read,
  },
  {
    to: routes.migrationQueue,
    label: 'migrationQueue',
    Icon: Layers,
    permission: BackofficePermissions.Read,
  },
  {
    to: routes.translations,
    label: 'translations',
    Icon: LanguageIcon,
    permission: BackofficePermissions.Read,
  },
  {
    to: routes.faqManagement,
    label: 'faqManagement',
    Icon: BookIcon,
    permission: BackofficePermissions.Read,
  },
  {
    to: '',
    label: 'cimode',
    Icon: CodeIcon,
    permission: BackofficePermissions.Read,
    onClick: () => i18n.changeLanguage('cimode'),
    customClickAction: true,
  },
];

export const lists = {
  mainMenu: navItems,
  services: externalServices,
  backoffice: backofficeItems,
  preferences: preferencesItems,
} as const;

export const faqGeneral: NavItem[] = [
  {
    to: routes.faqPersons,
    label: 'persons',
    Icon: NaturalPersonIcon,
  },
  {
    to: routes.faqEstates,
    label: 'estates',
    Icon: EstateIcon,
  },
  {
    to: routes.faqDossiers,
    label: 'dossiers',
    Icon: DossierIcon,
  },
];

export const faqProducts: NavItem[] = [
  {
    to: routes.faqRentalDeposit,
    label: 'rentalDeposit',
    Icon: RentalDeposit,
  },
  {
    to: routes.faqFireInsurance,
    label: 'fireInsurance',
    Icon: FireIcon,
  },
  {
    to: routes.faqLegalAssistance,
    label: 'legalAssistance',
    Icon: CourtIcon,
  },
];

export const faqNavItems = {
  general: faqGeneral,
  products: faqProducts,
} as const;
