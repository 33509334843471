import { Stack, Tooltip, TooltipProps } from '@mui/material';

interface Props extends TooltipProps {
  /** @default "top" */
  placement?: TooltipProps['placement'];
}

export const IconTooltip = ({ children, placement = 'top', ...props }: Props) => (
  <Tooltip {...props} placement={placement}>
    <Stack alignItems="center">{children}</Stack>
  </Tooltip>
);
