import { EstateComposition } from 'api/estate/types';
import { EstateCompositionTemplate } from './types';

// https://dev.azure.com/korfinadevelopmentcenter/Korfine2/_sprints/taskboard/Onion%20Team/Korfine2/Sprint%202.32.1?workitem=12971
export const ESTATE_COMPOSITION_TEMPLATES: Record<
  EstateCompositionTemplate,
  EstateComposition
> = {
  Studio: { livingRooms: 1, kitchens: 1, bathrooms: 1 },
  Apartment1Bedroom: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 1,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    terraces: 1,
  },
  Apartment2Bedrooms: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 2,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    terraces: 1,
    parkingPlaces: 1,
    basements: 1,
  },
  Apartment3Bedrooms: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 3,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    terraces: 1,
    parkingPlaces: 1,
    basements: 1,
  },
  Apartment4Bedrooms: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 4,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    corridors: 1,
    terraces: 1,
    parkingPlaces: 1,
    basements: 1,
  },
  Duplex: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 3,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    corridors: 1,
    terraces: 1,
    parkingPlaces: 1,
    basements: 1,
  },
  TerracedHouse: {
    entrances: 1,
    livingRooms: 1,
    bedrooms: 3,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    parkingPlaces: 1,
    terraces: 1,
    attics: 1,
    basements: 1,
    gardens: 1,
    corridors: 1,
  },
  HalfOpenHouse: {
    entrances: 1,
    livingRooms: 1,
    diningRooms: 1,
    bedrooms: 3,
    kitchens: 1,
    toilets: 1,
    bathrooms: 1,
    storages: 1,
    parkingPlaces: 1,
    terraces: 1,
    attics: 1,
    basements: 1,
    gardens: 1,
    gardenSheds: 1,
    corridors: 2,
  },
  VillaOrOpenHouse: {
    entrances: 1,
    livingRooms: 1,
    diningRooms: 1,
    bedrooms: 3,
    offices: 1,
    kitchens: 1,
    toilets: 1,
    bathrooms: 2,
    storages: 1,
    parkingPlaces: 1,
    terraces: 1,
    garages: 1,
    attics: 1,
    basements: 1,
    gardens: 1,
    gardenSheds: 1,
    corridors: 2,
  },
};
