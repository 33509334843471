import { NumberOfMonthlyRent } from 'api/dossier/types';

export const numberOfMonthlyRentValues: Record<
  NonNullable<NumberOfMonthlyRent>,
  number
> = {
  Other: 0,
  One: 1,
  Two: 2,
  Three: 3,
};
