import { FieldError, FieldErrorsImpl, FieldValues, Merge } from 'react-hook-form';
import i18n from 'i18n';
import { ValidationKeys } from 'types/yup';
import { FieldWarning } from './types';

export const getErrorMessage = <T extends FieldValues>(
  error: FieldError | Merge<FieldError, FieldErrorsImpl<T>> | FieldWarning | undefined,
) => {
  if (!error?.message) return undefined;

  const msg = error.message as ValidationKeys;
  if (typeof msg === 'string') {
    return i18n.t(msg, { ns: 'validation' }).toString();
  }
  return i18n.t(msg.key, { ...msg.values, ns: 'validation' }).toString();
};
