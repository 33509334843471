import { MAX_ESTATES_COUNT } from 'modules/Relation/static-data';
import getLng from 'utils/getLng';
import api from 'api';
import { apiUrl } from 'api/const';
import { ResponseWithId } from 'api/types';
import {
  AddOwnership,
  AddPrivateRelation,
  AddPrivateRelationError,
  AddRepresentationError,
  AddRepresentationInterface,
  FetchedEstateOwner,
  FetchedOwnedEstates,
  FetchedPrivateRelation,
  FetchedRepresentation,
} from './types';

export const getPrivateRelation = (naturalPersonId: string) =>
  api.get<FetchedPrivateRelation | null>(
    apiUrl.getPersonPrivateRelation(naturalPersonId),
    { params: { lng: getLng() } },
  );

export const addPrivateRelation = (data: AddPrivateRelation) =>
  api.post<ResponseWithId, AddPrivateRelationError>(apiUrl.addPrivateRelation, data);

export const deletePrivateRelation = (privateRelationId: string) =>
  api.delete<void>(apiUrl.privateRelation(privateRelationId));

export const addRepresentation = (data: AddRepresentationInterface) =>
  api.post<ResponseWithId, AddRepresentationError>(apiUrl.addRepresentation, data);

export const getNaturalPersonRepresentations = (naturalPersonId: string) =>
  api.get<FetchedRepresentation<'NaturalPerson'>[]>(
    apiUrl.getNaturalPersonRepresentations(naturalPersonId),
    { params: { lng: getLng() } },
  );

export const getLegalPersonRepresentatives = (legalPersonId: string) =>
  api.get<FetchedRepresentation<'LegalPerson'>[]>(
    apiUrl.getLegalPersonRepresentatives(legalPersonId),
    { params: { lng: getLng() } },
  );

export const deleteRepresentation = (relationId: string) =>
  api.delete<void>(apiUrl.deleteRepresentation(relationId));

export const getEstateOwners = (estateId: string) =>
  api.get<FetchedEstateOwner[]>(apiUrl.getEstateOwners(estateId), {
    params: { lng: getLng() },
  });

export const getNaturalPersonEstates = (personId: string) =>
  api.get<FetchedOwnedEstates[]>(apiUrl.getNaturalPersonEstates(personId), {
    params: { lng: getLng(), limit: MAX_ESTATES_COUNT },
  });

export const getLegalPersonEstates = (personId: string) =>
  api.get<FetchedOwnedEstates[]>(apiUrl.getLegalPersonEstates(personId), {
    params: { lng: getLng(), limit: MAX_ESTATES_COUNT },
  });

export const addOwnership = (data: AddOwnership) =>
  api.post<ResponseWithId>(apiUrl.addOwnership, data);

export const deleteOwnership = (ownershipId: string) =>
  api.delete<void>(apiUrl.deleteOwnership(ownershipId));
