import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import useProductStage from 'hooks/useProductStage';
import { EditIcon, LockIcon } from 'icons';
import { ProductStageInterface } from 'api/dossier/products/types';

const ProductStageTitle = ({
  name,
  isAvailable,
}: Pick<ProductStageInterface, 'name' | 'isAvailable'>) => {
  const Icon = useMemo(() => (isAvailable ? EditIcon : LockIcon), [isAvailable]);
  const { title, isStageAwaitingForExternalAction } = useProductStage(name);

  return (
    <Box display="flex" gap={2} justifyContent="space-between" height="100%">
      <Typography variant="h5" fontWeight="bold" sx={{ alignSelf: 'center' }}>
        {title}
      </Typography>
      {isStageAwaitingForExternalAction && isAvailable ? null : (
        <Box fontSize={16}>
          <Icon fontSize="inherit" color="action" />
        </Box>
      )}
    </Box>
  );
};

export default ProductStageTitle;
