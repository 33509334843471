import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';
import { ApiErrorReturn } from 'api/types';

const fieldsToExclude: string[] = ['address'];

export const setValidationErrors = <T extends FieldValues>(
  validation: ApiErrorReturn['validation'],
  setError: UseFormSetError<T>,
) => {
  validation
    ?.filter(({ field }) => !fieldsToExclude.includes(field))
    .forEach(({ errorCode, field, defaultValue }) => {
      setError(field as FieldPath<T>, {
        // @ts-ignore react-hook-form does not know, that its message also supports objects
        message: { key: errorCode, values: { field, defaultValue } },
      });
    });
};
