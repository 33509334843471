import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import requestStateReportSchema from 'formData/dossier/products/stateReport/schema';
import ProductParties from 'modules/ProductParties';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductActionEstateInfo,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { stateReportConfig } from './static-data';
import { RequestStateReportForm } from './types';
import { transformRequestStateReportData } from './utils';

const RequestStateReport = ({
  sendRequest,
  dossier: { estate, tenants, landlords },
}: ProductActionComponentProps) => {
  const methods = useForm<RequestStateReportForm>({
    defaultValues: requestStateReportSchema.getDefault(),
    resolver: resolver(requestStateReportSchema),
  });

  const onSubmit: SubmitHandler<RequestStateReportForm> = (data) => {
    sendRequest(transformRequestStateReportData(data));
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container gap={4} mt={1}>
        <ProductActionEstateInfo estate={estate} />
        <Grid container item spacing={4}>
          <FormProvider {...methods}>
            <ProductParties
              persons={tenants}
              config={stateReportConfig}
              partyType="tenants"
            />
            <ProductParties
              persons={landlords}
              config={stateReportConfig}
              partyType="landlords"
            />
          </FormProvider>
        </Grid>
      </Grid>
    </ProductActionContent>
  );
};

export default RequestStateReport;
