import { EstateFormDefaultValues, EstateFormInterface } from 'modules/EstateForm/types';
import { getEstateDetailsDefaultValues } from 'modules/EstateView/utils';
import { RelationData } from 'modules/Relation/types';
import { personToRelation } from 'modules/Relation/utils';
import { getEstateDetails } from 'api/estate/requests';
import { FetchedPersonData } from 'api/person/types';
import { getEstateOwners } from 'api/relations/requests';

export const getEstateFormDefaultValues = async (
  estateId: string,
  isCopy = false,
): Promise<Omit<EstateFormDefaultValues, 'estate'>> => {
  let estateDetails: EstateFormInterface['estateDetails'] = undefined;
  let owners: RelationData<FetchedPersonData>[] = [];

  const estateDetailsRes = await getEstateDetails(estateId);
  if (estateDetailsRes.ok) {
    estateDetails = getEstateDetailsDefaultValues(estateDetailsRes.response);
  }

  const ownersRes = await getEstateOwners(estateId);
  if (ownersRes.ok) {
    owners = ownersRes.response.map(({ owner, id }) => ({
      ...personToRelation(owner),
      responseId: id,
      isSaved: !isCopy,
    }));
  }

  return { estateDetails, owners };
};
