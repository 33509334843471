import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import useLoader from 'hooks/useLoader';
import {
  EstateBasicDataCard,
  EstateDescriptionCard,
  EstateViewHeader,
} from 'modules/EstateView';
import routes, { estateTabRoutes } from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { RouteTabs } from 'components/tabs';
import { getEstateConfig, shouldDisplayEstateInfoTab } from './utils';

const EstateDetails = () => {
  const { estateId } = useParams();
  if (!estateId) return null;

  const { isFeatureActive } = useContext(FeatureFlagsContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const { data: estate, Loader } = useLoader(getEstateConfig(estateId));

  const estateRoutes = useMemo(() => {
    if (!matches) {
      return estateTabRoutes;
    }
    if (estate) {
      const { estateInfo: _, ...routesWithoutEstateInfo } = estateTabRoutes;
      const isEstateInfoTab = shouldDisplayEstateInfoTab(estate.data);
      return isEstateInfoTab ? estateTabRoutes : routesWithoutEstateInfo;
    }
    return {};
  }, [estate, matches]);

  return (
    <Loader>
      {estate && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <Box display={{ lg: 'none' }}>
                <EstateViewHeader id={estateId} data={estate.data} />
              </Box>
              <RouteTabs
                routes={estateRoutes}
                parentPath={routes.estate(estateId)}
                translationKey="estateView"
                hiddenTabs={!isFeatureActive('HistoryLogging') ? ['history'] : []}
              />
            </Stack>
            <Outlet context={estate} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            display={{ xs: 'none', lg: 'block' }}
            mb="auto"
          >
            <EstateViewHeader id={estateId} data={estate.data} />
            <Stack spacing={3}>
              <EstateBasicDataCard
                data={estate.data}
                status={estate.status}
                ownReference={estate.ownReference}
              />
              {estate.data.descriptions && (
                <EstateDescriptionCard descriptions={estate.data.descriptions} />
              )}
              {!!estate.owners.length && (
                <AffiliatedPersonsCard persons={estate.owners} title="owners" />
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default EstateDetails;
