import { MouseEvent, MouseEventHandler, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { TextInput } from 'components/inputs';
import ModalWrapper from 'components/modals/ModalWrapper';
import DateRangeCalendar from './DateRangeCalendar';
import { DateRangeCalendarProps, DateRangeProps } from './types';
import { getDateRangeInputValue } from './utils';

const DateRange = ({
  setValues,
  values: [from, to],
  placeholder,
  label,
}: DateRangeProps) => {
  const [input, setInput] = useState(getDateRangeInputValue(from, to));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // It handles clearing values from parent, e.g. clear all filters in the table.
  useUpdateEffect(() => {
    setInput(getDateRangeInputValue(from, to));
  }, [from, to]);

  const openCalendar = () => {
    setIsCalendarOpen(true);
  };

  const closeCalendar = () => {
    setIsCalendarOpen(false);
  };

  const onClear = () => {
    if (from || to) {
      setValues([null, null]);
      setInput('');
    }
    closeCalendar();
  };

  const onSave: DateRangeCalendarProps['onSave'] = (dateFrom, dateTo) => {
    setValues([dateFrom, dateTo]);
    setInput(getDateRangeInputValue(dateFrom, dateTo));
    closeCalendar();
  };

  const handleOnButtonClear: MouseEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClear();
  };

  return (
    <>
      <TextInput
        onClick={openCalendar}
        value={input}
        label={label}
        placeholder={placeholder}
        multiline
        noLimitForMultiline
        clearable
        onClear={handleOnButtonClear}
      />
      <ModalWrapper open={isCalendarOpen} onClose={closeCalendar}>
        <DateRangeCalendar
          initValues={[from, to]}
          onClear={onClear}
          onSave={onSave}
          onClose={closeCalendar}
        />
      </ModalWrapper>
    </>
  );
};

export default DateRange;
