import i18n from 'i18n';
import { formatDate } from 'utils/date';
import { firstCharLowerCase } from 'utils/string';
import { HistoryResponseInterface, PropertyValuesElement } from 'api/common/types';
import { HistoryOfChangesInterface } from './types';

export const mapHistoryOfChanges = (
  history: HistoryResponseInterface['data'],
): HistoryOfChangesInterface[] =>
  history.map(
    ({
      entityId: _,
      eventData,
      propertyName,
      eventType,
      propertyValue,
      propertyValues,
      ...history
    }) => {
      const value = formatPropertyValue(propertyName, propertyValue);
      return {
        ...history,
        propertyName: propertyName || eventData,
        eventType:
          eventType === 'Updated' && !value && !propertyValues?.length
            ? 'Deleted'
            : eventType,
        eventData,
        propertyValue: value,
        propertyValues,
      };
    },
  );

const formatPropertyValue = (propertyName: string | null, value: string | null) => {
  if (!value) {
    return null;
  }
  switch (propertyName) {
    case 'DateOfBirth':
    case 'Conformity':
    case 'Epc.ValidUntil':
    case 'DossierDetails.ContractSigningDate':
    case 'DossierDetails.EntryDate':
    case 'DossierDetails.EndDate':
      return formatDate(new Date(value));
    case 'Epc.Type':
      return i18n.t(`enums:estateEpcTypes.${value}`, value);
    case 'Destination':
      return i18n.t(`enums:estateDestination.${value}`, value);
    case 'Type':
      return i18n.t(`enums:estateType.${value}`, value);
    case 'SubType':
      return i18n.t(`enums:estateSubtype.${value}`, value);
    case 'ManagementType':
      return i18n.t(`enums:estateManagementTypes.${value}`, value);
    case 'Epc.Types':
      return i18n.t(`enums:estateEpcTypes.${value}`, value);
    case 'IsPoliticallyExposedPerson':
    case 'DossierDetails.IsVatRegimeApplicable':
    case 'DossierDetails.IsAdditionalVatRegimeApplicable':
      return value === 'False' ? null : value;
    case 'TaxResidenceBelgium':
      if (value === 'True') {
        return i18n.t('enums:taxResidences.Belgium');
      }
      if (value === 'False') {
        return i18n.t('enums:taxResidences.NotBelgium');
      }
      return null;
    case 'PersonDocument': {
      return i18n.t(
        `documents:naturalPerson.${value}`,
        i18n.t(`documents:legalPerson.${value}`, value),
      );
    }
    case 'RentalDossierDocument': {
      return i18n.t(`documents:dossier.${value}`, value);
    }
    case 'DossierDetails.RentType': {
      return i18n.t(`enums:rentType.${value}`, value);
    }
    case 'DossierDetails.Duration': {
      if (isNaN(Number(value))) {
        return i18n.t(`enums:rentalDossierDuration.${value}`, value);
      }
      return i18n.t('months', { count: Number(value) });
    }
    case 'DossierDetails.RentFrequency': {
      return i18n.t(`enums:rentFrequency.${value}`, value);
    }
    case 'DossierDetails.NumberOfMonthlyRent': {
      return i18n.t(`enums:dossierNumberOfMonthlyRent.${value}`, value);
    }
    case 'RentalDossierStatus': {
      return i18n.t(`enums:dossierStatuses.${value}`, value);
    }
    case 'Products.RentalDepositStatus': {
      return i18n.t(`dossierProducts:RentalDeposit.statuses.${value}`, value);
    }
    case 'Products.FireInsuranceStatus': {
      return i18n.t(`dossierProducts:FireInsurance.statuses.${value}`, value);
    }
    case 'Products.LegalAssistanceStatus': {
      return i18n.t(`dossierProducts:LegalAssistance.statuses.${value}`, value);
    }
    case 'Products.RentalContractStatus': {
      return i18n.t(`dossierProducts:RentalContract.statuses.${value}`, value);
    }
    case 'Products.MyMoveStatus': {
      return i18n.t(`dossierProducts:MyMove.statuses.${value}`, value);
    }
    case 'Products.StateReportStatus': {
      return i18n.t(`dossierProducts:StateReport.statuses.${value}`, value);
    }
    case 'Products.IntermediateStateReportStatus': {
      return i18n.t(`dossierProducts:IntermediateStateReport.statuses.${value}`, value);
    }
    case 'Products.EndStateReportStatus': {
      return i18n.t(`dossierProducts:EndStateReport.statuses.${value}`, value);
    }
    default:
      return value;
  }
};

export const getHistoryPropertyValue = (
  propertyName: string,
  propertyValue: string | null,
  propertyValues: PropertyValuesElement[] | null,
) => {
  if (propertyName === 'AdditionalEstateCompositionItem' && propertyValues?.length) {
    return propertyValues.map((value) => `${value.name}: ${value.count}`).join(', ');
  }
  return propertyValue;
};

export const getHistoryRecordSubtitle = (propertyName: string) => {
  const [section, value] = propertyName.split('.');

  return section === 'EstateComposition' && value
    ? i18n.t(`enums:estateComposition.${firstCharLowerCase(value)}`, value)
    : '';
};

export const getHistoryTranslationKey = (propertyName: string) => {
  const [section, _value] = propertyName.split('.');

  if (section === 'EstateComposition') {
    return 'EstateComposition';
  }

  return propertyName;
};
