import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import NumberInput from './NumberInput';
import { ControlledNumberInputProps } from './types';

const ControlledNumberInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledNumberInputProps<T, N>,
) => React.ReactElement = ({
  name,
  error,
  warning,
  helperText,
  control,
  onChange: handleChange,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, ...field } }) => (
      <NumberInput
        value={value ?? ''}
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        helperText={getErrorMessage(error ?? warning) || helperText}
        onChange={(e) => {
          onChange(e.target.value ? Number(e.target.value) : null);
          if (handleChange) {
            handleChange(e);
          }
        }}
        {...field}
        {...inputProps}
      />
    )}
  />
);

export default ControlledNumberInput;
