import { getDossierDocuments } from 'api/dossier/requests';
import { getEstateDocuments } from 'api/estate/requests';
import { getLegalPersonDocuments } from 'api/person/legal/requests';
import { getNaturalPersonDocuments } from 'api/person/natural/requests';
import { DocumentsList } from './types';

export const getDocuments: DocumentsList = {
  estate: getEstateDocuments,
  naturalPerson: getNaturalPersonDocuments,
  legalPerson: getLegalPersonDocuments,
  dossier: getDossierDocuments,
};
