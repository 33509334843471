import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { OpenInNewIcon } from 'icons';
import { previewFile } from 'utils/handleFile';
import { DocumentFile, DocumentType } from 'api/documents/types';
import { getDossierDocumentFile, getDossierDocuments } from 'api/dossier/requests';

interface Props {
  dossierId: string;
  type: DocumentType<'dossier'>;
}

const ProductDocumentPreview = ({ dossierId, type }: Props) => {
  const [file, setFile] = useState<DocumentFile>();
  const [documentId, setDocumentId] = useState<number | null>(null);

  const { t } = useTranslation('documents');

  const fetchDocument = useCallback(async () => {
    const { ok, response } = await getDossierDocuments(dossierId);
    if (ok) {
      const { documents } = response.dossier;
      const document = documents.find(({ documentType }) => documentType === type);
      if (document) {
        setDocumentId(document.id);
        setFile(document.files[0]);
      }
    }
  }, [dossierId]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  const preview = (fileId: number, contentType: string) => async () => {
    if (documentId) {
      const { ok, response } = await getDossierDocumentFile(
        dossierId,
        documentId,
        fileId,
      );
      if (ok && response) {
        previewFile(response, contentType);
      }
    }
  };

  if (!file) {
    return null;
  }

  return (
    <Grid container item xs={12} justifyContent="space-between" alignItems="center">
      <Typography color="text.secondary">{t(`dossier.${type}`)}</Typography>
      <Button onClick={preview(file.id, file.contentType)} endIcon={<OpenInNewIcon />}>
        {file.fileName}
      </Button>
    </Grid>
  );
};

export default ProductDocumentPreview;
