import { CountryCode } from 'libphonenumber-js';

interface Config {
  mask: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
  length: number | number[];
}

export const TAX_RESIDENCE_DATA: Partial<Record<CountryCode, Config>> = {
  SI: {
    // 99999999
    mask: new Array(8).fill(/\d/),
    length: 8,
  },
  IE: {
    // 9999999L| 9999999LL
    mask: [...new Array(7).fill(/\d/), /[A-Za-z]/, /[A-Za-z]/],
    length: [8, 9],
  },
  AT: {
    // 999999999
    mask: new Array(9).fill(/\d/),
    length: 9,
  },
  CY: {
    // 99999999L
    mask: [...new Array(8).fill(/\d/), /A-Za-z/],
    length: 9,
  },
  ES: {
    // 99999999L | L9999999L
    mask: [/[A-Za-z0-9]/, ...new Array(7).fill(/\d/), /[A-Za-z]/],
    length: 9,
  },
  GR: {
    // 999999999
    mask: new Array(9).fill(/\d/),
    length: 9,
  },
  LT: {
    // 999999999
    mask: new Array(9).fill(/\d/),
    length: 9,
  },
  MT: {
    // 99999999L | 999999999
    mask: [...new Array(8).fill(/\d/), /[A-Za-z0-9]/],
    length: 9,
  },
  NL: {
    // 999999999
    mask: new Array(9).fill(/\d/),
    length: 9,
  },
  PT: {
    // 999999999
    mask: new Array(9).fill(/\d/),
    length: 9,
  },
  BG: {
    // 9999999999
    mask: new Array(10).fill(/\d/),
    length: 10,
  },
  HU: {
    // 9999999999
    mask: new Array(10).fill(/\d/),
    length: 10,
  },
  CZ: {
    // 999999/999 | 999999/9999
    mask: [...new Array(6).fill(/\d/), '/', ...new Array(4).fill(/\d/)],
    length: [10, 11],
  },
  PL: {
    // 9999999999 | 99999999999
    mask: new Array(11).fill(/\d/),
    length: [10, 11],
  },
  SK: {
    // 9999999999 | 999999/9999 | 999999/999
    mask: [...new Array(6).fill(/\d/), /[/\d//]/, ...new Array(4).fill(/\d/)],
    length: [10, 11],
  },
  DE: {
    // 99999999999
    mask: new Array(11).fill(/\d/),
    length: 11,
  },
  DK: {
    // 999999-9999
    mask: [...new Array(6).fill(/\d/), '-', ...new Array(4).fill(/\d/)],
    length: 11,
  },
  EE: {
    // 99999999999
    mask: new Array(11).fill(/\d/),
    length: 11,
  },
  FI: {
    // 999999(+/-/A)999(L/9)
    mask: [
      ...new Array(6).fill(/\d/),
      /[Aa+-]/,
      ...new Array(3).fill(/\d/),
      /[A-Za-z0-9]/,
    ],
    length: 11,
  },
  HR: {
    // 99999999999
    mask: new Array(11).fill(/\d/),
    length: 11,
  },
  LV: {
    // DDMMYY99999 | 32999999999
    mask: (value) => {
      let second = /[0-9]/;
      if (value[0] === '0') {
        second = /[1-9]/;
      } else if (value[0] === '3') {
        second = /[0-2]/;
      }

      let month = [/\d/, /\d/];
      if (value[0] + value[1] !== '32') {
        if (value[2] === '0') {
          month = [/[0-1]/, /[1-9]/];
        } else {
          month = [/[0-1]/, /[0-2]/];
        }
      }
      return [/[0-3]/, second, ...month, ...new Array(7).fill(/\d/)];
    },
    length: 11,
  },
  SE: {
    // 999999-9999
    mask: [...new Array(6).fill(/\d/), '-', ...new Array(4).fill(/\d/)],
    length: 11,
  },
  FR: {
    // 9999999999999
    mask: new Array(13).fill(/\d/),
    length: 13,
  },
  LU: {
    // 9999999999999
    mask: new Array(13).fill(/\d/),
    length: 13,
  },
  RO: {
    // 9999999999999
    mask: new Array(13).fill(/\d/),
    length: 13,
  },
  IT: {
    // LLLLLL99L99L999L
    mask: [
      ...new Array(6).fill(/[A-Za-z]/),
      ...new Array(2).fill(/\d/),
      /[A-Za-z]/,
      ...new Array(2).fill(/\d/),
      /[A-Za-z]/,
      ...new Array(3).fill(/\d/),
      /[A-Za-z]/,
    ],
    length: 16,
  },
};
