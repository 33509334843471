export const faqTableColumns: {
  name: 'section' | 'language' | 'name' | 'howdyGoLink';
  translationPrefix?: string;
}[] = [
  {
    name: 'section',
    translationPrefix: 'faqSections',
  },
  {
    name: 'language',
    translationPrefix: 'language',
  },
  {
    name: 'name',
  },
  {
    name: 'howdyGoLink',
  },
];
