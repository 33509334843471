import { CustomWhenConfig } from 'types/yup';
import { ISchema, Schema } from 'yup';

export function customWhen(
  this: Schema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { is, then, otherwise }: CustomWhenConfig<ISchema<any>>,
) {
  // @ts-ignore it exists, typescript just don't see it
  return this.when((_, schema, options) => {
    // @ts-ignore it exists - some mutation happens here, because it does not appear immediately, but there is no delay in rendering
    const from = options.from?.map(({ value }) => value) ?? [];
    const levels = {
      parent: options.parent ?? {},
      grandParent: from.at(1) ?? {},
      form: from.at(-1) ?? {},
    };

    if (is(levels, options.value)) {
      return then(schema);
    } else if (otherwise) {
      return otherwise(schema);
    }
  });
}
