import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import CardRow from './CardRow';

interface Props {
  iban?: string | null;
  bic?: string | null;
}

const AccountNumberRow = ({ iban, bic }: Props) => {
  const { t } = useTranslation('formFields');

  if (iban || bic) {
    return (
      <>
        {iban && (
          <CardRow title={t('iban')} value={IBAN.printFormat(iban)} testId="iban" />
        )}
        {bic && <CardRow title={t('bic')} value={bic} testId="bic" />}
      </>
    );
  }

  return null;
};

export default AccountNumberRow;
