import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import { TKey } from 'i18next';
import { getObjectKeys } from 'utils/object';
import { PartyFieldsConfig } from './types';

export enum ContactField {
  Primary,
  Secondary,
}

export const defaultFieldsConfig: PartyFieldsConfig = {
  maxFieldsCount: 1,
  addingFields: false,
  shouldDisplaySecondaryField: () => true,
  selectableRepresentatives: false,
  showPartner: false,
  showLegalPersonInSecondaryContact: false,
  showRelationDropdown: false,
};

export const relationOptions: TranslatedOption<
  TKey<'enums', 'privateRelation_orderProduct'>
>[] = getObjectKeys(namespaces.enums.privateRelation_orderProduct).map((value) => ({
  value,
  label: value,
  translationKey: `privateRelation_orderProduct.${value}`,
}));
