import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { firstCharLowerCase } from 'utils/string';
import { ProductDocument } from 'api/dossier/products/types';
import { attachDossierDocument, attachDossierTypeDocument } from 'api/dossier/requests';

const attachDocument =
  (dossierId: string, missingDocuments: ProductDocument[]): HandleSingleDocumentSave =>
  async (documentId, scope, entityId) => {
    if (scope === 'dossier') {
      await attachDossierTypeDocument(dossierId, documentId, { attached: true });
    } else {
      const missingDocument = missingDocuments.find(
        ({ relatedEntityId }) => relatedEntityId === entityId,
      );

      if (missingDocument?.relatedEntityType === 'Estate') {
        await attachDossierDocument(dossierId, {
          documentId,
          entityId,
          entityType: 'estate',
        });
      } else if (missingDocument?.person?.partyType) {
        await attachDossierDocument(dossierId, {
          documentId,
          entityId,
          entityType: firstCharLowerCase(missingDocument.person.partyType),
        });
      }
    }
  };

export default attachDocument;
