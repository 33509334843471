import { ThemedStyle } from 'types/themedStyle';

export const getBaseInputStyles =
  (error: boolean, warning: boolean, fullWidthWarning?: boolean): ThemedStyle =>
  ({ palette }) => ({
    '.MuiFormHelperText-root': {
      color: error
        ? palette.error.main
        : warning
        ? palette.warning.main
        : palette.text.secondary,
      ...(fullWidthWarning && {
        marginLeft: 0,
        marginRight: 0,
      }),
    },
    label: {
      '&.MuiFormLabel-root.Mui-error.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
    input: {
      boxSizing: 'content-box',
    },
  });
