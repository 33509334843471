import { useOutletContext } from 'react-router-dom';
import HistoryOfChanges from 'templates/HistoryOfChanges';
import { PERSON_HISTORY_ACTIVITIES } from 'templates/HistoryOfChanges/static-data';
import { LegalPersonViewInterface } from 'api/person/legal/types';
import { getPersonHistory } from 'api/person/requests';

const LegalPersonHistoryTab = () => {
  const legalPerson = useOutletContext<LegalPersonViewInterface>();

  return (
    <HistoryOfChanges
      getHistory={getPersonHistory}
      entityId={legalPerson.id}
      activities={PERSON_HISTORY_ACTIVITIES}
    />
  );
};

export default LegalPersonHistoryTab;
