import { ThemedStyle } from 'types/themedStyle';

export const getFilterStyle =
  (checked: boolean): ThemedStyle =>
  ({ palette }) => ({
    fontWeight: 600,
    color: checked ? palette.common.white : palette.grey[500],
    bgcolor: checked ? palette.primary.main : palette.background.paper,
    border: `1px solid ${checked ? palette.primary.main : palette.grey[400]}`,
    borderRadius: 2,
    py: 1,
    '.MuiSvgIcon-root': {
      fill: palette.common.white,
      ml: 1,
    },
    '&:hover': {
      bgcolor: checked ? palette.primary.dark : palette.other.lightBlue,
    },
  });
