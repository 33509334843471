import { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { TAX_RESIDENCE_DATA } from 'formData/naturalPerson/taxResidences/static-data';
import InputWrapper from '../InputWrapper';
import MaskedInput from '../MaskedInput';
import Select from '../Select';
import { TaxResidenceInputProps } from './types';
import { mapCountriesCodes } from './utils';

const TaxResidenceInput = ({
  taxIdentificationNumber = '',
  taxResidenceCountry = 'NL',
  disabled,
  onNumberChange,
  onCountryChange,
  ...props
}: TaxResidenceInputProps) => {
  const { ueCountries } = useContext(ConfigContext);

  const groupedOptions = useMemo(
    () => ({
      main: ueCountries.main
        .filter(({ alpha2Code }) => alpha2Code !== 'BE')
        .map(mapCountriesCodes),
      other: ueCountries.other.map(mapCountriesCodes),
    }),
    [ueCountries],
  );

  const options = useMemo(
    () => [...groupedOptions.main, ...groupedOptions.other],
    [groupedOptions],
  );

  return (
    <Grid container spacing={0.5} justifyContent="space-between">
      {options ? (
        <Grid item minWidth={70}>
          <Select
            name="phone_country"
            options={options}
            groupedOptions={groupedOptions}
            renderValue={({ value }) => value}
            value={taxResidenceCountry}
            onChange={onCountryChange}
            disabled={disabled}
          />
        </Grid>
      ) : null}
      <Grid item width={options ? 'calc(100% - 75px)' : '100%'}>
        <MaskedInput
          {...props}
          mask={TAX_RESIDENCE_DATA[taxResidenceCountry]?.mask || false}
          pipe={(value) => value?.toUpperCase()}
          disabled={disabled}
          value={taxIdentificationNumber}
          onChange={onNumberChange}
        />
      </Grid>
    </Grid>
  );
};

const TaxResidenceInputWrapper = (props: TaxResidenceInputProps) =>
  InputWrapper(TaxResidenceInput, props);

export default TaxResidenceInputWrapper;
