import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputLabel,
} from '@mui/material';
import Button from 'atoms/Button';
import { languageOptions } from 'formData/common/selectOptions';
import { faqDemoSchema } from 'formData/faqDemoUpload/schema';
import { generalOptions, productsOptions } from 'formData/faqDemoUpload/static-data';
import { DataUrl } from 'types/data';
import { resolver } from 'utils/yup';
import { updateDemo, updateThumbnail, uploadNewDemo } from 'api/faq/requests';
import { DemoInterface } from 'api/faq/types';
import { ControlledSelect, ControlledTextInput } from 'components/inputs';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';
import UploadPhoto from 'components/UploadPhoto';
import { FileToProcess } from 'components/UploadPhoto/types';
import { FaqFieldNames } from './types';
import { transformToThumbnail } from './utils';

interface Props {
  onClose: () => void;
  getList: () => Promise<void>;
  defaultValues?: DemoInterface;
}

const ModalContent = ({ onClose, getList, defaultValues }: Props) => {
  const [saving, setSaving] = useState(false);

  const [thumbnail, setThumbnail] = useState<DataUrl>(null);
  const [uploadedFile, setUploadedFile] = useState<FileToProcess | null>(null);

  const clearFile = () => {
    setThumbnail(null);
    setUploadedFile(null);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = useForm<DemoInterface>({
    resolver: resolver(faqDemoSchema),
    defaultValues: defaultValues || faqDemoSchema.getDefault(),
  });
  useEffect(() => {
    const fetchSavedPhoto = async () => {
      if (defaultValues?.thumbnail) {
        const blob = await (await fetch(defaultValues.thumbnail as string)).blob();
        const file = new File([blob], '', { type: blob.type });
        setFileDataUrl(defaultValues.thumbnail);
        setUploadedFile({ file, status: 'SAVED' });
      }
    };
    fetchSavedPhoto();
  }, []);

  const { t } = useTranslation(['formFields', 'validation', 'common']);

  const onSubmit = async (data: DemoInterface) => {
    setSaving(true);
    let ok = false;
    if (defaultValues) {
      const { thumbnail, ...putBody } = data;
      const updateResponse = await updateDemo(defaultValues.id, putBody);
      ok = updateResponse.ok;
      if (thumbnail !== defaultValues?.thumbnail && uploadedFile) {
        const updateThumbnailResponse = await updateThumbnail(
          defaultValues.id,
          transformToThumbnail(thumbnail, uploadedFile.file),
        );
        ok = updateThumbnailResponse.ok;
      }
    } else {
      if (uploadedFile) {
        const newDemoResponse = await uploadNewDemo({
          ...data,
          thumbnail: transformToThumbnail(thumbnail, uploadedFile.file),
        });
        ok = newDemoResponse.ok;
      }
    }
    if (ok) {
      await getList();
      onClose();
    }
    setSaving(false);
  };

  const setFileDataUrl = (dataUrl: DataUrl) => {
    setThumbnail(dataUrl);
    setValue('thumbnail', dataUrl);
    if (errors.thumbnail) {
      trigger('thumbnail');
    }
  };

  const getInputProps = (name: FaqFieldNames) => ({
    name,
    control,
    label: t(`formFields:${name}`),
    error: errors[name],
    sm: 6,
  });

  return (
    <>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1, pb: 0 } }}>
        <Grid container spacing={2}>
          <ControlledSelect
            {...getInputProps('section')}
            md={4}
            options={[...generalOptions, ...productsOptions]}
            groupedOptions={{ general: generalOptions, products: productsOptions }}
          />
          <ControlledSelect
            {...getInputProps('language')}
            md={4}
            options={languageOptions}
          />
          <ControlledTextInput {...getInputProps('name')} md={4} />
          <ControlledTextInput {...getInputProps('howdyGoLink')} md={8} />
          <Grid item xs={12}>
            <InputLabel htmlFor="my-input" error={!!errors.thumbnail}>
              {t('thumbnail')}
            </InputLabel>
            <UploadPhoto
              setFileDataUrl={setFileDataUrl}
              setUploadedFile={setUploadedFile}
              fileDataUrl={thumbnail}
              uploadedFile={uploadedFile}
              clearFile={clearFile}
              maxHeight={'150px'}
            />
            {errors.thumbnail && (
              <FormHelperText id="my-helper-text" error={!!errors.thumbnail}>
                {t('validation:required', { field: 'thumbnail' })}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={onClose}>
          {t('common:close')}
        </Button>
        <Button disableElevation onClick={handleSubmit(onSubmit)} loading={saving}>
          {t('common:save')}
        </Button>
      </DialogActions>
    </>
  );
};

const UploadFaqDemoModal = ({ onClose, getList, defaultValues }: Props) => {
  const { t } = useTranslation('modals');
  return (
    <ModalWrapper open onClose={onClose} fullWidth maxWidth={'md'}>
      <ModalTitle
        title={t(defaultValues ? 'updateDemo.title' : 'uploadFaqDemo.title')}
        closeModal={onClose}
      />
      <ModalContent onClose={onClose} getList={getList} defaultValues={defaultValues} />
    </ModalWrapper>
  );
};

export default UploadFaqDemoModal;
