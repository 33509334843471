import { PropsWithChildren } from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import { ClearIcon } from 'icons';
import { closeModalButtonStyles } from './styles';

interface Props extends PropsWithChildren {
  title?: string;
  closeModal?: () => void;
  disableClosing?: boolean;
  noMinWidth?: boolean;
}

const ModalTitle = ({
  title,
  children,
  closeModal,
  disableClosing,
  noMinWidth,
}: Props) => (
  <DialogTitle
    sx={{
      minWidth: noMinWidth ? undefined : 600,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    variant="h4"
  >
    {title}
    {children}

    {closeModal && (
      <IconButton disabled={disableClosing} onClick={closeModal}>
        <ClearIcon sx={closeModalButtonStyles} />
      </IconButton>
    )}
  </DialogTitle>
);
export default ModalTitle;
