import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { sortTypeOptions } from 'formData/common/selectOptions';
import { SetState } from 'types/setState';
import { SortDirection } from 'utils/sortUtils/types';
import DateRange from 'components/DateRange';
import { DateRangeValues } from 'components/DateRange/types';
import { Select } from 'components/inputs';

interface Props {
  sortBy: SortDirection;
  setSortBy: SetState<SortDirection>;
  dateRange: DateRangeValues;
  setDateRange: SetState<DateRangeValues>;
}

const HistoryToolbar = ({ sortBy, setSortBy, dateRange, setDateRange }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'selectInput' });

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12} sm={6} xl={4}>
        <Select
          label={t('label.sortBy')}
          options={sortTypeOptions}
          value={sortBy}
          onChange={(value) => setSortBy(value as SortDirection)}
        />
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <DateRange
          setValues={setDateRange}
          values={dateRange}
          label={t('label.eventDate')}
          placeholder={t('placeholder.dateRange')}
        />
      </Grid>
    </Grid>
  );
};

export default HistoryToolbar;
