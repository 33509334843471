import StorageKeys from 'consts/storage';
import { namespaces } from 'i18n';
import Cookies from 'js-cookie';
import getObjectPaths from 'utils/object/getObjectPaths';
import { firstCharLowerCase } from 'utils/string';
import { DocumentType } from 'api/documents/types';
import { BACKEND_ADDRESS } from './const';
import { ApiCustomErrorMessage, CustomErrorMessageBody, UrlParams } from './types';

export const getSearchParamsUrl = (url: string, params: UrlParams) => {
  const urlSearch = new URLSearchParams();

  Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .forEach(([name, value]) => {
      urlSearch.append(name, String(value));
    });

  const searchParams = urlSearch.toString();

  if (searchParams.length) return `${url}?${searchParams}`;
  return url;
};

export const formatPropertyName = (propertyName: string) => {
  const keys = getObjectPaths(namespaces.formFields);
  const field = keys.find((key) => key.toLowerCase() === propertyName.toLowerCase());
  if (!field) {
    console.warn(propertyName, 'Field was not found in translations');
  }

  return field ?? propertyName.length
    ? propertyName.split('.').map(firstCharLowerCase).join('.')
    : '';
};

export const getBaseUrl = (
  businessId = Cookies.get(StorageKeys.ImpersonatedBusinessId),
) => {
  const id = businessId || localStorage.getItem(StorageKeys.BusinessId);
  return `${BACKEND_ADDRESS}/businesses/${id}`;
};

export const getDocumentsTypeSearchParam = (typesToFilter?: DocumentType[]) => {
  if (!typesToFilter) return '';
  const params = new URLSearchParams();
  params.append('types', typesToFilter.join(','));
  return '?' + params.toString();
};

const matchesCustomError = (
  customError: CustomErrorMessageBody,
  status: number,
  errorCode?: string,
) => {
  if (customError.errorCode) {
    return customError.errorCode === errorCode && customError.status === status;
  }
  return customError.status === status;
};

export const getCustomErrorMessage = (
  customError: ApiCustomErrorMessage | undefined,
  status: number,
  errorCode: string | undefined,
) => {
  if (!customError) return;

  let errorMessage: string | undefined;
  if (Array.isArray(customError)) {
    errorMessage = customError.find((msg) =>
      matchesCustomError(msg, status, errorCode),
    )?.text;
  } else if (matchesCustomError(customError, status, errorCode)) {
    errorMessage = customError.text;
  }

  return errorMessage;
};
