import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import CardRow, { AccountNumberRow, AddressRow } from 'templates/CardRow';
import { vatToString } from 'utils/vat';
import { LegalPersonViewInterface } from 'api/person/legal/types';

const LegalPersonDetailsCard = ({ data: person }: LegalPersonViewInterface) => {
  const { t } = useTranslation(['common', 'formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.person.basicInfo')} />
      <CardRow
        title={t('formFields:language')}
        value={t(`enums:language.${person.language}`)}
        testId="language"
      />
      <AddressRow address={person.address} testId="legalPersonAddress" />
      {person.vat?.number && (
        <CardRow
          title={t('formFields:vat')}
          value={vatToString(person.vat)}
          testId="vat"
        />
      )}
      <AccountNumberRow iban={person.iban} bic={person.bic} />
    </Card>
  );
};

export default LegalPersonDetailsCard;
