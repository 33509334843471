import { createContext, PropsWithChildren } from 'react';
import { Modify } from 'types/modify';
import { ProductChosenParties, ProductName } from 'api/dossier/products/types';

interface ProductContextInterface {
  productId: string;
  productName: ProductName;
  fetchProduct: () => Promise<void>;
  chosenParties: ProductChosenParties<ProductName> | null;
  reportedErrors: string[] | null;
}

type ProductProviderProps = PropsWithChildren<
  Modify<ProductContextInterface, { fetchProduct?: () => Promise<void> }>
>;

const ProductContext = createContext<ProductContextInterface>({
  productId: '',
  productName: '' as ProductName,
  fetchProduct: async () => {},
  chosenParties: null,
  reportedErrors: null,
});

const ProductContextProvider = ({
  children,
  fetchProduct = async () => {},
  ...props
}: ProductProviderProps) => (
  <ProductContext.Provider value={{ ...props, fetchProduct }}>
    {children}
  </ProductContext.Provider>
);

export { ProductContext, ProductContextProvider };
