import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ibanMask, trimMask } from 'formData/utils';
import useBicFromIban from 'hooks/useBicFromIban';
import useFormMethods from 'hooks/useFormMethods';
import i18n from 'i18n';
import { HandleBlurChange } from 'types/handleChange';
import { ControlledMaskedInput } from 'components/inputs';
import InputWrapper from 'components/inputs/InputWrapper';
import { InputWrapperProps } from 'components/inputs/InputWrapper/types';

interface Props {
  prefix?: string;
  disabled?: boolean;
}

const AccountNumberField = ({ prefix, disabled }: Props) => {
  const { registerController, setValue } = useFormMethods(prefix);
  const { getBicFromIban, loadingBic } = useBicFromIban();
  const { t } = useTranslation('formFields');

  const onIbanBlur: HandleBlurChange = async (e) => {
    const iban = e.target.value;
    const bic = await getBicFromIban(iban);
    setValue('bic', bic);
  };

  return (
    <Grid item container spacing={1}>
      <ControlledMaskedInput
        xs={8}
        {...ibanMask}
        {...registerController('iban')}
        label={t('iban')}
        disabled={disabled}
        onBlur={onIbanBlur}
      />
      <ControlledMaskedInput
        xs={4}
        {...trimMask}
        {...registerController('bic')}
        label={t('bic')}
        loading={loadingBic}
        disabled={disabled}
      />
    </Grid>
  );
};

const Wrapper = (props: Props & Pick<InputWrapperProps, 'labelVariant'>) =>
  InputWrapper(AccountNumberField, {
    ...props,
    wrapWithGrid: false,
    label: i18n.t('formFields:accountNumber'),
  });

export default Wrapper;
