import { useMemo, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import useTableFiltersState from './useTableFiltersState';

const useTableDebouncedFilter = (field: string) => {
  const [filters, setFilters] = useTableFiltersState();

  const filter = useMemo(() => filters.find(({ name }) => name === field), [filters]);

  const [inputValue, setInputValue] = useState(filter?.value ?? '');
  const debouncedInputValue = useDebounce(inputValue, 500);

  const onFilterChange = (input: string) => {
    if (filter && filter.value !== input) {
      const updatedFilters = filters.map((item) =>
        item.name === field ? { ...item, value: input } : item,
      );
      setFilters(updatedFilters);
    } else if (!filter) {
      setFilters([...filters, { name: field, value: input }]);
    }
  };

  useUpdateEffect(() => {
    onFilterChange(debouncedInputValue);
  }, [debouncedInputValue]);

  useUpdateEffect(() => {
    // Clears input when filter param for this field is empty
    if (inputValue && !filter?.value) {
      setInputValue('');
    }
  }, [filter?.value]);

  const clearValue = () => {
    setInputValue('');
    onFilterChange('');
  };

  return { value: inputValue, setValue: setInputValue, clearValue };
};

export default useTableDebouncedFilter;
