import { useContext, useMemo } from 'react';
import { DropzoneProps } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { ConfigContext } from 'contexts/config/ConfigContext';
import getDocumentConfigData from 'utils/getDocumentConfigData';
import Dropzone from 'components/Dropzone';
import DocumentFields from './DocumentFields';
import { DocumentProps } from './types';
import {
  getDocumentOptionsToDisplay,
  getDropzoneConfig,
  getFilteredDocumentTypeOptions,
  isDocumentFull,
} from './utils';

const Document = ({
  document,
  index,
  formMethods,
  documentActions: { removeDocument, updateDocument },
  fieldsConfig: {
    requiredDocumentTypes,
    isUploadingMissingDocuments,
    excludedDocumentTypes,
  },
  onFilePreview,
  onClosePreview,
  isPreviewOpen,
  onDocumentTypeChange,
}: DocumentProps) => {
  const { documentsConfig } = useContext(ConfigContext);

  const watchedDocuments = useWatch({
    control: formMethods.control,
    name: 'documents',
  });

  const watchedDocument = useMemo(
    () => watchedDocuments.at(index),
    [watchedDocuments, index],
  );

  const documentConfigData = useMemo(
    () =>
      getDocumentConfigData(
        documentsConfig,
        document.scope,
        watchedDocument?.documentType ?? null,
      ),
    [document.scope, documentsConfig, watchedDocument?.documentType],
  );

  const filteredDocumentsOptions = useMemo(() => {
    const options = getFilteredDocumentTypeOptions(
      documentsConfig,
      document.scope,
      excludedDocumentTypes,
    );

    return !isUploadingMissingDocuments
      ? getDocumentOptionsToDisplay(options, index, watchedDocuments)
      : options;
  }, [watchedDocuments, index, excludedDocumentTypes]);

  const onDrop: DropzoneProps['onDrop'] = (acceptedFiles) => {
    updateDocument(document.id, { files: [...document.files, ...acceptedFiles] });
    if (isPreviewOpen) {
      const lastFile = acceptedFiles[acceptedFiles.length - 1];
      onFilePreview(lastFile);
    }
  };

  const isDocumentTypeRequired = useMemo(
    () =>
      !!watchedDocument?.documentType &&
      !!requiredDocumentTypes?.includes(watchedDocument.documentType),
    [watchedDocument?.documentType, requiredDocumentTypes],
  );

  const disabledInputs = useMemo(
    () => ({
      documentName: !watchedDocument?.documentType,
      date: !watchedDocument?.documentType,
    }),
    [watchedDocument?.documentType],
  );

  const isDropzoneDisabled = useMemo(
    () =>
      watchedDocument?.status === 'SAVED' ||
      watchedDocument?.status === 'PENDING' ||
      !watchedDocument?.documentType ||
      isDocumentFull(watchedDocument.files, documentConfigData),
    [watchedDocument, documentConfigData],
  );

  return (
    <Dropzone
      showIcon={false}
      onDrop={onDrop}
      dropzoneConfig={getDropzoneConfig(documentConfigData)}
      disabled={isDropzoneDisabled}
      warning={isDocumentTypeRequired ? 'uploadingRequiredDocument' : undefined}
    >
      <DocumentFields
        document={document}
        index={index}
        formMethods={formMethods}
        documentActions={{ updateDocument, removeDocument }}
        fieldsConfig={{
          documentConfigData,
          documentTypeOptions: filteredDocumentsOptions,
          disabledInputs,
          onFilePreview,
          isUploadingMissingDocuments,
        }}
        onClosePreview={onClosePreview}
        onDocumentTypeChange={onDocumentTypeChange}
      />
    </Dropzone>
  );
};

export default Document;
