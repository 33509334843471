import { documentsColumns } from 'templates/DocumentsTable/static-data';
import { DocumentsColumn, GetDocumentActions } from 'templates/DocumentsTable/types';
import {
  handleDeleteDocument,
  handleDownloadDocument,
} from 'templates/DocumentsTable/utils';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { DossierStatus } from 'api/dossier/types';
import { DossierTableDocumentData, HandleOpenDocumentsModal } from './types';

export const getDossierDocumentActions =
  (
    dossierStatus: DossierStatus,
    openDocumentsModal: HandleOpenDocumentsModal,
  ): GetDocumentActions<DossierTableDocumentData> =>
  ({ document, entityId, scope, editable, turnEditMode }) => {
    if (document.isMissing) {
      return [
        {
          name: 'upload',
          onClick: () => openDocumentsModal(document.documentType),
        },
      ];
    }

    const hidden =
      (document.source && document.source !== 'Internal') ||
      document.documentClassification !== 'Document';

    return [
      {
        name: 'download',
        hidden: document.documentClassification !== 'Document',
        onClick: handleDownloadDocument(document, entityId, scope),
        disabled: document.actionsDisabled,
      },
      {
        name: 'edit',
        hidden: hidden || !editable,
        disabled:
          !shouldDossierFeatureBeActive(dossierStatus) || document.actionsDisabled,
        onClick: turnEditMode,
      },
      {
        name: 'delete',
        hidden,
        disabled:
          !shouldDossierFeatureBeActive(dossierStatus) ||
          document.sentOut ||
          document.actionsDisabled,
        onClick: handleDeleteDocument(document, entityId, scope, 'dossier'),
      },
    ];
  };

export const dossierDocumentsColumns: DocumentsColumn[] = [
  {
    name: 'attachedToDossier',
    headerTooltip: 'attachmentInformation',
  },
  ...documentsColumns,
];
