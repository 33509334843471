import {
  getLegalPersonRepresentatives,
  getPrivateRelation,
} from 'api/relations/requests';
import { PartyOption, PartyPerson, ProductParty } from './types';

export const getEmptyField = (index = 0): ProductParty => ({ id: '', index });

export const getPersonsOptions = (
  persons: PartyPerson[],
  filterIds?: (string | undefined)[],
): PartyOption[] =>
  persons
    .map(({ name, address, ...person }) => ({
      label: name,
      value: person.id,
      caption: address,
      ...person,
    }))
    .filter(({ value }) => !filterIds?.includes(value));

export const fetchRepresentatives = async (
  personId: string,
): Promise<PartyPerson[] | undefined> => {
  const { ok, response } = await getLegalPersonRepresentatives(personId);
  if (ok) {
    return response.map(({ representative }) => ({
      ...representative,
      personId: representative.id,
    }));
  }
};

export const fetchPrivateRelation = async (
  personId: string,
): Promise<PartyPerson | undefined> => {
  const { ok, response: relation } = await getPrivateRelation(personId);
  if (ok && relation) {
    return {
      ...relation.relative,
      id: relation.relative.id,
      relation: relation.type,
    };
  }
};

export const getSecondaryFieldRelation = (
  primaryContact: ProductParty,
  partnerId: string | undefined,
): ProductParty['relation'] => {
  if (primaryContact.id === partnerId) {
    return primaryContact.relation;
  }

  if (
    !partnerId &&
    !primaryContact.hasPrivateRelation &&
    primaryContact.personType === 'NaturalPerson'
  ) {
    return 'Other';
  }

  return null;
};

const getPartnerOption = (
  persons: PartyPerson[],
  partner?: PartyPerson,
): ProductParty['partnerOption'] =>
  partner && !persons.some(({ id }) => id === partner.id) ? partner : undefined;

export const updateSecondaryField = (
  persons: PartyPerson[],
  primaryContact: ProductParty,
  initSecondaryContact?: ProductParty,
) => {
  const partner = primaryContact.partner;
  let secondaryContact = initSecondaryContact;

  // set primaryContact's partner data when secondaryContact is not selected
  if (!secondaryContact?.id && partner) {
    secondaryContact = {
      ...secondaryContact,
      id: partner.id,
      personType: partner.type,
      partnerId: primaryContact.id,
    };
  }

  return {
    ...secondaryContact,
    id: secondaryContact?.id ?? '',
    relation: getSecondaryFieldRelation(primaryContact, secondaryContact?.partnerId),
    partnerOption: getPartnerOption(persons, partner),
  };
};
