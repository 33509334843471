import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import MaskedInput from './MaskedInput';
import { ControlledMaskedInputProps } from './types';

const ControlledMaskedInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledMaskedInputProps<T, N>,
) => React.ReactElement = ({
  name,
  error,
  warning,
  helperText,
  control,
  onChange: handleChange,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, ...field } }) => (
      <MaskedInput
        value={value ?? ''}
        onChange={(value) => {
          onChange(value || null);
          if (handleChange) {
            handleChange(value);
          }
        }}
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...field}
        {...inputProps}
      />
    )}
  />
);

export default ControlledMaskedInput;
