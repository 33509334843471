import { Navigate, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';
import { estateTabRoutes } from 'router/routes';
import {
  EstateDocumentsTab,
  EstateDossiersTab,
  EstateHistoryTab,
  EstateInfoTab,
} from 'pages/Estates/Details/tabs';

export const estateTabs: RouteObject[] = [
  { path: estateTabRoutes.estateInfo, element: <EstateInfoTab /> },
  { path: estateTabRoutes.dossiers, element: <EstateDossiersTab /> },
  { path: estateTabRoutes.documents, element: <EstateDocumentsTab /> },
  {
    path: estateTabRoutes.history,
    element: (
      <ProtectedRoute featureFlag="HistoryLogging" fallback={estateTabRoutes.estateInfo}>
        <EstateHistoryTab />
      </ProtectedRoute>
    ),
  },
  { path: '*', element: <Navigate to={estateTabRoutes.estateInfo} /> },
];
