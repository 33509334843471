export default {
  // GENERIC
  myMoveRedirection: '/myMove/directLogin',
  checknetRedirection: '/checknet/directLogin',

  // VERSIONS
  version: '/version',

  // EXTERNAL SOFTWARES
  getThirdPartySoftwareConnection: '/getThirdPartySoftwareConnection',
  connectThirdPartySoftware: '/establishThirdPartySoftwareConnection',
};
