import { useCallback, useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid } from '@mui/material';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import PageCard from 'templates/PageCard';
import sortByOrder from 'utils/object/sortByOrder';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { apiUrl } from 'api/const';
import { getCustomTodoItems, updateCustomTodoList } from 'api/dossier/requests';
import { CustomDossierTodoItem, DossierStatus } from 'api/dossier/types';
import { CustomToDoItemModal } from './CustomToDoItemModal';
import { CustomTodoListItem } from './CustomTodoListItem';
import { reorderTodoList } from './utils';

interface Props {
  dossierId: string;
  dossierStatus: DossierStatus;
}

export const CustomTodoList = ({ dossierId, dossierStatus }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [toDos, setToDos] = useState<CustomDossierTodoItem[]>([]);
  const [editingToDo, setEditingToDo] = useState<CustomDossierTodoItem | null>(null);

  const fetchCustomTodoItems = useCallback(async () => {
    const { ok, response } = await getCustomTodoItems(dossierId);
    if (ok && response) {
      setToDos(response.sort(sortByOrder));
    }
  }, [dossierId]);

  useEffect(() => {
    fetchCustomTodoItems();
  }, [fetchCustomTodoItems]);

  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const handleDelete = (id: string) => () => {
    setUpAndOpenConfirmationModal({
      translationKey: 'dossierCustomTodoItem',
      url: apiUrl.customTodoItem(dossierId, id),
      onSuccess: fetchCustomTodoItems,
    });
  };

  const handleEdit = (todo: CustomDossierTodoItem) => () => {
    setEditingToDo(todo);
    setOpenModal(true);
  };

  const handleComplete = (id: string) => () => {
    setToDos((prev) =>
      prev.map((toDo) =>
        toDo.id === id ? { ...toDo, completed: !toDo.completed } : toDo,
      ),
    );
  };

  const closeModal = () => {
    setOpenModal(false);
    setEditingToDo(null);
  };

  const updateListOrder = (itemIndex: number, destinationIndex: number) => {
    if (itemIndex !== destinationIndex) {
      const newTodoList = reorderTodoList(toDos, itemIndex, destinationIndex);
      setToDos(newTodoList);
      updateCustomTodoList(
        dossierId,
        newTodoList.map(({ id, order }) => ({ id, order })),
      );
    }
  };

  return (
    <>
      <PageCard
        tKey="tasks"
        blank={!toDos?.length}
        headerProps={{
          actionProps: {
            onClick: () => setOpenModal(true),
            hidden: !shouldDossierFeatureBeActive(dossierStatus),
          },
        }}
      >
        {!!toDos?.length && (
          <DndProvider backend={HTML5Backend}>
            <Grid container rowSpacing={0.5}>
              {toDos.map((toDo, index) => (
                <CustomTodoListItem
                  key={toDo.id}
                  toDo={toDo}
                  handleComplete={handleComplete}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  index={index}
                  updateListOrder={updateListOrder}
                  dossierId={dossierId}
                />
              ))}
            </Grid>
          </DndProvider>
        )}
      </PageCard>
      {openModal && (
        <CustomToDoItemModal
          fetchCustomTodoItems={fetchCustomTodoItems}
          editingToDo={editingToDo}
          setToDos={setToDos}
          closeModal={closeModal}
          dossierId={dossierId}
        />
      )}
    </>
  );
};
