import { useCallback, useMemo, useState } from 'react';
import compressToDataUrl from 'utils/compressToDataUrl';
import Dropzone from 'components/Dropzone';
import ProcessImageUpload from './components/ProcessImageUpload';
import { MAX_PHOTO_SIZE } from './static-data';
import { UploadPhotoProps } from './types';

export const UploadPhoto = ({
  fileDataUrl,
  setFileDataUrl,
  setUploadedFile,
  submitting = false,
  uploadedFile,
  clearFile,
  showStatus,
  maxHeight,
}: UploadPhotoProps) => {
  const [loading, setLoading] = useState(false);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setLoading(true);
    setFileDataUrl(null);
    if (acceptedFiles.length) {
      compressToDataUrl(acceptedFiles[0], (dataUrl) => {
        setFileDataUrl(dataUrl);
        setUploadedFile({ file: acceptedFiles[0], status: 'PENDING' });
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const fileStatus = useMemo(() => {
    if (submitting && fileDataUrl) {
      return 'PENDING';
    } else if (uploadedFile?.status === 'ERROR') {
      return uploadedFile.status;
    }
    return undefined;
  }, [uploadedFile, fileDataUrl, submitting]);

  return (
    <Dropzone
      onDrop={handleDrop}
      dropzoneConfig={{
        maxSize: MAX_PHOTO_SIZE,
        maxFiles: 1,
        multiple: false,
        accept: { 'image/jpeg': [], 'image/png': [] },
      }}
      loading={loading}
      type="photo"
      showIcon={!uploadedFile}
    >
      {uploadedFile && fileDataUrl && (
        <ProcessImageUpload
          file={uploadedFile.file}
          onRemove={clearFile}
          status={fileStatus}
          imageUrl={fileDataUrl.toString()}
          showStatus={showStatus}
          maxHeight={maxHeight}
        />
      )}
    </Dropzone>
  );
};
