import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { UNITS } from 'consts/units';
import useFormMethods from 'hooks/useFormMethods';
import { ControlledNumberInput, ControlledSelect } from 'components/inputs';
import { fireInsuranceSelectorOptions } from './static-data';
import { EstateForFireInsuranceForm, FireInsuranceSelectorOption } from './types';

const EstateDetailsAdditionalDataForm = () => {
  const { t } = useTranslation('formFields');
  const { setValue, registerController } = useFormMethods<EstateForFireInsuranceForm>();

  const onAtticChange = (value: FireInsuranceSelectorOption) => {
    switch (value) {
      case 'yes':
        setValue('estateDetails.estateComposition.attics', 1);
        setValue('estateDetails.estateComposition.unfurnishedAttic', null);
        break;
      case 'no':
        setValue('estateDetails.estateComposition.attics', 0);
        setValue('estateDetails.estateComposition.unfurnishedAttic', null);
        break;
    }
  };

  const onBasementChange = (value: FireInsuranceSelectorOption) => {
    switch (value) {
      case 'yes':
        setValue('estateDetails.estateComposition.basements', 1);
        setValue('estateDetails.estateComposition.unfurnishedCellar', null);
        break;
      case 'no':
        setValue('estateDetails.estateComposition.basements', 0);
        setValue('estateDetails.estateComposition.unfurnishedCellar', null);
        break;
    }
  };

  const onExtraBuildingsChange = (value: FireInsuranceSelectorOption) => {
    switch (value) {
      case 'yes':
        setValue('estateDetails.estateComposition.outhouses', 1);
        setValue('estateDetails.estateComposition.unfurnishedOutbuildings', null);
        setValue('estateDetails.estateComposition.gardenSheds', null);
        break;
      case 'no':
        setValue('estateDetails.estateComposition.outhouses', 0);
        setValue('estateDetails.estateComposition.unfurnishedOutbuildings', null);
        setValue('estateDetails.estateComposition.gardenSheds', null);
        break;
    }
  };

  return (
    <Stack spacing={4}>
      {
        <ControlledNumberInput
          {...registerController('estateDetails.habitableSurface')}
          endAdornment={UNITS.squareMeter}
          labelVariant="separate"
        />
      }
      <Stack spacing={2}>
        <ControlledSelect
          {...registerController('estateDetails.attic')}
          options={fireInsuranceSelectorOptions}
          onChange={onAtticChange}
          label={t('fireInsuranceSelectors.attic')}
          labelVariant="separate"
        />
        <ControlledSelect
          {...registerController('estateDetails.basement')}
          options={fireInsuranceSelectorOptions}
          label={t('fireInsuranceSelectors.basement')}
          labelVariant="separate"
          onChange={onBasementChange}
        />
        <ControlledSelect
          {...registerController('estateDetails.extraBuildings')}
          options={fireInsuranceSelectorOptions}
          onChange={onExtraBuildingsChange}
          labelVariant="separate"
          label={t('fireInsuranceSelectors.extraBuildings')}
        />
      </Stack>
    </Stack>
  );
};

export default EstateDetailsAdditionalDataForm;
