import { SortDirection } from './types';

// from frontend to backend

const sortModelToString = ({
  field,
  direction,
}: {
  field: string;
  direction: SortDirection | null;
}): string => (direction ? `${field}:${direction}` : '');

export default sortModelToString;
