import { useCallback, useEffect, useState } from 'react';
import { DocumentData, DocumentScope } from 'api/documents/types';
import { getDocuments } from './utils';

export const useDocumentsData = (
  entityId: string,
  scope: Exclude<DocumentScope, 'dossier'>,
) => {
  const [documentsData, setDocumentsData] = useState<DocumentData[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchDocuments = useCallback(async () => {
    setLoading(true);
    const { ok, response } = await getDocuments[scope](entityId);
    if (ok && response) {
      setDocumentsData(response);
    }
    setLoading(false);
  }, [entityId, scope]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return { documentsData, fetchDocuments, loading };
};
