import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, TableCell } from '@mui/material';
import { DocumentExpanderProps } from './types';

const DocumentExpanderCell = ({
  expanded,
  toggleExpand,
  document,
}: DocumentExpanderProps) => (
  <TableCell sx={({ mixins }) => mixins.actionCellStyles}>
    <IconButton
      onClick={toggleExpand}
      size="small"
      disabled={!document.files.length || document.documentClassification !== 'Document'}
    >
      {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
    </IconButton>
  </TableCell>
);

export default DocumentExpanderCell;
