import i18n from 'i18n';

export const getFirstAndLastVisibleRow = (
  /** The zero-based index of the current page. */
  page: number,
  pageSize: number,
  totalRecords: number,
) => {
  const first = page * pageSize + 1;
  const last = page * pageSize + pageSize;

  return { first, last: totalRecords < last ? totalRecords : last };
};

export const getDisplayedRowsLabel = (
  page: number,
  pageSize: number,
  totalRecords: number,
) => {
  const { first, last } = getFirstAndLastVisibleRow(page, pageSize, totalRecords);
  return i18n.t('table:pagination.pageReport', { first, last, totalRecords });
};

export const getPagesToRender = (currentPage: number, lastPage: number) => {
  const allPages = new Array(lastPage).fill(1).map((page, index) => page + index);

  if (lastPage <= 5) {
    // lastPage === 1 -> [1]
    // lastPage === 2 -> [1,2]
    // lastPage === 3 -> [1,2,3]
    // lastPage === 4 -> [1,2,3,4]
    // lastPage === 5 -> [1,2,3,4,5]
    return allPages;
  }

  if (currentPage <= 3) {
    // [1,2,3,4,5]
    return allPages.slice(0, 5);
  }

  if (currentPage >= lastPage - 2) {
    // currentPage === 6 && lastPage === 8 -> [4,5,6,7,8]
    // currentPage === 7 && lastPage === 8 -> [4,5,6,7,8]
    // currentPage === 8 && lastPage === 8 -> [4,5,6,7,8]
    return allPages.slice(lastPage - 5, lastPage);
  }

  // currentPage === 5 && lastPage === 10 -> [3,4,5,6,7]
  return allPages.slice(currentPage - 3, currentPage + 2);
};
