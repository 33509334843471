import { useOutletContext } from 'react-router-dom';
import HistoryOfChanges from 'templates/HistoryOfChanges';
import { ESTATE_HISTORY_ACTIVITIES } from 'templates/HistoryOfChanges/static-data';
import { getEstateHistory } from 'api/estate/requests';
import { EstateViewInterface } from 'api/estate/types';

const EstateHistoryTab = () => {
  const { id } = useOutletContext<EstateViewInterface>();

  return (
    <HistoryOfChanges
      getHistory={getEstateHistory}
      entityId={id}
      activities={ESTATE_HISTORY_ACTIVITIES}
    />
  );
};

export default EstateHistoryTab;
