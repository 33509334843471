import { useTranslation } from 'react-i18next';
import { Toolbar, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { tableToolbarStyles } from './styles';
import { DocumentsToolbarProps } from './types';

const DocumentsToolbar = ({ titleProps, actionProps }: DocumentsToolbarProps) => {
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        ...tableToolbarStyles,
        justifyContent: titleProps?.title ? 'space-between' : 'flex-end',
      }}
    >
      {titleProps?.title && (
        <Typography
          sx={({ palette }) => ({ flex: '1 1 100%', color: palette.common.white })}
          variant="h5"
        >
          {titleProps.title}
        </Typography>
      )}
      {!actionProps?.hidden && (
        <Button size="small" {...actionProps}>
          {t('add')}
        </Button>
      )}
    </Toolbar>
  );
};

export default DocumentsToolbar;
