import { createContext, useState } from 'react';
import { areRelationsEqual } from 'modules/Relation/utils';
import transformName from 'utils/transformName';
import { NaturalPersonFormData } from 'api/person/natural/types';
import {
  NaturalPersonFormContextInterface,
  NaturalPersonFormProviderProps,
  PersonForRelationUpdateInterface,
} from './types';
import {
  updateOwnerships,
  updatePrivateRelation,
  updateRepresentatives,
  updateRepresentedParties,
} from './utils';

export const NaturalPersonFormContext = createContext<NaturalPersonFormContextInterface>({
  personId: null,
  representativesToPreview: [],
  setRepresentativesToPreview: () => {},
  representedPartiesToPreview: [],
  setRepresentedPartiesToPreview: () => {},
  ownershipsToPreview: [],
  setOwnershipsToPreview: () => {},
  privateRelation: null,
  setPrivateRelation: () => {},
  privateRelationType: 'LegallyLivingTogether',
  setPrivateRelationType: () => {},
  saveRelations: async () => false,
});

export const NaturalPersonFormProvider = ({
  children,
  personId = null,
  defaultOwnerships = [],
  defaultRepresentatives = [],
  defaultRepresentedParties = [],
  defaultPrivateRelation = null,
  defaultPrivateRelationType = 'LegallyLivingTogether',
}: NaturalPersonFormProviderProps) => {
  const [privateRelation, setPrivateRelation] = useState(defaultPrivateRelation);
  const [privateRelationType, setPrivateRelationType] = useState(
    defaultPrivateRelationType,
  );
  const [representedPartiesToPreview, setRepresentedPartiesToPreview] = useState(
    defaultRepresentedParties,
  );
  const [representativesToPreview, setRepresentativesToPreview] =
    useState(defaultRepresentatives);
  const [ownershipsToPreview, setOwnershipsToPreview] = useState(defaultOwnerships);

  const saveRelations = async (personId: string, data: NaturalPersonFormData) => {
    let ok = true;

    if (
      privateRelation?.id !== defaultPrivateRelation?.id ||
      privateRelationType !== defaultPrivateRelationType
    ) {
      const { relation, ok: isPrivateRelationSaved } = await updatePrivateRelation(
        personId,
        privateRelation,
        privateRelationType,
        defaultPrivateRelation,
      );

      setPrivateRelation(relation);
      ok = isPrivateRelationSaved;
    }

    const person: PersonForRelationUpdateInterface = {
      id: personId,
      name: transformName(data),
      type: 'NaturalPerson',
    };

    if (!areRelationsEqual(representedPartiesToPreview, defaultRepresentedParties)) {
      const { allSaved } = await updateRepresentedParties(person)(
        representedPartiesToPreview,
        setRepresentedPartiesToPreview,
        true,
      );
      ok = allSaved;
    }

    if (!areRelationsEqual(representativesToPreview, defaultRepresentatives)) {
      const { allSaved } = await updateRepresentatives(person)(
        representativesToPreview,
        setRepresentativesToPreview,
        true,
      );
      ok = allSaved;
    }

    if (!areRelationsEqual(ownershipsToPreview, defaultOwnerships)) {
      const { allSaved } = await updateOwnerships(personId, 'NaturalPerson')(
        ownershipsToPreview,
        setOwnershipsToPreview,
        true,
      );
      ok = ok && allSaved;
    }
    return ok;
  };

  return (
    <NaturalPersonFormContext.Provider
      value={{
        personId,
        privateRelation,
        privateRelationType,
        setPrivateRelation,
        setPrivateRelationType,
        representativesToPreview,
        setRepresentativesToPreview,
        representedPartiesToPreview,
        setRepresentedPartiesToPreview,
        ownershipsToPreview,
        setOwnershipsToPreview,
        saveRelations,
      }}
    >
      {children}
    </NaturalPersonFormContext.Provider>
  );
};
