import { useTranslation } from 'react-i18next';
import { transformFormAddress } from 'formData/common/address/utils';
import EstateForm from 'modules/EstateForm';
import { estateToRelation } from 'modules/Relation/utils';
import addressToString from 'utils/addressToString';
import getLng from 'utils/getLng';
import { EstateFormData, FetchedEstateListItem } from 'api/estate/types';
import ModalTitle from 'components/modals/ModalTitle';
import { FormModal } from './FormModal';
import { FormModalProps } from './types';

export const AddEstateModal = ({
  open,
  closeModal,
  onSubmit,
}: FormModalProps<FetchedEstateListItem>) => {
  const { t } = useTranslation();

  const onSave = async (
    { address, ...data }: EstateFormData,
    id: string,
    isAddingAnotherEstate = false,
  ) => {
    const formattedAddress = transformFormAddress(address);
    const relationData = estateToRelation({
      id,
      address: addressToString(formattedAddress, { isFullAddress: false }),
      postCode: formattedAddress.postCode,
      city: formattedAddress.city[getLng()],
      owners: [],
      korfineReference: '',
      ...data,
    });
    onSubmit(relationData);
    if (!isAddingAnotherEstate) {
      closeModal();
    }
  };

  return (
    <FormModal open={open} closeModal={closeModal}>
      <ModalTitle title={t('stepWizard.estate.title')} closeModal={closeModal} />
      <EstateForm
        handleSave={onSave}
        handleCancel={closeModal}
        hideOwners
        stepsToHide={['documents']}
      />
    </FormModal>
  );
};
