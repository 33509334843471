import { StepInterface } from './types';

export const filterSteps = (steps: StepInterface[], stepsToHide?: string[]) =>
  stepsToHide?.length ? steps.filter(({ name }) => !stepsToHide.includes(name)) : steps;

export const getStepsIndex = (steps: StepInterface[]) =>
  steps.reduce((prev, { name }, index) => ({ ...prev, [name]: index }), {}) as Record<
    string,
    number
  >;
