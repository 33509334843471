import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { gridClasses, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid';
import Button from 'atoms/Button';
import { FilterIcon } from 'icons';
import TableFilterSidebar from '../TableFilterSidebar';
import SelectedRowsButton from './SelectedRowsButton';
import { TableToolbarComponentProps } from './types';

const TableToolbar = <TRow extends GridValidRowModel>({
  tKey,
  filters,
  updateRows,
  rowSelection,
  Component,
}: TableToolbarComponentProps<TRow>) => {
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

  const apiRef = useGridApiContext();
  const selectedRows = apiRef.current.state.rowSelection;
  const { t } = useTranslation('table');

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1.5}
        className={gridClasses.toolbarContainer}
      >
        {!!selectedRows.length && (
          <Typography color="grey.500">
            {t(`${tKey}.header.selectedRows`, {
              count: selectedRows.length,
              defaultValue: t('header.selectedRows', { count: selectedRows.length }),
            })}
          </Typography>
        )}
        <Box display="flex" alignItems="center" ml="auto" gap={1.5}>
          {!!filters?.length && (
            <Button
              role="secondary"
              onClick={() => setIsFilterSidebarOpen(true)}
              startIcon={<FilterIcon />}
            >
              {t('header.filter')}
            </Button>
          )}
          {Component ? <Component /> : null}
          {rowSelection && !rowSelection.hideButton ? (
            <SelectedRowsButton updateRows={updateRows} rowSelection={rowSelection} />
          ) : null}
        </Box>
      </Box>
      {!!filters?.length && (
        <TableFilterSidebar
          tKey={tKey}
          isOpen={isFilterSidebarOpen}
          closeSidebar={() => setIsFilterSidebarOpen(false)}
          filters={filters}
        />
      )}
    </>
  );
};

export default TableToolbar;
