import { RentalConditions } from 'api/dossier/types';

export const formatTaxPayableByTenant = (tax: RentalConditions['taxPayableByTenant']) => {
  if (tax && tax.unit === 'Percent') {
    return `${tax.value}%`;
  } else if (tax) {
    return `${tax.value} ${tax.unit}`;
  }
  return null;
};
