import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { registerAddendumSchema } from 'formData/dossier/products/rentalContract/schema';
import StepWizard from 'templates/StepWizard';
import { StepInterface, StepperErrors } from 'templates/StepWizard/types';
import { resolver } from 'utils/yup';
import { getRentalContractData } from 'api/dossier/products/rentalContract/request';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { usePersonStep } from './steps/Contacts/usePersonStep';
import { useRentalConditionsStep } from './steps/RentalConditions/useRentalConditionsStep';
import { useUploadAddendum } from './steps/UploadAddendum/useUploadAddendum';
import { UseRegisterAddendumStepInterface } from './types';
import { getAddendumDefaultValues } from './utils';

const RegisterAddendum = ({ dossier, onClose }: ProductActionComponentProps) => {
  const [stepErrors, setStepErrors] = useState<StepperErrors>(null);

  const getStepProps = (
    type?: 'landlords' | 'tenants',
  ): UseRegisterAddendumStepInterface => ({
    onClose,
    setStepErrors,
    persons: type ? dossier[type] : [],
    dossierId: dossier.id,
  });

  const steps: StepInterface[] = [
    useRentalConditionsStep(getStepProps()),
    usePersonStep({ ...getStepProps('landlords'), sectionType: 'landlords' }),
    usePersonStep({ ...getStepProps('tenants'), sectionType: 'tenants' }),
    useUploadAddendum(getStepProps()),
  ];

  return (
    <StepWizard
      steps={steps}
      translationKey="registerAddendum"
      hideTitle
      errors={stepErrors}
      withDialogComponents
    />
  );
};

const RegisterAddendumWrapper = (props: ProductActionComponentProps) => {
  const methods = useForm<RegisterAddendumForm>({
    resolver: resolver(registerAddendumSchema),
  });

  const getData = async () => {
    const { ok, response } = await getRentalContractData(
      props.dossier.id,
      props.product.productId,
    );
    if (ok && response) {
      const defaultValues = getAddendumDefaultValues(response);
      methods.reset(defaultValues);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FormProvider {...methods}>
      <RegisterAddendum {...props} />
    </FormProvider>
  );
};

export default RegisterAddendumWrapper;
