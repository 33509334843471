import { useTableFilters } from 'templates/Table/hooks';
import { Select } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableSelectFilter = ({
  field,
  filterName = field,
  filterOptions = [],
  filterLabel,
}: TranslatedTableColumn) => {
  const { value, setValue } = useTableFilters(filterName);

  return (
    <Select
      placeholder={filterLabel}
      value={value}
      options={filterOptions}
      onChange={setValue}
      onClear={() => setValue('')}
      clearable
    />
  );
};

export default TableSelectFilter;
