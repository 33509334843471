import { useGridApiContext } from '@mui/x-data-grid';
import { TableFilter } from '../types';

type TableFiltersState = [TableFilter[], (value: TableFilter[]) => void];

const useTableFiltersState = (): TableFiltersState => {
  const apiRef = useGridApiContext();
  const filters = apiRef.current.state.filter.filterModel.quickFilterValues ?? [];
  const setFilters = apiRef.current.setQuickFilterValues;

  return [filters, setFilters];
};

export default useTableFiltersState;
