import { transformFormAddress } from 'formData/common/address/utils';
import { FetchedTableData } from 'templates/Table/types';
import { Modify } from 'types/modify';
import { transformAddress, transformShortAddress } from 'utils/transformAddressResponse';
import { FetchedAddress, FetchedShortAddress } from 'api/common/types';
import {
  EstateData,
  EstateFormData,
  FetchedEstateData,
  FetchedEstateList,
  FetchedEstateListItem,
} from './types';

export const transformEstate = ({
  photo: _photo,
  address,
  ...estate
}: EstateFormData): Omit<EstateData, 'referenceNumber'> => ({
  ...estate,
  address: transformFormAddress(address),
});

export const transformEstateResponse = (
  response: Modify<FetchedEstateData, { address: FetchedAddress }>,
): FetchedEstateData => ({
  ...response,
  address: transformAddress(response.address),
});

export const transformEstateListResponse = ({
  data,
  meta,
}: FetchedTableData<
  Omit<FetchedEstateListItem, 'address' | 'postCode' | 'city'> & FetchedShortAddress
>): FetchedEstateList => ({
  data: data.map(({ address, city, postCode, ...estate }) => ({
    ...estate,
    ...transformShortAddress({ address, city, postCode }),
  })),
  meta,
});
