import { transformFormAddress } from 'formData/common/address/utils';
import { NaturalPersonData, NaturalPersonFormData } from './types';

export const transformData = ({
  phone,
  address,
  taxResidences,
  taxResidenceBelgium,
  ...data
}: NaturalPersonFormData): Omit<NaturalPersonData, 'referenceNumber'> => ({
  ...data,
  address: transformFormAddress(address),
  phone: phone?.number ? { ...phone, number: phone.number.replace(/ /g, '') } : null,
  taxResidenceBelgium: taxResidenceBelgium ? taxResidenceBelgium === 'Belgium' : null,
  taxResidences:
    taxResidenceBelgium === 'Belgium'
      ? []
      : taxResidences.filter((tax) => tax?.taxIdentificationNumber),
});
