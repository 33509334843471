import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { ErrorIcon } from 'icons';
import { getLinkedTypographyStyles } from 'components/Dropzone/styles';
import { MissingRequiredFieldsErrorProps } from './types';

const MissingRequiredFieldsError = ({ tKey, to }: MissingRequiredFieldsErrorProps) => {
  const { t } = useTranslation('formFields');

  const navigate = useCustomNavigate();

  return (
    <Box display="flex" alignItems="center" gap={1.5} mt={1}>
      <ErrorIcon color="error" />
      <span>
        <Trans
          t={t}
          i18nKey={tKey}
          defaults="Could not save data. Required data is missing."
          components={{
            Link: (
              <Typography
                sx={getLinkedTypographyStyles(false)}
                onClick={() => navigate(to)}
                component="span"
              />
            ),
          }}
        />
      </span>
    </Box>
  );
};

export default MissingRequiredFieldsError;
