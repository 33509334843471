import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import CardRow from 'templates/CardRow';

const OwnReferenceCard = ({ ownReference }: { ownReference: string }) => {
  const { t } = useTranslation('formFields');

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow title={t('ownReference')} value={ownReference} />
    </Card>
  );
};

export default OwnReferenceCard;
