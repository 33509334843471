import { LoaderConfig } from 'hooks/useLoader/types';
import { getDossierDocuments } from 'api/dossier/requests';
import { DossierDocumentsData } from 'api/dossier/types';

export const getDossierDocumentsConfig = (
  dossierId: string,
): LoaderConfig<DossierDocumentsData> => ({
  id: dossierId,
  scope: 'dossierDocuments',
  fetch: getDossierDocuments,
  withContext: false,
});
