import { ComponentProps, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  ProductActionInterface,
  ProductInterface,
  ProductName,
} from 'api/dossier/products/types';
import ProductActionModal from 'components/modals/ProductActionModal';
import { ProductActionModalData } from 'components/modals/ProductActionModal/types';
import { DossierDocumentsContext } from 'pages/Dossiers/Details/tabs/DossierDocumentsTab/types';

export const useProductsDocumentsModal = (products: ProductInterface[]) => {
  const [productActionModalData, setProductActionModalData] =
    useState<ProductActionModalData | null>(null);

  const { fetchDossierAndDossierDocuments, ...dossier } =
    useOutletContext<DossierDocumentsContext>();

  const openProductActionModal = <TProductName extends ProductName>(
    productName: TProductName,
    stageName: ProductActionInterface['name'],
  ) => {
    const product = products.find(({ productName: name }) => name === productName);

    if (product && product.process?.stages.length) {
      const action = product.process.stages.find((stage) => stage.name === stageName);

      if (action) {
        setProductActionModalData({
          ...action,
          productId: product.id,
          productName,
          reportedErrors: product.process?.reportedErrors || null,
        });
      }
    }
  };

  const productActionModalProps: ComponentProps<typeof ProductActionModal> | null =
    useMemo(() => {
      if (!productActionModalData) {
        return null;
      }

      return {
        dossier,
        onClose: () => setProductActionModalData(null),
        onSubmit: fetchDossierAndDossierDocuments,
        actionData: productActionModalData,
      };
    }, [productActionModalData]);

  return { productActionModalProps, openProductActionModal };
};
