/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import customDate from './customDate';
import customWhen from './customWhen';
import email from './email';
import exactLength from './exactLength';
import invalid from './invalid';
import requiredForNullable from './requiredForNullable';
import startsWith from './startsWith';

yup.setLocale({
  // keys must be created in validation.json file in locales/[lng] folder
  mixed: {
    required: ({ path, label = path }) => ({
      key: 'required',
      values: { field: label },
    }),
  },
  string: {
    min: ({ min, path, label = path }) => ({
      key: 'string.min',
      values: { min, field: label },
    }),
    max: ({ max, path, label = path }) => ({
      key: 'string.max',
      values: { max, field: label },
    }),
    email: ({ path, label = path }) => ({
      key: 'string.email',
      values: { field: label },
    }),
    matches: ({ path, label = path }) => ({
      key: 'string.matches',
      values: { field: label },
    }),
    url: ({ path, label = path }) => ({
      key: 'string.url',
      values: { field: label },
    }),
  },
  number: {
    min: ({ min, path, label = path }) => ({
      key: 'number.min',
      values: { min, field: label },
    }),
    max: ({ max, path, label = path }) => ({
      key: 'number.max',
      values: { max, field: label },
    }),
    positive: ({ path, label = path }) => ({
      key: 'number.positive',
      values: { field: label },
    }),
  },
  array: {
    min: ({ min, path, label = path }) => ({
      key: min === 1 ? 'array.empty' : 'array.min',
      values: { min, field: label },
    }),
    max: ({ max, path, label = path }) => ({
      key: 'array.max',
      values: { max, field: label },
    }),
  },
});

yup.addMethod(yup.string, 'startsWith', startsWith);
yup.addMethod(yup.string, 'exactLength', exactLength);
yup.addMethod(yup.string, 'customDate', customDate);
yup.addMethod(yup.string, 'email', email);
yup.addMethod<any>(yup.Schema, 'invalid', invalid);
yup.addMethod<any>(yup.Schema, 'customWhen', customWhen);
yup.addMethod<any>(yup.Schema, 'requiredForNullable', requiredForNullable);

export { yup, yupResolver as resolver };
