import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { UploadIcon } from 'icons';
import { MAX_FILE_SIZE } from './static-data';
import {
  getBaseTypographyStyles,
  getDropzoneStyles,
  getLinkedTypographyStyles,
  getUploadIconStyles,
} from './styles';
import { DropzoneProps } from './types';
import { getAcceptedFormats, getErrorMessage } from './utils';

export const Dropzone = ({
  children,
  dropzoneConfig = {},
  onDrop,
  loading,
  warning,
  type = 'file',
  disabled = false,
  showIcon = true,
}: DropzoneProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'dropzone' });

  const {
    open,
    getRootProps,
    getInputProps,
    fileRejections,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: true,
    disabled: disabled || loading,
    maxSize: MAX_FILE_SIZE,
    useFsAccessApi: false,
    ...dropzoneConfig,
  });

  const errorMessage = useMemo(
    () => getErrorMessage(fileRejections, dropzoneConfig),
    [fileRejections.length],
  );

  return (
    <Stack gap={1}>
      <Box
        sx={getDropzoneStyles({ disabled, isDragAccept, isDragReject })}
        data-testid="dropzone"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box minHeight="10rem">
          {loading ? (
            <Box
              sx={({ mixins }) => ({ ...mixins.centered, minHeight: 'inherit' })}
              data-testid="progress-spinner"
            >
              <CircularProgress size={80} color="secondary" />
            </Box>
          ) : (
            <Stack alignItems="center" pb={2} pt={showIcon ? 2 : 0}>
              {showIcon && (
                <Box sx={getUploadIconStyles(disabled)}>
                  <UploadIcon />
                </Box>
              )}
              {children}
              <Typography sx={getBaseTypographyStyles(disabled)}>
                <Trans
                  t={t}
                  i18nKey="dragAndDrop"
                  defaults="Drag & drop or <Link>choose</Link> file to upload"
                  values={{ fileType: t(type) }}
                  components={{
                    Link: (
                      <Typography
                        sx={getLinkedTypographyStyles(disabled)}
                        onClick={open}
                        component="span"
                      />
                    ),
                  }}
                />
              </Typography>
              {dropzoneConfig.accept && (
                <Typography sx={{ fontSize: '0.75rem', color: 'text.secondary' }}>
                  {t('formats', { formats: getAcceptedFormats(dropzoneConfig.accept) })}
                </Typography>
              )}
            </Stack>
          )}
        </Box>
        {warning && <WarningField tKey={warning} fullWidth />}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </Stack>
  );
};
