import { Box } from '@mui/material';
import Table from 'templates/Table';
import { getDossierMigrationQueue } from 'api/businesses/requests';
import PageTitle from 'components/PageTitle';
import { dossierMigrationColumns, getDossierMigrationQueueActions } from './static-data';

const DossierMigrationQueueList = () => (
  <Box sx={({ mixins }) => mixins.tablePageWrapper}>
    <PageTitle page="dossierMigrationQueue" />
    <Table
      tKey="migrationQueue"
      get={getDossierMigrationQueue}
      withUrlSearchParams
      columns={dossierMigrationColumns}
      getActions={getDossierMigrationQueueActions}
    />
  </Box>
);

export default DossierMigrationQueueList;
