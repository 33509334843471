import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Table from 'templates/Table';
import { GetTableList } from 'templates/Table/types';
import { getNotifications } from 'api/notifications/requests';
import { ProductNotification } from 'api/notifications/types';
import PageTitle from 'components/PageTitle';
import {
  getLinkToProduct,
  notificationsColumns,
  notificationsToolbarProps,
} from './static-data';

const NotificationsList = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const notificationType = useMemo(
    () => state?.notificationType ?? 'actionRequired',
    [state?.notificationType],
  );

  const get: GetTableList<ProductNotification> = useCallback(
    (params) =>
      getNotifications({
        ...params,
        isActionRequired: state?.notificationType !== 'noAction',
      }),
    [state?.notificationType],
  );

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box>
        <PageTitle page="notifications" />
        <Typography variant="subtitle1" color="grey.500">
          {t(`tabs.notifications.${notificationType}`, notificationType)}
        </Typography>
      </Box>
      <Table
        tKey="notifications"
        get={get}
        columns={notificationsColumns}
        withUrlSearchParams
        toolbarProps={notificationsToolbarProps}
        rowRedirectionLink={getLinkToProduct}
      />
    </Box>
  );
};

export default NotificationsList;
