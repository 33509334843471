import { useMemo, useState } from 'react';
import { WithRequired } from 'types/withRequired';
import api from 'api';
import { ConfirmationModalProps } from 'components/modals/ConfirmationModal';
import { ConfirmationProps } from './types';

export const useConfirmationModal = () => {
  const [confirmationProps, setConfirmationProps] = useState<WithRequired<
    ConfirmationProps,
    'type'
  > | null>(null);
  const [action, setAction] = useState<ConfirmationModalProps['action']>(null);

  const closeConfirmationModal = () => {
    setConfirmationProps(null);
  };

  const setUpAndOpenConfirmationModal = (props: ConfirmationProps) => {
    setConfirmationProps({ ...props, type: props.type ?? 'deletion' });
  };

  const handleSubmit = async () => {
    if (!confirmationProps) return;

    const {
      url = '',
      onSuccess = () => {},
      onError,
      errorMessage,
      onSubmit,
      type,
      shouldCloseOnSubmit,
      shouldAwaitOnSuccess = true,
    } = confirmationProps;
    setAction('submitting');
    let response;

    if (onSubmit) {
      response = await onSubmit();
    } else if (type === 'deletion') {
      response = await api.delete(url, {
        customErrorMessage: errorMessage,
      });
    }

    if (response?.ok) {
      if (shouldAwaitOnSuccess) {
        await onSuccess();
      } else {
        onSuccess();
      }
      closeConfirmationModal();
    } else if (onError) {
      onError(response);
    } else if (shouldCloseOnSubmit) {
      closeConfirmationModal();
    }

    setAction(null);
  };

  const handleCancel = async () => {
    setAction('cancelling');
    await confirmationProps?.onCancel?.();
    setAction(null);
    closeConfirmationModal();
  };

  const confirmationModalProps: ConfirmationModalProps | null = useMemo(
    () =>
      confirmationProps
        ? {
            action,
            textContentKey: confirmationProps.translationKey,
            onClose: closeConfirmationModal,
            onCancel: handleCancel,
            onSubmit: handleSubmit,
            translationValues: confirmationProps.translationValues,
            type: confirmationProps.type,
          }
        : null,
    [confirmationProps?.type, action],
  );

  return { confirmationModalProps, setUpAndOpenConfirmationModal };
};
