import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { documentLanguageOptions } from 'formData/common/selectOptions';
import useFormMethods from 'hooks/useFormMethods';
import {
  ControlledDateInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/inputs';
import { RegisterRentalContractForm } from './types';

const RegisterRentalContractFields = () => {
  const { t } = useTranslation('formFields');
  const { registerController } = useFormMethods<RegisterRentalContractForm>();

  const registerInput = (name: keyof RegisterRentalContractForm) => ({
    label: t(`formFields:${name}`, name),
    labelVariant: 'separate' as const,
    ...registerController(name),
  });

  return (
    <Stack spacing={1}>
      <ControlledDateInput {...registerInput('contractSigningDate')} />
      <WarningField tKey="contractSigningDate" fullWidth />
      <ControlledTextInput {...registerInput('biv')} />
      <ControlledSelect
        {...registerInput('language')}
        options={documentLanguageOptions}
      />
    </Stack>
  );
};

export default RegisterRentalContractFields;
