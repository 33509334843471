import yup, { numberSchema } from 'utils/yup';
import { BelgiumCountry } from './static-data';

const addressSchema = yup.object({
  street: yup
    .string()
    .label('address.street')
    .required()
    .when({
      is: (value: string | null) => value && !value.match(/[a-z]/gi),
      then: (schema) => schema.invalid('invalidStreet'),
    }),
  streetTranslation: yup.string(),
  houseNumber: numberSchema.positive().label('address.houseNumber').required(),
  extension: yup
    .string()
    .label('address.extension')
    .nullable()
    .default(null)
    .exactLength(1)
    .when({
      is: (value: string) => !!value,
      then: (schema) => schema.matches(/[A-Za-z]/),
    }),
  apartmentNumber: yup
    .string()
    .label('address.apartmentNumber')
    .max(5)
    .nullable()
    .default(null),
  postCode: yup.string().label('address.postCode').required(),
  city: yup.string().label('address.city').required(),
  country: yup
    .string()
    .label('address.country')
    .default(JSON.stringify(BelgiumCountry.name))
    .required(),
});

export default addressSchema;
