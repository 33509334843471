import i18n from 'i18n';
import { DocumentType } from 'api/documents/types';

const getDocumentDateLabel = (
  documentType: DocumentType,
  configDate?: 'Future' | 'Past',
) => {
  switch (documentType) {
    case 'ProofOfFireInsuranceTenant':
    case 'DeathCertificate':
    case 'DeedOfSuccession':
    case 'CompanyAuthorization':
    case 'CertificateOfConformity':
    case 'AsbestosCertificate':
      return i18n.t('documentDateTypes.issueDate');
  }

  if (configDate === 'Future') {
    return i18n.t('documentDateTypes.expirationDate');
  }
  if (configDate === 'Past') {
    return i18n.t('documentDateTypes.signingDate');
  }
};

export default getDocumentDateLabel;
