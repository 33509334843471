import { ThemedStyle } from 'types/themedStyle';

export const filterAccordionStyle: ThemedStyle = {
  border: 'none',
  '.MuiAccordionSummary-content': {
    fontWeight: 'bold',
    pt: 1,
  },
  ':first-of-type': {
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
  },
  ':last-of-type': {
    borderBottom: ({ shape }) => shape.border,
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
};
