import i18n from 'i18n';
import getObjectEntries from 'utils/object/getObjectEntries';
import { DocumentsConfig, DocumentScope, DocumentType } from 'api/documents/types';
import { SelectOption } from 'components/inputs/Select/types';

export const getDocumentTypeOptions = (
  documentsConfig: DocumentsConfig,
  scope: DocumentScope,
): SelectOption<DocumentType>[] => {
  if (!documentsConfig[scope]) {
    return [];
  }

  return [
    ...getObjectEntries(documentsConfig[scope])
      .filter(([_, { source }]) => source === 'Internal')
      .map(([value]) => ({
        value,
        label: i18n.t(`documents:${scope}.${value}`),
      }))
      .sort(({ value, label: a }, { label: b }) => {
        if (value === 'Other') {
          return 1;
        }
        if (a === b) {
          return 0;
        }
        return a > b ? 1 : -1;
      }),
  ];
};
