import { LoaderConfig } from 'hooks/useLoader/types';
import { getUser } from 'api/users/requests';
import { FetchedUserData, UserFormData } from 'api/users/types';

export const getUserData = ({ id: _id, ...data }: FetchedUserData): UserFormData => data;

export const getUserConfig = (userId: string): LoaderConfig<FetchedUserData> => ({
  id: userId,
  scope: 'user',
  fetch: getUser,
});
