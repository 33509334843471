import { Stack, Typography } from '@mui/material';
import isTruthyValue from 'utils/isTruthyValue';
import { normalizeString } from 'utils/string';
import { ProductDetailsType } from 'api/dossier/products/types';

interface Props {
  details: ProductDetailsType;
  fontSize?: number;
}

const ProductDetails = ({ details, fontSize }: Props) => (
  <Stack spacing={1}>
    {Object.entries(details)
      .filter(([_, value]) => isTruthyValue(value))
      .map(([key, value]) => (
        <Stack key={key} direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontSize={fontSize} color="GrayText">
            {normalizeString(key)}
          </Typography>
          <Typography fontSize={fontSize} fontWeight="bold">
            {value}
          </Typography>
        </Stack>
      ))}
  </Stack>
);

export default ProductDetails;
