import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import getLng from 'utils/getLng';
import { getDemoByLanguage } from 'api/faq/requests';
import { DemoInterface } from 'api/faq/types';
import DemoTile from './components/DemoTile';
import { FaqContentProps } from './types';

const FaqContent = ({ section }: FaqContentProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'faqPage' });

  const [demos, setDemos] = useState<DemoInterface[]>([]);
  const [loading, setLoading] = useState(false);

  const { isFeatureActive } = useContext(FeatureFlagsContext);

  const { i18n } = useTranslation();
  const lng = useMemo(() => getLng(i18n), [i18n.language]);

  const isHowdyGoActive = isFeatureActive('HowdyGo');

  const currentSectionDemos = useMemo(
    () => demos.filter((demo) => demo.section.toLowerCase() === section.toLowerCase()),
    [section, demos],
  );

  const fetchDemos = useCallback(async () => {
    if (isHowdyGoActive) {
      setLoading(true);
      const { ok, response } = await getDemoByLanguage({ language: lng });
      if (ok) {
        setDemos(response);
      }
      setLoading(false);
    }
  }, [isHowdyGoActive, lng]);

  useEffect(() => {
    fetchDemos();
  }, [fetchDemos]);

  if (loading) {
    return <LoadingElement />;
  }

  if (!isHowdyGoActive) {
    return <Navigate to="" />;
  }

  return currentSectionDemos.length ? (
    <Grid container spacing={2}>
      {currentSectionDemos.map((demo) => (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={demo.id}>
          <DemoTile {...demo} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h2" textAlign="center">
        {t('noDemoAvailable')}
      </Typography>
    </Box>
  );
};

export default FaqContent;
