import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { ProductContext } from 'contexts/product/productContext';
import { orderRentalDepositSchemaV2 } from 'formData/dossier/products/rentalDeposit/schema';
import { resolver } from 'utils/yup';
import { ControlledCheckbox } from 'components/inputs';
import {
  ProductActionContent,
  ProductActionEstateInfo,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import RentalDepositPartiesDetails from '../RentalDepositPartiesDetails';
import { RentalDepositData } from '../types';
import { fetchChosenPartiesDetails } from '../utils';
import FireInsuranceController from './FireInsuranceController';
import LegalAssistanceController from './LegalAssistanceController';
import RentalConditionsDetails from './RentalConditionsDetails';
import { OrderRentalDepositForm } from './types';
import { isProductAvailable } from './utils';

export const OrderRentalDeposit = ({
  sendRequest,
  setLoading,
  dossier: { estate, rentalConditions },
  product: { chosenParties },
}: ProductActionComponentProps) => {
  const [data, setData] = useState<RentalDepositData | null>(null);
  const { rentalDossierChecklist } = useContext(ConfigContext);
  const { fetchProduct } = useContext(ProductContext);
  const { fetchRentalDossier } = useOutletContext<DossierOutletContext>();

  const { t } = useTranslation(['formFields', 'errors']);

  const fetchData = useCallback(async () => {
    if (chosenParties) {
      setLoading(true);
      const details = await fetchChosenPartiesDetails(chosenParties);
      setData(details);
      setLoading(false);
    }
  }, [chosenParties]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const methods = useForm<OrderRentalDepositForm>({
    defaultValues: orderRentalDepositSchemaV2.getDefault(),
    resolver: resolver(orderRentalDepositSchemaV2),
  });

  const onSubmit: SubmitHandler<OrderRentalDepositForm> = async (data) => {
    sendRequest(data, undefined, (status, closeModal) => {
      if (status === 499 || status === 504) {
        fetchRentalDossier();
        fetchProduct();
        closeModal?.();
      }
    });
  };

  const [isFireInsuranceAvailable, isLegalAssistanceAvailable] = useMemo(
    () => [
      isProductAvailable('FireInsurance', rentalDossierChecklist),
      isProductAvailable('LegalAssistance', rentalDossierChecklist),
    ],
    [rentalDossierChecklist],
  );
  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Stack gap={2}>
          <ProductActionEstateInfo estate={estate} />
          <RentalConditionsDetails rentalConditions={rentalConditions} />
          <RentalDepositPartiesDetails data={data} />
        </Stack>
        <FormProvider {...methods}>
          <Stack>
            <Stack>
              <ControlledCheckbox
                name="multipleTenants"
                label={t('multipleTenants')}
                control={methods.control}
              />
              {isFireInsuranceAvailable && (
                <FireInsuranceController
                  methods={methods}
                  estate={estate}
                  rentalConditions={rentalConditions}
                />
              )}
            </Stack>
            {isLegalAssistanceAvailable && (
              <LegalAssistanceController
                methods={methods}
                rentalConditions={rentalConditions}
              />
            )}
          </Stack>
        </FormProvider>
      </Stack>
    </ProductActionContent>
  );
};
