import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { documentLanguageOptions } from 'formData/common/selectOptions';
import useFormMethods from 'hooks/useFormMethods';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import {
  ControlledDateInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/inputs';

const UploadAddendumFields = () => {
  const { t } = useTranslation('formFields');
  const { registerController } = useFormMethods<RegisterAddendumForm>();

  const registerInput = (name: keyof RegisterAddendumForm['uploadedAddendum']) => ({
    label: t(`formFields:${name}`, name),
    labelVariant: 'separate' as const,
    ...registerController(`uploadedAddendum.${name}`),
  });

  return (
    <Stack spacing={1}>
      <ControlledDateInput {...registerInput('addendumSigningDate')} />
      <WarningField tKey="addendumSigningDate" fullWidth />
      <ControlledTextInput {...registerInput('biv')} />
      <ControlledSelect
        {...registerInput('language')}
        options={documentLanguageOptions}
      />
    </Stack>
  );
};

export default UploadAddendumFields;
