import { DefaultValues, FieldArrayWithId, FormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { productMissingDataSchema } from 'formData/dossier/products/productMissingData/schema';
import i18n from 'i18n';
import { getEstateDefaultValues } from 'modules/EstateView/utils';
import { getNaturalPersonDefaultValues } from 'modules/PersonView/NaturalPersonDetailsCard/utils';
import { firstCharLowerCase } from 'utils/string';
import transformName from 'utils/transformName';
import { getRentalConditions, updateRentalConditions } from 'api/dossier/requests';
import { DossierPartyInterface } from 'api/dossier/types';
import { getEstate, updateEstate } from 'api/estate/requests';
import { MissingField, MissingFields, PersonMissingFields } from 'api/missingData/types';
import { getLegalPerson, updateLegalPerson } from 'api/person/legal/requests';
import { getNaturalPerson, updateNaturalPerson } from 'api/person/natural/requests';
import { ProductActionModalProps } from 'components/modals/ProductActionModal/types';
import { getRentalConditionsDefaultValues } from 'pages/Dossiers/Edition/utils';
import { getLegalPersonDefaultValues } from 'pages/Persons/LegalPersons/Edition/utils';
import { FormDirtyFields, MissingDataForm, PartyDetails } from './types';

// TODO: Add tests for all utils here
const getPersonData = (
  personId: string,
  persons: DossierPartyInterface[],
  isRepresentative = false,
) => {
  let person: DossierPartyInterface | undefined = undefined;
  let companyName: string | undefined = undefined;

  if (isRepresentative) {
    const legalPerson = persons.find(
      ({ representative }) => representative?.id === personId,
    );
    person = legalPerson?.representative ?? undefined;
    companyName = legalPerson?.name;
  } else {
    person = persons.find(({ id }) => id === personId);
  }

  if (person) {
    return { ...person, companyName };
  }
};

const getParty = (
  personId: string,
  tenants: DossierPartyInterface[],
  landlords: DossierPartyInterface[],
  isRepresentative = false,
) => {
  const tenantData = getPersonData(personId, tenants, isRepresentative);
  if (tenantData) {
    return { ...tenantData, partyType: 'tenant' as const };
  }

  const landlordData = getPersonData(personId, landlords, isRepresentative);
  if (landlordData) {
    return { ...landlordData, partyType: 'landlord' as const };
  }
};

const getNaturalPersonsFormData = async (
  naturalPersonsMissingData: PersonMissingFields[],
  { tenants, landlords }: ProductActionModalProps['dossier'],
  defaultValues: MissingDataForm['naturalPersons'],
) => {
  const persons: MissingDataForm['naturalPersons'] = [];

  for (const [
    index,
    { personId, fields, isRepresentative },
  ] of naturalPersonsMissingData.entries()) {
    const party = getParty(personId, tenants, landlords, isRepresentative);
    const { ok, response } = await getNaturalPerson(personId);

    if (ok && party) {
      const values = defaultValues?.[index]
        ? { ...defaultValues[index], ...response }
        : response;

      persons.push({
        ...getNaturalPersonDefaultValues(values),
        id: response.id,
        index,
        partyType: party.partyType,
        companyName: party.companyName,
        personType: 'NaturalPerson',
        missingFields: fields,
      });
    } else {
      toast.error(
        i18n.t('errors:persons.fetch', {
          context: party ? 'withName' : '',
          name: party ? transformName(party) : '',
        }),
        { toastId: personId },
      );
    }
  }
  return persons;
};

const getLegalPersonsFormData = async (
  legalPersonsMissingData: PersonMissingFields[],
  { tenants, landlords }: ProductActionModalProps['dossier'],
  defaultValues: MissingDataForm['legalPersons'],
) => {
  const persons: MissingDataForm['legalPersons'] = [];
  for (const [index, { personId }] of legalPersonsMissingData.entries()) {
    const party = getParty(personId, tenants, landlords);
    const { ok, response } = await getLegalPerson(personId);
    if (ok && party) {
      const values = defaultValues?.[index]
        ? { ...defaultValues[index], ...response }
        : response;

      persons.push({
        ...getLegalPersonDefaultValues(values),
        id: response.id,
        index,
        partyType: party.partyType,
        representativeId: party.representative?.id,
        personType: 'LegalPerson',
      });
    } else {
      toast.error(
        i18n.t('errors:persons.fetch', {
          context: party ? 'withName' : '',
          name: party?.name,
        }),
        { toastId: personId },
      );
    }
  }

  return persons;
};

const getRentalConditionsFormData = async (
  { id: dossierId, estate }: ProductActionModalProps['dossier'],
  defaultValues: MissingDataForm['rentalConditions'],
) => {
  const { ok, response } = await getRentalConditions(dossierId);
  if (ok) {
    const values = defaultValues ? { ...defaultValues, ...response } : response;
    return getRentalConditionsDefaultValues(values, estate);
  } else {
    toast.error(i18n.t('errors:rentalConditions.fetch'), { toastId: dossierId });
  }
};

const getEstateFormData = async (
  estateId: string,
  defaultValues: MissingDataForm['estate'],
) => {
  const { ok, response } = await getEstate(estateId);
  if (ok) {
    const values = defaultValues ? { ...defaultValues, ...response } : response;
    return getEstateDefaultValues(values);
  } else {
    toast.error(i18n.t('errors:estates.fetch'), { toastId: estateId });
  }
};

const getPersonsMissingFields = (missingFields: MissingFields) => {
  const personsMissingFields = [...missingFields.tenants, ...missingFields.landlords];
  const naturalPersonsMissingFields = personsMissingFields.filter(
    ({ personType }) => personType === 'NaturalPerson',
  );
  const legalPersonsMissingFields = personsMissingFields.filter(
    ({ personType }) => personType === 'LegalPerson',
  );

  return { naturalPersonsMissingFields, legalPersonsMissingFields };
};

export const getFormData = async (
  missingFields: MissingFields,
  currentValues: MissingDataForm,
  dossier: ProductActionModalProps['dossier'],
) => {
  let form: MissingDataForm = {};

  if (missingFields.rentalConditions?.length) {
    const rentalConditions = await getRentalConditionsFormData(
      dossier,
      currentValues.rentalConditions,
    );
    if (rentalConditions) {
      form = { ...form, rentalConditions };
    }
  }

  if (missingFields.estate?.length) {
    const estate = await getEstateFormData(dossier.estate.id, currentValues.estate);
    if (estate) {
      form = { ...form, estate };
    }
  }

  const { naturalPersonsMissingFields, legalPersonsMissingFields } =
    getPersonsMissingFields(missingFields);

  if (naturalPersonsMissingFields.length) {
    const naturalPersons = await getNaturalPersonsFormData(
      naturalPersonsMissingFields,
      dossier,
      currentValues.naturalPersons,
    );
    form = { ...form, naturalPersons };
  }

  if (legalPersonsMissingFields.length) {
    const legalPersons = await getLegalPersonsFormData(
      legalPersonsMissingFields,
      dossier,
      currentValues.legalPersons,
    );
    form = { ...form, legalPersons };
  }
  return form;
};

type LegalPersonField = FieldArrayWithId<MissingDataForm, 'legalPersons', 'fieldId'>;
type NaturalPersonField = FieldArrayWithId<MissingDataForm, 'naturalPersons', 'fieldId'>;

export const getPartyFields = (
  partyType: 'tenant' | 'landlord',
  legalPersonsFields: LegalPersonField[],
  naturalPersonsFields: NaturalPersonField[],
) => {
  const legalPersons = legalPersonsFields.filter(
    (legalPerson) => legalPerson.partyType === partyType,
  );
  const naturalPersons = naturalPersonsFields.filter(
    (naturalPerson) => naturalPerson.partyType === partyType,
  );

  const fields: (LegalPersonField | NaturalPersonField)[] = [];
  legalPersons.forEach((legalPerson) => {
    const representative = naturalPersons.find(
      ({ id }) => id === legalPerson.representativeId,
    );
    if (representative) {
      fields.push(legalPerson, representative);
    } else {
      fields.push(legalPerson);
    }
  });

  return [
    ...fields,
    ...naturalPersons.filter((person) => !fields.some(({ id }) => person.id === id)),
  ];
};

export const handleUpdateEntities = async (
  { rentalConditions, estate, naturalPersons, legalPersons }: MissingDataForm,
  dossier: ProductActionModalProps['dossier'],
  dirtyFields: FormDirtyFields,
  errors: FormState<MissingDataForm>['errors'] = {},
) => {
  let isValid = !Object.keys(errors).length;

  if (!errors.estate && dirtyFields.estate && estate) {
    const { ok } = await updateEstate(dossier.estate.id, estate);
    if (ok) {
      toast.success(i18n.t('successMessages.EstateUpdatedSuccessfully'), {
        autoClose: 5000,
        toastId: dossier.estate.id,
      });
    } else {
      isValid = false;
      toast.error(i18n.t('errors:estates.save'), { toastId: dossier.estate.id });
    }
  }

  if (!errors.rentalConditions && dirtyFields.rentalConditions && rentalConditions) {
    const { ok } = await updateRentalConditions(dossier.id, rentalConditions);
    if (ok) {
      toast.success(i18n.t('successMessages.RentalConditionsUpdatedSuccessfully'), {
        autoClose: 5000,
        toastId: dossier.id,
      });
    } else {
      isValid = false;
      toast.error(i18n.t('errors:rentalConditions.save'), { toastId: dossier.id });
    }
  }

  if (naturalPersons?.length) {
    for (const [index, naturalPerson] of naturalPersons.entries()) {
      if (!errors.naturalPersons?.[index] && dirtyFields.naturalPersons?.[index]) {
        const { ok } = await updateNaturalPerson(naturalPerson.id, naturalPerson);
        const name = transformName(naturalPerson);
        if (ok) {
          toast.success(i18n.t('successMessages.PersonUpdatedSuccessfully', { name }), {
            autoClose: 5000,
            toastId: naturalPerson.id,
          });
        } else {
          isValid = false;
          toast.error(i18n.t('errors:persons.save', { name }), {
            toastId: naturalPerson.id,
          });
        }
      }
    }
  }

  if (legalPersons?.length) {
    for (const [index, legalPerson] of legalPersons.entries()) {
      if (!errors.legalPersons?.[index] && dirtyFields.legalPersons?.[index]) {
        const { name } = legalPerson;
        const { ok } = await updateLegalPerson(legalPerson.id, legalPerson);
        if (ok) {
          toast.success(i18n.t('successMessages.PersonUpdatedSuccessfully', { name }), {
            autoClose: 5000,
            toastId: legalPerson.id,
          });
        } else {
          isValid = false;
          toast.error(i18n.t('errors:persons.save', { name }), {
            toastId: legalPerson.id,
          });
        }
      }
    }
  }

  return isValid;
};

export const isRentTypeMissing = (fields: MissingField[]) =>
  !!fields.find(({ propertyName }) => firstCharLowerCase(propertyName) === 'rentType');

const cleanUpPerson = <T extends Partial<PartyDetails> | undefined>(person: T) => {
  if (person) {
    const {
      index: _index,
      partyType: _partyType,
      personType: _personType,
      companyName: _companyName,
      missingFields: _missingFields,
      representativeId: _representativeId,
      ...data
    } = person;
    return data;
  }
};

const getPersonsDirtyFields = <T extends { id?: string }>(
  missingFields: PersonMissingFields[],
  persons: T[] = [],
  defaultPersons: (T | undefined)[] = [],
) =>
  persons.reduce((prev, person, index) => {
    const defaultPerson = defaultPersons.find(
      (defaultPerson) => defaultPerson?.id === person.id,
    );
    return {
      ...prev,
      [index]: missingFields.find(({ personId }) => personId === person.id)
        ? !isDeepEqual(cleanUpPerson(person), cleanUpPerson(defaultPerson))
        : false,
    };
  }, {});

export const getDirtyFields = (
  missingFields: MissingFields = {
    landlords: [],
    rentalConditions: [],
    tenants: [],
    estate: [],
  },
  data: MissingDataForm,
  defaultValues: DefaultValues<MissingDataForm> = {},
): FormDirtyFields => {
  const { naturalPersonsMissingFields, legalPersonsMissingFields } =
    getPersonsMissingFields(missingFields);
  return {
    estate: missingFields.estate?.length
      ? !isDeepEqual(data.estate, defaultValues.estate)
      : false,
    naturalPersons: getPersonsDirtyFields(
      naturalPersonsMissingFields,
      data.naturalPersons,
      defaultValues.naturalPersons,
    ),
    legalPersons: getPersonsDirtyFields(
      legalPersonsMissingFields,
      data.legalPersons,
      defaultValues.legalPersons,
    ),
    rentalConditions: missingFields.rentalConditions.length
      ? !isDeepEqual(data.rentalConditions, defaultValues.rentalConditions)
      : false,
  };
};

export const getIsDirty = ({
  estate,
  rentalConditions,
  naturalPersons,
  legalPersons,
}: FormDirtyFields) =>
  estate ||
  rentalConditions ||
  Object.values(naturalPersons).some(Boolean) ||
  Object.values(legalPersons).some(Boolean);

export const transformFormToSchemaValues = (values: MissingDataForm) =>
  // https://github.com/jquense/yup?tab=readme-ov-file#schemacastvalue-any-options---infertypeschema
  productMissingDataSchema.cast(values, { assert: false }) as MissingDataForm;
