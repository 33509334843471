import { useMemo } from 'react';
import useEstatePurposeFields from 'hooks/useEstatePurposeFields';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import {
  findMissingField,
  getInputLabel,
  isMissingFieldRequired,
} from 'utils/missingFields';
import { ControlledNumberInput, ControlledSelect } from 'components/inputs';
import { EstatePurposeFieldNames, EstatePurposeFieldsProps } from './types';

const EstatePurposeFields = ({
  missingFields = [],
  onEstateTypeChange,
  previousDestination,
}: EstatePurposeFieldsProps) => {
  const {
    estateDestinations,
    estateTypes,
    estateSubTypes,
    handleDestinationChange,
    handleTypeChange,
    isSubTypeDisabled,
    isTypeDisabled,
    type,
    destinationWarning,
  } = useEstatePurposeFields(previousDestination);

  const { registerController } = useFormMethods<EstateFormInterface>();

  const isFloorLevelVisible = useMemo(() => {
    const isApartmentType = type && type === 'Apartment';
    if (missingFields.length) {
      return !!findMissingField('floorLevel', missingFields) && isApartmentType;
    }
    return isApartmentType;
  }, [type, missingFields]);

  const getInputProps = (name: EstatePurposeFieldNames) => {
    const isFieldRequired = isMissingFieldRequired(name, missingFields);
    const label = getInputLabel(name, isFieldRequired);
    return {
      label,
      labelVariant: missingFields.length ? 'separate' : 'float',
      ...registerController(`estate.${name}`),
    } as const;
  };

  const isVisible = useMemo(() => {
    if (missingFields.length) {
      return (
        !!findMissingField('destination', missingFields) ||
        !!findMissingField('type', missingFields) ||
        !!findMissingField('subType', missingFields)
      );
    }
    return true;
  }, [missingFields]);

  return (
    <>
      {isVisible ? (
        <>
          <ControlledSelect
            {...getInputProps('destination')}
            options={estateDestinations}
            onChange={handleDestinationChange}
            warning={destinationWarning}
            md={isFloorLevelVisible ? 3 : 4}
            xs={12}
          />
          <ControlledSelect
            {...getInputProps('type')}
            options={estateTypes}
            onChange={(value) => {
              handleTypeChange(value);
              onEstateTypeChange?.(value);
            }}
            noOptionsText="estateType"
            disabled={isTypeDisabled}
            md={isFloorLevelVisible ? 3 : 4}
            xs={12}
          />
          <ControlledSelect
            {...getInputProps('subType')}
            options={estateSubTypes}
            disabled={isSubTypeDisabled}
            noOptionsText="estateSubType"
            md={isFloorLevelVisible ? 3 : 4}
            xs={12}
          />
        </>
      ) : null}
      {isFloorLevelVisible && (
        <ControlledNumberInput {...getInputProps('floorLevel')} md={3} xs={12} />
      )}
    </>
  );
};

export default EstatePurposeFields;
