import { Collapse, List, TableCell, TableRow } from '@mui/material';
import { DocumentData } from 'api/documents/types';
import { DocumentsContextInterface } from '../types';
import DocumentFileListItem from './DocumentFileComponent';
import { DocumentFilesProps } from './types';

const DocumentFiles = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  open,
  document,
  ...props
}: DocumentFilesProps<TData, TContext>) =>
  document.files.length > 0 ? (
    <TableRow>
      <TableCell sx={{ '&.MuiTableCell-sizeSmall': { py: 0 } }} colSpan={100}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {document.files.map((file) => (
              <DocumentFileListItem
                key={file.id}
                file={file}
                document={document}
                {...props}
              />
            ))}
          </List>
        </Collapse>
      </TableCell>
    </TableRow>
  ) : null;

export default DocumentFiles;
