import { useMemo } from 'react';
import { Box } from '@mui/material';
import InputWrapper from '../InputWrapper';
import NumberInput from '../NumberInput';
import Select from '../Select';
import { PaymentInputProps } from './types';

const PaymentInput = ({
  value = '',
  currency = 'EUR',
  options = [],
  onValueChange,
  onCurrencyChange,
  ...props
}: PaymentInputProps) => {
  const isCurrencySelectable = useMemo(() => options.length > 1, [options.length]);

  return (
    <Box display="flex" gap={1} justifyContent="space-between">
      <Box width="100%">
        <NumberInput
          name="payment_value"
          onChange={onValueChange}
          value={value ?? ''}
          precision={2}
          endAdornment={!isCurrencySelectable ? currency : undefined}
          {...props}
        />
      </Box>
      {isCurrencySelectable && (
        <Box minWidth={75}>
          <Select
            options={options}
            name="payment_currency"
            value={currency}
            onChange={onCurrencyChange}
            disabled={props.disabled}
          />
        </Box>
      )}
    </Box>
  );
};

const PaymentInputWrapper = (props: PaymentInputProps) =>
  InputWrapper(PaymentInput, props);

export default PaymentInputWrapper;
