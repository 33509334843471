import { LoaderConfig } from 'hooks/useLoader/types';
import { getProducts } from 'api/dossier/products/requests';
import { ProductInterface, ProductName } from 'api/dossier/products/types';
import { FetchedDossierChecklistItem } from 'api/dossier/types';

export const getProductsConfig = (
  dossierId: string,
): LoaderConfig<ProductInterface[]> => ({
  scope: 'products',
  id: dossierId,
  fetch: getProducts,
  withContext: false,
});

export const getProductsOrder = (checklist: FetchedDossierChecklistItem[]) =>
  checklist.reduce((prev, { name, order }) => ({ ...prev, [name]: order }), {}) as Record<
    ProductName,
    number
  >;
