import i18n from 'i18n';
import { RelationOption } from 'modules/Relation/types';
import { EstateOption } from './types';

export const renderEstateOption = (option: EstateOption) => {
  if (option.data) {
    const { address, city, postCode } = option.data;
    if (address && city && postCode) {
      return `${address}, ${postCode} ${city}`;
    }
  }
  return '';
};

export const setSubHeaderForAssignedParameter = <T extends object>(
  options: RelationOption<T>[],
): RelationOption<T>[] =>
  options.map((option) => ({
    ...option,
    subHeader: i18n.t('searchGroup.contextResults'),
  }));
