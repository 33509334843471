import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EAN_NUMBER_START } from 'formData/estate/details/eanNumber/static-data';
import { electricityTypeOptions } from 'formData/estate/details/selectOptions';
import { isEstateElectricityMeterNumber2 } from 'formData/estate/details/utils';
import { trimStartMask } from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import {
  ControlledCheckbox,
  ControlledMaskedInput,
  ControlledSelect,
} from 'components/inputs';

const ElectricityField = () => {
  const { t } = useTranslation('formFields');

  const { setValue, getValues, watch, registerController } =
    useFormMethods<EstateFormInterface>();

  const type = watch('estateDetails.electricity.type');

  const updateCheckbox = () => {
    if (!getValues('estateDetails.hasElectricity')) {
      setValue('estateDetails.hasElectricity', true);
    }
  };

  const isMeterNumber2Visible = useMemo(
    () => isEstateElectricityMeterNumber2(type),
    [type],
  );

  return (
    <Grid item container xs={12}>
      <Grid
        item
        container
        sx={({ shape }) => ({ border: shape.border, borderRadius: 2, padding: 2 })}
        rowGap={2}
      >
        <ControlledCheckbox
          xs={12}
          {...registerController('estateDetails.hasElectricity')}
          label={t('electricity.self')}
        />
        <Grid item container xs={12} spacing={2}>
          <ControlledSelect
            md={isMeterNumber2Visible ? 3 : 4}
            xs={12}
            {...registerController('estateDetails.electricity.type')}
            label={t('electricity.type')}
            options={electricityTypeOptions}
            onChange={updateCheckbox}
            clearable
          />
          <ControlledMaskedInput
            md={isMeterNumber2Visible ? 3 : 4}
            xs={12}
            {...registerController('estateDetails.electricity.eanNumber')}
            label={t('electricity.eanNumber')}
            mask={new Array(18).fill(/\d/)}
            onChange={updateCheckbox}
            placeholder={`${EAN_NUMBER_START}1234567890123`}
          />
          <ControlledMaskedInput
            md={isMeterNumber2Visible ? 3 : 4}
            xs={12}
            {...registerController('estateDetails.electricity.meterNumber')}
            {...trimStartMask}
            onChange={updateCheckbox}
            label={t('electricity.meterNumber', {
              suffix: isMeterNumber2Visible ? 1 : '',
            })}
          />
          {isMeterNumber2Visible && (
            <ControlledMaskedInput
              md={3}
              xs={12}
              {...registerController('estateDetails.electricity.meterNumber2')}
              {...trimStartMask}
              label={t('electricity.meterNumber2')}
              onChange={updateCheckbox}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ElectricityField;
