import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Badge } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import useDocumentsModal from 'hooks/useDocumentsModal';
import { FileIcon } from 'icons';
import { attachDocument, mapMissingDocuments } from 'utils/products';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { DocumentsModal } from 'components/modals';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import DocumentButtonTooltip from './DocumentButtonTooltip';
import { ProductCardFooterProps } from './types';

const ProductDocumentsAction = ({
  missingDocuments = [],
}: Pick<ProductCardFooterProps, 'missingDocuments'>) => {
  const {
    id: dossierId,
    status,
    fetchRentalDossier,
  } = useOutletContext<DossierOutletContext>();

  const { t } = useTranslation();
  const { isOpen, openModal, closeModal } = useDocumentsModal();

  const disabled = useMemo(() => !shouldDossierFeatureBeActive(status), [status]);

  return (
    <>
      <TooltipIconButton
        onClick={() => openModal()}
        tooltipTitle={
          !disabled ? (
            <DocumentButtonTooltip missingDocuments={missingDocuments} />
          ) : (
            t('tooltips.uploadMissingDocuments')
          )
        }
        disabled={disabled}
        Icon={
          !disabled ? (
            <Badge
              color="error"
              sx={{ '.MuiBadge-badge': { lineHeight: 0 } }}
              badgeContent={missingDocuments.length}
            >
              <FileIcon />
            </Badge>
          ) : (
            <FileIcon />
          )
        }
      />
      {isOpen && (
        <DocumentsModal
          missingDocuments={mapMissingDocuments(missingDocuments)}
          onClose={closeModal}
          onSubmit={fetchRentalDossier}
          onSingleDocumentSave={attachDocument(dossierId, missingDocuments)}
        />
      )}
    </>
  );
};

export default ProductDocumentsAction;
