import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TKey } from 'i18next';

interface Props {
  page: TKey<'common', 'pages'>;
}

export const PageTitle = ({ page }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'pages' });
  return (
    <Typography variant="h2" sx={({ palette }) => ({ color: palette.primary.main })}>
      {t(page)}
    </Typography>
  );
};
