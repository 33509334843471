import { Chip, Paper, Stack, Typography } from '@mui/material';
import { normalizeString } from 'utils/string';
import { ProductInterface } from 'api/dossier/products/types';
import ProductDetails from '../ProductDetails';
import { subProductsCardStyles } from '../styles';
import SubProductAction from './SubProductAction';
import SubProductTitle from './SubProductTitle';
import { filterSubProducts } from './utils';

type Props = Required<Pick<ProductInterface, 'subProducts'>>;

export const SubProducts = ({ subProducts }: Props) => (
  <Stack direction="row" gap={1} width="100%" flexWrap="wrap">
    {filterSubProducts(subProducts).map(
      ({ name, status, details, action, active, index, statusComment }) => (
        <Paper variant="outlined" key={name} sx={subProductsCardStyles}>
          <SubProductTitle name={name} index={index} active={active} />
          {details && <ProductDetails details={details} fontSize={12} />}
          <Stack alignItems="center" gap={0.25} mt="auto">
            <Chip
              size="small"
              label={normalizeString(status)}
              sx={({ mixins }) => ({ bgcolor: mixins.getProductStatusColor(status) })}
            />
            {statusComment && (
              <Typography
                variant="caption"
                fontStyle="italic"
                color="GrayText"
              >{`"${statusComment}"`}</Typography>
            )}
          </Stack>
          {action && (
            <Stack alignItems="center" mt={0.5}>
              <SubProductAction {...action} />
            </Stack>
          )}
        </Paper>
      ),
    )}
  </Stack>
);
