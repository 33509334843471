import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import LoadingElement from 'atoms/LoadingElement';
import { assignableDossierStatuses } from 'formData/dossier/selectOptions';
import FilterChips from 'templates/FilterChips';
import { FilterChipInterface } from 'templates/FilterChips/types';
import { sortModelToString } from 'utils/sortUtils';
import { SortDirection } from 'utils/sortUtils/types';
import { getDossiers } from 'api/dossier/requests';
import AddNewDossierCard from './AddNewDossierCard';
import AssignedDossierCard from './AssignedDossierCard';
import AssignedDossiersToolbar from './AssignedDossiersToolbar';
import { INIT_DOSSIERS_COUNT } from './static-data';
import { AssignedDossier, AssignedDossiersGridProps } from './types';
import { getAssignedDossierData } from './utils';

const AssignedDossiersGrid = ({ entityId, type }: AssignedDossiersGridProps) => {
  const [dossiers, setDossiers] = useState<AssignedDossier[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [loadingRemaining, setLoadingRemaining] = useState(false);

  const { i18n, t } = useTranslation(['common', 'formFields', 'enums']);
  const [filterStatuses, setFilterStatuses] = useState<FilterChipInterface[]>(
    assignableDossierStatuses.map(({ value }) => ({
      name: value,
      label: t(`enums:dossierStatuses.${value}`),
      checked: false,
    })),
  );
  const [sortBy, setSortBy] = useState<SortDirection>('desc');

  const getDossiersList = useCallback(
    async (limit = INIT_DOSSIERS_COUNT) => {
      const { ok, response } = await getDossiers({
        limit,
        [type === 'Person' ? 'personsIds' : 'estateIds']: entityId,
        sort: sortModelToString({ field: 'createdAt', direction: sortBy || null }),
        statuses: filterStatuses
          .filter(({ checked }) => checked)
          .map(({ name }) => name)
          .join(','),
      });
      if (ok) {
        setDossiers(getAssignedDossierData(response));
        setTotalCount(response.meta.total);
      }
    },
    [entityId, i18n.language, sortBy, filterStatuses],
  );

  useEffect(() => {
    const fetchDossiers = async () => {
      setLoading(true);
      await getDossiersList();
      setLoading(false);
    };
    fetchDossiers();
  }, [getDossiersList]);

  const loadRemainingDossiers = async () => {
    setLoadingRemaining(true);
    await getDossiersList(totalCount);
    setLoadingRemaining(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <AssignedDossiersToolbar sortBy={sortBy} setSortBy={setSortBy} />
      <FilterChips filters={filterStatuses} setFilters={setFilterStatuses} />
      {!loading ? (
        <Box sx={({ mixins }) => mixins.cardsGrid}>
          <AddNewDossierCard entityId={entityId} type={type} />
          {dossiers.map((dossier) => (
            <AssignedDossierCard
              key={dossier.id}
              entityId={entityId}
              type={type}
              {...dossier}
            />
          ))}
        </Box>
      ) : (
        <LoadingElement />
      )}
      {totalCount > dossiers.length && (
        <Box display="flex" width="100%" justifyContent="center" mt={3}>
          {loadingRemaining ? (
            <LoadingElement />
          ) : (
            <Button role="text" color="secondary" onClick={loadRemainingDossiers}>
              {t('assignedEntityCard.showMore', { count: totalCount - dossiers.length })}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AssignedDossiersGrid;
