import { BoldCell, DossierStatusCell, PersonsCell } from 'templates/Table/Cells';
import { TableColumn } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import { FetchedDossierListItem } from 'api/dossier/types';
import ProductsStatuses from './cells/ProductsStatuses';

export const dashboardDossiersColumns: TableColumn<FetchedDossierListItem, 'dossiers'>[] =
  [
    {
      field: 'referenceNumber',
      renderCell: BoldCell,
      flex: 1.5,
    },
    {
      field: 'ownReference',
      flex: 1,
    },
    {
      field: 'status',
      renderCell: DossierStatusCell,
      flex: 1,
      sortable: true,
    },
    {
      field: 'estate',
      flex: 1.5,
      sortable: true,
      valueGetter: (_value, { estate }) => estate.address,
    },
    {
      field: 'tenants',
      flex: 2,
      renderCell: PersonsCell,
      sortable: true
    },
    {
      field: 'productsStatuses',
      flex: 1.5,
      renderCell: ProductsStatuses,
    },
    {
      field: 'entryDate',
      flex: 1.5,
      sortable: true,
      valueGetter: (value) => formatDate(value),
    },
  ];
