import { MAX_OWNERS_COUNT } from 'modules/Relation/static-data';
import yup from 'utils/yup';
import { rentalConditionsSchema } from './rentalConditions';
import { residentsSchema } from './residents';

export const dossierFormSchema = yup.object({
  dossierType: yup.string().required().default('RentalDossier'),
  checklistItems: yup.array().default([]),
  estateId: yup.string().label('estate').required(),
  landlords: yup.array().min(1).max(MAX_OWNERS_COUNT).default([]),
  tenants: yup.array().max(MAX_OWNERS_COUNT).default([]),
  responsibleUsers: yup.array().default([]),
  rentalConditions: rentalConditionsSchema.optional(),
  residents: residentsSchema.optional(),
});
