import { SvgIconProps } from '@mui/material';
import { AttachIcon, DropIcon, FolderIcon, LightningIcon, RouterIcon } from 'icons';

const SubProductIcon = ({ name, ...props }: { name: string } & SvgIconProps) => {
  switch (name) {
    case 'stateReport':
      return <FolderIcon {...props} />;
    case 'addendum':
      return <AttachIcon {...props} />;
    case 'energy':
      return <LightningIcon {...props} />;
    case 'telecom':
      return <RouterIcon {...props} />;
    case 'water':
      return <DropIcon {...props} />;
    default:
      return null;
  }
};

export default SubProductIcon;
