import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import { ClearIcon } from 'icons';
import replaceSpecialChars from 'utils/replaceSpecialChars';
import BaseInput from '../BaseInput';
import { CHARACTERS_LIMIT } from '../BaseInput/static-data';
import { TextInputProps } from './types';

const TextInput = ({
  helperText,
  warning,
  value,
  withSpecialChars,
  clearable,
  onClear,
  noLimitForMultiline = false,
  ...props
}: TextInputProps) => {
  const { t } = useTranslation();
  const inputLength = String(value).length;
  const isLimitedMultiline = !!props.multiline && !noLimitForMultiline;

  return (
    <BaseInput
      type="text"
      value={withSpecialChars && value ? replaceSpecialChars(String(value)) : value}
      helperText={
        helperText ||
        (isLimitedMultiline ? (
          <Stack direction="row">
            {inputLength === CHARACTERS_LIMIT.textArea && (
              <Typography variant="caption">
                {t('helperText.maxNumberOfCharacters')}
              </Typography>
            )}
            <Typography
              ml="auto"
              variant="caption"
            >{`${inputLength}/${CHARACTERS_LIMIT.textArea}`}</Typography>
          </Stack>
        ) : undefined)
      }
      endAdornment={
        clearable &&
        !!value && (
          <IconButton size="small" onClick={onClear}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )
      }
      warning={
        warning ||
        (isLimitedMultiline ? inputLength === CHARACTERS_LIMIT.textArea : undefined)
      }
      {...props}
    />
  );
};

export default TextInput;
