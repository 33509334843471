import { useTranslation } from 'react-i18next';
import CardRow, { RowValues } from 'templates/CardRow';
import paymentToString from 'utils/paymentToString';
import { RentalConditions } from 'api/dossier/types';

const RentalPaymentRow = ({
  rentalPrice,
  rentFrequency,
}: Pick<RentalConditions, 'rentFrequency' | 'rentalPrice'>) => {
  const { t } = useTranslation(['formFields', 'enums']);

  if (rentalPrice && rentFrequency) {
    return (
      <CardRow
        title={t('rentalPrice')}
        value={
          <RowValues
            primary={paymentToString(rentalPrice)}
            secondary={t(`enums:rentFrequency_ordinal.${rentFrequency}`)}
          />
        }
      />
    );
  }

  if (rentalPrice) {
    return <CardRow title={t('rentalPrice')} value={`${paymentToString(rentalPrice)}`} />;
  }

  if (rentFrequency) {
    return (
      <CardRow
        title={t('formFields:rentFrequency')}
        value={t(`enums:rentFrequency.${rentFrequency}`)}
      />
    );
  }

  return null;
};

export default RentalPaymentRow;
