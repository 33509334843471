import { estateDetailsSchema } from 'formData/estate';
import { EstateDetailsForm, FetchedEstateDetails } from 'api/estate/types';

export const getEstateDetailsData = ({
  id: _id,
  businessId: _businessId,
  ...data
}: FetchedEstateDetails): EstateDetailsForm => ({
  ...estateDetailsSchema.getDefault(),
  ...data,
});
