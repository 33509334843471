import { Grid } from '@mui/material';
import { addMonths, subMonths, subYears } from 'date-fns';
import {
  currencyOptions,
  dossierDurations,
  numberOfMonthlyRentOptions,
  rentFrequencyOptions,
  rentTypes,
} from 'formData/dossier/rentalConditions/selectOptions';
import useRentalConditionsFields from 'hooks/useRentalConditionsFields';
import { findMissingField, getInputProps } from 'utils/missingFields';
import { RentalConditions } from 'api/dossier/types';
import {
  ControlledCheckbox,
  ControlledDateInput,
  ControlledNumberInput,
  ControlledPaymentInput,
  ControlledSelect,
} from 'components/inputs';
import { RentalConditionsFieldsProps } from './types';

const RentalConditionsFields = ({
  prefix,
  missingFields = [],
  editable = true,
}: RentalConditionsFieldsProps) => {
  const {
    hiddenFields,
    registerController,
    onContractSigningDateChange,
    onDepositAmountChange,
    onDurationNumberOfMonthsBlur,
    onEntryDateChange,
    onIsVatRegimeApplicableChange,
    onNumberOfMonthlyRentChange,
    onRentTypeChange,
    onRentalDurationChange,
    onRentalPriceBlur,
    onIsTaxPayableByTenantChange,
  } = useRentalConditionsFields(prefix);

  const registerInput = (name: keyof RentalConditions) => ({
    ...getInputProps(name, missingFields),
    ...registerController(name),
    disabled: !editable,
  });

  const isVisible = (name: keyof RentalConditions) => {
    let visible = !hiddenFields[name];
    if (missingFields.length) {
      if (
        name === 'durationNumberOfMonths' &&
        findMissingField('duration', missingFields)
      ) {
        visible = !hiddenFields[name];
      } else {
        visible = visible && !!findMissingField(name, missingFields);
      }
    }
    return visible;
  };

  return (
    <>
      {isVisible('rentType') && (
        <Grid
          item
          container={!missingFields.length}
          xs={12}
          spacing={!missingFields.length ? 2 : undefined}
        >
          <ControlledSelect
            md={3}
            {...registerInput('rentType')}
            options={rentTypes}
            onChange={onRentTypeChange}
          />
        </Grid>
      )}
      {isVisible('contractSigningDate') && (
        <ControlledDateInput
          md={3}
          {...registerInput('contractSigningDate')}
          onChange={onContractSigningDateChange}
        />
      )}
      {isVisible('entryDate') && (
        <ControlledDateInput
          md={hiddenFields.durationNumberOfMonths ? 3 : 2.25}
          {...registerInput('entryDate')}
          onChange={onEntryDateChange}
          maxDate={addMonths(new Date(), 6)}
          minDate={
            missingFields.length ? subMonths(new Date(), 6) : subYears(new Date(), 20)
          }
        />
      )}
      {isVisible('duration') && (
        <ControlledSelect
          md={hiddenFields.durationNumberOfMonths ? 3 : 2.25}
          {...registerInput('duration')}
          onChange={onRentalDurationChange}
          options={dossierDurations}
        />
      )}
      {isVisible('durationNumberOfMonths') && (
        <ControlledNumberInput
          md={2.25}
          isInteger
          {...registerInput('durationNumberOfMonths')}
          onBlur={onDurationNumberOfMonthsBlur}
        />
      )}
      {isVisible('endDate') && (
        <ControlledDateInput
          md={hiddenFields.durationNumberOfMonths ? 3 : 2.25}
          {...registerInput('endDate')}
          disabled
        />
      )}
      {isVisible('rentFrequency') && (
        <ControlledSelect
          md={3}
          {...registerInput('rentFrequency')}
          options={rentFrequencyOptions}
        />
      )}
      {isVisible('rentalPrice') && (
        <ControlledPaymentInput
          md={3}
          {...registerInput('rentalPrice')}
          options={currencyOptions}
          onBlur={onRentalPriceBlur}
        />
      )}
      {isVisible('numberOfMonthlyRent') && (
        <ControlledSelect
          md={3}
          {...registerInput('numberOfMonthlyRent')}
          options={numberOfMonthlyRentOptions}
          onChange={onNumberOfMonthlyRentChange}
        />
      )}
      {isVisible('depositAmount') && (
        <ControlledPaymentInput
          md={3}
          {...registerInput('depositAmount')}
          options={currencyOptions}
          onBlur={({ target: { value } }) =>
            onDepositAmountChange(value ? Number(value) : null)
          }
        />
      )}
      {isVisible('charges') && (
        <Grid
          item
          container={!missingFields.length}
          xs={12}
          spacing={!missingFields.length ? 2 : undefined}
        >
          <ControlledPaymentInput
            md={3}
            {...registerInput('charges')}
            options={currencyOptions}
          />
        </Grid>
      )}
      {isVisible('isTaxPayableByTenant') && (
        <ControlledCheckbox
          md={6}
          {...registerInput('isTaxPayableByTenant')}
          onChange={(_, checked) => onIsTaxPayableByTenantChange(checked)}
        />
      )}
      {isVisible('taxPayableByTenant') && (
        <ControlledPaymentInput
          md={6}
          {...registerInput('taxPayableByTenant')}
          options={[...currencyOptions, { value: 'Percent', label: '%' }]}
          currencyFieldName="unit"
        />
      )}
      {isVisible('isVatRegimeApplicable') && (
        <ControlledCheckbox
          md={12}
          {...registerInput('isVatRegimeApplicable')}
          onChange={(_, checked) => onIsVatRegimeApplicableChange(checked)}
        />
      )}
      {isVisible('isAdditionalVatRegimeApplicable') && (
        <ControlledCheckbox
          md={12}
          {...registerInput('isAdditionalVatRegimeApplicable')}
        />
      )}
    </>
  );
};

export default RentalConditionsFields;
