import { MissingField } from 'api/missingData/types';

const findMissingField = <TName extends string>(
  name: TName,
  missingFields: MissingField<TName>[],
) =>
  missingFields.find(
    ({ propertyName }) => propertyName.toLowerCase() === name.toLowerCase(),
  );

export default findMissingField;
