export default {
  naturalPersons: 'naturalPersons',
  naturalPerson: (naturalPersonId: string) => `naturalPersons/${naturalPersonId}`,
  naturalPersonView: (naturalPersonId: string) =>
    `naturalPersons/${naturalPersonId}/natural-person-view`,
  naturalPersonDocuments: (naturalPersonId: string) =>
    `naturalPersons/${naturalPersonId}/documents`,
  naturalPersonDocument: (naturalPersonId: string, documentId: number) =>
    `naturalPersons/${naturalPersonId}/documents/${documentId}`,
  naturalPersonDocumentFile: (
    naturalPersonId: string,
    documentId: number,
    fileId: number,
  ) => `naturalPersons/${naturalPersonId}/documents/${documentId}/files/${fileId}`,
};
