import { Box, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  value: string | string[];
}

const ProductActionInfoRow = ({ title, value }: Props) => (
  <Box display="flex" width="100%" justifyContent="space-between">
    <Typography color="text.secondary">{title}</Typography>
    {Array.isArray(value) ? (
      <Stack alignItems="flex-end">
        {value.map((item) => (
          <Typography key={item} fontWeight="bold">
            {item}
          </Typography>
        ))}
      </Stack>
    ) : (
      <Typography fontWeight="bold">{value}</Typography>
    )}
  </Box>
);

export default ProductActionInfoRow;
