import { BlockIcon, ErrorIcon } from 'icons';

interface Props {
  isError?: boolean;
  isDisabled?: boolean;
}

const StepIcon = ({ isDisabled, isError }: Props) => {
  if (isError) {
    return <ErrorIcon color="error" />;
  }
  if (isDisabled) {
    return <BlockIcon color="disabled" />;
  }
  return null;
};

export default StepIcon;
