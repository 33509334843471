import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import useMenu from 'hooks/useMenu';
import { useInterval } from 'usehooks-ts';
import { getNotificationsSummary } from 'api/notifications/requests';
import { NotificationsWithCount } from './types';
import { countAllNotifications, getNotificationsFromSummary } from './utils';

const NOTIFICATIONS_INTERVAL = 300000; // 5 minutes

const useNotificationsMenu = () => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [notifications, setNotifications] = useState<NotificationsWithCount>({
    actionRequired: {},
    noAction: {},
  });

  const { anchorEl, closeMenu, openMenu } = useMenu();

  const fetchNotificationsSummary = useCallback(
    async (shouldFetchNotifications = true) => {
      setLoading(true);
      const { ok, response } = await getNotificationsSummary();
      if (ok) {
        setTotalCount(countAllNotifications(response));
        if (shouldFetchNotifications) {
          const notifications = await getNotificationsFromSummary(response);
          setNotifications(notifications);
        }
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    fetchNotificationsSummary(false);
  }, [fetchNotificationsSummary]);

  useInterval(() => {
    fetchNotificationsSummary(Boolean(anchorEl));
  }, NOTIFICATIONS_INTERVAL);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchNotificationsSummary(Boolean(anchorEl));
      }
    };
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [fetchNotificationsSummary, anchorEl]);

  const onMenuClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    openMenu(e);
    fetchNotificationsSummary();
  };

  return {
    menuProps: {
      anchorEl,
      open: !!anchorEl,
    },
    notifications,
    totalCount,
    loading,
    closeMenu,
    openMenu: onMenuClick,
    fetchNotificationsSummary,
  };
};

export default useNotificationsMenu;
