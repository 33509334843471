import usePostCodesAndCities from 'hooks/useAddressFields/usePostCodesAndCities';
import useStreetTranslation from 'hooks/useAddressFields/useStreetTranslation';
import AddressRawFields from './AddressRawFields';
import { AddressFieldsProps } from './types';

const AddressFields = (props: AddressFieldsProps) => {
  const postCodesAndCitiesProps = usePostCodesAndCities(props.prefix);
  const streetProps = useStreetTranslation(props.prefix);

  return <AddressRawFields {...props} {...streetProps} {...postCodesAndCitiesProps} />;
};

export default AddressFields;
