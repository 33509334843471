import { TranslatedOption } from 'formData/types';
import { AssignableUserRole } from 'api/users/types';

export const getUserRolesOptions = (
  roles: AssignableUserRole[],
): TranslatedOption<AssignableUserRole>[] =>
  roles.map((role) => ({
    value: role,
    label: role,
    translationKey: `userRoles.${role}`,
  }));
