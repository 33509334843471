import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { EstatePermissions } from 'consts/permissions';
import { Documents } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { EstateViewInterface } from 'api/estate/types';

const EstateDocumentsTab = () => {
  const { id } = useOutletContext<EstateViewInterface>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} marginBottom="auto">
        <Documents
          entityId={id}
          scope="estate"
          editable={hasPermission(EstatePermissions.Write)}
        />
      </Grid>
    </Grid>
  );
};

export default EstateDocumentsTab;
