import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, Stack, Typography } from '@mui/material';
import { DateCalendar, DateView } from '@mui/x-date-pickers';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import Button from 'atoms/Button';
import { isAfter, isBefore } from 'date-fns';
import ModalTitle from 'components/modals/ModalTitle';
import CustomCalendarDay from './CustomCalendarDay';
import { DateRangeCalendarProps } from './types';
import { getDateRangeInputValue, isBetween } from './utils';

const DateRangeCalendar = ({
  initValues: [initDateFrom, initDateTo],
  onClear,
  onSave,
  onClose,
}: DateRangeCalendarProps) => {
  const [dateFrom, setDateFrom] = useState(initDateFrom);
  const [dateTo, setDateTo] = useState(initDateTo);
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);
  const [selection, setSelection] = useState<'from' | 'to'>(initDateFrom ? 'to' : 'from');

  const [viewDate, setViewDate] = useState(initDateFrom);
  const [view, setView] = useState<DateView>('day');

  const { t } = useTranslation();

  const handleDaySelection = (newValue: Date | null, state?: PickerSelectionState) => {
    if (!newValue) {
      return;
    }

    // MUI needs DateCalendar value to set correct date after selecting year and month;
    // It seems to be a bug, so creating viewDate state is kind of a fix;
    // Selecting year or month -> state === 'partial', selecting day -> state === 'finish';
    // After selecting a month, viewDate must be cleared to not display the 1st day of the month as selected date
    if (view === 'month' || state === 'finish') {
      setViewDate(null);
    } else if (state === 'partial') {
      setViewDate(newValue);
    }

    if (state === 'finish') {
      if (selection === 'from') {
        if (
          dateFrom &&
          dateTo &&
          isAfter(newValue, dateFrom) &&
          !isBetween(dateFrom, dateTo, newValue)
        ) {
          setDateTo(newValue);
        } else {
          setDateFrom(newValue);
          setSelection('to');
        }
      } else {
        if (dateFrom && !dateTo && isBefore(newValue, dateFrom)) {
          setDateFrom(newValue);
          setDateTo(dateFrom);
          setSelection('from');
        } else if (dateFrom && dateTo && isBefore(newValue, dateFrom)) {
          setDateFrom(newValue);
        } else {
          setDateTo(newValue);
          setSelection('from');
        }
      }
    }
  };

  return (
    <>
      <ModalTitle closeModal={onClose} noMinWidth>
        <Stack rowGap={1}>
          <Typography variant="h6">{t('selectedDateRange')}</Typography>
          <Typography variant="h5">{getDateRangeInputValue(dateFrom, dateTo)}</Typography>
        </Stack>
      </ModalTitle>
      <DateCalendar
        sx={{ '.MuiPickersSlideTransition-root': { minHeight: '260px' } }}
        views={['year', 'month', 'day']}
        onViewChange={setView}
        value={viewDate}
        onChange={handleDaySelection}
        slots={{ day: CustomCalendarDay }}
        showDaysOutsideCurrentMonth
        slotProps={{
          day: (ownerState) => ({
            dateFrom,
            dateTo,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
          }),
        }}
      />

      <DialogActions>
        <Button onClick={onClear} role="text" size="small">
          {t('clear')}
        </Button>
        <Button onClick={() => onSave(dateFrom, dateTo)} size="small">
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default DateRangeCalendar;
