import { Chip } from '@mui/material';
import { renderPersonIcon } from 'utils/ui';
import { PersonType } from 'api/person/types';

interface Person {
  name: string;
  type: PersonType;
}

const PersonChip = ({ name, type }: Person) => (
  <Chip
    sx={({ mixins }) => mixins.tableChip}
    label={name}
    icon={renderPersonIcon(type)}
    color="primary"
    size="small"
    variant="outlined"
  />
);

export default PersonChip;
