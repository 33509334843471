import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { BackofficePermissions } from 'consts/permissions';
import { languages } from 'i18n';
import { LanguageIcon } from 'icons';
import { Language } from 'types/language';
import hasPermission from 'utils/hasPermission';
import { updateUserLanguage } from 'api/config/requests';
import { LanguageType } from 'api/person/types';

const languagesMap: Record<Language, LanguageType> = {
  nl: 'Dutch',
  en: 'English',
  fr: 'French',
};

interface Props {
  closeMenu: () => void;
}

const LanguageItem = ({ closeMenu }: Props) => {
  const [languagesExpanded, setLanguagesExpanded] = useState(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => () => {
    if (lng !== 'cimode') {
      updateUserLanguage(languagesMap[lng as Language]);
    }

    i18n.changeLanguage(lng.toLowerCase());
    setLanguagesExpanded(false);
    closeMenu();
  };

  const visibleLanguages = useMemo(
    () =>
      hasPermission(BackofficePermissions.Read) ||
      window._env_.REACT_APP_ENVIRONMENT === 'TEST' ||
      window._env_.REACT_APP_ENVIRONMENT === 'UAT'
        ? [...languages, 'cimode']
        : languages,
    [],
  );

  return (
    <>
      <MenuItem onClick={() => setLanguagesExpanded((prev) => !prev)}>
        <ListItemIcon>
          <LanguageIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('accountMenu.language')}</ListItemText>
        <Box display="flex" alignItems="center" pl={1}>
          {languagesExpanded ? (
            <ExpandLess color="action" fontSize="small" />
          ) : (
            <ExpandMore color="action" fontSize="small" />
          )}
        </Box>
      </MenuItem>
      <Collapse in={languagesExpanded} timeout="auto" unmountOnExit>
        {visibleLanguages.map((lng) => (
          <MenuItem
            key={lng}
            onClick={changeLanguage(lng)}
            selected={
              i18n.language === undefined ? lng === 'cimode' : i18n.language === lng
            }
          >
            <ListItemIcon />
            {t(`enums:translations.${lng}`, lng)}
          </MenuItem>
        ))}
      </Collapse>
    </>
  );
};

export default LanguageItem;
