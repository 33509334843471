import i18n from 'i18n';
import getLng from 'utils/getLng';
import { testStringToJSON } from 'utils/string';
import { TranslationFieldInterface } from 'api/common/types';
import { AddressToFormat, AddressToString } from './types';

const formatField = (field: string | TranslationFieldInterface) => {
  if (typeof field === 'string') {
    return testStringToJSON(field) ? JSON.parse(field)[getLng()] : field;
  }
  return field[getLng()];
};

export const getAddressCityPart = (
  { city, postCode }: AddressToFormat,
  withPostCode = true,
) => {
  let cityPart = '';
  if (city) {
    cityPart = formatField(city);
  }
  if (withPostCode && postCode) {
    cityPart = `${postCode} ${cityPart}`;
  }

  return cityPart;
};

export const addressToString: AddressToString = (addressToFormat, config) => {
  const { isFullAddress = true, withPostCode = true } = config ?? {};
  let address = '';

  if ('street' in addressToFormat) {
    const { street, houseNumber, extension, apartmentNumber } = addressToFormat;
    address = `${street && formatField(street)} ${houseNumber ?? ''}${extension ?? ''} ${
      apartmentNumber ? `${i18n.t('addressBox')} ${apartmentNumber}` : ''
    }`.trim();
  }

  const cityPart = getAddressCityPart(addressToFormat, withPostCode);

  return isFullAddress && cityPart ? `${address}, ${cityPart}` : address;
};
