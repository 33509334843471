import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { NaturalPersonFormContext } from 'contexts/personForm/naturalPersonFormContext';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import AddRelation from 'modules/Relation/AddRelation';
import {
  fetchPersonRelationOptionsByType,
  getRelationErrorMessage,
  personToRelationOption,
} from 'modules/Relation/utils';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { PrivateRelationType } from 'api/relations/types';

const PRIVATE_RELATION_TYPES: PrivateRelationType[] = [
  'LegallyLivingTogether',
  'Marriage',
];

const PrivateRelationSection = () => {
  const {
    personId,
    privateRelationType,
    setPrivateRelation,
    setPrivateRelationType,
    privateRelation,
  } = useContext(NaturalPersonFormContext);
  const { getValues } = useFormContext<NaturalPersonFormData>();

  const { t } = useTranslation('enums');

  return (
    <Grid item xs={12} display="flex" gap={2}>
      <RadioGroup
        name="relations"
        value={privateRelationType}
        sx={{ flexDirection: 'row', width: '100%' }}
        onChange={(_e, value) => setPrivateRelationType(value as PrivateRelationType)}
      >
        {PRIVATE_RELATION_TYPES.map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={<Radio />}
            label={t(`privateRelation.${type}`)}
          />
        ))}
      </RadioGroup>
      <Stack width="100%">
        <AddRelation
          translationKey="privateRelation"
          withLabel={false}
          defaultOption={privateRelation ? personToRelationOption(privateRelation) : null}
          fetchOptions={fetchPersonRelationOptionsByType('NaturalPerson')}
          relationsToFilter={personId ? [personId] : []}
          onSelectOption={(data) => setPrivateRelation(data)}
          onInputClear={() => setPrivateRelation(null)}
          fillInputWithOption
          errorMessage={getRelationErrorMessage(privateRelation)}
          AddNewOptionModal={AddNaturalPersonModal}
          getInitialNewOptionValues={() => ({
            address: getValues('address'),
            email: getValues('email'),
          })}
        />
      </Stack>
    </Grid>
  );
};

export default PrivateRelationSection;
