import { PropsWithChildren } from 'react';
import { Box, Breakpoint, Card, CardContent } from '@mui/material';
import { AddIcon } from 'icons';
import { cardWithPlusStyles } from './styles';

interface Props extends PropsWithChildren {
  maxWidth?: Partial<Record<Breakpoint, string>> | 'unset';
  minHeight?: number;
}

const CardWithPlus = ({ children, maxWidth = 'unset', minHeight = 300 }: Props) => (
  <Card variant="outlined" sx={{ minHeight, maxWidth }}>
    <CardContent sx={cardWithPlusStyles}>
      <Box className="cover">
        <AddIcon color="primary" fontSize="inherit" />
      </Box>
      <Box className="content">{children}</Box>
    </CardContent>
  </Card>
);

export default CardWithPlus;
