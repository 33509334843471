import { Box } from '@mui/material';

interface Props {
  title: React.ReactNode;
  value?: React.ReactNode;
  footer?: React.ReactNode;
  gap?: number;
  asHeader?: boolean;
  testId?: string;
}

const CardRow = ({ title, value, footer, gap = 1, asHeader = false, testId }: Props) => (
  <Box
    display="grid"
    gridTemplateColumns="auto auto"
    justifyContent="space-between"
    alignItems="center"
    gap={gap}
    py={1.5}
    px={2}
    sx={{
      bgcolor: ({ palette }) => (asHeader ? palette.primary.main : palette.common.white),
      color: ({ palette }) => (asHeader ? palette.common.white : palette.common.black),
      '&:not(:last-of-type)': {
        borderBottom: ({ shape }) => shape.border,
      },
    }}
  >
    <Box
      fontWeight={500}
      fontSize={asHeader ? 16 : 14}
      lineHeight={1.4}
      data-testid={`${testId}-title`}
    >
      {title}
    </Box>
    {value ? (
      <Box
        display="flex"
        justifyContent="flex-end"
        fontWeight={600}
        textAlign="right"
        data-testid={`${testId}-value`}
      >
        {value}
      </Box>
    ) : null}
    {footer ? (
      <Box gridColumn="1 / span 2" lineHeight={0} data-testid={`${testId}-footer`}>
        {footer}
      </Box>
    ) : null}
  </Box>
);
export default CardRow;
