import { TKey } from 'i18next';
import yup, { numberSchema } from 'utils/yup';

const epcSchema = yup
  .object({
    type: yup
      .string<TKey<'enums', 'estateEpcTypes'>>()
      .label('epc.type')
      .nullable()
      .default(null)
      .customWhen({
        is: ({ grandParent: estate, parent: epc }) =>
          !!estate.hasEpc && !epc.referenceEnergyConsumption,
        then: (schema) => schema.required(),
      }),
    primaryEnergyConsumption: yup
      .string()
      .label('epc.primaryEnergyConsumption')
      .nullable()
      .default(null),
    referenceEnergyConsumption: numberSchema
      .label('epc.referenceEnergyConsumption')
      .nullable()
      .default(null),
    validUntil: yup
      .string()
      .label('epc.validUntil')
      .nullable()
      .default(null)
      .customDate(),
  })
  .nullable()
  .default(null)
  .when('hasEpc', { is: false, then: (schema) => schema.transform(() => null) });

export default epcSchema;
