import { Grid } from '@mui/material';
import InputLabelWrapper from './InputLabelWrapper';
import { InputWrapperProps } from './types';

const InputWrapper = <T extends InputWrapperProps>(
  Component: (props: T) => JSX.Element,
  {
    label,
    labelVariant = 'float',
    labelTooltip,
    wrapWithGrid = true,
    xs,
    sm,
    md,
    lg,
    xl,
    ...props
  }: T,
) => {
  if (labelVariant === 'separate') {
    return (
      <InputLabelWrapper label={label} labelTooltip={labelTooltip}>
        <Component {...(props as T)} />
      </InputLabelWrapper>
    );
  }

  if (wrapWithGrid) {
    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Component {...(props as T)} label={label} />
      </Grid>
    );
  }

  return <Component {...(props as T)} label={label} />;
};

export default InputWrapper;
