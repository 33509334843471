import { useTranslation } from 'react-i18next';
import { Box, Card, Stack, Theme, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import { EstateIcon } from 'icons';
import routes from 'router/routes';
import CardRow from 'templates/CardRow';
import { groupAddressValues } from 'templates/CardRow/utils';
import hasRole from 'utils/hasRole';
import { DossierViewInterface } from 'api/dossier/types';

const DossierEstateCard = ({
  estate: { id, type, address },
}: Pick<DossierViewInterface, 'estate'>) => {
  const { t } = useTranslation('enums');
  const { primary, secondary } = groupAddressValues(address);

  const linkProps = hasRole('Admin')
    ? {}
    : {
        component: CustomLink,
        to: routes.estateTab(id).estateInfo,
        sx: ({ mixins }: Theme) => mixins.link,
      };

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow
        title={
          <Box display="flex" alignItems="center" gap={1.5}>
            <EstateIcon />
            <Stack>
              <Typography {...linkProps} variant="h5">
                {primary}
              </Typography>
              <Typography variant="caption">{secondary}</Typography>
            </Stack>
          </Box>
        }
        value={type ? t(`estateType.${type}`, type) : ''}
      />
    </Card>
  );
};

export default DossierEstateCard;
