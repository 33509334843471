import { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import CustomLink from 'atoms/Link';
import { ErrorIcon, EstateIcon, LegalPersonIcon, NaturalPersonIcon } from 'icons';
import { RelationData } from './types';
import { getRelationErrorMessage } from './utils';

interface Props<T> {
  relation: T;
  isPreview?: boolean;
  showIcon?: boolean;
  withLink?: boolean;
}

const RelationDetails = <T extends RelationData>({
  relation,
  isPreview,
  showIcon,
  withLink = true,
}: Props<T>) => {
  const errorMessage = useMemo(
    () => getRelationErrorMessage(relation),
    [relation.errorMessage],
  );

  const Icon = () => {
    if ('type' in relation) {
      switch (relation.type) {
        case 'LegalPerson':
          return <LegalPersonIcon />;
        case 'NaturalPerson':
          return <NaturalPersonIcon />;
      }
    }
    return <EstateIcon />;
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {!!errorMessage && (
        <IconTooltip title={errorMessage}>
          <ErrorIcon color="error" />
        </IconTooltip>
      )}
      {showIcon && <Icon />}
      <Stack>
        {withLink && relation.link ? (
          <Typography
            variant="h5"
            component={CustomLink}
            sx={({ mixins }) => mixins.link}
            fontWeight="bold"
            to={relation.link}
            target={isPreview ? '_blank' : '_self'}
            rel="noopener noreferrer"
          >
            {relation.name}
          </Typography>
        ) : (
          <Typography variant="h5" color="primary.main">
            {relation.name}
          </Typography>
        )}
        {relation.details && (
          <Typography variant="caption">{relation.details}</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default RelationDetails;
