import { useTheme } from '@mui/material';

interface Props {
  size?: 'medium' | 'small';
}

export const Logo = ({ size = 'medium' }: Props) => {
  const theme = useTheme();

  return size === 'medium' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 475.88 73.8"
      height={20}
    >
      <path
        d="M65.37 67.43q0 4.32-5.19 4.32-4.91 0-6-.53l-42-21v17c0 2.18-.82 3.57-2.45 4.2-.51.27-2.24.41-5.19.41S0 70.21 0 67.14V5.27Q0 .73 4.55.72a22.39 22.39 0 0 1 5.25.35q2.38.81 2.39 4.2v32.25h.06l41.46-20.76a22.56 22.56 0 0 1 5.59-.47c3.47 0 5.19 1.42 5.19 4.26q0 2.79-3.14 4.37L23.67 43.7 62.22 63q3.15 1.57 3.15 4.43ZM152.32 54.37q0 9.56-9.45 14-6.69 3.39-17.84 3.39h-20.47a47.28 47.28 0 0 1-18.37-3.44q-10.14-4.57-10.14-13.95V33.78q0-9.39 10.09-14.22 7.39-3.73 18.36-3.74H125q27.3 0 27.29 17.85Zm-12.48 0V32.85q0-6.64-15.16-6.65h-20a33.34 33.34 0 0 0-10 1.4q-6.3 2-6.3 6.18v20.59q0 4 6.42 5.83a36.07 36.07 0 0 0 10 1.22h20a29.61 29.61 0 0 0 9.1-1.42c3.96-1.26 5.94-3.14 5.94-5.63Z"
        fill={theme.palette.primary[700]}
      />
      <path
        d="M111.1 53.52v-6.81q0-2.12-4.81-2.11H100a10.77 10.77 0 0 0-3.18.44c-1.33.43-2 1.09-2 2v6.52c0 .84.68 1.45 2 1.85a11.45 11.45 0 0 0 3.16.38h6.33a9.22 9.22 0 0 0 2.9-.44c1.26-.45 1.89-1.04 1.89-1.83Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M203 21.65c0 3.08-1.82 4.61-5.48 4.61q-.75 0-2.19-.09c-1-.05-1.68-.08-2.18-.08q-14.15 0-14.15 7.05v34q0 3.38-2.57 4.26a23.67 23.67 0 0 1-5.36.35q-4.38 0-4.38-4.67V33.14q0-11 11.26-14.87 6.88-2.44 20.47-2.45c3.05 0 4.58 1.95 4.58 5.83ZM256 20.84q0 5.72-4.55 5.71h-18.76v40.59c0 3.07-1.51 4.61-4.55 4.61s-4.8-.14-5.3-.41c-1.64-.59-2.45-2-2.45-4.2v-50Q220.39.66 248 .66q4.72 0 5.89.53 2.16 1 2.16 5.19 0 4.55-4.55 4.55a102.83 102.83 0 0 0-10.79.34q-7.81 1.17-7.81 5h18.6q4.5.02 4.5 4.57ZM283.72 67.14q0 4.6-4.6 4.61c-3 0-4.75-.14-5.25-.41-1.59-.59-2.39-2-2.39-4.2V20.2c0-3 1.54-4.55 4.61-4.55s4.68.13 5.18.41c1.64.58 2.45 2 2.45 4.14ZM377.33 67.14q0 4.6-4.61 4.61c-3 0-4.8-.14-5.31-.41-1.67-.59-2.51-2-2.51-4.2V31.57c0-2.53-2.09-4.18-6.29-5a23.28 23.28 0 0 0-3.42-.32c-1.53-.06-3.47-.09-5.8-.09h-21.22q-3.21 0-5.4.12a28.56 28.56 0 0 0-3.47.35c-4 .78-5.95 2.43-5.95 5v35.51q0 4.6-4.54 4.61c-3 0-4.8-.14-5.31-.41-1.63-.59-2.45-2-2.45-4.2v-35.4q0-15.91 28.22-15.92h20.12q27.93 0 27.94 15.81Z"
        fill={theme.palette.primary[700]}
      />
      <path
        d="m441.69 54.65-.44.54c-.87 1.06-1.6 1.88-2.15 2.43A21.46 21.46 0 0 1 423.48 64c-5.2 0-9.53-1.35-12.88-4l37.82-37.81-.65-.71c-.85-.92-1.43-1.56-1.78-1.95-.9-.91-1.83-1.75-2.76-2.53a31.83 31.83 0 0 0-51.48 25 30.76 30.76 0 0 0 9.3 22.49 31.75 31.75 0 0 0 44.94 0 43.76 43.76 0 0 0 4.37-5.38l.76-1.08ZM423.48 20a20.17 20.17 0 0 1 11.11 2.81l-30.34 30.31A20.26 20.26 0 0 1 401.44 42a22.06 22.06 0 0 1 22-22Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M286.05 11.64V4.82q0-2.1-4.8-2.1h-6.34a10.34 10.34 0 0 0-3.17.44c-1.33.43-2 1.08-2 2v6.52c0 .83.68 1.45 2 1.84a11.47 11.47 0 0 0 3.16.39h6.34a9.51 9.51 0 0 0 2.9-.44c1.28-.47 1.91-1.05 1.91-1.83Z"
        fill={theme.palette.primary[700]}
      />
      <path
        d="M465.83 0a9.9 9.9 0 1 1-.06 19.8 10 10 0 0 1-10.17-9.93A9.94 9.94 0 0 1 465.77 0Zm-.12 2a7.7 7.7 0 0 0-7.59 7.88 7.68 7.68 0 0 0 7.71 7.88 7.56 7.56 0 0 0 7.52-7.82A7.67 7.67 0 0 0 465.77 2Zm-1.51 13h-2.29V5.12a26.71 26.71 0 0 1 3.8-.31 5.28 5.28 0 0 1 3.37.79 2.48 2.48 0 0 1 1 2.1 2.54 2.54 0 0 1-2.05 2.35v.12c1 .3 1.45 1.08 1.75 2.41a9 9 0 0 0 .72 2.41h-2.41a8 8 0 0 1-.78-2.41c-.18-1-.78-1.51-2.05-1.51h-1.06Zm.06-5.54h1.09c1.26 0 2.28-.42 2.28-1.45s-.66-1.5-2.1-1.5a5.5 5.5 0 0 0-1.27.12Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  ) : (
    <svg
      id="Laag_1"
      data-name="Laag 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      height={28}
    >
      <path
        d="m150,95.8c0,12.53-6.2,21.71-18.59,27.53-8.77,4.44-20.47,6.67-35.09,6.67h-40.26c-12.37.17-24.66-2.13-36.13-6.77C6.65,117.25,0,108.1,0,95.8v-40.49c0-12.31,6.61-21.63,19.84-27.97,9.69-4.89,21.73-7.34,36.11-7.36h40.32c35.79,0,53.68,11.7,53.67,35.11l.06,40.71Zm-24.54,0v-42.32c0-8.71-9.94-13.07-29.82-13.08h-39.33c-6.66-.09-13.29.84-19.67,2.75-8.26,2.62-12.39,6.67-12.39,12.15v40.49c0,5.24,4.21,9.07,12.63,11.47,6.41,1.72,13.03,2.52,19.67,2.4h39.33c6.07,0,12.11-.93,17.9-2.79,7.79-2.48,11.68-6.18,11.68-11.07h0Z"
        fill={theme.palette.primary[700]}
      />
      <path
        d="m68.93,81.54v-13.39c0-2.78-3.15-4.16-9.46-4.15h-12.37c-2.12-.03-4.22.27-6.25.87-2.62.85-3.93,2.14-3.93,3.93v12.82c0,1.65,1.34,2.85,3.93,3.64,2.03.54,4.12.79,6.21.75h12.45c1.94.02,3.86-.27,5.7-.87,2.48-.89,3.72-2.05,3.72-3.6Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
};
