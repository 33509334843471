import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  EstateAdditionalDataCard,
  EstateBasicDataCard,
  EstateCompositionCard,
  EstateDescriptionCard,
  EstateElectricityCard,
  EstateEpcCard,
  EstateGasCard,
} from 'modules/EstateView';
import routes from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { EstateViewInterface } from 'api/estate/types';
import {
  isEstateAdditionalData,
  isEstateMedia,
  shouldDisplayEstateInfoTab,
} from '../utils';

const EstateInfoTab = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { id, data, owners, status, ownReference } =
    useOutletContext<EstateViewInterface>();

  if (matches && !shouldDisplayEstateInfoTab(data)) {
    return <Navigate to={routes.estateTab(id).dossiers} replace />;
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={!isEstateAdditionalData(data) ? 12 : 6}
        marginBottom="auto"
        display={{ lg: 'none' }}
      >
        <EstateBasicDataCard data={data} status={status} ownReference={ownReference} />
      </Grid>
      {isEstateAdditionalData(data) && (
        <Grid item xs={12} md={6} lg={12} xl={isEstateMedia(data) ? 6 : 12} mb="auto">
          <EstateAdditionalDataCard data={data} />
        </Grid>
      )}
      {data.descriptions && (
        <Grid item xs={12} display={{ lg: 'none' }}>
          <EstateDescriptionCard descriptions={data.descriptions} />
        </Grid>
      )}
      {!!owners.length && (
        <Grid item xs={12} display={{ lg: 'none' }}>
          <AffiliatedPersonsCard persons={owners} title="owners" />
        </Grid>
      )}
      {data.hasElectricity && (
        <Grid item xs={12} md={6} lg={12} xl={6} mb="auto">
          <EstateElectricityCard electricity={data.electricity} />
        </Grid>
      )}
      {data.hasEpc && (
        <Grid item xs={12} md={6} lg={12} xl={6} mb="auto">
          <EstateEpcCard epc={data.epc} />
        </Grid>
      )}
      {data.hasGas && (
        <Grid item xs={12} md={6} lg={12} xl={6} mb="auto">
          <EstateGasCard gas={data.gas} />
        </Grid>
      )}
      {data.estateComposition && (
        <Grid item xs={12}>
          <EstateCompositionCard estateComposition={data.estateComposition} />
        </Grid>
      )}
    </Grid>
  );
};

export default EstateInfoTab;
