import { useCallback, useEffect, useState } from 'react';
import useFormMethods from 'hooks/useFormMethods';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { AddressFormInterface } from 'api/common/types';
import { getPostCodesWithCity } from 'api/config/requests';
import { PostCodesWithCities } from 'api/config/types';
import { FetchPostCodesWithCities, PostCodeAndCityOption } from './types';
import useLanguage from './useLanguage';
import {
  createCityOption,
  createPostCodeOption,
  getInitPostCodeAndCityParams,
  isCountryBelgium,
  translateCity,
} from './utils';

const usePostCodesAndCities = (prefix: string | undefined) => {
  const lng = useLanguage();
  const { getValues, watch } = useFormMethods<{ address: AddressFormInterface }>(prefix);

  const [postCodes, setPostCodes] = useState<PostCodeAndCityOption[]>([]);
  const [cities, setCities] = useState<PostCodeAndCityOption[]>([]);
  const [loading, setLoading] = useState(false);

  const initParams = getInitPostCodeAndCityParams(getValues('address'));

  const [params, setParams] = useState(initParams);
  const [inputs, setInputs] = useState(initParams);
  const debouncedInputs = useDebounce<PostCodesWithCities>(inputs, 500);

  useUpdateEffect(() => {
    setParams(debouncedInputs);
  }, [debouncedInputs.city, debouncedInputs.postCode]);

  const updateParams = (
    newParams: Partial<PostCodesWithCities>,
    withDebounce = false,
  ) => {
    if (withDebounce) {
      setInputs((prev) => ({ ...prev, ...newParams }));
    } else {
      setParams((prev) => ({ ...prev, ...newParams }));
      setInputs((prev) => ({ ...prev, ...newParams }));
    }
  };

  const fetchPostCodesWithCities: FetchPostCodesWithCities = useCallback(
    async ({ postCode, city }) => {
      if (postCode || city) {
        setLoading(true);
        const { ok, response } = await getPostCodesWithCity({
          postCode,
          city: translateCity(city),
          lng,
        });
        setLoading(false);
        if (ok && response) {
          const data = response.map(({ city, postCode }) => ({
            city: JSON.stringify(city),
            postCode,
          }));
          setPostCodes(data.map(createPostCodeOption));
          setCities(data.map(createCityOption));
          return response;
        }
      }
      setPostCodes([]);
      setCities([]);
      return [];
    },
    [lng],
  );

  useUpdateEffect(() => {
    const isBelgium = isCountryBelgium(watch('address.country'));
    if (isBelgium) {
      fetchPostCodesWithCities(params);
    }
  }, [lng]);

  useEffect(() => {
    fetchPostCodesWithCities(params);
  }, [params.city, params.postCode]);

  return {
    fetchPostCodesWithCities,
    fetchingPostCodesWithCities: loading,
    postCodes,
    cities,
    updatePostCodeAndCityParams: updateParams,
    postCodeAndCityParams: params,
  };
};

export default usePostCodesAndCities;
