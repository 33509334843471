import { FieldArray, UseFieldArrayUpdate, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ClearIcon } from 'icons';
import { transformToAddendumFormPerson } from 'modules/ProductRentalContract/RegisterAddendum/utils';
import { renderPersonIcon } from 'utils/ui';
import {
  AddendumFormPerson,
  RegisterAddendumForm,
} from 'api/dossier/products/rentalContract/types';
import { FetchedNaturalPersonData } from 'api/person/natural/types';
import { PersonType } from 'api/person/types';
import { Address } from './sections/Address';
import { BasicInformation } from './sections/BasicInformation';
import { RepresentativeSelector } from './RepresentativeSelector';

interface ContactsStepProps<T extends 'landlords' | 'tenants'> {
  index: number;
  sectionType: T;
  name: string;
  toggleAccordion: (id: string) => () => void;
  expanded: boolean;
  personType: PersonType;
  companyName?: string;
  remove: (index: number) => void;
  legalPersonId?: string;
  id: string;
  update: UseFieldArrayUpdate<RegisterAddendumForm, T>;
}

export const ContactsStep = <T extends 'landlords' | 'tenants'>({
  index,
  sectionType,
  name,
  toggleAccordion,
  expanded,
  personType,
  companyName,
  legalPersonId,
  id,
  update,
  remove,
}: ContactsStepProps<T>) => {
  const { t } = useTranslation('modals');

  const { formState } = useFormContext<RegisterAddendumForm>();

  const updateRepresentative = (personData: FetchedNaturalPersonData) => {
    const defaultValues = formState.defaultValues as RegisterAddendumForm;
    const persons = defaultValues[sectionType];
    const samePerson = persons.find(
      ({ id, companyName }) => id === personData.id && !!companyName,
    );
    if (samePerson) {
      update(index, {
        ...samePerson,
        legalPersonId,
      } as FieldArray<RegisterAddendumForm, typeof sectionType>);
    } else {
      const transformed: AddendumFormPerson = transformToAddendumFormPerson(
        personData,
        'NaturalPerson',
        companyName,
      );
      update(index, {
        ...transformed,
        legalPersonId,
      } as FieldArray<RegisterAddendumForm, typeof sectionType>);
    }
    toggleAccordion(personData.id)();
  };

  return (
    <Accordion
      disableGutters
      variant="outlined"
      expanded={expanded}
      onChange={toggleAccordion(id)}
      sx={{ mt: 1 }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexDirection: 'row-reverse' }}>
        <Grid container justifyContent="space-between" ml={2}>
          <Grid>
            <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
              {renderPersonIcon(personType)}
              <Typography fontWeight="bold">
                {companyName ? t('data.representative', { companyName }) : name}
              </Typography>
            </Stack>
          </Grid>
          <Grid>
            {!companyName && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  remove(index);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {personType === 'NaturalPerson' && legalPersonId && (
          <RepresentativeSelector
            legalPersonId={legalPersonId}
            value={id}
            updateRepresentative={updateRepresentative}
          />
        )}
        <BasicInformation type={sectionType} index={index} personType={personType} />
        <Address type={sectionType} index={index} />
      </AccordionDetails>
    </Accordion>
  );
};
