import { SvgIconProps } from '@mui/material';
import {
  CourtIcon,
  EndStateReport,
  FireIcon,
  IntermediateStateReport,
  MoveIcon,
  RentalContract,
  RentalDeposit,
  StateReport,
} from 'icons';
import { ProductName } from 'api/dossier/products/types';

interface Props extends SvgIconProps {
  name: ProductName;
}

const ProductIcon = ({ name, ...props }: Props) => {
  switch (name) {
    case 'LegalAssistance':
      return <CourtIcon {...props} />;
    case 'MyMove':
      return <MoveIcon {...props} />;
    case 'FireInsurance':
      return <FireIcon {...props} />;
    case 'StateReport':
      return <StateReport {...props} />;
    case 'IntermediateStateReport':
      return <IntermediateStateReport {...props} />;
    case 'RentalDeposit':
      return <RentalDeposit {...props} />;
    case 'EndStateReport':
      return <EndStateReport {...props} />;
    case 'RentalContract':
      return <RentalContract {...props} />;
    default:
      return null;
  }
};

export default ProductIcon;
