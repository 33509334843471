import { BaseSyntheticEvent, useContext, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { NaturalPersonFormContext } from 'contexts/personForm/naturalPersonFormContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { addNaturalPerson, updateNaturalPerson } from 'api/person/natural/requests';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { NaturalPersonFormProps } from '../types';

interface Props {
  onSaveSuccess: NaturalPersonFormProps['handleSave'];
}

const useSubmitNaturalPersonForm = ({ onSaveSuccess }: Props) => {
  const { saveRelations, personId } = useContext(NaturalPersonFormContext);
  const [savedId, setSavedId] = useState(personId);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { reset, formState } = useFormContext<NaturalPersonFormData>();

  const {
    saveDocuments,
    isAnyDocumentEmpty,
    markEmptyDocuments,
    documents,
    validateDocuments,
  } = useContext(FilesUploadContext);

  const saveForm = async (
    event: BaseSyntheticEvent | undefined,
    person: NaturalPersonFormData,
  ) => {
    let isAnyDocumentToSave = false;
    let shouldProceed = true;
    let personId = savedId;

    if (documents.length && documents[0].documentType) {
      const onValid = () => {
        isAnyDocumentToSave = true;
      };
      const onError = () => {
        shouldProceed = false;
      };
      await validateDocuments(event, onValid, onError);
    }

    if (shouldProceed && formState.isDirty) {
      if (personId) {
        const { ok } = await updateNaturalPerson(personId, person);
        shouldProceed = ok;
      } else {
        const { ok, response } = await addNaturalPerson(person);
        shouldProceed = ok;
        personId = ok ? response.id : null;
      }
    }

    if (shouldProceed && personId) {
      setSavedId(personId);
      reset(person, { keepDirty: false });

      const ownersAndRelationsSaved = await saveRelations(personId, person);
      const { isAnyDocumentWithError } = isAnyDocumentToSave
        ? await saveDocuments(event, personId)
        : { isAnyDocumentWithError: false };

      if (!ownersAndRelationsSaved || isAnyDocumentWithError) {
        shouldProceed = false;
      }

      if (shouldProceed) {
        if (formState.isDirty) {
          toast.success(t('successMessages.PersonSavedSuccessfully'), {
            toastId: personId,
            autoClose: 5000,
          });
        }
        if (onSaveSuccess) {
          onSaveSuccess(person, personId);
        } else {
          navigate(routes.naturalPerson(personId), { withoutPrompt: true });
        }
      }
    }
  };

  const onSubmit = (): SubmitHandler<NaturalPersonFormData> => (form, event) =>
    isAnyDocumentEmpty ? markEmptyDocuments() : saveForm(event, form);

  return onSubmit;
};

export default useSubmitNaturalPersonForm;
