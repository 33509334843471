const downloadJSON = (object: object, filename: string, withExtension = true) => {
  const data = JSON.stringify(object);
  const blob = new Blob([data], { type: 'text/json' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = withExtension ? `${filename}.json` : filename;
  a.click();
};

export default downloadJSON;
