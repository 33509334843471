import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { EstateElectricityType, EstateManagementType } from 'api/estate/types';

export const electricityTypeOptions: TranslatedOption<
  NonNullable<EstateElectricityType>
>[] = getObjectKeys(namespaces.enums.estateElectricityTypes).map((value) => ({
  value,
  label: value,
  translationKey: `estateElectricityTypes.${value}`,
}));

export const managementTypeOptions: TranslatedOption<
  NonNullable<EstateManagementType>
>[] = getObjectKeys(namespaces.enums.estateManagementTypes).map((value) => ({
  value,
  label: value,
  translationKey: `estateManagementTypes.${value}`,
}));

export const compositionOptions: TranslatedOption[] = getObjectKeys(
  namespaces.enums.estateComposition,
).map((value) => ({
  value,
  label: value,
  translationKey: `estateComposition.${value}`,
}));

export const compositionTemplateOptions: TranslatedOption[] = getObjectKeys(
  namespaces.enums.estateCompositionTemplates,
).map((value) => ({
  value,
  label: value,
  translationKey: `estateCompositionTemplates.${value}`,
}));
