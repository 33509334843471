import { AssignedDossierType, DossierRole } from 'modules/AssignedDossiersGrid/types';
import { DossierLocationState } from 'modules/DossierForm/types';
import { FetchedDossiersList } from 'api/dossier/types';
import { getPrivateRelation } from 'api/relations/requests';
import { ESTATE_DOSSIER_CARD_HEIGHT, PERSON_DOSSIER_CARD_HEIGHT } from './static-data';
import { AssignedDossier } from './types';

export const getAssignedDossierCardHeight = (type: AssignedDossierType) =>
  type === 'Person' ? PERSON_DOSSIER_CARD_HEIGHT : ESTATE_DOSSIER_CARD_HEIGHT;

export const getAssignedDossierData = (
  dossierData: FetchedDossiersList,
): AssignedDossier[] =>
  dossierData.data.map(
    ({ id, referenceNumber, ownReference, status, landlords, tenants, estate }) => ({
      id,
      referenceNumber,
      ownReference,
      status,
      landlords,
      tenants,
      estate,
    }),
  );

export const getNavigationState = async (
  entityId: string,
  role?: DossierRole,
): Promise<DossierLocationState> => {
  if (role) {
    const personIds: string[] = [entityId];
    const { ok, response } = await getPrivateRelation(entityId);
    if (ok && response) {
      personIds.push(response.relative.id);
    }
    return { [`${role}Ids`]: personIds };
  }
  return { estateId: entityId };
};
