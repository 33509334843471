import { Check } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { getFilterStyle } from './styles';
import { FilterChipsProps } from './types';

const FilterChips = ({ filters, setFilters }: FilterChipsProps) => {
  const updateFilters = (name: string) => {
    setFilters((prev) =>
      prev.map((data) =>
        data.name === name ? { ...data, checked: !data.checked } : data,
      ),
    );
  };

  return filters.length ? (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {filters.map(({ name, label, checked }) => (
        <Chip
          key={name}
          icon={checked ? <Check fontSize="small" /> : undefined}
          label={label}
          clickable
          onClick={() => updateFilters(name)}
          sx={getFilterStyle(checked)}
        />
      ))}
    </Box>
  ) : null;
};

export default FilterChips;
