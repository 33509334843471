import PRODUCTS from 'consts/products';
import palette from 'themes/palette';
import { ProductInDossierListInterface } from 'api/dossier/types';

export const sortProductsIcons = (
  a: ProductInDossierListInterface,
  b: ProductInDossierListInterface,
) => (PRODUCTS.indexOf(a.product) > PRODUCTS.indexOf(b.product) ? 1 : -1);

export const getProductIconColor = (status: string, isPaid?: boolean) => {
  if ((isPaid && (status == 'Draft' || status == 'OrderSent')) || status === 'Paid') {
    return palette.primary.light;
  }
  switch (status) {
    case 'OrderSent':
    case 'InAbolishment':
    case 'Sent':
    case 'Failed':
    case 'Rejected':
    case 'WaitingForPayment':
    case 'Planned':
    case 'Requested':
      return palette.warning.main;
    case 'Running':
    case 'Released':
    case 'Cancelled':
    case 'Final':
    case 'Registered':
    case 'Completed':
      return palette.success.main;
    default:
      return palette.error.main;
  }
};
