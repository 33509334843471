import createVatIdMask from 'text-mask-vat-id';
import { CountryCodesForValidation } from './types';

export const VALIDATED_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
] as const;

const commonEuVatMask = Object.fromEntries(
  VALIDATED_COUNTRIES.map((prefix) => [
    prefix,
    createVatIdMask({ localeFormat: true })(prefix).slice(2),
  ]),
) as Record<CountryCodesForValidation, Array<string | RegExp>>;

export const getCountriesVatMask = (
  value?: string,
): Record<CountryCodesForValidation, Array<string | RegExp>> => ({
  ...commonEuVatMask,
  BE: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/], // if mask is changed, check if validation is still correct
  FR: [
    /\d|([a-hj-np-za-hj-np-z])/i,
    /\d|([a-hj-np-za-hj-np-z])/i,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
  ], // if mask is changed, check if validation is still correct
  NL: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, 'B', /\d/, /\d/],
  LU: new Array(8).fill(/\d/),
  BG: new Array(10).fill(/\d/),
  CZ: new Array(10).fill(/\d/),
  GB:
    value && value[0].match(/[a-zA-Z]/)
      ? new Array(5).fill(/[A-Za-z0-9]/)
      : [/[a-zA-Z0-9]/, ...new Array(11).fill(/\d/)],
  IE: new Array(8).fill(/[A-Za-z0-9]/),
  LT: new Array(12).fill(/\d/),
  RO: new Array(10).fill(/\d/),
});
