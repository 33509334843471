import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import PersonChip from 'atoms/PersonChip';
import { PersonType } from 'api/person/types';

interface Person {
  name: string;
  type: PersonType;
  personId?: string;
  id?: string;
}

const PersonsCell = ({ row, field }: GridRenderCellParams) => {
  const persons = row[field];
  if (!persons?.length) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" gap={0.5}>
      {persons
        .filter((person: Person) =>
          'isRepresentative' in person ? !person.isRepresentative : true,
        )
        .map(({ id, personId = id, ...person }: Person) => (
          <PersonChip key={personId} {...person} />
        ))}
    </Box>
  );
};

export default PersonsCell;
