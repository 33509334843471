import { generalSections, productsSections } from 'formData/faqDemoUpload/static-data';
import { TableAction } from 'templates/Table/types';
import { SetState } from 'types/setState';
import { deleteDemo } from 'api/faq/requests';
import { DemoInterface } from 'api/faq/types';

export const getActions: (
  linkData: DemoInterface,
  setDemoToUpdate: SetState<DemoInterface | null>,
) => TableAction<'faq'>[] = (demo, setDemoToUpdate) => [
  {
    name: 'update',
    onClick: () => {
      setDemoToUpdate(demo);
    },
  },
  {
    name: 'delete',
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) =>
      setUpAndOpenConfirmationModal({
        onSubmit: () => deleteDemo(demo.id),
        onSuccess: updateRows,
        translationKey: 'faqDemo',
        translationValues: {
          name: demo.name,
        },
      }),
  },
];

const sortDemos = (a: DemoInterface, b: DemoInterface) =>
  a.section < b.section ? -1 : 1;

export const getTableData = (data: DemoInterface[]) => ({
  general: data
    .filter(({ section }) => generalSections.includes(section))
    .sort(sortDemos),
  products: data
    .filter(({ section }) => productsSections.includes(section))
    .sort(sortDemos),
});
