import { PartyFieldsConfig } from 'modules/ProductParties/types';

export const rentalContractTenantsConfig: Partial<PartyFieldsConfig> = {
  context: 'multiple',
  maxFieldsCount: 5,
  addingFields: 'byButton',
  showPartner: 'nextToPerson',
  showLegalPersonInSecondaryContact: true,
  selectableRepresentatives: true,
};

export const rentalContractLandlordsConfig: Partial<PartyFieldsConfig> = {
  context: 'multiple',
  maxFieldsCount: 5,
  addingFields: 'byButton',
  selectableRepresentatives: true,
};
