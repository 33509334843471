import { rentalConditionsSchema } from 'formData/dossier/rentalConditions';
import { residentsSchema } from 'formData/dossier/residents';
import i18n from 'i18n';
import {
  DossierAssignedParameters,
  DossierFormInterface,
} from 'modules/DossierForm/types';
import {
  estateToRelationOption,
  personToRelation,
  personToRelationOption,
  responsibleUserToRelation,
} from 'modules/Relation/utils';
import addressToString from 'utils/addressToString';
import calculateEndOfRental from 'utils/calculateEndOfRental';
import { formatDate } from 'utils/date';
import getLng from 'utils/getLng';
import {
  DossierViewInterface,
  RentalConditions,
  RentType,
  Residents,
  ResidentsForm,
} from 'api/dossier/types';
import { FetchedEstateListItem } from 'api/estate/types';

export const getAssignedParameters = ({
  landlords,
  tenants,
  estate: { address, ...estate },
}: DossierViewInterface): DossierAssignedParameters => ({
  assignedLandlords: landlords.map((person) => ({
    ...personToRelationOption(person),
    subHeader: i18n.t('searchGroup.savedLandlords'),
  })),
  assignedTenants: tenants.map((person) => ({
    ...personToRelationOption(person),
    subHeader: i18n.t('searchGroup.savedTenants'),
  })),
  assignedEstates: [
    // TODO: We should create a separate interfaces for relations
    // @ts-ignore estate should be extended
    estateToRelationOption({
      address: addressToString(address),
      city: address.city[getLng()],
      postCode: address.postCode,
      owners: [],
      ...estate,
    }),
  ],
});

export const getDefaultRentType = ({
  destination,
  type,
}: Pick<FetchedEstateListItem, 'destination' | 'type'>): RentType => {
  if (destination === 'Living' && type) {
    switch (type) {
      case 'House':
      case 'Apartment':
        return 'Habitation';
      default:
        return 'CommonLaw';
    }
  }
  if (type === 'GarageParking') {
    return 'CommonLaw';
  }
  return 'Commercial';
};

const getDefaultEndDate = (rentalConditions: RentalConditions | null) => {
  if (
    rentalConditions?.entryDate &&
    rentalConditions.duration &&
    !rentalConditions.endDate
  ) {
    return calculateEndOfRental(rentalConditions.entryDate, rentalConditions.duration);
  }
  return formatDate(rentalConditions?.endDate, 'yyyy-MM-dd');
};

export const getRentalConditionsDefaultValues = (
  rentalConditions: RentalConditions | null,
  estate?: Pick<FetchedEstateListItem, 'destination' | 'type'> | null,
): RentalConditions => ({
  ...rentalConditionsSchema.getDefault(),
  ...(rentalConditions || rentalConditionsSchema.getDefault()),
  contractSigningDate: formatDate(rentalConditions?.contractSigningDate, 'yyyy-MM-dd'),
  entryDate: formatDate(rentalConditions?.entryDate, 'yyyy-MM-dd'),
  endDate: getDefaultEndDate(rentalConditions),
  rentType:
    rentalConditions?.rentType ||
    (estate ? getDefaultRentType(estate) : rentalConditionsSchema.getDefault().rentType),
});

export const getResidentsDefaultValues = (
  residents: Residents | null,
): ResidentsForm => ({
  ...residentsSchema.getDefault(),
  ...(residents || residentsSchema.getDefault()),
  childrenDateOfBirth:
    residents?.childrenDateOfBirth?.map((dateOfBirth) => ({ dateOfBirth })) ?? null,
});

export const getDossierDefaultValues = (
  dossier: DossierViewInterface,
): Partial<DossierFormInterface> => ({
  status: dossier.status,
  ownReference: dossier.ownReference,
  estateId: dossier.estate.id,
  landlords: dossier.landlords.map(personToRelation),
  tenants: dossier.tenants.map(personToRelation),
  responsibleUsers: dossier.responsibleUsers
    ? dossier.responsibleUsers.map(responsibleUserToRelation)
    : undefined,
  rentalConditions: dossier.rentalConditions
    ? getRentalConditionsDefaultValues(dossier.rentalConditions, dossier.estate)
    : undefined,
  residents: getResidentsDefaultValues(dossier.residents),
});
