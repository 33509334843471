import { useEffect, useMemo, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { msalScopes } from 'authConfig';
import StorageKeys from 'consts/storage';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { cleanObject } from 'utils/object';
import { getBusiness } from 'api/businesses/requests';
import { BusinessDetails } from 'api/businesses/types';
import { getUserSettings } from 'api/config/requests';
import { UserRole } from 'api/users/types';

const ROLES_FOR_IMPERSONATION: UserRole[] = ['SuperAdmin', 'Admin'];

export const useLoginUser = () => {
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [username, setUsername] = useState('');

  const [businessData, setBusinessData] = useState<BusinessDetails | null>(null);

  const { inProgress, instance, accounts } = useMsal();

  useEffect(() => {
    if (!inProgress || inProgress === InteractionStatus.None) {
      const account = accounts.at(0);
      instance
        .acquireTokenSilent({ scopes: msalScopes, account })
        .then(async () => {
          const res = await getUserSettings();

          if (res.ok) {
            const { businessId, language, permissions, roles } = res.response;
            // temporary solution -> roles should be fetched as one role from backend
            const userRole = roles?.[0];
            if (!ROLES_FOR_IMPERSONATION.includes(userRole)) {
              Cookies.remove(StorageKeys.ImpersonatedBusinessId);
            }

            localStorage.setItem(StorageKeys.BusinessId, businessId);
            localStorage.setItem(StorageKeys.Permissions, JSON.stringify(permissions));
            localStorage.setItem(StorageKeys.UserRole, userRole);

            if (i18n.language !== language) {
              i18n.changeLanguage(language);
            }
            const { ok, response } = await getBusiness(businessId);
            if (ok) {
              setBusinessData(cleanObject(response));
            }
            setError(false);
            setLogged(true);
            if (account) {
              setUsername(account.username);
              setUserId(account.localAccountId);
            }
          } else {
            localStorage.removeItem(StorageKeys.BusinessId);
            localStorage.removeItem(StorageKeys.Permissions);
            localStorage.removeItem(StorageKeys.UserRole);
            setError(true);
          }
        })
        .catch((_error) =>
          instance.acquireTokenRedirect({
            scopes: msalScopes,
          }),
        );
    }
  }, [instance, accounts, inProgress]);

  const loading = useMemo(() => {
    return (
      inProgress === InteractionStatus.HandleRedirect ||
      inProgress === InteractionStatus.Login ||
      inProgress === InteractionStatus.Logout ||
      (!logged && !error)
    );
  }, [inProgress, logged, error]);

  return { loading, error, userId, businessData, username } as const;
};
