const DATA = [
  { sign: '<=', code: '\u2264' },
  { sign: '>=', code: '\u2265' },
];

export const replaceSpecialChars = (string: string) => {
  const dataToReplace = DATA.find(({ sign }) => string.includes(sign));

  if (dataToReplace) {
    const { code, sign } = dataToReplace;
    return string.replace(sign, code);
  }

  return string;
};
