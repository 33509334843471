import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { AddendumSteps } from 'modules/ProductRentalContract/RegisterAddendum/static-data';
import { UseRegisterAddendumPersonStepInterface } from 'modules/ProductRentalContract/RegisterAddendum/types';
import { StepInterface } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import transformName from 'utils/transformName';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { AddNewContact } from './AddNewContact';
import { ContactsStep } from './ContactsStep';

export const usePersonStep = ({
  onClose,
  persons,
  setStepErrors,
  sectionType,
}: UseRegisterAddendumPersonStepInterface): StepInterface => {
  const [expandedId, setExpandedId] = useState('');

  const { control, trigger, formState } = useFormContext<RegisterAddendumForm>();

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: sectionType,
    keyName: 'fieldId',
  });

  useUpdateEffect(() => {
    const validate = async () => {
      const isValid = await trigger(sectionType, { shouldFocus: true });
      setStepErrors((prev) => ({ ...prev, [AddendumSteps[sectionType]]: !isValid }));
    };
    validate();
  }, [Object.keys(formState.errors.landlords ?? {}).length]);

  const toggleAccordion = (id: string) => () => {
    if (id !== expandedId) {
      setExpandedId(id);
    } else {
      setExpandedId('');
    }
  };

  const cleanExpansion = () => setExpandedId('');

  const navigate = (changeStep: () => void) => async () => {
    const isValid = await trigger(sectionType, { shouldFocus: true });
    setStepErrors((prev) => ({ ...prev, [AddendumSteps[sectionType]]: !isValid }));

    if (isValid) {
      cleanExpansion();
      changeStep();
    }
  };

  const removePerson = (index: number) => {
    const landlord = fields[index];
    if (landlord.type === 'LegalPerson') {
      const representativeIndex = fields.findIndex(
        (field) => field.companyName === landlord.name,
      );
      remove([index, representativeIndex]);
    } else {
      remove(index);
    }
  };

  return {
    Component: (
      <Stack gap={2} justifyItems="center">
        <Box>
          {fields.map(
            (
              { name, firstName, id, type, companyName, legalPersonId, fieldId },
              index,
            ) => (
              <ContactsStep
                key={fieldId}
                id={id}
                sectionType={sectionType}
                index={index}
                expanded={expandedId === id}
                name={transformName({ name, firstName })}
                toggleAccordion={toggleAccordion}
                personType={type}
                companyName={companyName}
                legalPersonId={legalPersonId}
                update={update}
                remove={removePerson}
              />
            ),
          )}
        </Box>
        <AddNewContact type={sectionType} prepend={prepend} persons={persons} />
      </Stack>
    ),
    cancelButton: {
      onClick: onClose,
    },
    backButton: { changeStepManually: true, onClick: navigate },
    nextButton: { changeStepManually: true, onClick: navigate },
    labelButton: { changeStepManually: true, onClick: navigate },
  };
};
