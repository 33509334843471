import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import useFormMethods from 'hooks/useFormMethods';
import { getInputLabel } from 'utils/missingFields';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { taxResidenceBelgiumOptions } from './static-data';
import { TaxResidenceVariantProps } from './types';

const TaxResidenceVariant = ({ isRequired, prefix }: TaxResidenceVariantProps) => {
  const { control, getName } = useFormMethods<NaturalPersonFormData>(prefix);
  const { t } = useTranslation('enums');

  return (
    <Controller
      name={getName('taxResidenceBelgium')}
      control={control}
      render={({ field: { value = null, onChange } }) => (
        <FormControl
          fullWidth
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormLabel>{getInputLabel('taxResidenceBelgium', isRequired)}</FormLabel>
          <RadioGroup
            name="taxResidenceBelgium"
            value={value}
            row
            onChange={(_e, value) => onChange(value)}
          >
            {taxResidenceBelgiumOptions.map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={t(`taxResidences.${type}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default TaxResidenceVariant;
