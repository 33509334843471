import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import { NaturalPersonFormProvider } from 'contexts/personForm/naturalPersonFormContext';
import { naturalPersonSchema } from 'formData/naturalPerson';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard_V2';
import { filterSteps, getStepsIndex } from 'templates/StepWizard_V2/utils';
import { resolver } from 'utils/yup';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { NATURAL_PERSON_STEPS } from './static-data';
import { NaturalPersonFormDefaultValues, NaturalPersonFormProps } from './types';
import useNaturalPersonSteps from './useNaturalPersonSteps';

const PersonFormStepper = ({
  handleCancel,
  handleSave,
  stepsToHide,
}: NaturalPersonFormProps) => {
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
    }
  };

  const steps = useMemo(() => filterSteps(NATURAL_PERSON_STEPS, stepsToHide), []);

  const stepsProps = useNaturalPersonSteps({
    onSaveSuccess: handleSave,
    stepsIndex: getStepsIndex(steps),
  });

  return (
    <StepWizard tKey="naturalPerson" steps={steps} onCancel={onCancel} {...stepsProps} />
  );
};

export const NaturalPersonForm = ({
  naturalPersonId,
  defaultValues,
  ...props
}: NaturalPersonFormProps & {
  naturalPersonId?: string | null;
  defaultValues?: NaturalPersonFormDefaultValues;
}) => {
  const methods = useForm<NaturalPersonFormData>({
    mode: naturalPersonId ? 'onChange' : 'onSubmit',
    defaultValues: {
      ...naturalPersonSchema.getDefault(),
      ...defaultValues?.naturalPerson,
    },
    resolver: resolver(naturalPersonSchema),
  });

  useEffect(() => {
    if (naturalPersonId) {
      methods.trigger();
    }
  }, []);

  usePrompt({ when: methods.formState.isDirty, id: 'naturalPersonForm' });

  useEffect(() => {
    if (naturalPersonId) {
      methods.trigger();
    }
  }, []);

  return (
    <FilesUploadProvider scope="naturalPerson">
      <FormProvider {...methods}>
        <NaturalPersonFormProvider
          personId={naturalPersonId}
          defaultOwnerships={defaultValues?.ownerships}
          defaultRepresentedParties={defaultValues?.representedParties}
          defaultRepresentatives={defaultValues?.representatives}
          defaultPrivateRelation={defaultValues?.privateRelation}
          defaultPrivateRelationType={defaultValues?.privateRelationType}
        >
          <PersonFormStepper {...props} />
        </NaturalPersonFormProvider>
      </FormProvider>
    </FilesUploadProvider>
  );
};
