import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import isBoolean from 'utils/isBoolean';
import { StepFooterProps } from './types';

const StepFooter = ({ step, prevStep, nextStep, Elements }: StepFooterProps) => {
  const { t } = useTranslation();

  const { backButton, cancelButton, nextButton, submitButton, isValid = true } = step;

  const handleBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (backButton && !isBoolean(backButton)) {
      if (!backButton.changeStepManually) {
        backButton.onClick?.(e);
        if (isValid) {
          prevStep();
        }
      } else {
        backButton.onClick(prevStep)(e);
      }
    } else {
      prevStep();
    }
  };

  const handleNext: MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (nextButton && !isBoolean(nextButton)) {
      if (!nextButton.changeStepManually) {
        nextButton.onClick?.(e);
        if (isValid) {
          nextStep();
        }
      } else {
        nextButton.onClick(nextStep)(e);
      }
    } else {
      nextStep();
    }
  };

  return (
    <Box width="100%" display="flex" justifyContent="flex-end" gap={8}>
      {Elements ? <Box mr="auto">{Elements}</Box> : null}
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" gap={2}>
        {cancelButton ? (
          <Button
            role="text"
            onClick={cancelButton.onClick}
            disabled={
              cancelButton.disable ||
              (!isBoolean(nextButton) && nextButton?.saving) ||
              submitButton?.saving
            }
          >
            {cancelButton.label || t('cancel')}
          </Button>
        ) : null}
        {backButton ? (
          <Button
            role="secondary"
            color="primary"
            onClick={handleBack}
            disabled={
              (!isBoolean(backButton) && backButton.disable) ||
              (!isBoolean(nextButton) && nextButton?.saving) ||
              submitButton?.saving
            }
          >
            {!isBoolean(backButton) ? backButton.label || t('back') : t('back')}
          </Button>
        ) : null}
        {nextButton ? (
          <Button
            role="primary"
            onClick={handleNext}
            disabled={
              (!isBoolean(nextButton) && (nextButton.disable || nextButton.saving)) ||
              submitButton?.saving
            }
            loading={!isBoolean(nextButton) && nextButton.saving}
          >
            {!isBoolean(nextButton) ? nextButton.label || t('next') : t('next')}
          </Button>
        ) : null}
        {submitButton ? (
          <Button
            disableElevation
            type="submit"
            onClick={submitButton.onClick}
            disabled={
              submitButton.disable ||
              submitButton.saving ||
              (!isBoolean(nextButton) && nextButton?.saving)
            }
            loading={submitButton.saving}
            data-testid="submit_form_button"
          >
            {submitButton.label || t('save')}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default StepFooter;
