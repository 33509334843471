import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getLng from 'utils/getLng';

const useLanguage = () => {
  const { i18n } = useTranslation();
  const lng = useMemo(() => getLng(i18n), [i18n.language]);
  return lng;
};

export default useLanguage;
