import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NaturalPersonFormContext } from 'contexts/personForm/naturalPersonFormContext';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import NaturalPersonFields from 'modules/NaturalPersonFields';
import {
  FormContainer,
  FormSection,
  FormSide,
  FormVerticalDivider,
} from 'templates/FormTemplates';
import { NaturalPersonFormData } from 'api/person/natural/types';

const NaturalPersonGeneralStep = () => {
  const { t } = useTranslation();
  const methods = useFormContext<NaturalPersonFormData>();
  const { personId } = useContext(NaturalPersonFormContext);

  const {
    formState: { errors },
    control,
    trigger,
  } = methods;

  const dateOfBirth = useWatch({ control, name: 'dateOfBirth' });
  const sex = useWatch({ control, name: 'sex' });

  useEffect(() => {
    if ((dateOfBirth || sex) && errors.nationalRegisterNumber) {
      trigger('nationalRegisterNumber');
    }
  }, [dateOfBirth, sex]);

  return (
    <FormContainer>
      <FormSide>
        <FormSection
          container
          label={t('stepWizard.naturalPerson.sections.basicInformation')}
        >
          <NaturalPersonFields section="general" />
        </FormSection>
      </FormSide>
      <FormVerticalDivider />
      <FormSide>
        <FormSection container label={t('stepWizard.naturalPerson.sections.details')}>
          <NaturalPersonFields section="details" />
        </FormSection>
      </FormSide>
      <FormSection container label={t('stepWizard.naturalPerson.sections.address')}>
        <AddressFieldsWithGoogle
          clearButtonTranslationLabel={personId ? 'resetAddress' : 'clearAddress'}
        />
      </FormSection>
    </FormContainer>
  );
};

export default NaturalPersonGeneralStep;
