import { toast } from 'react-toastify';
import { Breakpoint } from '@mui/material';
import axios from 'axios';
import i18n from 'i18n';
import { FireInsuranceAdditionalData } from 'modules/ProductFireInsurance';
import ProductMissingData from 'modules/ProductMissingData';
import { OrderMyMove } from 'modules/ProductMyMove';
import {
  RegisterAddendum,
  RegisterRentalContract,
  RegisterStateReport,
} from 'modules/ProductRentalContract';
import {
  OrderRentalDeposit,
  RentalDepositAmicableRelease,
  RentalDepositChooseParties,
  RentalDepositUploadReleaseForm,
  RentalDepositVerdictRelease,
} from 'modules/ProductRentalDeposit';
import { RequestStateReport } from 'modules/ProductStateReport';
import getAuthorizationHeader from 'utils/getAuthorizationHeader';
import openExternal from 'utils/openExternal';
import { BACKEND_ADDRESS } from 'api/const';
import { ProductActionName } from 'api/dossier/products/types';
import ProductActionConfirmation from './ProductActionConfirmation';
import { FetchProductActionProps, ProductActionComponentProps } from './types';

type ProductActionComponent =
  | ((props: ProductActionComponentProps) => JSX.Element | null)
  | null;

// TODO: Move all products actions components into a new module
export const getProductActionData = (
  name: ProductActionName,
): {
  Component: ProductActionComponent;
  maxWidth?: Breakpoint;
  hideFooterButtons?: boolean;
} => {
  switch (name) {
    // CONFIRMATION
    case 'StateReportComplete':
    case 'IntermediateStateReportComplete':
    case 'EndStateReportComplete':
    case 'RentalDepositCancelRelease':
      return { Component: ProductActionConfirmation };
    // MISSING DATA
    case 'RentalDepositFillMissingData':
    case 'RentalContractFillMissingData':
      return { Component: ProductMissingData };
    // RENTAL DEPOSIT
    case 'RentalDepositChooseParties':
      return { Component: RentalDepositChooseParties as ProductActionComponent };
    case 'RentalDepositOrder':
      return { Component: OrderRentalDeposit };
    case 'RentalDepositReleaseAmicable':
      return { Component: RentalDepositAmicableRelease };
    case 'RentalDepositReleaseVerdict':
    case 'RentalDepositUploadVerdict':
      return { Component: RentalDepositVerdictRelease };
    case 'RentalDepositUploadSignedReleaseForm':
      return { Component: RentalDepositUploadReleaseForm };
    // FIRE INSURANCE
    case 'FireInsuranceFillAdditionalData':
      return { Component: FireInsuranceAdditionalData };
    // RENTAL CONTRACT
    case 'RentalContractOrder':
      return { Component: RegisterRentalContract };
    case 'RentalContractRegisterStateReport':
      return { Component: RegisterStateReport };
    case 'RentalContractRegisterAddendum':
      return {
        Component: RegisterAddendum,
        maxWidth: 'md' as const,
        hideFooterButtons: true,
      };
    // MY MOVE
    case 'MyMoveOrder':
      return { Component: OrderMyMove };
    // STATE REPORT
    case 'StateReportRequest':
    case 'IntermediateStateReportRequest':
    case 'EndStateReportRequest':
      return { Component: RequestStateReport };
    default:
      return { Component: null };
  }
};

export const fetchProductAction = async ({
  httpMethod,
  link,
  data,
  onSuccess,
  onError,
}: FetchProductActionProps) => {
  const Authorization = await getAuthorizationHeader();

  try {
    const { data: response, status } = await axios(link, {
      method: httpMethod,
      baseURL: BACKEND_ADDRESS,
      headers: { Authorization },
      data,
    });

    await onSuccess();
    if (status === 201 && response?.redirectionLink) {
      openExternal(response.redirectionLink);
    }
  } catch (error) {
    handleError(error, link, onError);
  }
};

const handleError = (
  error: unknown,
  toastId: string,
  onError: FetchProductActionProps['onError'],
) => {
  let message = i18n.t('errors:status.default');
  let status: number | undefined;

  if (axios.isAxiosError(error)) {
    status = error.response?.status;
    const { data } = error?.response ?? {};
    if (data?.errorCode) {
      message = i18n.t(`errors:${data.errorCode}`, {
        ...data.data,
        defaultValue: message,
      });
    } else {
      message = i18n.t(
        `errors:status.${status}`,
        (data?.ErrorMessage ?? data?.error) || message,
      );
    }
  }

  if (onError) {
    onError(status);
  } else {
    toast.error(message, { toastId });
  }
};
