import { Theme, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import routes from 'router/routes';
import hasRole from 'utils/hasRole';
import transformName from 'utils/transformName';
import { AffiliatedPersonInterface } from 'api/common/types';

const AffiliatedPersonLabel = (person: AffiliatedPersonInterface) => {
  const linkProps = hasRole('Admin')
    ? {}
    : {
        component: CustomLink,
        to: routes.personTab(person.id, person.type).details,
        sx: ({ mixins }: Theme) => mixins.link,
      };
  return (
    <Typography fontWeight={600} {...linkProps}>
      {transformName(person)}
    </Typography>
  );
};

export default AffiliatedPersonLabel;
