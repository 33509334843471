import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Select } from 'components/inputs';
import { ROWS_PER_PAGE } from '../static-data';
import { TableTranslationKey } from '../types';
import { RowsPerPageSelectorProps } from './types';

const RowsPerPageSelector = <Key extends TableTranslationKey>({
  tKey,
  pageSize,
  disabled,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
}: RowsPerPageSelectorProps<Key>) => {
  const { t } = useTranslation('table');

  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="flex-start" gap={2}>
      <Select
        value={String(pageSize)}
        onChange={onRowsPerPageChange}
        options={rowsPerPage.map((value) => ({
          label: String(value),
          value: String(value),
        }))}
        disabled={disabled}
        staticWidth={75}
      />
      <Typography color="grey.500">
        {t('pagination.rowsPerPage', { rows: t(`${tKey}.title`, '') })}
      </Typography>
    </Box>
  );
};

export default RowsPerPageSelector;
