import api from 'api';
import { apiUrl } from 'api/const';
import { AbolishmentRequestData, RentalDepositDetails } from './types';

export const getRentalDepositDetails = (dossierId: string, productId: string) =>
  api.get<RentalDepositDetails>(apiUrl.rentalDeposit(dossierId, productId));

export const getRentalDepositAbolishmentData = (dossierId: string, productId: string) =>
  api.get<AbolishmentRequestData>(
    apiUrl.rentalDepositAbolishmentData(dossierId, productId),
  );
