import { useOutletContext } from 'react-router-dom';
import AssignedDossiersGrid from 'modules/AssignedDossiersGrid';
import { NaturalPersonViewInterface } from 'api/person/natural/types';

const NaturalPersonDossiersTab = () => {
  const naturalPerson = useOutletContext<NaturalPersonViewInterface>();

  return <AssignedDossiersGrid entityId={naturalPerson.id} type="Person" />;
};

export default NaturalPersonDossiersTab;
