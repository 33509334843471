import { AddressFormInterface } from 'api/common/types';
import { GoogleAddressComponent } from 'components/inputs/GooglePlacesSearch/types';

export const getAddressFields = (components: GoogleAddressComponent[]) => {
  let address: Partial<AddressFormInterface> = { country: '' };

  components.forEach(({ long_name, types }) => {
    switch (types[0]) {
      case 'street_number':
        {
          // input = "8c"   -> long_name = "8c"
          // input = "8c/2" -> long_name = "8"
          // input = "8/2"  -> long_name = "8"
          const houseNumber = long_name.match(/\d+/g)?.[0];
          const extension = long_name.match(/[a-zA-Z]+/g)?.[0];
          address = {
            ...address,
            houseNumber: houseNumber ? Number(houseNumber) : undefined,
            extension,
          };
        }
        break;
      case 'route':
        address = { ...address, street: long_name };
        break;
      case 'locality':
        address = { ...address, city: long_name };
        break;
      case 'country':
        address = {
          ...address,
          country: long_name,
        };
        break;
      case 'postal_code':
        address = { ...address, postCode: long_name };
        break;
    }
  });

  return address;
};
