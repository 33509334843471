import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { InfoIcon } from 'icons';
import { RentalDepositData } from 'modules/ProductRentalDeposit/types';
import transformName from 'utils/transformName';
import { AbolishmentReason } from 'api/dossier/products/rentalDeposit/types';
import { getDeceasedTenantName } from './utils';

interface Props {
  selectedReason: AbolishmentReason;
  data: RentalDepositData;
  deceasedPersonReference?: string | null;
}

export const ReleaseDocumentsInfo = ({
  selectedReason,
  data,
  deceasedPersonReference,
}: Props) => {
  const { t } = useTranslation('modals', {
    keyPrefix: 'prepareReleaseModal.sections.requiredDocuments',
  });

  if (selectedReason !== 'verdict' && !t(`${selectedReason}.list`, '')) {
    return null;
  }

  return (
    <Box
      display="flex"
      gap={1.5}
      p={1.5}
      borderRadius={2}
      bgcolor={({ palette }) => palette.primary[100]}
    >
      <InfoIcon color="primary" fontSize="large" />
      {selectedReason === 'verdict' ? (
        <Typography>{t('verdict.info')}</Typography>
      ) : (
        <Box>
          <Typography>{t('note')}</Typography>
          <ul>
            <Trans
              t={t}
              values={{
                tenantName: data.tenant ? transformName(data.tenant) : null,
                tenantPartnerName: data.tenantPartner
                  ? transformName(data.tenantPartner)
                  : null,
                deceasedTenantName: getDeceasedTenantName(data, deceasedPersonReference),
                landlordName: data.landlord ? transformName(data.landlord) : null,
              }}
              i18nKey={`${selectedReason}.list`}
              components={{
                item: <li />,
              }}
            />
          </ul>
          <Typography>
            {t(`${selectedReason}.info`, '', {
              tenantName: data.tenant ? transformName(data.tenant) : null,
              tenantPartnerName: data.tenantPartner
                ? transformName(data.tenantPartner)
                : null,
              deceasedTenantName: getDeceasedTenantName(data, deceasedPersonReference),
              landlordName: data.landlord ? transformName(data.landlord) : null,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
