import { useMemo } from 'react';
import DocumentsTable from 'templates/DocumentsTable';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import DossierDocumentCells from './DossierDocumentCells';
import { dossierDocumentsColumns, getDossierDocumentActions } from './static-data';
import { DossierDocumentsTableProps } from './types';

const DossierDocumentsTable = ({
  openDocumentsModal,
  context,
  toolbarProps,
  ...props
}: DossierDocumentsTableProps) => {
  const actionProps = useMemo(
    () => ({
      onClick: () => openDocumentsModal(),
      disabled: !shouldDossierFeatureBeActive(context.dossierStatus) || props.loading,
    }),
    [props.loading, context.dossierStatus],
  );

  const actions = useMemo(
    () => getDossierDocumentActions(context.dossierStatus, openDocumentsModal),
    [context.dossierStatus, openDocumentsModal],
  );

  return (
    <DocumentsTable
      columns={dossierDocumentsColumns}
      getActions={actions}
      toolbarProps={{ actionProps, ...toolbarProps }}
      context={context}
      CellsComponent={DossierDocumentCells}
      {...props}
    />
  );
};

export default DossierDocumentsTable;
