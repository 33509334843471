import { UrlParams } from 'api/types';
import { getSearchParamsUrl } from 'api/utils';

export default {
  // BUSINESSES
  businessesWithParams: (params: UrlParams) =>
    getSearchParamsUrl('/migrations/getBusinesses', { ...params }),

  // MIGRATION
  runBusinessMigration: '/migrations/runBusinessesMigration',
  runDataMigration: (businessId: string) =>
    `/migrations/runMigration/${businessId}?withSync=true&withSafetyCheck=true`,
  dossierMigrationQueue: (params: UrlParams) =>
    getSearchParamsUrl('/migrations/getDossierMigrationQueue', { ...params }),
  businessMigrationQueue: (params: UrlParams) =>
    getSearchParamsUrl('/migrations/getBusinessMigrationQueue', { ...params }),
  getMigrationResponseFile: (params: UrlParams) =>
    getSearchParamsUrl('/migrations/getMigrationResponseFile', { ...params }),
};
