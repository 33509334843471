import { useTranslation } from 'react-i18next';
import Button from 'atoms/Button';
import CardEditionButtons from 'components/CardEditionButtons';
import { PageCardHeaderEditionProps } from '../types';

const PageCardHeaderEdition = ({
  isEditing,
  onEdit,
  editDisabled,
  label = 'edit',
  variant,
  ...editionButtonsProps
}: PageCardHeaderEditionProps) => {
  const { t } = useTranslation();

  return isEditing ? (
    <CardEditionButtons variant={variant} {...editionButtonsProps} />
  ) : (
    <Button size="small" onClick={onEdit} disabled={editDisabled}>
      {t(label)}
    </Button>
  );
};

export default PageCardHeaderEdition;
