import { bivSchema, languageSchema } from 'formData/common/schema';
import yup from 'utils/yup';
import { AssignableUserRole } from 'api/users/types';

const userSchema = yup.object({
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  name: yup.string().required(),
  biv: bivSchema,
  language: languageSchema.required(),
  role: yup.string<AssignableUserRole>().required(),
});

export default userSchema;
