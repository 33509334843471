import { useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import useLoader from 'hooks/useLoader';
import UserDetailsCard from 'modules/UserView';
import UserViewHeader from 'modules/UserView/UserViewHeader';
import transformName from 'utils/transformName';
import { getUserConfig, getUserData } from './utils';

export const UserDetails = () => {
  const { userId } = useParams();
  if (!userId) return null;

  const { data, Loader } = useLoader(getUserConfig(userId));

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Loader>
      {data && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              {!matches && (
                <UserViewHeader
                  id={data.id}
                  userName={transformName({ firstName: data.firstName, name: data.name })}
                />
              )}
              <UserDetailsCard userData={getUserData(data)} />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
          >
            <UserViewHeader id={data.id} userName={transformName(data)} />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
