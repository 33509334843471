import { Components, Theme } from '@mui/material';
import palette from './palette';

const BORDER_RADIUS = '8px';

export default (theme: Theme): Components<Omit<Theme, 'components'>> => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        minHeight: theme.shape.inputMinHeight,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: BORDER_RADIUS,
          '&:hover fieldset': {
            borderColor: palette.primary.main,
          },
          '&.Mui-error': {
            '&:hover fieldset': {
              borderColor: palette.error.main,
            },
          },
          '&.Mui-disabled': {
            '&:hover fieldset': {
              borderColor: palette.grey[300],
            },
          },
        },
        '& .MuiFormHelperText-root': {
          fontSize: '11px',
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: { color: palette.common.white },
      },
      {
        props: { size: 'small' },
        style: { minHeight: '32px', fontSize: '12px' },
      },
      {
        props: { size: 'medium' },
        style: { minHeight: '40px', fontSize: '14px' },
      },
      {
        props: { size: 'large' },
        style: { minHeight: '48px', fontSize: '16px' },
      },
    ],
    styleOverrides: {
      iconSizeMedium: {
        '& > *:first-of-type': {
          fontSize: 16,
        },
      },
      root: ({ ownerState }) => {
        switch (ownerState.variant) {
          case 'contained':
            return {
              borderRadius: BORDER_RADIUS,
              lineHeight: 1.4,
              '&.Mui-disabled': {
                backgroundColor: palette.grey[300],
                color: palette.grey[500],
              },
            };
          case 'outlined':
            return {
              borderRadius: BORDER_RADIUS,
              lineHeight: 1.4,
              '&.Mui-disabled': {
                border: `1px solid ${palette.grey[300]}`,
                color: palette.grey[300],
              },
            };
          default:
            return {
              ':hover': {
                borderRadius: BORDER_RADIUS,
              },
            };
        }
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeLarge: {
        width: theme.spacing(5.5),
        height: theme.spacing(5.5),
        fontSize: '1.25rem',
      },
      sizeMedium: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        fontSize: '1rem',
      },
      sizeSmall: {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
        fontSize: '0.75rem',
      },
    },
  },
  MuiTableCell: {
    variants: [
      {
        props: { size: 'small' },
        style: {
          fontSize: '0.875rem',
          padding: 12,
          borderColor: palette.other.border,
          borderWidth: 1,
          '&.MuiTableCell-sizeSmall': {
            paddingTop: 4,
            paddingBottom: 4,
          },
          '&:last-child': {
            paddingRight: 16,
          },
          '&:first-of-type': {
            paddingLeft: 16,
          },
          '> a': {
            textDecoration: 'none',
            transition: 'color 0.1s',
            color: palette.primary.main,
            '&:hover': {
              color: palette.primary.dark,
            },
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        borderColor: palette.divider,
      },
      head: {
        fontWeight: 600,
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        '& .MuiIconButton-root:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        color: 'blue',
        '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
          right: '5px',
          top: 'unset',
        },
        '& .MuiInputBase-root, & .MuiInputBase-input': {
          cursor: 'pointer',
        },
        '& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
          transform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
        '& .MuiAutocomplete-clearIndicator': {
          marginRight: -10,
        },
      },
    },
  },
  MuiAccordion: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          width: '100%',
          borderColor: palette.grey[300],
          '&, &:first-of-type, &:last-of-type': {
            borderRadius: BORDER_RADIUS,
          },
          '& .MuiAccordionSummary-content:has(.MuiFormControlLabel-root)': {
            margin: 0,
          },
          '& .MuiButtonBase-root:has(.MuiFormControlLabel-root)': {
            paddingRight: 0,
            paddingLeft: 0,
          },
          '& .MuiFormControlLabel-root': {
            width: '100%',
            paddingRight: 4,
            paddingLeft: 16,
            marginLeft: 0,
          },
          '::before': {
            display: 'none',
          },
        },
      },
    ],
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        '& .MuiFormControlLabel-root': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
    },
  },
  MuiTabs: {
    variants: [
      {
        props: { variant: 'scrollable' },
        style: {
          '& .MuiTabs-scrollButtons': {
            display: 'inline-flex',
          },
          '.MuiTab-root': {
            flex: 1,
            minWidth: 'fit-content',
            textTransform: 'none',
            maxWidth: 'unset',
          },
          '.MuiButtonBase-root': {
            fontWeight: 400,
          },
        },
      },
    ],
  },
  MuiChip: {
    variants: [
      {
        props: { color: 'success' },
        style: { color: palette.common.white },
      },
      {
        props: { color: 'warning' },
        style: { color: palette.common.white },
      },
      {
        props: { color: 'default', variant: 'filled' },
        style: { color: palette.common.white, backgroundColor: palette.grey[400] },
      },
      {
        props: { clickable: true, size: 'medium' },
        style: { fontSize: '12px' },
      },
    ],
  },
  MuiTypography: {
    styleOverrides: { root: { lineHeight: 1.4 } },
  },
  MuiPaper: {
    styleOverrides: {
      root: { borderRadius: BORDER_RADIUS },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: { borderRadius: BORDER_RADIUS },
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: { fontWeight: 400 },
    },
  },
});
