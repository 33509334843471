import Button from 'atoms/Button';
import { normalizeString } from 'utils/string';
import { ProductActionInterface } from 'api/dossier/products/types';

const SubProductAction = ({ name, isAvailable }: ProductActionInterface) => (
  <Button size="small" variant="outlined" color="secondary" disabled={!isAvailable}>
    {normalizeString(name)}
  </Button>
);

export default SubProductAction;
