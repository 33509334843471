import { useContext, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { MailIcon, MapTagIcon } from 'icons';
import { businessInfoBoxStyle } from './styles';
import { transformBusinessData } from './utils';

const CompanyInfo = () => {
  const { businessData } = useContext(AccountContext);

  const data = useMemo(
    () => (businessData ? transformBusinessData(businessData) : null),
    [businessData],
  );

  return data ? (
    <>
      <span>
        <Divider sx={{ margin: 0 }} />
      </span>
      <Box sx={businessInfoBoxStyle}>
        <Typography color={'primary.light'} fontWeight={600} fontSize={16}>
          {data.name}
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <MapTagIcon fontSize="small" />
          <Stack>
            {data.street && <Typography fontWeight={500}>{data.street}</Typography>}
            {data.city && <Typography fontWeight={500}>{data.city}</Typography>}
          </Stack>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <MailIcon fontSize="small" />
          <Stack>
            <Typography
              fontWeight={500}
              component="a"
              href={`mailto:${data.email}`}
              sx={({ mixins }) => ({ ...mixins.link, color: 'unset' })}
            >
              {data.email}
            </Typography>
            <Typography
              fontWeight={500}
              component="a"
              href={`tel:${data.phone}`}
              sx={({ mixins }) => ({ ...mixins.link, color: 'unset' })}
            >
              {data.phone}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  ) : null;
};

export default CompanyInfo;
