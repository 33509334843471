import {
  DossierAssignedParameters,
  DossierFormChecklistItem,
  DossierLocationState,
} from 'modules/DossierForm/types';
import { estateToRelationOption, personToRelationOption } from 'modules/Relation/utils';
import addressToString from 'utils/addressToString';
import getLng from 'utils/getLng';
import { FetchedDossierChecklistItem } from 'api/dossier/types';
import { getEstates } from 'api/estate/requests';
import { FetchedEstateListItem } from 'api/estate/types';
import { getLegalPersonViewData } from 'api/person/legal/requests';
import { getNaturalPersonViewData } from 'api/person/natural/requests';
import { getPersons } from 'api/person/requests';
import { FetchedPersonData } from 'api/person/types';

export const fetchEstateOwners = async (
  estate: FetchedEstateListItem | undefined,
  personsIdsToFilter: string[] = [],
) => {
  const ownersIds = estate?.owners
    .filter(({ id }) => !personsIdsToFilter.includes(id))
    .map(({ id }) => id)
    .join(',');

  if (ownersIds?.length) {
    const { ok, response } = await getPersons({ ids: ownersIds });
    if (ok) {
      return response.data;
    }
  }
  return [];
};

export const fetchDossierAssignedParameters = async (
  state: DossierLocationState | null,
): Promise<DossierAssignedParameters> => {
  let params: DossierAssignedParameters = {
    assignedEstates: [],
    assignedLandlords: [],
    assignedTenants: [],
  };

  if (state?.estateId) {
    const { ok, response } = await getEstates({ ids: state.estateId });
    if (ok) {
      const estate = response.data[0];
      const owners = await fetchEstateOwners(estate);
      params = {
        ...params,
        assignedEstates: [estateToRelationOption(estate)],
        assignedLandlords: owners.map(personToRelationOption),
      };
    }
  }

  if (state?.landlordIds) {
    const { ok, response } = await getPersons({ ids: state.landlordIds.join(',') });
    if (ok) {
      const landlords = response.data;

      if (landlords.length === 1) {
        const estates = await fetchOwnedEstates(landlords[0]);
        params = {
          ...params,
          assignedEstates: estates.map(({ address, ...estate }) =>
            estateToRelationOption({
              ...estate,
              korfineReference: '',
              postCode: address.postCode,
              city: address.city[getLng()],
              address: addressToString(address, { isFullAddress: false }),
            }),
          ),
          assignedLandlords: landlords.map(personToRelationOption),
        };
      } else {
        params = {
          ...params,
          assignedLandlords: landlords.map(personToRelationOption),
        };
      }
    }
  }

  if (state?.tenantIds) {
    const { ok, response } = await getPersons({ ids: state.tenantIds.join(',') });
    if (ok) {
      params = {
        ...params,
        assignedTenants: response.data.map(personToRelationOption),
      };
    }
  }

  return {
    assignedEstates: params.assignedEstates,
    assignedLandlords: params.assignedLandlords,
    assignedTenants: params.assignedTenants,
  };
};

const fetchOwnedEstates = async (person: FetchedPersonData) => {
  const { ok, response } =
    person.type === 'NaturalPerson'
      ? await getNaturalPersonViewData(person.id)
      : await getLegalPersonViewData(person.id);
  if (ok) {
    return response.estates;
  }
  return [];
};

export const formatRentalDossierChecklist = (
  props: FetchedDossierChecklistItem,
): DossierFormChecklistItem => ({
  ...props,
  checked:
    props.name === 'RentalDeposit' ||
    props.name === 'FireInsurance' ||
    props.name === 'LegalAssistance',
  disabled: props.name === 'IntermediateStateReport' || props.name === 'EndStateReport',
});
