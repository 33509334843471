import { isEstateComposition } from 'formData/estate/details/utils';
import { EstateDestination, EstateDetailsForm } from 'api/estate/types';
import { EstateFormInterface } from '../types';

export const getEstateCompositionValue = (
  { estate, estateDetails }: EstateFormInterface,
  prevDestination: EstateDestination | undefined,
): EstateDetailsForm['estateComposition'] => {
  if (!isEstateComposition(estate)) {
    return null;
  }

  if (prevDestination && prevDestination !== estate.destination) {
    if (
      estate.destination !== 'NonResidential' &&
      estate.subType?.match(/Apartment\dBedroom/g)
    ) {
      return { bedrooms: Number(estate.subType.match(/\d+/)?.[0]) };
    }
    return null;
  }

  const composition = estateDetails?.estateComposition ?? null;

  if (
    estate.destination !== 'NonResidential' &&
    estate.subType?.match(/Apartment\dBedroom/g)
  ) {
    return {
      ...composition,
      bedrooms: Number(estate.subType.match(/\d+/)?.[0]),
    };
  }

  return composition;
};
