import yup from 'utils/yup';
import { EAN_NUMBER_LENGTH, EAN_NUMBER_START } from './static-data';
import { isEanNumberValid } from './utils';

const eanNumberSchema = yup
  .string()
  .nullable()
  .default(EAN_NUMBER_START)
  .startsWith(EAN_NUMBER_START)
  .exactLength(EAN_NUMBER_LENGTH)
  .when({
    is: (value: string | null) => value && !isEanNumberValid(value),
    then: (schema) => schema.invalid(),
  });

export default eanNumberSchema;
