import { TKey } from 'i18next';
import { downloadFile, previewFile } from 'utils/handleFile';
import { apiUrl } from 'api/const';
import { getDossierDocumentFile } from 'api/dossier/requests';
import { getEstateDocumentFile } from 'api/estate/requests';
import { getLegalPersonDocumentFile } from 'api/person/legal/requests';
import { getNaturalPersonDocumentFile } from 'api/person/natural/requests';
import { FileGet, FileUrl, HandleFileAction } from './types';

export const getDeletionTooltipKey = (
  sentOut: boolean,
  isLastFile: boolean,
): TKey<'table', 'documents'> => {
  if (sentOut) {
    return 'tooltips.documentProcessed';
  }
  if (isLastFile) {
    return 'tooltips.lastFile';
  }
  return 'actions.delete';
};

export const getFileUrl: FileUrl = {
  estate: apiUrl.estateDocumentFile,
  naturalPerson: apiUrl.naturalPersonDocumentFile,
  legalPerson: apiUrl.legalPersonDocumentFile,
  dossier: apiUrl.rentalDossierDocumentFile,
};

export const getFile: FileGet = {
  estate: getEstateDocumentFile,
  naturalPerson: getNaturalPersonDocumentFile,
  legalPerson: getLegalPersonDocumentFile,
  dossier: getDossierDocumentFile,
};

export const handleFile: HandleFileAction = async (
  actionType,
  { file, document, entityId, scope },
) => {
  const { ok, response } = await getFile[scope](entityId, document.id, file.id);
  if (ok && response) {
    actionType == 'download'
      ? downloadFile(response, file.fileName)
      : previewFile(response, file.contentType);
  }
};
