import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getEstateViewData } from 'api/estate/requests';
import { EstateData, EstateViewInterface, FetchedEstateData } from 'api/estate/types';

export const getEstateData = ({
  id: _id,
  businessId: _businessId,
  korfineReference: _korfineRef,
  ...data
}: FetchedEstateData): EstateData => data;

export const getEstateConfig = (estateId: string): LoaderConfig<EstateViewInterface> => ({
  id: estateId,
  scope: 'estate',
  fetch: getEstateViewData,
  shouldRedirect: (pathname) => pathname === routes.estate(estateId),
  redirectUrl: routes.estateTab(estateId).estateInfo,
});

export const isEstateAdditionalData = ({
  conformity,
  hasSyndic,
  isFurnished,
  smokeDetectors,
  habitableSurface,
  managementType,
}: EstateViewInterface['data']) =>
  !!(
    conformity ||
    hasSyndic ||
    isFurnished ||
    smokeDetectors !== null ||
    habitableSurface ||
    managementType
  );

export const isEstateMedia = ({
  hasEpc,
  hasGas,
  hasElectricity,
}: EstateViewInterface['data']) => hasEpc || hasGas || hasElectricity;

export const shouldDisplayEstateInfoTab = (data: EstateViewInterface['data']) =>
  isEstateAdditionalData(data) || isEstateMedia(data) || !!data.estateComposition;
