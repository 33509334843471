import { AddIcon, DeleteIcon, SwitchIcon } from 'icons';
import { HistoryOfChangesInterface } from './types';

const HistoryRecordIcon = ({
  eventType,
}: Pick<HistoryOfChangesInterface, 'eventType'>) => {
  switch (eventType) {
    case 'Created':
      return <AddIcon />;
    case 'Updated':
      return <SwitchIcon />;
    case 'Deleted':
      return <DeleteIcon />;
    default:
      return null;
  }
};

export default HistoryRecordIcon;
