import isTruthyValue from 'utils/isTruthyValue';

export const isTruthyValueInObject = (obj: object | null | undefined): boolean =>
  Object.values(obj ?? {}).some((value) => {
    if (Array.isArray(value)) {
      return !!value.length;
    }

    if (value !== null && typeof value === 'object') {
      return isTruthyValueInObject(value);
    }
    return isTruthyValue(value);
  });
