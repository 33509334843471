import { AffiliatedPersonInterface } from 'api/common/types';

export const getAffiliatedPersonData = (person: AffiliatedPersonInterface) => {
  if (person.representative) {
    return {
      email: person.representative.email || null,
      phone: person.representative.phone || null,
      address: person.representative.address,
    };
  }
  return {
    email: person.email || null,
    phone: person.phone || null,
    address: person.address,
  };
};
