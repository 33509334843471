import i18n from 'i18n';
import { NaturalPersonFormDefaultValues } from 'modules/PersonForm/NaturalPersonForm/types';
import { estateToRelation, personToRelation } from 'modules/Relation/utils';
import {
  getNaturalPersonEstates,
  getNaturalPersonRepresentations,
  getPrivateRelation,
} from 'api/relations/requests';
import { FetchedRepresentative, FetchedRepresentedParty } from 'api/relations/types';

export const fetchNaturalPersonAffiliations = async (
  naturalPersonId: string,
): Promise<Omit<NaturalPersonFormDefaultValues, 'naturalPerson'>> => {
  let representatives: NaturalPersonFormDefaultValues['representatives'] = [];
  let representedParties: NaturalPersonFormDefaultValues['representedParties'] = [];
  let ownerships: NaturalPersonFormDefaultValues['ownerships'] = [];
  let privateRelation: NaturalPersonFormDefaultValues['privateRelation'] = null;
  let privateRelationType: NaturalPersonFormDefaultValues['privateRelationType'];

  const privateRelationResponse = await getPrivateRelation(naturalPersonId);
  if (privateRelationResponse.ok && privateRelationResponse.response) {
    const { id, type, relative } = privateRelationResponse.response;
    privateRelationType = type;
    privateRelation = { ...personToRelation(relative), responseId: id };
  }

  const representationsResponse = await getNaturalPersonRepresentations(naturalPersonId);
  if (representationsResponse.ok && representationsResponse.response.length) {
    representedParties = representationsResponse.response
      .filter(
        (relation): relation is FetchedRepresentedParty =>
          'representedParty' in relation && !!relation.representedParty,
      )
      .map(({ relationId, isDeletable, representedParty }) => ({
        ...personToRelation(representedParty),
        responseId: relationId,
        isSaved: true,
        deletable: isDeletable,
        notDeletableReason: !isDeletable
          ? i18n.t('tooltips.representeeNoDeletable')
          : undefined,
      }));

    representatives = representationsResponse.response
      .filter(
        (relation): relation is FetchedRepresentative =>
          'representative' in relation && !!relation.representative,
      )
      .map(({ relationId, isDeletable, representative }) => ({
        ...personToRelation(representative),
        responseId: relationId,
        isSaved: true,
        deletable: isDeletable,
        notDeletableReason: !isDeletable
          ? i18n.t('tooltips.representativeNoDeletable')
          : undefined,
      }));
  }

  const estatesResponse = await getNaturalPersonEstates(naturalPersonId);
  if (estatesResponse.ok && estatesResponse.response.length) {
    ownerships = estatesResponse.response.map(({ id, estate }) => ({
      ...estateToRelation(estate),
      responseId: id,
      isSaved: true,
    }));
  }

  return {
    representatives,
    representedParties,
    ownerships,
    privateRelation,
    privateRelationType,
  };
};
