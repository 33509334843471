import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import Table from 'templates/Table';
import { getBusinesses, migrateBusinesses } from 'api/businesses/requests';
import { FetchedBusinessListItem } from 'api/businesses/types';
import PageTitle from 'components/PageTitle';
import { businessesColumns } from './static-data';

const BusinessesList = () => (
  <Box sx={({ mixins }) => mixins.tablePageWrapper}>
    <PageTitle page="businesses" />
    <Table
      tKey="businesses"
      rowIdKey="externalGuid"
      get={getBusinesses}
      rowsPerPage={[10, 25, 50, 100]}
      withUrlSearchParams
      columns={businessesColumns}
      toolbarProps={{
        rowSelection: {
          buttonLabel: 'businesses.header.migrateSelected',
          buttonRole: 'cta',
          handleSelectedRows: async (
            rowsIds,
            { rows, setUpAndOpenConfirmationModal, updateRows },
          ) =>
            setUpAndOpenConfirmationModal({
              type: 'confirmation',
              translationKey: 'migrateBusinesses',
              translationValues: { count: rowsIds.length, name: rows.at(0)?.name },
              onSubmit: () => migrateBusinesses({ idsToMigrate: rowsIds.map(String) }),
              onSuccess: updateRows,
            }),
          isRowSelectable: ({ row }: GridRowParams<FetchedBusinessListItem>) =>
            !row.migrated,
        },
      }}
    />
  </Box>
);

export default BusinessesList;
