import { useTranslation } from 'react-i18next';
import { addMonths, subYears } from 'date-fns';
import {
  currencyOptions,
  dossierDurations,
} from 'formData/dossier/rentalConditions/selectOptions';
import useFormMethods from 'hooks/useFormMethods';
import useRentalConditionsFields from 'hooks/useRentalConditionsFields';
import { FormSection } from 'templates/FormTemplates';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { RentalConditions } from 'api/dossier/types';
import {
  ControlledDateInput,
  ControlledNumberInput,
  ControlledPaymentInput,
  ControlledSelect,
} from 'components/inputs';

type RentalConditionsFieldNames = keyof Pick<
  RentalConditions,
  | 'rentalPrice'
  | 'charges'
  | 'entryDate'
  | 'duration'
  | 'endDate'
  | 'durationNumberOfMonths'
>;

export const RentalConditionsStep = () => {
  const { registerController } = useFormMethods<RegisterAddendumForm>();
  const { t } = useTranslation(['modals', 'formFields']);

  const registerInput = (name: RentalConditionsFieldNames) => ({
    xs: 6,
    label: t(`formFields:${name}`),
    ...registerController(`rentalConditions.${name}`),
  });

  const {
    hiddenFields,
    onRentalPriceBlur,
    onRentalDurationChange,
    onDurationNumberOfMonthsBlur,
    onEntryDateChange,
  } = useRentalConditionsFields('rentalConditions');

  return (
    <FormSection sx={{ pt: 1 }}>
      <ControlledPaymentInput
        {...registerInput('rentalPrice')}
        options={currencyOptions}
        onBlur={onRentalPriceBlur}
      />
      <ControlledPaymentInput {...registerInput('charges')} options={currencyOptions} />
      <ControlledDateInput
        {...registerInput('entryDate')}
        onChange={onEntryDateChange}
        maxDate={addMonths(new Date(), 6)}
        minDate={subYears(new Date(), 20)}
      />
      <ControlledSelect
        {...registerInput('duration')}
        onChange={onRentalDurationChange}
        options={dossierDurations}
      />
      {!hiddenFields.durationNumberOfMonths && (
        <ControlledNumberInput
          isInteger
          {...registerInput('durationNumberOfMonths')}
          onBlur={onDurationNumberOfMonthsBlur}
        />
      )}
      <ControlledDateInput {...registerInput('endDate')} disabled />
    </FormSection>
  );
};
