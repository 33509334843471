import i18n from 'i18n';
import { firstCharLowerCase } from 'utils/string';
import { ProductDocument } from 'api/dossier/products/types';
import getPersonLabel from './getPersonLabel';

export const getDocumentLabel = ({
  name,
  person,
  relatedEntityType,
}: ProductDocument) => {
  const scope = firstCharLowerCase(relatedEntityType);
  const documentName = i18n.t(`documents:${scope}.${name}`, name);

  switch (relatedEntityType) {
    case 'Dossier':
      return documentName;
    case 'Estate':
      return `${documentName} - Estate`;
    default:
      return person ? `${documentName} - ${getPersonLabel(person)}` : documentName;
  }
};

export default getDocumentLabel;
