import { forwardRef, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, To } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCustomNavigate from 'hooks/useCustomNavigate';
import openExternal from 'utils/openExternal';
import { getRedirectionLink } from 'api/config/requests';

export const CustomLink = forwardRef<
  HTMLAnchorElement,
  LinkProps & {
    external?: boolean;
    fetchRedirectionLink?: string;
    customClickAction?: boolean;
  }
>(({ onClick, external, fetchRedirectionLink, customClickAction, ...props }, ref) => {
  const [redirectUrl, setRedirectUrl] = useState('');

  const { t } = useTranslation('errors');
  const navigate = useCustomNavigate();

  const getRedirectUrl = useCallback(async () => {
    if (fetchRedirectionLink) {
      const { ok, response } = await getRedirectionLink(fetchRedirectionLink);
      if (ok) {
        setRedirectUrl(response.redirectUrl);
      }
    }
  }, []);

  useEffect(() => {
    getRedirectUrl();
  }, [getRedirectUrl]);

  const handleRedirect = useCallback(
    async (to: To) => {
      if (redirectUrl) {
        openExternal(redirectUrl);
      } else if (fetchRedirectionLink) {
        const { ok, response } = await getRedirectionLink(fetchRedirectionLink);
        if (ok) {
          openExternal(response.redirectUrl);
        } else {
          toast.error(t('externalService'));
        }
      } else {
        openExternal(to as string);
      }
    },
    [fetchRedirectionLink, redirectUrl],
  );

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    const { to, relative, state, replace, preventScrollReset } = props;
    if (!to && customClickAction && onClick) {
      onClick(e);
    } else if (!external) {
      const navigated = navigate(to, { state, relative, replace, preventScrollReset });
      if (navigated && onClick) {
        onClick(e);
      }
    } else {
      handleRedirect(to);
    }
  };

  return <Link {...props} ref={ref} onClick={handleClick} />;
});
