import { createContext } from 'react';
import {
  DossierEditableFieldsInterface,
  DossierFormContextInterface,
  DossierFormProviderProps,
} from './types';

const dossierEditableFields: DossierEditableFieldsInterface = {
  estate: true,
  parties: true,
  rentalConditions: true,
};

export const DossierFormContext = createContext<DossierFormContextInterface>({
  mode: 'creation',
  dossierId: null,
  assignedEstates: [],
  assignedLandlords: [],
  assignedTenants: [],
  setRegion: () => {},
  editableFields: dossierEditableFields,
});

export const DossierFormProvider = ({
  children,
  dossierId = null,
  assignedEstates = [],
  assignedLandlords = [],
  assignedTenants = [],
  editableFields,
  setRegion,
}: DossierFormProviderProps) => {
  return (
    <DossierFormContext.Provider
      value={{
        mode: dossierId ? 'edition' : 'creation',
        setRegion,
        dossierId,
        assignedEstates,
        assignedLandlords,
        assignedTenants,
        editableFields: { ...dossierEditableFields, ...editableFields },
      }}
    >
      {children}
    </DossierFormContext.Provider>
  );
};
