import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import hasPermission from 'utils/hasPermission';
import { FeatureFlagName, RolePermissions } from 'api/config/types';
import routes from './routes';

interface Props {
  children: JSX.Element;
  featureFlag?: FeatureFlagName;
  permission?: RolePermissions;
  fallback?: string;
  redirect?: () => string | undefined;
}

const ProtectedRoute = ({
  permission,
  fallback = routes.dashboard,
  redirect,
  children,
  featureFlag,
}: Props) => {
  const { isFeatureActive } = useContext(FeatureFlagsContext);
  let shouldFallback = false;

  const redirectionPath = redirect && redirect();
  if (redirectionPath) {
    return <Navigate to={redirectionPath} replace />;
  }

  if (featureFlag && !isFeatureActive(featureFlag)) {
    shouldFallback = true;
  }

  if (permission && !hasPermission(permission)) {
    shouldFallback = true;
  }

  if (shouldFallback) {
    return <Navigate to={fallback} replace />;
  }

  return children;
};

export default ProtectedRoute;
