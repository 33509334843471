import { useTranslation } from 'react-i18next';
import CardRow, { AffiliatedPersonRowValue, RowValues } from 'templates/CardRow';
import { PrivateRelationInterface } from 'api/person/natural/types';

const PrivateRelationRow = ({ type, relative }: PrivateRelationInterface) => {
  const { t } = useTranslation(['common', 'enums']);

  return (
    <CardRow
      title={
        <RowValues
          asTitle
          primary={t('pageCard.person.privateRelation')}
          secondary={t(`enums:privateRelation.${type}`)}
        />
      }
      value={<AffiliatedPersonRowValue {...relative} />}
    />
  );
};

export default PrivateRelationRow;
