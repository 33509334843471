import { useEffect, useState } from 'react';
import { getNaturalPerson } from 'api/person/natural/requests';
import { FetchedNaturalPersonData } from 'api/person/natural/types';
import { getLegalPersonRepresentatives } from 'api/relations/requests';
import { Select } from 'components/inputs';
import { SelectOption } from 'components/inputs/Select/types';

interface RepresentativeSelectorProps {
  legalPersonId: string;
  value: string;
  updateRepresentative: (personData: FetchedNaturalPersonData) => void;
}

export const RepresentativeSelector = ({
  legalPersonId,
  value,
  updateRepresentative,
}: RepresentativeSelectorProps) => {
  const [selectValue, setSelectValue] = useState(value);
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getOptions = async () => {
      const { ok, response } = await getLegalPersonRepresentatives(legalPersonId);
      if (ok) {
        setOptions(
          response.map(({ representative: { name, id } }) => ({
            label: name,
            value: id,
          })),
        );
      }
    };
    getOptions();
  }, [legalPersonId]);

  const onChange = async (personId: string) => {
    setSelectValue(personId);
    const { ok, response } = await getNaturalPerson(personId);
    if (ok) {
      updateRepresentative(response);
    }
  };

  return <Select options={options} value={selectValue} onChange={onChange} />;
};
