import Omnicasa from 'assets/services/Omnicasa.svg';
import RealSmart from 'assets/services/Realsmart.svg';
import Skarabee from 'assets/services/Skarabee.svg';
import { ThirdPartySoftwareName } from 'api/businesses/types';

export const EXTERNAL_SOFTWARE_LOGO: Record<ThirdPartySoftwareName, string> = {
  Omnicasa,
  RealSmart,
  Skarabee,
};
