import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mui/material';
import { faqTableColumns } from 'pages/FaqManagement/static-data';

export const BodyDivider = ({
  sectionsDivider,
}: {
  sectionsDivider: 'general' | 'products';
}) => {
  const { t } = useTranslation('table');
  return (
    <TableRow>
      <TableCell
        colSpan={faqTableColumns.length + 1}
        align="center"
        sx={({ palette }) => ({
          color: palette.grey[500],
          fontWeight: 500,
          fontSize: 14,
          backgroundColor: palette.divider,
        })}
      >
        {t(`faq.dividers.${sectionsDivider}`)}
      </TableCell>
    </TableRow>
  );
};
