import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { UNITS } from 'consts/units';
import useEpcFields from 'hooks/useEpcFields';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import {
  ControlledCheckbox,
  ControlledDateInput,
  ControlledNumberInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/inputs';

const EpcField = () => {
  const { t } = useTranslation('formFields');

  const { registerController } = useFormMethods<EstateFormInterface>();

  const {
    options: typeOptions,
    onTypeChange,
    updateCheckbox,
    onTypeClear,
  } = useEpcFields();

  return (
    <Grid item container xs={12}>
      <Grid
        item
        container
        sx={({ shape }) => ({ border: shape.border, borderRadius: 2, padding: 2 })}
        rowGap={2}
      >
        <ControlledCheckbox
          xs={12}
          {...registerController('estateDetails.hasEpc')}
          label={t('epc.self')}
        />
        <Grid item container xs={12} spacing={2}>
          <ControlledSelect
            md={3}
            xs={12}
            options={typeOptions}
            {...registerController('estateDetails.epc.type')}
            label={t('epc.type')}
            onChange={onTypeChange}
            onClear={onTypeClear}
            clearable
          />
          <ControlledTextInput
            md={3}
            xs={12}
            {...registerController('estateDetails.epc.primaryEnergyConsumption')}
            label={t('epc.primaryEnergyConsumption')}
            endAdornment={UNITS.energyConsumption}
            onChange={updateCheckbox}
            withSpecialChars
            disabled
          />
          <ControlledNumberInput
            md={3}
            xs={12}
            {...registerController('estateDetails.epc.referenceEnergyConsumption')}
            label={t('epc.referenceEnergyConsumption')}
            endAdornment={UNITS.energyConsumption}
            allowNegative
            precision={2}
            onChange={updateCheckbox}
          />
          <ControlledDateInput
            md={3}
            xs={12}
            {...registerController('estateDetails.epc.validUntil')}
            label={t('epc.validUntil')}
            onChange={updateCheckbox}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EpcField;
