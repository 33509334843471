import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { ProductContext } from 'contexts/product/productContext';
import { ProductProcessInterface } from 'api/dossier/products/types';

const ProductProcessProgress = ({
  stages,
  processName,
}: Pick<ProductProcessInterface, 'stages' | 'processName'>) => {
  const { t } = useTranslation('dossierProducts');
  const { productName } = useContext(ProductContext);

  const completedStagesCount = stages.filter(({ isCompleted }) => isCompleted).length;
  const allStagesCount = stages.length - 1; // Last stage is the target stage, it should not be included into progress bar

  return allStagesCount ? (
    <Stack justifyContent="center" alignItems="center">
      <LinearProgress
        variant="determinate"
        color="success"
        value={(completedStagesCount * 100) / allStagesCount}
        sx={{ width: '100%', height: 6, borderRadius: 5, mb: 0.5 }}
      />
      <Typography variant="caption">
        {t(`${productName}.processes.${processName}`, {
          defaultValue: processName,
          allStagesCount,
          completedStagesCount,
        })}
      </Typography>
    </Stack>
  ) : null;
};

export default ProductProcessProgress;
