import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useLoader from 'hooks/useLoader';
import { NaturalPersonForm } from 'modules/PersonForm';
import { NaturalPersonFormDefaultValues } from 'modules/PersonForm/NaturalPersonForm/types';
import { getNaturalPersonDefaultValues } from 'modules/PersonView/NaturalPersonDetailsCard/utils';
import routes from 'router/routes';
import FullPageCard from 'templates/FullPageCard';
import { getNaturalPerson } from 'api/person/natural/requests';
import { fetchNaturalPersonAffiliations } from './utils';

const NaturalPersonEdition = () => {
  const [loadingAffiliations, setLoadingAffiliations] = useState(true);
  const [affiliations, setAffiliations] = useState<
    Omit<NaturalPersonFormDefaultValues, 'naturalPerson'>
  >({
    ownerships: [],
    representatives: [],
    representedParties: [],
    privateRelation: null,
    privateRelationType: undefined,
  });

  const { naturalPersonId } = useParams();
  if (!naturalPersonId) {
    return <Navigate to={routes.persons} />;
  }

  const { data, Loader } = useLoader({
    id: naturalPersonId,
    scope: 'naturalPerson',
    fetch: getNaturalPerson,
  });

  const getAffiliations = useCallback(async () => {
    const affiliations = await fetchNaturalPersonAffiliations(naturalPersonId);
    setLoadingAffiliations(false);
    setAffiliations(affiliations);
  }, [naturalPersonId]);

  useEffect(() => {
    getAffiliations();
  }, [getAffiliations]);

  return (
    <Loader loadingAdditionalData={loadingAffiliations}>
      {data ? (
        <FullPageCard>
          <NaturalPersonForm
            defaultValues={{
              naturalPerson: getNaturalPersonDefaultValues(data),
              ...affiliations,
            }}
            naturalPersonId={naturalPersonId}
            stepsToHide={['documents']}
          />
        </FullPageCard>
      ) : null}
    </Loader>
  );
};

export default NaturalPersonEdition;
