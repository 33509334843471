import { SvgIconProps } from '@mui/material';
import { DownloadIcon, EyeShowIcon } from 'icons';

const DocumentActionIcon = ({ name, ...props }: { name: string } & SvgIconProps) => {
  switch (name) {
    case 'download':
      return <DownloadIcon {...props} />;
    case 'preview':
      return <EyeShowIcon {...props} />;
    default:
      return null;
  }
};

export default DocumentActionIcon;
