import { isAddressIncomplete } from 'formData/common/address/utils';
import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getLegalPersonViewData } from 'api/person/legal/requests';
import { LegalPersonViewInterface } from 'api/person/legal/types';

export const getLegalPersonConfig = (
  legalPersonId: string,
): LoaderConfig<LegalPersonViewInterface> => ({
  id: legalPersonId,
  scope: 'legalPerson',
  fetch: getLegalPersonViewData,
  shouldRedirect: (pathname) => pathname === routes.legalPerson(legalPersonId),
  redirectUrl: routes.legalPersonTab(legalPersonId).details,
});

export const isLegalPersonMissingRequiredData = ({
  address,
  language,
  name,
}: LegalPersonViewInterface['data']) => {
  if (!name || !language || isAddressIncomplete(address)) {
    return true;
  }
  return false;
};
