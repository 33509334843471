import { createContext, PropsWithChildren } from 'react';
import useFileUpload from 'hooks/useFileUpload';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import { DocumentScope } from 'api/documents/types';
import { UploadContext } from './types';

const FilesUploadContext = createContext<UploadContext>({
  documents: [],
  isAnyPending: false,
  formMethods: {} as UploadContext['formMethods'],
  isAnyDocumentEmpty: false,
  addDocument: () => {},
  updateDocument: () => {},
  removeDocument: () => {},
  saveDocuments: async () => ({
    isAnyDocumentSaved: false,
    isAnyDocumentWithError: false,
    isAnyDocumentWithWarning: false,
  }),
  validateDocuments: async () => {},
  markEmptyDocuments: () => {},
  clearDocuments: () => {},
});

const FilesUploadProvider = ({
  children,
  scope,
}: PropsWithChildren<{ scope: DocumentScope }>) => {
  const props = useFileUpload({ defaultFields: [getDocumentToUpload({ scope })] });

  return (
    <FilesUploadContext.Provider value={props}>{children}</FilesUploadContext.Provider>
  );
};

export { FilesUploadContext, FilesUploadProvider };
