import { BelgiumCountry } from 'formData/common/address/static-data';
import {
  AddressInterface,
  FetchedAddress,
  FetchedShortAddress,
  ShortAddress,
} from 'api/common/types';

export const transformShortAddress = ({
  address,
  city,
  postCode,
}: FetchedShortAddress): ShortAddress => ({
  address: address ?? '',
  city: city ?? '',
  postCode: postCode ?? '',
});

export const transformAddress = ({
  city,
  postCode,
  street,
  houseNumber,
  country,
  streetTranslation,
  ...rest
}: FetchedAddress): AddressInterface => ({
  ...rest,
  streetTranslation: streetTranslation ?? undefined,
  city: city ?? { en: '', fr: '', nl: '' },
  postCode: postCode ?? '',
  street: street ?? { en: '', fr: '', nl: '' },
  houseNumber: houseNumber ?? 0,
  country: country ?? BelgiumCountry.name,
});
