import { useState } from 'react';
import { HandleBlurChange, HandleChange } from 'types/handleChange';
import { ControlledTextInput } from 'components/inputs';
import { FieldWarning } from '../types';
import { compareEmails } from './utils';

const ControlledEmailInput: typeof ControlledTextInput = ({
  onChange,
  onBlur,
  ...props
}) => {
  const [warning, setWarning] = useState<FieldWarning>();
  const [emailValue, setEmailValue] = useState('');

  const handleChange: HandleChange = (e) => {
    setWarning(undefined);
    setEmailValue(e.target.value);
    onChange?.(e);
  };

  const handleBlur: HandleBlurChange = (e) => {
    const warning = compareEmails(emailValue);
    setWarning(
      warning
        ? { message: { key: warning.key, values: { emails: warning.emails } } }
        : undefined,
    );
    onBlur?.(e);
  };

  return (
    <ControlledTextInput
      onChange={handleChange}
      onBlur={handleBlur}
      warning={warning}
      {...props}
    />
  );
};

export default ControlledEmailInput;
