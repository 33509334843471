export const isEanNumberValid = (value: string) => {
  if (value.split('').some((value) => isNaN(Number(value)))) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < value.length - 1; i++) {
    const digit = Number(value[i]);
    const multiplier = (i + 1) % 2 === 0 ? 1 : 3;
    sum += digit * multiplier;
  }

  const checkDigit = Math.ceil(sum / 10) * 10 - sum;
  if (checkDigit !== Number(value[value.length - 1])) {
    return false;
  }

  return true;
};
