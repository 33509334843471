import { Stack } from '@mui/material';
import { ProductProcessInterface } from 'api/dossier/products/types';
import ProductCurrentStage from './ProductCurrentStage';
import ProductProcessProgress from './ProductProcessProgress';

export const ProductProcess = ({
  stages,
  processName,
  currentStageIndex,
}: ProductProcessInterface) => {
  const currentStage = stages.find(
    ({ index, isCompleted }) => index === currentStageIndex && !isCompleted,
  );

  return currentStage ? (
    <Stack gap={2}>
      <Stack alignItems="center">
        <ProductCurrentStage {...currentStage} />
      </Stack>
      <ProductProcessProgress stages={stages} processName={processName} />
    </Stack>
  ) : null;
};
