import { Box } from '@mui/material';
import ThirdPartySoftware from 'modules/ThirdPartySoftware';
import PageTitle from 'components/PageTitle';

const Settings = () => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <PageTitle page="settings" />
    </Box>
    <Box display="flex" justifyContent="center" margin={2}>
      <ThirdPartySoftware />
    </Box>
  </Box>
);

export default Settings;
