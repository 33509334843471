import { DraftDocument } from 'contexts/fileUpload/types';
import i18n from 'i18n';
import { formatDate } from 'utils/date';
import { uploadDossierDocument } from 'api/dossier/requests';
import { uploadEstateDocument } from 'api/estate/requests';
import { uploadLegalPersonDocument } from 'api/person/legal/requests';
import { uploadNaturalPersonDocument } from 'api/person/natural/requests';
import { DocumentToUpload, ProcessedFileStatus, SaveDocument, UploadFile } from './types';

const uploadFile: UploadFile = {
  estate: uploadEstateDocument,
  naturalPerson: uploadNaturalPersonDocument,
  legalPerson: uploadLegalPersonDocument,
  dossier: uploadDossierDocument,
};

export const saveDocument: SaveDocument = async ({
  entityId,
  scope,
  files,
  documentType,
  documentName,
  otherCustomType,
  date,
}) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  if (documentType && documentName) {
    formData.append('documentName', documentName);
    formData.append('documentType', documentType);
    if (otherCustomType) {
      formData.append('otherCustomType', otherCustomType);
    }
  }

  if (date) {
    formData.append('date', formatDate(date, 'yyyy-MM-dd') ?? '');
  }

  const res = await uploadFile[scope](entityId, formData);
  return res.ok
    ? { status: 'SAVED', documentId: res.response.id }
    : { status: 'ERROR', message: res.message };
};

export const getDocumentToUpload = ({
  date = null,
  documentName,
  documentType = null,
  files = [],
  entityId = '',
  ...document
}: DraftDocument): DocumentToUpload => ({
  documentName:
    documentName ??
    (documentType
      ? i18n.t(`documents:${document.scope}.${documentType}`, documentType)
      : ''),
  documentType,
  date,
  files,
  status: files.length ? 'READY' : 'DRAFT',
  entityId,
  ...document,
});

export const setWarningForEmptyDocument = (
  document: DocumentToUpload,
): DocumentToUpload => {
  const shouldWarn = !!document.documentType && !document.files.length;
  return {
    ...document,
    status: shouldWarn ? 'WARNING' : document.status,
    message: shouldWarn ? i18n.t('warnings.documentWithoutFiles') : undefined,
  };
};

export const setPendingStatusForDocument = (
  document: DocumentToUpload,
): DocumentToUpload =>
  document.status === 'READY' || document.status === 'ERROR'
    ? { ...document, status: 'PENDING' }
    : document;

export const updateDocumentStatus = (document: DocumentToUpload): ProcessedFileStatus => {
  if (document.files.length) {
    return 'READY';
  }
  return document.status === 'WARNING' ? 'WARNING' : 'DRAFT';
};
