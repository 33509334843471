import { Box } from '@mui/material';
import { PageCardHeaderProps } from '../types';
import PageCardHeaderAction from './PageCardHeaderAction';
import PageCardHeaderEdition from './PageCardHeaderEdition';
import PageCardHeaderTitle from './PageCardHeaderTitle';

const PageCardHeader = ({
  tKey,
  titleProps,
  editionProps,
  actionProps,
  size = 'normal',
  variant = 'dark',
}: PageCardHeaderProps) => (
  <Box
    display="flex"
    justifyContent={!titleProps?.hidden ? 'space-between' : 'flex-end'}
    alignItems="center"
    sx={{
      py: size === 'large' ? 2 : 1,
      px: 2,
      backgroundColor: variant === 'dark' ? 'primary.main' : 'common.white',
      borderBottom: ({ shape }) => (variant === 'light' ? shape.border : undefined),
      minHeight: 52,
    }}
  >
    {!titleProps?.hidden && (
      <PageCardHeaderTitle variant={variant} size={size} tKey={tKey} {...titleProps} />
    )}
    {editionProps && !editionProps.hidden && (
      <PageCardHeaderEdition variant={variant} {...editionProps} />
    )}
    {actionProps && !actionProps.hidden && (
      <PageCardHeaderAction {...actionProps} dataTestId={`${tKey}-add`} />
    )}
  </Box>
);

export default PageCardHeader;
