import StorageKeys from 'consts/storage';
import { UserRole } from 'api/users/types';

/**
 * @returns true when logged user has provided role
 */
const hasRole = (role: UserRole) => {
  const storedRole = localStorage.getItem(StorageKeys.UserRole);
  return role === storedRole;
};

export default hasRole;
