import { Navigate, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';
import { dossierDocumentsTabRoutes, dossierTabRoutes } from 'router/routes';
import DossierDetailsTab from 'pages/Dossiers/Details/tabs/DossierDetailsTab';
import DossierDocumentsTab, {
  DossierEstateDocuments,
  DossierPersonDocuments,
  DossierProductsDocuments,
} from 'pages/Dossiers/Details/tabs/DossierDocumentsTab';
import DossierHistoryTab from 'pages/Dossiers/Details/tabs/DossierHistoryTab';
import {
  ProductsGrid,
  SingleProduct,
} from 'pages/Dossiers/Details/tabs/DossierProductsTab';

export const dossierTabs: RouteObject[] = [
  { path: dossierTabRoutes.products, element: <ProductsGrid /> },
  {
    path: `${dossierTabRoutes.products}/:productId`,
    element: <SingleProduct />,
  },
  { path: dossierTabRoutes.details, element: <DossierDetailsTab /> },
  {
    path: dossierTabRoutes.documents,
    element: <DossierDocumentsTab />,
    children: [
      { path: '', element: <Navigate to="products" /> },
      { path: dossierDocumentsTabRoutes.products, element: <DossierProductsDocuments /> },
      { path: dossierDocumentsTabRoutes.estate, element: <DossierEstateDocuments /> },
      {
        path: dossierDocumentsTabRoutes.tenants,
        element: <DossierPersonDocuments type="tenant" />,
      },
      {
        path: dossierDocumentsTabRoutes.landlords,
        element: <DossierPersonDocuments type="landlord" />,
      },
      { path: '*', element: <Navigate to="products" /> },
    ],
  },
  {
    path: dossierTabRoutes.history,
    element: (
      <ProtectedRoute featureFlag="HistoryLogging" fallback={dossierTabRoutes.details}>
        <DossierHistoryTab />
      </ProtectedRoute>
    ),
  },
  { path: '*', element: <Navigate to={dossierTabRoutes.products} /> },
];
