import { useCallback, useEffect, useState } from 'react';
import { DataUrl } from 'types/data';
import { deleteEstatePhoto, uploadEstatePhoto } from 'api/estate/requests';
import { FileToProcess } from 'components/UploadPhoto/types';
import { createPhotoBody } from './utils';

export const useUploadPhoto = (photoUrl?: string | null) => {
  const [fileDataUrl, setFileDataUrl] = useState<DataUrl>(null);
  const [uploadedFile, setUploadedFile] = useState<FileToProcess | null>(null);

  useEffect(() => {
    const fetchSavedPhoto = async () => {
      if (photoUrl) {
        const blob = await (await fetch(photoUrl)).blob();
        const file = new File([blob], '', { type: blob.type });
        setFileDataUrl(photoUrl);
        setUploadedFile({ file, status: 'SAVED' });
      }
    };

    fetchSavedPhoto();
  }, []);

  const uploadPhoto = useCallback(
    async (estateId: string) => {
      let isValid = true;
      if (uploadedFile && fileDataUrl && fileDataUrl !== photoUrl) {
        const photoBody = createPhotoBody(fileDataUrl, uploadedFile.file);
        const { ok } = await uploadEstatePhoto(estateId, photoBody);
        isValid = ok;
        if (!ok) {
          setUploadedFile((prev) => prev && { ...prev, status: 'ERROR' });
        }
      } else if (photoUrl && !uploadedFile && !fileDataUrl) {
        const { ok } = await deleteEstatePhoto(estateId);
        isValid = ok;
      }
      return isValid;
    },
    [fileDataUrl, uploadedFile],
  );

  const clearFile = () => {
    setFileDataUrl(null);
    setUploadedFile(null);
  };

  return {
    fileDataUrl,
    uploadedFile,
    setFileDataUrl,
    setUploadedFile,
    uploadPhoto,
    clearFile,
  };
};
