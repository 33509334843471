import yup, { numberSchema } from 'utils/yup';

const paymentSchema = yup
  .object({
    value: numberSchema.default(null).nullable(),
    currency: yup.string().default('EUR'),
  })
  .nullable()
  .transform((payment) => (typeof payment?.value !== 'number' ? null : payment));

export default paymentSchema;
