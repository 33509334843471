import api from 'api';
import { apiUrl } from 'api/const';
import { UrlParams } from 'api/types';
import { FetchedUserData, FetchedUsersList, UserFormData, UserResponse } from './types';

export const getUsers = (params: UrlParams = {}) =>
  api.get<FetchedUsersList>(apiUrl.usersWithParams({ ...params }));

export const getUser = (userId: string) => api.get<FetchedUserData>(apiUrl.user(userId));

export const addUser = async (data: UserFormData) =>
  api.post<UserResponse>(apiUrl.users, data);

export const updateUser = async (userId: string, data: UserFormData) =>
  api.put<void>(apiUrl.user(userId), data);
