import businessAndMigrationUrls from './businessAndMigrationUrls';
import dossierUrls from './dossierUrls';
import estatesUrls from './estatesUrls';
import faqUrls from './faqUrls';
import legalPersonsUrls from './legalPersonsUrls';
import naturalPersonsUrls from './naturalPersonsUrls';
import notificationsUrls from './notificationsUrls';
import otherUrls from './otherUrls';
import personsUrls from './personsUrls';
import productsUrls from './productsUrls';
import relationsUrls from './relationsUrls';
import usersUrls from './usersUrls';

export const SAMPLE_BUSINESS_ID = 'FF29E44D-3CB2-401E-8F22-A9093F53612D';
export const BACKEND_ADDRESS =
  process.env.NODE_ENV === 'test' ? '' : window._env_.REACT_APP_BACKEND_URL;
export const MY_KORFINE_COUNTRY_SHEET_URL = window._env_.MY_KORFINE_COUNTRY_SHEET_URL;

export const apiUrl = {
  ...estatesUrls,
  ...personsUrls,
  ...naturalPersonsUrls,
  ...legalPersonsUrls,
  ...relationsUrls,
  ...dossierUrls,
  ...productsUrls,
  ...usersUrls,
  ...businessAndMigrationUrls,
  ...notificationsUrls,
  ...faqUrls,
  ...otherUrls,
};
