import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { registerStateReportSchema } from 'formData/dossier/products/rentalContract/schema';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductDocumentPreview,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import RegisterStateReportFields from './RegisterStateReportFields';
import { RegisterStateReportForm } from './types';

const RegisterStateReport = ({
  sendRequest,
  dossier: { id: dossierId },
}: ProductActionComponentProps) => {
  const methods = useForm<RegisterStateReportForm>({
    // TODO: Add request to fetch user's BIV number and set it as default value
    defaultValues: registerStateReportSchema.getDefault(),
    resolver: resolver(registerStateReportSchema),
  });

  const onSubmit: SubmitHandler<RegisterStateReportForm> = (data) => {
    sendRequest(data);
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container gap={4} mt={1}>
        <FormProvider {...methods}>
          <RegisterStateReportFields />
          {/* probably instead of StateReport there should be ProofOfRegistrationStateReport */}
          <ProductDocumentPreview dossierId={dossierId} type="StateReport" />
        </FormProvider>
      </Grid>
    </ProductActionContent>
  );
};

export default RegisterStateReport;
