import { useMemo } from 'react';
import { Box } from '@mui/material';
import useCountryOptions from 'hooks/useCountryOptions';
import getLng from 'utils/getLng';
import { getVatMask, getVatPlaceholder } from 'utils/vat';
import { Country } from 'api/config/types';
import InputWrapper from '../InputWrapper';
import MaskedInput from '../MaskedInput';
import Select from '../Select';
import { SelectOption } from '../Select/types';
import { VatInputProps } from './types';

const mapVatOptions = ({ name, vatPrefix }: Country): SelectOption => ({
  value: vatPrefix,
  label: name[getLng()],
  countryName: name,
});

const VatInput = ({
  vatNumber = '',
  vatPrefix = 'BE',
  onPrefixChange,
  onNumberChange,
  disabled,
  ...props
}: VatInputProps) => {
  const [options, groupedOptions] = useCountryOptions(mapVatOptions);
  const mask = useMemo(() => getVatMask(vatPrefix, vatNumber), [vatPrefix, vatNumber]);
  const placeholder = useMemo(
    () => getVatPlaceholder(mask, vatPrefix),
    [mask, vatPrefix],
  );

  return (
    <Box display="flex" gap={1} justifyContent="space-between">
      <Box minWidth={75}>
        <Select
          name="vat_prefix"
          value={vatPrefix}
          options={options}
          groupedOptions={groupedOptions}
          onChange={onPrefixChange}
          disabled={disabled}
          renderValue={({ value }) => value}
        />
      </Box>
      <Box width="100%">
        <MaskedInput
          mask={!mask ? (value) => new Array(value.length).fill(/\w|\d/) : mask}
          onChange={onNumberChange}
          value={vatNumber}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
      </Box>
    </Box>
  );
};

const VatInputWrapper = (props: VatInputProps) => InputWrapper(VatInput, props);

export default VatInputWrapper;
