import { useContext } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Backdrop, Box, IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Logo from 'atoms/Logo';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { HelpIcon } from 'icons';
import Navigation from 'modules/Navigation';
import NavItem from 'modules/Navigation/NavItem';
import routes from 'router/routes';
import AgentContactBox from './AgentContactBox';
import {
  getDrawerStyles,
  getSidebarHeaderStyles,
  toggleSidebarButtonStyles,
} from './styles';

interface Props {
  expanded: boolean;
  toggleSidebar: () => void;
  matchesBreakpoint: boolean;
  forFaq?: boolean;
}

const Sidebar = ({ expanded, matchesBreakpoint, toggleSidebar, forFaq }: Props) => {
  const { isFeatureActive } = useContext(FeatureFlagsContext);
  const navigate = useCustomNavigate();
  const onNavItem = () => {
    if (expanded && !matchesBreakpoint) {
      toggleSidebar();
    }
  };

  const expandSidebar = () => {
    if (!expanded) {
      toggleSidebar();
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={getDrawerStyles(expanded)}
        open={expanded}
      >
        <Box
          sx={getSidebarHeaderStyles(expanded)}
          onClick={() => navigate(routes.dashboard)}
        >
          <Logo size={expanded ? 'medium' : 'small'} />
        </Box>
        <Navigation onNavItem={onNavItem} isSidebarExpanded={expanded} forFaq={forFaq} />
        {!forFaq && (
          <Box>
            {isFeatureActive('HowdyGo') && (
              <NavItem
                Icon={HelpIcon}
                label="FAQ"
                to={routes.faqPersons}
                external
                select={() => {}}
                isSelected={false}
                isSidebarExpanded={expanded}
                resetSelection={() => {}}
              ></NavItem>
            )}
            <AgentContactBox onClick={expandSidebar} isSidebarExpanded={expanded} />
          </Box>
        )}
        <Box sx={toggleSidebarButtonStyles}>
          <IconButton size="large" onClick={toggleSidebar}>
            {expanded ? (
              <ChevronLeft fontSize="large" />
            ) : (
              <ChevronRight fontSize="large" />
            )}
          </IconButton>
        </Box>
      </Drawer>
      <Backdrop
        open={expanded && !matchesBreakpoint}
        sx={{ zIndex: ({ zIndex }) => zIndex.drawer - 1 }}
        onClick={toggleSidebar}
      />
    </>
  );
};

export default Sidebar;
