import addressToString from 'utils/addressToString';
import { getObjectKeys } from 'utils/object';
import { getRentalDepositDetails } from 'api/dossier/products/rentalDeposit/requests';
import {
  RentalDepositChosenParties,
  RentalDepositDetails,
} from 'api/dossier/products/rentalDeposit/types';
import { getLegalPerson } from 'api/person/legal/requests';
import { getNaturalPerson } from 'api/person/natural/requests';
import { RentalDepositData } from './types';

const defaultContacts = {
  tenant: null,
  tenantPartner: null,
  tenantRepresentative: null,
  landlord: null,
  landlordRepresentative: null,
  landlordPartner: null,
};

const fetchPartiesDetails = async ({
  multipleTenants,
  ...details
}: RentalDepositDetails) => {
  let data: RentalDepositData = { ...defaultContacts, multipleTenants };

  for (const contactKey of getObjectKeys(defaultContacts)) {
    const contact = details[contactKey];
    if (contact) {
      const { ok, response } = await (contact.personType === 'NaturalPerson'
        ? getNaturalPerson
        : getLegalPerson)(contact.id);
      if (ok) {
        data = {
          ...data,
          [contactKey]: {
            ...response,
            ...contact,
            address: addressToString(response.address),
          },
        };
      }
    }
  }
  return data;
};

export const fetchRentalDepositDetails = async (
  dossierId: string,
  productId: string,
): Promise<RentalDepositData | null> => {
  const { ok, response } = await getRentalDepositDetails(dossierId, productId);
  if (!ok) {
    return null;
  }

  return fetchPartiesDetails(response);
};

export const fetchChosenPartiesDetails = ({
  landlordId,
  multipleTenants,
  tenantId,
  landlordPartnerId,
  landlordRepresentativeId,
  tenantPartnerId,
  tenantRepresentativeId,
}: RentalDepositChosenParties) => {
  const details: RentalDepositDetails = {
    multipleTenants,
    tenant: {
      id: tenantId,
      personType: tenantRepresentativeId ? 'LegalPerson' : 'NaturalPerson',
    },
    tenantPartner: tenantPartnerId
      ? { id: tenantPartnerId, personType: 'NaturalPerson' }
      : null,
    tenantRepresentative: tenantRepresentativeId
      ? { id: tenantRepresentativeId, personType: 'NaturalPerson' }
      : null,
    landlord: {
      id: landlordId,
      personType: landlordRepresentativeId ? 'LegalPerson' : 'NaturalPerson',
    },
    landlordPartner: landlordPartnerId
      ? { id: landlordPartnerId, personType: 'NaturalPerson' }
      : null,
    landlordRepresentative: landlordRepresentativeId
      ? { id: landlordRepresentativeId, personType: 'NaturalPerson' }
      : null,
  };

  return fetchPartiesDetails(details);
};
