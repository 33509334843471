import { Box, IconButton } from '@mui/material';
import DocumentActionIcon from 'icons/DocumentActionIcon';
import { SubProductInterface } from 'api/dossier/products/types';

const SubProductDocuments = ({
  documentActions,
}: Required<Pick<SubProductInterface, 'documentActions'>>) => (
  <Box display="flex" gap={0.5} alignItems="flex-end">
    {documentActions.map(({ name, isAvailable }) => (
      <IconButton size="small" key={name} disabled={!isAvailable} color="primary">
        <DocumentActionIcon name={name} fontSize="small" />
      </IconButton>
    ))}
  </Box>
);

export default SubProductDocuments;
