import api from 'api';
import { apiUrl, BACKEND_ADDRESS } from 'api/const';
import { UrlParams } from 'api/types';
import { DemoFormInterface, DemoInterface, DemoUpdateData, Thumbnail } from './types';

export const uploadNewDemo = (data: DemoFormInterface) =>
  api.post(apiUrl.demo, data, {
    baseURL: BACKEND_ADDRESS,
  });

export const updateDemo = (id: number, data: DemoUpdateData) =>
  api.put(apiUrl.demoUpdate(id.toString()), data, {
    baseURL: BACKEND_ADDRESS,
  });

export const updateThumbnail = (id: number, data: Thumbnail) =>
  api.patch(
    apiUrl.demoUpdate(id.toString()),
    { data },
    {
      baseURL: BACKEND_ADDRESS,
    },
  );

export const deleteDemo = (id: number) =>
  api.delete(apiUrl.demoUpdate(id.toString()), {
    baseURL: BACKEND_ADDRESS,
  });

export const getDemoList = () =>
  api.get<DemoInterface[]>(apiUrl.demo, {
    baseURL: BACKEND_ADDRESS,
  });

export const getDemoByLanguage = (params: UrlParams) =>
  api.get<DemoInterface[]>(apiUrl.demoWithParams({ ...params }), {
    baseURL: BACKEND_ADDRESS,
  });
