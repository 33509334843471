import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { TextDivider } from 'atoms/TextDivider/TextDivider';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';

interface AddressProps {
  index: number;
  type: 'landlords' | 'tenants';
}

export const Address = ({ index, type }: AddressProps) => {
  const { t } = useTranslation('modals');

  return (
    <Box mt={2}>
      <TextDivider
        label={t(
          'productActionModal.RentalContractRegisterAddendum.personsSections.address',
        )}
      />
      <Grid container mt={2} columnSpacing={2} rowSpacing={0.5}>
        <AddressFieldsWithGoogle prefix={`${type}.${index}`} />
      </Grid>
    </Box>
  );
};
