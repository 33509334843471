import { useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { MissingDataForm } from './types';
import { getPartyFields } from './utils';

const usePartiesFields = ({ control }: UseFormReturn<MissingDataForm>) => {
  const { fields: naturalPersonsFields } = useFieldArray({
    control,
    name: 'naturalPersons',
    keyName: 'fieldId',
  });

  const { fields: legalPersonsFields } = useFieldArray({
    control,
    name: 'legalPersons',
    keyName: 'fieldId',
  });

  const tenantsFields = useMemo(
    () => getPartyFields('tenant', legalPersonsFields, naturalPersonsFields),
    [legalPersonsFields, naturalPersonsFields],
  );

  const landlordsFields = useMemo(
    () => getPartyFields('landlord', legalPersonsFields, naturalPersonsFields),
    [legalPersonsFields, naturalPersonsFields],
  );

  return { tenantsFields, landlordsFields };
};

export default usePartiesFields;
