import IBAN from 'iban';
import yup from 'utils/yup';

const ibanSchema = yup
  .string()
  .label('iban')
  .default(null)
  .nullable()
  .transform((value) => value || null)
  .when({
    is: (value: string | null) => value && !IBAN.isValid(IBAN.electronicFormat(value)),
    then: (schema) => schema.invalid(),
  });

export default ibanSchema;
