import { ProductParty } from 'modules/ProductParties/types';
import { RegisterRentalContractForm } from './types';

const transformPersonToRegisterContract = ({
  id,
  personType,
  representativeId,
  partnerId,
}: ProductParty) => ({
  id,
  personType,
  representativeId,
  partnerId,
});

export const transformRentalContractData = ({
  tenants,
  landlords,
  ...rest
}: RegisterRentalContractForm): RegisterRentalContractForm => ({
  ...rest,
  landlords: landlords.filter(({ id }) => id).map(transformPersonToRegisterContract),
  tenants: tenants.filter(({ id }) => id).map(transformPersonToRegisterContract),
});
