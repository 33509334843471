import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { personTypeOptions } from 'formData/common/selectOptions';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { Select } from 'components/inputs';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';
import { LegalPersonFormContent } from './AddLegalPersonModal';
import { NaturalPersonFormContent } from './AddNaturalPersonModal';
import { FormModalProps } from './types';

export const AddPersonModal = ({
  open,
  closeModal,
  onSubmit,
}: FormModalProps<FetchedPersonData>) => {
  const [personType, setPersonType] = useState<PersonType>('NaturalPerson');
  const { t } = useTranslation();
  const handleClose = () => {
    closeModal();
  };

  const handleChange = (value: string) => {
    setPersonType(value as PersonType);
  };

  return (
    <ModalWrapper open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <ModalTitle closeModal={handleClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h5" fontWeight="bold">
            {t('addNew.self')}
          </Typography>
          <Select
            value={personType}
            options={personTypeOptions}
            onChange={handleChange}
            sx={{ flex: 1 }}
          />
        </Stack>
      </ModalTitle>
      {personType === 'NaturalPerson' ? (
        <NaturalPersonFormContent onSubmit={onSubmit} closeModal={handleClose} />
      ) : (
        <LegalPersonFormContent onSubmit={onSubmit} closeModal={handleClose} />
      )}
    </ModalWrapper>
  );
};
