import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import CardRow, { AccountNumberRow, AddressRow } from 'templates/CardRow';
import { formatDate } from 'utils/date';
import transformPhone from 'utils/transformPhone';
import { NaturalPersonViewInterface } from 'api/person/natural/types';
import PoliticallyExposedPersonRow from './PoliticallyExposedPersonRow';
import PrivateRelationRow from './PrivateRelationRow';
import TaxResidenceRow from './TaxResidenceRow';

const NaturalPersonDetailsCard = ({
  data: person,
  privateRelation,
}: NaturalPersonViewInterface) => {
  const { t } = useTranslation(['common', 'formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.person.basicInfo')} />
      {person.givenName && (
        <CardRow
          title={t('formFields:givenName')}
          value={person.givenName}
          testId="givenName"
        />
      )}
      {person.email && (
        <CardRow
          title={t('formFields:email')}
          value={
            <Typography
              component="a"
              href={`mailTo:${person.email}`}
              sx={({ mixins }) => ({ ...mixins.link, fontWeight: 600 })}
            >
              {person.email}
            </Typography>
          }
          testId="email"
        />
      )}
      {person.phone?.number && (
        <CardRow
          title={t('formFields:phone')}
          value={
            <Typography
              component="a"
              href={`tel:${transformPhone(person.phone)}`}
              sx={({ mixins }) => ({ ...mixins.link, fontWeight: 600 })}
            >
              {transformPhone(person.phone)}
            </Typography>
          }
          testId="phone"
        />
      )}
      <CardRow
        title={t('formFields:language')}
        value={t(`enums:language.${person.language}`)}
        testId="language"
      />
      <CardRow
        title={t('formFields:sex')}
        value={t(`enums:sex.${person.sex}`)}
        testId="sex"
      />
      <AddressRow address={person.address} testId="naturalPersonAddress" />
      {person.nationality && (
        <CardRow
          title={t('formFields:nationality')}
          value={person.nationality}
          testId="nationality"
        />
      )}
      {person.nationalRegisterNumber && (
        <CardRow
          title={t('formFields:nationalRegisterNumber')}
          value={person.nationalRegisterNumber}
          testId="nationalRegisterNumber"
        />
      )}
      {person.dateOfBirth && (
        <CardRow
          title={t('formFields:dateOfBirth')}
          value={formatDate(person.dateOfBirth)}
          testId="dateOfBirth"
        />
      )}
      {person.placeOfBirth && (
        <CardRow
          title={t('formFields:placeOfBirth')}
          value={person.placeOfBirth}
          testId="placeOfBirth"
        />
      )}
      <AccountNumberRow iban={person.iban} bic={person.bic} />
      <PoliticallyExposedPersonRow
        isPoliticallyExposedPerson={person.isPoliticallyExposedPerson}
      />
      <TaxResidenceRow
        taxResidenceBelgium={person.taxResidenceBelgium}
        taxResidences={person.taxResidences}
      />
      {privateRelation && <PrivateRelationRow {...privateRelation} />}
    </Card>
  );
};

export default NaturalPersonDetailsCard;
