import i18n from 'i18n';
import { MissingField } from 'api/missingData/types';
import isMissingFieldRequired from './isMissingFieldRequired';

export const getInputLabel = (name: string, required?: boolean) => {
  const label = i18n.t(`formFields:${name}`, name);
  return required ? `${label}*` : label;
};

export const getLabelVariant = (missingFields: MissingField[]) =>
  missingFields.length ? 'separate' : 'float';

const getInputProps = (name: string, missingFields: MissingField[]) => {
  const isFieldRequired = isMissingFieldRequired(name, missingFields);
  const label = getInputLabel(name, isFieldRequired);
  return { label, labelVariant: getLabelVariant(missingFields) } as const;
};

export default getInputProps;
