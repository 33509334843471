import { Check } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { ProductRequirement } from './types';

interface Props {
  requirements: ProductRequirement[];
}

const ProductStageRequirements = ({ requirements }: Props) => {
  const uncompletedRequirements = requirements.filter(({ isCompleted }) => !isCompleted);
  const completedRequirements = requirements.filter(({ isCompleted }) => isCompleted);

  return (
    <Stack gap={0.5}>
      {!!uncompletedRequirements.length && (
        <Stack component="ul" pl={2} my={0} gap={0.25}>
          {uncompletedRequirements.map(({ name }) => (
            <Typography
              key={name}
              component="li"
              variant="caption"
              color={({ palette }) => palette.grey[600]}
              lineHeight={1.3}
            >
              {name}
            </Typography>
          ))}
        </Stack>
      )}
      {!!completedRequirements.length && (
        <Stack gap={0.25}>
          {completedRequirements.map(({ name }) => (
            <Stack direction="row" fontSize={12} key={name} gap={0.8}>
              <Check fontSize="inherit" color="action" sx={{ ml: -0.3, mt: 0.2 }} />
              <Typography
                variant="caption"
                color={({ palette }) => palette.grey[500]}
                lineHeight={1.3}
              >
                {name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default ProductStageRequirements;
