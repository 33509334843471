import { PropsWithChildren } from 'react';
import { Breakpoint } from '@mui/material';
import ModalWrapper from 'components/modals/ModalWrapper';
import { FormModalProps } from './types';

type Props = Pick<FormModalProps, 'open' | 'closeModal'> & { maxWidth?: Breakpoint };

export const FormModal = ({
  open,
  closeModal,
  children,
  maxWidth = 'md',
}: PropsWithChildren<Props>) => (
  <ModalWrapper open={open} onClose={closeModal} maxWidth={maxWidth}>
    {children}
  </ModalWrapper>
);
