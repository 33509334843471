import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import { getVersion } from 'api/versions/requests';

const Version = () => {
  const [version, setVersion] = useState<string>();
  const [loading, setLoading] = useState(true);

  const fetchVersion = useCallback(async () => {
    const { ok, response } = await getVersion();
    if (ok) {
      setVersion(response.version);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchVersion();
  }, []);

  if (loading) {
    return <LoadingElement />;
  }

  return <Typography>{version ?? 'Unable to get version from server!'}</Typography>;
};

export default Version;
