import { Theme } from '@mui/material';
import { ThemedStyle } from 'types/themedStyle';

const ICON_SIZE = 24;
const LINE_SIZE = 2;
const LINE_zINDEX = 1;
const ELEMENT_MIN_WIDTH = { xs: 150, md: 200, lg: 150, xl: 200 };
const ELEMENT_MAX_WIDTH = 300;
const ELEMENT_MIN_HEIGHT = 65;

const getElementColor =
  (
    isAvailable: boolean,
    isCompleted: boolean,
    isStageAwaitingForExternalAction: boolean,
    variant: 'main' | 'light' = 'main',
  ) =>
  ({ palette }: Theme) => {
    if (isCompleted) {
      return variant === 'main' ? palette.success.main : palette.success.light;
    }

    if (!isAvailable) {
      return variant === 'main' ? palette.grey[400] : palette.grey[100];
    }

    if (isStageAwaitingForExternalAction) {
      return variant === 'main' ? palette.primary.light : palette.other.lightBlue;
    }

    return variant === 'main' ? palette.secondary.main : palette.secondary.light;
  };

export const getElementStatusStyles = (
  isAvailable: boolean,
  isCompleted: boolean,
  isStageAwaitingForExternalAction: boolean,
): ThemedStyle => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > .status-box-top, & > .status-box-bottom': {
    height: '100%',
    width: '100%',
    zIndex: LINE_zINDEX + 1,
    bgcolor: 'transparent',
  },
  '.MuiAvatar-root': {
    backgroundColor: getElementColor(
      isAvailable,
      isCompleted,
      isStageAwaitingForExternalAction,
    ),
    width: ICON_SIZE,
    height: ICON_SIZE,
    zIndex: LINE_zINDEX + 1,
    fontSize: 16,
    alignSelf: 'center',
    svg: {
      color: 'white',
    },
  },
});

export const getElementContentStyles = (
  isAvailable: boolean,
  isCompleted: boolean,
  isStageAwaitingForExternalAction: boolean,
): ThemedStyle => ({
  display: 'flex',
  minHeight: ELEMENT_MIN_HEIGHT,
  minWidth: ELEMENT_MIN_WIDTH,
  maxWidth: ELEMENT_MAX_WIDTH,
  width: '100%',
  zIndex: LINE_zINDEX + 1,
  borderRadius: 0,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  '& > .content-indicator': {
    backgroundColor: getElementColor(
      isAvailable,
      isCompleted,
      isStageAwaitingForExternalAction,
    ),
    width: 3,
  },
  '& > .MuiCardActionArea-root': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: getElementColor(
      isAvailable,
      isCompleted,
      isStageAwaitingForExternalAction,
      'light',
    ),
    '& > .MuiCardContent-root': {
      p: 1.5,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    },
  },
});

const lineStyles: ThemedStyle = {
  position: 'absolute',
  zIndex: LINE_zINDEX,
  backgroundColor: ({ palette }) => palette.grey[300],
};

export const horizontalLineStyles: ThemedStyle = {
  ...lineStyles,
  height: LINE_SIZE,
  width: '100%',
  left: '50%',
  top: '50%',
  translate: '-50% -50%',
};

export const verticalLineStyles: ThemedStyle = {
  ...lineStyles,
  height: '100%',
  width: LINE_SIZE,
  left: ICON_SIZE / 2,
  top: 0,
};

export const groupedElementsWrapperStyles: ThemedStyle = {
  display: 'grid',
  gridAutoRows: '1fr',
  '& > .flow-tree-element': {
    '&:first-of-type .status-box-top, &:last-of-type .status-box-bottom': {
      backgroundColor: 'white',
    },
  },
};
