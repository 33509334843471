import { Box } from '@mui/material';
import Table from 'templates/Table';
import { getBusinessMigrationQueue } from 'api/businesses/requests';
import PageTitle from 'components/PageTitle';
import { businessMigrationColumns } from './static-data';

const BusinessMigrationQueueList = () => (
  <Box sx={({ mixins }) => mixins.tablePageWrapper}>
    <PageTitle page="businessMigrationQueue" />
    <Table
      rowIdKey="externalBusinessId"
      tKey="migrationQueue"
      get={getBusinessMigrationQueue}
      withUrlSearchParams
      columns={businessMigrationColumns}
    />
  </Box>
);

export default BusinessMigrationQueueList;
