import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BackOfficeContext } from 'contexts/backofficeContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { shouldRedirectWhenImpersonatingIsFinished } from 'modules/Navigation/utils';
import routes from 'router/routes';

const ImpersonatedBusinessBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const { impersonatedBusinessData, finishImpersonating } = useContext(BackOfficeContext);

  const handleFinishImpersonating = () => {
    finishImpersonating();
    const shouldRedirect = shouldRedirectWhenImpersonatingIsFinished(pathname);
    if (shouldRedirect) {
      navigate(routes.businesses);
    }
  };

  return impersonatedBusinessData ? (
    <Box
      width="100%"
      height="100%"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={1}
      bgcolor="primary.main"
      py={0.5}
    >
      <Typography color="common.white" fontWeight="bold">
        {impersonatedBusinessData.name}
      </Typography>
      {impersonatedBusinessData.email ? (
        <>
          <Typography color="common.white">|</Typography>
          <Typography
            component="a"
            href={`mailTo:${impersonatedBusinessData.email}`}
            variant="caption"
            sx={({ mixins }) => ({ ...mixins.link, color: 'common.white' })}
          >
            {impersonatedBusinessData.email}
          </Typography>
        </>
      ) : null}
      <Typography
        variant="caption"
        fontWeight="bold"
        sx={({ mixins }) => ({
          ...mixins.link,
          color: 'common.white',
          position: 'absolute',
          right: 24,
        })}
        onClick={handleFinishImpersonating}
      >
        {t('leave').toUpperCase()}
      </Typography>
    </Box>
  ) : null;
};

export default ImpersonatedBusinessBar;
