import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import useDocumentsData from 'hooks/useDocumentsData';
import useDocumentsModal from 'hooks/useDocumentsModal';
import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import DossierDocumentsTable from 'templates/DossierDocumentsTable';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { attachDossierDocument } from 'api/dossier/requests';
import { DossierViewInterface } from 'api/dossier/types';
import { DocumentsModal } from 'components/modals';
import DocumentActionIcon from './DocumentActionIcon';
import { DossierObjectDocumentsCardProps } from './types';
import {
  createMissingDocumentType,
  handleFileDeletion,
  transformDocumentsData,
} from './utils';

export const DossierObjectDocumentsCard = ({
  entityType,
  entityId,
  scope,
  titleOptions,
  requiredDocumentTypes,
  missingDocumentTypes,
  attachedDocuments = [],
  fetchDossierAndDossierDocuments,
  showTitle = true,
  editable,
}: DossierObjectDocumentsCardProps) => {
  const { t } = useTranslation();
  const dossier = useOutletContext<DossierViewInterface>();

  const { documentsData, loading, fetchDocuments } = useDocumentsData(entityId, scope);
  const { isOpen, missingDocumentType, openModal, closeModal } = useDocumentsModal();

  const transformedDocuments = useMemo(
    () => transformDocumentsData(documentsData, attachedDocuments),
    [documentsData, attachedDocuments],
  );

  const [documentsDataToDisplay, setDocumentsDataToDisplay] =
    useState(transformedDocuments);

  useEffect(() => {
    if (!loading) {
      if (shouldDossierFeatureBeActive(dossier.status)) {
        const missingDocuments = missingDocumentTypes.map(createMissingDocumentType);
        setDocumentsDataToDisplay([...transformedDocuments, ...missingDocuments]);
      } else {
        setDocumentsDataToDisplay(transformedDocuments);
      }
    }
  }, [transformedDocuments, missingDocumentTypes, loading, dossier.status]);

  const onSingleDocumentSave: HandleSingleDocumentSave = async (documentId) => {
    await attachDossierDocument(dossier.id, { documentId, entityId, entityType });
    if (missingDocumentType) {
      closeModal();
    }
  };

  const PersonIcon = useMemo(() => {
    switch (scope) {
      case 'naturalPerson':
        return NaturalPersonIcon;
      case 'legalPerson':
        return LegalPersonIcon;
      default:
        return null;
    }
  }, [scope]);

  const refetchDocuments = async () => {
    await fetchDocuments();
    await fetchDossierAndDossierDocuments();
  };

  const titleProps = useMemo(
    () => ({
      title: showTitle ? t('pageCard.documents.title', { ...titleOptions }) : '',
      startIcon: PersonIcon ? <PersonIcon sx={{ color: 'white' }} /> : null,
      endIcon: (
        <DocumentActionIcon
          scope={scope}
          documentsDataToDisplay={documentsDataToDisplay}
          missingDocumentTypes={
            dossier.status !== 'Cancelled' ? missingDocumentTypes : []
          }
        />
      ),
    }),
    [
      titleOptions,
      PersonIcon,
      scope,
      documentsDataToDisplay,
      dossier.status,
      missingDocumentTypes,
      showTitle,
    ],
  );

  return (
    <>
      <DossierDocumentsTable
        data={documentsDataToDisplay}
        updateDocuments={refetchDocuments}
        openDocumentsModal={openModal}
        context={{
          scope,
          entityId,
          dossierId: dossier.id,
          dossierStatus: dossier.status,
          entityType,
          editable,
          fileDeletion: handleFileDeletion(dossier.status),
        }}
        loading={loading}
        toolbarProps={{ titleProps }}
      />
      {isOpen && (
        <DocumentsModal
          scope={scope}
          entityId={entityId}
          missingDocuments={
            missingDocumentType
              ? [
                  getDocumentToUpload({
                    entityId,
                    scope,
                    documentType: missingDocumentType,
                  }),
                ]
              : undefined
          }
          onClose={closeModal}
          onSubmit={refetchDocuments}
          onSingleDocumentSave={onSingleDocumentSave}
          requiredDocumentTypes={requiredDocumentTypes}
        />
      )}
    </>
  );
};
