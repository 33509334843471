import ErrorIcon from '@mui/icons-material/ErrorOutline';
import SupportAgent from '@mui/icons-material/SupportAgent';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/Add.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/ArrowUp.svg';
import { ReactComponent as AttachIcon } from 'assets/Attach.svg';
import { ReactComponent as BlockIcon } from 'assets/Block.svg';
import { ReactComponent as BookIcon } from 'assets/Book.svg';
import { ReactComponent as BoxIcon } from 'assets/Box.svg';
import { ReactComponent as BusinessesIcon } from 'assets/Businesses.svg';
import { ReactComponent as CalendarIcon } from 'assets/Calendar.svg';
import { ReactComponent as CancelIcon } from 'assets/Cancel.svg';
import { ReactComponent as CheckIcon } from 'assets/Check.svg';
import { ReactComponent as ClearIcon } from 'assets/Clear.svg';
import { ReactComponent as CodeIcon } from 'assets/Code.svg';
import { ReactComponent as CourtIcon } from 'assets/Court.svg';
import { ReactComponent as DashboardIcon } from 'assets/Dashboard.svg';
import { ReactComponent as DeleteIcon } from 'assets/Delete.svg';
import { ReactComponent as DossierIcon } from 'assets/Dossier.svg';
import { ReactComponent as DownloadIcon } from 'assets/Download.svg';
import { ReactComponent as DropIcon } from 'assets/Drop.svg';
import { ReactComponent as EditIcon } from 'assets/Edit.svg';
import { ReactComponent as EndStateReport } from 'assets/EndStateReport.svg';
import { ReactComponent as EstateIcon } from 'assets/Estate.svg';
import { ReactComponent as EyeShowIcon } from 'assets/EyeShow.svg';
import { ReactComponent as FileIcon } from 'assets/File.svg';
import { ReactComponent as FilterIcon } from 'assets/Filter.svg';
import { ReactComponent as FireIcon } from 'assets/Fire.svg';
import { ReactComponent as FolderIcon } from 'assets/Folder.svg';
import { ReactComponent as HelpIcon } from 'assets/Help.svg';
import { ReactComponent as HourglassIcon } from 'assets/Hourglass.svg';
import { ReactComponent as InfoIcon } from 'assets/Info.svg';
import { ReactComponent as IntermediateStateReport } from 'assets/IntermediateStateReport.svg';
import { ReactComponent as KebabMenuIcon } from 'assets/Kebab.svg';
import { ReactComponent as LanguageIcon } from 'assets/Language.svg';
import { ReactComponent as Layers } from 'assets/Layers.svg';
import { ReactComponent as LegalPersonIcon } from 'assets/LegalPerson.svg';
import { ReactComponent as LightningIcon } from 'assets/Lightning.svg';
import { ReactComponent as LockIcon } from 'assets/Lock.svg';
import { ReactComponent as LoginIcon } from 'assets/Login.svg';
import { ReactComponent as LogoutIcon } from 'assets/Logout.svg';
import { ReactComponent as MailIcon } from 'assets/Mail.svg';
import { ReactComponent as MapTagIcon } from 'assets/MapTag.svg';
import { ReactComponent as MenuIcon } from 'assets/Menu.svg';
import { ReactComponent as MoveIcon } from 'assets/Move.svg';
import { ReactComponent as NaturalPersonIcon } from 'assets/NaturalPerson.svg';
import { ReactComponent as NotificationIcon } from 'assets/Notification.svg';
import { ReactComponent as OpenInNewIcon } from 'assets/OpenInNew.svg';
import { ReactComponent as PersonsIcon } from 'assets/Persons.svg';
import { ReactComponent as PhoneIcon } from 'assets/Phone.svg';
import { ReactComponent as PlayIcon } from 'assets/Play.svg';
import { ReactComponent as RegistrationIcon } from 'assets/Registration.svg';
import { ReactComponent as RemoveIcon } from 'assets/Remove.svg';
import { ReactComponent as RemoveFilterIcon } from 'assets/RemoveFilter.svg';
import { ReactComponent as RentalContract } from 'assets/RentalContract.svg';
import { ReactComponent as RentalDeposit } from 'assets/RentalDeposit.svg';
import { ReactComponent as ReportIcon } from 'assets/Report.svg';
import { ReactComponent as RestoreIcon } from 'assets/Restore.svg';
import { ReactComponent as RouterIcon } from 'assets/Router.svg';
import { ReactComponent as SaveIcon } from 'assets/Save.svg';
import { ReactComponent as SearchIcon } from 'assets/Search.svg';
import { ReactComponent as SettingsIcon } from 'assets/Settings.svg';
import { ReactComponent as ShopIcon } from 'assets/Shop.svg';
import { ReactComponent as SortIcon } from 'assets/Sort.svg';
import { ReactComponent as StateReport } from 'assets/StateReport.svg';
import { ReactComponent as SwitchIcon } from 'assets/Switch.svg';
import { ReactComponent as UploadIcon } from 'assets/Upload.svg';
import { ReactComponent as WarningIcon } from 'assets/Warning.svg';

const Icons = Object.fromEntries(
  Object.entries({
    BoxIcon,
    ShopIcon,
    BookIcon,
    EstateIcon,
    NaturalPersonIcon,
    LegalPersonIcon,
    DossierIcon,
    PersonsIcon,
    ErrorIcon,
    FileIcon,
    WarningIcon,
    SearchIcon,
    KebabMenuIcon,
    AddIcon,
    ClearIcon,
    DeleteIcon,
    RemoveIcon,
    SettingsIcon,
    DownloadIcon,
    OpenInNewIcon,
    CancelIcon,
    SaveIcon,
    MenuIcon,
    DashboardIcon,
    NotificationIcon,
    CheckIcon,
    CalendarIcon,
    LanguageIcon,
    BusinessesIcon,
    LogoutIcon,
    UploadIcon,
    FilterIcon,
    HelpIcon,
    RestoreIcon,
    FireIcon,
    EditIcon,
    MoveIcon,
    CourtIcon,
    RegistrationIcon,
    InfoIcon,
    ReportIcon,
    CodeIcon,
    PhoneIcon,
    DropIcon,
    AttachIcon,
    FolderIcon,
    RouterIcon,
    LightningIcon,
    HourglassIcon,
    EyeShowIcon,
    MapTagIcon,
    MailIcon,
    LockIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    SortIcon,
    Layers,
    PlayIcon,
    LoginIcon,
    RemoveFilterIcon,
    BlockIcon,
    SwitchIcon,
    SupportAgent,
    StateReport,
    IntermediateStateReport,
    RentalDeposit,
    EndStateReport,
    RentalContract,
  }).map(([key, value]) => [
    key,
    (props: SvgIconProps) => <SvgIcon component={value} {...props} />,
  ]),
);

export { Icons };
