import { MouseEventHandler, ReactNode } from 'react';
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  tooltipTitle: ReactNode;
  Icon: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const TooltipIconButton = ({
  tooltipTitle,
  Icon,
  onClick,
  disabled,
  size,
}: Props) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <IconButton disabled={disabled} onClick={onClick} size={size}>
        {Icon}
      </IconButton>
    </span>
  </Tooltip>
);
