import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { getOtherToDivide } from 'formData/dossier/products/rentalDeposit/utils';
import AccountNumberField from 'modules/AccountNumberField';
import { HandleCheckboxChange } from 'types/handleChange';
import {
  AbolishmentForm,
  DepositDistribution,
  DistributionPersonType,
} from 'api/dossier/products/rentalDeposit/types';
import {
  ControlledCheckbox,
  ControlledEmailInput,
  ControlledNumberInput,
} from 'components/inputs';

interface Props {
  type: DistributionPersonType;
  depositDistribution?: DepositDistribution;
  noEmail?: boolean;
}

const DepositDistributionFields = ({
  type,
  depositDistribution,
  noEmail = false,
}: Props) => {
  const {
    control,
    clearErrors,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext<AbolishmentForm>();

  const { t } = useTranslation('formFields');

  const emailConsent = useWatch({ control, name: 'emailConsent' });

  const onCheckboxChange: HandleCheckboxChange = (_e, checked) => {
    if (checked) {
      clearErrors(`${type}.accountNumber`);
    } else if (errors[type]?.email && !getValues(`${type}.email`)) {
      clearErrors(`${type}.email`);
    }
  };

  const handleAmountChange = () => {
    if (type !== 'tenant') {
      const fieldToTrigger = getOtherToDivide(type);
      if (errors?.[fieldToTrigger]?.amount) {
        trigger(`${getOtherToDivide(type)}.amount`);
      }
    }
  };

  return (
    <Grid container spacing={2} py={1}>
      {depositDistribution === 'depositDivided' && (
        <ControlledNumberInput
          xs={12}
          control={control}
          name={`${type}.amount`}
          error={errors[type]?.amount}
          label={t(type === 'tenant' ? 'balance' : 'amount')}
          disabled={type === 'tenant'}
          endAdornment="EUR"
          precision={2}
          onChange={handleAmountChange}
        />
      )}
      {depositDistribution !== 'depositUnknown' && (
        <AccountNumberField
          prefix={`${type}.accountNumber`}
          disabled={type === 'tenant' && !!emailConsent}
        />
      )}
      {type === 'tenant' && !noEmail && (
        <>
          <ControlledCheckbox
            control={control}
            onChange={onCheckboxChange}
            name="emailConsent"
            label={t('emailConsent')}
          />
          <ControlledEmailInput
            xs={12}
            control={control}
            name={`${type}.email`}
            error={errors[type]?.email}
            label={t(depositDistribution === 'depositUnknown' ? 'tenantEmail' : 'email')}
          />
        </>
      )}
    </Grid>
  );
};

export default DepositDistributionFields;
