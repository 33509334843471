import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';
import { FilePreview } from './FilePreview';
import { DocumentPreviewElementProps } from './types';

const PreviewModal = ({
  fileName,
  closePreview,
  ...props
}: DocumentPreviewElementProps) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper open onClose={closePreview} fullWidth maxWidth={'xl'}>
      <ModalTitle title={fileName} closeModal={closePreview} />
      <DialogContent
        sx={{
          pb: 0,
          height: 800,
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <FilePreview {...props} isModal />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={closePreview}>
          {t('close')}
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default PreviewModal;
