import { Box } from '@mui/material';
import PageTitle from 'components/PageTitle';
import AssignedDossiers from './AssignedDossiers';

const Dashboard = () => (
  <Box sx={({ mixins }) => mixins.tablePageWrapper}>
    <PageTitle page="dashboard" />
    <AssignedDossiers />
  </Box>
);

export default Dashboard;
