import yup from 'utils/yup';

const placeOfBirthSchema = yup
  .string()
  .label('placeOfBirth')
  .nullable()
  .default(null)
  .transform((value) => value || null);

export default placeOfBirthSchema;
