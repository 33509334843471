import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from '@mui/material';
import { BackOfficeContext } from 'contexts/backofficeContext';
import hasRole from 'utils/hasRole';
import NavItem from './NavItem';
import { lists } from './static-data';
import { impersonatingNavStyle } from './styles';

interface Props {
  isSidebarExpanded: boolean;
  selected: string;
  select: (item: string) => () => void;
}

const ImpersonatedBusinessNav = ({ isSidebarExpanded, selected, select }: Props) => {
  const { t } = useTranslation();

  const { impersonatedBusinessData } = useContext(BackOfficeContext);

  const isSuperAdmin = hasRole('SuperAdmin');
  const impersonatedNavItems = lists.mainMenu.filter(({ label }) =>
    isSuperAdmin ? label !== 'dashboard' : label === 'dossiers',
  );

  return impersonatedBusinessData ? (
    <List sx={impersonatingNavStyle}>
      {impersonatedNavItems.map(({ to, label, onClick, ...props }) => (
        <NavItem
          key={`${label}-${to}`}
          to={to}
          label={t(`aside.items.${label}`)}
          isSelected={selected === label}
          select={onClick || select(label)}
          isSidebarExpanded={isSidebarExpanded}
          resetSelection={select('')}
          {...props}
        />
      ))}
    </List>
  ) : null;
};

export default ImpersonatedBusinessNav;
