import { useTranslation } from 'react-i18next';
import { TableCell } from '@mui/material';
import useFormMethods from 'hooks/useFormMethods';
import { ControlledTextInput } from 'components/inputs';
import { DocumentNameCellProps, DocumentsTableForm } from './types';

const DocumentNameCell = ({
  documentName,
  isEditing,
  translationPrefix,
}: DocumentNameCellProps) => {
  const { registerController } = useFormMethods<DocumentsTableForm>();
  const { t } = useTranslation('documents', { keyPrefix: translationPrefix });
  return (
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {isEditing ? (
        <ControlledTextInput {...registerController('documentName')} />
      ) : translationPrefix ? (
        t(documentName, documentName)
      ) : (
        documentName
      )}
    </TableCell>
  );
};

export default DocumentNameCell;
