import { SubProductInterface } from 'api/dossier/products/types';

export const groupSubProducts = (subProducts: SubProductInterface[]) => {
  const groupedSubProducts: SubProductInterface[][] = [];
  subProducts
    .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
    .forEach((subProduct) => {
      const existingGroup = groupedSubProducts.find((group) =>
        group.some(({ name }) => name === subProduct.name),
      );

      if (existingGroup) {
        existingGroup.push(subProduct);
      } else {
        groupedSubProducts.push([subProduct]);
      }
    });

  return groupedSubProducts;
};
