import { useTableDebouncedFilter } from 'templates/Table/hooks';
import { TextInput } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableTextFilter = ({
  field,
  filterName = field,
  filterLabel,
}: TranslatedTableColumn) => {
  const { value, setValue, clearValue } = useTableDebouncedFilter(filterName);

  return (
    <TextInput
      placeholder={filterLabel}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      clearable
      onClear={clearValue}
    />
  );
};

export default TableTextFilter;
