import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  DossierEstateCard,
  RentalConditionsCard,
  ResidentsSection,
  ResponsibleUsersSection,
} from 'modules/DossierView';
import routes from 'router/routes';
import { AffiliatedPersonsCard, OwnReferenceCard } from 'templates/PageCard';
import { DossierOutletContext } from '../types';

const DossierDetailsTab = () => {
  const {
    id,
    residents,
    rentalConditions,
    responsibleUsers,
    landlords,
    tenants,
    estate,
    ownReference,
  } = useOutletContext<DossierOutletContext>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.rentalDossierTab(id).products} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DossierEstateCard estate={estate} />
      </Grid>
      {ownReference && (
        <Grid item xs={12}>
          <OwnReferenceCard ownReference={ownReference} />
        </Grid>
      )}
      {!!landlords.length && (
        <Grid item xs={12} md={tenants.length ? 6 : 12} marginBottom="auto">
          <AffiliatedPersonsCard title="landlords" persons={landlords} />
        </Grid>
      )}
      {!!tenants.length && (
        <Grid item xs={12} md={landlords.length ? 6 : 12} marginBottom="auto">
          <AffiliatedPersonsCard title="tenants" persons={tenants} />
        </Grid>
      )}
      {rentalConditions && (
        <Grid item xs={12}>
          <RentalConditionsCard {...rentalConditions} />
        </Grid>
      )}
      {residents && (
        <Grid item xs={12}>
          <ResidentsSection {...residents} />
        </Grid>
      )}
      {!!responsibleUsers?.length && (
        <Grid item xs={12}>
          <ResponsibleUsersSection responsibleUsers={responsibleUsers} />
        </Grid>
      )}
    </Grid>
  );
};

export default DossierDetailsTab;
