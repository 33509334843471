import { ChangeEvent } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import InputWrapper from 'components/inputs/InputWrapper';
import { ControlledCheckboxProps } from './types';

const ControlledCheckbox: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledCheckboxProps<T, N>,
) => React.ReactElement = ({
  control,
  name,
  label,
  labelPlacement,
  onChange: handleChange,
  sx,
  ...checkboxProps
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value, onChange, ...field } }) => (
      <FormControlLabel
        sx={sx}
        label={label}
        onChange={(e, checked) => {
          onChange(e);
          if (handleChange) {
            handleChange(e as ChangeEvent<HTMLInputElement>, checked);
          }
        }}
        labelPlacement={labelPlacement}
        control={<Checkbox checked={value ?? false} {...checkboxProps} {...field} />}
        data-testid={label}
      />
    )}
  />
);

const CheckboxWrapper = <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledCheckboxProps<T, N>,
) => InputWrapper(ControlledCheckbox, props);

export default CheckboxWrapper;
