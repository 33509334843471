import Compressor from 'compressorjs';
import { DataUrl } from 'types/data';

const MAX_SIZE = 500000;
const MAX_PNG_SIZE = MAX_SIZE;

export const compressToDataUrl = (
  file: File,
  onSuccess: (dataUrl: DataUrl) => void,
  quality = 0.8,
) => {
  new Compressor(file, {
    strict: true,
    convertSize: MAX_PNG_SIZE,
    quality,
    success(result) {
      if (result.size <= MAX_SIZE) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          onSuccess(event.target?.result || null);
        };
        reader.readAsDataURL(result);
      } else {
        compressToDataUrl(file, onSuccess, quality - 0.1);
      }
    },
  });
};
