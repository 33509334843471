import { useRouteError } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { ErrorIcon } from 'icons';

export const ErrorElement = ({ error }: { error?: string }) => {
  const routeError = useRouteError();

  return (
    <Box sx={({ mixins }) => mixins.centered} data-testid="errorContainer">
      <Stack alignItems="center" fontSize="medium">
        <Box fontSize={40}>
          <ErrorIcon color="error" fontSize="inherit" />
        </Box>
        {String(error ?? routeError)}
      </Stack>
    </Box>
  );
};
