import { useFormContext } from 'react-hook-form';
import { AddendumSteps } from 'modules/ProductRentalContract/RegisterAddendum/static-data';
import { UseRegisterAddendumStepInterface } from 'modules/ProductRentalContract/RegisterAddendum/types';
import { StepInterface } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { RentalConditionsStep } from './RentalConditionsStep';

export const useRentalConditionsStep = ({
  onClose,
  setStepErrors,
}: UseRegisterAddendumStepInterface): StepInterface => {
  const { trigger, formState } = useFormContext<RegisterAddendumForm>();

  const navigate = (changeStep: () => void) => async () => {
    const isValid = await trigger('rentalConditions', { shouldFocus: true });
    setStepErrors((prev) => ({ ...prev, [AddendumSteps.rentalConditions]: !isValid }));

    if (isValid) {
      changeStep();
    }
  };

  useUpdateEffect(() => {
    const isValid = !Object.keys(formState.errors.rentalConditions ?? {}).length;
    setStepErrors((prev) => ({ ...prev, [AddendumSteps.rentalConditions]: !isValid }));
  }, [Object.keys(formState.errors.rentalConditions ?? {}).length]);

  return {
    Component: <RentalConditionsStep />,
    cancelButton: {
      onClick: onClose,
    },
    backButton: { disable: true },
    nextButton: { changeStepManually: true, onClick: navigate },
    labelButton: { changeStepManually: true, onClick: navigate },
  };
};
