import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { HelpIcon } from 'icons';
import { StepWizardProps } from './types';

interface Props extends Pick<StepWizardProps, 'translationKey'> {
  step: number;
}

const NotAllowedLabel = ({ translationKey, step }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'stepWizard' });

  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Typography variant="caption">{t('notAllowedLabel')}</Typography>
      <IconTooltip
        title={t(`${translationKey}.notAllowedTooltips.${step}`, '')}
        sx={{ pointerEvents: 'auto' }}
      >
        <HelpIcon sx={{ fontSize: 16 }} color="primary" />
      </IconTooltip>
    </Box>
  );
};

export default NotAllowedLabel;
