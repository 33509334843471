import { getPostCodesWithCity } from 'api/config/requests';

export const getRegion = async (postCode: string, city: string) => {
  const { ok, response } = await getPostCodesWithCity({
    postCode,
    city,
    lng: 'en',
  });
  if (ok && response?.[0]) {
    return response[0].region;
  }
};
