import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import LegalPersonDetailsCard from 'modules/PersonView/LegalPersonDetailsCard';
import routes from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { LegalPersonViewInterface } from 'api/person/legal/types';

const LegalPersonDetailsTab = () => {
  const legalPerson = useOutletContext<LegalPersonViewInterface>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.legalPersonTab(legalPerson.id).dossiers} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LegalPersonDetailsCard {...legalPerson} />
      </Grid>
      <Grid item xs={12}>
        <AffiliatedPersonsCard
          persons={legalPerson.representedBy}
          title="representedBy"
        />
      </Grid>
    </Grid>
  );
};

export default LegalPersonDetailsTab;
