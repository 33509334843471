import { DEFAULT_PARAMS } from 'hooks/useAddressFields/static-data';
import { createCountryOption } from 'hooks/useAddressFields/utils';
import useCountryOptions from 'hooks/useCountryOptions';
import useFormMethods from 'hooks/useFormMethods';
import { getInputLabel } from 'utils/missingFields';
import { AddressFormInterface } from 'api/common/types';
import { ControlledSelect } from 'components/inputs';
import { SelectInputProps } from 'components/inputs/Select/types';
import { CountryFieldProps } from './types';

const CountryField = ({
  prefix,
  isMissingFieldsModal,
  disableCountrySelection,
  onCountryChange,
  updatePostCodeAndCityParams,
}: CountryFieldProps) => {
  const { registerController, setValue } = useFormMethods<{
    address: AddressFormInterface;
  }>(prefix);

  const [countriesOptions, groupedCountriesOptions] =
    useCountryOptions(createCountryOption);

  const handleCountryChange: SelectInputProps['onChange'] = (value) => {
    const { country } = countriesOptions.find((country) => country.value === value) ?? {};
    onCountryChange?.(country);
    setValue(`address.postCode`, '', { shouldDirty: true });
    setValue(`address.city`, '', { shouldDirty: true });
    updatePostCodeAndCityParams(DEFAULT_PARAMS);
  };

  return (
    <ControlledSelect
      xs={12}
      md={6}
      {...registerController('address.country')}
      label={getInputLabel('address.country', isMissingFieldsModal)}
      labelVariant={isMissingFieldsModal ? 'separate' : 'float'}
      options={countriesOptions}
      groupedOptions={groupedCountriesOptions}
      onChange={handleCountryChange}
      disabled={disableCountrySelection}
    />
  );
};

export default CountryField;
