import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton } from '@mui/material';
import Button from 'atoms/Button';
import { getCompositionFields } from 'formData/estate/details/fields';
import useEstateCompositionCustomFields from 'hooks/useEstateCompositionCustomFields';
import useFormMethods from 'hooks/useFormMethods';
import { AddIcon, DeleteIcon, EditIcon } from 'icons';
import { EstateFormInterface } from 'modules/EstateForm/types';
import getLng from 'utils/getLng';
import { ControlledNumberInput } from 'components/inputs';
import EditNewCompositionField from './CustomCompositionFieldModal';
import EstateCompositionTemplateSelector from './EstateCompositionTemplateSelector';

const CompositionStep = () => {
  const { t } = useTranslation(['enums', 'common']);

  const { registerController, getValues } = useFormMethods<EstateFormInterface>();

  const estateDestination = getValues('estate.destination');

  const {
    customFields,
    addNewCustomField,
    editCustomField,
    removeCustomField,
    isCustomFieldModalOpen,
    customFieldModalProps,
  } = useEstateCompositionCustomFields(estateDestination);

  const compositionFields = getCompositionFields(estateDestination);

  return (
    <Grid container spacing={2}>
      <EstateCompositionTemplateSelector
        isAvailable={estateDestination !== 'NonResidential'}
      />
      <Grid container item xs={12} spacing={2}>
        {compositionFields.map((name) => (
          <Grid item key={name} xs={12} sm={6} md={4}>
            <ControlledNumberInput
              isInteger
              placeholder="#"
              label={t(`estateComposition.${name}`)}
              {...registerController(`estateDetails.estateComposition.${name}`)}
            />
          </Grid>
        ))}
        {customFields.map(({ id, name }, index) => (
          <Grid item key={id} xs={12} sm={6} md={4}>
            <ControlledNumberInput
              isInteger
              placeholder="#"
              label={name[getLng()]}
              {...registerController(
                `estateDetails.estateComposition.additionalItems.${index}.count`,
              )}
              endAdornment={
                <Box display="flex" mr={-1}>
                  <IconButton size="small" onClick={() => editCustomField(index)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => removeCustomField(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
            />
          </Grid>
        ))}
        <Grid item>
          <Button role="secondary" onClick={addNewCustomField} startIcon={<AddIcon />}>
            {t('common:addNew.compositionField')}
          </Button>
        </Grid>
      </Grid>
      {isCustomFieldModalOpen && <EditNewCompositionField {...customFieldModalProps} />}
    </Grid>
  );
};

export default CompositionStep;
