import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import {
  AbolishmentReason,
  DepositDistribution,
} from 'api/dossier/products/rentalDeposit/types';
import DepositDistributionFields from './DepositDistributionFields';

interface Props {
  depositDistribution: DepositDistribution;
  abolishmentReason: AbolishmentReason | null;
  noEmail?: boolean;
}

const DepositDistributionFieldsWrapper = ({
  abolishmentReason,
  depositDistribution,
  noEmail = false,
}: Props) => {
  const { t } = useTranslation('modals', { keyPrefix: 'prepareReleaseModal' });

  switch (depositDistribution) {
    case 'depositLandlord':
      return (
        <DepositDistributionFields
          type="landlord"
          depositDistribution={depositDistribution}
          noEmail={noEmail}
        />
      );
    case 'depositTenant':
      return (
        <DepositDistributionFields
          type="tenant"
          depositDistribution={depositDistribution}
          noEmail={noEmail}
        />
      );
    case 'depositDivided':
      return (
        <Stack gap={2} mt={2}>
          <Stack>
            <Typography mb={1} fontWeight="bold">
              {t('sections.depositDistribution.persons.landlord')}
            </Typography>
            <DepositDistributionFields
              type="landlord"
              depositDistribution={depositDistribution}
              noEmail={noEmail}
            />
          </Stack>
          {abolishmentReason !== 'verdict' && (
            <Stack>
              <Typography mb={1} fontWeight="bold">
                {t('sections.depositDistribution.persons.broker')}
              </Typography>
              <DepositDistributionFields
                type="broker"
                depositDistribution={depositDistribution}
                noEmail={noEmail}
              />
            </Stack>
          )}
          <Stack>
            <Typography fontWeight="bold" mb={1}>
              {t('sections.depositDistribution.persons.tenant')}
            </Typography>
            <DepositDistributionFields
              type="tenant"
              depositDistribution={depositDistribution}
              noEmail={noEmail}
            />
          </Stack>
        </Stack>
      );
    default:
      return (
        <DepositDistributionFields
          type="tenant"
          depositDistribution={depositDistribution}
        />
      );
  }
};

export default DepositDistributionFieldsWrapper;
