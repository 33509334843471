import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import orderMyMoveSchema from 'formData/dossier/products/myMove/schema';
import ProductParties from 'modules/ProductParties';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductActionEstateInfo,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { myMoveTenantsConfig } from './static-data';
import { OrderMyMoveForm } from './types';
import { transformOrderMyMoveData } from './utils';

const OrderMyMove = ({
  sendRequest,
  dossier: { estate, tenants },
}: ProductActionComponentProps) => {
  const methods = useForm<OrderMyMoveForm>({
    defaultValues: orderMyMoveSchema.getDefault(),
    resolver: resolver(orderMyMoveSchema),
  });

  const onSubmit: SubmitHandler<OrderMyMoveForm> = (data) => {
    sendRequest(transformOrderMyMoveData(data));
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container gap={4} mt={1}>
        <ProductActionEstateInfo estate={estate} />
        <FormProvider {...methods}>
          <ProductParties
            persons={tenants}
            partyType="tenants"
            config={myMoveTenantsConfig}
          />
        </FormProvider>
      </Grid>
    </ProductActionContent>
  );
};

export default OrderMyMove;
