import { createContext, ReactElement } from 'react';
import useLoginUser from 'hooks/useLoginUser';

export const AccountContext = createContext<ReturnType<typeof useLoginUser>>({
  username: '',
  userId: null,
  businessData: null,
  loading: false,
  error: false,
});

export const AccountProvider = ({ children }: { children: ReactElement }) => {
  const loggedUserData = useLoginUser();

  return (
    <AccountContext.Provider value={loggedUserData}>{children}</AccountContext.Provider>
  );
};
