import { Box, Card, Stack, Typography } from '@mui/material';
import { PhoneIcon } from 'icons';
import { DossierViewInterface } from 'api/dossier/types';

const ResponsibleUsersSection = ({
  responsibleUsers,
}: Pick<DossierViewInterface, 'responsibleUsers'>) =>
  responsibleUsers?.length ? (
    <Stack spacing={1}>
      {responsibleUsers.map(({ id, name, email }) => (
        <Card key={id} variant="outlined" sx={({ mixins }) => mixins.cardContent}>
          <Box display="flex" alignItems="center" gap={1.5}>
            <PhoneIcon />
            <Typography fontWeight={600}>{name}</Typography>
          </Box>
          <Typography
            component="a"
            href={`mailTo:${email}`}
            variant="caption"
            sx={({ mixins }) => ({ ...mixins.link, color: 'grey.600' })}
          >
            {email}
          </Typography>
        </Card>
      ))}
    </Stack>
  ) : null;

export default ResponsibleUsersSection;
