import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import PaymentInput from './PaymentInput';
import { ControlledPaymentInputProps } from './types';

const ControlledPaymentInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledPaymentInputProps<T, N>,
) => React.ReactElement = ({
  name,
  error,
  warning,
  helperText,
  control,
  currencyFieldName = 'currency',
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, value: payment, ...field } }) => (
      <PaymentInput
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        value={payment?.value ?? ''}
        currency={payment?.[currencyFieldName]}
        onValueChange={(e) =>
          onChange({ ...payment, value: e.target.value ? Number(e.target.value) : null })
        }
        onCurrencyChange={(value) => onChange({ ...payment, [currencyFieldName]: value })}
        helperText={
          getErrorMessage((error && 'value' in error ? error.value : error) ?? warning) ||
          helperText
        }
        {...field}
        {...props}
      />
    )}
  />
);

export default ControlledPaymentInput;
