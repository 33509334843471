import { useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { TableSearchParams } from '../types';
import { stringToSortModel } from './utils';

const getSortModelData = (model: GridSortModel) => {
  // Currently we are handling sorting by one field
  const { field, sort: direction } = model.at(0) ?? {};
  return { field, direction };
};

const useTableSort = (
  searchParams: TableSearchParams,
  updateRows: (params?: object) => Promise<void>,
) => {
  const [sortModel, setSortModel] = useState(stringToSortModel(searchParams.sort));

  const debouncedSortModel = useDebounce(sortModel, 500);

  useUpdateEffect(() => {
    const { field, direction } = getSortModelData(debouncedSortModel);
    const sort = field && direction ? `${field}:${direction}` : null;
    updateRows({ sort });
  }, [debouncedSortModel[0]?.sort, debouncedSortModel[0]?.field]);

  const onSortChange = (model: GridSortModel) => {
    const { field, direction } = getSortModelData(model);
    setSortModel(field ? [{ field, sort: direction }] : []);
  };

  return { sortModel, onSortChange };
};

export default useTableSort;
