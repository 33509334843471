import { StringSchema } from 'yup';

export function invalid(this: StringSchema, key?: string) {
  return this.test('invalid', (_value, { createError }) =>
    key
      ? createError({
          message: key,
        })
      : createError({
          message: ({ path, label = path }) => ({
            key: 'invalid',
            values: { field: label },
          }),
        }),
  );
}
