export default {
  legalPersons: 'legalPersons',
  legalPerson: (legalPersonId: string) => `legalPersons/${legalPersonId}`,
  legalPersonView: (legalPersonId: string) =>
    `legalPersons/${legalPersonId}/legal-person-view`,
  legalPersonDocuments: (legalPersonId: string) =>
    `legalPersons/${legalPersonId}/documents`,

  legalPersonDocument: (legalPersonId: string, documentId: number) =>
    `legalPersons/${legalPersonId}/documents/${documentId}`,
  legalPersonDocumentFile: (legalPersonId: string, documentId: number, fileId: number) =>
    `legalPersons/${legalPersonId}/documents/${documentId}/files/${fileId}`,
};
