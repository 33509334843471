import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { getFeatureFlags } from 'api/config/requests';
import { FeatureFlagName } from 'api/config/types';

interface ContextInterface {
  isFeatureActive: (flagName: FeatureFlagName) => boolean;
}

export const FeatureFlagsContext = createContext<ContextInterface>({
  isFeatureActive: () => false,
});

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const [featureFlags, setFeatureFlags] = useState<Record<FeatureFlagName, boolean>>({
    HistoryLogging: false,
    HowdyGo: false,
    Impersonate: false,
    ThirdPartySoftware: false,
  });

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const { ok, response } = await getFeatureFlags();
      if (ok) {
        setFeatureFlags(
          Object.fromEntries(
            response.featureFlags.map(({ name, isEnabled }) => [name, isEnabled]),
          ) as Record<FeatureFlagName, boolean>,
        );
      }
    };
    fetchFeatureFlags();
  }, []);

  const isFeatureActive = useCallback(
    (flagName: FeatureFlagName) => !!featureFlags[flagName],
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={{ isFeatureActive }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
