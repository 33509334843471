import { UrlParams } from 'api/types';
import { getSearchParamsUrl } from 'api/utils';

export default {
  //NOTIFICATIONS
  notificationsSummary: '/notifications/notificationsSummary',
  notifications: (params: UrlParams) =>
    getSearchParamsUrl('notifications', { ...params }),
  notificationsClear: '/notifications/clear',
  notificationsClearAll: '/notifications/clearAll',
};
