import {
  AbolishmentReason,
  DepositDistribution,
} from 'api/dossier/products/rentalDeposit/types';

export const ABOLISHMENT_REASONS: AbolishmentReason[] = [
  'identicalParties',
  'oneOfTenantsDied',
  'propertySold',
  'allTenantsDied',
  'onlyTenantHasDied',
  'landlordDied',
  'landlordRepresentativeChanged',
  'tenantRepresentativeChanged',
  'verdict',
];

export const DEPOSIT_DISTRIBUTIONS: DepositDistribution[] = [
  'depositTenant',
  'depositLandlord',
  'depositDivided',
  'depositUnknown',
];
