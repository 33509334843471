import i18n from 'i18n';
import { BusinessDetails } from 'api/businesses/types';

export const transformBusinessData = ({
  name,
  email,
  phone,
  vat: _vat,
  biv: _biv,
  ...address
}: BusinessDetails) => {
  const city =
    address.zipCode && address.city
      ? `${address.zipCode} ${address.city}`
      : address.city ?? null;

  const houseNumber = getHouseNumber(address.houseNumber, address.houseNumberExtension);
  const mailbox = address.mailbox ? `${i18n.t('addressBox')} ${address.mailbox}` : '';
  const street = address.street
    ? `${address.street} ${houseNumber} ${mailbox}`.trim()
    : null;

  return { name, email, phone, street, city };
};

const getHouseNumber = (houseNumber: unknown, extension: unknown) => {
  if (!houseNumber) {
    return '';
  }
  if (extension && !isNaN(Number(extension))) {
    return `${houseNumber}/${extension}`; // when extension is number
  }
  return `${houseNumber}${extension ?? ''}`.trim();
};
