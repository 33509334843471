import { isKeyInObject } from 'utils/object';
import { DocumentsConfig, DocumentScope, DocumentType } from 'api/documents/types';

export const getDocumentConfigData = (
  documentsConfig: DocumentsConfig,
  scope: DocumentScope | undefined,
  documentType: DocumentType | null,
) => {
  if (scope && documentType && isKeyInObject(documentType, documentsConfig[scope])) {
    return documentsConfig[scope][documentType];
  }
};
