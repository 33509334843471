import { TableCell } from '@mui/material';
import { formatDate } from 'utils/date';
import { DocumentData } from 'api/documents/types';
import { DocumentCellsProps, DocumentsContextInterface } from '../types';
import DocumentDateCell from './DocumentDateCell';
import DocumentNameCell from './DocumentNameCell';
import { getDocumentTypeValue } from './utils';

const DocumentCells = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  document,
  context,
  isEditing,
}: DocumentCellsProps<TData, TContext>) => (
  <>
    <DocumentNameCell documentName={document.documentName} isEditing={isEditing} />
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {getDocumentTypeValue(document, context.scope)}
    </TableCell>
    <DocumentDateCell
      document={document}
      documentConfigData={context.documentConfigData}
      isEditing={isEditing}
    />
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {formatDate(document.createdAt, 'dd/MM/yyyy HH:mm')}
    </TableCell>
  </>
);

export default DocumentCells;
