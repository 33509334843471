import i18n from 'i18n';
import { ProductMissingField } from 'api/dossier/products/types';
import getPersonLabel from './getPersonLabel';

export const getMissingFieldsLabel = ({ name, person }: ProductMissingField) => {
  const label = i18n.t(`enums:missingFieldName.${name}`, name);

  return person ? getPersonLabel(person) : label;
};

export default getMissingFieldsLabel;
