import getDocumentConfigData from 'utils/getDocumentConfigData';
import yup from 'utils/yup';
import {
  DocumentConfigData,
  DocumentsConfig,
  DocumentScope,
  DocumentType,
} from 'api/documents/types';

export const uploadDocumentsSchema = yup.object({
  documents: yup
    .array()
    .of(
      yup.object({
        documentName: yup
          .string()
          .label('documentName')
          .when('files', {
            is: (files: File[]) => !!files.length,
            then: (schema) => schema.required(),
          }),
        otherCustomType: yup
          .string()
          .label('otherCustomType')
          .when('documentType', {
            is: (documentType: DocumentType) => documentType === 'Other',
            then: (schema) => schema.required(),
          }),
        documentType: yup
          .string<DocumentType>()
          .label('documentType')
          .nullable()
          .defined()
          .when('files', {
            is: (files: File[]) => !!files.length,
            then: (schema) => schema.required(),
          }),
        date: yup
          .string()
          .label('documentDate')
          .nullable()
          .default(null)
          .when(['$documentConfigData', 'scope', 'documentType', 'files'], {
            is: (
              config: DocumentsConfig,
              scope: DocumentScope,
              type: DocumentType,
              files: File[],
            ) =>
              !!getDocumentConfigData(config, scope, type)?.date?.required &&
              files.length,
            then: (schema) => schema.required(),
          })
          .when(['$documentConfigData', 'scope', 'documentType'], {
            is: (config: DocumentsConfig, scope: DocumentScope, type: DocumentType) =>
              getDocumentConfigData(config, scope, type)?.date?.type === 'Past',
            then: (schema) => schema.customDate({ disableFuture: true }),
          })
          .when(['$documentConfigData', 'scope', 'documentType'], {
            is: (config: DocumentsConfig, scope: DocumentScope, type: DocumentType) =>
              getDocumentConfigData(config, scope, type)?.date?.type === 'Future',
            then: (schema) => schema.customDate({ disablePast: true }),
            otherwise: (schema) => schema.customDate(),
          }),
      }),
    )
    .default([]),
});

export const documentsSchema = yup.object({
  documentName: yup.string().required(),
  date: yup
    .string()
    .nullable()
    .default(null)
    .when('$documentConfigData', {
      is: (config: DocumentConfigData) => !!config?.date?.required,
      then: (schema) => schema.required(),
    })
    .when('$documentConfigData', {
      is: (config: DocumentConfigData) => config?.date?.type === 'Past',
      then: (schema) => schema.customDate({ disableFuture: true }),
    })
    .when('$documentConfigData', {
      is: (config: DocumentConfigData) => config?.date?.type === 'Future',
      then: (schema) => schema.customDate({ disablePast: true }),
      otherwise: (schema) => schema.customDate(),
    }),
});
