import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from 'atoms/Button';
import { compositionTemplateOptions } from 'formData/estate/details/selectOptions';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { mapObject } from 'utils/object';
import { Select } from 'components/inputs';
import { ESTATE_COMPOSITION_TEMPLATES } from './static-data';
import { EstateCompositionTemplate } from './types';

const EstateCompositionTemplateSelector = ({ isAvailable }: { isAvailable: boolean }) => {
  const [template, setTemplate] = useState<EstateCompositionTemplate | ''>('');

  const { setValue, getValues } = useFormMethods<EstateFormInterface>();
  const { t } = useTranslation(['common', 'formFields']);

  const applyTemplate = () => {
    if (template) {
      const fields = ESTATE_COMPOSITION_TEMPLATES[template];
      if (fields) {
        setValue(
          'estateDetails.estateComposition',
          {
            ...mapObject(getValues('estateDetails.estateComposition') ?? {}, () => null),
            ...fields,
            additionalItems: getValues('estateDetails.estateComposition.additionalItems'),
          },
          { shouldDirty: true },
        );
      }
    }
  };

  return (
    <Grid item container columnSpacing={2}>
      <Select
        xs={4}
        label={t('formFields:estateCompositionTemplates')}
        options={compositionTemplateOptions}
        value={template}
        onChange={(value) => setTemplate(value as EstateCompositionTemplate)}
        disabled={!isAvailable}
      />
      <Grid container item xs>
        <Button size="small" onClick={applyTemplate} disabled={!template}>
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EstateCompositionTemplateSelector;
