import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

const BoldCell = ({ row, field, formattedValue }: GridRenderCellParams) => (
  <Typography
    variant="h6"
    color="primary"
    fontWeight={600}
    sx={{ wordBreak: 'break-all' }}
  >
    {formattedValue || row[field]}
  </Typography>
);

export default BoldCell;
