import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import StorageKeys from 'consts/storage';
import { LogoutIcon } from 'icons';
import Cookies from 'js-cookie';

const LogoutItem = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const logout = () => {
    localStorage.clear();
    Cookies.remove(StorageKeys.ImpersonatedBusinessId);
    instance.logoutRedirect().catch((err) => console.error(err));
  };

  return (
    <MenuItem onClick={logout}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('logout')}</ListItemText>
    </MenuItem>
  );
};

export default LogoutItem;
