import { Box } from '@mui/material';
import { UserPermissions } from 'consts/permissions';
import { TranslatedOption } from 'formData/types';
import useGetUserRoles from 'hooks/useGetUserRoles';
import routes from 'router/routes';
import Table from 'templates/Table';
import hasPermission from 'utils/hasPermission';
import { getUsers } from 'api/users/requests';
import AddNewButton from 'components/AddNewButton';
import PageTitle from 'components/PageTitle';
import { getLinkToUser, usersColumns } from './static-data';

export const UsersList = () => {
  const userRoles = useGetUserRoles();

  const addable = hasPermission(UserPermissions.Write);

  const columns = usersColumns(userRoles as TranslatedOption[]);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle page="users" />
        {addable && <AddNewButton translationKey="user" to={routes.newUser} />}
      </Box>
      <Table
        tKey="users"
        get={getUsers}
        withUrlSearchParams
        columns={columns}
        // after uncomment below, remove actions column config from static-data.ts
        // getActions={getUsersActions}
        rowRedirectionLink={getLinkToUser}
      />
    </Box>
  );
};
