import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { TestId } from 'consts';
import { ClearIcon } from 'icons';
import routes from 'router/routes';
import { formatDate } from 'utils/date';
import { clearNotifications } from 'api/notifications/requests';
import { SingleNotificationContentProps } from './types';

const SingleNotificationContent = ({
  notification,
  fetchNotificationsSummary,
  closeNotifications,
}: SingleNotificationContentProps) => {
  const { dossierId, title, id, timestamp, productId } = notification;

  const { t } = useTranslation('notifications');
  const navigate = useNavigate();
  const handleClear = async () => {
    const { ok } = await clearNotifications({ ids: [id] });
    if (ok) {
      await fetchNotificationsSummary();
    }
  };

  const handleClick = async () => {
    await handleClear();
    closeNotifications();
    navigate(routes.rentalDossierProduct(dossierId, productId));
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      }
      disablePadding
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText>
          <Typography
            fontWeight="bold"
            sx={{ wordBreak: 'break-word' }}
            data-testid={TestId.NotificationTitle}
          >
            {t(notification.type, {
              notification,
              tenants: notification.tenants.map(({ name }) => name).join(', '),
              landlords: notification.landlords.map(({ name }) => name).join(', '),
              context: 'popup',
              defaultValue: title,
            })}
          </Typography>
          <Typography
            variant="body2"
            fontStyle="italic"
            data-testid={TestId.NotificationSubtitle}
          >
            {notification.estate.address}
          </Typography>
          <Typography
            variant="caption"
            fontStyle="italic"
            data-testid={TestId.NotificationDate}
          >
            {formatDate(timestamp)}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default SingleNotificationContent;
