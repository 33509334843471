import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { CheckIcon } from 'icons';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';

const EstateGasCard = ({ gas }: Pick<EstateViewInterface['data'], 'gas'>) => {
  const { t } = useTranslation(['formFields', 'common']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('formFields:gas.self')} value={<CheckIcon />} />
      {gas?.eanNumber && (
        <CardRow title={t('formFields:gas.eanNumber')} value={gas.eanNumber} />
      )}
      {gas?.meterNumber && (
        <CardRow title={t('formFields:gas.meterNumber')} value={gas.meterNumber} />
      )}
    </Card>
  );
};

export default EstateGasCard;
