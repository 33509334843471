import { addressSchema, bivSchema } from 'formData/common/schema';
import { rentalConditionsSchema } from 'formData/dossier/rentalConditions';
import vatSchema from 'formData/legalPerson/vat/schema';
import { naturalPersonSchema } from 'formData/naturalPerson';
import dateOfBirthSchema from 'formData/naturalPerson/dateOfBirth/schema';
import nationalRegisterNumberSchema from 'formData/naturalPerson/nationalRegisterNumber/schema';
import { TKey } from 'i18next';
import yup from 'utils/yup';
import { productPartyListSchema } from '../schema';

export const commonRentalContractSchema = yup.object({
  biv: bivSchema.requiredForNullable(),
  language: yup.string<TKey<'enums', 'documentLanguage'>>().required(),
});

export const registerRentalContractSchema = commonRentalContractSchema.concat(
  yup.object({
    landlords: productPartyListSchema,
    tenants: productPartyListSchema,
    contractSigningDate: yup.string().customDate().required(),
  }),
);

export const registerStateReportSchema = commonRentalContractSchema.concat(
  yup.object({
    stateReportSigningDate: yup.string().customDate({ disableFuture: true }).required(),
  }),
);

const personContactSchema = yup.object({
  id: yup.string().defined(),
  type: yup.string<'LegalPerson' | 'NaturalPerson'>().defined(),
  name: yup.string().label('name').required(),
  address: addressSchema,
});

const naturalPersonContactSchema = naturalPersonSchema
  .pick(['firstName', 'language'])
  .concat(
    yup.object({
      dateOfBirth: dateOfBirthSchema,
      placeOfBirth: yup.string().label('placeOfBirth').nullable().default(null),
      nationalRegisterNumber: nationalRegisterNumberSchema,
    }),
  );

const legalPersonContactSchema = yup.object({
  representative: personContactSchema
    .concat(naturalPersonContactSchema)
    .optional()
    .default(undefined),
  vat: vatSchema,
});

export const addendumContactSchema = personContactSchema
  .concat(naturalPersonContactSchema.partial())
  .concat(legalPersonContactSchema.partial());

export const registerAddendumSchema = yup.object({
  rentalConditions: rentalConditionsSchema.pick([
    'rentalPrice',
    'charges',
    'entryDate',
    'duration',
    'durationNumberOfMonths',
    'endDate',
  ]),
  landlords: yup.array().of(addendumContactSchema).defined(),
  tenants: yup.array(addendumContactSchema).defined(),
  uploadedAddendum: commonRentalContractSchema.concat(
    yup.object({
      addendumSigningDate: yup
        .string()
        .label('addendumSigningDate')
        .customDate({ disableFuture: true })
        .required(),
      addendumFile: yup.mixed<File>().nullable().default(null).requiredForNullable(),
      documentName: yup
        .string()
        .label('addendumDocumentName')
        .when('addendumFile', {
          is: (value: File | null) => !!value,
          then: (schema) => schema.required(),
        }),
    }),
  ),
});
