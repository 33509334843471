import { Box, styled } from '@mui/material';
import { CalendarSelectedDayBoxProps } from './types';

const CalendarSelectedDayBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isDayInRange' && prop !== 'isFirst' && prop !== 'isLast',
})<CalendarSelectedDayBoxProps>(({ theme, isDayInRange, isFirst, isLast }) => ({
  ...(isDayInRange && {
    backgroundColor: theme.palette.other.lightBlue,
    borderRadius: isFirst ? '50% 0 0 50%' : isLast ? '0 50% 50% 0' : 0,
  }),
}));
export default CalendarSelectedDayBox;
