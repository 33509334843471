import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useLoader from 'hooks/useLoader';
import { LegalPersonForm } from 'modules/PersonForm';
import { LegalPersonFormDefaultValues } from 'modules/PersonForm/LegalPersonForm/types';
import routes from 'router/routes';
import FullPageCard from 'templates/FullPageCard';
import { getLegalPerson } from 'api/person/legal/requests';
import { fetchLegalPersonAffiliations, getLegalPersonDefaultValues } from './utils';

const LegalPersonEdition = () => {
  const [loadingAffiliations, setLoadingAffiliations] = useState(true);
  const [affiliations, setAffiliations] = useState<
    Omit<LegalPersonFormDefaultValues, 'legalPerson'>
  >({ ownerships: [], representatives: [] });

  const { legalPersonId } = useParams();
  if (!legalPersonId) {
    return <Navigate to={routes.persons} />;
  }

  const { data, Loader } = useLoader({
    id: legalPersonId,
    scope: 'legalPerson',
    fetch: getLegalPerson,
  });

  const getAffiliations = useCallback(async () => {
    const affiliations = await fetchLegalPersonAffiliations(legalPersonId);
    setAffiliations(affiliations);
    setLoadingAffiliations(false);
  }, [legalPersonId]);

  useEffect(() => {
    getAffiliations();
  }, [getAffiliations]);

  return (
    <Loader loadingAdditionalData={loadingAffiliations}>
      {data ? (
        <FullPageCard>
          <LegalPersonForm
            legalPersonId={legalPersonId}
            defaultValues={{
              legalPerson: getLegalPersonDefaultValues(data),
              ...affiliations,
            }}
            stepsToHide={['documents']}
          />
        </FullPageCard>
      ) : null}
    </Loader>
  );
};

export default LegalPersonEdition;
