import { conformToMask } from 'react-text-mask';
import { clearVatMask } from './clearVatMask';
import { getVatMask } from './getVatMask';

export const getVatNumberWithMask = (number: string, prefix: string) => {
  const mask = getVatMask(prefix, number);

  if (!number || !mask || mask.length === 0) {
    return clearVatMask(number);
  }

  return conformToMask(number, mask, { guide: false }).conformedValue;
};
