import { useMemo } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import SearchInput from '../SearchInput';
import { SelectInputProps } from '../Select/types';
import { GooglePlaceOption, GooglePlacesSearchProps } from './types';

const API_KEY = window._env_.REACT_APP_GOOGLE_API_KEY;

const GooglePlacesSearch = ({
  onChange,
  country,
  input,
  setInput,
  loading,
}: GooglePlacesSearchProps) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'selectInput' });

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: API_KEY ?? '',
    debounce: 500,
    options: {
      componentRestrictions: { country },
      fields: ['address_components'],
      types: ['address'],
    },
  });

  const handleChange = (option: GooglePlaceOption | null) => {
    if (option) {
      placesService.getDetails(
        {
          placeId: option.place.place_id,
          language: (i18n.language === 'en' ? 'nl' : i18n.language) ?? 'nl',
        },
        onChange,
      );
    }
  };

  const handleInputChange = (input: string) => {
    setInput(input);
    getPlacePredictions({ input, language: i18n.language ?? 'en' });
  };

  const noOptionsText: SelectInputProps['noOptionsText'] = useMemo(() => {
    if (!input) {
      return 'typeForAddress';
    } else if (country) {
      return 'estateGoogleSelect';
    }
    return 'googleSelect';
  }, [input.length]);

  return API_KEY ? (
    <SearchInput
      name="google-address-search-input"
      value={input}
      xs={12}
      options={placePredictions.map((place) => ({
        value: place.description,
        label: place.description,
        place,
      }))}
      loading={isPlacePredictionsLoading || loading}
      noOptionsText={noOptionsText}
      placeholder={t(`placeholder.${country ? 'estateGoogleSelect' : 'googleSelect'}`)}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  ) : null;
};

export default GooglePlacesSearch;
