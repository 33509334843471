import { isBefore, startOfDay } from 'date-fns';
import { DocumentConfigData } from 'api/documents/types';

export const isDocumentExpired = (
  documentConfigData: DocumentConfigData,
  documentDate: string | null | undefined,
) => {
  const dateType = documentConfigData?.date?.type;

  if (!documentDate || dateType !== 'Future') {
    return false;
  }

  const date = startOfDay(new Date(documentDate));
  const today = startOfDay(new Date());
  return isBefore(date, today);
};
