import { useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { productMissingDataSchema } from 'formData/dossier/products/productMissingData/schema';
import useGetRegion from 'hooks/useGetRegion';
import { WarningIcon } from 'icons';
import { useUpdateEffect } from 'usehooks-ts';
import { isTruthyValueInObject } from 'utils/object';
import { resolver } from 'utils/yup';
import { getMissingFields } from 'api/missingData/requests';
import { MissingFields } from 'api/missingData/types';
import { ProductActionContent } from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import PersonMissingDataForm from './forms/PersonMissingDataForm';
import RentalConditionsMissingForm from './forms/RentalConditionsMissingDataForm';
import { EstateMissingDataForm } from './forms';
import { MissingDataForm } from './types';
import usePartiesFields from './usePartiesFields';
import {
  getDirtyFields,
  getFormData,
  getIsDirty,
  handleUpdateEntities,
  isRentTypeMissing,
  transformFormToSchemaValues,
} from './utils';

export const ProductMissingData = ({
  sendRequest,
  setDisableSubmitButton,
  setLoading,
  onClose,
  dossier,
  product: { productId, productName, reportedErrors },
}: ProductActionComponentProps) => {
  const [missingData, setMissingData] = useState<MissingFields>();
  const [isMissingDataError, setIsMissingDataError] = useState(false);

  const { t } = useTranslation(['modals', 'errors']);
  const region = useGetRegion(
    dossier.estate.address.postCode,
    dossier.estate.address.city.en,
  );

  const fetchData = async (currentValues: MissingDataForm = {}) => {
    const { ok, response } = await getMissingFields(dossier.id, productId);
    if (ok) {
      const defaultValues = await getFormData(response, currentValues, dossier);
      setMissingData(response);
      return { defaultValues, missingFields: response };
    } else {
      setIsMissingDataError(true);
      return { defaultValues: {}, missingFields: undefined };
    }
  };

  const methods = useForm<MissingDataForm>({
    resolver: resolver(productMissingDataSchema),
    context: { region, productName },
    defaultValues: async () => {
      setLoading(true);
      setDisableSubmitButton(true);
      const { defaultValues } = await fetchData();
      setLoading(false);
      return defaultValues;
    },
  });

  const watchedForm = methods.watch();

  const isDirty = useMemo(() => {
    const data = transformFormToSchemaValues(watchedForm);
    const dirtyFields = getDirtyFields(
      missingData,
      data,
      methods.formState.defaultValues,
    );
    return getIsDirty(dirtyFields);
  }, [missingData, watchedForm, methods.formState.defaultValues]);

  useUpdateEffect(() => {
    setDisableSubmitButton(!isDirty);
  }, [isDirty]);

  const { tenantsFields, landlordsFields } = usePartiesFields(methods);

  const fetchMissingData = async () => {
    const { defaultValues, missingFields } = await fetchData(methods.getValues());

    methods.reset(defaultValues, {
      keepErrors: true,
      keepValues: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    });

    return missingFields;
  };

  const onSubmit: SubmitHandler<MissingDataForm> = (data) => {
    const dirtyFields = getDirtyFields(
      missingData,
      data,
      methods.formState.defaultValues,
    );
    const fetchMissingDataWithSpinner = async () => {
      setLoading(true);
      const missingFields = await fetchMissingData();
      if (missingFields && !isTruthyValueInObject(missingFields)) {
        onClose();
      }
      setLoading(false);
    };

    sendRequest(
      null,
      handleUpdateEntities(data, dossier, dirtyFields, methods.formState.errors),
      fetchMissingDataWithSpinner,
    );

    console.debug('ProductMissingData - onSubmit', {
      missingData,
      data,
      dirtyFields,
      defaultValues: methods.formState.defaultValues,
    });
  };

  const onError: SubmitErrorHandler<MissingDataForm> = (errors) => {
    const data = transformFormToSchemaValues(methods.getValues());
    const dirtyFields = getDirtyFields(
      missingData,
      data,
      methods.formState.defaultValues,
    );

    const refetchMissingData = async () => {
      await fetchMissingData();
    };

    sendRequest(
      null,
      handleUpdateEntities(data, dossier, dirtyFields, errors),
      refetchMissingData,
    );

    console.debug('ProductMissingData - onError', {
      missingData,
      data,
      dirtyFields,
      defaultValues: methods.formState.defaultValues,
      errors,
    });
  };

  const personErrors =
    reportedErrors?.filter(
      (error) =>
        error === 'PERSONS_EMAIL_DUPLICATE' ||
        error === 'AGENT_TENANT_EMAIL_DOMAIN_DUPLICATE' ||
        error === 'TENANT_AGENCY_EMAIL_DUPLICATE',
    ) || [];

  if (isMissingDataError) {
    return <Typography color="error">{t('data.getMissingDataError')}</Typography>;
  }

  return missingData ? (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit, onError)}>
      <FormProvider {...methods}>
        <Stack spacing={4}>
          {!!missingData.estate?.length && (
            <Stack spacing={2}>
              <Divider>
                <Typography variant="h5">{t('data.estate')}</Typography>
              </Divider>
              <EstateMissingDataForm
                id={dossier.estate.id}
                fields={missingData.estate}
                isRentTypeMissing={isRentTypeMissing(missingData.rentalConditions)}
              />
            </Stack>
          )}
          {!!missingData.rentalConditions?.length && (
            <Stack spacing={2}>
              <Divider>
                <Typography variant="h5">{t('data.rentalConditions')}</Typography>
              </Divider>
              <RentalConditionsMissingForm fields={missingData.rentalConditions} />
            </Stack>
          )}
          {!!missingData.tenants?.length && (
            <Stack spacing={2}>
              <Divider>
                <Typography variant="h5">{t('data.tenants')}</Typography>
              </Divider>
              {!!personErrors.length && (
                <Stack gap={1.5} p={1.5}>
                  {personErrors.map((error) => (
                    <Box key={error} display="flex" alignItems="center" gap={1}>
                      <WarningIcon color="error" />
                      <Typography>{t(`errors:codes.${error}`, error)}</Typography>
                    </Box>
                  ))}
                </Stack>
              )}
              <Stack spacing={3}>
                {tenantsFields.map((tenant) => (
                  <PersonMissingDataForm
                    key={tenant.id}
                    id={tenant.id}
                    index={tenant.index}
                    personType={tenant.personType}
                    fields={missingData.tenants}
                    methods={methods}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {!!missingData.landlords?.length && (
            <Stack spacing={2}>
              <Divider data-testid="landlords">
                <Typography variant="h5">{t('data.landlords')}</Typography>
              </Divider>
              {landlordsFields.map((landlord) => (
                <PersonMissingDataForm
                  key={landlord.id}
                  id={landlord.id}
                  index={landlord.index}
                  personType={landlord.personType}
                  fields={missingData.landlords}
                  methods={methods}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </FormProvider>
    </ProductActionContent>
  ) : null;
};
