import { EstateEpcTypeResponse } from 'api/config/types';
import { EstateTypeOption } from './types';

export const getEpcTypesOptions = (res: EstateEpcTypeResponse): EstateTypeOption[] =>
  res.map(({ type, primaryEnergyConsumption }) => ({
    value: type,
    label: type,
    translationKey: `estateEpcTypes.${type}`,
    primaryEnergyConsumption,
  }));
