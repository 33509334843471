import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Stack, Switch, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import { InfoIcon } from 'icons';
import {
  connectThirdPartySoftware,
  getThirdPartySoftware,
} from 'api/businesses/requests';
import { ThirdPartySoftwareInterface } from 'api/businesses/types';
import { EXTERNAL_SOFTWARE_LOGO } from './static-data';
import ThirdPartySoftwareData from './ThirdPartySoftwareData';

const ThirdPartySoftware = () => {
  const [fetching, setFetching] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [software, setSoftware] = useState<ThirdPartySoftwareInterface | null>(null);

  const { t } = useTranslation('common', { keyPrefix: 'pageCard.thirdPartySoftware' });

  const fetchSoftware = useCallback(async () => {
    const { ok, response } = await getThirdPartySoftware();
    if (ok) {
      setSoftware(response);
    }
    setFetching(false);
  }, []);

  useEffect(() => {
    fetchSoftware();
  }, [fetchSoftware]);

  const connectSoftware = async () => {
    if (software?.name) {
      setConnecting(true);
      const { ok, response: businessConnectReference } = await connectThirdPartySoftware(software.name);
      if (ok) {
        setSoftware({ businessConnectReference, name: software.name, hasConnection: true });
      }
      setConnecting(false);
    }
  };

  return fetching ? (
    <LoadingElement />
  ) : (
    <Card sx={{ width: { lg: '50%' } }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Stack>
          <Typography variant="h4">{t('title')}</Typography>
          <Typography variant="caption">{t('subTitle')}</Typography>
        </Stack>
        {software?.name ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Box
                component="img"
                src={EXTERNAL_SOFTWARE_LOGO[software.name]}
                alt={software.name}
                width={200}
                display="flex"
                alignItems="center"
              />
              <Switch
                color="secondary"
                onChange={connectSoftware}
                checked={software.hasConnection}
                disabled={connecting || software.hasConnection}
              />
            </Box>
            {software.businessConnectReference && <ThirdPartySoftwareData businessConnectReference={software.businessConnectReference} />}
          </>
        ) : (
          <Box display="flex" gap={1} alignItems="center">
            <InfoIcon color="primary" />
            <Typography>{t('noLicenseInfo')}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ThirdPartySoftware;
