import { TKey } from 'i18next';
import { ProductName } from 'api/dossier/products/types';
import { ProductNotification } from 'api/notifications/types';

export type NotificationTabs = TKey<'common', 'tabs.notifications'>;

export type NotificationsByType = Partial<
  Record<ProductName, { notifications: ProductNotification[]; count: number }>
>;

export type NotificationsWithCount = Record<NotificationTabs, NotificationsByType>;

export type NotificationInterface = {
  productName: ProductName;
  notifications?: ProductNotification[];
  count?: number;
};

export enum ActiveNotificationTab {
  actionRequired,
  noAction,
}
