import { useContext, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { MAX_OWNERS_COUNT } from 'modules/Relation/static-data';
import { RelationData } from 'modules/Relation/types';
import {
  fetchPersonRelationOptions,
  findPersonRelative,
  getPersonsIdsToFilter,
} from 'modules/Relation/utils';
import { firstCharUpperCase } from 'utils/string';
import { FetchedPersonData } from 'api/person/types';
import { getErrorMessage } from 'components/inputs/utils';

const DossierPartyInput = ({ partyType }: { partyType: 'landlord' | 'tenant' }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<DossierFormInterface>();

  const { i18n, t } = useTranslation();
  const context = useContext(DossierFormContext);

  const partyFields = useWatch({ control, name: `${partyType}s` });
  const oppositePartyFields = useWatch({
    control,
    name: partyType === 'landlord' ? 'tenants' : 'landlords',
  });

  const {
    fields: previewList,
    append,
    remove,
  } = useFieldArray({ control, name: `${partyType}s` });

  const fieldsToFilter = useMemo(
    () => getPersonsIdsToFilter([...partyFields, ...oppositePartyFields]),
    [partyFields.length, oppositePartyFields.length],
  );

  const onSelectOption = async (data: RelationData<FetchedPersonData>) => {
    const relative = await findPersonRelative(data);
    if (relative) {
      append([data, relative]);
    } else {
      append(data);
    }
  };

  const contextOptions = useMemo(
    () =>
      context[`assigned${firstCharUpperCase(partyType)}s`].map((option) => ({
        ...option,
        subHeader: t('searchGroup.contextResults'),
      })),
    [i18n.language],
  );

  return (
    <Box>
      <AddRelation
        translationKey={partyType}
        maxRelations={MAX_OWNERS_COUNT}
        relationsToFilter={fieldsToFilter}
        selectedRelations={partyFields.map(({ id }) => id)}
        fetchOptions={fetchPersonRelationOptions}
        AddNewOptionModal={AddPersonModal}
        errorMessage={getErrorMessage(errors[`${partyType}s`])}
        contextOptions={contextOptions}
        onSelectOption={onSelectOption}
        disabled={!context.editableFields.parties}
      />
      <RelationPreviewList
        showIcons
        relationsToPreview={previewList}
        onRemove={(_, index) => remove(index)}
        removable={context.editableFields.parties}
      />
    </Box>
  );
};

export default DossierPartyInput;
