import {
  add,
  format,
  isAfter,
  isBefore,
  isToday,
  isValid,
  startOfDay,
  sub,
} from 'date-fns';
import { CustomDateOptions } from 'types/yup';
import { StringSchema } from 'yup';
import { getDurationData } from './utils';

export function customDate(this: StringSchema, options?: CustomDateOptions) {
  return this.test('date-validation', (value, { createError, path, parent }) => {
    if (value) {
      const TODAY = startOfDay(new Date());
      const date = startOfDay(new Date(value));
      const values = { field: this.spec.label ?? path };

      if (!isValid(date)) {
        return createError({
          message: { key: 'invalid', values },
        });
      }
      if (options?.disableToday && isToday(date)) {
        return createError({
          message: { key: 'date.today', values },
        });
      }
      if (options?.disableFuture && isAfter(date, TODAY)) {
        return createError({
          message: { key: 'date.after', values },
        });
      }
      if (options?.disablePast && isBefore(date, TODAY)) {
        return createError({
          message: { key: 'date.before', values },
        });
      }
      if (options?.minPastRange && isAfter(date, sub(TODAY, options.minPastRange))) {
        const { key = 'date.minPastRange', ...duration } = options.minPastRange;
        const durationData = getDurationData(duration);

        return createError({
          message: { key, values: { ...durationData, ...duration, ...values } },
        });
      }
      if (options?.maxPastRange && isBefore(date, sub(TODAY, options.maxPastRange))) {
        const { key = 'date.maxPastRange', ...duration } = options.maxPastRange;
        const durationData = getDurationData(duration);

        return createError({
          message: { key, values: { ...durationData, ...duration, ...values } },
        });
      }
      if (options?.minFutureRange && isBefore(date, add(TODAY, options.minFutureRange))) {
        const { key = 'date.minFutureRange', ...duration } = options.minFutureRange;
        const durationData = getDurationData(duration);

        return createError({
          message: { key, values: { ...durationData, ...duration, ...values } },
        });
      }
      if (options?.maxFutureRange && isAfter(date, add(TODAY, options.maxFutureRange))) {
        const { key = 'date.maxFutureRange', ...duration } = options.maxFutureRange;
        const durationData = getDurationData(duration);

        return createError({
          message: { key, values: { ...durationData, ...duration, ...values } },
        });
      }
      if (options?.min) {
        const { date: dateToCompare, key } = options.min(parent);
        if (isBefore(date, startOfDay(dateToCompare))) {
          return createError({ message: { key, ...values } });
        }
      }
      if (options?.max) {
        const { date: dateToCompare, key } = options.max(parent);
        if (isAfter(date, startOfDay(dateToCompare))) {
          return createError({ message: { key, ...values } });
        }
      }
    }
    return true;
  }).transform((value: string | null) => {
    if (value && isValid(new Date(value))) {
      return format(new Date(value), 'yyyy-MM-dd');
    }
    return value;
  });
}
