import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DossierFormProvider } from 'contexts/dossierForm/DossierFormContext';
import { DossierEditableFieldsInterface } from 'contexts/dossierForm/types';
import { dossierFormSchema } from 'formData/dossier/schema';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard_V2';
import { filterSteps, getStepsIndex } from 'templates/StepWizard_V2/utils';
import { resolver } from 'utils/yup';
import { DOSSIER_STEPS } from './static-data';
import {
  DossierAssignedParameters,
  DossierFormInterface,
  DossierFormProps,
} from './types';
import useDossierSteps from './useDossierSteps';

const DossierFormStepper = ({ stepsToHide }: DossierFormProps) => {
  const navigate = useCustomNavigate();
  const onCancel = () => {
    navigate(-1);
  };

  const steps = useMemo(() => filterSteps(DOSSIER_STEPS, stepsToHide), []);
  const stepsProps = useDossierSteps({ stepsIndex: getStepsIndex(steps) });

  return <StepWizard tKey="dossier" steps={steps} onCancel={onCancel} {...stepsProps} />;
};

const DossierForm = ({
  dossierId,
  assignedParameters,
  defaultValues,
  editableFields,
  ...props
}: DossierFormProps & {
  dossierId?: string | null;
  defaultValues?: Partial<DossierFormInterface>;
  assignedParameters?: DossierAssignedParameters;
  editableFields?: DossierEditableFieldsInterface;
}) => {
  const [region, setRegion] = useState('');

  const methods = useForm<DossierFormInterface>({
    mode: dossierId ? 'onChange' : 'onSubmit',
    defaultValues: {
      ...dossierFormSchema.getDefault(),
      ...defaultValues,
      rentalConditions:
        defaultValues?.rentalConditions ||
        dossierFormSchema.getDefault().rentalConditions,
      residents: defaultValues?.residents || dossierFormSchema.getDefault().residents,
    },
    resolver: resolver(dossierFormSchema),
    context: { region },
  });

  useEffect(() => {
    if (dossierId) {
      methods.trigger();
    }
  }, []);

  usePrompt({ when: methods.formState.isDirty, id: 'dossierForm' });

  useEffect(() => {
    if (dossierId) {
      methods.trigger();
    }
  }, []);

  return (
    <DossierFormProvider
      dossierId={dossierId}
      editableFields={editableFields}
      setRegion={setRegion}
      {...assignedParameters}
    >
      <FormProvider {...methods}>
        <DossierFormStepper {...props} />
      </FormProvider>
    </DossierFormProvider>
  );
};

export default DossierForm;
