import { dossierStatuses } from 'formData/dossier/selectOptions';
import { DossierStatus } from 'api/dossier/types';

export const filterDossierStatuses = (currentStatus: DossierStatus) =>
  dossierStatuses.filter(({ value: status }) => {
    switch (currentStatus) {
      case 'Draft':
        return status === 'InTreatment' || status === 'Running' || status === 'Cancelled';
      case 'Running':
        return status === 'InTreatment' || status === 'Terminated';
      case 'Cancelled':
        return status === 'Draft';
      case 'InTreatment':
        return status === 'Running' || status === 'Terminated';
      case 'Terminated':
        return status === 'InTreatment' || status === 'Running';
      case 'Migrated':
        return status === 'Running';
    }
  });
