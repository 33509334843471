import { rentalConditionsSchema } from 'formData/dossier/rentalConditions';
import entryDateSchema, {
  entryDateOptions,
} from 'formData/dossier/rentalConditions/entryDate/schema';

export const rentalConditionsMissingDataSchema = rentalConditionsSchema.shape({
  entryDate: entryDateSchema.customDate({
    ...entryDateOptions,
    maxPastRange: { months: 6 },
  }),
});
