import getLng from 'utils/getLng';
import { EstateDetailsForm } from 'api/estate/types';

export const getCompositionList = ({
  additionalItems,
  ...items
}: NonNullable<EstateDetailsForm['estateComposition']>): {
  name: string;
  count: number;
  isCustom?: boolean;
}[] => [
  ...Object.entries(items)
    .map(([name, count]) => ({ name, count }))
    .filter((item): item is { name: string; count: number } => item.count !== null),
  ...(additionalItems ?? []).map(({ name, count }) => ({
    name: name[getLng()],
    count,
    isCustom: true,
  })),
];
