import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { BackOfficeContext } from 'contexts/backofficeContext';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import { CancelIcon, LoginIcon, SortIcon } from 'icons';
import hasRole from 'utils/hasRole';
import { runDataMigration } from 'api/businesses/requests';
import { FetchedBusinessListItem } from 'api/businesses/types';

const ImpersonateAction = ({ businessId, migrated }: FetchedBusinessListItem) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('table', { keyPrefix: 'businesses.tooltips' });

  const { fetchChecklist } = useContext(ConfigContext);
  const { impersonate, finishImpersonating, impersonateId } =
    useContext(BackOfficeContext);

  const startImpersonating = async () => {
    setLoading(true);
    await impersonate(businessId);
    await fetchChecklist(businessId);
    setLoading(false);
  };

  return impersonateId === businessId ? (
    <TooltipIconButton
      Icon={<CancelIcon />}
      tooltipTitle={t('finishImpersonating')}
      onClick={finishImpersonating}
    />
  ) : (
    <TooltipIconButton
      Icon={loading ? <CircularProgress size={20} /> : <LoginIcon />}
      tooltipTitle={loading ? '' : t('startImpersonating')}
      disabled={loading || !migrated}
      onClick={startImpersonating}
    />
  );
};

const DataMigrationAction = ({
  externalGuid,
  canMigrateData,
}: FetchedBusinessListItem) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('table');

  const onClick = async () => {
    setLoading(true);
    await runDataMigration(externalGuid);
    setLoading(false);
  };

  return (
    <TooltipIconButton
      Icon={
        loading ? (
          <CircularProgress size={20} />
        ) : (
          <SortIcon fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
        )
      }
      onClick={onClick}
      disabled={loading || !canMigrateData}
      tooltipTitle={t('businesses.tooltips.migrateData')}
    />
  );
};

const BusinessActions = ({
  row,
  colDef,
}: GridRenderCellParams<FetchedBusinessListItem>) => {
  const { isFeatureActive } = useContext(FeatureFlagsContext);

  return (
    <Box width="100%" display="flex" gap={0.5} justifyContent={colDef.align}>
      {isFeatureActive('Impersonate') && (hasRole('SuperAdmin') || hasRole('Admin')) && (
        <ImpersonateAction {...row} />
      )}
      <DataMigrationAction {...row} />
    </Box>
  );
};

export default BusinessActions;
