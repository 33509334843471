import { useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import { getSubProductsIndexes } from 'modules/ProductCard/SubProducts/utils';
import { ProductInterface } from 'api/dossier/products/types';
import SubProductContent from './SubProductContent';
import SubProductNavigator from './SubProductNavigator';

const SubProductsGroup = ({
  subProducts,
}: Required<Pick<ProductInterface, 'subProducts'>>) => {
  const { firstIndex, lastIndex } = getSubProductsIndexes(subProducts);
  const [activeIndex, setActiveIndex] = useState(lastIndex);

  const visibleSubProduct = useMemo(
    () => subProducts.at(activeIndex),
    [subProducts, activeIndex],
  );

  const onPrevious = () => {
    setActiveIndex((prev) => (prev > firstIndex ? prev - 1 : prev));
  };

  const onNext = () => {
    setActiveIndex((prev) => (prev < lastIndex ? prev + 1 : prev));
  };

  return visibleSubProduct ? (
    <Box display="flex">
      <SubProductNavigator
        Icon={ArrowBackIcon}
        onClick={onPrevious}
        disabled={activeIndex === firstIndex}
      />
      <SubProductContent {...visibleSubProduct} />
      <SubProductNavigator
        Icon={ArrowForwardIcon}
        onClick={onNext}
        disabled={activeIndex === lastIndex}
      />
    </Box>
  ) : null;
};

export default SubProductsGroup;
