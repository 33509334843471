import { Box } from '@mui/material';
import { EstatePermissions } from 'consts/permissions';
import routes from 'router/routes';
import Table from 'templates/Table';
import hasPermission from 'utils/hasPermission';
import { getEstates } from 'api/estate/requests';
import AddNewButton from 'components/AddNewButton';
import PageTitle from 'components/PageTitle';
import { estatesColumns, getEstatesActions, getLinkToEstate } from './static-data';

const EstatesList = () => {
  const addable = hasPermission(EstatePermissions.Write);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle page="estates" />
        {addable && (
          <AddNewButton
            translationKey="estate"
            to={routes.newEstate}
            dataTestId="addEstate"
          />
        )}
      </Box>
      <Table
        tKey="estates"
        get={getEstates}
        withUrlSearchParams
        columns={estatesColumns}
        getActions={getEstatesActions}
        rowRedirectionLink={getLinkToEstate}
      />
    </Box>
  );
};

export default EstatesList;
