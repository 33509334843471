import { useOutletContext } from 'react-router-dom';
import { DossierPermissions } from 'consts/permissions';
import { DossierDocumentsCard } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { DossierDocumentsContext } from './types';

const DossierProductsDocuments = () => {
  const { dossierDocuments, fetchDossierAndDossierDocuments } =
    useOutletContext<DossierDocumentsContext>();

  return (
    <DossierDocumentsCard
      dossierDocumentsInfo={dossierDocuments.dossier}
      entityType="dossier"
      editable={hasPermission(DossierPermissions.Write)}
      fetchDossierAndDossierDocuments={fetchDossierAndDossierDocuments}
    />
  );
};

export default DossierProductsDocuments;
