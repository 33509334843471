import { FieldArrayWithId } from 'react-hook-form';
import { getCompositionFields } from 'formData/estate/details/fields';
import i18n from 'i18n';
import getLng from 'utils/getLng';
import { TranslationFieldInterface } from 'api/common/types';
import { EstateDestination, EstateDetailsForm } from 'api/estate/types';

export const compositionNameExists = (
  input: TranslationFieldInterface,
  fields: FieldArrayWithId<EstateDetailsForm, 'estateComposition.additionalItems'>[],
  estateDestination: EstateDestination,
) => {
  const lng = getLng();
  const currentInput = input[lng].toLowerCase();

  const staticFieldExists = getCompositionFields(estateDestination).some(
    (name) => i18n.t(`enums:estateComposition.${name}`).toLowerCase() === currentInput,
  );

  const dynamicFieldExists = fields.some(
    ({ name }) => name[lng].toLowerCase() === currentInput,
  );

  return staticFieldExists || dynamicFieldExists;
};

export const getAdditionalCompositionFieldName = (
  name: TranslationFieldInterface,
): TranslationFieldInterface => ({
  en: name.en || name.nl || name.fr,
  fr: name.fr || name.nl || name.en,
  nl: name.nl || name.fr || name.en,
});
