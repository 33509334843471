import { Navigate, RouteObject } from 'react-router-dom';
import { AccountProvider } from 'contexts/accountContext/AccountContext';
import Content from 'modules/FaqContent/FaqContent';
import Faq from 'pages/Faq';
import routes from './routes';

export const faqRoute: RouteObject = {
  path: routes.faq,
  element: (
    <AccountProvider>
      <Faq />
    </AccountProvider>
  ),

  errorElement: <Navigate to={routes.dashboard} />,
  children: [
    { path: routes.faqPersons, element: <Content section="persons" /> },
    { path: routes.faqEstates, element: <Content section="estates" /> },
    { path: routes.faqDossiers, element: <Content section="dossiers" /> },
    { path: routes.faqRentalDeposit, element: <Content section="rentalDeposit" /> },
    { path: routes.faqFireInsurance, element: <Content section="fireInsurance" /> },
    { path: routes.faqLegalAssistance, element: <Content section="legalAssistance" /> },
    { path: '*', element: <Navigate to={routes.faqPersons} /> },
  ],
};
