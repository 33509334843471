import { useCallback, useEffect, useState } from 'react';
import { EstateFormDefaultValues } from 'modules/EstateForm/types';
import { getEstateFormDefaultValues } from './utils';

const useEstateFormDefaultValues = (estateId: string | null, isCopy = false) => {
  const [loading, setLoading] = useState(!!estateId);
  const [defaultValues, setDefaultValues] = useState<
    Omit<EstateFormDefaultValues, 'estate'>
  >({
    estateDetails: undefined,
    owners: [],
  });

  const fetchDefaultValues = useCallback(async () => {
    if (estateId) {
      const defaultValues = await getEstateFormDefaultValues(estateId, isCopy);
      setLoading(false);
      setDefaultValues(defaultValues);
    }
  }, [estateId]);

  useEffect(() => {
    fetchDefaultValues();
  }, [fetchDefaultValues]);

  return { loading, defaultValues };
};

export default useEstateFormDefaultValues;
