import { useMemo } from 'react';
import { formatDate } from 'utils/date';
import DateRange from 'components/DateRange';
import { DateRangeValues } from 'components/DateRange/types';
import { useTableFilters } from '../hooks';
import { TranslatedTableColumn } from '../types';

const TableDateRangeFilter = ({
  field,
  filterName = field,
  filterLabel,
}: TranslatedTableColumn) => {
  const { values, setValues } = useTableFilters();

  const [dateFromField, dateToField] = useMemo(
    () => [`${filterName}From`, `${filterName}To`],
    [filterName],
  );

  const dateValues: DateRangeValues = useMemo(() => {
    const { [dateFromField]: dateFrom, [dateToField]: dateTo } = values;
    return [dateFrom ? new Date(dateFrom) : null, dateTo ? new Date(dateTo) : null];
  }, [values]);

  return (
    <DateRange
      values={dateValues}
      placeholder={filterLabel}
      setValues={([dateFrom, dateTo]) => {
        setValues({
          [dateFromField]: formatDate(dateFrom, 'yyyy-MM-dd') ?? '',
          [dateToField]: formatDate(dateTo, 'yyyy-MM-dd') ?? '',
        });
      }}
    />
  );
};

export default TableDateRangeFilter;
