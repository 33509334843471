import { DocumentsColumn, GetDocumentActions } from './types';
import { handleDeleteDocument, handleDownloadDocument } from './utils';

export const getDocumentActions: GetDocumentActions = ({
  document,
  entityId,
  scope,
  editable,
  turnEditMode,
}) => [
  {
    name: 'download',
    hidden: document.documentClassification !== 'Document',
    onClick: handleDownloadDocument(document, entityId, scope),
  },
  {
    name: 'edit',
    hidden: !editable,
    onClick: turnEditMode,
  },
  {
    name: 'delete',
    onClick: handleDeleteDocument(document, entityId, scope),
  },
];

export const documentsColumns: DocumentsColumn[] = [
  {
    name: 'documentName',
  },
  {
    name: 'documentType',
  },
  {
    name: 'date',
  },
  {
    name: 'createdAt',
  },
];
