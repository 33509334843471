import { Stack } from '@mui/material';
import RentalConditionsFields from 'modules/RentalConditionsFields';
import { RentalConditions } from 'api/dossier/types';
import { MissingDataComponentsProps } from './types';
import { getRequiredAndNotRequiredFields } from './utils';

const RentalConditionsMissingDataForm = ({
  fields,
}: MissingDataComponentsProps<keyof RentalConditions>) => {
  const [requiredFields, notRequiredFields] = getRequiredAndNotRequiredFields(fields);

  return (
    <Stack spacing={1}>
      {!!requiredFields.length && (
        <RentalConditionsFields
          prefix="rentalConditions"
          missingFields={requiredFields}
        />
      )}
      {!!notRequiredFields.length && (
        <RentalConditionsFields
          prefix="rentalConditions"
          missingFields={notRequiredFields}
        />
      )}
    </Stack>
  );
};

export default RentalConditionsMissingDataForm;
