import { TranslatedOption } from 'formData/types';
import { Sex } from 'api/person/natural/types';

const sexArray: Sex[] = ['Female', 'Male', 'Neutral'];

export const sexOptions: TranslatedOption<Sex>[] = sexArray.map((value) => ({
  value,
  label: value,
  translationKey: `sex.${value}`,
}));
