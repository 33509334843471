import { getRouteMatch } from 'utils/route';
import { LoaderScope } from './types';

export const shouldClearLoader = (
  scope: LoaderScope | undefined,
  route: ReturnType<typeof getRouteMatch>,
) =>
  (scope === 'estate' && !route.estate) ||
  (scope === 'dossier' && !route.rentalDossier) ||
  (scope === 'legalPerson' && !route.legalPerson) ||
  (scope === 'naturalPerson' && !route.naturalPerson) ||
  (scope === 'user' && !route.user);
