import { ThemedStyle } from 'types/themedStyle';

export const cardWithPlusStyles: ThemedStyle = {
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& > .content': {
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 300ms, visibility 300ms',
    width: '100%',
  },
  '& > .cover': {
    opacity: 1,
    transition: 'opacity 300ms',
    position: 'absolute',
    top: '50%',
    left: '50%',
    translate: '-50% -50%',
    lineHeight: 0,
    fontSize: 75,
  },
  '&:hover': {
    '& > .cover': {
      opacity: 0,
    },
    '& > .content': {
      opacity: 1,
      visibility: 'visible',
    },
  },
};
