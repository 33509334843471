import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import CardRow from 'templates/CardRow';
import { UserFormData } from 'api/users/types';

interface Props {
  userData: UserFormData;
}

export const UserDetailsCard = ({ userData }: Props) => {
  const { t } = useTranslation(['formFields', 'enums', 'common']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('common:pageCard.user.title')} />
      <CardRow title={t('email')} value={userData.email} />
      <CardRow title={t('biv')} value={userData.biv} />
      <CardRow
        title={t('formFields:language')} //formFields: added because without it there was an errors
        value={t(`enums:language.${userData.language}`)}
      />
      <CardRow title={t('role')} value={t(`enums:userRoles.${userData.role}`)} />
    </Card>
  );
};
