import { Box } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import routes from 'router/routes';
import Table from 'templates/Table';
import hasPermission from 'utils/hasPermission';
import { getPersons } from 'api/person/requests';
import AddNewButton from 'components/AddNewButton';
import PageTitle from 'components/PageTitle';
import { getLinkToPerson, getPersonsActions, personsColumns } from './static-data';

export const PersonsList = () => {
  const addable = hasPermission(PersonPermissions.Write);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle page="persons" />
        {addable && (
          <Box display="flex" gap={2}>
            <AddNewButton
              translationKey="naturalPerson"
              to={routes.newPerson}
              state={{ personType: 'NaturalPerson' }}
              dataTestId="addNaturalPerson"
            />
            <AddNewButton
              translationKey="legalPerson"
              to={routes.newPerson}
              state={{ personType: 'LegalPerson' }}
              dataTestId="addLegalPerson"
            />
          </Box>
        )}
      </Box>
      <Table
        tKey="persons"
        get={getPersons}
        withUrlSearchParams
        columns={personsColumns}
        getActions={getPersonsActions}
        rowRedirectionLink={getLinkToPerson}
      />
    </Box>
  );
};
