import WarningField from 'atoms/WarningField';
import { languageOptions } from 'formData/common/selectOptions';
import { sexOptions } from 'formData/naturalPerson/selectOptions';
import {
  capitalizeMaskWithOnlyLettersAndBasicSigns,
  capitalizeMaskWithoutNumbers,
} from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import AccountNumberField from 'modules/AccountNumberField';
import { HandleBlurChange } from 'types/handleChange';
import {
  findMissingField,
  getInputProps,
  getLabelVariant,
  isMissingFieldRequired,
} from 'utils/missingFields';
import { transformNrnToDateOfBirth } from 'utils/nrn';
import { NaturalPersonFormData } from 'api/person/natural/types';
import {
  ControlledCheckbox,
  ControlledDateInput,
  ControlledEmailInput,
  ControlledMaskedInput,
  ControlledPhoneInput,
  ControlledSelect,
} from 'components/inputs';
import LabelWithTooltip from 'components/LabelWithTooltip';
import NationalityField from './NationalityField';
import {
  NATIONAL_REGISTER_NUMBER_MASK,
  NATURAL_PERSON_FIELDS_BY_SECTION,
} from './static-data';
import TaxResidenceField from './TaxResidenceField';
import { NaturalPersonFieldNames, NaturalPersonFieldsProps } from './types';
import { getNaturalPersonInputProps, isBirthFieldsWarningVisible } from './utils';

const NaturalPersonFields = ({
  prefix,
  missingFields = [],
  section,
}: NaturalPersonFieldsProps) => {
  const { getValues, registerController, setValue, watch } =
    useFormMethods<NaturalPersonFormData>(prefix);

  const registerInput = (name: NaturalPersonFieldNames) => ({
    ...getNaturalPersonInputProps(name, missingFields),
    ...registerController(name),
  });

  const isVisible = (name: NaturalPersonFieldNames) => {
    if (missingFields.length) {
      return !!findMissingField(name, missingFields);
    }
    if (section) {
      return NATURAL_PERSON_FIELDS_BY_SECTION[section].includes(name);
    }
    return true;
  };

  const nationality = watch('nationality');

  const fillDateOfBirthFromNrn: HandleBlurChange = (event) => {
    const nrn = event.target.value;
    const knownDateOfBirth = nrn.replaceAll(/[^\d]/g, '').slice(2, 6) !== '0000';

    const dateOfBirth = getValues('dateOfBirth');
    if (nrn && !dateOfBirth && knownDateOfBirth) {
      setValue('dateOfBirth', transformNrnToDateOfBirth(nrn).toDateString());
    }
  };

  return (
    <>
      {isVisible('name') && (
        <ControlledMaskedInput
          xs={12}
          md={6}
          {...registerInput('name')}
          {...capitalizeMaskWithoutNumbers}
        />
      )}
      {isVisible('firstName') && (
        <ControlledMaskedInput
          xs={12}
          md={6}
          {...registerInput('firstName')}
          {...capitalizeMaskWithoutNumbers}
        />
      )}
      {isVisible('givenName') && (
        <ControlledMaskedInput
          xs={12}
          md={6}
          {...registerInput('givenName')}
          {...capitalizeMaskWithoutNumbers}
        />
      )}
      {isVisible('sex') && (
        <ControlledSelect xs={12} md={6} {...registerInput('sex')} options={sexOptions} />
      )}
      {isVisible('email') && <ControlledEmailInput xs={12} {...registerInput('email')} />}
      {isVisible('phone') && <ControlledPhoneInput xs={12} {...registerInput('phone')} />}
      {isVisible('language') && (
        <ControlledSelect
          xs={12}
          md={6}
          {...registerInput('language')}
          options={languageOptions}
        />
      )}
      {isVisible('nationality') && (
        <NationalityField
          {...getInputProps('nationality', missingFields)}
          isMissingDataModal={!!missingFields.length}
          prefix={prefix}
        />
      )}
      {isBirthFieldsWarningVisible(missingFields, nationality) && (
        <WarningField tKey="missingNrnOrBirthFields" fullWidth />
      )}
      {isVisible('nationalRegisterNumber') && (
        <ControlledMaskedInput
          xs={12}
          md={6}
          {...registerInput('nationalRegisterNumber')}
          mask={NATIONAL_REGISTER_NUMBER_MASK}
          onBlur={fillDateOfBirthFromNrn}
        />
      )}
      {isVisible('dateOfBirth') && (
        <ControlledDateInput
          xs={12}
          md={6}
          {...registerInput('dateOfBirth')}
          disableFuture
          disableToday
        />
      )}
      {isVisible('placeOfBirth') && (
        <ControlledMaskedInput
          xs={12}
          md={6}
          {...capitalizeMaskWithOnlyLettersAndBasicSigns}
          {...registerInput('placeOfBirth')}
        />
      )}
      {(isVisible('iban') || isVisible('bic')) && (
        <AccountNumberField
          prefix={prefix}
          labelVariant={getLabelVariant(missingFields)}
        />
      )}
      {isVisible('isPoliticallyExposedPerson') && (
        <ControlledCheckbox
          {...registerInput('isPoliticallyExposedPerson')}
          sx={{ mb: -1.25 }}
          label={
            <LabelWithTooltip
              label={registerInput('isPoliticallyExposedPerson').label}
              tooltip="tooltips.politicallyExposedPerson"
            />
          }
        />
      )}
      {(isVisible('taxResidenceBelgium') || isVisible('taxResidences')) && (
        <TaxResidenceField
          prefix={prefix}
          labelVariant={getLabelVariant(missingFields)}
          isTaxResidenceInputsRequired={isMissingFieldRequired(
            'taxResidences',
            missingFields,
          )}
          isTaxResidenceVariantRequired={isMissingFieldRequired(
            'taxResidenceBelgium',
            missingFields,
          )}
        />
      )}
    </>
  );
};

export default NaturalPersonFields;
