import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';
import Button from 'atoms/Button';
import { languageOptions } from 'formData/common/selectOptions';
import { userSchema } from 'formData/users';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useGetUserRoles from 'hooks/useGetUserRoles';
import usePrompt from 'hooks/usePrompt';
import routes from 'router/routes';
import { FormContainer } from 'templates/FormTemplates';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { addUser, updateUser } from 'api/users/requests';
import { UserFormData } from 'api/users/types';
import { ControlledSelect, ControlledTextInput } from 'components/inputs';
import { EmailInformationModal } from 'components/modals/EmailInformationModal';

interface Props {
  defaultValues?: UserFormData;
  userId?: string;
  mode?: 'creation' | 'edition';
}

const UserForm = ({ defaultValues, userId, mode = 'creation' }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState(userId || '');
  const { t } = useTranslation(['formFields', 'common']);
  const navigate = useCustomNavigate();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isDirty },
  } = useForm<UserFormData>({
    defaultValues: defaultValues || userSchema.getDefault(),
    resolver: resolver(userSchema),
  });

  usePrompt({ when: isDirty });

  const onCancel = () => {
    navigate(-1);
  };

  const onAdd = async (data: UserFormData) => {
    setSubmitting(true);
    const res = await addUser(data);
    if (res.ok) {
      setId(res.response.id);
    } else {
      if (res.validation) {
        setValidationErrors(res.validation, setError);
      } else if (res.status === 422) {
        setError('email', {
          // @ts-ignore react-hook-form does not know, that its message also supports objects
          message: {
            key: 'userAlreadyExist',
            values: { field: 'email' },
          },
        });
      }
    }
    setSubmitting(false);
  };

  const onEdit = async (data: UserFormData) => {
    setSubmitting(true);
    const res = await updateUser(id, data);
    if (res.ok) {
      navigate(routes.user(id), { withoutPrompt: true });
    } else {
      if (res.validation) {
        setValidationErrors(res.validation, setError);
      }
      setSubmitting(false);
    }
  };

  const closeModal = () => {
    navigate(routes.user(id), { withoutPrompt: true });
  };

  const roleOptions = useGetUserRoles();
  const registerInput = (name: keyof UserFormData) =>
    ({
      label: t(`formFields:${name}`),
      labelVariant: 'float',
      control,
      name,
      error: errors[name],
    } as const);

  return (
    <>
      <Stack
        spacing={4}
        component="form"
        onSubmit={handleSubmit(mode === 'creation' ? onAdd : onEdit)}
        mt={2}
      >
        <Box>
          <FormContainer>
            <Grid item container rowSpacing={2} columnSpacing={2}>
              <ControlledTextInput
                xs={12}
                sm={4}
                disabled={mode === 'edition'}
                {...registerInput('email')}
              />
              <ControlledTextInput xs={12} sm={4} {...registerInput('firstName')} />
              <ControlledTextInput xs={12} sm={4} {...registerInput('name')} />
              <ControlledTextInput xs={12} sm={4} {...registerInput('biv')} />
              <ControlledSelect
                xs={12}
                sm={4}
                {...registerInput('language')}
                options={languageOptions}
              />
              <ControlledSelect
                xs={12}
                sm={4}
                {...registerInput('role')}
                options={roleOptions}
              />
            </Grid>
          </FormContainer>
        </Box>
        <Stack justifyContent="flex-end" direction="row" spacing={2}>
          <Button role="text" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button disableElevation type="submit" loading={submitting}>
            {t('common:save')}
          </Button>
        </Stack>
      </Stack>
      <EmailInformationModal open={mode === 'creation' && !!id} closeModal={closeModal} />
    </>
  );
};

export default UserForm;
