import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField, TextFieldProps, Typography } from '@mui/material';
import { ClearIcon } from 'icons';
import InputHelperText from '../BaseInput/InputHelperText';
import { getBaseInputStyles } from '../BaseInput/styles';
import InputWrapper from '../InputWrapper';
import MenuItemContent from './MenuItemContent';
import renderMenuItems from './renderMenuItems';
import { selectInputStyles } from './styles';
import { SelectInputProps } from './types';

const Select = <TValue extends string>({
  helperText,
  name,
  onChange = () => {},
  clearable,
  onClear,
  fullWidth = true,
  size = 'small',
  variant = 'outlined',
  options,
  noOptionsText,
  loading,
  error = false,
  warning = false,
  loadingText,
  groupedOptions,
  renderValue,
  value,
  staticWidth, // 0 would be treated same as undefined,
  placeholder,
  multiple,
  InputLabelProps,
  InputProps,
  inputProps,
  ...props
}: SelectInputProps<TValue>) => {
  const { t } = useTranslation();

  const renderSelectedValue = useCallback(
    (selected: unknown) => {
      if (multiple && Array.isArray(selected) && selected.length) {
        return selected
          .map((value) => {
            const { translationKey, label } = options.filter(
              ({ value: optionValue }) => optionValue === value,
            )[0];
            return translationKey ? t(translationKey, label) : label;
          })
          .join(', ');
      }

      const selectedOption = options.filter(({ value }) => value === selected)[0];
      if (renderValue) {
        return renderValue(selectedOption);
      }
      return selectedOption ? (
        <MenuItemContent {...selectedOption} />
      ) : (
        <Typography color="grey.400">{placeholder}</Typography>
      );
    },
    [multiple, options, renderValue],
  );

  const SelectProps: TextFieldProps['SelectProps'] = useMemo(
    () => ({
      displayEmpty: !!placeholder,
      renderValue: renderSelectedValue,
      MenuProps: {
        elevation: 2,
        PaperProps: {
          'data-testid': `${name}-menu`,
          sx: { maxHeight: 250, ...(staticWidth ? { width: staticWidth } : {}) },
        },
      },
      multiple,
    }),
    [staticWidth, renderSelectedValue],
  );

  const isClearable = useMemo(() => {
    if (props.disabled || !clearable) {
      return false;
    }
    return Array.isArray(value) ? !!value.length : !!value;
  }, [value, props.disabled, clearable]);

  return (
    <TextField
      select
      sx={{
        ...getBaseInputStyles(error, warning),
        ...(staticWidth ? { width: staticWidth } : {}),
      }}
      SelectProps={SelectProps}
      id={name}
      size={size}
      onChange={({ target }) =>
        onChange(
          target.value as TValue,
          options.find(({ value }) => value === target.value),
        )
      }
      variant={variant}
      fullWidth={fullWidth}
      error={error}
      // @ts-ignore it exists
      FormHelperTextProps={{ component: 'div' }}
      helperText={
        helperText ? (
          <InputHelperText helperText={helperText} warning={warning} error={error} />
        ) : undefined
      }
      inputProps={{
        'data-testid': name,
        ...inputProps,
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        notched: true,
        endAdornment: isClearable && (
          <IconButton
            size="small"
            className="clearable"
            sx={{ position: 'absolute', right: 25, fontSize: 18 }}
            onClick={onClear}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        ),
        sx: selectInputStyles,
        ...InputProps,
      }}
      // removes mui warning - value out of range
      value={options.length ? value : ''}
      data-testid={`${name}-container`}
      {...props}
    >
      {renderMenuItems({ options, groupedOptions, loading, loadingText, noOptionsText })}
    </TextField>
  );
};

const SelectWrapper = <TValue extends string>(props: SelectInputProps<TValue>) =>
  InputWrapper(Select, props);

export default SelectWrapper;
