import { createContext, ReactElement } from 'react';
import useConfirmationModal from 'hooks/useConfirmationModal';
import { ConfirmationProps } from 'hooks/useConfirmationModal/types';
import { ConfirmationModal } from 'components/modals';

export const ConfirmationContext = createContext<{
  setUpAndOpenConfirmationModal: (props: ConfirmationProps) => void;
}>({
  setUpAndOpenConfirmationModal: () => {},
});

export const ConfirmationProvider = ({ children }: { children: ReactElement }) => {
  const { confirmationModalProps, setUpAndOpenConfirmationModal } =
    useConfirmationModal();

  return (
    <ConfirmationContext.Provider value={{ setUpAndOpenConfirmationModal }}>
      {children}
      {confirmationModalProps && <ConfirmationModal {...confirmationModalProps} />}
    </ConfirmationContext.Provider>
  );
};
