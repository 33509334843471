import { useTranslation } from 'react-i18next';
import Button from 'atoms/Button';
import { PageCardHeaderActionProps } from '../types';

const PageCardHeaderAction = ({
  onClick,
  disabled,
  label = 'add',
  dataTestId,
}: PageCardHeaderActionProps) => {
  const { t } = useTranslation();

  return (
    <Button size="small" onClick={onClick} disabled={disabled} data-testid={dataTestId}>
      {t(label)}
    </Button>
  );
};

export default PageCardHeaderAction;
