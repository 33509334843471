export default {
  // PRIVATE RELATIONS
  getPersonPrivateRelation: (naturalPersonId: string) =>
    `/naturalPersons/${naturalPersonId}/privateRelations`,
  addPrivateRelation: '/privateRelations',
  privateRelation: (relationId: string) => `/privateRelations/${relationId}`,

  // REPRESENTATIVE RELATIONS
  addRepresentation: '/representations',
  deleteRepresentation: (relationId: string) => `/representations/${relationId}`,
  getNaturalPersonRepresentations: (naturalPersonId: string) =>
    `/naturalPersons/${naturalPersonId}/representations`,
  getLegalPersonRepresentatives: (legalPersonId: string) =>
    `/legalPersons/${legalPersonId}/representations`,

  // OWNERSHIPS
  getEstateOwners: (estateId: string) => `/estates/${estateId}/owners`,
  getNaturalPersonEstates: (personId: string) => `/naturalPersons/${personId}/estates`,
  getLegalPersonEstates: (personId: string) => `/legalPersons/${personId}/estates`,
  addOwnership: '/ownerships',
  deleteOwnership: (ownershipId: string) => `/ownerships/${ownershipId}`,
};
