import { UseFormReturn } from 'react-hook-form';
import { findMissingField } from 'utils/missingFields';
import { normalizeString } from 'utils/string';
import { AddressFormInterface } from 'api/common/types';
import { MissingField } from 'api/missingData/types';
import { MissingDataForm } from '../types';

export const getRequiredAndNotRequiredFields = <T extends string>(
  missingFields: MissingField<T>[],
) => [
  missingFields.filter(({ required }) => required),
  missingFields.filter(({ required }) => !required),
];

export const getNaturalPersonsFields = (missingFields: MissingField[]) => {
  let [requiredFields, notRequiredFields] =
    getRequiredAndNotRequiredFields(missingFields);

  const isTaxResidenceVariant = findMissingField('taxResidenceBelgium', missingFields);

  if (isTaxResidenceVariant?.required) {
    requiredFields = missingFields.filter(
      ({ required, propertyName }) => required || propertyName === 'TaxResidences',
    );
    notRequiredFields = notRequiredFields.filter(
      ({ propertyName }) => propertyName !== 'TaxResidences',
    );
  } else if (isTaxResidenceVariant) {
    notRequiredFields = missingFields.filter(
      ({ required, propertyName }) => !required || propertyName === 'TaxResidences',
    );
    requiredFields = requiredFields.filter(
      ({ propertyName }) => propertyName !== 'TaxResidences',
    );
  }

  return { requiredFields, notRequiredFields };
};

export const getMissingRequiredFields = <T extends keyof MissingDataForm>(
  errors: NonNullable<UseFormReturn<MissingDataForm>['formState']['errors'][T]> = {},
  fields: MissingField[],
) => {
  const fieldExists = (key: string) => {
    return !!fields.find(({ propertyName }) =>
      key === 'vat'
        ? key.toUpperCase() === propertyName.toUpperCase()
        : normalizeString(key) === normalizeString(propertyName),
    );
  };

  return Object.keys(errors).filter((key) => {
    if (key === 'address' && 'address' in errors) {
      return !Object.keys(errors.address ?? {}).some(fieldExists);
    }

    return !fieldExists(key);
  });
};

const addressFields: (keyof AddressFormInterface)[] = [
  'street',
  'houseNumber',
  'extension',
  'apartmentNumber',
  'city',
  'postCode',
  'country',
];

export const isAnyAddressFieldMissing = (fields: MissingField[]) =>
  fields.some(({ propertyName }) =>
    addressFields.find(
      (field) => normalizeString(field) === normalizeString(propertyName),
    ),
  );
