import { useContext, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import { ConfigContext } from 'contexts/config/ConfigContext';
import AddNewProductCard from 'modules/AddNewProductCard';
import ProductCard from 'modules/ProductCard';
import hasRole from 'utils/hasRole';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import { getProductsOrder } from './utils';

export const ProductsGrid = () => {
  const { rentalDossierChecklist } = useContext(ConfigContext);
  const { products = [], fetchRentalDossier } = useOutletContext<DossierOutletContext>();

  const productsOrder = useMemo(
    () => getProductsOrder(rentalDossierChecklist),
    [rentalDossierChecklist],
  );

  return (
    <Box sx={({ mixins }) => mixins.cardsGrid}>
      {products
        .sort((a, b) => productsOrder[a.productName] - productsOrder[b.productName])
        .map((product) => (
          <ProductCard key={product.id} {...product} />
        ))}
      {!hasRole('Admin') && (
        <AddNewProductCard products={products ?? []} fetchProducts={fetchRentalDossier} />
      )}
    </Box>
  );
};
