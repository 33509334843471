import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { UserPermissions } from 'consts/permissions';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import RentalConditionsFields from 'modules/RentalConditionsFields';
import { FormContainer, FormSection } from 'templates/FormTemplates';
import hasPermission from 'utils/hasPermission';
import DossierEstateInput from './DossierEstateInput';
import DossierPartyInput from './DossierPartyInput';
import OwnReferenceInput from './OwnReferenceInput';
import ResidentsFields from './ResidentsFields';
import ResponsibleUsersInput from './ResponsibleUsersInput';

const ParametersStep = () => {
  const { mode, editableFields } = useContext(DossierFormContext);
  const { t } = useTranslation('common', { keyPrefix: 'stepWizard.dossier.sections' });

  return (
    <FormContainer>
      <Grid item xs={12} data-testid="dossier-estate-section">
        <DossierEstateInput />
      </Grid>
      <FormSection container label={t('parties')}>
        <Grid item xs={12} md={6} data-testid="dossier-landlord-section">
          <DossierPartyInput partyType="landlord" />
        </Grid>
        <Grid item xs={12} md={6} data-testid="dossier-tenant-section">
          <DossierPartyInput partyType="tenant" />
        </Grid>
      </FormSection>
      <FormSection container label={t('rentalConditions')}>
        <RentalConditionsFields
          prefix="rentalConditions"
          editable={editableFields.rentalConditions}
        />
      </FormSection>
      {/* TODO: Confirm whether residents should be displayed for rentType !== Habitation */}
      <FormSection label={t('residents')}>
        <ResidentsFields />
      </FormSection>
      {(hasPermission(UserPermissions.Read) || mode === 'edition') && (
        <FormSection container label={t('additionalInfo')}>
          {/* TODO: Remove this condition once backend add ownReference into dossier POST request */}
          {mode === 'edition' && (
            <Grid item xs={12}>
              <OwnReferenceInput />
            </Grid>
          )}
          {hasPermission(UserPermissions.Read) && (
            <Grid item xs={12} data-testid="dossier-responsible-persons-section">
              <ResponsibleUsersInput />
            </Grid>
          )}
        </FormSection>
      )}
    </FormContainer>
  );
};

export default ParametersStep;
