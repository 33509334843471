import { matchPath, PathMatch } from 'react-router-dom';

type MatchRoute = (
  routes: string | string[],
  pathname: string,
  exclude?: string[] | string,
) => PathMatch | null;

export const matchRoute: MatchRoute = (routes, pathname, exclude = '') => {
  let matchesExcluded = false;
  if (Array.isArray(routes)) {
    const matchingRoute = routes.find((item) => matchPath(item, pathname));
    return matchingRoute ? matchPath(matchingRoute, pathname) : null;
  }
  if (Array.isArray(exclude)) {
    matchesExcluded = exclude.includes(pathname);
  } else if (exclude) {
    matchesExcluded = pathname === exclude;
  }

  return !matchesExcluded ? matchPath(routes, pathname) : null;
};
