import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import calculateEndOfRental from 'utils/calculateEndOfRental';
import { formatDate } from 'utils/date';
import paymentToString from 'utils/paymentToString';
import { RentalConditions } from 'api/dossier/types';
import { ProductActionInfoRow } from 'components/modals/ProductActionModal';

interface Props {
  rentalConditions: RentalConditions;
}

const RentalConditionDetails = ({ rentalConditions }: Props) => {
  const { t } = useTranslation('modals');

  const { entryDate, duration, endDate, rentType, rentalPrice, charges } =
    rentalConditions;

  return (
    <Grid container>
      {rentType && <ProductActionInfoRow title={t('data.rentalType')} value={rentType} />}
      {entryDate && (
        <ProductActionInfoRow
          title={t('data.startOfRental')}
          value={formatDate(entryDate) ?? ''}
        />
      )}
      {duration && (
        <ProductActionInfoRow
          title={t('data.endOfRental')}
          value={
            duration === 'Other' && endDate
              ? formatDate(endDate) ?? ''
              : calculateEndOfRental(entryDate, duration, 'dd/MM/yyyy') ?? duration
          }
        />
      )}
      {rentalPrice && (
        <ProductActionInfoRow
          title={t('data.rentalPrice')}
          value={paymentToString(rentalPrice)}
        />
      )}
      {charges && (
        <ProductActionInfoRow
          title={t('data.commonCharges')}
          value={paymentToString(charges)}
        />
      )}
    </Grid>
  );
};

export default RentalConditionDetails;
