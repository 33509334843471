import { Outlet, useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import useLoader from 'hooks/useLoader';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import { DossierDocumentsContext } from './types';
import { getDossierDocumentsConfig } from './utils';

const DossierDocumentsTab = () => {
  const { fetchRentalDossier, ...dossier } = useOutletContext<DossierOutletContext>();
  const { data, updateData, Loader } = useLoader(getDossierDocumentsConfig(dossier.id));

  const fetchDossierAndDossierDocuments = async () => {
    await updateData();
    await fetchRentalDossier();
  };

  return (
    <Loader>
      {data && (
        <Grid container item spacing={3}>
          <Grid item xs={12} marginBottom="auto">
            <Outlet
              context={
                {
                  ...dossier,
                  dossierDocuments: data,
                  fetchDossierAndDossierDocuments,
                } as DossierDocumentsContext
              }
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default DossierDocumentsTab;
