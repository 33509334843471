import { useContext } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { NavigationContext } from 'contexts/navigation/navigationContext';
import { CustomNavigation, CustomNavOptions } from './types';
import { blockNavigation } from './utils';

export const useCustomNavigate = () => {
  const { setNavData, promptData, openPrompt, resetPrompt } =
    useContext(NavigationContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const customNavigate: CustomNavigation = (...props) => {
    const [to, navOptions] = props;
    const { withoutPrompt = false, ...options } = (navOptions ?? {}) as CustomNavOptions;
    setNavData({ to, options });
    const shouldNavigate = !blockNavigation(promptData, to) || withoutPrompt;

    if (shouldNavigate) {
      if (withoutPrompt) {
        resetPrompt();
      }

      if (typeof to === 'number') {
        navigate(to);
      } else {
        navigate(to, options as NavigateOptions);
      }
      setNavData(null);
    } else if (pathname !== to) {
      openPrompt();
    }

    return shouldNavigate;
  };

  return customNavigate;
};
