import { useMemo } from 'react';
import useTableFiltersState from './useTableFiltersState';

const useTableInitFilter = (field: string) => {
  const [filters] = useTableFiltersState();

  const initFilter = useMemo(
    () => filters.find(({ name }) => name === field)?.value ?? '',
    [],
  );

  return initFilter;
};

export default useTableInitFilter;
