import { translationSchema } from 'formData/common/schema';
import { TKey } from 'i18next';
import isTruthyValueInObject from 'utils/object/isTruthyValueInObject';
import yup, { numberSchema } from 'utils/yup';
import estateCompositionSchema from './composition/schema';
import electricitySchema from './electricity/schema';
import epcSchema from './epc/schema';
import gasSchema from './gas/schema';

const estateDetailsSchema = yup.object({
  descriptions: translationSchema
    .nullable()
    .transform((value) => (isTruthyValueInObject(value) ? value : null)),
  habitableSurface: numberSchema
    .label('habitableSurface')
    .nullable()
    .default(null)
    .positive(),
  managementType: yup
    .string<TKey<'enums', 'estateManagementTypes'>>()
    .nullable()
    .default(null),
  smokeDetectors: numberSchema
    .label('smokeDetectors')
    .nullable()
    .default(null)
    .min(0)
    .max(99),
  isFurnished: yup.boolean().default(false),
  others: yup.string().nullable().default(null),
  hasConformity: yup.boolean().default(false),
  conformity: yup
    .string()
    .label('conformityDate')
    .nullable()
    .default(null)
    .customDate({ disableFuture: true })
    .transform((value) => value || null),
  // below code seems to be redundant until we have explicit controller for manipulating hasConformity value
  // .when('hasConformity', {
  //   is: true,
  //   then: (schema) => schema.required(),
  // }),
  hasSyndic: yup.boolean().default(false),
  syndic: yup.string().label('syndic').nullable().default(null),
  hasElectricity: yup.boolean().default(false),
  electricity: electricitySchema,
  hasGas: yup.boolean().default(false),
  gas: gasSchema,
  hasEpc: yup.boolean().default(false),
  epc: epcSchema,
  estateComposition: estateCompositionSchema,
});

export default estateDetailsSchema;
