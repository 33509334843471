import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import FilterChips from 'templates/FilterChips';
import { FilterChipInterface } from 'templates/FilterChips/types';
import { formatDate } from 'utils/date';
import { SortDirection } from 'utils/sortUtils/types';
import { DateRangeValues } from 'components/DateRange/types';
import HistoryOfChangesFooter from './HistoryOfChangesFooter';
import HistoryOfChangesRecord from './HistoryOfChangesRecord';
import HistoryOfChangesToolbar from './HistoryOfChangesToolbar';
import { INIT_RECORDS_TO_LOAD } from './static-data';
import { HistoryOfChangesInterface, HistoryOfChangesProps } from './types';
import { mapHistoryOfChanges } from './utils';

const HistoryOfChanges = ({
  entityId,
  getHistory,
  activities,
}: HistoryOfChangesProps) => {
  const [fetchingRecords, setFetchingRecords] = useState(true);
  const [visibleRecordsCount, setVisibleRecordsCount] = useState(INIT_RECORDS_TO_LOAD);
  const [records, setRecords] = useState<HistoryOfChangesInterface[]>([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  const { t, i18n } = useTranslation('history');

  const [eventsData, setEventsData] = useState<FilterChipInterface[]>(
    activities.map((name) => ({
      name,
      label: t(`activities.${name}`, name),
      checked: false,
    })),
  );
  const [dateRange, setDateRange] = useState<DateRangeValues>([null, null]);
  const [sortBy, setSortBy] = useState<SortDirection>('desc');

  const fetchRecords = useCallback(
    async (count = visibleRecordsCount, withSpinner = true) => {
      setFetchingRecords(withSpinner);
      const { ok, response } = await getHistory(entityId, {
        limit: count,
        sort: sortBy ? `modifiedAt:${sortBy}` : undefined,
        eventsData: eventsData
          .filter(({ checked }) => checked)
          .map(({ name }) => name)
          .join(','),
        dateFrom: formatDate(dateRange[0], 'yyyy-MM-dd'),
        dateTo: formatDate(dateRange[1], 'yyyy-MM-dd'),
      });
      if (ok) {
        setVisibleRecordsCount(count);
        setRecords(mapHistoryOfChanges(response.data));
        setTotalRecordsCount(response.meta.total);
      }
      setFetchingRecords(false);
    },
    [entityId, i18n.language, eventsData, dateRange, sortBy],
  );

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <HistoryOfChangesToolbar
        sortBy={sortBy}
        setSortBy={setSortBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <FilterChips filters={eventsData} setFilters={setEventsData} />
      {fetchingRecords ? (
        <LoadingElement />
      ) : records.length ? (
        <>
          <Card sx={({ mixins }) => mixins.pageCard}>
            {records.map((record) => (
              <HistoryOfChangesRecord
                key={`${record.propertyName}_${record.modifiedAt}`}
                {...record}
              />
            ))}
          </Card>
          <HistoryOfChangesFooter
            fetchRecords={fetchRecords}
            visibleRecordsCount={visibleRecordsCount}
            totalRecordsCount={totalRecordsCount}
          />
        </>
      ) : (
        <Typography variant="h5" textAlign="center">
          {totalRecordsCount ? t('noHistoryWithFiltersLabel') : t('noHistoryLabel')}
        </Typography>
      )}
    </Box>
  );
};

export default HistoryOfChanges;
