import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import WarningField from 'atoms/WarningField';
import { FeatureFlagsContext } from 'contexts/featureFlags/FeatureFlagsContext';
import useLoader from 'hooks/useLoader';
import { LegalPersonDetailsCard, PersonViewHeader } from 'modules/PersonView';
import routes, { personTabRoutes } from 'router/routes';
import { AffiliatedPersonsCard } from 'templates/PageCard';
import { RouteTabs } from 'components/tabs';
import { getLegalPersonConfig, isLegalPersonMissingRequiredData } from './utils';

const LegalPersonDetails = () => {
  const { legalPersonId } = useParams();
  if (!legalPersonId) return null;

  const { isFeatureActive } = useContext(FeatureFlagsContext);
  const { data: legalPerson, Loader } = useLoader(getLegalPersonConfig(legalPersonId));

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const personRoutes = useMemo(() => {
    const { details, ...routesWithoutDetails } = personTabRoutes;
    return matches ? routesWithoutDetails : { details, ...routesWithoutDetails };
  }, [matches]);

  return (
    <Loader>
      {legalPerson && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              {!matches && (
                <PersonViewHeader
                  type="LegalPerson"
                  id={legalPersonId}
                  data={legalPerson.data}
                />
              )}
              <RouteTabs
                routes={personRoutes}
                parentPath={routes.legalPerson(legalPersonId)}
                translationKey="personView"
                hiddenTabs={!isFeatureActive('HistoryLogging') ? ['history'] : []}
              />
            </Stack>
            <Outlet context={legalPerson} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
            gap={3}
          >
            <PersonViewHeader
              type="LegalPerson"
              id={legalPersonId}
              data={legalPerson.data}
            />
            <Stack spacing={3}>
              {isLegalPersonMissingRequiredData(legalPerson.data) && (
                <WarningField tKey="missingFormRequiredData" fullWidth />
              )}
              <LegalPersonDetailsCard {...legalPerson} />
              <AffiliatedPersonsCard
                persons={legalPerson.representedBy}
                title="representedBy"
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default LegalPersonDetails;
