import { useState } from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { DemoInterface } from 'api/faq/types';
import DemoPreviewModal from './DemoPreviewModal';

const DemoTile = (demo: DemoInterface) => {
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <>
      <Card
        variant="outlined"
        sx={({ mixins }) => ({
          ...mixins.actionCard,
          height: { xs: 250, xl: 300 },
        })}
      >
        <CardActionArea sx={{ height: '100%' }} onClick={() => setOpenPreview(true)}>
          <Box
            component="img"
            // thumbnail should not be null but it's for satisfying DataUrl type
            src={demo.thumbnail?.toString() ?? ''}
            width="100%"
            height={'80%'}
          />
          <CardContent>
            <Typography variant="h5">{demo.name}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {openPreview && (
        <DemoPreviewModal demo={demo} onClose={() => setOpenPreview(false)} />
      )}
    </>
  );
};

export default DemoTile;
