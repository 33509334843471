import { StringSchema } from 'yup';

const isEmailValid = (value: string) => {
  if (
    !value.includes('@') ||
    [...value].filter((char) => char === '@').length > 1 ||
    value.includes('..')
  ) {
    return false;
  }

  // localPart@domain
  const [localPart, domain] = value.split('@');

  if (
    !localPart.length ||
    localPart.startsWith('.') ||
    localPart.endsWith('.') ||
    !localPart.match(/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]{1,64}$/)
  ) {
    return false;
  }

  // com, org
  const topLevelDomain = domain.substring(domain.lastIndexOf('.') + 1);

  if (
    !domain.length ||
    domain.startsWith('-') ||
    domain.endsWith('-') ||
    topLevelDomain.match(/^\d+$/) ||
    !domain.match(/^[a-zA-Z0-9-.]+[.]+[a-zA-Z0-9]{2,}$/)
  ) {
    return false;
  }

  return true;
};

export function validateEmail(this: StringSchema) {
  return this.when({
    is: (value: string | null) => value && !isEmailValid(value),
    then: (schema) => schema.invalid('string.email'),
  });
}
