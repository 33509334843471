import { To } from 'react-router-dom';
import { PromptData } from 'contexts/navigation/types';
import { matchRoute } from 'utils/route';

export const blockNavigation = (promptData: PromptData | null, to: number | To) =>
  promptData &&
  Object.values(promptData).some((data) => {
    const { pathnamesToExclude, shouldBlock } = data ?? {};
    if (pathnamesToExclude) {
      return shouldBlock && !matchRoute(pathnamesToExclude, String(to));
    }
    return shouldBlock;
  });
