import { Divider, Stack, Typography } from '@mui/material';
import { AssignedEstateProps } from './types';

const AssignedEstate = ({ label, address }: AssignedEstateProps) => (
  <Stack gap={1}>
    <Stack direction="row" justifyContent="space-between" gap={1} alignItems="center">
      <Typography color="grey.500">{label}</Typography>
      <Typography color="primary.main" fontWeight="bold" textAlign="right">
        {address}
      </Typography>
    </Stack>
    <Divider />
  </Stack>
);

export default AssignedEstate;
